import React from "react"

import { ErrorMessage } from "components/Forms/Formik"
import { CheckboxField } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

const Availabilities = ({ availabilities }) => (
  <>
    <h3>Availabilities</h3>
    {availabilities.map(availability => (
      <CheckboxField
        key={availability.id}
        name="availabilityIds"
        value={availability.id}
        label={
          <>
            <LocalTime timestamp={availability.startsAt} format="dddd LT" />{" "}
            &mdash; <LocalTime timestamp={availability.endsAt} omitDate />
          </>
        }
        hideError
      />
    ))}
    <ErrorMessage name="availabilityIds" />
  </>
)

export default Availabilities
