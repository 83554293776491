import React from "react"

import Tippy from "@tippyjs/react"

import ordered from "src/ordered"

import StudentAvailability from "./StudentAvailability"

import "tippy.js/dist/tippy.css"

const UnassignedStudents = ({ assessmentSectionId, students }) => {
  const score = student =>
    student.assessmentScores.find(
      score => score.assessmentSection.id === assessmentSectionId
    ).score

  return (
    <>
      <h3>Unassigned Students</h3>
      <ul className="list-unstyled">
        {ordered(students, "lastName").map(student => (
          <li key={student.id}>
            <Tippy
              content={
                <StudentAvailability
                  availabilities={student.studentAvailabilities}
                />
              }
              placement="left"
            >
              <a href={student.showPath}>{student.fullName}</a>
            </Tippy>
            {assessmentSectionId && (
              <span className="ml-3">Score: {score(student)}</span>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

export default UnassignedStudents
