import React from "react"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"

const StatementUnbilledSessions = ({
  totalPages,
  currentPage,
  changePage,
  sessions,
}) => {
  return (
    <>
      <h4>Unbilled Sessions</h4>
      <table className="table">
        <thead className="text-primary">
          <tr>
            <th>Tutor</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {sessions.map(session => (
            <tr key={session.id}>
              <td>
                {session.tutor ? (
                  <a href={session.tutor.showPath}>{session.tutor.fullName}</a>
                ) : null}
              </td>
              <td>
                <LocalTime timestamp={session.startsAt} omitTime />
              </td>
              <td>
                <a className="btn btn-sm btn-info" href={session.showPath}>
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        changePage={changePage}
      />
    </>
  )
}

export default StatementUnbilledSessions
