import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const SessionReassignmentModal = ({ timeout, redirectTo, isOpen, onClose }) => {
  const handleRedirect = () => {
    clearTimeout(timeout.current)
    window.location = redirectTo
  }

  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Your session is moving"
      closeModal={onClose}
      hideCloseButton
    >
      <>
        <div className="modal-body">
          <p className="lead">
            Your tutor wasn't able to make your session, so we are moving you to
            another session. Click below to be taken there immediately.
          </p>
        </div>
        <div className="modal-footer">
          <button className="btn solid blue" onClick={handleRedirect}>
            Join Session
          </button>
        </div>
      </>
    </ModalWithProvidedBody>
  )
}

export default SessionReassignmentModal
