import React, { useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
import { buildQuery, useQuery } from "micro-graphql-react"

import EditableList, { Item } from "components/EditableList"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import NewSemesterForm from "./NewSemesterForm"
import loadOrganizationSemestersQuery from "./loadOrganizationSemestersQuery"
import removeOrganizationSemesterMutation from "./removeSemesterMutation"

const OrganizationSemesters = props => {
  const { organizationId } = props
  const [addingSemester, setAddingSemester] = useState(false)

  const opacityDuration = 0.3
  const heightDuration = 0.5

  const loadingState = useQuery(
    buildQuery(
      loadOrganizationSemestersQuery,
      { organizationId },
      {
        onMutation: [
          {
            when: "removeSemester",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.removeSemester
              currentResults.organization = organization
              softReset(currentResults)
            },
          },
          {
            when: "createSemester",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.createSemester
              currentResults.organization = organization
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error ocurred while loading academic years"
      heading={<h4>Academic Years</h4>}
    >
      {({ data }) => (
        <>
          <EditableList className="ml-2">
            {data.organization.semesters.map(semester => (
              <motion.li key={semester.id} className="row" positionTransition>
                <Item
                  mutation={removeOrganizationSemesterMutation}
                  mutationProps={{ id: semester.id }}
                  css={{ width: "100%" }}
                >
                  <div css={{ fontWeight: "400" }} className="col-sm-3">
                    {semester.name}
                  </div>
                  <div className="col-sm-6">
                    <LocalTime timestamp={semester.startsOn} omitTime />
                    &nbsp;&mdash;&nbsp;
                    <LocalTime timestamp={semester.endsOn} omitTime />
                  </div>
                </Item>
              </motion.li>
            ))}
          </EditableList>

          <motion.div
            animate={addingSemester ? "visible" : "hidden"}
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  duration: opacityDuration,
                  delay: heightDuration / 2,
                },
              },
              hidden: {
                opacity: 0,
                transition: {
                  duration: opacityDuration,
                },
              },
            }}
          >
            <AnimatePresence>
              {addingSemester && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={{
                    visible: {
                      height: "auto",
                      transition: {
                        duration: heightDuration,
                      },
                    },
                    hidden: {
                      height: 0,
                      transition: {
                        duration: heightDuration,
                        delay: opacityDuration / 2,
                      },
                      transitionEnd: {
                        display: "none",
                      },
                    },
                  }}
                >
                  <NewSemesterForm
                    cancel={() => setAddingSemester(false)}
                    organizationId={organizationId}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>

          <motion.div
            animate={addingSemester ? "hidden" : "visible"}
            variants={{
              hidden: {
                opacity: 0,
                display: "none",
              },
              visible: {
                opacity: 1,
                display: "block",
                transition: {
                  duration: heightDuration,
                  delay: opacityDuration,
                },
              },
            }}
          >
            <button
              onClick={() => setAddingSemester(true)}
              className="btn btn-success"
            >
              Add New
            </button>
          </motion.div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

export default OrganizationSemesters
