import React from "react"

class Timer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: props.timer,
    }
  }

  componentDidMount = () => {
    this.timer = setInterval(() => {
      const updatedTime = this.state.time + 1
      this.setState({ time: updatedTime })
      this.props.timerChanged(updatedTime)
    }, 1000)
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  render() {
    return <div>Duration: {this.state.time} seconds</div>
  }
}

Timer.defaultProps = {
  timer: 0,
}

export default Timer
