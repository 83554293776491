import React from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

export type ConfirmStudyGroupProps = { studyGroupUserId: string }

const ConfirmStudyGroup: React.FC<ConfirmStudyGroupProps> = ({
  studyGroupUserId,
}) => {
  const client = useClient()
  const [result] = useQuery({
    query,
    client,
    variables: { id: studyGroupUserId },
  })

  return (
    <ErrorBoundary name="studyGroupUserConfirm">
      <UrqlLoadingIndicator result={result}>
        {({ data: { viewer, studyGroupUser } }) => (
          <>
            <h1>Confirm Study Group Registration</h1>

            {studyGroupUser?.confirmedAt ? (
              <div>
                Your have successfully confirmed. Click <a href="/">here</a> to
                return to your dashboard.
              </div>
            ) : studyGroupUser ? (
              <Form viewerType={viewer.type} studyGroupUser={studyGroupUser} />
            ) : null}
          </>
        )}
      </UrqlLoadingIndicator>
    </ErrorBoundary>
  )
}

const query = gql`
  query studyGroupUser($id: ID!) {
    viewer {
      id
      type
    }
    studyGroupUser(id: $id) {
      confirmedAt
      studyGroup {
        id
        name
        allAvailabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
        subjects {
          id
          name
        }
        nextSession {
          startsAt
        }
      }
      user {
        ... on Student {
          id
          firstName
        }
      }
    }
  }
`

export default ConfirmStudyGroup
