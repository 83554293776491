import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { css } from "@emotion/core"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateObject, DateRangePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import { RadioField } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  startsAt: Yup.number().required(),
})

const SummaryReport = ({ id }) => {
  const formState = {
    id,
    startsAt: new DateObject(),
    endsAt: new DateObject(),
    schoolId: "",
    reportType: "",
  }
  const { runMutation: exportSummary } = useMutation(
    buildMutation(summaryMutation)
  )
  const { runMutation: exportStudentSubjects } = useMutation(
    buildMutation(studentSubjectsMutation)
  )

  const handleSubmit = (values, actions, closeModal) => {
    const { reportType, startsAt, endsAt, ...rest } = values
    const startMoment = moment(startsAt.toDate()).startOf("day")
    const endMoment = moment(endsAt?.toDate() || startMoment).endOf("day")

    switch (reportType) {
      case "summary":
        exportSummary({
          startsAt: startMoment.format(),
          endsAt: endMoment.format(),
          ...rest,
        }).then(response => {
          const { errorMessages } = response.exportOrganizationSummary
          if (errorMessages) {
            actions.setStatus(errorMessages)
          }
          closeModal()
          NotificationManager.info(
            "Your summary is being built and will be downloaded momentarily"
          )
        })
        break

      case "studentSubjects":
        exportStudentSubjects({
          startsAt: startMoment.format(),
          endsAt: endMoment.format(),
          ...rest,
        }).then(response => {
          const { errorMessages } = response.exportStudentSubjectHours
          if (errorMessages) {
            actions.setStatus(errorMessages)
          }
          closeModal()
          NotificationManager.info(
            "Your student subject report is being built and will be downloaded momentarily"
          )
        })
        break

      default:
        break
    }
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Generate Summary"
      buttonClassName="btn btn-info"
      buttonText="Generate Summary"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ values, status, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Date Range
                    <div
                      css={css`
                        min-height: 50px;
                      `}
                    >
                      <DateRangePicker
                        fixed
                        value={[values.startsAt, values.endsAt]}
                        onChange={([startDate, endDate]) => {
                          setFieldValue("startsAt", startDate)
                          setFieldValue("endsAt", endDate)
                        }}
                      />
                    </div>
                    <ErrorMessage name="startsAt" />
                    <ErrorMessage name="endsAt" />
                  </label>
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    School (optional)
                    <AutocompleteSelect
                      api="/api/admins/schools/autocomplete_name"
                      onChange={selected =>
                        setFieldValue("schoolId", selected?.value)
                      }
                      menuPortalTarget={document.body}
                    />
                  </label>
                </div>

                <RadioField
                  name="reportType"
                  label="Report Type"
                  options={[
                    { label: "Summary", value: "summary" },
                    {
                      label: "Student Subject Hours",
                      value: "studentSubjects",
                    },
                  ]}
                />
              </div>

              <div className="modal-footer">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton isSubmitting={isSubmitting} text="Generate" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const summaryMutation = compress`
  mutation($id: ID!, $startsAt: DateTime!, $endsAt: DateTime!, $schoolId: ID) {
    exportOrganizationSummary(
      organizationId: $id
      startsAt: $startsAt
      endsAt: $endsAt
      schoolId: $schoolId
    ) {
      errorMessages
    }
  }
`

const studentSubjectsMutation = compress`
  mutation($id: ID!, $startsAt: DateTime!, $endsAt: DateTime!, $schoolId: ID) {
    exportStudentSubjectHours(
      organizationId: $id
      startsAt: $startsAt
      endsAt: $endsAt
      schoolId: $schoolId
    ) {
      errorMessages
    }
  }
`

export default SummaryReport
