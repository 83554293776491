import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import axios from "src/axios"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import LoadingIndicator from "components/LoadingIndicator"

import DiagramFormGroup from "./DiagramFormGroup"
import TailoredAnswersFormGroup from "./TailoredAnswersFormGroup"
import TailoredQuestionFormGroup from "./TailoredQuestionFormGroup"

class TailoredQuestionForm extends React.Component {
  constructor() {
    super()
    this.state = {
      percentCompleted: 0,
      formState: {
        type: "text",
        text: "",
        image: {},
        diagram: {},
        tailoredAnswers: [],
        tags: [],
        tailoredQuestionGroupId: "",
      },
    }
  }

  componentDidMount = () => {
    if (this.props.id) {
      axios
        .get(`/api/admins/tailored_questions/${this.props.id}`)
        .then(response => {
          const formState = response.data
          formState.tags = formState.tags.map(tag => ({
            value: tag.id,
            label: tag.name,
          }))
          formState.tailoredQuestionGroupId =
            formState.tailoredQuestionGroup?.id
          this.setState({ formState, loaded: true })
        })
    }
  }

  handleSubmit = (values, actions) => {
    actions.setStatus({ msg: "Submitting..." })
    const {
      type,
      text,
      image,
      diagram,
      tailoredAnswers,
      tags,
      tailoredQuestionGroupId,
    } = values

    let formData = new FormData()
    formData.append("tailored_question[type]", type)
    formData.append("tailored_question[text]", text)
    formData.append(
      "tailored_question[tailored_question_group_id]",
      tailoredQuestionGroupId || ""
    )
    formData.append(
      "tailored_question[tag_list]",
      tags.map(tag => tag.label).join(",")
    )
    if (image && image.name)
      formData.append("tailored_question[image]", image, image.name)
    if (diagram && diagram.image && diagram.image.name)
      formData.append(
        "tailored_question[diagram]",
        diagram.image,
        diagram.image.name
      )
    if (diagram && diagram.remove)
      formData.append("tailored_question[remove_diagram]", true)
    tailoredAnswers.forEach((answer, index) => {
      let formKey = `tailored_question[tailored_answers_attributes][${index}]`
      formData.append(`${formKey}[type]`, answer.type)
      formData.append(`${formKey}[text]`, answer.text)
      formData.append(`${formKey}[correct]`, answer.correct)
      if (answer._destroy === true)
        formData.append(`${formKey}[_destroy]`, true)
      if (answer.id) formData.append(`${formKey}[id]`, answer.id)
      if (answer.image && answer.image.name)
        formData.append(`${formKey}[image]`, answer.image, answer.image.name)
    })

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({ percentCompleted })
      },
    }

    let action
    if (this.props.id) {
      action = axios.patch(
        `/api/admins/tailored_questions/${this.props.id}`,
        formData,
        config
      )
    } else {
      action = axios.post("/api/admins/tailored_questions", formData, config)
    }
    action.then(response => {
      const json = response.data
      if (json.success === true) {
        window.location = json.redirectTo
      } else {
        alert(
          `Unable to save question. Please ensure there are no validation errors and resubmit: ${json.errors.join(
            ", "
          )}`
        )
        actions.setSubmitting(false)
      }
    })
  }

  render() {
    if (this.props.id && !this.state.loaded) return <LoadingIndicator />
    return (
      <div className="row">
        <div className="col">
          <Formik
            initialValues={this.state.formState}
            enableReinitialize={true}
            onSubmit={this.handleSubmit}
            validationSchema={TailoredQuestionSchema}
          >
            {({
              values,
              errors,
              status,
              touched,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <TailoredQuestionFormGroup
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <DiagramFormGroup
                  diagram={values.diagram}
                  setFieldValue={setFieldValue}
                />
                <TailoredAnswersFormGroup
                  tailoredAnswers={values.tailoredAnswers}
                  setFieldValue={setFieldValue}
                />
                <div className="form-group">
                  <label className="form-label">Tags</label>
                  <AutocompleteSelect
                    api="/api/admins/tailored_questions/autocomplete_tags"
                    creatable
                    isMulti
                    value={values.tags}
                    onChange={selected => setFieldValue("tags", selected)}
                  />
                  <ErrorMessage name="tags" />
                </div>

                <div className="form-group">
                  <label className="form-label">Add to Question Group</label>
                  <div className="italic">
                    Note: if you add this question to a group, it will be
                    removed from any existing sections
                  </div>
                  <AutocompleteSelect
                    api="/api/admins/tailored_question_groups/autocomplete_internal_name"
                    onChange={selected =>
                      setFieldValue(
                        "tailoredQuestionGroupId",
                        selected ? selected.value : ""
                      )
                    }
                    selected={
                      values.tailoredQuestionGroup
                        ? {
                            value: values.tailoredQuestionGroup?.id,
                            label: values.tailoredQuestionGroup?.internalName,
                          }
                        : null
                    }
                  />
                </div>

                <div className="row">
                  <div className="col-1">
                    <SubmitButton isSubmitting={isSubmitting} />
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    {status && status.msg && <div>{status.msg}</div>}
                  </div>
                </div>

                {isSubmitting && (
                  <div>
                    <progress value={this.state.percentCompleted} max="100" />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

const TailoredQuestionSchema = Yup.object().shape({
  text: Yup.string().when("type", {
    is: "text",
    then: Yup.string().required("Question text is required"),
  }),
  image: Yup.mixed().when("type", {
    is: "image",
    then: Yup.mixed().test(
      "question-image",
      "Question image must be present",
      image => {
        return image.name || image.fileName
      }
    ),
  }),
  tailoredAnswers: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().when(["type", "_destroy"], {
          is: (type, destroy) => !destroy && type === "text",
          then: Yup.string().required("Answer text is required"),
        }),
        image: Yup.mixed().when(["type", "_destroy"], {
          is: (type, destroy) => !destroy && type === "image",
          then: Yup.mixed().test(
            "answer-image",
            "Answer image must be present",
            image => {
              return image.name || image.fileName
            }
          ),
        }),
      })
    )
    .test(
      "min-length",
      "At least 1 answer is required",
      tailoredAnswers => tailoredAnswers.filter(a => !a._destroy).length > 0
    )
    .test(
      "has-correct",
      "Exactly one answer must be marked as correct",
      tailoredAnswers =>
        tailoredAnswers
          .map(a => !a._destroy && a.correct)
          .filter(correct => correct === true).length === 1
    ),
  tags: Yup.array().of(Yup.string()).min(1, "At least one tag is required"),
})

export default TailoredQuestionForm
