import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const TagSearch = ({ setSearchParams }) => {
  const tagsChanged = selected => {
    setSearchParams({ tagList: selected.map(s => s.label) })
  }

  return (
    <div className="form-group">
      <label className="form-label w-100">
        Search by tag
        <AutocompleteSelect
          menuPortalTarget={document.body}
          api="/api/admins/tutors/autocomplete_tags"
          isMulti
          onChange={selected => {
            selected && tagsChanged(selected)
          }}
        />
      </label>
    </div>
  )
}

export default TagSearch
