import React from "react"

import { css } from "@emotion/core"
import {
  faCheck,
  faFrown,
  faMeh,
  faMinus,
  faSmile,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

const LessonPlan = ({ lessonPlanAssignment }) => {
  const { lessonPlan } = lessonPlanAssignment

  const RatingIcon = ({ value }) => {
    let icon
    const classNames = []
    if (value === "weak") {
      icon = faFrown
      classNames.push("text-danger red-text")
    }
    if (value === "moderate") {
      icon = faMeh
      classNames.push("text-warning orange-text")
    }
    if (value === "strong") {
      icon = faSmile
      classNames.push("text-success green-text")
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        className={classNames.join(" ")}
        css={css`
          font-size: 36px;
        `}
      />
    )
  }

  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <ol
        css={css`
          display: inline-block;
          text-align: left;
        `}
      >
        {lessonPlan.lessonTopics.map(lessonTopic => (
          <React.Fragment key={lessonTopic.id}>
            <li
              css={css`
                list-style: upper-alpha;
              `}
            >
              <h2
                css={css`
                  font-weight: 500;
                  margin-left: 12px;
                `}
              >
                {lessonTopic.name}
              </h2>
              <ol css={css``}>
                {lessonTopic.lessonResources.map(lessonResource => (
                  <li
                    key={lessonResource.id}
                    css={css`
                      list-style: decimal;
                      margin-left: 66px;
                      padding-left: 12px;
                    `}
                    className="mb-3"
                  >
                    {lessonResource.completed ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success green-text fa-fw mr-3"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning orange-text fa-fw mr-3"
                      />
                    )}
                    <h3
                      className="d-inline-block"
                      css={css`
                        margin: 0;
                      `}
                    >
                      {lessonResource.name}
                    </h3>
                    {lessonResource.current && (
                      <button className="btn btn-sm btn-success solid small green ml-3">
                        Current
                      </button>
                    )}
                    <ul>
                      {lessonResource.lessonResourceCompletions &&
                        ordered(
                          lessonResource.lessonResourceCompletions,
                          "session",
                          "startsAt"
                        ).map(completion => (
                          <li
                            key={completion.id}
                            css={css`
                              list-style: none;
                            `}
                          >
                            <a href={completion.session.showPath}>
                              Session with {completion.session.tutor?.fullName}{" "}
                              on{" "}
                              <LocalTime
                                timestamp={completion.session.startsAt}
                                omitTime
                              />
                            </a>
                            <ul
                              className="list-unstyled"
                              css={css`
                                li {
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin-bottom: 15px;
                                }
                              `}
                            >
                              {completion.studentMastery && (
                                <li>
                                  <span>Student</span>
                                  <RatingIcon
                                    value={completion.studentMastery}
                                  />
                                </li>
                              )}
                              {completion.studentTopicMasteryStart && (
                                <li>
                                  <span>Student Chapter Start</span>
                                  <RatingIcon
                                    value={completion.studentTopicMasteryStart}
                                  />
                                </li>
                              )}
                              {completion.studentTopicMasteryEnd && (
                                <li>
                                  <span>Student Chapter End</span>
                                  <RatingIcon
                                    value={completion.studentTopicMasteryEnd}
                                  />
                                </li>
                              )}
                              {completion.tutorMastery && (
                                <li>
                                  <span>Tutor</span>
                                  <RatingIcon value={completion.tutorMastery} />
                                </li>
                              )}
                              {completion.tutorTopicMastery && (
                                <li>
                                  <span>Tutor Chapter</span>
                                  <RatingIcon
                                    value={completion.tutorTopicMastery}
                                  />
                                </li>
                              )}
                            </ul>
                            <hr />
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              </ol>
            </li>
          </React.Fragment>
        ))}
      </ol>
    </div>
  )
}

export default LessonPlan
