import React, { useRef } from "react"

import {
  buildMutation,
  buildQuery,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import PropTypes from "prop-types"

import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import linkStudentMutation from "./linkStudentMutation"
import loadStudentsQuery from "./loadStudentsQuery"
import unlinkStudentMutation from "./unlinkStudentMutation"

const AdminParentStudents = props => {
  const { parentId, newStudentPath } = props
  const selectRef = useRef(null)

  const loadingState = useQuery(
    buildQuery(
      loadStudentsQuery,
      { parentId },
      {
        onMutation: [
          {
            when: "linkStudent",
            run: ({ softReset, currentResults }, response) => {
              const linkedStudent = response.linkStudent.student
              currentResults.parent.students.push(linkedStudent)
              softReset(currentResults)
            },
          },
          {
            when: "unlinkStudent",
            run: ({ softReset, currentResults }, response) => {
              const removedStudent = response.unlinkStudent.student
              const newStudents = currentResults.parent.students.filter(
                student => student.id !== removedStudent.id
              )
              currentResults.parent.students = newStudents
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const { runMutation: runLinkStudentMutation } = useMutation(
    buildMutation(linkStudentMutation)
  )

  const { runMutation: runUnlinkStudentMutation } = useMutation(
    buildMutation(unlinkStudentMutation)
  )

  const studentSelected = option => {
    if (!option?.value) return
    const studentId = option.value
    runLinkStudentMutation({ parentId, studentId }).then(() => {
      selectRef.current.clearValue()
    })
  }

  const unlinkStudent = studentId => {
    runUnlinkStudentMutation({ parentId, studentId })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading student subjects"
      heading={<h4>Students</h4>}
    >
      {({ data }) => (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <table className="table">
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Subjects</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ordered(data.parent.students, "fullName").map(student => (
                    <tr key={student.id}>
                      <td>{student.fullName}</td>
                      <td>{student.email}</td>
                      <td>
                        {ordered(student.subjects, "name")
                          .map(s => s.name)
                          .join(", ")}
                      </td>
                      <td className="button-cell">
                        <a
                          href={student.showPath}
                          className="btn btn-sm btn-info"
                        >
                          Show
                        </a>
                        <a href={student.editPath} className="btn btn-sm">
                          Edit
                        </a>
                        <button
                          onClick={() => unlinkStudent(student.id)}
                          className={`btn btn-sm btn-danger`}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <AutocompleteSelect
                ref={selectRef}
                onChange={studentSelected}
                api={"/api/admins/students/autocomplete_full_name"}
                searchOptions={"no_parent=true"}
                placeholder="Link existing student"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <a href={newStudentPath} className="btn btn-success">
                Add New Student
              </a>
            </div>
          </div>
        </React.Fragment>
      )}
    </WithLoadingIndicator>
  )
}

AdminParentStudents.propTypes = {
  parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  newStudentPath: PropTypes.string.isRequired,
}

export default AdminParentStudents
