import React, { useState } from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import airbrake from "src/airbrake-notifier"
import { studentAssessmentOptionsWithIcons } from "src/enums"
import { studentClient as client } from "src/urql-client"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  RadioField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  studentAssessment: Yup.string().required("Please select an option"),
})

const StudentAssessmentModal = ({ sessionId, isOpen, onClose }) => {
  const formState = { sessionId, studentAssessment: "" }
  const [complete, setComplete] = useState(false)
  const [, submitAssessment] = useMutation(studentAssessmentMutation, client)

  const handleSubmit = (values, actions) => {
    submitAssessment(values)
      .then(
        result => {
          if (result.error) {
            airbrake.notify({
              error: result.error,
              name: "StudentAssessmentModal#handleSubmit",
              params: {
                message: result.error?.message,
              },
            })
            handleFailure(actions)
            return
          }

          const { success, errorMessages } = result.data.submitStudentAssessment
          if (errorMessages) {
            handleFailure(
              actions,
              errorMessages.map(message => ({ message }))
            )
            return
          }
          if (success) {
            handleFailure(actions, [
              {
                type: "info",
                message: "Thanks so much!",
              },
            ])
            setTimeout(onClose, 3000)
            setComplete(true)
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="How is your session going?"
      closeModal={onClose}
      afterOpenModal={() => setComplete(false)}
      hideCloseButton
    >
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        <Form>
          <div className="modal-body">
            <RadioField
              name="studentAssessment"
              options={studentAssessmentOptionsWithIcons}
            />
          </div>
          <div className="modal-footer flex-col">
            <FormFailures />
            {!complete ? <SubmitButton text="Submit Feedback" /> : null}
          </div>
        </Form>
      </Formik>
    </ModalWithProvidedBody>
  )
}

const studentAssessmentMutation = gql`
  mutation ($sessionId: ID!, $studentAssessment: StudentAssessmentEnum!) {
    submitStudentAssessment(
      sessionId: $sessionId
      studentAssessment: $studentAssessment
    ) {
      success
      errorMessages
    }
  }
`

export default StudentAssessmentModal
