import React, { useState } from "react"

import { Field, Form, Formik } from "formik"
import PropTypes from "prop-types"
import * as Yup from "yup"

import { ErrorMessage } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required(
    "Please provide a cancellation reason"
  ),
})

const CancelSessionButton = props => {
  const { id, runMutation } = props
  const [errorMessage, setErrorMessage] = useState()
  const initialFormState = {
    cancellationReason: "",
  }

  const handleSubmit = (values, actions) => {
    actions.setStatus("Submitting...")
    runMutation({ id, cancellationReason: values.cancellationReason }).then(
      response => {
        if (response.cancelAirtutorsSession.success) {
          window.location.reload()
        } else {
          actions.setStatus(null)
          actions.setSubmitting(false)
          setErrorMessage(response.cancelAirtutorsSession.errorMessage)
        }
      }
    )
  }

  return (
    <ModalWithProvidedBody
      buttonClassName={`btn solid btn-danger ${
        props.size === "normal" ? "" : "small"
      } red`}
      buttonText="Cancel"
      modalClassName="bootstrap-modal"
      modalTitle="Cancel Session"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <Formik
            initialValues={initialFormState}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ status, isSubmitting }) => (
              <Form>
                <div className="modal-body">
                  {errorMessage && (
                    <p className="alert alert-danger">{errorMessage}</p>
                  )}
                  <p>
                    Are you sure you want to cancel this session? This action is
                    not reversible.
                  </p>
                  <label className="d-block">
                    Cancellation Reason
                    <Field
                      component="textarea"
                      className="form-control"
                      name="cancellationReason"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage name="cancellationReason" />
                  </label>
                </div>
                <div className="modal-footer" style={{ flexFlow: "column" }}>
                  <div className="w-100 d-flex justify-content-between">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn solid red btn-danger"
                    >
                      Yes, cancel it!
                    </button>
                    <button
                      className="btn solid blue btn-info"
                      onClick={closeModal}
                    >
                      No, I'll keep it
                    </button>
                  </div>

                  {status && <div>{status}</div>}
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

CancelSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  runMutation: PropTypes.func.isRequired,
}

export default CancelSessionButton
