import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Pagination from "components/Pagination"
import TailoredQuestionPreview from "components/TailoredAssignmentPreview/TailoredQuestionPreview"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TailoredQuestionsIndex = () => {
  const [page, setPage] = useState(1)
  const [tags, setTags] = useState([])

  const [result] = useQuery({
    query: questionsQuery,
    variables: { page, tagList: tags.map(tag => tag.label) },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { tailoredQuestions } }) => (
        <>
          <div className="row">
            <div className="col-8">
              <a
                className="btn btn-success"
                href="/admin/tailored_questions/new"
              >
                New Question
              </a>
              <a
                className="btn btn-success"
                href="/admin/tailored_question_groups/new"
              >
                New Question Group
              </a>
              <a
                className="btn btn-info"
                href="/admin/tailored_question_groups"
              >
                View Question Groups
              </a>
            </div>
            <div className="col-4">
              <div>
                <label className="form-label">Tags</label>
                <AutocompleteSelect
                  api="/api/admins/tailored_questions/autocomplete_tags"
                  isMulti
                  value={tags}
                  onChange={selected => setTags(selected)}
                />
              </div>
            </div>
          </div>

          {tailoredQuestions.data.map(question => (
            <TailoredQuestionPreview
              key={question.id}
              question={question}
              editable
            />
          ))}
          <hr />
          <Pagination
            totalPages={tailoredQuestions.totalPages}
            currentPage={tailoredQuestions.currentPage}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const questionsQuery = gql`
  query ($page: Int, $tagList: [String!]) {
    tailoredQuestions(page: $page, tagList: $tagList) {
      currentPage
      totalPages
      data {
        id
        tags {
          id
          name
        }
        editPath
        type
        text
        image {
          url
          filename
        }
        diagram {
          url
          filename
        }
        tailoredAnswers {
          id
          type
          text
          correct
          image {
            url
            filename
          }
        }
      }
    }
  }
`

export default TailoredQuestionsIndex
