import React, { useState } from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type TailoredAssignmentsProps = {
  studentId: string
}

const TailoredAssignments: React.FC<TailoredAssignmentsProps> = ({
  studentId,
}) => {
  const [page, setPage] = useState(1)
  const client = useClient()
  const [result] = useQuery({
    query: assignmentsQuery,
    variables: { studentId, page },
    client,
  })

  return (
    <UrqlLoadingIndicator
      result={result}
      heading={<h2>Tailored Assignments</h2>}
    >
      {({ data }) => (
        <React.Fragment>
          <ul>
            {data.tailoredAssignmentSummaries.data.map(summary => (
              <li key={summary.tailoredAssignment.id}>
                <span>{summary.tailoredAssignment.name}</span>
                <span className="mx-[5px]">|</span>
                <a href={summary.resultsPath}>Section Results</a>
                <span className="mx-[5px]">|</span>
                {summary.aasmState === "initial" && (
                  <span>Score: Not Started</span>
                )}
                {summary.aasmState === "in_progress" && (
                  <span>Score: In Progress</span>
                )}
                {summary.aasmState === "completed" && (
                  <span>Score: {(summary.score * 100).toFixed(2)}%</span>
                )}
              </li>
            ))}
          </ul>
          <Pagination
            currentPage={page}
            totalPages={data.tailoredAssignmentSummaries.totalPages}
            changePage={page => setPage(page)}
          />
        </React.Fragment>
      )}
    </UrqlLoadingIndicator>
  )
}

const assignmentsQuery = gql`
  query TailoredAssignmentsQuery($studentId: ID!, $page: Int!) {
    tailoredAssignmentSummaries(studentId: $studentId, page: $page) {
      totalPages
      data {
        score
        aasmState
        resultsPath
        tailoredAssignment {
          id
          name
        }
      }
    }
  }
`

export default TailoredAssignments
