import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { publicClient as client } from "src/graphql-config"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import Form from "./Form"

const StudentSimpleIntakeContainer = ({
  organization: { id: organizationId, name: organizationName },
}) => {
  const loadingState = useQuery(
    buildQuery(studentSimpleIntakeQuery, { organizationId }, { client })
  )
  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => <Form data={data} />}
    </WithLoadingIndicator>
  )
}

const studentSimpleIntakeQuery = compress`
  query($organizationId: ID!) {
    grades {
      id
      name
      number
    }
    organization(id: $organizationId) {
      id
      simpleIntakeBlurb
      allowedDomains
      intakeParentRequired
      emailTimeZone
      consentText
      parentConsentRequired
      studentConsentRequired
      consentDocuments {
        id
        downloadUrl
        filename
      }
      schools {
        id
        name
        permittedGrades {
          id
        }
      }
    }
  }
`

StudentSimpleIntakeContainer.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default StudentSimpleIntakeContainer
