import React from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import LocalTime from "components/LocalTime"

import Reprocess from "./Reprocess"

import "react-table/react-table.css"

const BulkActionsTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/study_groups/bulk_actions",
  })

  const columns = [
    {
      id: "user",
      Header: "Admin",
      accessor: "user",
      sortable: false,
      filterable: false,
    },
    {
      id: "bulk_action_created_at",
      Header: "Created",
      accessor: "created_at",
      filterable: false,
      Cell: props => <LocalTime timestamp={props.value} />,
    },
    {
      id: "type",
      Header: "Action",
      accessor: "type",
      filterable: false,
      sortable: false,
    },
    {
      id: "state",
      Header: "State",
      accessor: "state",
      filterable: false,
      sortable: false,
    },
    {
      id: "error_messages",
      Header: "Errors",
      accessor: "error_messages",
      filterable: false,
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {props.value.map(errorMessage => (
            <li key={JSON.stringify(errorMessage)}>
              <ul className="list-unstyled my-3">
                {Object.keys(errorMessage).map(key => (
                  <li key={key} className="mb-5 whitespace-normal">
                    {key === "study_group_id" ? (
                      <a
                        href={`/admin/study_groups/${errorMessage[key]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Group
                      </a>
                    ) : (
                      <>
                        {key}:{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: errorMessage[key],
                          }}
                          className="rounded-md bg-red-500 p-2 text-white"
                        />
                      </>
                    )}
                  </li>
                ))}
                <hr />
              </ul>
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "arguments",
      Header: "Settings",
      accessor: "arguments",
      filterable: false,
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {Object.keys(props.value).map(key => (
            <li key={key}>
              {key}: {props.value[key]}
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: "actions",
      filterable: false,
      sortable: false,
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          {props.original.state === "failed" && (
            <Reprocess bulkActionId={props.original.id} />
          )}
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default BulkActionsTable
