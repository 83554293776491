import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import Generation from "./Shared/Generation"
import { studyGroupFields, teacherSubjectFields } from "./Shared/fragments"

const updateResults = ({ currentResults, studyGroup, teacherSubject }) => {
  if (studyGroup) {
    const groupIndex =
      currentResults.studyGroupGeneration.studyGroups.findIndex(
        g => g.id === studyGroup.id
      )
    currentResults.studyGroupGeneration.studyGroups[groupIndex] = studyGroup
  }

  if (teacherSubject) {
    currentResults.studyGroupGeneration.teacherSubject = teacherSubject
  }

  return currentResults
}

const StudyGroupGenerationDetails = ({ studyGroupGenerationId }) => {
  const loadingState = useQuery(
    buildQuery(
      studyGroupGenerationQuery,
      { id: studyGroupGenerationId },
      {
        onMutation: [
          {
            when: "generatedStudyGroupAddStudent",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject } =
                response.generatedStudyGroupAddStudent

              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  teacherSubject,
                })
              )
            },
          },
          {
            when: "generatedStudyGroupRemoveStudent",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject } =
                response.generatedStudyGroupRemoveStudent

              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  teacherSubject,
                })
              )
            },
          },
          {
            when: "studyGroupAddUser",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupAddUser
              softReset(updateResults({ currentResults, studyGroup }))
            },
          },
          {
            when: "studyGroupRemoveUser",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupRemoveUser
              softReset(updateResults({ currentResults, studyGroup }))
            },
          },
          {
            when: "createGeneratedStudyGroup",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroupGeneration } =
                response.createGeneratedStudyGroup
              currentResults.studyGroupGeneration.studyGroups =
                studyGroupGeneration.studyGroups
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupRemove",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject } = response.studyGroupRemove
              currentResults.studyGroupGeneration.studyGroups =
                currentResults.studyGroupGeneration.studyGroups.filter(
                  g => g.id !== studyGroup.id
                )
              currentResults.studyGroupGeneration.teacherSubject =
                teacherSubject
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Generation
          generation={data.studyGroupGeneration}
          organizationId={data.studyGroupGeneration.organization.id}
        />
      )}
    </WithLoadingIndicator>
  )
}

const studyGroupGenerationQuery = compress`
  ${studyGroupFields}
  ${teacherSubjectFields}
  query($id: ID!) {
    studyGroupGeneration(id: $id) {
      id
      minimumGroupSize
      maximumGroupSize
      sessionsPerWeek
      excusedAbsencesEnabled
      requestStudyGroupConfirmations
      canExceedScheduledDuration
      inSchool
      allowLongSessions
      startsOn
      endsOn
      maximumScoreDeviation
      assessmentSection {
        id
        name
        assessment {
          id
          name
        }
      }
      organization {
        id
      }
      studyGroups {
        ...StudyGroupFields
      }
      teacherSubject {
        ...TeacherSubjectFields
      }
    }
  }
`

export default StudyGroupGenerationDetails
