import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
})

const NewCollege = () => {
  const formState = { name: "", diplomaMill: false }
  const [, createCollege] = useMutation(createCollegeMutation)

  const handleSubmit = (values, actions) => {
    createCollege(values).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { failures } = result.data.createCollege
      if (failures) {
        handleFailure(actions, failures)
        return
      } else {
        window.location.reload()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      buttonText="New College"
      buttonClassName="btn btn-info"
      modalTitle="New College"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field name="name" label="Name" />
              <CheckboxField name="diplomaMill" label="Diploma Mill?" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />

              <div className="w-100 flex justify-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createCollegeMutation = gql`
  mutation ($name: String!, $diplomaMill: Boolean!) {
    createCollege(name: $name, diplomaMill: $diplomaMill) {
      failures {
        message
      }
      college {
        id
      }
    }
  }
`

export default NewCollege
