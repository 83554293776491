import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import Tutor from "./Tutor"

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required(),
})

const StatementTutorsWithUnbilledSessions = ({ tutorsWithCount }) => {
  const formState = {
    message: `[URGENT] YOU HAVE UNBILLED SESSIONS. PLEASE CLICK THE FOLLOWING LINK TO BILL THEM. https://app.airtutors.org/tutor/airtutors_sessions`,
  }
  const [, sendSms] = useMutation(sendSmsMutation)
  const handleSubmit = (values, actions, closeModal) => {
    sendSms({
      tutorIds: tutorsWithCount.map(g => g.tutor.id),
      message: values.message,
    })
      .then(
        response => {
          const { failures } = response.data.sendSms
          if (failures) {
            handleFailure(actions, failures)
          } else {
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <>
      <h4>Tutors with Unbilled Sessions</h4>
      <ModalWithProvidedBody
        modalTitle="Text Unbilled Tutors"
        buttonClassName="btn btn-info"
        buttonText="Send text to all unbilled tutors"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <p className="alert bg-purple-500 font-medium text-white">
                  This will send a text message to {tutorsWithCount.length}{" "}
                  tutors
                </p>
                <TextAreaField name="message" label="Message" />
              </div>
              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <SubmitButton text="Send Text" />
                  <button className="btn btn-danger" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
      <table className="table">
        <thead className="text-primary">
          <tr>
            <th>Tutor</th>
            <th>Unbilled Count</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {tutorsWithCount.map(({ tutor, count }) => (
            <Tutor key={tutor.id} tutor={tutor} count={count} />
          ))}
        </tbody>
      </table>
    </>
  )
}

const sendSmsMutation = gql`
  mutation ($tutorIds: [ID!]!, $message: String!) {
    sendSms(userIds: $tutorIds, message: $message) {
      failures {
        message
      }
    }
  }
`

export default StatementTutorsWithUnbilledSessions
