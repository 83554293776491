import React from "react"

const File = props => {
  const { setFieldValue, ...inputProps } = props
  return (
    <input
      type="file"
      onChange={event =>
        setFieldValue(inputProps.name, event.currentTarget.files[0])
      }
      {...inputProps}
    />
  )
}

export default File
