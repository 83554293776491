import React from "react"

import Form from "~tailwindui/Form"

import classNames from "src/classNames"

export type TextInputProps = {
  label: string
  placeholder?: string
  name: string
  description?: string
  error?: string
  subfield?: boolean
}

const TextInput: React.FC<
  TextInputProps & React.HTMLProps<HTMLInputElement>
> = ({
  label,
  name,
  error = "",
  description = "",
  placeholder = "",
  subfield = false,
  ...props
}) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label
        htmlFor={name}
        className={classNames(
          subfield && "ml-8",
          "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        )}
      >
        {label}
      </label>

      <div
        className={classNames(
          subfield && "ml-8",
          error && "relative rounded-md shadow-sm"
        )}
      >
        <input
          type="text"
          name={name}
          id={name}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          aria-describedby={`${name}-description`}
          {...props}
        />
        {error && <Form.ErrorIcon />}
      </div>
      {error ? (
        <Form.FieldError message={error} />
      ) : (
        <Form.FieldDescription description={description} />
      )}
    </div>
  )
}

export default TextInput
