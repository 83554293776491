import { compress } from "micro-graphql-react"

export default compress`
  query {
    referralSources {
      label
      value
    }
  }
`
