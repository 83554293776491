import React from "react"

export type ConsistentTutorWarningProps = Record<string, never>

const ConsistentTutorWarning: React.FC<ConsistentTutorWarningProps> = ({
  minTutorConsistencyRate,
  maxSubsEstimate,
}) => {
  return (
    <div className="alert alert-danger mb-4 p-3">
      <span>
        WARNING: This group requires a commitment to attend{" "}
        {minTutorConsistencyRate}% of all sessions. If you accept this group,
        you will only be allowed to request a substitute approximately{" "}
        {maxSubsEstimate} time(s). (Note that this is an estimate. You will be
        given the exact number after registration.) Please think carefully
        before accepting this group.
      </span>
      <br />
    </div>
  )
}

export default ConsistentTutorWarning
