import React from "react"

import { css } from "@emotion/core"
import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = props => {
  const { styles, muted, disabled, ...rest } = props
  return (
    <button
      css={css`
        width: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        border: none;
        transition: all 0.5s;
        padding: 0.25rem;
        border-radius: 0.15rem;
        outline: none;
        background-color: ${muted
          ? "rgba(236, 50, 85, 0.75)"
          : "rgba(53, 105, 192, 0.75)"};
        ${disabled && "background-color: #ccc; cursor: not-allowed;"}
        ${styles}
      `}
      {...rest}
    >
      <FontAwesomeIcon icon={muted ? faVideoSlash : faVideo} />
    </button>
  )
}

export default Button
