import React from "react"

import { useField } from "formik"

import ErrorMessage from "./ErrorMessage"

const Field = props => {
  const [field, meta] = useField(props)
  const { labelClassName, ...rest } = props

  return (
    <div className="form-group">
      <label className={`form-label w-100 ${labelClassName}`}>
        <span>{props.label}</span>
        <input className="form-control" {...field} {...rest} />
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default Field
