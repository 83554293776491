import React from "react"

import { Form, Formik } from "formik"

import ordered from "src/ordered"

import {
  CheckboxField,
  FormStatus,
  RadioField,
  SelectAll,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

const SessionResults = ({ sessions, addRecipients, closeModal }) => {
  const formState = {
    sessionIds: [],
    source: "student",
  }

  const handleSubmit = values => {
    const { sessionIds } = values

    const selectedSessions = sessions.filter(session =>
      sessionIds.includes(session.id)
    )
    const selectedStudents = selectedSessions
      .map(session => session.students)
      .flat()
    const selectedTutors = selectedSessions
      .map(session => session.tutor)
      .filter(tutor => tutor)

    if (values.source === "student") {
      addRecipients(selectedStudents, "phoneNumber", "parentPhoneNumber")
    } else if (values.source === "parent") {
      addRecipients(selectedStudents, "parentPhoneNumber")
    } else if (values.source === "tutor") {
      addRecipients(selectedTutors, "Session Search", "phoneNumber")
    }
    closeModal()
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="modal-body">
          {sessions.length === 0 ? (
            <p className="lead">No results</p>
          ) : (
            <>
              <SelectAll fieldName="sessionIds" items={sessions} />
              <RadioField
                name="source"
                options={[
                  { label: "Use Student Phone", value: "student" },
                  { label: "Use Parent Phone", value: "parent" },
                  { label: "Use Tutor Phone", value: "tutor" },
                ]}
              />
              <hr />

              <ul className="list-unstyled">
                {ordered(sessions, "startsAt").map(session => (
                  <li key={session.id}>
                    <CheckboxField
                      name="sessionIds"
                      value={session.id}
                      label={
                        <>
                          <LocalTime timestamp={session.startsAt} />
                          <br />
                          Subject: {session.subject.name}
                          <br />
                          Tutor: {session.tutor?.fullName}
                          <br />
                          Students:
                          <ul className="list-unstyled">
                            {session.students.map(student => (
                              <li key={student.id}>{student.fullName}</li>
                            ))}
                          </ul>
                        </>
                      }
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        <div className="modal-footer flex-column">
          <FormStatus />
          <SubmitButton text="Add Selected Users" />
        </div>
      </Form>
    </Formik>
  )
}

export default SessionResults
