import React from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { ErrorMessage, RadioGroup } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ValidationSchema = Yup.object().shape({
  actualDuration: Yup.string().required("Please specify a duration"),
})

const BillSessionButton = ({ id }) => {
  const loadingState = useQuery(buildQuery(sessionStudentsQuery, { id }))
  const { runMutation } = useMutation(buildMutation(billSessionMutation))
  const buildFormState = data => ({
    actualDuration: data.airtutorsSession.roundedActualDuration || "",
    noShowStudentIds: [],
  })

  const handleSubmit = (values, actions) => {
    runMutation({ id, ...values })
      .then(
        response => {
          const { success, errorMessages } = response.billAirtutorsSession
          if (success) {
            window.location.reload()
          } else if (errorMessages) {
            actions.setStatus(errorMessages)
            actions.setSubmitting(false)
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-warning"
      buttonText="Bill"
      modalTitle="Bill Session"
    >
      {({ closeModal }) => (
        <WithLoadingIndicator loadingState={loadingState}>
          {({ data }) => (
            <Formik
              initialValues={buildFormState(data)}
              onSubmit={handleSubmit}
              validationSchema={ValidationSchema}
            >
              {({ status, isSubmitting, values }) => (
                <Form>
                  <div className="modal-body">
                    {data.airtutorsSession.sessionIncomplete && (
                      <p className="inline-block font-medium italic text-red-500">
                        Note: This session was not ended properly (using the
                        "End Session" button), so the duration shown may not be
                        correct.
                      </p>
                    )}
                    <div className="form-group">
                      <label className="form-label">
                        Actual Duration
                        <Field name="actualDuration" className="form-control" />
                      </label>
                      <div>
                        <ErrorMessage name="actualDuration" />
                      </div>
                    </div>
                    <p>
                      Enter as hours:minutes, i.e. 1:30 for 1 hour and 30
                      minutes.
                      <br />
                      Sessions will be billed to the closest 15 minute
                      increment: i.e., a 22 minute session will be billed as 15
                      minutes.
                    </p>
                    <h4>No Show Students</h4>
                    <FieldArray name="noShowStudentIds">
                      {arrayHelpers => (
                        <RadioGroup
                          type="checkbox"
                          name="noShowStudentIds"
                          values={data.airtutorsSession.studentSessions.map(
                            studentSession => ({
                              value: studentSession.student.id,
                              label: studentSession.student.fullName,
                            })
                          )}
                          onChange={e => {
                            if (e.currentTarget.checked) {
                              arrayHelpers.push(e.currentTarget.value)
                            } else {
                              arrayHelpers.remove(
                                values.noShowStudentIds.findIndex(
                                  s => s === e.currentTarget.value
                                )
                              )
                            }
                          }}
                        />
                      )}
                    </FieldArray>
                  </div>

                  <div className="modal-footer flex-column">
                    {status && (
                      <div className="alert alert-danger">{status}</div>
                    )}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-success"
                    >
                      Bill
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </WithLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const sessionStudentsQuery = compress`
  query($id: ID!) {
    airtutorsSession(id: $id) {
      roundedActualDuration
      sessionIncomplete
      studentSessions {
        id
        noShow
        student {
          id
          fullName
        }
      }
    }
  }
`

const billSessionMutation = compress`
  mutation($id: ID!, $actualDuration: String!, $noShowStudentIds: [ID!]!) {
    billAirtutorsSession(id: $id, actualDuration: $actualDuration, noShowStudentIds: $noShowStudentIds) {
      success
      errorMessages
    }
  }
`

export default BillSessionButton
