import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"

import { useDisclosure } from "@chakra-ui/core"

import { tutorClient } from "src/graphql-config"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const DeclineSessionButton = props => {
  const { id } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [errorMessage, setErrorMessage] = useState()

  const { runMutation } = useMutation(
    buildMutation(declineSessionMutation, { client: tutorClient })
  )

  const declineSession = () => {
    runMutation({ id }).then(response => {
      if (response.declineAirtutorsSession.success) {
        window.location.reload()
      } else {
        setErrorMessage(response.declineAirtutorsSession.errorMessage)
        onOpen()
      }
    })
  }

  return (
    <>
      <ModalWithProvidedBody
        isOpen={isOpen}
        closeModal={onClose}
        modalClassName="bootstrap-modal"
        modalTitle="Decline Failed"
        hideTrigger
      >
        {({ closeModal }) => (
          <React.Fragment>
            <div className="modal-body">
              <p className="lead">{errorMessage}</p>
            </div>
          </React.Fragment>
        )}
      </ModalWithProvidedBody>

      <button className="btn solid small red" onClick={declineSession}>
        Decline
      </button>
    </>
  )
}

const declineSessionMutation = compress`
  mutation($id: ID!) {
    declineAirtutorsSession(id: $id) {
      success
      errorMessage
    }
  }
`

DeclineSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default DeclineSessionButton
