import React, { useLayoutEffect, useRef } from "react"

import { Form, Formik } from "formik"
import { AnimatePresence, motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { studentClient as client } from "src/graphql-config"

import { SubmitButton } from "components/Forms/Formik/hookComponents"

const Wizard = ({ data, closeModal, FormComponent, step, setStep }) => {
  const ValidationSchema = Yup.object().shape({
    gradeId: Yup.string().required("Please select a grade"),
    schoolId: Yup.string().test(
      "schoolId",
      "Please select a school",
      schoolId => {
        if (data.organization?.schools) {
          return !!schoolId
        } else {
          return true
        }
      }
    ),
    schoolName: Yup.string().test(
      "schoolName",
      "Please enter a school",
      schoolName => {
        if (data.organization?.schools) {
          return true
        } else {
          return schoolName && schoolName.trim().length > 0
        }
      }
    ),
    availabilityIds: Yup.array().test(
      "availabilityIds",
      `Please select at least ${data.organization?.minimumAvailabilityWindows} availabilities`,
      selectedAvailabilityIds => {
        if (
          !data.organization?.minimumAvailabilityWindows ||
          data.organization?.availabilitySets?.length === 0
        ) {
          return true
        } else {
          return (
            selectedAvailabilityIds.length >=
            data.organization.minimumAvailabilityWindows
          )
        }
      }
    ),
  })

  const formState = {
    gradeId: data.viewer.grade?.id,
    schoolId: data.viewer.school?.id,
    schoolName: "",
    subjectIds: data.viewer.subjects.map(subject => subject.id),
    teacherSubjectIds: data.viewer.teacherSubjects.map(
      teacherSubject => teacherSubject.id
    ),
    availabilityIds: data.viewer.availabilities.map(
      availability => availability.id
    ),
    phoneNumber: data.viewer.phoneNumber,
    parentPhoneNumber: data.viewer.parentPhoneNumber,
  }

  const { runMutation } = useMutation(
    buildMutation(updateStudentMutation, { client })
  )

  const bodyContainer = useRef()
  useLayoutEffect(() => {
    if (bodyContainer.current) {
      bodyContainer.current.scrollTo(0, 0)
    }
  }, [step])

  const handleSubmit = (values, actions) => {
    runMutation({ input: values })
      .then(
        response => {
          const { errorMessages } = response.profileUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  if (!FormComponent) return null

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div
          className="modal-body"
          css={css`
            height: 50vh;
          `}
          ref={bodyContainer}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={FormComponent.step}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: -100 },
                leave: { opacity: 1 },
              }}
              initial="hidden"
              animate="visible"
              exit="leave"
              transition={{
                type: "spring",
                damping: 20,
                stiffness: 150,
              }}
            >
              <FormComponent {...data} />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="modal-footer flex-column">
          {FormComponent.step === "Done" ? (
            <div>
              <button onClick={() => setStep(1)} className="btn solid red">
                Review
              </button>
              <SubmitButton text="Submit" />
            </div>
          ) : (
            <div>
              <button
                onClick={() => setStep(currentStep => currentStep - 1)}
                className="btn solid red"
                type="button"
                css={css`
                  visibility: ${step > 1 ? "visible" : "hidden"};
                `}
              >
                Previous Step
              </button>
              <button
                onClick={() => setStep(currentStep => currentStep + 1)}
                type="button"
                className="btn solid blue"
              >
                Next Step
              </button>
            </div>
          )}
        </div>
      </Form>
    </Formik>
  )
}

const updateStudentMutation = compress`
  mutation($input: StudentProfileInputObject!) {
    profileUpdate(input: $input) {
      errorMessages
    }
  }
`

export default Wizard
