import React from "react"

import { Composition } from "atomic-layout"

import RemoteParticipant from "components/TwilioCloudRoom/RemoteParticipant"

import styles from "./video-layout-styles.module.scss"

const VideoLayout = ({
  api,
  users,
  channel,
  studentScreenShareDisabled,
  controlsLockedUserIds,
  rvh,
  id,
}) => {
  const searchParams = new URLSearchParams(window.location.search)
  let usersArray = Array.from(users.values())
  let userSize = users.size

  if (searchParams.get("users") && userSize > 0) {
    userSize = parseInt(searchParams.get("users"))
    const userSizeArray = [...Array(userSize)]
    userSizeArray.forEach((_, index) => {
      const copy = { ...usersArray[0] }
      copy.identity = `${copy.identity}-${index}`
      usersArray.push(copy)
    })
  }

  let remoteUsersColumns = "1fr 1fr"
  let remoteUsersRows = "1fr 1fr"

  if (userSize === 1) {
    remoteUsersColumns = "1fr"
    remoteUsersRows = "1fr"
  }
  if (userSize === 2) {
    remoteUsersColumns = "1fr 1fr"
    remoteUsersRows = "1fr"
  }

  return (
    <Composition areas="students">
      {({ Students }) => (
        <>
          <Students
            className={styles.studentsContainer}
            templateCols={remoteUsersColumns}
            templateRows={remoteUsersRows}
            gapCol={25}
            maxHeight={rvh[80]}
            maxHeightMd={rvh[100]}
          >
            {usersArray.map(user => (
              <RemoteParticipant
                key={user.identity}
                user={user}
                api={api}
                channel={channel}
                studentScreenShareDisabled={studentScreenShareDisabled}
                controlsLocked={controlsLockedUserIds.includes(user.id)}
                id={id}
                controlled
              />
            ))}
          </Students>
        </>
      )}
    </Composition>
  )
}

export default VideoLayout
