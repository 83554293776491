import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  CheckboxField,
  Field,
  FormStatus,
  RadioField,
  SessionDateRangeField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

import AssessmentScoreFields from "./AssessmentScoreFields"

const ValidationSchema = Yup.object().shape({
  minimumGroupSize: Yup.number().min(
    1,
    "Minimum group size must be at least 1"
  ),
  maximumGroupSize: Yup.number()
    .min(1, "Maximum group size must be at least 1")
    .test(
      "maximumGroupSize",
      "Cannot be less than minimum group size",
      function () {
        const minSize = this.parent.minimumGroupSize
        const maxSize = this.parent.maximumGroupSize
        if (!minSize || !maxSize) return true
        if (minSize > maxSize) {
          return false
        } else {
          return true
        }
      }
    ),
  sessionsPerWeek: Yup.number()
    .min(1, "Groups must meet at least once per week")
    .max(3, "Groups may not meet more than 3 times per week"),
  maximumScoreDeviation: Yup.mixed().when("groupByAssessmentScore", {
    is: true,
    then: Yup.number()
      .required("Score deviation is required when grouping by assessment score")
      .min(1, "Score deviation must be at least 1"),
  }),
  assessmentSectionId: Yup.mixed().when("groupByAssessmentScore", {
    is: true,
    then: Yup.string().required(
      "Assessment section is required when grouping by assessment score"
    ),
  }),
})

const GenerateGroupsForm = ({
  teacherSubjectId,
  organizationId,
  closeModal,
}) => {
  const formState = {
    teacherSubjectId,
    organizationId,
    minimumGroupSize: 1,
    maximumGroupSize: 1,
    sessionsPerWeek: 1,
    preserveExisting: false,
    dates: [],
    groupByAssessmentScore: false,
    assessmentSectionId: "",
    maximumScoreDeviation: "",
    excusedAbsencesEnabled: true,
    requestStudyGroupConfirmations: true,
    canExceedScheduledDuration: false,
    inSchool: false,
    allowLongSessions: undefined,
  }

  const [errors, setErrors] = useState([])
  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions) => {
    const {
      groupByAssessmentScore,
      assessmentSectionId,
      maximumScoreDeviation,
      dates,
      ...variables
    } = values
    if (groupByAssessmentScore) {
      variables.assessmentSectionId = assessmentSectionId
      variables.maximumScoreDeviation = maximumScoreDeviation
    }
    if (dates.length === 2) {
      variables.startsOn = dates[0].toDate().toString()
      variables.endsOn = dates[1].toDate().toString()
    }

    if (variables.allowLongSessions !== undefined)
      variables.allowLongSessions = JSON.parse(variables.allowLongSessions)

    runMutation(variables)
      .then(
        response => {
          const { failures } = response.studyGroupGenerationCreate
          if (failures.length > 0) {
            setErrors(failures)
            actions.setStatus("Unable to create generation")
            actions.setSubmitting(false)
          } else {
            actions.setStatus(
              "Generating. Be patient. Page will redirect automatically when completed."
            )
          }
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      <Form>
        <div className="modal-body">
          <Field
            name="minimumGroupSize"
            label="Minimum Group Size"
            type="number"
            min={1}
          />
          <Field
            name="maximumGroupSize"
            label="Maximum Group Size"
            type="number"
            min={1}
          />
          <Field
            name="sessionsPerWeek"
            label="Sessions Per Week"
            type="number"
            min={1}
            max={3}
          />

          <SessionDateRangeField
            name="dates"
            label="Date Range (optional - this will allow auto-generation of sessions when tutors register)"
          />

          <CheckboxField
            name="excusedAbsencesEnabled"
            label="Enable Excused Absences"
          />

          <CheckboxField
            name="requestStudyGroupConfirmations"
            label="Request Student Study Group Confirmations"
          />

          <CheckboxField
            name="canExceedScheduledDuration"
            label="Tutors can bill for longer than scheduled duration"
          />

          <CheckboxField name="inSchool" label="In-School Program" />

          <CheckboxField
            name="groupByAssessmentScore"
            label="Group by assessment score"
          />
          <AssessmentScoreFields />

          <CheckboxField
            name="preserveExisting"
            label="Preserve Existing Groups"
          />

          <label className="form-label w-100">
            Allow Sessions Longer Than 1 Hour?
          </label>
          <div className="ml-3">
            <RadioField
              name="allowLongSessions"
              options={[
                {
                  label: "Use Organization Default (Recommended)",
                  value: undefined,
                },
                { label: "Always Allow", value: true },
                { label: "Never Allow", value: false },
              ]}
            />
          </div>
        </div>

        <div className="modal-footer flex-column">
          <FormStatus />
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger">
              {error.message}
            </div>
          ))}
          <div className="d-flex justify-content-between w-full">
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeModal}
            >
              Cancel
            </button>
            <SubmitButton text="Generate" />
          </div>
        </div>
      </Form>
    </Formik>
  )
}

const createMutation = compress`
  mutation(
    $organizationId: ID!
    $teacherSubjectId: ID
    $minimumGroupSize: Int!
    $maximumGroupSize: Int!
    $sessionsPerWeek: Int!
    $startsOn: Date
    $endsOn: Date
    $assessmentSectionId: ID
    $maximumScoreDeviation: Int
    $preserveExisting: Boolean
    $excusedAbsencesEnabled: Boolean
    $requestStudyGroupConfirmations: Boolean
    $canExceedScheduledDuration: Boolean
    $inSchool: Boolean
    $allowLongSessions: Boolean
  ) {
    studyGroupGenerationCreate(
      organizationId: $organizationId
      teacherSubjectId: $teacherSubjectId
      minimumGroupSize: $minimumGroupSize
      maximumGroupSize: $maximumGroupSize
      sessionsPerWeek: $sessionsPerWeek
      startsOn: $startsOn
      endsOn: $endsOn
      assessmentSectionId: $assessmentSectionId
      maximumScoreDeviation: $maximumScoreDeviation
      preserveExisting: $preserveExisting
      excusedAbsencesEnabled: $excusedAbsencesEnabled
      requestStudyGroupConfirmations: $requestStudyGroupConfirmations
      canExceedScheduledDuration: $canExceedScheduledDuration
      inSchool: $inSchool
      allowLongSessions: $allowLongSessions
    ) {
      failures {
        message
      }
    }
  }
`

export default GenerateGroupsForm
