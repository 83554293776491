import React, { useRef, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { css } from "@emotion/core"
import Tippy from "@tippyjs/react"

import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"
import RemoveButton from "components/RemoveButton"

import StudentAvailability from "./StudentAvailability"
import { studyGroupFields, teacherSubjectFields } from "./fragments"

import "tippy.js/dist/tippy.css"

const GeneratedStudyGroup = ({
  studyGroup,
  organizationId,
  assessmentSectionId,
}) => {
  const addStudentRef = useRef()
  const addTutorRef = useRef()
  const addTeacherRef = useRef()
  const [errors, setErrors] = useState()
  const { runMutation: removeStudent, running: removeStudentRunning } =
    useMutation(buildMutation(removeStudentMutation))
  const { runMutation: addStudent } = useMutation(
    buildMutation(addStudentMutation)
  )
  const { runMutation: removeUser, running: removeUserRunning } = useMutation(
    buildMutation(removeUserMutation)
  )
  const { runMutation: addUser } = useMutation(buildMutation(addUserMutation))
  const { runMutation: removeGroup, running: removeGroupRunning } = useMutation(
    buildMutation(removeMutation)
  )

  const score = student =>
    student.assessmentScores.find(
      score => score.assessmentSection.id === assessmentSectionId
    ).score

  return (
    <React.Fragment key={studyGroup.id}>
      <h4>
        <a href={studyGroup.showPath}>{studyGroup.name}</a>
      </h4>
      <h5>Session Times</h5>
      <ul className="list-unstyled">
        {studyGroup.availabilities.map(availability => (
          <li key={availability.id}>
            {availability.weekday}{" "}
            <LocalTime timestamp={availability.startsAt} format="h:mm A" />
            <span> &ndash; </span>
            <LocalTime timestamp={availability.endsAt} format="h:mm A z" />
          </li>
        ))}
      </ul>
      <button
        type="button"
        className="btn btn-danger mb-4"
        disabled={removeGroupRunning}
        onClick={() =>
          removeGroup({ id: studyGroup.id })
            .then(
              r => {
                if (r.studyGroupRemove.errorMessages) {
                  setErrors(r.studyGroupRemove.errorMessages)
                } else {
                  setErrors()
                }
              },
              e => setErrors("rejected: " + e?.message)
            )
            .catch(e => setErrors("caught: " + e?.message))
        }
      >
        Remove Group
      </button>

      <h4 className="mt-0">Students</h4>
      <ul className="list-unstyled">
        {ordered(studyGroup.students, "lastName").map(student => (
          <li key={student.id} className="mb-2">
            <RemoveButton
              disabled={removeStudentRunning}
              onClick={() =>
                removeStudent({
                  studyGroupId: studyGroup.id,
                  studentId: student.id,
                })
              }
            />
            <Tippy
              content={
                <StudentAvailability
                  availabilities={student.studentAvailabilities}
                />
              }
              placement="right"
            >
              <a href={student.showPath}>{student.fullName}</a>
            </Tippy>
            {assessmentSectionId && (
              <span className="ml-3">Score: {score(student)}</span>
            )}
          </li>
        ))}
      </ul>
      <div className="my-3">
        <AutocompleteSelect
          withLoadingIndicator
          api="/api/admins/students/autocomplete_full_name"
          searchOptions={`organization_id=${organizationId}`}
          ref={addStudentRef}
          placeholder="Add Student"
          onChange={selected =>
            selected &&
            addStudent({
              studentId: selected.value,
              studyGroupId: studyGroup.id,
            }).then(r => {
              addStudentRef.current.clearValue()
              const { errorMessages } = r.generatedStudyGroupAddStudent
              if (errorMessages) {
                setErrors(errorMessages)
              } else {
                setErrors()
              }
            })
          }
        />
      </div>
      {errors && (
        <div className="alert alert-danger d-flex align-items-center mt-3">
          <RemoveButton
            onClick={() => setErrors()}
            buttonCss={css`
              background-color: white;
              &:hover {
                background-color: black !important;
              }
            `}
          />
          <span>{errors}</span>
        </div>
      )}

      <h4 className="mt-0">Tutor</h4>
      <div className="mb-2">
        {studyGroup.tutor && (
          <>
            <RemoveButton
              disabled={removeUserRunning}
              onClick={() =>
                removeUser({
                  studyGroupId: studyGroup.id,
                  userId: studyGroup.tutor.id,
                  userType: "Tutor",
                })
              }
            />
            <a href={studyGroup.tutor.showPath}>{studyGroup.tutor.fullName}</a>
          </>
        )}
      </div>
      <div className="my-3">
        <AutocompleteSelect
          withLoadingIndicator
          api="/api/admins/tutors/autocomplete_full_name"
          ref={addTutorRef}
          placeholder="Change Tutor"
          onChange={selected =>
            selected &&
            addUser({
              studyGroupId: studyGroup.id,
              userId: selected.value,
              userType: "Tutor",
            }).then(r => {
              addTutorRef.current.clearValue()
            })
          }
        />
      </div>

      <h4 className="mt-0">Teachers</h4>
      <ul className="list-unstyled">
        {ordered(studyGroup.teachers, "lastName").map(teacher => (
          <li key={teacher.id} className="mb-2">
            <RemoveButton
              disabled={removeUserRunning}
              onClick={() =>
                removeUser({
                  studyGroupId: studyGroup.id,
                  userId: teacher.id,
                  userType: "Teacher",
                })
              }
            />
            <a href={teacher.showPath}>{teacher.fullName}</a>
          </li>
        ))}
      </ul>
      <div className="my-3">
        <AutocompleteSelect
          withLoadingIndicator
          api="/api/admins/teachers/autocomplete_full_name"
          searchOptions={`organization_id=${organizationId}`}
          ref={addTeacherRef}
          placeholder="Add Teacher"
          onChange={selected =>
            selected &&
            addUser({
              studyGroupId: studyGroup.id,
              userId: selected.value,
              userType: "Teacher",
            }).then(r => {
              addTeacherRef.current.clearValue()
            })
          }
        />
      </div>

      <hr />
    </React.Fragment>
  )
}

const removeStudentMutation = compress`
  ${studyGroupFields}
  ${teacherSubjectFields}
  mutation($studyGroupId: ID!, $studentId: ID!) {
    generatedStudyGroupRemoveStudent(studyGroupId: $studyGroupId, studentId: $studentId) {
      studyGroup {
        ...StudyGroupFields
      }
      teacherSubject {
        ...TeacherSubjectFields
      }
      studyGroupGeneration {
        id
      }
    }
  }
`

const addStudentMutation = compress`
  ${studyGroupFields}
  ${teacherSubjectFields}
  mutation($studyGroupId: ID!, $studentId: ID!) {
    generatedStudyGroupAddStudent(studyGroupId: $studyGroupId, studentId: $studentId) {
      errorMessages
      studyGroup {
        ...StudyGroupFields
      }
      teacherSubject {
        ...TeacherSubjectFields
      }
      studyGroupGeneration {
        id
      }
    }
  }
`

const removeUserMutation = compress`
  ${studyGroupFields}
  mutation(
    $studyGroupId: ID!
    $userId: ID!
    $userType: UserTypeEnum!
  ) {
    studyGroupRemoveUser(
      studyGroupId: $studyGroupId
      userId: $userId
      userType: $userType
    ) {
      studyGroup {
        ...StudyGroupFields
        studyGroupGeneration {
          id
        }
      }
    }
  }
`

const addUserMutation = compress`
  ${studyGroupFields}
  mutation(
    $studyGroupId: ID!
    $userId: ID!
    $userType: UserTypeEnum!
  ) {
    studyGroupAddUser(
      studyGroupId: $studyGroupId
      userId: $userId
      userType: $userType
    ) {
      studyGroup {
        ...StudyGroupFields
        studyGroupGeneration {
          id
        }
      }
    }
  }
`

const removeMutation = compress`
  ${teacherSubjectFields}
  mutation($id: ID!) {
    studyGroupRemove(id: $id) {
      studyGroup {
        id
        studyGroupGeneration {
          id
        }
      }
      teacherSubject {
        ...TeacherSubjectFields
      }
    }
  }
`

export default GeneratedStudyGroup
