import { compress } from "micro-graphql-react"

import lessonResourcesFragment from "./lessonResourcesFragment"

export default compress`
  ${lessonResourcesFragment}
  fragment LessonTopicsFields on LessonTopic {
    id
    name
    sortOrder
    signedUrl {
      url
      key
      successActionStatus
      acl
      policy
      credential
      algorithm
      date
      signature
    }
    lessonResources {
      ...LessonResourcesFields
    }
  }
`
