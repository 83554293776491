import React from "react"

import { FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"
import { weekdayOptions } from "src/enums"
import { parentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ValidationSchema = Yup.object().shape({
  availabilityIds: Yup.array().test(
    "availabilityIds",
    "Please select more availabilities",
    function () {
      const min = this.parent.requiredAvailabilities
      if (this.parent.availabilityIds.length < min) {
        return this.createError({
          message: `Please select at least ${min} availabilities`,
          path: "availabilityIds",
        })
      } else {
        return true
      }
    }
  ),
})

const EditAvailabilities = ({ studentId }) => {
  const loadingState = useQuery(
    buildQuery(availabilitiesQuery, { studentId }, { client })
  )
  const { runMutation } = useMutation(buildMutation(updateMutation, { client }))

  const formState = {
    availabilityIds: [],
  }

  const groupedAvailabilities = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  }

  if (loadingState.data) {
    formState.requiredAvailabilities =
      loadingState.data.student.minimumAvailabilityWindows
    formState.availabilityIds = loadingState.data.student.availabilities.map(
      oa => oa.id
    )

    if (!loadingState.data.student.availabilitySets?.length) return null

    const availabilitySets = loadingState.data.student.availabilitySets
    availabilitySets.forEach(availabilitySet => {
      availabilitySet.availabilities.forEach(availability => {
        groupedAvailabilities[availability.weekday].push(availability)
      })
    })
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      studentId,
      availabilityIds: values.availabilityIds,
    })
      .then(
        response => {
          const { errorMessages } = response.availabilityUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        error => {
          actions.setStatus(`Something went wrong: ${error?.message}`)
          airbrake.notify({
            error,
            name: "ParentEditAvailabilities",
          })
        }
      )
      .catch(error => {
        actions.setStatus(`Something went wrong: ${error?.message}`)
        airbrake.notify({
          error,
          name: "ParentEditAvailabilities",
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Availabilities"
      modalClassName="bootstrap-modal"
      buttonText="Edit Availabilities"
      buttonClassName="btn solid blue"
    >
      {({ closeModal }) => (
        <WithLoadingIndicator loadingState={loadingState}>
          {({ data }) => (
            <Formik
              initialValues={formState}
              onSubmit={handleSubmit}
              validationSchema={ValidationSchema}
            >
              {({ status, values, isSubmitting }) => (
                <Form>
                  <>
                    <div className="modal-body">
                      <div
                        className="form-group"
                        css={css`
                          display: flex;
                          justify-content: center;
                        `}
                      >
                        <FieldArray name="availabilityIds">
                          {arrayHelpers => (
                            <>
                              <div
                                css={css`
                                  display: flex;
                                  flex-wrap: wrap;
                                  @media screen and (max-width: 768px) {
                                    flex-flow: column;
                                  }
                                `}
                              >
                                {weekdayOptions
                                  .map(d => d.label)
                                  .map(
                                    weekday =>
                                      groupedAvailabilities[weekday].length >
                                        0 && (
                                        <div
                                          key={weekday}
                                          css={css`
                                            margin: 0 15px;
                                          `}
                                        >
                                          <h4
                                            css={css`
                                              font-weight: 500;
                                            `}
                                          >
                                            {weekday}
                                          </h4>
                                          <RadioGroup
                                            key={weekday}
                                            type="checkbox"
                                            name="availabilityIds"
                                            values={ordered(
                                              groupedAvailabilities[weekday],
                                              "startsAt"
                                            ).map(availability => ({
                                              value: availability.id,
                                              checked:
                                                values.availabilityIds.includes(
                                                  availability.id
                                                ),
                                              label: (
                                                <>
                                                  <LocalTime
                                                    timestamp={
                                                      availability.startsAt
                                                    }
                                                    format="LT"
                                                  />
                                                  &mdash;
                                                  <LocalTime
                                                    timestamp={
                                                      availability.endsAt
                                                    }
                                                    format="LT"
                                                  />
                                                </>
                                              ),
                                            }))}
                                            onChange={e => {
                                              if (e.currentTarget.checked) {
                                                arrayHelpers.push(
                                                  e.currentTarget.value
                                                )
                                              } else {
                                                arrayHelpers.remove(
                                                  values.availabilityIds.findIndex(
                                                    a =>
                                                      a ===
                                                      e.currentTarget.value
                                                  )
                                                )
                                              }
                                            }}
                                          />
                                        </div>
                                      )
                                  )}
                              </div>
                            </>
                          )}
                        </FieldArray>
                      </div>
                      <div className="text-center">
                        <ErrorMessage name="availabilityIds" />
                      </div>
                    </div>

                    <div className="modal-footer flex-col">
                      {status && (
                        <div
                          className="alert"
                          css={css`
                            padding: 9px 15px;
                            margin-bottom: 15px;
                            text-align: left;
                            display: inline-block;
                          `}
                        >
                          {status}
                        </div>
                      )}

                      <SubmitButton
                        isSubmitting={isSubmitting}
                        text="Save"
                        containerCss={css`
                          margin-right: 15px;
                        `}
                      />
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          )}
        </WithLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const availabilitiesQuery = compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      minimumAvailabilityWindows
      availabilitySets {
        id
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
      availabilities {
        id
      }
    }
  }
`

const updateMutation = compress`
  mutation($studentId: ID!, $availabilityIds: [ID!]!) {
    availabilityUpdate(studentId: $studentId, availabilityIds: $availabilityIds) {
      success
      errorMessages
    }
  }
`

export default EditAvailabilities
