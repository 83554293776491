import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import Select from "react-select"

import { parentClient as client } from "src/graphql-config"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import Active from "./Active"
import StudentCourses from "./StudentCourses"

const ParentCourseClusters = () => {
  const [heading, setHeading] = useState()
  const [student, setStudent] = useState()
  const loadingState = useQuery(buildQuery(studentsQuery, {}, { client }))

  return (
    <div className="row">
      <div className="col-1-1">
        <Active />
      </div>
      <div className="col-1-1">
        <WithLoadingIndicator
          loadingState={loadingState}
          heading={<h1>{heading || "Available Programs"}</h1>}
        >
          {({ data }) => (
            <>
              <div className="mb-3">
                <Select
                  options={data.students.map(s => ({
                    label: s.fullName,
                    value: s.id,
                  }))}
                  placeholder="Select a student"
                  onChange={selected => {
                    if (selected) {
                      setStudent(
                        data.students.find(s => s.id === selected.value)
                      )
                    } else {
                      setStudent()
                    }
                  }}
                  styles={{
                    container: provided => ({
                      ...provided,
                      maxWidth: 250,
                    }),
                  }}
                  isClearable
                />
              </div>

              {student && (
                <StudentCourses student={student} setHeading={setHeading} />
              )}
            </>
          )}
        </WithLoadingIndicator>
      </div>
    </div>
  )
}

const studentsQuery = compress`
  query {
    students {
      id
      fullName
    }
  }
`

export default ParentCourseClusters
