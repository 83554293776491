import React, { useEffect, useMemo, useRef, useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import { NotificationContainer } from "react-notifications"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"
import createBlob from "src/createBlob"

import useWebsocket from "hooks/useWebsocket"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import AutomatedReportSettings from "./AutomatedReportSettings"
import GradeRunRates from "./GradeRunRates"
import KitchenSinkReport from "./KitchenSinkReport"
import LessonPlanStatus from "./LessonPlanStatus"
import SummaryReport from "./SummaryReport"

import "react-notifications/lib/notifications.css"

const AdminOrganizationReports = ({ id }) => {
  const [exportObject, setExportObject] = useState({ url: "" })
  const loadingState = useQuery(buildQuery(organizationQuery, { id }))
  const downloadLink = useRef()

  const channelProps = useMemo(() => {
    return { id }
  }, [id])

  useWebsocket({
    channelName: "OrganizationChannel",
    channelProps,
  })

  useEffect(() => {
    EventEmitter.subscribe("exportComplete", data => {
      if (data.error) {
        alert(`Error occurred during export: ${data.error}`)
      } else {
        setExportObject({
          url: createBlob(data.content, data.type),
          filename: data.filename,
        })
      }
    })
  }, [])

  useEffect(() => {
    if (exportObject.url === "") return
    downloadLink.current.click()
  }, [exportObject.url])

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <NotificationContainer />
          <a
            ref={downloadLink}
            href={exportObject.url}
            download={exportObject.filename}
            css={css`
              display: none;
            `}
          >
            Download
          </a>
          <div className="flex">
            <div className="mr-2">
              <AutomatedReportSettings organization={data.organization} />
            </div>
            <div className="mr-2">
              <SummaryReport id={id} />
            </div>
            <div className="mr-2">
              <KitchenSinkReport id={id} />
            </div>
            <div className="mr-2">
              <GradeRunRates id={id} />
            </div>
            <div className="mr-2">
              <LessonPlanStatus id={id} />
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const organizationQuery = compress`
  query($id: ID!) {
    organization(id: $id) {
      id
      reportScheduleMode
      reportWeeklyFrequency
      reportWeekday
      reportDays
      reportContactEmail
      reportLastLoginAt
    }
  }
`

export default AdminOrganizationReports
