import React, { useEffect, useState } from "react"

import { FormikValues, useFormikContext } from "formik"

import { SelectOption } from "~tailwindui/types/SelectOption"

import ordered from "src/ordered"

import { ErrorMessage } from "components/Forms/Formik"
import {
  AutocompleteField,
  CheckboxField,
  Field,
  RadioField,
} from "components/Forms/Formik/hookComponents"
import RemoveButton from "components/RemoveButton"

export type AutogeneratedBreakdownsProps = Record<string, never>

const AutogeneratedBreakdowns: React.FC<AutogeneratedBreakdownsProps> = () => {
  const { values, setFieldValue, setFieldError, setStatus } =
    useFormikContext<FormikValues>()

  const [label, setLabel] = useState<string>("")
  const [subjects, setSubjects] = useState<SelectOption[]>([])

  useEffect(() => {
    if (label.length > 0 || subjects.length > 0) {
      setStatus([{ message: "You have an unsaved subject grouping" }])
    } else setStatus()
  }, [label, subjects, setStatus])

  const addGrouping = () => {
    const errs = []
    if (label.trim().length === 0) errs.push(["Label is required"])
    if (subjects.length === 0) errs.push(["At least one subject is required"])
    if (
      label &&
      values.subjectGroupingsAttributes.some(
        grouping => grouping.label === label
      )
    )
      errs.push(["A grouping with that label already exists"])
    setFieldError("subjectGroupingsAttributes", errs.join(", "))
    if (errs.length > 0) return

    const grouping = {
      label,
      subjects: subjects.map(sub => ({
        id: sub.value,
        name: sub.label,
      })),
    }
    setLabel("")
    setSubjects([])
    setFieldError("subjectGroupingsAttributes", "")
    setFieldValue(
      "subjectGroupingsAttributes",
      values.subjectGroupingsAttributes.concat([grouping])
    )
    setStatus()
  }

  const removeGrouping = (label: string) => {
    const newGroupings = values.subjectGroupingsAttributes.filter(
      sub => sub.label !== label
    )
    setFieldValue("subjectGroupingsAttributes", newGroupings)
  }

  return (
    <>
      <CheckboxField
        name="useAutogeneratedBreakdowns"
        label="Use Autogenerated Breakdowns"
      />

      {values.useAutogeneratedBreakdowns && (
        <div className="ml-4">
          <RadioField
            name="autogeneratesBreakdownsByCategory"
            options={[
              { label: "By School", value: "school" },
              { label: "By Subject", value: "subject" },
              {
                label: "By School-Subject Combo",
                value: "schoolSubject",
              },
            ]}
          />
        </div>
      )}

      {((values.useAutogeneratedBreakdowns &&
        values.autogeneratesBreakdownsByCategory === "subject") ||
        values.autogeneratesBreakdownsByCategory === "schoolSubject") && (
        <div className="ml-10">
          <h5>Subject Groupings</h5>
          {values.subjectGroupingsAttributes.length === 0 ? (
            <span className="italic">
              No subject groupings exist. Each subject will have its own line
              item.
            </span>
          ) : (
            <>
              <ul className="list-unstyled">
                {values.subjectGroupingsAttributes.map(grouping => (
                  <li key={grouping.label} className="flex items-start">
                    <div>
                      <RemoveButton
                        onClick={() => removeGrouping(grouping.label)}
                      />
                    </div>
                    <dl>
                      <dt>{grouping.label}</dt>
                      <dd>
                        <ul className="list-unstyled">
                          {ordered(grouping.subjects).map(subject => (
                            <li key={subject.id}>{subject.name}</li>
                          ))}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                ))}
              </ul>

              <span className="italic">
                All other subjects will each have their own line item.
              </span>
            </>
          )}

          <hr />

          <div className="w-100">
            <Field
              label="Title"
              name="subjectTitle"
              value={label}
              onChange={e => setLabel(e.target.value)}
            />
          </div>

          <div className="w-100">
            <AutocompleteField
              name="subjectGroupingSubjectIds"
              label="Subjects"
              value={subjects}
              api="/api/admins/subjects/autocomplete_name"
              onChange={selected => {
                setSubjects(selected)
              }}
              searchOptions={`organization_id=${values.organizationId}`}
              isMulti
            />
          </div>

          <div>
            <ErrorMessage name="subjectGroupingsAttributes" touched={true} />
          </div>

          <button
            type="button"
            className="btn btn-success"
            onClick={addGrouping}
          >
            Save Subject Grouping
          </button>
        </div>
      )}
    </>
  )
}

export default AutogeneratedBreakdowns
