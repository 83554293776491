import React from "react"

import Layer from "components/Pages/Layer"
import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"

import StudyGroupsIndex from "./StudyGroupsIndex"

const StudyGroupsPage = () => {
  return (
    <React.Fragment>
      <Layer>
        <ErrorBoundary name="StudyGroupsList">
          <StudyGroupsIndex />
        </ErrorBoundary>
      </Layer>
    </React.Fragment>
  )
}

export default StudyGroupsPage
