import React, { useState } from "react"

import { gql } from "urql"

import { useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const Receipt = ({ statement, receipt }) => {
  const [errors, setErrors] = useState([])
  const [, runMutation] = useMutation(deleteReceiptMutation)
  const removeReceipt = () => {
    runMutation({ statementId: statement.id, receiptId: receipt.id }).then(
      result => {
        const { failures } = result.data.statementDeleteReceipt
        if (failures) {
          setErrors(failures)
        }
      }
    )
  }
  return (
    <li key={receipt.id} className="mb-3">
      <ModalWithProvidedBody
        buttonClassName="btn-link btn btn-sm btn-outline-danger red rounded-circle p-1 text-danger nc-icon nc-simple-remove m-0 mr-3"
        modalTitle="Remove Receipt"
      >
        {({ closeModal }) => (
          <>
            <div className="modal-body">
              <p>Are you sure?</p>
            </div>
            <div className="modal-footer flex-col">
              {errors.map(error => (
                <div key={error.message} className="alert alert-danger mb-3">
                  {error.message}
                </div>
              ))}
              <div className="w-100 flex justify-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={removeReceipt}
                >
                  Remove
                </button>
                <button type="button" className="btn" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
      </ModalWithProvidedBody>
      <a href={receipt.downloadUrl}>{receipt.filename}</a>
    </li>
  )
}

const deleteReceiptMutation = gql`
  mutation ($statementId: ID!, $receiptId: ID!) {
    statementDeleteReceipt(statementId: $statementId, receiptId: $receiptId) {
      statement {
        id
      }
      failures {
        message
      }
    }
  }
`

export default Receipt
