import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { gql, useMutation, useQuery } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  ValidationNotice,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Availabilities from "./Availabilities"
import BackgroundChecks from "./BackgroundChecks"
import Education from "./Education"
import ForeignLanguageProficiencies from "./ForeignLanguageProficiencies"
import PersonalInfo from "./PersonalInfo"
import ReferralSource from "./ReferralSource"
import Subjects from "./Subjects"
import TeachingExperience from "./TeachingExperience"
import Technology from "./Technology"

const ValidationSchema = Yup.object().shape({
  referralSource: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Referral Source is required"),
  }),
  firstName: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("First name is required"),
  }),
  lastName: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Last name is required"),
  }),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Phone number is required"),
  }),
  country: Yup.mixed().test(
    "country",
    "Country is required",
    function (country) {
      if (this.parent.draft) return true
      if (this.parent.inUsa) return true

      return country && country.length > 1
    }
  ),
  region: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("State/Region is required"),
  }),
  timeZone: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Time zone is required"),
  }),
  canCompleteFingerprint: Yup.mixed().when("draft", {
    is: false,
    then: Yup.boolean().required(
      "Please indicate whether you can complete a fingerprint check"
    ),
  }),
  techSavviness: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number()
      .min(1, "Please indicate your comfort with technology")
      .max(5, "Please indicate your comfort with technology"),
  }),
  highSpeedInternet: Yup.mixed().when("draft", {
    is: false,
    then: Yup.boolean().required(
      "Please indicate whether you have high speed internet"
    ),
  }),
  hasTablet: Yup.mixed().when("draft", {
    is: false,
    then: Yup.boolean().required("Please indicate whether you have a tablet"),
  }),
  willPurchaseTablet: Yup.mixed().test(
    "willPurchaseTablet",
    "Please indicate whether you are able to purchase a tablet",
    function (willPurchaseTablet) {
      if (this.parent.hasTablet === null) return true
      if (this.parent.hasTablet || this.parent.draft) return true
      if (typeof willPurchaseTablet === "boolean") return true
    }
  ),
  teachingExperienceYears: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required("Please enter your years of experience"),
  }),
  teachingExperienceK2: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required(
      "Please indicate your grades K-2 teaching experience level"
    ),
  }),
  teachingExperience35: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required(
      "Please indicate your grades 3-5 teaching experience level"
    ),
  }),
  teachingExperience68: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required(
      "Please indicate your grades 6-8 teaching experience level"
    ),
  }),
  teachingExperience912: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required(
      "Please indicate your grades 9-12 teaching experience level"
    ),
  }),
  teachingExperienceCollege: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required(
      "Please indicate your college teaching experience level"
    ),
  }),
  learningDifferencesTeachingAbility: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required(
      "Please indicate your experience level with learning differences"
    ),
  }),
  behavioralDifferencesTeachingAbility: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required(
      "Please indicate your experience level with behavioral differences"
    ),
  }),
  englishLanguageLearnersTeachingAbility: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required(
      "Please indicate your experience level with English language learners"
    ),
  }),
  yearsSinceLastTeachingExperience: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required(
      "Please indicate number of years since your last teaching experience"
    ),
  }),
  teachingStyle: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Please describe your teaching style"),
  }),
  teachingReason: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Please describe your teaching reason"),
  }),
  camaraderieNotes: Yup.mixed().when("draft", {
    is: false,
    then: Yup.string().required("Please describe your camaraderie method"),
  }),
  parentCommunicationAbility: Yup.mixed().when("draft", {
    is: false,
    then: Yup.number().required(
      "Please indicate your parent communication ability"
    ),
  }),
  subjectBucketIds: Yup.mixed().when("draft", {
    is: false,
    then: Yup.array().min(1, "Please select at least 1 subject"),
  }),
  availabilityIds: Yup.mixed().when("draft", {
    is: false,
    then: Yup.array().min(1, "Please select at least 1 availability time"),
  }),
  foreignLanguageProficienciesAttributes: Yup.array().of(
    Yup.object().shape({
      foreignLanguageId: Yup.string().required("Please select a language"),
      fluency: Yup.string().required("Please select your fluency level"),
      academicAbility: Yup.number().required(
        "Please select your academic ability for this language"
      ),
    })
  ),
  degreesAttributes: Yup.array()
    .of(
      Yup.object().shape({
        college: Yup.string().required("College/University name is required"),
        degree: Yup.string().required("Degree name is required"),
        level: Yup.string().required("Degree level is required"),
        levelOther: Yup.mixed().when("level", {
          is: "other",
          then: Yup.string().required("Please describe your degree level"),
        }),
        year: Yup.string().required("Year of graduation is required"),
        country: Yup.mixed().when("inUsa", {
          is: false,
          then: Yup.string().required(
            "Country for college/university is required"
          ),
        }),
        degreeType: Yup.string().required("Degree Type is required"),
      })
    )
    .test(
      "degreesAttributes",
      "Please add at least 1 degree",
      function (degrees) {
        if (this.parent.noCollegeEducation) return true
        if (this.parent.draft) return true
        return degrees.filter(d => !d._destroy).length > 0
      }
    ),
})

const TutorApplicationForm = ({ tutorApplication, admin, client }) => {
  if (tutorApplication) {
    tutorApplication.foreignLanguageProficiencies.forEach(proficiency => {
      if (proficiency.foreignLanguage) {
        proficiency.foreignLanguageId = proficiency.foreignLanguage.id
        delete proficiency.foreignLanguage
      }
    })
  }
  const formState = {
    draft: admin ? true : false,
    firstName: tutorApplication?.firstName || "",
    lastName: tutorApplication?.lastName || "",
    email: tutorApplication?.email || "",
    phoneNumber: tutorApplication?.phoneNumber || "",
    referralSource: tutorApplication?.referralSource || "",
    referralSourceOther: tutorApplication?.referralSourceOther || "",
    resume: {},

    country: tutorApplication?.country || "",
    inUsa: tutorApplication?.inUsa ?? true,
    region: tutorApplication?.region || "",
    timeZone: tutorApplication?.timeZone || "Pacific",

    noCollegeEducation: tutorApplication?.noCollegeEducation || false,
    certificates: tutorApplication?.certificates || [],
    degreesAttributes: tutorApplication?.degrees || [],
    foreignLanguageProficienciesAttributes:
      tutorApplication?.foreignLanguageProficiencies || [],

    race: tutorApplication?.race || "",
    raceOther: tutorApplication?.raceOther || "",
    ethnicity: tutorApplication?.ethnicity || "",
    canCompleteFingerprint: tutorApplication?.canCompleteFingerprint || null,
    backgroundCheckNotes: tutorApplication?.backgroundCheckNotes || "",

    techSavviness: tutorApplication?.techSavviness || 0,
    highSpeedInternet: tutorApplication?.highSpeedInternet || null,
    hasTablet: tutorApplication?.hasTablet || null,
    willPurchaseTablet: tutorApplication?.willPurchaseTablet || null,

    teachingExperienceYears: tutorApplication?.teachingExperienceYears || 0,
    teachingExperienceK2: tutorApplication?.teachingExperienceK2 || "",
    teachingExperienceK2Other:
      tutorApplication?.teachingExperienceK2Other || "",
    teachingExperience35: tutorApplication?.teachingExperience35 || "",
    teachingExperience35Other:
      tutorApplication?.teachingExperience35Other || "",
    teachingExperience68: tutorApplication?.teachingExperience68 || "",
    teachingExperience68Other:
      tutorApplication?.teachingExperience68Other || "",
    teachingExperience912: tutorApplication?.teachingExperience912 || "",
    teachingExperience912Other:
      tutorApplication?.teachingExperience912Other || "",
    teachingExperienceCollege:
      tutorApplication?.teachingExperienceCollege || "",
    teachingExperienceCollegeOther:
      tutorApplication?.teachingExperienceCollegeOther || "",
    learningDifferencesTeachingAbility:
      tutorApplication?.learningDifferencesTeachingAbility || 0,
    learningDifferencesExperienceNotes:
      tutorApplication?.learningDifferencesExperienceNotes || "",
    behavioralDifferencesTeachingAbility:
      tutorApplication?.behavioralDifferencesTeachingAbility || 0,
    behavioralDifferencesExperienceNotes:
      tutorApplication?.behavioralDifferencesExperienceNotes || "",
    englishLanguageLearnersTeachingAbility:
      tutorApplication?.englishLanguageLearnersTeachingAbility || 0,
    englishLanguageLearnersExperienceNotes:
      tutorApplication?.englishLanguageLearnersExperienceNotes || "",
    teachingStyle: tutorApplication?.teachingStyle || "",
    teachingReason: tutorApplication?.teachingReason || "",
    camaraderieNotes: tutorApplication?.camaraderieNotes || "",
    parentCommunicationAbility:
      tutorApplication?.parentCommunicationAbility || 0,
    yearsSinceLastTeachingExperience:
      tutorApplication?.yearsSinceLastTeachingExperience || 0,

    smsOptIn: tutorApplication?.smsOptIn || false,
    subjectBucketIds: tutorApplication
      ? tutorApplication.subjectBuckets.map(bucket => bucket.id)
      : [],
    availabilityIds: tutorApplication
      ? tutorApplication.availabilities.map(av => av.id)
      : [],
  }
  formState.degreesAttributes.map(degree => delete degree.__typename)
  formState.foreignLanguageProficienciesAttributes.map(
    proficiency => delete proficiency.__typename
  )

  const [result] = useQuery({ query: foreignLanguagesQuery, client })
  const [, runMutation] = useMutation(submitApplicationMutation, client)

  const handleSubmit = (values, actions) => {
    const params = {
      token: tutorApplication?.token,
      input: { ...values },
    }
    const promises = params.input.degreesAttributes.map(degree => {
      if (degree.transcript) {
        return readFile(degree.transcript)
      } else {
        return new Promise(resolve => resolve())
      }
    })

    readFile(values.resume).then(resumeResult => {
      params.input.resume = resumeResult
      Promise.allSettled(promises).then(promiseResults => {
        promiseResults.forEach((fileUpload, index) => {
          params.input.degreesAttributes[index].transcript = fileUpload.value
        })

        runMutation(params)
          .then(
            result => {
              if (result.error) {
                handleFailure(actions, [result.error])
                return
              }
              const { failures, tutorApplication } =
                result.data.submitTutorApplication
              if (failures) {
                handleFailure(actions, failures)
              } else {
                if (admin) {
                  window.location = tutorApplication.showPath
                }
                if (tutorApplication.applicationState === "draft") {
                  actions.setStatus([
                    {
                      type: "info",
                      message: (
                        <>
                          Saved successfully. You can access your application at{" "}
                          <br />
                          {tutorApplication.editUrl}
                        </>
                      ),
                    },
                  ])
                  actions.setSubmitting(false)
                } else {
                  actions.setStatus([
                    {
                      type: "success",
                      message:
                        "Application submitted. You will be contacted with further steps.",
                    },
                  ])
                  actions.setSubmitting(false)
                }
              }
            },
            () => handleFailure(actions)
          )
          .catch(() => handleFailure(actions))
      })
    })
  }

  return (
    <>
      <h2>Tutor Candidate Questionnaire</h2>
      <div>
        <p>
          We're looking forward to learning a bit more about you! Once you
          complete this form, we will reach out based on your fit to our current
          programs and needs.
        </p>
        <p>
          *Please note: There are no correct answers - only honest ones! We love
          the eclectic array of experiences our educators bring to the team, and
          are looking for candidates who answer questions truthfully so we can
          match them to best-fit students.
        </p>
        <p>
          Thank you for continuing this process with us - we look forward to
          reading your story!
        </p>
        <p>Note: Pay rate is $45/hour, billed in 15-min increments.</p>
        <p>
          ***No phone calls please. Due to the influx of applications we will
          not be able to respond to every submission. Only qualified applicants
          will be contacted.
        </p>
        <hr />
      </div>
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <Formik
            initialValues={formState}
            onSubmit={handleSubmit}
            validationSchema={!admin && ValidationSchema}
          >
            <Form>
              <div
                css={css`
                  input {
                    max-width: 500px;
                  }
                `}
              >
                <Field name="email" label="Email" type="email" />
                <ReferralSource />
                <PersonalInfo />
                <Education />

                <ForeignLanguageProficiencies
                  foreignLanguageOptions={data.foreignLanguages}
                />
                <BackgroundChecks />
                <Technology />
                <TeachingExperience />
                <Subjects
                  mathSubjects={data.mathSubjects}
                  scienceSubjects={data.scienceSubjects}
                  englishSubjects={data.englishSubjects}
                  socialStudiesSubjects={data.socialStudiesSubjects}
                  languagesSubjects={data.languagesSubjects}
                  otherSubjects={data.otherSubjects}
                />
                <Availabilities
                  availabilities={data.tutorApplicationAvailabilities}
                />

                <div className="my-5">
                  <hr />

                  <CheckboxField
                    name="smsOptIn"
                    label={
                      <>
                        <span>SMS Opt-In</span>
                        <p className="mt-2 italic">
                          (For expedited review, if you would like to receive
                          text message updates regarding your application,
                          please check this box and note that Msg & data rates
                          may apply)
                        </p>
                      </>
                    }
                  />
                  {!admin && (
                    <CheckboxField
                      name="draft"
                      label="Draft (save without submitting)"
                    />
                  )}
                </div>

                <FormFailures />
                <ValidationNotice />
                <SubmitButton text="Submit" />
              </div>
            </Form>
          </Formik>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const foreignLanguagesQuery = gql`
  fragment SubjectBucketFields on SubjectBucket {
    id
    name
    subjectType
    tier {
      id
      name
    }
  }
  query {
    foreignLanguages {
      id
      name
    }
    mathSubjects: subjectBuckets(subjectType: math) {
      ...SubjectBucketFields
    }
    scienceSubjects: subjectBuckets(subjectType: science) {
      ...SubjectBucketFields
    }
    englishSubjects: subjectBuckets(subjectType: english) {
      ...SubjectBucketFields
    }
    socialStudiesSubjects: subjectBuckets(subjectType: social_studies) {
      ...SubjectBucketFields
    }
    languagesSubjects: subjectBuckets(subjectType: languages) {
      ...SubjectBucketFields
    }
    otherSubjects: subjectBuckets(subjectType: other) {
      ...SubjectBucketFields
    }
    tutorApplicationAvailabilities {
      id
      startsAt
      endsAt
      weekday
    }
  }
`

const submitApplicationMutation = gql`
  mutation ($token: String!, $input: TutorApplicationInputObject!) {
    submitTutorApplication(token: $token, input: $input) {
      tutorApplication {
        id
        editUrl
        showPath
        applicationState
      }

      failures {
        message
      }
    }
  }
`

export default TutorApplicationForm
