import React from "react"

import * as colors from "styles/colors"

import { css } from "@emotion/core"
import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Rating = ({ name, value, onChange, className }) => {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        input {
          display: none;
        }
        .btn {
          padding: 6px;
          font-size: 32px;
          margin-right: 9px;
          width: 46px;
          height: 46px;
          opacity: 0.75;
          border: none;

          &:hover,
          &.solid {
            padding: 0;
            font-size: 42px;
            background: white !important;
            border: none;
            opacity: 1;
          }
        }
      `}
    >
      <div>
        <label>
          <div
            className={`btn ${value === "weak" && "solid"}`}
            css={css`
              :hover,
              &.solid {
                color: ${colors.red};
              }
            `}
          >
            <FontAwesomeIcon icon={faFrown} />
          </div>
          <input
            type="radio"
            value="weak"
            defaultChecked={value === "weak"}
            name={name}
            onChange={onChange}
          />
        </label>
      </div>

      <div>
        <label>
          <div
            className={`btn ${value === "moderate" && "solid"}`}
            css={css`
              :hover,
              &.solid {
                color: ${colors.orange};
              }
            `}
          >
            <FontAwesomeIcon icon={faMeh} />
          </div>
          <input
            type="radio"
            value="moderate"
            name={name}
            onChange={onChange}
          />
        </label>
      </div>

      <div>
        <label>
          <div
            className={`btn ${value === "strong" && "solid"}`}
            css={css`
              :hover,
              &.solid {
                color: ${colors.green};
              }
            `}
          >
            <FontAwesomeIcon icon={faSmile} />
          </div>
          <input type="radio" value="strong" name={name} onChange={onChange} />
        </label>
      </div>
    </div>
  )
}

const RatingContainer = ({ label, ...ratingProps }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75%;
      margin: auto;
      margin-bottom: 30px;
    `}
  >
    <div
      css={css`
        flex-grow: 1;
        text-align: left;
      `}
    >
      {label}
    </div>
    <Rating {...ratingProps} />
  </div>
)

export { Rating, RatingContainer }
