import React, { useState } from "react"

import { publicClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"
import InterviewListener from "./InterviewListener"

const ScheduleInterview = ({ token }) => {
  const [interviewer, setInterviewer] = useState()
  const [result] = useQuery({
    query: interviewersQuery,
    variables: { token },
    client,
  })

  return (
    <UrqlLoadingIndicator result={result} heading={<h1>Schedule Interview</h1>}>
      {({ data }) =>
        interviewer ? (
          <>
            <InterviewListener token={token} interviewer={interviewer} />
          </>
        ) : (
          <Form
            setInterviewer={setInterviewer}
            interviewers={data.tutorApplicationInterviewers}
          />
        )
      }
    </UrqlLoadingIndicator>
  )
}

const interviewersQuery = gql`
  query ($token: String!) {
    tutorApplicationInterviewers(token: $token) {
      id
      name
      calendlyUrl
    }
  }
`

export default ScheduleInterview
