import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import { NotificationManager } from "react-notifications"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const TutorRow = ({ tutor }) => {
  const [errors, setErrors] = useState()
  const { runMutation } = useMutation(buildMutation(observeTutorMutation))

  const observeTutor = () => {
    runMutation({ tutorId: tutor.id })
      .then(
        response => {
          const { errorMessages } = response.tutorMarkForObservation
          if (errorMessages) {
            setErrors(errorMessages)
          } else {
            NotificationManager.info("Tutor has been marked for observation")
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <tr>
      <td>
        <a href={tutor.showPath}>{tutor.fullName}</a>
      </td>
      <td>
        <ModalWithProvidedBody
          modalTitle="Tutor Observation"
          buttonText="Mark for Observation"
          buttonClassName="btn btn-info"
        >
          {({ closeModal }) => (
            <>
              <div className="modal-body">
                <p className="lead">
                  Click claim below to mark this tutor to be observed
                </p>
                <h4>Upcoming Sessions</h4>

                {ordered(tutor.airtutorsSessions.data, "startsAt").map(
                  session => (
                    <React.Fragment key={session.id}>
                      <dl>
                        <dt>Session Date</dt>
                        <dd>
                          <LocalTime timestamp={session.startsAt} />
                        </dd>

                        <dt>Subject</dt>
                        <dd>{session.subject.name}</dd>

                        <dt>Students</dt>
                        <dd>
                          <ul className="list-unstyled">
                            {session.students.map(student => (
                              <li key={student.id}>{student.fullName}</li>
                            ))}
                          </ul>
                        </dd>

                        {session.organization && (
                          <>
                            <dt>Organization</dt>
                            <dd>{session.organization.name}</dd>
                          </>
                        )}

                        {session.studyGroup && (
                          <>
                            <dt>Study Group</dt>
                            <dd>{session.studyGroup.name}</dd>
                          </>
                        )}
                      </dl>
                      <hr />
                    </React.Fragment>
                  )
                )}
              </div>

              <div className="modal-footer flex-column">
                {errors && <div className="alert alert-danger">{errors}</div>}

                <div className="w-100 d-flex justify-content-between">
                  <button onClick={closeModal} className="btn btn-danger">
                    Cancel
                  </button>
                  <button onClick={observeTutor} className="btn btn-success">
                    Claim
                  </button>
                </div>
              </div>
            </>
          )}
        </ModalWithProvidedBody>
      </td>
    </tr>
  )
}

const observeTutorMutation = compress`
  mutation($tutorId: ID!) {
    tutorMarkForObservation(tutorId: $tutorId) {
      errorMessages
      tutor {
        id
        fullName
        showPath
      }
    }
  }
`

export default TutorRow
