import React from "react"

import { Form } from "formik"

import AutogeneratedBreakdowns from "components/Admin/Organization/Statements/New/AutogeneratedBreakdowns"
import Schools from "components/Admin/Organization/Statements/New/Schools"
import Tags from "components/Admin/Organization/Statements/New/Tags"
import {
  Field,
  FormFailures,
  SubmitButton,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"

import Budgets from "../Statements/New/Budgets"

import GenerationSettings from "./GenerationSettings"

const StatementConfigurationForm = ({
  formType,
  statementConfiguration,
  organization,
  budgetRef,
}) => {
  return (
    <Form>
      <Field name="name" label="Name" />

      {formType === "new" && <GenerationSettings organization={organization} />}

      <Budgets
        budgets={organization.budgets.data}
        ref={budgetRef}
        defaultValue={statementConfiguration?.budget}
      />

      <TextAreaField
        name="miscellaneousContent"
        label="Miscellaneous Content"
      />

      <Schools schools={organization.schools} />
      <Tags
        defaultValue={statementConfiguration?.studyGroupTags.map(tag => ({
          label: tag,
        }))}
      />
      <AutogeneratedBreakdowns />

      <hr />

      <FormFailures />
      <SubmitButton />
    </Form>
  )
}

export default StatementConfigurationForm
