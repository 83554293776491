import React, { useRef } from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import ordered from "src/ordered"

import { useMutation, useQuery } from "hooks/urql"

import EditableList from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import {
  AutocompleteField,
  CheckboxField,
  RadioField,
} from "components/Forms/Formik/hookComponents"
import RemoveButton from "components/RemoveButton"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  minimumStudentsThreshold: Yup.number().required().min(0),
  allowLongSessions: Yup.mixed().when("organizationId", {
    is: undefined,
    then: Yup.mixed().required(
      "Cannot use organization default for public clusters"
    ),
  }),
})

const StudyGroupForm = () => {
  const [result] = useQuery({ query: viewerQuery })
  const formState = {
    name: "",
    details: "",
    organizationId: "",
    schoolId: "",
    foreignLanguageId: "",
    splitBill: false,
    minimumStudentsThreshold: 0,
    teachers: [],
    tutor: "",
    students: [],
    subjects: [],
    hourlyRate: "",
    noShowRate: "",
    excusedAbsencesEnabled: false,
    allowLongSessions: undefined,
    requestConfirmations: false,
    canExceedScheduledDuration: false,
    inSchool: false,
    tags: [],
    budgetId: "",
  }
  const [, runMutation] = useMutation(createMutation)
  const selectRefs = useRef({})

  const addItem = ({ arrayHelpers, selectedOption, field, values }) => {
    if (!selectedOption?.value) return
    const ref = selectRefs.current[field]
    const selectedId = String(selectedOption.value)
    if (values[field].find(s => s.id === selectedId)) {
      setTimeout(() => ref.clearValue(), 0)
      return
    }
    arrayHelpers.push({
      id: selectedId,
      fullName: selectedOption.label,
      name: selectedOption.label,
    })
    setTimeout(() => ref.clearValue(), 0)
  }

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true)
    const {
      teachers,
      tutor,
      students,
      subjects,
      hourlyRate,
      noShowRate,
      tags,
      ...rest
    } = {
      ...values,
    }
    const input = {
      ...rest,
      teacherIds: teachers.map(s => s.id),
      tutorId: tutor.id,
      studentIds: students.map(s => s.id),
      subjectIds: subjects.map(s => s.id),
      tagList: tags.map(t => t.label),
    }

    if (hourlyRate !== "") input.hourlyRate = parseFloat(hourlyRate)
    if (noShowRate !== "") input.noShowRate = parseFloat(noShowRate)
    if (input.allowLongSessions !== undefined)
      input.allowLongSessions = JSON.parse(input.allowLongSessions)

    runMutation({ input })
      .then(response => {
        const { success, redirectTo, errors } = response.data.studyGroupCreate
        if (success) {
          window.location = redirectTo
        } else {
          actions.setSubmitting(false)
          actions.setStatus(
            "Failed to create study group. Please correct validation errors."
          )
          errors.forEach(error => {
            actions.setFieldError(error.field, error.messages.join(", "))
          })
        }
      })
      .catch(err => {
        actions.setStatus("An error occurred while creating study group")
        actions.setSubmitting(false)
      })
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {({ isSubmitting, status, setFieldValue, values }) => (
            <Form>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Name
                      <Field type="text" name="name" className="form-control" />
                      <ErrorMessage name="name" />
                    </label>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Details
                      <Field name="details">
                        {({ field, form }) => (
                          <textarea className="form-control" {...field} />
                        )}
                      </Field>
                      <ErrorMessage name="details" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <AutocompleteField
                      name="organizationId"
                      api="/api/admins/organizations/autocomplete_name"
                      label="Select Organization"
                      ref={ref => (selectRefs.current.organizationId = ref)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label w-100">
                      School
                      <Field
                        name="schoolId"
                        type="text"
                        className="form-control"
                      >
                        {({ field: { name } }) => (
                          <AutocompleteSelect
                            api="/api/admins/schools/autocomplete_name"
                            ref={ref => (selectRefs.current.schoolId = ref)}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                            placeholder="Select School"
                            searchOptions={
                              values.organizationId
                                ? `organization_id=${values.organizationId}`
                                : null
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage name="schoolId" />
                    </label>
                  </div>

                  <AutocompleteField
                    name="foreignLanguageId"
                    label="Foreign Language Requirement"
                    api="/api/admins/foreign_languages/autocomplete_name"
                  />
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Hourly Rate
                      <Field
                        type="number"
                        name="hourlyRate"
                        className="form-control"
                      />
                      <ErrorMessage name="hourlyRate" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <CheckboxField
                    name="splitBill"
                    label="Split Bill Enabled"
                    afterChange={() => {
                      selectRefs.current["organizationId"].clearValue()
                      selectRefs.current["schoolId"].clearValue()
                    }}
                  />

                  {values.splitBill && (
                    <>
                      <div className="form-group">
                        <label className="form-label w-100">
                          Minimum Students Threshold
                          <Field
                            type="number"
                            name="minimumStudentsThreshold"
                            className="form-control"
                            min={0}
                          />
                          <ErrorMessage name="minimumStudentsThreshold" />
                        </label>
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          No Show Rate
                          <Field
                            type="text"
                            name="noShowRate"
                            className="form-control"
                          />
                        </label>
                        <ErrorMessage name="noShowRate" />
                      </div>
                    </>
                  )}

                  <CheckboxField
                    name="excusedAbsencesEnabled"
                    label="Excused Absences Enabled"
                  />

                  <CheckboxField
                    name="requestConfirmations"
                    label="Request Student Confirmations"
                  />

                  <CheckboxField
                    name="canExceedScheduledDuration"
                    label="Tutors can bill for longer than scheduled duration"
                  />

                  <CheckboxField name="inSchool" label="In-School Program" />

                  <div className="form-group">
                    <label className="form-label">
                      Allow Sessions Longer Than 1 Hour?
                      <div className="ml-3">
                        <RadioField
                          name="allowLongSessions"
                          options={[
                            {
                              label: "Use Organization Default (Recommended)",
                              value: undefined,
                            },
                            { label: "Always Allow", value: true },
                            { label: "Never Allow", value: false },
                          ]}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              {data.viewer.canAdminBudgets && values.organizationId && (
                <div className="row">
                  <div className="col-md-6">
                    <h4>Budget</h4>
                    <AutocompleteField
                      name="budgetId"
                      api="/api/admins/budgets/autocomplete_name"
                      searchOptions={`organization_id=${values.organizationId}`}
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-6">
                  <h4>Teachers</h4>
                  <FieldArray name="teachers">
                    {arrayHelpers => (
                      <React.Fragment>
                        <EditableList>
                          {ordered(values.teachers, "fullName").map(
                            ({ id, name }) => (
                              <li key={id}>
                                <RemoveButton
                                  onClick={() =>
                                    arrayHelpers.remove(
                                      values.teachers.findIndex(
                                        s => s.id === id
                                      )
                                    )
                                  }
                                />
                                <span>{name}</span>
                              </li>
                            )
                          )}
                        </EditableList>
                        <AutocompleteSelect
                          ref={ref => (selectRefs.current.teachers = ref)}
                          api="/api/admins/teachers/autocomplete_full_name"
                          onChange={selectedOption =>
                            addItem({
                              selectedOption,
                              arrayHelpers,
                              values,
                              field: "teachers",
                            })
                          }
                          placeholder="Add Teacher"
                        />
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <ErrorMessage name="teachers" />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100">
                      <h4>Tutor</h4>
                      <Field name="tutor" type="text" className="form-control">
                        {({ field: { name } }) => (
                          <AutocompleteSelect
                            api="/api/admins/tutors/autocomplete_full_name"
                            searchOptions="published=true"
                            onChange={selectedOption =>
                              setFieldValue(name, {
                                id: selectedOption ? selectedOption.value : "",
                              })
                            }
                            placeholder="Select Tutor"
                          />
                        )}
                      </Field>
                    </label>
                  </div>
                  <ErrorMessage name="tutor" />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h4>Students</h4>
                  <FieldArray name="students">
                    {arrayHelpers => (
                      <React.Fragment>
                        <EditableList>
                          {ordered(values.students, "fullName").map(
                            ({ id, fullName }) => (
                              <li key={id}>
                                <RemoveButton
                                  onClick={() =>
                                    arrayHelpers.remove(
                                      values.students.findIndex(
                                        s => s.id === id
                                      )
                                    )
                                  }
                                />
                                <span>{fullName}</span>
                              </li>
                            )
                          )}
                        </EditableList>
                        <AutocompleteSelect
                          ref={ref => (selectRefs.current.students = ref)}
                          api="/api/admins/students/autocomplete_full_name"
                          searchOptions={`
                          ${
                            values.organizationId
                              ? `organization_id=${values.organizationId}&`
                              : values.splitBill
                              ? "private_billing_method=true"
                              : ""
                          }
                        `.trim()}
                          onChange={selectedOption =>
                            addItem({
                              selectedOption,
                              arrayHelpers,
                              values,
                              field: "students",
                            })
                          }
                          placeholder="Add Student"
                        />
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <ErrorMessage name="students" />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h4>Subjects</h4>
                  <FieldArray name="subjects">
                    {arrayHelpers => (
                      <React.Fragment>
                        <EditableList>
                          {ordered(values.subjects, "name").map(
                            ({ id, name }) => (
                              <li key={id}>
                                <RemoveButton
                                  onClick={() =>
                                    arrayHelpers.remove(
                                      values.subjects.findIndex(
                                        s => s.id === id
                                      )
                                    )
                                  }
                                />
                                <span>{name}</span>
                              </li>
                            )
                          )}
                        </EditableList>
                        <AutocompleteSelect
                          ref={ref => (selectRefs.current.subjects = ref)}
                          api="/api/admins/subjects/autocomplete_name"
                          onChange={selectedOption =>
                            addItem({
                              selectedOption,
                              arrayHelpers,
                              values,
                              field: "subjects",
                            })
                          }
                          placeholder="Add Subject"
                        />
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <ErrorMessage name="subjects" />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h4>Tags</h4>
                  <AutocompleteSelect
                    api="/api/admins/study_groups/autocomplete_tags"
                    isMulti
                    creatable
                    value={values.tags}
                    onChange={selected => setFieldValue("tags", selected)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {status && (
                    <React.Fragment>
                      <div className="alert alert-danger mt-3">{status}</div>
                    </React.Fragment>
                  )}
                  <SubmitButton isSubmitting={isSubmitting} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </UrqlLoadingIndicator>
  )
}

const viewerQuery = gql`
  query viewer {
    viewer {
      canAdminBudgets
    }
  }
`

const createMutation = gql`
  mutation ($input: StudyGroupInputObject!) {
    studyGroupCreate(input: $input) {
      success
      redirectTo
      errors {
        field
        messages
      }
    }
  }
`

export default StudyGroupForm
