import React from "react"

import { useField } from "formik"

import { buildTeachingExperienceOption } from "src/enums"

import {
  Field,
  RadioField,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"

const TeachingExperienceField = ({ name, label, labelOther, shortLabel }) => {
  const [field] = useField(name)
  return (
    <>
      <RadioField
        name={name}
        label={label}
        options={buildTeachingExperienceOption(shortLabel)}
      />
      {field.value === "other" && (
        <Field name={`${name}Other`} label={labelOther} />
      )}
    </>
  )
}

const experienceLevelOptions = [
  { label: "1 (None/Limited)", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5 (Extensive)", value: 5 },
]

const TeachingExperience = () => {
  return (
    <>
      <h3>Teaching Experience</h3>
      <Field
        name="teachingExperienceYears"
        label="Indicate the number of years of paid academic teaching or tutoring from the last 10 years."
        type="number"
      />
      <TeachingExperienceField
        name="teachingExperienceK2"
        label="Are you experienced & comfortable teaching at the K-2 level?"
        labelOther="Please describe your experience and comfort teaching at the K-2 level"
        shortLabel="K-2"
      />

      <TeachingExperienceField
        name="teachingExperience35"
        label="Are you experienced & comfortable teaching at the 3-5 level?"
        labelOther="Please describe your experience and comfort teaching at the 3-5 level"
        shortLabel="3-5"
      />

      <TeachingExperienceField
        name="teachingExperience68"
        label="Are you experienced & comfortable teaching at the 6-8 level?"
        labelOther="Please describe your experience and comfort teaching at the 6-8 level"
        shortLabel="6-8"
      />

      <TeachingExperienceField
        name="teachingExperience912"
        label="Are you experienced & comfortable teaching at the 9-12 level?"
        labelOther="Please describe your experience and comfort teaching at the 9-12 level"
        shortLabel="9-12"
      />

      <TeachingExperienceField
        name="teachingExperienceCollege"
        label="Are you experienced & comfortable teaching at the undergraduate or graduate level?"
        labelOther="Please describe your experience and comfort teaching at the undergraduate or graduate level"
        shortLabel="college"
      />

      <RadioField
        name="learningDifferencesTeachingAbility"
        label="How much experience have you had working with students with diagnosed learning differences?"
        options={experienceLevelOptions}
        numeric
      />
      <TextAreaField
        name="learningDifferencesExperienceNotes"
        label="If you have worked with learning-different students, please briefly describe your experience below. (Example: 5 years teaching in a special ed classroom, 2 years working with adults on the autism spectrum, math tutoring an ADHD adult)"
        maxLength={500}
      />

      <RadioField
        name="behavioralDifferencesTeachingAbility"
        label="How much experience have you had working with students with diagnosed behavioral differences?"
        options={experienceLevelOptions}
        numeric
      />
      <TextAreaField
        name="behavioralDifferencesExperienceNotes"
        label="If you have worked with students with behavioral differences or challenges, please briefly describe your experience below. (Example: familiarity with PBIS implementation at the classroom level)"
        maxLength={500}
      />

      <RadioField
        name="englishLanguageLearnersTeachingAbility"
        label="How much experience have you had working with English Language Learners?"
        options={experienceLevelOptions}
        numeric
      />
      <TextAreaField
        name="englishLanguageLearnersExperienceNotes"
        label="If you have worked with learning-different students, please briefly describe your experience below. (Example: 5 years tutoring ELLs, 2 years working teaching English in another country, managing ELL program at the school/classroom level etc)"
        maxLength={500}
      />

      <Field
        type="number"
        step={1}
        name="yearsSinceLastTeachingExperience"
        label="How many years ago was your last tutoring/teaching experience?"
      />

      <TextAreaField
        name="teachingStyle"
        label="How would you describe your teaching style? (3-5 sentences, could be featured in your tutor bio if hired)."
        maxLength={500}
      />

      <TextAreaField
        name="teachingReason"
        label="In 3-5 sentences: Why do you teach?"
        maxLength={500}
      />

      <TextAreaField
        name="camaraderieNotes"
        label="What are 1-2 ways you might create camaraderie with a student or group of students in a virtual setting?"
        maxLength={500}
      />

      <RadioField
        name="parentCommunicationAbility"
        label="I feel confident speaking with parents about their student's progress:"
        options={[
          { label: "1 (Uncomfortable)", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
          { label: "4", value: 4 },
          { label: "5 (Extremely Comfortable)", value: 5 },
        ]}
        numeric
      />
    </>
  )
}

export default TeachingExperience
