import React from "react"

import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { DateObject } from "components/Forms/DatePicker"
import {
  DateField,
  Field,
  FormStatus,
  SessionDateRangeField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import AvailabilitiesInput from "./AvailabilitiesInput"

const ValidationSchema = Yup.object().shape({
  dates: Yup.array().min(2, "Please specify a start and end date"),
  studentRegistrationEndsOn: Yup.number().required(
    "Please specify registration end date"
  ),
  groupLimit: Yup.number().required(
    "Please specify the maximum number of groups for this section"
  ),
  availabilities: Yup.array().of(
    Yup.object().shape({
      startsAt: Yup.number().required("Please specify a start time"),
      endsAt: Yup.number().required("Please specify an end time"),
      weekday: Yup.string().required(
        "Please specify the weekday for this availability"
      ),
    })
  ),
})

const NewSection = ({ course }) => {
  const formState = {
    courseId: course.id,
    dates: [],
    studentRegistrationEndsOn: new DateObject(
      dayjs().subtract(2, "days").toDate()
    ),
    groupLimit: 100,
    availabilities: [],
  }

  const { runMutation } = useMutation(
    buildMutation(createCourseSectionMutation)
  )

  const handleSubmit = (closeModal, values, actions) => {
    const { dates, studentRegistrationEndsOn, availabilities, ...rest } = values
    runMutation({
      startsOn: dates[0].toDate().toString(),
      endsOn: dates[1].toDate().toString(),
      studentRegistrationEndsOn: studentRegistrationEndsOn.toDate().toString(),
      availabilities: availabilities.map(availability => ({
        startsAt: availability.startsAt.toDate(),
        endsAt: availability.endsAt.toDate(),
        weekday: availability.weekday,
      })),
      ...rest,
    })
      .then(
        response => {
          const { errorMessages } = response.createCourseSection
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonText="New Section"
      buttonClassName="btn btn-success"
      modalTitle="New Section"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(closeModal, values, actions)
          }
        >
          <Form>
            <div className="modal-body">
              <SessionDateRangeField name="dates" label="Date Range" />

              <DateField
                name="studentRegistrationEndsOn"
                label="Registration Ends"
              />

              <Field
                name="groupLimit"
                label="Maximum Group Count"
                type="number"
                min={1}
              />

              <AvailabilitiesInput />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <SubmitButton text="Submit" />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createCourseSectionMutation = compress`
  mutation(
    $courseId: ID!
    $startsOn: Date!
    $endsOn: Date!
    $studentRegistrationEndsOn: Date!
    $groupLimit: Int!
    $availabilities: [AvailabilityInputObject!]!
  ) {
    createCourseSection(
      courseId: $courseId
      startsOn: $startsOn
      endsOn: $endsOn
      studentRegistrationEndsOn: $studentRegistrationEndsOn
      groupLimit: $groupLimit
      availabilities: $availabilities
    ) {
      errorMessages
      courseSection {
        id
        startsOn
        endsOn
        studentRegistrationEndsAt
        groupLimit
        studyGroupsRebalanced
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

export default NewSection
