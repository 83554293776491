import React from "react"

const { Map } = require("immutable")

class FullStoryIdentity extends React.Component {
  componentDidMount() {
    if (typeof FS == "undefined") {
      return
    }

    FS.identify(
      this.props.userId,
      new Map(this.props).delete("userId").toObject()
    )
  }

  render() {
    return ""
  }
}

export default FullStoryIdentity
