import React from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import GroupTutoring from "./GroupTutoring"

const SmallClassTutoring = ({ student }) => {
  const client = useClient()
  const [result] = useQuery({
    query,
    client,
    variables: { gradeId: student.grade.id },
  })
  return (
    <UrqlLoadingIndicator
      result={result}
      heading={<h1>Small Class Tutoring</h1>}
    >
      {({ data }) => <GroupTutoring {...data} student={student} />}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query ($gradeId: ID!) {
    viewer {
      stripePaymentMethods {
        id
        default
        type
        brand
        last4
        expirationMonth
        expirationYear
      }
    }

    courses(prepaid: true, maximumStudyGroupSize: 10, gradeId: $gradeId) {
      id
      durationWeeks
      formattedPrepaidPrice
      subject {
        id
        name
      }

      studentRegisterableCourseSections {
        id
        startsOn
        endsOn
        availabilities {
          id
          weekday
          weekdayNumber
          startsAt
          endsAt
        }
      }

      courseCluster {
        name
        formattedDescription
      }
    }
  }
`

export default SmallClassTutoring
