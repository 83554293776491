import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RemoveSubject = ({ subject }) => {
  const [errors, setErrors] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(removeSubjectMutation)
  )
  const removeSubject = closeModal => {
    runMutation({ id: subject.id })
      .then(
        response => {
          const { errorMessages } = response.subjectRemove
          if (errorMessages) {
            setErrors(errorMessages)
          } else {
            closeModal()
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Remove Subject"
      buttonText="Remove Subject"
      buttonClassName="btn btn-sm btn-danger"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              Are you sure? If this subject has any sessions, their subject will
              be set to nothing.
            </p>
          </div>

          <div className="modal-footer flex-column">
            {errors && <div className="alert alert-danger w-100">{errors}</div>}
            <div className="d-flex justify-content-between w-100">
              <button className="btn btn-outline" onClick={closeModal}>
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => removeSubject(closeModal)}
                disabled={running}
              >
                Yes, remove it!
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const removeSubjectMutation = compress`
  mutation($id: ID!) {
    subjectRemove(id: $id) {
      errorMessages
      subject {
        id
        subjectBucket {
          id
        }
        tier {
          id
        }
      }
    }
  }
`

export default RemoveSubject
