import React from "react"

import { Link } from "react-router-dom"

import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/core"

const SemestersSelect = ({ semesters }) => {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon="chevron-down">
        Filter by Academic Year
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to="/sessions">
          All Time
        </MenuItem>
        {semesters.map(semester => (
          <MenuItem
            as={Link}
            key={semester.id}
            to={`/sessions/semesters/${semester.id}`}
          >
            {semester.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default SemestersSelect
