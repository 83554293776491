/* eslint-disable no-console */
import { useEffect } from "react"

const useCloudRoomLogging = ({ room, setError, debug = false }) => {
  useEffect(() => {
    if (!room) return
    if (debug) attachDebugListeners(room, setError)
    attachReconnectingListeners(room, setError)
  }, [room, debug, setError])
}

export default useCloudRoomLogging

function logNetworkQuality(participant, level, stats) {
  if (process.env.NODE_ENV === "development") return
  console.info(
    `network quality changed for ${participant.identity}`,
    level,
    stats
  )
}

function attachDebugListeners(room, setError) {
  room.localParticipant.on("networkQualityLevelChanged", (level, stats) => {
    logNetworkQuality(room.localParticipant, level, stats)
  })

  room.participants.forEach(participant => {
    participant.on("networkQualityLevelChanged", (level, stats) => {
      logNetworkQuality(participant, level, stats)
    })
  })

  room.on("participantConnected", participant => {
    participant.on("networkQualityLevelChanged", (level, stats) => {
      logNetworkQuality(participant, level, stats)
    })
  })

  room.on("dominantSpeakerChanged", participant => {
    if (process.env.NODE_ENV === "development") return
    if (participant) {
      console.info("dominant speaker changed", participant.identity)
    } else {
      console.info("no dominant speaker")
    }
  })
}

function attachReconnectingListeners(room, setError) {
  room.on(
    "trackSubscriptionFailed",
    (error, remoteTrackPublication, remoteParticipant) => {
      console.warn(
        "Failed to subscribe to RemoteTrack from RemoteParticipant",
        {
          kind: remoteTrackPublication.kind,
          participant: remoteParticipant.identity,
          error: error.message,
        }
      )
    }
  )

  room.on("reconnecting", error => {
    if (!error) {
      console.warn("Unknown reconnection error (no error provided)")
      return
    }
    if (error.code === 53001) {
      console.warn("Reconnecting signal", error.message)
    } else if (error.code === 53405) {
      console.warn("Reconnecting media", error.message)
    } else {
      console.warn("Other reconnection error", error)
    }

    setError(
      "It looks like you were disconnected. Please wait a moment while we reconnect you. If this persists, please refresh the page."
    )
  })

  room.on("reconnected", () => {
    console.warn("Reconnected")
    setError()
  })

  room.on("disconnected", (room, error) => {
    if (!error) return
    if (error.code === 20104) {
      console.error(
        "Signaling reconnection failed due to expired AccessToken!",
        error.message
      )
    } else if (error.code === 53000) {
      console.error("Signaling reconnection attempts exhausted!", error.message)
    } else if (error.code === 53204) {
      console.error("Signaling reconnection took too long!", error.message)
    } else {
      console.error("Other disconnection error", error)
    }

    setError(
      "It looks like you were disconnected. Please wait a moment while we reconnect you. If this persists, please refresh the page."
    )
  })
}
