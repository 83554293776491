import React from "react"

import { NotificationContainer } from "react-notifications"

import dayjs from "src/dayjs"
import ordered from "src/ordered"

import { gql, useMutation, useQuery } from "hooks/urql"

import EditableDisplay from "components/Forms/EditableDisplay"
import LocalTime from "components/LocalTime"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import EditQuestions from "./EditQuestions"

import "react-notifications/lib/notifications.css"

type SurveyDetailsProps = {
  id: string
}

const SurveyDetails: React.FC<SurveyDetailsProps> = ({ id }) => {
  const [result] = useQuery({ query: surveyQuery, variables: { id } })
  const [, updateSurvey]: any[] = useMutation(updateSurveyMutation)

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <div>
          <NotificationContainer />
          <dl>
            <dt>Name</dt>
            <dd>
              <EditableDisplay
                displayValue={data.survey.name}
                save={({ value }) =>
                  updateSurvey({ id, input: { name: value } })
                }
              />
            </dd>

            <dt>Organization</dt>
            <dd>
              <EditableDisplay
                displayValue={data.survey.organization.name}
                autocomplete
                isClearable={false}
                api="/api/admins/organizations/autocomplete_name"
                save={({ selectedId }) =>
                  updateSurvey({ id, input: { organizationId: selectedId } })
                }
              />
            </dd>

            <dt>Starts On</dt>
            <dd>
              <EditableDisplay
                editableValue={dayjs(data.survey.startsOn).toDate()}
                displayValue={
                  <LocalTime timestamp={data.survey.startsOn} omitTime />
                }
                type="date"
                save={({ value }) =>
                  updateSurvey({
                    id,
                    input: { startsOn: value.toDate() },
                  })
                }
              />
            </dd>

            <dt>Questions</dt>
            <dd>
              <EditQuestions
                id={id}
                currentQuestions={data.survey.questions}
                allQuestions={data.surveyQuestions}
                updateSurvey={updateSurvey}
              />
            </dd>
          </dl>

          <div className="my-5">
            <hr />
          </div>

          <div>
            <h3>Assigned Course Clusters</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>School</th>
                  <th>Grades</th>
                  <th>Subject</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {ordered(data.survey.courseClusters, "name").map(
                  courseCluster => (
                    <tr key={courseCluster.id}>
                      <td>{courseCluster.name}</td>
                      <td>{courseCluster.school?.name}</td>
                      <td>
                        {courseCluster.grades
                          .map(grade => grade.name)
                          .join(", ")}
                      </td>
                      <td>{courseCluster.subject.name}</td>
                      <td>
                        <a
                          href={courseCluster.showPath}
                          className="btn btn-sm btn-info"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </UrqlLoadingIndicator>
  )
}

const surveyQuery = gql`
  query Survey($id: ID!) {
    survey(id: $id) {
      id
      name
      startsOn
      organization {
        id
        name
      }
      questions {
        id
        text
      }
      courseClusters {
        id
        name
        showPath
        school {
          id
          name
        }
        grades {
          id
          name
        }
        subject {
          id
          name
        }
      }
    }
    surveyQuestions: allSurveyQuestions {
      id
      text
    }
  }
`

const updateSurveyMutation = gql`
  mutation UpdateSurvey($id: ID!, $input: SurveyInputObject!) {
    updateSurvey(id: $id, input: $input) {
      failures {
        message
      }
      survey {
        id
      }
    }
  }
`

export default SurveyDetails
