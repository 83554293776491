import React from "react"

function RealtimeboardDirectLink(props) {
  if (!props.boardId) return null
  return (
    <a
      className={`btn solid green ${props.className}`}
      href={`https://miro.com/app/board/${props.boardId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Whiteboard
    </a>
  )
}

RealtimeboardDirectLink.defaultProps = {
  className: "",
}

export default RealtimeboardDirectLink
