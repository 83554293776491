import React from "react"

import { Field, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { useDisclosure } from "@chakra-ui/core"

import { tutorClient } from "src/graphql-config"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import {
  CheckboxField,
  SelectField,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Evaluation from "../Evaluation"

import StudentParticipation from "./StudentParticipation"
import TutorAssessment from "./TutorAssessment"

const BillSession = ({ id, tutorFeedback, topicsCovered }) => {
  const formState = {
    tutorFeedback,
    topicsCovered,
    participationLevel: [],
    confirmZeroHours: false,
    durationMinutes: null,
    durationHours: null,
  }

  const { isOpen, onOpen } = useDisclosure()

  const loadingState = useQuery(
    buildQuery(airtutorsSessionQuery, { id }, { client: tutorClient })
  )
  const { runMutation } = useMutation(
    buildMutation(billSessionMutation, { client: tutorClient })
  )

  const noShow = (studentParticipation, tutorAssessment) => {
    if (!studentParticipation || !tutorAssessment) return

    return (
      Object.values(studentParticipation).every(
        student => student.participationLevel === "absent"
      ) &&
      Object.values(tutorAssessment).every(
        student => student.tutorAssessment === "absent"
      )
    )
  }

  const requireConfirmZeroHours = (durationHours, durationMinutes) => {
    return durationHours === 0 && durationMinutes === 0
  }

  const notLongDuration = (hours, minutes) => {
    if (hours === null || minutes === null) return true

    const totalDuration = hours + minutes / 60
    return totalDuration <= 1.0
  }

  function sessionDurationValid() {
    const { canBillLongSessions } = loadingState.data.airtutorsSession
    return (
      canBillLongSessions ||
      notLongDuration(this.parent.durationHours, this.parent.durationMinutes)
    )
  }

  function belowMaxDuration() {
    return this.parent.durationHours + this.parent.durationMinutes / 60 <= 3
  }

  function belowScheduledDuration() {
    if (loadingState.data.airtutorsSession.canExceedScheduledDuration)
      return true

    if (
      this.parent.durationMinutes === null ||
      this.parent.durationHours === null
    )
      return true

    const totalDuration =
      this.parent.durationHours * 60 + this.parent.durationMinutes
    return (
      totalDuration <=
      loadingState.data.airtutorsSession.roundedScheduledDurationMinutes
    )
  }

  function noShowDurationValid() {
    const allowLongNoShows =
      loadingState.data.airtutorsSession.organization?.allowLongNoShows
    return (
      allowLongNoShows ||
      notLongDuration(this.parent.durationHours, this.parent.durationMinutes)
    )
  }

  let validationSchema
  if (loadingState.data) {
    const { billedDurationMinutes, roundedSuggestedDurationMinutes } =
      loadingState.data.airtutorsSession

    if (billedDurationMinutes === null) {
      formState.durationMinutes = roundedSuggestedDurationMinutes % 60
      formState.durationHours = Math.floor(roundedSuggestedDurationMinutes / 60)
    } else {
      formState.durationMinutes = billedDurationMinutes % 60
      formState.durationHours = Math.floor(billedDurationMinutes / 60)
    }

    const resourceCompletions = {}
    loadingState.data.airtutorsSession.lessonResourceCompletions.forEach(
      completion => {
        resourceCompletions[completion.id] = {
          id: completion.id,
          tutorMastery: completion.tutorMastery,
          tutorTopicMastery: completion.tutorTopicMastery,
        }
      }
    )
    formState.lessonResourceCompletions = resourceCompletions

    const studentParticipation = {}
    const tutorAssessment = {}
    loadingState.data.airtutorsSession.students.forEach(student => {
      const presenceDuration =
        loadingState.data.airtutorsSession.sessionPresences
          .filter(presence => presence.user.id === student.id)
          .map(presence => presence.duration)
          .reduce((prev, curr) => prev + curr, 0)
      const minimumDuration =
        loadingState.data.airtutorsSession.scheduledDurationMinutes / 4

      studentParticipation[student.id] = {
        studentId: student.id,
        participationLevel: presenceDuration < minimumDuration ? "absent" : "",
      }
      tutorAssessment[student.id] = {
        studentId: student.id,
        tutorAssessment: presenceDuration < minimumDuration ? "absent" : "",
      }
    })
    formState.studentParticipation = studentParticipation
    formState.tutorAssessment = tutorAssessment

    validationSchema = Yup.object().shape({
      actualDuration: Yup.string()
        .when(["durationHours", "durationMinutes"], {
          is: (hours, minutes) => hours === null || minutes === null,
          then: Yup.string().required(
            "Please select an option for both hours and minutes."
          ),
        })
        .when(["studentParticipation", "tutorAssessment"], {
          is: noShow,
          then: Yup.string().test(
            ["durationHours", "durationMinutes"],
            "You cannot bill no shows longer than 1 hour for this session",
            noShowDurationValid
          ),
          otherwise: Yup.string().test(
            "actualDuration",
            "You cannot bill sessions longer than 1 hour for this study group",
            sessionDurationValid
          ),
        })
        .test(
          ["durationHours", "durationMinutes"],
          `Duration cannot exceed scheduled duration of ${loadingState.data.airtutorsSession.roundedScheduledDurationMinutes} minutes`,
          belowScheduledDuration
        )
        .test(
          ["durationHours", "durationMinutes"],
          "Maximum session length is 3 hours",
          belowMaxDuration
        ),
      tutorFeedback: Yup.string().required("Feedback is a required field"),
      topicsCovered: Yup.string().required(
        "Topics covered is a required field"
      ),
      studentParticipation: Yup.mixed().test(
        "studentParticipation",
        "You must specify a participation level for every student",
        studentParticipation => {
          const participationValues = Object.values(studentParticipation)
            .map(sp => sp.participationLevel)
            .filter(Boolean)

          return (
            participationValues.length ===
            loadingState.data.airtutorsSession.students.length
          )
        }
      ),
      tutorAssessment: Yup.mixed().test(
        "tutorAssessment",
        "You must specify an assessment level for every student",
        tutorAssessment => {
          const assessmentValues = Object.values(tutorAssessment)
            .map(sp => sp.tutorAssessment)
            .filter(Boolean)

          return (
            assessmentValues.length ===
            loadingState.data.airtutorsSession.students.length
          )
        }
      ),
      confirmZeroHours: Yup.boolean().when(
        ["durationHours", "durationMinutes"],
        {
          is: requireConfirmZeroHours,
          then: Yup.boolean().oneOf([true], "Please check the box to confirm"),
        }
      ),
    })
  }

  const handleSubmit = (values, actions) => {
    const {
      lessonResourceCompletions,
      studentParticipation,
      tutorAssessment,
      confirmZeroHours,
      durationMinutes,
      durationHours,
      ...rest
    } = values

    runMutation({
      id,
      actualDuration: `${durationHours}:${durationMinutes}`,
      lessonResourceCompletions: Object.values(lessonResourceCompletions),
      studentParticipation: Object.values(studentParticipation).filter(
        pl => pl.participationLevel
      ),
      tutorAssessment: Object.values(tutorAssessment).filter(
        pl => pl.tutorAssessment
      ),
      ...rest,
    })
      .then(
        response => {
          const payload = response.billAirtutorsSession
          const { errorMessage, airtutorsSession } =
            response.billAirtutorsSession
          if (errorMessage) {
            actions.setStatus(payload.errorMessage)
          } else if (airtutorsSession.studyGroup?.hasFutureSessions) {
            window.location = "/"
          } else if (airtutorsSession.studyGroup?.hasAvailabilities) {
            window.location = airtutorsSession.followUpPath
          } else {
            onOpen()
          }
        },
        error => {
          actions.setStatus(
            "An error occurred while attempting to bill session"
          )
        }
      )
      .catch(error => {
        actions.setStatus("An error occurred while attempting to bill session")
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <ModalWithProvidedBody
            isOpen={isOpen}
            hideTrigger
            modalClassName="bootstrap-modal"
            modalTitle="Schedule Follow Up"
            closeModal={() => {
              window.location = "/"
            }}
          >
            {() => (
              <>
                <div className="modal-body">
                  Would you like to schedule a follow-up session?
                </div>
                <div className="modal-footer">
                  <a
                    href={data.airtutorsSession.followUpPath}
                    className="btn solid blue"
                  >
                    Yes
                  </a>
                  <a href="/" className="btn solid red">
                    No
                  </a>
                </div>
              </>
            )}
          </ModalWithProvidedBody>
          <Formik
            initialValues={formState}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status, values, setFieldValue }) => (
              <Form>
                <hr />
                <StudentParticipation
                  students={data.airtutorsSession.students}
                  sessionPresences={data.airtutorsSession.sessionPresences}
                />
                <hr />
                <TutorAssessment
                  students={data.airtutorsSession.students}
                  sessionPresences={data.airtutorsSession.sessionPresences}
                />

                {data.airtutorsSession.lessonResourceCompletions.length > 0 && (
                  <hr />
                )}
                <Evaluation
                  data={data}
                  values={values}
                  setFieldValue={setFieldValue}
                />

                <hr />
                {data.airtutorsSession.sessionIncomplete && (
                  <p className="inline-block font-medium italic text-red-500">
                    Note: Please double-check the actual duration as the session
                    was not ended properly. For all future sessions, hit the
                    "End Session" button before closing the browser.
                  </p>
                )}

                <div className="flex flex-col items-center">
                  <label className="form-control-label flex flex-col items-center">
                    <h3 className="mt-0 mb-3 font-medium">Actual Duration</h3>
                  </label>

                  <p className="notice p-3 font-medium">
                    Select a duration, rounded to the nearest 15 minute
                    increment; ie, a 40 minute session should be billed for 45
                    minutes
                  </p>

                  <div className="flex flex-col items-center sm:flex-row sm:gap-12">
                    <SelectField
                      name="durationHours"
                      label="Hours"
                      options={[
                        { label: "0 hours", value: 0 },
                        { label: "1 hour", value: 1 },
                        { label: "2 hours", value: 2 },
                        { label: "3 hours", value: 3 },
                      ]}
                      defaultValue={
                        values.durationHours !== null && {
                          label: `${values.durationHours} hours`,
                          value: values.durationHours,
                        }
                      }
                      className="min-w-[250px]"
                    />
                    <div className="mt-5 italic">and</div>
                    <SelectField
                      name="durationMinutes"
                      label="Minutes"
                      options={[
                        { label: "0 minutes", value: 0 },
                        { label: "15 minutes", value: 15 },
                        { label: "30 minutes", value: 30 },
                        { label: "45 minutes", value: 45 },
                      ]}
                      defaultValue={
                        values.durationMinutes !== null && {
                          label: `${values.durationMinutes} minutes`,
                          value: values.durationMinutes,
                        }
                      }
                      className="min-w-[250px]"
                    />
                  </div>
                  <ErrorMessage name="actualDuration" touched={true} />
                </div>

                <hr />
                <div className="flex flex-col items-center">
                  <label className="form-control-label w-3/4 text-center">
                    <h3 className="mt-0 mb-3 font-medium">Session Feedback</h3>
                    <p className="notice p-3 font-medium">
                      Note: If these students are with an organization/district,
                      your feedback may be be provided to school administrators.
                    </p>
                    <Field name="tutorFeedback">
                      {({ field }) => (
                        <textarea
                          className="form-control"
                          {...field}
                          maxLength={500}
                        />
                      )}
                    </Field>
                    <p className="mt-0 w-3/4 text-left text-sm italic">
                      {500 - values.tutorFeedback.length}/500 characters
                      remaining
                    </p>
                  </label>
                  <ErrorMessage name="tutorFeedback" />
                </div>

                <div className="mt-7 flex flex-col items-center">
                  <label className="form-control-label w-3/4 text-center">
                    <h3 className="mt-0 mb-3 font-medium">Topics Covered</h3>
                    <Field name="topicsCovered">
                      {({ field }) => (
                        <textarea
                          className="form-control"
                          {...field}
                          maxLength={500}
                        />
                      )}
                    </Field>
                  </label>
                  <p className="mt-0 w-3/4 text-left text-sm italic">
                    {500 - values.topicsCovered.length}/500 characters remaining
                  </p>
                  <ErrorMessage name="topicsCovered" />
                </div>

                <div className="text-center">
                  {status && <div className="alert mt-3">{status}</div>}
                  {requireConfirmZeroHours(
                    values.durationHours,
                    values.durationMinutes
                  ) && (
                    <CheckboxField
                      label={
                        <span className="italic text-red-600">
                          Please confirm that you intend to bill for zero hours.
                        </span>
                      }
                      name="confirmZeroHours"
                    />
                  )}
                  <SubmitButton isSubmitting={isSubmitting} text="Complete" />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const airtutorsSessionQuery = compress`
  query($id: ID!) {
    airtutorsSession(id: $id) {
      id
      followUpPath
      scheduledDurationMinutes
      roundedScheduledDurationMinutes
      roundedSuggestedDurationMinutes
      billedDurationMinutes
      sessionIncomplete
      canBillLongSessions
      canExceedScheduledDuration
      organization {
        id
        allowLongNoShows
      }
      students(active: true) {
        id
        fullName
        lastName
      }
      sessionPresences {
        id
        duration
        user {
          id
        }
      }
      lessonResourceCompletions {
        id
        tutorMastery
        tutorTopicMastery
        lessonResource {
          name
          sortOrder
        }
        lessonTopic {
          sortOrder
        }
        student {
          fullName
        }
        completedLessonTopic {
          id
          name
          sortOrder
        }
      }
    }
  }
`

const billSessionMutation = compress`
  mutation(
    $id: ID!
    $actualDuration: String!
    $tutorFeedback: String!
    $topicsCovered: String!
    $lessonResourceCompletions: [LessonResourceCompletionInputObject!]
    $studentParticipation: [StudentParticipationInputObject!]!
    $tutorAssessment: [TutorAssessmentInputObject!]!
  ) {
    billAirtutorsSession(
      id: $id
      actualDuration: $actualDuration
      tutorFeedback: $tutorFeedback
      topicsCovered: $topicsCovered
      lessonResourceCompletions: $lessonResourceCompletions
      studentParticipation: $studentParticipation
      tutorAssessment: $tutorAssessment
    ) {
      airtutorsSession {
        id
        followUpPath
        studyGroup {
          hasFutureSessions
          hasAvailabilities
        }
      }
      errorMessage
    }
  }
`

export default BillSession
