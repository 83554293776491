import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import TutorObserved from "components/Admin/Tutor/Observed"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const PendingTutorObservations = () => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      tutorsQuery,
      { page },
      {
        onMutation: [
          {
            when: "tutorMarkForObservation",
            run: ({ softReset, currentResults }, response) => {
              const { tutor } = response.tutorMarkForObservation
              if (!tutor) return

              currentResults.viewer.observedTutors.data.push(tutor)
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) =>
        data.viewer.observedTutors.data.length === 0 ? (
          <p className="lead">No tutors found</p>
        ) : (
          <>
            <table className="table">
              <thead className="text-primary">
                <tr>
                  <th>Tutor</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.viewer.observedTutors.data.map(tutor => (
                  <tr key={tutor.id}>
                    <td>
                      <a href={tutor.showPath}>{tutor.fullName}</a>
                    </td>
                    <td>
                      <TutorObserved tutorId={tutor.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={data.viewer.observedTutors.currentPage}
              totalPages={data.viewer.observedTutors.totalPages}
              changePage={setPage}
            />
          </>
        )
      }
    </WithLoadingIndicator>
  )
}

const tutorsQuery = compress`
  query($page: Int!) {
    viewer {
      observedTutors(observationState: scheduled, page: $page) {
        currentPage
        totalPages
        data {
          id
          fullName
          showPath
        }
      }
    }
  }
`

export default PendingTutorObservations
