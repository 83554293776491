import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import useAssessmentSectionOptions from "hooks/useAssessmentSectionOptions"

import {
  DateField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  assessmentSectionId: Yup.string().required(
    "Please select an assessment section"
  ),
  score: Yup.number()
    .required("Please specify a score")
    .min(1, "Score must be at least 1"),
})

const NewScore = ({ userId }) => {
  const formState = {
    userId,
    assessmentSectionId: "",
    score: "",
    takenOn: "",
  }

  const [errors, setErrors] = useState([])
  const assessmentSectionOptions = useAssessmentSectionOptions()
  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const { takenOn, ...rest } = values
    const variables = { ...rest }
    if (takenOn) {
      variables.takenOn = moment(takenOn.toDate()).startOf("day").format()
    }
    runMutation(variables)
      .then(
        response => {
          const { failures } = response.assessmentScoreCreate
          if (failures) {
            setErrors(failures)
            actions.setSubmitting(false)
          } else {
            closeModal()
          }
        },
        () => {
          setErrors([{ message: "Something went wrong" }])
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        setErrors([{ message: "Something went wrong" }])
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Assessment Score"
      buttonText="Add Assessment Score"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <SelectField
                name="assessmentSectionId"
                label="Assessment Section"
                options={assessmentSectionOptions}
                valueAttribute="id"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                menuPortalTarget={document.body}
              />
              <Field name="score" type="number" label="Score" />
              <DateField name="takenOn" label="Taken On" />
            </div>

            <div className="modal-footer flex-col">
              {errors.map((error, index) => (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              ))}
              <FormStatus />
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = compress`
  mutation($userId: ID!, $assessmentSectionId: ID!, $score: Int!, $takenOn: Date) {
    assessmentScoreCreate(userId: $userId, assessmentSectionId: $assessmentSectionId, score: $score, takenOn: $takenOn) {
      failures {
        message
      }
    }
  }
`

export default NewScore
