import React from "react"

import { Box, Flex, List, ListItem, PseudoBox } from "@chakra-ui/core"

import ordered from "src/ordered"

const StudyGroupRow = ({ studyGroup }) => {
  return (
    <PseudoBox
      as="tr"
      key={studyGroup.id}
      borderBottom="1px"
      borderBottomColor="gray.400"
      _last={{ border: "none" }}
    >
      <td>
        <Flex align="center" direction="row">
          <Box flexBasis="25%">{studyGroup.name}</Box>
        </Flex>
      </td>
      <td>
        <Flex>
          <List>
            {ordered(studyGroup.students, "lastName").map(student => (
              <ListItem key={student.id}>{student.fullName}</ListItem>
            ))}
          </List>
        </Flex>
      </td>
    </PseudoBox>
  )
}

export default StudyGroupRow
