import React from "react"

import addTutorMutation from "./addTutorMutation"
import MatchedUsers from "./index"
import loadMatchedTutorsQuery from "./loadMatchedTutorsQuery"
import removeTutorMutation from "./removeTutorMutation"

const MatchedTutors = props => {
  const { studentId } = props
  return (
    <MatchedUsers
      sourceUserId={studentId}
      sourceKey="student"
      matchedKey="tutor"
      loadUsersQuery={loadMatchedTutorsQuery}
      matchUserMutation={addTutorMutation}
      unmatchUserMutation={removeTutorMutation}
      autocompletePath="/api/admins/tutors/autocomplete_full_name"
      searchAutocompletePath={`/api/admins/students/${studentId}/autocomplete_recommended_tutors`}
    />
  )
}

export default MatchedTutors
