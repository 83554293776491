import React, { createRef, useEffect, useRef } from "react"

import { BarChart as ApacheBarChart } from "echarts/charts"
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"

import classNames from "src/classNames"

type BarChartProps = {
  height: "small" | "medium" | "large"
  data: any
  className?: string
}
const BarChart: React.FC<BarChartProps> = ({ height, data, className }) => {
  const chartContainer = createRef<HTMLDivElement>()
  const chartObject = useRef<echarts.ECharts>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    echarts.use([
      ApacheBarChart,
      CanvasRenderer,
      GridComponent,
      TitleComponent,
      TooltipComponent,
    ])
    chartObject.current = echarts.init(chartContainer.current)

    return () => {
      if (chartContainer.current) {
        echarts.dispose(chartContainer.current)
      }
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const resize = () => {
      if (chartObject.current) chartObject.current.resize()
    }
    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  const heightClass = {
    small: "h-[25vh]",
    medium: "h-[50vh]",
    large: "h-[80vh]",
  }

  useEffect(() => {
    if (data) chartObject.current.setOption(data)
  }, [data])

  return (
    <div
      className={classNames(heightClass[height], className)}
      ref={chartContainer}
    />
  )
}

export default BarChart
