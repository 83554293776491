import React, { useCallback, useEffect } from "react"

import isArray from "./isArray"

export default function Select({
  selectedDate,
  focused,
  handleFocusedDate,
  state,
  setState,
  format,
}) {
  const handleFocused = useCallback(
    focused => {
      setState({
        ...state,
        focused,
      })

      handleFocusedDate(focused)
    },
    [handleFocusedDate, setState, state]
  )

  useEffect(() => {
    if (!isArray(selectedDate) || selectedDate.length === 0) return
    if (!focused) handleFocused(selectedDate[0])
  }, [focused, selectedDate, handleFocused])

  return (
    isArray(selectedDate) && (
      <div style={{ display: "flex", padding: "5px 0" }}>
        <select
          className="rmdp-input"
          style={{ height: "26px", width: "90%", margin: "auto" }}
          value={focused?.day || ""}
          onChange={e =>
            handleFocused(
              selectedDate.find(date => date.day.toString() === e.target.value)
            )
          }
        >
          {selectedDate.map(date => (
            <option key={date.day} value={date.day}>
              {date.format(format)}
            </option>
          ))}
        </select>
      </div>
    )
  )
}
