import React, { useRef } from "react"

import DatePicker from "react-multi-date-picker"

import { CalendarDaysIcon } from "@heroicons/react/24/outline"

const DateRangeSelectButton = ({ updateDateRange }) => {
  const datePicker = useRef() as any
  const handleDateChange = dates => {
    updateDateRange(dates)
  }

  const openCalendar = () => {
    datePicker.current.openCalendar()
  }

  return (
    <div className="relative flex">
      <button onClick={openCalendar}>
        <span className="sr-only">Change date range</span>
        <CalendarDaysIcon className="h-8 w-8 text-indigo-500" />
      </button>
      <div className="absolute left-0 top-6 z-10 origin-top-left">
        <DatePicker
          ref={datePicker}
          onChange={handleDateChange}
          inputClass="hidden"
          editable={false}
          arrow={false}
          range
        />
      </div>
    </div>
  )
}

export default DateRangeSelectButton
