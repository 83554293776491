import React from "react"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

import GeneratedStudyGroup from "./GeneratedStudyGroup"
import NewGeneratedGroup from "./NewGeneratedGroup"
import UnassignedStudents from "./UnassignedStudents"

const Generation = ({ generation, organizationId }) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h3>
            {generation.teacherSubject.teacher.fullName}:{" "}
            {generation.teacherSubject.subject.name}
          </h3>
          <div className="row">
            <div className="col-xl-6">
              <div className="d-flex justify-content-between">
                <label className="font-medium">Minimum Group Size</label>
                <span>{generation.minimumGroupSize}</span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">Maximum Group Size</label>
                <span>{generation.maximumGroupSize}</span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">Sessions Per Week</label>
                <span>{generation.sessionsPerWeek}</span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">Excused Absences Enabled</label>
                <span>{generation.excusedAbsencesEnabled ? "Yes" : "No"}</span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">
                  Request Student Study Group Confirmations
                </label>
                <span>
                  {generation.requestStudyGroupConfirmations ? "Yes" : "No"}
                </span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">
                  Tutors can bill for longer than scheduled duration
                </label>
                <span>
                  {generation.canExceedScheduledDuration ? "Yes" : "No"}
                </span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">In-School Program</label>
                <span>{generation.inSchool ? "Yes" : "No"}</span>
              </div>

              <div className="d-flex justify-content-between">
                <label className="font-medium">
                  Allow Sessions Longer Than 1 Hour
                </label>
                <span>
                  {generation.allowLongSessions === null
                    ? "Uses Organization Default"
                    : generation.allowLongSessions === false
                    ? "No"
                    : "Yes"}
                </span>
              </div>

              {generation.startsOn && (
                <div className="d-flex justify-content-between">
                  <label className="font-medium">Date Range</label>
                  <span>
                    <LocalTime timestamp={generation.startsOn} omitTime />{" "}
                    &ndash; <LocalTime timestamp={generation.endsOn} omitTime />
                  </span>
                </div>
              )}

              {generation.assessmentSection && (
                <>
                  <div className="d-flex justify-content-between">
                    <label className="font-medium">Assessment</label>
                    <span>
                      {generation.assessmentSection.assessment.name}:{" "}
                      {generation.assessmentSection.name}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <label className="font-medium">
                      Maximum Score Deviation
                    </label>
                    <span>{generation.maximumScoreDeviation}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          {ordered(
            generation.studyGroups,
            "availabilities",
            "0",
            "startsAt"
          ).map(group => (
            <GeneratedStudyGroup
              key={group.id}
              studyGroup={group}
              organizationId={organizationId}
              assessmentSectionId={generation?.assessmentSection?.id}
            />
          ))}
        </div>
        <div className="col-6">
          <UnassignedStudents
            assessmentSectionId={generation?.assessmentSection?.id}
            students={generation.teacherSubject.unassignedStudents}
          />
          <NewGeneratedGroup
            generation={generation}
            organizationId={organizationId}
          />
        </div>
      </div>
      <hr />
    </>
  )
}

export default Generation
