import React from "react"

import { Composition } from "atomic-layout"

import { css } from "@emotion/core"

import RemoteParticipant from "components/TwilioCloudRoom/RemoteParticipant"

import styles from "./screen-share-layout-styles.module.scss"

const ScreenShareLayout = ({
  api,
  users,
  channel,
  studentScreenShareDisabled,
  controlsLockedUserIds,
  id,
}) => {
  const usersArray = Array.from(users.values())

  const screenShareUser = usersArray.filter(
    u => u.videoTrack && u.videoTrack.name === "screenshare"
  )[0]
  const otherUsers = usersArray.filter(
    u => !u.videoTrack || u.videoTrack.name !== "screenshare"
  )

  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.get("users") && otherUsers.length > 0) {
    const userSize = parseInt(searchParams.get("users"))
    const userSizeArray = [...Array(userSize)]
    userSizeArray.forEach((_, index) => {
      const copy = { ...otherUsers[0] }
      copy.identity = `${copy.identity}-${index}`
      otherUsers.push(copy)
    })
  }

  const layout =
    otherUsers.length > 0
      ? `
    screenshare
    students
  `
      : `screenshare`

  return (
    <Composition areas={layout} templateCols="1fr" templateRows="1fr 20%">
      {({ Screenshare, Students }) => (
        <>
          <Screenshare
            className={`${
              otherUsers.length > 0
                ? styles.screenshareContainer
                : styles.soloScreenshareContainer
            }`}
          >
            <RemoteParticipant
              key={screenShareUser.identity}
              user={screenShareUser}
              api={api}
              channel={channel}
              studentScreenShareDisabled={studentScreenShareDisabled}
              controlsLocked={controlsLockedUserIds.includes(
                screenShareUser.id
              )}
              id={id}
              controlled
            />
          </Screenshare>
          <Students className={styles.studentsContainer}>
            <div
              css={css`
                overflow: scroll;
                > div {
                  display: inline-flex;
                  width: auto;
                  margin-right: 15px;
                  margin-bottom: 15px;
                }
              `}
            >
              {otherUsers.map(user => (
                <RemoteParticipant
                  key={user.identity}
                  user={user}
                  api={api}
                  channel={channel}
                  studentScreenShareDisabled={studentScreenShareDisabled}
                  controlsLocked={controlsLockedUserIds.includes(user.id)}
                  id={id}
                  compact
                  controlled
                />
              ))}
            </div>
          </Students>
        </>
      )}
    </Composition>
  )
}

export default ScreenShareLayout
