const formatDate = (date, opts) => {
  const mergedOptions = Object.assign({ usFormat: false, separator: "-" }, opts)
  const { usFormat, separator } = mergedOptions

  const dateComponents = usFormat
    ? [date.getMonth() + 1, date.getDate(), date.getFullYear()]
    : [date.getDate(), date.getMonth() + 1, date.getFullYear()]

  return dateComponents.join(separator)
}

export { formatDate }
