import React, { useEffect } from "react"

import { useFormikContext } from "formik"
import Select from "react-select"

import { weekdayOptions } from "src/enums"

import { DateObject, TimePicker } from "components/Forms/DatePicker"
import { ErrorMessage } from "components/Forms/Formik"

const AvailabilityInput = ({ availability, arrayHelpers, index }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    if (!availability.startsAt) {
      arrayHelpers.replace(
        values.availabilities.findIndex(av => av.id === availability.id),
        {
          ...availability,
          startsAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
          endsAt: new DateObject().setHour(13).setMinute(0).setSecond(0),
        }
      )
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="form-group">
        <label className="form-label w-100">
          Starts At
          <TimePicker
            value={availability.startsAt}
            onChange={date => {
              arrayHelpers.replace(
                values.availabilities.findIndex(
                  av => av.id === availability.id
                ),
                {
                  ...availability,
                  startsAt: date,
                  endsAt: new DateObject(date).add(1, "hour"),
                }
              )
            }}
          />
        </label>
      </div>

      <div className="form-group">
        <label className="form-label w-100">
          Ends At
          <TimePicker
            value={availability.endsAt}
            onChange={date => {
              arrayHelpers.replace(
                values.availabilities.findIndex(
                  av => av.id === availability.id
                ),
                {
                  ...availability,
                  endsAt: date,
                }
              )
            }}
          />
        </label>
      </div>

      <div>
        <ErrorMessage name={`availabilities[${index}].startsAt`} />
        <ErrorMessage name={`availabilities[${index}].endsAt`} />
      </div>

      <div className="form-group">
        <label className="form-label w-100">
          Day of week
          <Select
            options={weekdayOptions}
            menuPortalTarget={document.body}
            onChange={selectedOption =>
              arrayHelpers.replace(
                values.availabilities.findIndex(
                  av => av.id === availability.id
                ),
                {
                  ...availability,
                  weekday: selectedOption.value,
                }
              )
            }
          />
        </label>
        <ErrorMessage name={`availabilities[${index}].weekday`} />
      </div>
    </>
  )
}

export default AvailabilityInput
