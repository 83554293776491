import React from "react"

import { Form, Formik } from "formik"

import { gql, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const EditBudget = ({ budget }) => {
  const formState = {
    name: budget.name,
    active: budget.active,
    archiveStudyGroups: false,
    poNumber: budget.poNumber,
    prepaid: budget.prepaid,
    notes: budget.notes,
  }

  const [, updateBudget] = useMutation(updateBudgetMutation)

  const handleSubmit = (values, actions) => {
    updateBudget({ budgetId: budget.id, input: values })
      .then(
        result => {
          const { failures } = result.data.updateBudget
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Budget"
      buttonText="Edit"
      buttonClassName="btn"
    >
      {({ closeModal }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ values }) => (
            <Form>
              <div className="modal-body">
                <Field name="name" label="Name" />
                <CheckboxField name="active" label="Active" />
                {budget.active && !values.active && (
                  <CheckboxField
                    name="archiveStudyGroups"
                    label="Archive Study Groups"
                  />
                )}
                <Field name="poNumber" label="PO Number" />
                <CheckboxField name="prepaid" label="Prepaid" />
                <TextAreaField name="notes" label="Notes" />
              </div>
              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <SubmitButton text="Update Budget" />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateBudgetMutation = gql`
  mutation ($budgetId: ID!, $input: BudgetUpdateInputObject!) {
    updateBudget(budgetId: $budgetId, input: $input) {
      failures {
        message
      }
    }
  }
`

export default EditBudget
