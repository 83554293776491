import React from "react"

import { FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import { NotificationManager } from "react-notifications"

import ordered from "src/ordered"

import { RadioGroup, SubmitButton } from "components/Forms/Formik"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const NotifyTutors = ({ courseClusterId }) => {
  const loadingState = useQuery(
    buildQuery(courseSectionsQuery, { courseClusterId })
  )
  const { runMutation } = useMutation(buildMutation(notifyTutorsMutation))

  const formState = { courseSectionIds: [] }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ courseClusterId, ...values })
      .then(
        response => {
          const { errorMessages } = response.courseClusterNotifyTutors
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
            NotificationManager.info("Notifications will be sent momentarily")
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Notify Tutors"
      buttonText="Notify Tutors"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ status, isSubmitting, values }) => (
            <Form>
              <div className="modal-body">
                <WithLoadingIndicator loadingState={loadingState}>
                  {({ data }) => (
                    <div className="form-group">
                      <p>
                        Select course sections to enable tutor registration for.
                        <br />
                        List will only include sections whose student
                        registration is closed and have not already been enabled
                        for tutor registration.
                        <br />
                        (You can just click send notifications if the list is
                        empty)
                      </p>

                      <FieldArray name="courseSectionIds">
                        {arrayHelpers => (
                          <RadioGroup
                            type="checkbox"
                            name="courseSectionIds"
                            values={ordered(
                              data.courseCluster.courseSections,
                              "startsOn"
                            ).map(courseSection => ({
                              value: courseSection.id,
                              label: (
                                <div>
                                  Course duration:{" "}
                                  {courseSection.course.durationWeeks} Weeks
                                  <br />
                                  Starts:{" "}
                                  <LocalTime
                                    timestamp={courseSection.startsOn}
                                    omitTime
                                  />
                                  <br />
                                  Ends:{" "}
                                  <LocalTime
                                    timestamp={courseSection.endsOn}
                                    omitTime
                                  />
                                  <br />
                                  Meets:{" "}
                                  {courseSection.availabilities
                                    .map(av => av.weekday)
                                    .join(", ")}
                                </div>
                              ),
                            }))}
                            onChange={e => {
                              if (e.currentTarget.checked) {
                                arrayHelpers.push(e.currentTarget.value)
                              } else {
                                arrayHelpers.remove(
                                  values.courseSectionIds.findIndex(
                                    s => s === e.currentTarget.value
                                  )
                                )
                              }
                            }}
                          />
                        )}
                      </FieldArray>
                    </div>
                  )}
                </WithLoadingIndicator>
              </div>

              <div className="modal-footer d-flex flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Send Notifications"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const courseSectionsQuery = compress`
  query($courseClusterId: ID!) {
    courseCluster(id: $courseClusterId) {
      courseSections(studentRegistrationClosed: true, tutorRegistrationOpen: false) {
        id
        startsOn
        endsOn
        course {
          durationWeeks
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

const notifyTutorsMutation = compress`
  mutation($courseClusterId: ID!, $courseSectionIds: [ID!]) {
    courseClusterNotifyTutors(courseClusterId: $courseClusterId, courseSectionIds: $courseSectionIds) {
      errorMessages
    }
  }
`

export default NotifyTutors
