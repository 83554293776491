import React, { useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import Header from "~tailwindui/Form/Header"
import SubHeader from "~tailwindui/Form/SubHeader"
import Table from "~tailwindui/Table/Table"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"

export type NoShowsProps = Record<string, never>

const NoShowsPage: React.FC<NoShowsProps> = () => {
  const defaultStartDate = dayjs().startOf("year")
  const defaultEndDate = dayjs()
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const [studyGroupPage, setStudyGroupPage] = useState(1)
  const [studentPage, setStudentPage] = useState(1)

  const [studyGroupsResult] = useQuery({
    query: noShowStudyGroupsQuery,
    variables: { startDate, endDate, page: studyGroupPage },
  })
  const [studentsResult] = useQuery({
    query: noShowStudentsQuery,
    variables: { startDate, endDate, page: studentPage },
  })

  return (
    <>
      <Header title="No Shows"></Header>
      <DataDateRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      <SubHeader title="Study Groups" />
      <WithLoadingIndicator result={studyGroupsResult}>
        {({ data }) => (
          <>
            {data.viewer.organization.noShowStudyGroups.data.length > 0 ? (
              <Table
                changePage={setStudyGroupPage}
                data={data.viewer.organization.noShowStudyGroups}
                headers={["Name", "Students", "No Show Sessions", "Teacher"]}
                valueMapper={({ studyGroup, noShows }) => [
                  studyGroup.name,
                  <ul key="studentsList" className="list-none">
                    {studyGroup.students.map(student => (
                      <li key={student.id}>{student?.fullName}</li>
                    ))}
                  </ul>,
                  noShows,
                  studyGroup.teachers.length > 0 ? (
                    <ul className="list-none">
                      {studyGroup.teachers.map(teacher => (
                        <li key={teacher.id}>{teacher?.fullName}</li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  ),
                ]}
                rowCallback={({ studyGroup }) =>
                  (window.location.href = `/organization_admin/study_groups/${studyGroup.id}`)
                }
              />
            ) : (
              <span>
                Great! There are no study groups with multiple no shows in the
                given date range.
              </span>
            )}
          </>
        )}
      </WithLoadingIndicator>

      <SubHeader title="Students" />
      <WithLoadingIndicator result={studentsResult}>
        {({ data }) => (
          <>
            {data.viewer.organization.noShowStudents.data.length > 0 ? (
              <Table
                changePage={setStudentPage}
                data={data.viewer.organization.noShowStudents}
                headers={["Name", "School", "No Show Sessions"]}
                valueMapper={({ student, noShows }) => [
                  student.fullName,
                  student.school?.name,
                  noShows,
                ]}
                rowCallback={({ student }) =>
                  (window.location.href = `/organization_admin/students/${student.id}`)
                }
              />
            ) : (
              <span>
                Great! There are no students with multiple no shows in the given
                date range.
              </span>
            )}
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const noShowStudyGroupsQuery = gql`
  query noShowStudyGroupsQuery(
    $startDate: DateTime!
    $endDate: DateTime!
    $page: Int!
  ) {
    viewer {
      organization {
        noShowStudyGroups(
          startDate: $startDate
          endDate: $endDate
          page: $page
        ) {
          currentPage
          totalPages
          data {
            studyGroup {
              id
              name
              students {
                id
                fullName
              }
              teachers {
                id
                fullName
              }
            }
            noShows
          }
        }
      }
    }
  }
`
const noShowStudentsQuery = gql`
  query noShowStudentsQuery(
    $startDate: DateTime!
    $endDate: DateTime!
    $page: Int!
  ) {
    viewer {
      organization {
        noShowStudents(startDate: $startDate, endDate: $endDate, page: $page) {
          currentPage
          totalPages
          data {
            student {
              id
              fullName
              school {
                id
                name
              }
            }
            noShows
          }
        }
      }
    }
  }
`

export default NoShowsPage
