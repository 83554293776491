import React from "react"

import { useFormikContext } from "formik"

import {
  CheckboxField,
  DateField,
  Field,
  RadioField,
} from "components/Forms/Formik/hookComponents"

const GenerationSettings = ({ organization }) => {
  const { values } = useFormikContext()

  return (
    <>
      <CheckboxField name="scheduledGeneration" label="Scheduled Generation" />
      {values.scheduledGeneration && (
        <div className="ml-4">
          <Field
            name="invoiceNumberPrefix"
            label="Invoice Number Prefix"
            placeholder={`Ex: ${organization.slug.slice(0, 4)}`}
          />

          <RadioField
            name="generationFrequency"
            options={[
              { label: "2nd of Month", value: "monthly" },
              {
                label: "2nd and 16th of Month",
                value: "bimonthly",
              },
            ]}
          />
          <DateField
            name="startDate"
            label="First Statement Generation"
            onOpenPickNewDate={false}
            minDate={new Date()}
            mapDays={({ date }) => {
              const day = date.day
              const invalidDate =
                (values.generationFrequency === "monthly" && day !== 2) ||
                (day !== 2 && day !== 16)
              if (invalidDate)
                return {
                  disabled: true,
                  style: { color: "#ccc" },
                }
            }}
          />
          <div>
            <span className="italic">
              If you need a statement before this date, you can manually
              generate one by going to the "New Statement" page and selecting
              this configuration.
            </span>
          </div>
          <br />
        </div>
      )}
    </>
  )
}

export default GenerationSettings
