import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import ClipboardButton from "components/ClipboardButton"
import {
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  applicant: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("Please provide a first name"),
  lastName: Yup.string().required("Please provide a last name"),
})

const NewTutorApplication = () => {
  // using a field NOT named email to dodge chrome and its damned autocomplete ignorance (we also cannot use type=email)
  const formState = { applicant: "", firstName: "", lastName: "" }
  const [tutorApplicationUrl, setTutorApplicationUrl] = useState()
  const [, createTutorApplication] = useMutation(createTutorApplicationMutation)

  const handleSubmit = (values, actions) => {
    const { applicant, ...rest } = values
    createTutorApplication({ email: applicant, ...rest }).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { tutorApplication, failures } = result.data.createTutorApplication
      if (failures) {
        handleFailure(actions, failures)
        return
      }

      actions.setStatus()
      actions.setSubmitting(false)
      actions.resetForm()
      setTutorApplicationUrl(tutorApplication.editUrl)
    })
  }

  const resetModal = () => {
    setTutorApplicationUrl()
  }

  return (
    <ModalWithProvidedBody
      buttonText="Invite Applicant"
      buttonClassName="btn btn-info"
      modalTitle="New Tutor Application"
      afterClose={resetModal}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              {!tutorApplicationUrl ? (
                <>
                  <Field name="applicant" label="E-mail" autoComplete="off" />
                  <Field name="firstName" label="First Name" />
                  <Field name="lastName" label="Last Name" />
                </>
              ) : (
                <>
                  <div className="alert alert-info italic">
                    Application created! They will receive an email with
                    instructions momentarily.
                  </div>
                  <div className="form-group my-2">
                    <input
                      disabled
                      value={tutorApplicationUrl}
                      className="form-control w-100 text-md !cursor-default"
                    />
                  </div>
                  <div className="flex w-full justify-between">
                    <ClipboardButton
                      content={tutorApplicationUrl}
                      className="btn btn-sm btn-info"
                    >
                      Click to copy URL
                    </ClipboardButton>
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />

              <div className="w-100 flex justify-between">
                {tutorApplicationUrl ? (
                  <>
                    <button type="button" className="btn" onClick={closeModal}>
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={resetModal}
                    >
                      Add Another Applicant
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <SubmitButton />
                  </>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createTutorApplicationMutation = gql`
  mutation ($email: String!, $firstName: String!, $lastName: String!) {
    createTutorApplication(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      failures {
        message
      }
      tutorApplication {
        editUrl
      }
    }
  }
`

export default NewTutorApplication
