export enum ValueFormat {
  Number = "number",
  Percent = "percent",
  Currency = "currency",
}

export enum LayoutDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum ChangeDirection {
  Increase = "increase",
  Decrease = "decrease",
  Constant = "constant",
}

export enum AlertLevel {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Light = "light",
}

export enum Color {
  Blue = "blue",
  Yellow = "yellow",
  Green = "green",
  Red = "red",
}

export enum TimezoneOption {
  Alaska = "Alaska",
  Hawaii = "Hawaii",
  Pacific = "Pacific",
  Arizona = "Arizona",
  Mountain = "Mountain",
  Central = "Central",
  Eastern = "Eastern",
}

export enum BulkActionResourceType {
  StudyGroup = "studyGroup",
  CourseCluster = "courseCluster",
}
