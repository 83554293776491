import React from "react"

import { useFormikContext } from "formik"

import { css } from "@emotion/core"

const FormStatus = () => {
  const { status, errors } = useFormikContext()

  return (
    <div
      css={css`
        margin-top: 30px;
      `}
    >
      {(status || Object.keys(errors).length > 0) && (
        <div
          className="alert alert-danger"
          css={css`
            padding: 9px 15px;
            margin-bottom: 15px;
            text-align: left;
          `}
        >
          {status || "Please fix validation errors above"}
        </div>
      )}
    </div>
  )
}

export default FormStatus
