import React, { useContext } from "react"

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/core"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type StudentScreenShareMessageProps = Record<never, any>

const StudentScreenShareMessage: React.FC<
  StudentScreenShareMessageProps
> = () => {
  const { localUser, studentScreenShareMessage, setStudentScreenShareMessage } =
    useContext(CloudroomContext)

  if (!studentScreenShareMessage) return null

  return localUser.type === "Teacher" ? (
    <Modal isOpen>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Screen Share Failed</ModalHeader>
        <ModalBody>
          <p>{studentScreenShareMessage}</p>
          <div className="flex">
            <button
              className="mr-4 rounded bg-info-700 px-3 py-1 font-semibold text-white"
              onClick={() => {
                setStudentScreenShareMessage()
              }}
            >
              Okay
            </button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <ModalWithProvidedBody
      isOpen
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Screen Share Failed"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">{studentScreenShareMessage}</div>
          <div className="modal-footer">
            <button
              className="btn solid blue btn-info"
              onClick={() => {
                closeModal()
                setStudentScreenShareMessage()
              }}
            >
              Okay
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default StudentScreenShareMessage
