import React from "react"

import { motion } from "framer-motion"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import NewSection from "./NewSection"
import SectionScores from "./SectionScores"

const AssessmentDetails = ({ id }) => {
  const loadingState = useQuery(
    buildQuery(
      assessmentQuery,
      { id },
      {
        onMutation: [
          {
            when: "assessmentSectionCreate",
            run: ({ softReset, currentResults }, response) => {
              const { assessmentSection } = response.assessmentSectionCreate
              if (!assessmentSection) return

              currentResults.assessment.assessmentSections.push(
                assessmentSection
              )
              softReset(currentResults)
            },
          },
          {
            when: "assessmentSectionRemove",
            run: ({ softReset, currentResults }, response) => {
              const { assessmentSection } = response.assessmentSectionRemove
              if (!assessmentSection) return

              currentResults.assessment.assessmentSections =
                currentResults.assessment.assessmentSections.filter(
                  section => section.id !== assessmentSection.id
                )
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <h4 className="mt-0">Sections</h4>
          <NewSection assessmentId={id} />
          <EditableList>
            {ordered(data.assessment.assessmentSections, "name").map(
              section => (
                <motion.li
                  key={section.id}
                  positionTransition
                  className="mb-3 border-t-0 border-l-0 border-r-0 border-b-2 border-solid border-gray-200"
                >
                  <Item
                    mutation={removeSectionMutation}
                    mutationProps={{ assessmentSectionId: section.id }}
                  >
                    <dl className="m-0">
                      <dt>Name</dt>
                      <dd>{section.name}</dd>

                      <dt>Subject</dt>
                      <dd>{section.subject.name}</dd>
                    </dl>
                  </Item>
                </motion.li>
              )
            )}
          </EditableList>

          <h4>Scores</h4>

          {ordered(data.assessment.assessmentSections, "name").map(section => (
            <SectionScores key={section.id} assessmentSection={section} />
          ))}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const assessmentQuery = compress`
  query($id: ID!) {
    assessment(id: $id) {
      id
      name

      assessmentSections {
        id
        name
        subject {
          id
          name
        }
      }
    }
  }
`

const removeSectionMutation = compress`
  mutation($assessmentSectionId: ID!) {
    assessmentSectionRemove(assessmentSectionId: $assessmentSectionId) {
      failures {
        message
      }
      assessmentSection {
        id
      }
    }
  }
`

export default AssessmentDetails
