import React, { useRef, useState } from "react"

import { Flex, Icon, IconButton, Input } from "@chakra-ui/core"

const OrderIndicator = props => {
  const { direction } = props

  if (direction === "ascending") return <Icon name="chevron-up" />
  if (direction === "descending") return <Icon name="chevron-down" />
  return null
}

const CellHeader = props => {
  const {
    children,
    field,
    order,
    filter,
    updateOrder,
    updateFilter,
    removeFilter,
    readOnly,
    filterElement,
    orderDisabled,
    isDateFilter,
    ...other
  } = props
  const handler = useRef()

  let direction = false
  if (order && order.field === field) direction = order.direction

  const [value, setValue] = useState(filter ? filter.value : "")
  const [isFiltering, setIsFiltering] = useState(!!filter)

  return (
    <Flex
      align="center"
      justify="space-between"
      cursor={orderDisabled ? "default" : "pointer"}
      userSelect="none"
      {...other}
    >
      {isFiltering ? (
        <React.Fragment>
          {filterElement ? (
            filterElement
          ) : (
            <Input
              type="text"
              placeholder={`Search by ${field}`}
              value={value}
              autoFocus
              disabled={readOnly}
              onChange={e => {
                const value = e.target.value
                setValue(value)
                if (handler.current) clearTimeout(handler.current)
                handler.current = setTimeout(
                  () => updateFilter({ field, value }),
                  500
                )
              }}
            />
          )}
          <IconButton
            icon="small-close"
            variant="unstyled"
            onClick={() => {
              removeFilter(field)
              setValue("")
              setIsFiltering(false)
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Flex
            align="center"
            onClick={() =>
              !orderDisabled &&
              updateOrder({
                field,
                direction:
                  direction === "ascending" ? "descending" : "ascending",
              })
            }
          >
            {children}
            <OrderIndicator direction={direction} />
          </Flex>
          {filter !== false && (
            <Icon name="search" mr={4} onClick={() => setIsFiltering(true)} />
          )}
        </React.Fragment>
      )}
    </Flex>
  )
}

export default CellHeader
