import React from "react"

const SlackChannelHint = () => (
  <div className="text-sm italic">
    <div>To find the slack channel ID:</div>
    <ul>
      <li>Right click on a channel in the sidebar</li>
      <li>Select "View Channel Details"</li>
      <li>Scroll to the very bottom of the resulting window</li>
      <li>Click the little copy icon</li>
      <li>Come back here and paste it</li>
      <li>Voila!</li>
    </ul>
    <div>
      You will also need to ensure the Air Jarvis app is added to each channel.
      If you do not have slack admin privileges, please reach out to Amy or
      Chris to help you out.
    </div>
    <ul>
      <li>Open the channel details for the channel you want to configure</li>
      <li>Click the Integrations tab</li>
      <li>Click "Add an App" (second section)</li>
      <li>Add Air Jarvis</li>
      <li>All set!</li>
    </ul>
  </div>
)

export default SlackChannelHint
