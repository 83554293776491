import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required(
    "Please enter the applicant's new Air Tutors email address"
  ),
  emailCreated: Yup.boolean().oneOf(
    [true],
    "You must create their email account before submitting this form"
  ),
})

const ApproveApplicant = ({ id }) => {
  const formState = { id, email: "", emailCreated: false }
  const [, runMutation] = useMutation(workflowMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values).then(result => {
      if (result.error) {
        NotificationManager.error(result.error.message)
      }

      const { failures } = result.data.approveTutorApplication
      if (failures) {
        NotificationManager.error(failures.map(f => f.message).join(", "))
      } else {
        NotificationManager.success("Applicant approved")
      }
    })
  }

  return (
    <ModalWithProvidedBody
      buttonText="FINAL: Approve Applicant (this will generate their tutor account)"
      buttonClassName="btn btn-success"
      modalTitle="Create Tutor Account"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <div className="alert bg-red-500">
                <span className="font-bold">
                  YOU MUST CREATE THEIR AIR TUTORS EMAIL ACCOUNT IN GMAIL FIRST!
                </span>
              </div>
              <div className="alert bg-blue-300 font-medium text-gray-800/80">
                Failure to do so will result in all emails from the app being
                blocked!
                <br />
                If you cannot create their email, reach out to your manager
              </div>
              <Field name="email" label="Air Tutors E-mail" autoFocus />
              <CheckboxField
                name="emailCreated"
                label="This e-mail has already been created in gmail"
              />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button onClick={closeModal} className="btn btn-danger">
                  Cancel
                </button>
                <SubmitButton text="Create Tutor" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const workflowMutation = gql`
  mutation ($id: ID!, $email: String!) {
    approveTutorApplication(id: $id, email: $email) {
      failures {
        message
      }
      tutorApplication {
        id
      }
    }
  }
`

export default ApproveApplicant
