import React, { ReactElement } from "react"

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid"

import { AlertLevel, ButtonVariant, Color, Size } from "~tailwindui/types/enums"

export const chipIconColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-sky-500"
    case Color.Yellow:
      return "bg-yellow-500"
    case Color.Red:
      return "bg-red-500"
    case Color.Green:
      return "bg-green-500"
  }
}

export const buttonSizeClassNames = (size: Size) => {
  switch (size) {
    case Size.Small:
      return "rounded px-2 py-1 text-xs"
    case Size.Medium:
      return "rounded-md px-2.5 py-1.5 text-sm"
    case Size.Large:
      return "rounded-md px-3.5 py-2.5 text-sm"
  }
}

export const buttonColorClassNames = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.Primary:
      return "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    case ButtonVariant.Secondary:
      return "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    case ButtonVariant.Light:
      return "bg-indigo-50 text-indigo-600 hover:bg-indigo-100"
  }
}

export const alertBackgroundClassNames = (level: AlertLevel) => {
  switch (level) {
    case AlertLevel.Error:
      return "bg-red-50"
    case AlertLevel.Warning:
      return "bg-yellow-50"
    case AlertLevel.Info:
      return "bg-blue-50"
    case AlertLevel.Success:
      return "bg-green-50"
  }
}

export const alertTextClassNames = (level: AlertLevel) => {
  switch (level) {
    case AlertLevel.Error:
      return "text-red-800"
    case AlertLevel.Warning:
      return "text-yellow-800"
    case AlertLevel.Info:
      return "text-blue-800"
    case AlertLevel.Success:
      return "text-green-800"
  }
}

export const alertIcon = (level: AlertLevel): ReactElement<any, any> => {
  switch (level) {
    case AlertLevel.Error:
      return <XCircleIcon className="h5 w-5 text-red-400" aria-hidden="true" />
    case AlertLevel.Warning:
      return (
        <ExclamationTriangleIcon
          className="h5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      )
    case AlertLevel.Info:
      return (
        <InformationCircleIcon
          className="h5 w-5 text-blue-400"
          aria-hidden="true"
        />
      )
    case AlertLevel.Success:
      return (
        <CheckCircleIcon className="h5 w-5 text-green-400" aria-hidden="true" />
      )
  }
}
