import React, { useContext } from "react"

import { useFormikContext } from "formik"

import Checkbox from "components/Forms/Checkbox"
import { Field } from "components/Forms/Formik/hookComponents"

import FormStateContext from "../FormStateContext"

const StudentPhoneNumberField = () => {
  const { setFieldValue } = useFormikContext()
  const [store, setStore] = useContext(FormStateContext)

  const parentForm = store.parentProfileFields
  const defaultToParentPhoneNumber = store.defaultToParentPhoneNumber || false
  const setDefaultToParentPhoneNumber = value => {
    setStore({ ...store, defaultToParentPhoneNumber: value })
  }

  const handleDefaultPhoneNumber = setToParent => {
    const phoneNumber = setToParent ? parentForm.phoneNumber : ""
    setFieldValue("studentPhoneNumber", phoneNumber)
    setDefaultToParentPhoneNumber(setToParent)
  }

  return (
    <>
      <Field
        name="studentPhoneNumber"
        label="Student Phone Number"
        autoComplete="phoneNumber"
        disabled={defaultToParentPhoneNumber}
      />
      <Checkbox
        checked={defaultToParentPhoneNumber}
        onChange={e => handleDefaultPhoneNumber(e.currentTarget.checked)}
      >
        Use Parent Phone Number
      </Checkbox>
    </>
  )
}

export default StudentPhoneNumberField
