import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import RemoveSurvey from "./RemoveSurvey"

type SurveyListProps = Record<string, never>

const SurveyList: React.FC<SurveyListProps> = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({ query: surveysQuery, variables: { page } })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          {data.surveys.data.map(survey => (
            <div key={survey.id} className="mb-3">
              <h3 className="m-0">{survey.name}</h3>
              <p className="italic">
                Starts: <LocalTime timestamp={survey.startsOn} omitTime />
              </p>
              <ul className="list-unstyled">
                {survey.questions.map(question => (
                  <li key={question.id}>{question.text}</li>
                ))}
              </ul>
              <div className="flex">
                <a href={survey.showPath} className="btn btn-info">
                  View
                </a>
                <RemoveSurvey survey={survey} />
              </div>
            </div>
          ))}
          <Pagination
            currentPage={data.surveys.currentPage}
            changePage={setPage}
            totalPages={data.surveys.totalPages}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const surveysQuery = gql`
  query Surveys($page: Int) {
    surveys(page: $page) {
      currentPage
      totalPages

      data {
        id
        name
        startsOn
        showPath
        questions {
          id
          text
        }
      }
    }
  }
`

export default SurveyList
