import React from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import ProgramRegistration from "components/Family/ProgramRegistration"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const StudentProgramRegistration = () => {
  const client = useClient()
  const [result] = useQuery({ query, client })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => <ProgramRegistration student={data.viewer} />}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query {
    viewer {
      id
      firstName
      grade {
        id
        name
        tier {
          id
          gradeRange
        }
      }
    }
  }
`

export default StudentProgramRegistration
