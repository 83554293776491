import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
})

const EditCollege = ({ college, isOpen, onClose }) => {
  const formState = {
    collegeId: college?.id,
    name: college?.name,
    diplomaMill: college?.diploma_mill,
  }
  const [, updateCollege] = useMutation(updateCollegeMutation)

  const handleSubmit = (values, actions) => {
    updateCollege(values).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { failures } = result.data.updateCollege
      if (failures) {
        handleFailure(actions, failures)
        return
      } else {
        window.location.reload()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      hideTrigger
      modalTitle="Edit College"
      isOpen={isOpen}
      closeModal={onClose}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field name="name" label="Name" />
              <CheckboxField name="diplomaMill" label="Diploma Mill?" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />

              <div className="w-100 flex justify-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateCollegeMutation = gql`
  mutation ($collegeId: ID!, $name: String!, $diplomaMill: Boolean!) {
    updateCollege(
      collegeId: $collegeId
      name: $name
      diplomaMill: $diplomaMill
    ) {
      failures {
        message
      }
      college {
        id
        name
        diplomaMill
      }
    }
  }
`

export default EditCollege
