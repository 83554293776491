import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import {
  FormStatus,
  SessionDateRangeField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  dates: Yup.array().min(2, "You must specify both a start and and end date"),
})

const GenerateSessions = ({ organizationId }) => {
  const formState = {
    organizationId,
    dates: [],
  }
  const { runMutation } = useMutation(buildMutation(generateSessionsMutation))

  const handleSubmit = (values, actions) => {
    const { dates, ...rest } = values
    const startsOn = moment(dates[0].toDate()).format()
    const endsOn = moment(dates[1].toDate()).format()

    runMutation({ startsOn, endsOn, ...rest })
      .then(
        response => {
          const { errorMessages } =
            response.organizationAllGenerationsGenerateSessions
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Generate Sessions"
      buttonText="Generate Sessions"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <SessionDateRangeField name="dates" label="Date Range" />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="d-flex justify-content-between w-full">
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Generate" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const generateSessionsMutation = compress`
  mutation($organizationId: ID!, $startsOn: Date!, $endsOn: Date!) {
    organizationAllGenerationsGenerateSessions(
      organizationId: $organizationId
      startsOn: $startsOn
      endsOn: $endsOn
    ) {
      errorMessages
    }
  }
`

export default GenerateSessions
