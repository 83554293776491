import React from "react"

import axios from "axios"
import PropTypes from "prop-types"

class FavoriteStudent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { favorite: this.props.favorite }
  }

  render() {
    let button
    if (this.state.favorite) {
      button = <i className="fa fa-heart" onClick={this.removeFavorite}></i>
    } else {
      button = <i className="fa fa-heart-o" onClick={this.addFavorite}></i>
    }

    return (
      <React.Fragment>
        <button className="btn no-outline red hover:!bg-inherit hover:!text-inherit">
          {button}
        </button>
      </React.Fragment>
    )
  }

  addFavorite = () => {
    axios
      .patch(`/api/tutors/students/${this.props.studentId}/add_favorite.json`)
      .then(response => {
        const json = response.data
        this.setState({ favorite: json.favorite })
      })
  }

  removeFavorite = () => {
    axios
      .patch(
        `/api/tutors/students/${this.props.studentId}/remove_favorite.json`
      )
      .then(response => {
        const json = response.data
        this.setState({ favorite: json.favorite })
      })
  }
}

FavoriteStudent.propTypes = {
  studentId: PropTypes.number,
  favorite: PropTypes.bool,
}
export default FavoriteStudent
