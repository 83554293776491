import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { parentClient as client } from "src/graphql-config"

import CourseClusters from "components/CourseClusters"
import courseClusterFragment from "components/CourseClusters/fragment.js"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const StudentCourseClusters = ({ student, setHeading }) => {
  const loadingState = useQuery(
    buildQuery(coursesQuery, { studentId: student.id }, { client })
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <CourseClusters
          {...data.student}
          student={student}
          setHeading={setHeading}
        />
      )}
    </WithLoadingIndicator>
  )
}

const coursesQuery = compress`
  ${courseClusterFragment}
  query($studentId: ID!) {
    student(id: $studentId) {
      academicCourseClusters: organizationRegisterableCourseClusters(courseType: Academic) {
        ...CourseClusterFields
      }
      enrichmentCourseClusters: organizationRegisterableCourseClusters(courseType: Enrichment) {
        ...CourseClusterFields
      }
    }
  }
`

export default StudentCourseClusters
