import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  FormStatus,
  SubmitButton,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  observationComments: Yup.string().required(
    "Please provide observation comments"
  ),
})

const TutorObserved = ({ tutorId }) => {
  const formState = {
    tutorId,
    observationComments: "",
  }
  const { runMutation } = useMutation(buildMutation(observedMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.tutorObserved
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Complete Tutor Observation"
      buttonText="Complete Observation"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <TextAreaField
                name="observationComments"
                label="Observation Comments"
              />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="w-100 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <SubmitButton text="Complete Observation" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const observedMutation = compress`
  mutation($tutorId: ID!, $observationComments: String!) {
    tutorObserved(tutorId: $tutorId, observationComments: $observationComments) {
      errorMessages
    }
  }
`

export default TutorObserved
