import React from "react"

import { Field, getIn } from "formik"

import styles from "./styles.module.scss"

const ErrorMessage = ({
  name,
  fieldName = undefined,
  className = "",
  touched = undefined,
}) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      let touch = getIn(form.touched, fieldName || name)
      if (touched !== undefined) {
        touch = touched
      }
      if (!touch || !error || typeof error !== "string") return null
      return (
        <div className={`${styles.container} ${className} d-inline-block`}>
          <span>{error}</span>
        </div>
      )
    }}
  </Field>
)

export default ErrorMessage
