import React from "react"

import axios from "axios"
import AsyncSelect from "react-select/async"
import AsyncCreatable from "react-select/async-creatable"

import airbrake from "src/airbrake-notifier"

import ClearIndicator from "./ClearIndicator"

class AutocompleteSelect extends React.Component {
  fetchOptions = (inputValue, callback) => {
    if (!this.props.api) return
    axios(`${this.props.api}?filter=${inputValue}&${this.props.searchOptions}`)
      .then(
        response => {
          const json = response.data
          callback(json.data)
        },
        rejection => {
          airbrake.notify({
            name: "AutocompleteSelect",
            message: "AutocompleteSelect request rejected",
            error: rejection,
            params: {
              props: this.props,
              message: rejection.message,
              url: rejection.request.responseUrl,
              response: rejection.response?.data,
            },
          })
          callback([{ label: rejection.response.data.error }])
        }
      )
      .catch(error => {
        airbrake.notify({
          name: "AutocompleteSelect",
          message: "AutocompleteSelect caught",
          error,
        })
      })
  }

  clearSelects = () => {
    if (this.props.clearableSelects === undefined) return
    this.props.clearableSelects.forEach(component => {
      if (component === this) return
      component.clearValue()
    })
  }

  clearValue = () => {
    setTimeout(() => {
      this.ref.select.select.clearValue()
    }, 0)
  }

  selectStyles = {
    ...this.props.styles,
    container: provided => ({
      ...provided,
      maxWidth: 250,
    }),
  }

  render() {
    const components = { ...this.props.components }
    if (this.props.withLoadingIndicator) {
      components.ClearIndicator = ClearIndicator
    }
    const { creatable, ...rest } = this.props

    const AsyncComponent = creatable ? AsyncCreatable : AsyncSelect

    return (
      <AsyncComponent
        loadOptions={this.fetchOptions}
        onChange={event => this.props.onChange(event ? event.value : "")}
        onFocus={this.clearSelects}
        ref={ref => {
          this.ref = ref
        }}
        defaultValue={this.props.selected}
        isClearable={this.props.isClearable}
        {...rest}
        styles={this.selectStyles}
        components={components}
      />
    )
  }
}

AutocompleteSelect.defaultProps = {
  components: {},
  isClearable: true,
}

export default AutocompleteSelect
