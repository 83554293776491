import React from "react"

import { gql } from "urql"

import { tutorClient as client } from "src/urql-client"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const NewExpense = () => {
  const formState = {
    expenseType: "",
    amount: "",
    description: "",
    comments: "",
    receipts: [],
  }
  const [result] = useQuery({ query: tutorQuery, client })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => <Form formState={formState} tutor={data.viewer} />}
    </UrqlLoadingIndicator>
  )
}

const tutorQuery = gql`
  query {
    viewer {
      canSubmitGeneralExpenses
      hasSubmittedFingerprintExpense
      hasSubmittedIntroSessionExpense
    }
  }
`

export default NewExpense
