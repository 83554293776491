import React, { useRef, useState } from "react"

import classNames from "src/classNames"

export type ExpandableImageProps = {
  images: { url: string; filename: string }[]
}

const ExpandableImageCollection: React.FC<ExpandableImageProps> = ({
  images,
}) => {
  const container = useRef(null)
  const [expanded, setExpanded] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: container.current.offsetTop - 60,
      behavior: "smooth",
    })
  }

  return (
    <div
      ref={container}
      onClick={() => {
        if (expanded) {
          setExpanded(false)
          scrollToTop()
        } else {
          setExpanded(true)
        }
      }}
      className={classNames("flex space-x-4", expanded && "flex-col")}
    >
      {images.map(image => (
        <div key={image.url}>
          <img
            className={classNames(
              "cursor-pointer",
              expanded ? "max-w-full" : "inline-block h-80"
            )}
            key={image.url}
            src={image.url}
            alt={image.filename}
          />
        </div>
      ))}
    </div>
  )
}

export default ExpandableImageCollection
