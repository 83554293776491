import React, { useState } from "react"

import { gql, useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const UserInfoModal = ({ user, reset }) => {
  const [, runMutation] = useMutation(userMatchedMutation)
  const [errors, setErrors] = useState([])
  const [info, setInfo] = useState()
  const [contacted, setContacted] = useState(false)

  const closeModal = () => {
    setErrors([])
    setInfo()
    setContacted(false)
    reset()
  }

  const userMatched = () => {
    runMutation({ userId: user.id })
      .then(
        result => {
          if (result.error) {
            setErrors([result.error])
          }
          const { failures } = result.data.userMatched
          if (failures) {
            setErrors(failures)
          } else {
            setInfo("User marked as contacted")
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
      .finally(() => setContacted(true))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="User Info"
      isOpen={!!user}
      hideTrigger
      hideCloseButton
    >
      {!user ? null : (
        <>
          <div className="modal-body">
            <p className="lead font-italic">
              Please click the contact button
              <span className="font-weight-bold">&nbsp;before&nbsp;</span>
              contacting them to ensure multiple admins are not sending the same
              message.
            </p>

            <dl>
              <dt>Name</dt>
              <dd>{user.fullName}</dd>

              <dt>Email</dt>
              <dd>{user.email}</dd>

              <dt>User Type</dt>
              <dd>{user.type}</dd>

              <dt>Phone</dt>
              <dd>{user.phoneNumber}</dd>
            </dl>

            <div>
              <a href={user.showPath} target="_blank" rel="noopener noreferrer">
                View Details (opens in new tab)
              </a>
            </div>
          </div>

          <div className="modal-footer flex-column">
            {errors.length > 0 && (
              <div className="alert alert-danger">
                {errors.map(error => (
                  <div key={error.message}>{error.message}</div>
                ))}
              </div>
            )}

            {info && <div className="alert alert-info">{info}</div>}

            <div className="w-100 d-flex justify-content-between">
              {contacted ? (
                <button onClick={closeModal} className="btn btn-info">
                  Close
                </button>
              ) : (
                <>
                  <button onClick={closeModal} className="btn btn-danger">
                    Cancel
                  </button>
                  <button onClick={userMatched} className="btn btn-success">
                    Contact
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const userMatchedMutation = gql`
  mutation ($userId: ID!) {
    userMatched(userId: $userId) {
      failures {
        message
      }
      user {
        id
      }
    }
  }
`

export default UserInfoModal
