import React, { useState } from "react"

import { gql } from "urql"

import { useQuery } from "hooks/urql"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type StudyGroup = {
  id: number
  name: string
  showPath: string
}

type Session = {
  id: number
  studyGroup: StudyGroup
  startsAt: Date
  showPath: string
}

export type BudgetlessSessionsProps = {
  statementId: number
}

const BudgetlessSessions: React.FC<BudgetlessSessionsProps> = ({
  statementId,
}) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: budgetlessSessionsQuery,
    variables: { statementId, page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { statement } }) => (
        <>
          {statement.budgetlessSessions.data.length === 0 ? (
            <div className="m-3 italic">No budgetless sessions</div>
          ) : (
            <>
              <h4>Budgetless Sessions</h4>
              <table className="table">
                <thead className="text-primary">
                  <tr>
                    <th>Study Group</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {statement.budgetlessSessions.data.map((session: Session) => (
                    <tr key={session.id}>
                      <td>
                        {session.studyGroup ? (
                          <a href={session.studyGroup.showPath}>
                            {session.studyGroup.name}
                          </a>
                        ) : (
                          <span className="italic">
                            Missing Group: Contact Developer!
                          </span>
                        )}
                      </td>
                      <td>
                        <LocalTime timestamp={session.startsAt} omitTime />
                      </td>
                      <td>
                        <a
                          className="btn btn-sm btn-info"
                          href={session.showPath}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                totalPages={statement.budgetlessSessions.totalPages}
                currentPage={statement.budgetlessSessions.currentPage}
                changePage={setPage}
              />
            </>
          )}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const budgetlessSessionsQuery = gql`
  query ($statementId: ID!, $page: Int) {
    statement(id: $statementId) {
      id
      budgetlessSessions(page: $page) {
        totalPages
        currentPage
        data {
          id
          studyGroup {
            id
            name
            showPath
          }
          startsAt
          showPath
        }
      }
    }
  }
`

export default BudgetlessSessions
