import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SectionScores = ({ assessmentSection }) => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(scoresQuery, { id: assessmentSection.id, page })
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <h5>{assessmentSection.name} Scores</h5>
          {data.assessmentSection.assessmentScores.data.map(score => (
            <dl
              key={score.id}
              className="mb-3 border-t-0 border-l-0 border-r-0 border-b-2 border-solid border-gray-200"
            >
              <dt>User</dt>
              <dd>
                <a href={score.user.showPath}>{score.user.fullName}</a>
              </dd>

              <dt>Score</dt>
              <dd>{score.score}</dd>

              <dt>Taken On</dt>
              <dd>
                {score.takenOn ? (
                  <LocalTime timestamp={score.takenOn} omitTime />
                ) : (
                  "Unknown"
                )}
              </dd>
            </dl>
          ))}
          <Pagination
            currentPage={data.assessmentSection.assessmentScores.currentPage}
            totalPages={data.assessmentSection.assessmentScores.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const scoresQuery = compress`
  query($id: ID!, $page: Int!) {
    assessmentSection(id: $id) {
      assessmentScores(page: $page) {
        currentPage
        totalPages
        data {
          id
          score
          takenOn
          user {
            ... on Student {
              id
              fullName
              showPath
            }
          }
        }
      }
    }
  }
`

export default SectionScores
