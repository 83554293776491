import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { participationLevelOptions } from "src/enums"

import { gql, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const PresentValidationSchema = Yup.object().shape({
  participationLevel: Yup.string().required("Participation level is required"),
})

const AbsentValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required("This field is required"),
})

const StudentPresenceStatus = ({
  studentSessionId,
  present,
  noShow,
  excusedAbsence,
  canExcuseAbsences,
}) => {
  const formState = { participationLevel: "" }

  const [, runPresentMutation] = useMutation(markPresentMutation)
  const [, runAbsentMutation] = useMutation(markAbsentMutation)

  const markPresent = (values, actions) => {
    runPresentMutation({
      studentSessionId,
      participationLevel: values.participationLevel,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkPresent
          if (failures) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const markAbsent = (cancellationType, values, actions) => {
    runAbsentMutation({
      studentSessionId,
      cancellationReason: values.cancellationReason,
      cancellationType: cancellationType,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkAbsent
          if (failures) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <div className="text-center">
      {present ? (
        <div className="btn btn-outline-dark disabled">Present</div>
      ) : (
        <ModalWithProvidedBody
          modalTitle="Mark as Present"
          buttonText="Mark as present"
          buttonClassName="btn btn-success"
        >
          {({ closeModal }) => (
            <Formik
              initialValues={formState}
              validationSchema={PresentValidationSchema}
              onSubmit={markPresent}
            >
              <Form>
                <div className="modal-body">
                  <SelectField
                    name="participationLevel"
                    label="Participation Level"
                    options={participationLevelOptions}
                  />
                </div>

                <div className="modal-footer flex-col">
                  <FormFailures />
                  <div className="flex w-full justify-between">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <SubmitButton text="Update" />
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </ModalWithProvidedBody>
      )}

      <div>
        {noShow ? (
          <div className="btn btn-outline-dark disabled">No Show</div>
        ) : (
          <ModalWithProvidedBody
            buttonClassName="btn solid btn-danger"
            buttonText="Mark as No Show"
            modalClassName="bootstrap-modal"
            modalTitle="Submit No Show"
          >
            {({ closeModal }) => (
              <Formik
                initialValues={{ cancellationReason: "" }}
                validationSchema={AbsentValidationSchema}
                onSubmit={(values, actions) =>
                  markAbsent("noShow", values, actions)
                }
              >
                <Form>
                  <div className="modal-body">
                    <Field
                      name="cancellationReason"
                      label="Why are you updating this student's no show status?"
                    />
                  </div>
                  <div className="modal-footer" style={{ flexFlow: "column" }}>
                    <FormFailures />
                    <div className="w-100 d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn solid blue btn-info"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <SubmitButton
                        text="Submit"
                        className="btn solid red btn-danger"
                      />
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
          </ModalWithProvidedBody>
        )}
      </div>

      {excusedAbsence ? (
        <div className="btn btn-outline-dark disabled">Excused</div>
      ) : canExcuseAbsences ? (
        <ModalWithProvidedBody
          buttonClassName="btn solid btn-success"
          buttonText="Excuse Absence"
          modalClassName="bootstrap-modal"
          modalTitle="Submit Excused Absence"
        >
          {({ closeModal }) => (
            <Formik
              initialValues={{ cancellationReason: "" }}
              validationSchema={AbsentValidationSchema}
              onSubmit={(values, actions) =>
                markAbsent("excusedAbsence", values, actions)
              }
            >
              <Form>
                <div className="modal-body">
                  <Field
                    name="cancellationReason"
                    label="What is their reason for missing class?"
                  />
                </div>
                <div className="modal-footer" style={{ flexFlow: "column" }}>
                  <FormFailures />
                  <div className="w-100 d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn solid blue btn-info"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <SubmitButton
                      text="Submit"
                      className="btn solid red btn-danger"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </ModalWithProvidedBody>
      ) : null}
    </div>
  )
}

const markPresentMutation = gql`
  mutation markPresent(
    $studentSessionId: ID!
    $participationLevel: ParticipationLevelEnum!
  ) {
    studentSessionMarkPresent(
      studentSessionId: $studentSessionId
      participationLevel: $participationLevel
    ) {
      failures {
        message
      }
    }
  }
`

const markAbsentMutation = gql`
  mutation markAbsent(
    $studentSessionId: ID!
    $cancellationReason: String!
    $cancellationType: CancellationTypeEnum!
  ) {
    studentSessionMarkAbsent(
      studentSessionId: $studentSessionId
      cancellationReason: $cancellationReason
      cancellationType: $cancellationType
    ) {
      failures {
        message
      }
    }
  }
`

export default StudentPresenceStatus
