const csrfString = document.cookie.split("; ").find(c => c.match("CSRF-TOKEN="))

let csrfToken

if (!csrfString) {
  window.location.reload()
} else {
  csrfToken = csrfString.replace("CSRF-TOKEN=", "")
}

export default decodeURIComponent(csrfToken)
