import React, { useRef, useState } from "react"

import { motion } from "framer-motion"
import {
  buildMutation,
  buildQuery,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import styled from "styled-components"

import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Pagination from "components/Pagination"
import RemoveButton from "components/RemoveButton"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Search from "./Search"

const List = styled.div`
  list-style: none;
  padding: 0;
  > div {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    &:nth-of-type(odd) {
      background-color: #e3e3e3;
    }
  }
`

const Header = styled.div`
  margin-bottom: 15px;
  display: flex;
  h4 {
    margin: 0;
  }
  > div {
    flex-grow: 1;
    margin-left: 15px;
  }
`

const MatchedUsers = props => {
  const {
    sourceUserId,
    sourceKey,
    matchedKey,
    autocompletePath,
    searchAutocompletePath,
    loadUsersQuery,
    matchUserMutation,
    unmatchUserMutation,
  } = props
  const selectRef = useRef(null)
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      loadUsersQuery,
      { sourceUserId, page: page },
      {
        onMutation: [
          {
            when: "matchUser",
            run: ({ softReset, currentResults }, response) => {
              const matchedUser = response.matchUser[matchedKey]
              if (
                !currentResults[sourceKey].matchedUsers.data.find(
                  user => user.id === matchedUser.id
                )
              ) {
                currentResults[sourceKey].matchedUsers.data.push(matchedUser)
                softReset(currentResults)
              }
            },
          },
          {
            when: "unmatchUser",
            run: ({ softReset, currentResults }, response) => {
              const unmatchedUser = response.unmatchUser[matchedKey]
              const updatedMatchedUsers = currentResults[
                sourceKey
              ].matchedUsers.data.filter(user => user.id !== unmatchedUser.id)
              currentResults[sourceKey].matchedUsers.data = updatedMatchedUsers
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )
  const { runMutation: matchUser } = useMutation(
    buildMutation(matchUserMutation)
  )
  const { runMutation: unmatchUser } = useMutation(
    buildMutation(unmatchUserMutation)
  )

  const changePage = newPage => {
    setPage(newPage)
  }

  const onChange = value => {
    if (!value?.value) return
    matchUser({ sourceUserId, matchUserId: value.value }).then(() => {
      selectRef.current.clearValue()
    })
  }

  const removeUser = user => {
    unmatchUser({ sourceUserId, matchUserId: user.id })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading matched tutors"
      heading={
        <Header>
          <h4>Matched Users</h4>
          <Search autocompletePath={searchAutocompletePath} />
        </Header>
      }
    >
      {({ data }) => (
        <>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <AutocompleteSelect
                  ref={selectRef}
                  api={autocompletePath}
                  onChange={onChange}
                  placeholder="Match User..."
                />
              </div>
            </div>
          </div>

          <div className="ml-3">
            <List className="row">
              {ordered(data[sourceKey].matchedUsers.data, "lastName").map(
                user => {
                  return (
                    <motion.div
                      key={user.id}
                      positionTransition
                      className="col-6 col-md-4 col-lg-3"
                    >
                      <div>
                        <RemoveButton onClick={() => removeUser(user)} />
                      </div>
                      <div>
                        <a href={user.showPath}>
                          <span>{user.fullName}</span>
                        </a>

                        <div className="font-weight-bold mt-2">
                          Tutor &lt;&gt; Student
                        </div>
                        <div>
                          Student dials: {user.studentToTutorPhoneNumber}
                        </div>
                        <div>Tutor dials: {user.tutorToStudentPhoneNumber}</div>

                        <div className="font-weight-bold mt-2">
                          Tutor &lt;&gt; Parent
                        </div>
                        <div>Parent dials: {user.parentToTutorPhoneNumber}</div>
                        <div>Tutor dials: {user.tutorToParentPhoneNumber}</div>
                      </div>
                    </motion.div>
                  )
                }
              )}
            </List>
            <div className="row">
              <Pagination
                totalPages={data[sourceKey].matchedUsers.totalPages}
                currentPage={data[sourceKey].matchedUsers.currentPage}
                changePage={changePage}
              />
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

export default MatchedUsers
