import React from "react"

import { gql, useMutation } from "urql"
import * as Yup from "yup"

import FormikForm from "~tailwindui/FormikForm"
import InlineCalendar from "~tailwindui/FormikForm/InlineCalendar"

export type NewSemesterProps = {
  organizationId: number
  hideForm: () => void
}

const validationSchema = Yup.object().shape({
  startsOn: Yup.string().required("Start date is required"),
  endsOn: Yup.string().required("End Date is required"),
  name: Yup.string().required("Name is required"),
})

const NewSemester: React.FC<NewSemesterProps> = ({
  organizationId,
  hideForm,
}) => {
  const [, createSemester] = useMutation(createSemesterMutation)
  return (
    <>
      <FormikForm.Wrapper
        editable
        editing
        title=""
        initialValues={{ organizationId, name: "", startsOn: "", endsOn: "" }}
        onCancel={hideForm}
        validationSchema={validationSchema}
        mutation={{ name: "createSemester", run: createSemester }}
        convertFormValues={({ startsOn, endsOn, ...rest }) => ({
          startsOn: startsOn.toDate().toString(),
          endsOn: endsOn.toDate().toString(),
          ...rest,
        })}
        onSuccess={hideForm}
      >
        <>
          <FormikForm.TextInput name="name" label="Name" />
          <InlineCalendar
            startFieldName="startsOn"
            endFieldName="endsOn"
            label="Date Range"
            range
          />
        </>
      </FormikForm.Wrapper>
    </>
  )
}

const createSemesterMutation = gql`
  mutation (
    $organizationId: ID!
    $name: String!
    $startsOn: Date!
    $endsOn: Date!
  ) {
    createSemester(
      organizationId: $organizationId
      name: $name
      startsOn: $startsOn
      endsOn: $endsOn
    ) {
      organization {
        id
        name
      }
      failures {
        message
      }
    }
  }
`

export default NewSemester
