import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const CalendarInfo = ({ calendarId }) => {
  return (
    <ModalWithProvidedBody
      modalClassName="bootstrap-modal"
      modalTitle="Shared Calendar"
      buttonText="Calendar Info"
      buttonClassName="btn solid blue"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p>
              In order to add a calendar with your scheduled sessions, follow
              the below instructions:
            </p>
            <ol>
              <li>Login to calendar.google.com</li>
              <li>
                At the bottom of the left sidebar, click the + button next to
                "Other Calendars"
              </li>
              <li>Click subscribe to new calendar</li>
              <li>
                Paste the following into the corresponding text box:
                <pre>{calendarId}</pre>
              </li>
              <li>Hit enter</li>
            </ol>

            <p>
              The shared calendar should now be added to your account and you
              should be able to access it from any other calendar application
              that is linked to your account (ie iOS)
            </p>
          </div>
          <div className="modal-footer">
            <button onClick={closeModal} className="btn solid blue">
              Okay
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default CalendarInfo
