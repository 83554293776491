import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  slug: Yup.string().required(),
  label: Yup.string().required(),
  value: Yup.string().required(),
})

const NewConfiguration = () => {
  const formState = {
    slug: "",
    label: "",
    value: "",
  }
  const [, runMutation] = useMutation(createConfigurationMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [{ message: result.error.message }])
            return
          }

          const { failures } = result.data.createApplicationConfiguration
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Create Configuration"
      buttonText="New Config"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field
                name="slug"
                label="Slug (ask dev team what this should be)"
              />
              <Field name="label" label="Label (this is for you!)" />
              <Field name="value" label="Configuration Value" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button
                  onClick={closeModal}
                  className="btn btn-danger"
                  type="button"
                >
                  Cancel
                </button>
                <SubmitButton text="Create Config" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createConfigurationMutation = gql`
  mutation ($slug: String!, $label: String!, $value: String!) {
    createApplicationConfiguration(slug: $slug, label: $label, value: $value) {
      failures {
        message
      }
    }
  }
`

export default NewConfiguration
