import React from "react"

import { NotificationContainer } from "react-notifications"

import SurveyList from "./List"

type SurveyProps = {
  newSurveyPath: string
  questionsPath: string
}

const SurveyIndex: React.FC<SurveyProps> = ({
  newSurveyPath,
  questionsPath,
}) => {
  return (
    <>
      <NotificationContainer />
      <a href={newSurveyPath} className="btn btn-success">
        New Survey
      </a>
      <a href={questionsPath} className="btn btn-info">
        View Questions
      </a>
      <SurveyList />
    </>
  )
}

export default SurveyIndex
