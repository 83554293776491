import React, { useState } from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RevertToDraft = ({ id }) => {
  const [errors, setErrors] = useState()
  const [, runMutation] = useMutation(workflowMutation)

  const handleSubmit = closeModal => {
    runMutation({ id }).then(result => {
      if (result.error) {
        setErrors(result.error.message)
      }

      const { failures } = result.data.tutorApplicationRevertToDraft
      if (failures) {
        setErrors(failures.map(f => f.message).join(", "))
      } else {
        NotificationManager.success("Applicant reverted to draft state")
        closeModal()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Revert to Draft"
      buttonText="Revert to Draft"
      buttonClassName="btn btn-warning"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              Are you sure? This will reset any approval/rejection fields
            </p>
          </div>

          <div className="modal-footer flex-col">
            {errors && <div className="alert alert-danger">{errors}</div>}
            <div className="flex w-full justify-between">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger"
              >
                Cancel
              </button>

              <button
                type="button"
                onClick={() => handleSubmit(closeModal)}
                className="btn btn-warning"
              >
                Revert to Draft
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const workflowMutation = gql`
  mutation ($id: ID!) {
    tutorApplicationRevertToDraft(id: $id) {
      failures {
        message
      }
      tutorApplication {
        id
      }
    }
  }
`

export default RevertToDraft
