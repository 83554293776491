import React from "react"

import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { SelectOption } from "~tailwindui/types/SelectOption"

import Form from "~tailwindui/Form"
import FormikForm from "~tailwindui/FormikForm"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import { timezoneOptions } from "src/enums"
import ordered from "src/ordered"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string().required("Email is required."),
  emailTimeZone: Yup.string().required("Please select a time zone."),
  schoolIds: Yup.array().when("canOrganizationAdminAccessAllSchools", {
    is: (canAllSchools: boolean) => !canAllSchools,
    then: Yup.array().min(
      1,
      "Please choose the school(s) which this user can access."
    ),
  }),
})

const UserNew = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    emailTimeZone: "",
    canOrganizationAdminAccessAllSchools: false,
    canOrganizationAdminAccessFinances: false,
    canOrganizationAdminAccessUserManagement: false,
    schoolIds: [],
  }
  const [viewerResult] = useQuery({ query: viewerQuery })

  const [, createOrganizationAdmin] = useMutation(
    createOrganizationAdminMutation
  )

  return (
    <WithLoadingIndicator result={viewerResult}>
      {({ data: { viewer } }) => (
        <FormikForm.Wrapper
          title="Add New Admin"
          editable={true}
          editing={true}
          displayData={initialState}
          initialValues={initialState}
          validationSchema={validationSchema}
          mutation={{
            name: "createOrganizationAdmin",
            run: createOrganizationAdmin,
          }}
          onCancel={() => (window.location.href = "/organization_admin/users")}
          convertFormValues={vals => ({ input: vals })}
          onSuccess={() => (window.location.href = "/organization_admin/users")}
        >
          {({ values }) => (
            <>
              <FormikForm.EditOnly>
                <Form.Section title="User Account">
                  <FormikForm.TextInput label="First Name" name="firstName" />
                  <FormikForm.TextInput label="Last Name" name="lastName" />
                  <FormikForm.TextInput label="Email" name="email" />
                  <FormikForm.TextInput label="Username" name="username" />
                  <FormikForm.SingleSelect
                    label="Time Zone"
                    name="emailTimeZone"
                    options={
                      ordered(timezoneOptions, "label") as SelectOption[]
                    }
                    description="This is used to determine notification times."
                  />
                </Form.Section>
                <Form.Section title="Permissions">
                  <FormikForm.ToggleInput
                    label="Financial Access"
                    name="canOrganizationAdminAccessFinances"
                    description="Allows this user to view financial data."
                  />
                  <FormikForm.ToggleInput
                    label="Semesters Access"
                    name="canOrganizationAdminAccessSemesters"
                    description="Allows this user to create and edit your organization's academic semesters."
                  />
                  <FormikForm.ToggleInput
                    label="User Management Access"
                    name="canOrganizationAdminAccessUserManagement"
                    description="Allows this user to create and edit other users."
                  />
                  <FormikForm.ToggleInput
                    label="All Schools Access"
                    name="canOrganizationAdminAccessAllSchools"
                    description="Allows this user to view data for all schools."
                  />
                  {!values.canOrganizationAdminAccessAllSchools && (
                    <FormikForm.MultiSelect
                      label="Permitted School(s)"
                      name="schoolIds"
                      description="The schools this user has access to."
                      options={ordered(viewer.organization.schools, "name").map(
                        ({ id, name }) => ({
                          label: name,
                          value: id,
                        })
                      )}
                    />
                  )}
                </Form.Section>
              </FormikForm.EditOnly>
            </>
          )}
        </FormikForm.Wrapper>
      )}
    </WithLoadingIndicator>
  )
}

const viewerQuery = gql`
  query viewer {
    viewer {
      organization {
        schools {
          id
          name
        }
      }
    }
  }
`

const createOrganizationAdminMutation = gql`
  mutation ($input: OrganizationAdminInputObject!) {
    createOrganizationAdmin(input: $input) {
      redirectTo
      organizationAdmin {
        id
      }
      failures {
        message
      }
    }
  }
`

export default UserNew
