import React, { useRef } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import LessonPlan from "./LessonPlan"
import studentFragment from "./studentFragment"

const StudentLessonPlans = ({ id }) => {
  const planSelectRef = useRef()
  const loadingState = useQuery(
    buildQuery(
      lessonPlansQuery,
      { studentId: id },
      {
        onMutation: [
          {
            when: "assignLessonPlan",
            run: ({ currentResults, softReset }, response) => {
              currentResults.student.lessonPlanAssignments =
                response.assignLessonPlan.student.lessonPlanAssignments
              softReset(currentResults)
            },
          },
          {
            when: "unassignLessonPlan",
            run: ({ currentResults, softReset }, response) => {
              currentResults.student.lessonPlanAssignments =
                response.unassignLessonPlan.student.lessonPlanAssignments
              softReset(currentResults)
            },
          },
          {
            when: "setCurrentLessonResource",
            run: ({ currentResults, softReset }, response) => {
              const { lessonPlanAssignment, lessonResource } =
                response.setCurrentLessonResource

              currentResults.student.lessonPlanAssignments.forEach(
                assignment => {
                  if (assignment.id === lessonPlanAssignment.id) {
                    assignment.lessonPlan.lessonTopics.forEach(topic => {
                      topic.lessonResources.forEach(resource => {
                        if (resource.id === lessonResource.id) {
                          resource.current = true
                        } else {
                          resource.current = false
                        }
                      })
                    })
                  }
                }
              )
              softReset(currentResults)
            },
          },
          {
            when: "removeLessonResourceCompletion",
            run: ({ currentResults, softReset }, response) => {
              const removedCompletion =
                response.removeLessonResourceCompletion
                  .removedLessonResourceCompletion
              if (!removedCompletion) return
              currentResults.student.lessonPlanAssignments =
                removedCompletion.student.lessonPlanAssignments
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )
  const { runMutation: assignLessonPlan } = useMutation(
    buildMutation(assignLessonPlanMutation)
  )
  const { runMutation: unassignLessonPlan } = useMutation(
    buildMutation(unassignLessonPlanMutation)
  )
  const { runMutation: setCurrentResource } = useMutation(
    buildMutation(setCurrentLessonResourceMutation)
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={
        <div className="d-flex align-items-center py-3">
          <h4 className="m-0 mr-3">Lesson Plans</h4>
          <div className="flex-grow-1">
            <AutocompleteSelect
              api="/api/admins/lesson_plans/autocomplete_name"
              placeholder="Assign Lesson Plan"
              ref={planSelectRef}
              onChange={selected =>
                selected &&
                assignLessonPlan({
                  studentId: id,
                  lessonPlanId: selected.value,
                }).then(r => planSelectRef.current.clearValue())
              }
            />
          </div>
        </div>
      }
    >
      {({ data }) =>
        ordered(data.student.lessonPlanAssignments, "lessonPlan", "name").map(
          lessonPlanAssignment => (
            <div key={lessonPlanAssignment.id} className="mt-3">
              <LessonPlan
                studentId={id}
                lessonPlanAssignment={lessonPlanAssignment}
                removeLessonPlan={() =>
                  unassignLessonPlan({
                    studentId: id,
                    lessonPlanId: lessonPlanAssignment.lessonPlan.id,
                  })
                }
                setCurrentResource={lessonResource =>
                  setCurrentResource({
                    lessonPlanAssignmentId: lessonPlanAssignment.id,
                    lessonResourceId: lessonResource.id,
                  })
                }
              />
            </div>
          )
        )
      }
    </WithLoadingIndicator>
  )
}

const lessonPlansQuery = compress`
  ${studentFragment}
  query($studentId: ID!) {
    student(id: $studentId) {
      ...StudentLessonFields
    }
  }
`

const assignLessonPlanMutation = compress`
  ${studentFragment}
  mutation($studentId: ID!, $lessonPlanId: ID!) {
    assignLessonPlan(studentId: $studentId, lessonPlanId: $lessonPlanId) {
      student {
        ...StudentLessonFields
      }
    }
  }
`

const unassignLessonPlanMutation = compress`
  ${studentFragment}
  mutation($studentId: ID!, $lessonPlanId: ID!) {
    unassignLessonPlan(studentId: $studentId, lessonPlanId: $lessonPlanId) {
      student {
        ...StudentLessonFields
      }
    }
  }
`

const setCurrentLessonResourceMutation = compress`
  mutation($lessonPlanAssignmentId: ID!, $lessonResourceId: ID!) {
    setCurrentLessonResource(lessonPlanAssignmentId: $lessonPlanAssignmentId, lessonResourceId: $lessonResourceId) {
      lessonPlanAssignment {
        id
      }
      lessonResource {
        id
      }
    }
  }
`

export default StudentLessonPlans
