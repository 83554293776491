import React from "react"

import { gql, useQuery } from "hooks/urql"

import { SelectField } from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type QuestionSelectionProps = Record<string, never>

const QuestionSelection: React.FC<QuestionSelectionProps> = () => {
  const [result] = useQuery({ query: surveyQuestionsQuery })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <SelectField
            name="questionIds"
            label="Select Questions"
            isMulti
            options={data.surveyQuestions.map(question => ({
              label: question.text,
              value: question.id,
            }))}
            isClearable
            menuPortalTarget={document.body}
          />
          <p className="alert alert-info">
            Students will be able to select between "Don't Agree", "Kinda
            Agree", and "Completely Agree" options
          </p>
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const surveyQuestionsQuery = gql`
  query SurveyQuestions {
    surveyQuestions: allSurveyQuestions {
      id
      text
    }
  }
`

export default QuestionSelection
