import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import StudyGroup from "./StudyGroup"
import studyGroupFragment from "./studyGroupFragment"

const ReservedStudyGroups = ({ courseSection }) => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(groupsQuery, { courseSectionId: courseSection.id, page: page })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h4>Reserved (Session Generation In Progress)</h4>}
    >
      {({ data }) =>
        data.courseSection.reservedStudyGroups.data.length > 0 ? (
          <>
            {data.courseSection.reservedStudyGroups.data.map(group => (
              <StudyGroup
                studyGroup={group}
                key={group.id}
                generatingFor={group.generatingForTutor}
              />
            ))}
            <Pagination
              currentPage={data.courseSection.reservedStudyGroups.currentPage}
              totalPages={data.courseSection.reservedStudyGroups.totalPages}
              changePage={setPage}
            />
          </>
        ) : (
          <div>
            <span>No reserved groups</span>
            <hr />
          </div>
        )
      }
    </WithLoadingIndicator>
  )
}

const groupsQuery = compress`
  ${studyGroupFragment}
  query($courseSectionId: ID!, $page: Int) {
    courseSection(id: $courseSectionId) {
      reservedStudyGroups(page: $page) {
        currentPage
        totalPages
        data {
          ...StudyGroupFields
          generatingForTutor {
            id
            fullName
            lastName
            showPath
          }
        }
      }
    }
  }
`

export default ReservedStudyGroups
