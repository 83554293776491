import React, { useRef, useState } from "react"

import { FieldArray, Form, Formik } from "formik"

import { gql, useMutation } from "hooks/urql"

import { SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import NameSearch from "./NameSearch"
import SearchResults from "./SearchResults"
import TagSearch from "./TagSearch"

const AddTutorsButton = ({ courseCluster }) => {
  const [, runMutation]: any[] = useMutation(addTutorsMutation)
  const [searchParams, setSearchParams] = useState({
    fullName: "",
    tagList: [],
    subjectId: courseCluster.subject.id,
  })
  const setFieldValueRef = useRef(null)

  const formState = { tutorIds: [] }

  const searchActive =
    searchParams.fullName.length > 0 || searchParams.tagList.length > 0

  const searchParamsUpdated = params => {
    setFieldValueRef.current("tutorIds", [])
    setSearchParams({ ...searchParams, ...params })
  }

  const handleSubmit = (values, actions) => {
    runMutation({ courseClusterId: courseCluster.id, ...values })
      .then(
        response => {
          const { errorMessages } = response.data.courseClusterAddTutors
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            actions.setStatus("Tutors added successfully.")
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Tutors"
      buttonClassName="btn btn-info"
      buttonText="Add Tutors"
    >
      {({ closeModal }) => (
        <Formik onSubmit={handleSubmit} initialValues={formState}>
          {({ status, isSubmitting, values, setFieldValue }) => {
            setFieldValueRef.current = setFieldValue
            return (
              <Form>
                <div className="modal-body">
                  <TagSearch setSearchParams={searchParamsUpdated} />
                  <NameSearch setSearchParams={searchParamsUpdated} />

                  <FieldArray name="tutorIds">
                    {arrayHelpers =>
                      searchActive && (
                        <>
                          <SearchResults
                            params={searchParams}
                            arrayHelpers={arrayHelpers}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      )
                    }
                  </FieldArray>
                </div>
                <div className="modal-footer d-flex flex-column">
                  {status && <div className="alert alert-danger">{status}</div>}
                  <SubmitButton isSubmitting={isSubmitting} text="Add Tutors" />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const addTutorsMutation = gql`
  mutation AddTutors($courseClusterId: ID!, $tutorIds: [ID!]!) {
    courseClusterAddTutors(
      courseClusterId: $courseClusterId
      tutorIds: $tutorIds
    ) {
      errorMessages
      courseCluster {
        tutors {
          id
          fullName
          lastName
          showPath
        }
      }
    }
  }
`

export default AddTutorsButton
