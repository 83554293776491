import React from "react"

import Button from "./Button"

const RemoteLockButton = props => {
  const { userId, muted, channel } = props

  const mute = () => {
    channel.perform("lock_user", { userId: userId })
  }

  const unmute = () => {
    channel.perform("unlock_user", {
      userId: userId,
    })
  }

  const toggle = () => {
    muted ? unmute() : mute()
  }

  if (!channel) return null

  return (
    <Button
      onClick={toggle}
      muted={muted}
      styles={`
        margin-left: 15px;
      `}
    />
  )
}

export default RemoteLockButton
