import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  reason: Yup.string().required("Please provide a reason for rejection"),
})

const RejectSelfie = ({ id }) => {
  const formState = { id, reason: "" }
  const [, runMutation] = useMutation(workflowMutation)

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { failures } = result.data.rejectSelfie
      if (failures) {
        handleFailure(actions, failures)
      } else {
        NotificationManager.success("Applicant rejected")
        closeModal()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      buttonText="Reject Applicant (Selfie)"
      buttonClassName="btn btn-danger"
      modalTitle="Reject Applicant"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <TextAreaField name="reason" label="Reason" autoFocus />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Reject Applicant" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const workflowMutation = gql`
  mutation ($id: ID!, $reason: String!) {
    rejectSelfie(id: $id, reason: $reason) {
      failures {
        message
      }
      tutorApplication {
        id
      }
    }
  }
`

export default RejectSelfie
