import React from "react"

import { gql, useMutation, useQuery } from "hooks/urql"

import { handleFailure, readFile } from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import GroupForm from "../Form"

const EditTailoredQuestionGroup = ({ id }) => {
  const [result] = useQuery({ query: groupQuery, variables: { id } })

  const [, runMutation]: any[] = useMutation(updateGroupMutation)

  const handleSubmit = (values, actions) => {
    values.id = id
    const promises = values.diagrams.map(diagram => readFile(diagram))
    Promise.all(promises).then(files => {
      values.diagrams = files
      runMutation(values)
        .then(
          result => {
            const { failures, redirectTo } =
              result.data.tailoredQuestionUpdateGroup
            if (failures.length > 0) {
              handleFailure(actions, failures)
            } else {
              window.location.href = redirectTo
            }
            actions.setSubmitting(false)
          },
          () => handleFailure(actions)
        )
        .catch(() => handleFailure(actions))
    })
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <GroupForm
          internalName={data.tailoredQuestionGroup.internalName}
          tagList={data.tailoredQuestionGroup.tagList}
          handleSubmit={handleSubmit}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const groupQuery = gql`
  query tailoredQuestionGroup($id: ID!) {
    tailoredQuestionGroup(id: $id) {
      id
      internalName
      tagList
    }
  }
`

const updateGroupMutation = gql`
  mutation updateTailoredQuestionGroup(
    $id: ID!
    $internalName: String!
    $tagList: [String!]!
    $diagrams: [FileInputObject!]
  ) {
    tailoredQuestionUpdateGroup(
      id: $id
      internalName: $internalName
      tagList: $tagList
      diagrams: $diagrams
    ) {
      failures {
        message
      }
      redirectTo
    }
  }
`

export default EditTailoredQuestionGroup
