import React from "react"

import TimeProtectedLink from "components/TimeProtectedLink"

export type CloudroomButtonProps = {
  href: string
  startsAt: string
}

const CloudroomButton: React.FC<CloudroomButtonProps> = props => {
  return (
    <TimeProtectedLink target="_blank" {...props}>
      <div className="cloudroom-button btn blue">
        <i className="zmdi zmdi-cloud-outline" />
        <div>Cloud Room</div>
      </div>
    </TimeProtectedLink>
  )
}

export default CloudroomButton
