import React, { useEffect, useRef, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as colors from "styles/colors"

import { css } from "@emotion/core"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"

import { tutorClient } from "src/graphql-config"

import { Loading } from "components/Icons"

import LessonResourceCompletion from "./LessonResourceCompletion"

import "tippy.js/dist/tippy.css"

const LessonResource = ({ airtutorsSessionId, lessonResource, isCurrent }) => {
  const lessonResourceDownloadLink = useRef()
  const [collapsed, setCollapsed] = useState(true)
  const [lessonResourceUrl, setLessonResourceUrl] = useState("")
  const [completeResourceError, setCompleteResourceError] = useState()
  const { runMutation: completeResource, running: completeResourceRunning } =
    useMutation(
      buildMutation(completeResourceMutation, { client: tutorClient })
    )
  const { runMutation: downloadResource, running: downloadResourceRunning } =
    useMutation(
      buildMutation(downloadResourceMutation, { client: tutorClient })
    )

  useEffect(() => {
    if (lessonResourceUrl === "") return
    lessonResourceDownloadLink.current.click()
  }, [lessonResourceUrl])

  return (
    <li
      key={lessonResource.id}
      css={css`
        margin-left: 66px;
        padding-left: 12px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div>
          <span>#{lessonResource.sortOrder}</span>
          <a
            ref={lessonResourceDownloadLink}
            href={lessonResourceUrl}
            download
            css={css`
              display: none;
            `}
          >
            download resource
          </a>
          <button
            onClick={() =>
              !downloadResourceRunning &&
              downloadResource({
                airtutorsSessionId,
                lessonResourceId: lessonResource.id,
              }).then(r => {
                setCompleteResourceError()
                if (r.downloadLessonResource?.lessonResource) {
                  setLessonResourceUrl(
                    r.downloadLessonResource.lessonResource.file.downloadUrl
                  )
                  setTimeout(() => setLessonResourceUrl(""), 1000)
                }
              })
            }
            disabled={downloadResourceRunning}
            css={css`
              display: inline-flex;
              align-items: center;
              background-color: transparent;
              color: ${colors.red};
              border: none;
              &:hover {
                color: ${colors.redHover};
              }
              &:disabled {
                cursor: not-allowed;
                color: ${colors.disabled};
              }
            `}
          >
            <div
              css={css`
                margin-right: 15px;
              `}
            >
              <h3
                css={css`
                  display: inline-block;
                `}
              >
                {lessonResource.name}
              </h3>
              <div className="d-inline-block ml-3">
                {downloadResourceRunning ? (
                  <Loading />
                ) : (
                  <FontAwesomeIcon icon={faCloudDownloadAlt} />
                )}
              </div>
            </div>
            <Tippy
              content={
                <img
                  src={lessonResource.file.previewUrl}
                  alt={lessonResource.name}
                  css={css`
                    max-height: 80vh;
                  `}
                />
              }
              placement="auto"
              maxWidth="90vw"
            >
              <div>
                <img
                  src={lessonResource.file.thumbUrl}
                  alt={lessonResource.name}
                />
              </div>
            </Tippy>
          </button>
        </div>

        {isCurrent && (
          <div className="ml-3">
            <button
              className="btn solid orange"
              onClick={() =>
                completeResource({
                  airtutorsSessionId,
                  lessonResourceId: lessonResource.id,
                }).then(r => {
                  if (r.completeLessonResource.errorMessages) {
                    setCompleteResourceError(
                      r.completeLessonResource.errorMessages
                    )
                  }
                })
              }
              disabled={completeResourceRunning}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                Mark Worksheet Completed
                {completeResourceRunning && (
                  <div className="d-inline-block ml-3">
                    <Loading />
                  </div>
                )}
              </div>
            </button>
            {completeResourceError && (
              <div
                className="alert"
                css={css`
                  margin-top: 15px;
                  padding: 9px;
                `}
              >
                {completeResourceError}
              </div>
            )}
          </div>
        )}
      </div>

      {lessonResource.lessonResourceCompletions.length > 0 && (
        <div
          css={css`
            margin-top: 30px;
          `}
        >
          <button
            onClick={() => setCollapsed(c => !c)}
            className="btn small outline"
          >
            {collapsed
              ? "View Student Completions"
              : "Hide Student Completions"}
          </button>
          {!collapsed && (
            <div
              css={css`
                margin-top: 30px;
              `}
            >
              {lessonResource.lessonResourceCompletions.map(completion => (
                <LessonResourceCompletion
                  key={completion.id}
                  airtutorsSessionId={airtutorsSessionId}
                  lessonResourceCompletion={completion}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <hr />
    </li>
  )
}

const downloadResourceMutation = compress`
mutation($lessonResourceId: ID!, $airtutorsSessionId: ID!) {
  downloadLessonResource(lessonResourceId: $lessonResourceId, airtutorsSessionId: $airtutorsSessionId) {
    lessonResource {
      file {
        downloadUrl
      }
    }
  }
}
`

const completeResourceMutation = compress`
  mutation($airtutorsSessionId: ID!, $lessonResourceId: ID!) {
    completeLessonResource(airtutorsSessionId: $airtutorsSessionId, lessonResourceId: $lessonResourceId) {
      errorMessages
      airtutorsSession {
        lessonPlans {
          id
          name
        }
        currentLessonResources {
          id
          name
          sortOrder
          file {
            thumbUrl
            previewUrl
          }
          lessonTopic {
            id
            name
            sortOrder
          }
          lessonPlan {
            id
            name
          }
          lessonResourceCompletions(sessionId: $airtutorsSessionId) {
            id
            createdAt
            studentMastery
            student {
              id
              fullName
            }
          }
        }
      }
    }
  }
`

export default LessonResource
