import React, { useEffect, useRef, useState } from "react"

import { pipe, subscribe } from "wonka"

import { gql } from "@urql/core"

import createBlob from "src/createBlob"
import client from "src/urql-client"

const LessonPlanStatus = ({ id }) => {
  const [status, setStatus] = useState()
  const [errors, setErrors] = useState([])
  const [csvUrl, setCsvUrl] = useState()
  const csvDownloadLink = useRef()

  const handleExport = () => {
    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, { organizationId: id }),
      subscribe(result => {
        setStatus(result.data.organizationLessonPlanStatus.status)

        if (result.data?.organizationLessonPlanStatus?.status === "done") {
          const blobUrl = createBlob(
            result.data.organizationLessonPlanStatus.csv,
            "text/csv"
          )
          setStatus(result.data.organizationLessonPlanStatus.status)
          setCsvUrl(blobUrl)
          unsubscribe()
        }

        if (result.data?.organizationLessonPlanStatus?.errorMessages?.length) {
          setErrors(result.data.organizationLessonPlanStatus.errorMessages)
          unsubscribe()
        }

        if (result.error) {
          setErrors([result.error])
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl()
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <div>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download={`lesson plan status.csv`}
        className="hidden"
      >
        csv
      </a>
      <button
        className="btn btn-info"
        onClick={handleExport}
        disabled={status === "enqueued"}
      >
        Lesson Plan Summary {status && `(${status})`}
      </button>
      {errors.map((error, index) => (
        <div key={index} className="alert alert-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

const exportSubscription = gql`
  subscription ($organizationId: ID!) {
    organizationLessonPlanStatus(organizationId: $organizationId) {
      status
      csv
      errorMessages
    }
  }
`

export default LessonPlanStatus
