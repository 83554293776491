import React, { useEffect, useState } from "react"

import axios from "src/axios"

import Form from "./Form"

type AdminTailoredSectionFormContainerProps = {
  id: string
}

const AdminTailoredSectionFormContainer: React.FC<
  AdminTailoredSectionFormContainerProps
> = ({ id }) => {
  const [section, setSection] = useState(null)

  useEffect(() => {
    axios.get(`/api/admins/tailored_sections/${id}`).then(response => {
      const section = response.data
      setSection(section)
    })
  }, [id])

  if (!section) return null

  return (
    <Form
      id={id}
      maxQuestions={section.maxQuestions}
      maxQuestionGroups={section.maxQuestionGroups}
      tags={section.tags}
      groupTags={section.groupTags}
      formState={{
        questionIds: section.tailoredQuestionIds,
        questionGroupIds: section.tailoredQuestionGroupIds,
      }}
    />
  )
}

export default AdminTailoredSectionFormContainer
