import React from "react"

const CloseButton = ({ DatePicker, setClosed }) => {
  return (
    <div className="p-3">
      <button
        type="button"
        className="btn btn-outline-info"
        onClick={() => {
          setClosed(true)
          setTimeout(() => DatePicker.closeCalendar(), 0)
        }}
      >
        Done
      </button>
    </div>
  )
}

export default CloseButton
