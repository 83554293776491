import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const StudyGroupSearch = props => {
  const studyGroupSelected = value => {
    if (!value?.value) return
    window.location.href = `/tutor/study_groups/${value.value}`
  }

  return (
    <div>
      <AutocompleteSelect
        api="/api/tutors/study_groups/autocomplete_name"
        placeholder={props.placeholder}
        onChange={studyGroupSelected}
      />
    </div>
  )
}

StudyGroupSearch.defaultProps = {
  placeholder: "Search by name",
}

export default StudyGroupSearch
