import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  subjectBucketId: Yup.string().required(
    "Please specify a parent subject bucket"
  ),
})

const EditSubject = ({ subject, subjectBucket, tier }) => {
  const { runMutation } = useMutation(buildMutation(updateSubjectMutation))
  const formState = {
    id: subject.id,
    name: subject.name,
    subjectBucketId: subjectBucket.id,
    organizationId: subject.organization?.id,
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.subjectUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Subject"
      buttonClassName="btn btn-sm"
      buttonText="Edit"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ isSubmitting, status, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field name="name" className="form-control" autoFocus />
                  </label>
                  <ErrorMessage name="name" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Bucket
                    <AutocompleteSelect
                      api="/api/admins/subject_buckets/autocomplete_name"
                      searchOptions={`tier_id=${tier.id}`}
                      onChange={selected =>
                        setFieldValue("subjectBucketId", selected?.value)
                      }
                      isClearable={false}
                      selected={{
                        label: subjectBucket.name,
                        value: subjectBucket.id,
                      }}
                      menuPortalTarget={document.body}
                    />
                  </label>
                  <ErrorMessage name="subjectBucketId" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Organization (optional)
                    <AutocompleteSelect
                      api="/api/admins/organizations/autocomplete_name"
                      onChange={selected =>
                        setFieldValue("organizationId", selected?.value)
                      }
                      selected={
                        subject.organization && {
                          label: subject.organization.name,
                          value: subject.organization.id,
                        }
                      }
                      isClearable
                      menuPortalTarget={document.body}
                    />
                  </label>
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Update Subject"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateSubjectMutation = compress`
  mutation(
    $id: ID!
    $subjectBucketId: ID!
    $name: String!
    $organizationId: ID
  ) {
    subjectUpdate(
      id: $id
      subjectBucketId: $subjectBucketId
      name: $name
      organizationId: $organizationId
    ) {
      subject {
        id
        name
        tier {
          id
        }
        subjectBucket {
          id
        }
        organization {
          id
          name
        }
      }
    }
  }
`

export default EditSubject
