import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required(),
})

const TutorRow = ({ tutor, count }) => {
  const formState = {
    message: `[URGENT] YOU HAVE UNBILLED SESSIONS. PLEASE CLICK THE FOLLOWING LINK TO BILL THEM. https://app.airtutors.org/tutor/airtutors_sessions`,
  }
  const [, sendSms] = useMutation(sendSmsMutation)

  const handleSubmit = (values, actions, closeModal) => {
    sendSms({ tutorId: tutor.id, message: values.message })
      .then(
        response => {
          const { failures } = response.data.sendSms
          if (failures) {
            handleFailure(actions, failures)
          } else {
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <tr>
      <td>
        <a href={tutor.showPath}>{tutor.fullName}</a>
      </td>
      <td>{count}</td>
      <td>
        <ModalWithProvidedBody
          modalTitle={`Send Text to ${tutor.fullName}`}
          buttonClassName="btn btn-sm btn-info"
          buttonText="Send Text"
        >
          {({ closeModal }) => (
            <Formik
              initialValues={formState}
              validationSchema={ValidationSchema}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, closeModal)
              }
            >
              <Form>
                <div className="modal-body">
                  <TextAreaField name="message" label="Message" />
                </div>
                <div className="modal-footer flex-col">
                  <FormFailures />
                  <div className="flex w-full justify-between">
                    <SubmitButton text="Send Text" />
                    <button className="btn btn-danger" onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </ModalWithProvidedBody>
      </td>
    </tr>
  )
}

const sendSmsMutation = gql`
  mutation ($tutorId: ID!, $message: String!) {
    sendSms(userIds: [$tutorId], message: $message) {
      failures {
        message
      }
    }
  }
`

export default TutorRow
