import React, { useState } from "react"

import { Map as ImmutableMap } from "immutable"

import { css } from "@emotion/core"

import LoadingIndicator from "components/LoadingIndicator"
import ErrorModal from "components/TwilioCloudRoom/ErrorModal"
import RemoteParticipant from "components/TwilioCloudRoom/RemoteParticipant"
import useCloudRoom from "components/TwilioCloudRoom/hooks/useCloudRoom"
import useScreenShareActive from "components/TwilioCloudRoom/hooks/useScreenShareActive"

import styles from "./styles.module.scss"

const QualityControlCloudRoom = ({ id, accessToken, muted }) => {
  const [error, setError] = useState()
  const [users, setUsers] = useState(ImmutableMap())

  const { room } = useCloudRoom({
    id,
    accessToken,
    setUsers,
    setError,
    ghost: true,
  })
  const screenShareActive = useScreenShareActive(users)

  const tutors = Array.from(users.values()).filter(
    u => u.type === "tutor" || u.type === "teacher"
  )

  let primaryUsers = tutors
  const usersArray = Array.from(users.values())
  if (screenShareActive) {
    const screenShareUser = usersArray.filter(
      u => u.videoTrack && u.videoTrack.name === "screenshare"
    )[0]
    primaryUsers = [screenShareUser]
  }

  if (error) {
    return (
      <ErrorModal
        isOpen={true}
        onClose={() => window.location.reload()}
        userType="Admin"
        sessionId={id}
        phoneNumber=""
        {...error}
      />
    )
  }

  return (
    <div className={styles.container}>
      {!room ? (
        <div
          css={css`
            padding-top: 20vh;
            i {
              font-size: 64px;
              color: white;
            }
          `}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {primaryUsers.length > 0 ? (
            primaryUsers.map(user => (
              <RemoteParticipant
                key={user.identity}
                user={user}
                api="admins"
                id={id}
                qualityControlMuted={muted}
                qualityControl={true}
              />
            ))
          ) : (
            <p className="pt-6 text-center italic">
              The cloudroom is active, but there is no tutor present.
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default QualityControlCloudRoom
