import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import OrganizationStudents from "./OrganizationStudents"

const Organizations = () => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(buildQuery(organizationsQuery, { page }))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          {data.organizations.data.map(organization => (
            <OrganizationStudents
              key={organization.id}
              organization={organization}
            />
          ))}
          <Pagination
            totalPages={data.organizations.totalPages}
            currentPage={data.organizations.currentPage}
            changePage={setPage}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const organizationsQuery = compress`
  query($page: Int!) {
    organizations(page: $page) {
      totalPages
      currentPage
      data {
        id
        students: uncontactedStudents(page: 1) {
          totalPages
        }
      }
    }
  }
`

export default Organizations
