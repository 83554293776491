import React, { useState } from "react"

import { gql } from "urql"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Sessions from "./Sessions"
import Tutors from "./Tutors"

const StatementUnbilled = ({ statementId }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: unbilledSessionsQuery,
    variables: { statementId, page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { statement } }) => (
        <>
          {statement.unbilledSessions.data.length === 0 ? (
            <div className="m-3 italic">No unbilled sessions</div>
          ) : (
            <div className="grid gap-x-8 md:grid-flow-col">
              <div>
                <Sessions
                  totalPages={statement.unbilledSessions.totalPages}
                  currentPage={statement.unbilledSessions.currentPage}
                  changePage={setPage}
                  sessions={statement.unbilledSessions.data}
                />
              </div>
              <div>
                <Tutors tutorsWithCount={statement.tutorsWithUnbilledCount} />
              </div>
            </div>
          )}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const unbilledSessionsQuery = gql`
  query ($statementId: ID!, $page: Int) {
    statement(id: $statementId) {
      id
      unbilledSessions(page: $page) {
        totalPages
        currentPage
        data {
          id
          startsAt
          showPath
          tutor {
            fullName
            showPath
          }
        }
      }
      tutorsWithUnbilledCount {
        count
        tutor: model {
          ... on Tutor {
            id
            fullName
            showPath
          }
        }
      }
    }
  }
`

export default StatementUnbilled
