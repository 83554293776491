import React, { useRef, useState } from "react"

import dayjs from "dayjs"
import { pipe, subscribe } from "wonka"

import { tutorClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const AcceptReplacementRequest = ({ studyGroupTutorReplacementId }) => {
  const [status, setStatus] = useState()
  const [errorMessages, setErrorMessages] = useState([])
  const [inFlight, setInFlight] = useState(false)
  const submitClicked = useRef()
  const [result] = useQuery({
    query: replacementQuery,
    variables: { id: studyGroupTutorReplacementId },
    client,
  })

  const acceptRequest = () => {
    if (submitClicked.current) {
      return
    }
    submitClicked.current = true
    setInFlight(true)
    setErrorMessages([])
    setStatus("Starting")

    const { unsubscribe } = pipe(
      client.subscription(acceptRequestSubscription, {
        studyGroupTutorReplacementId,
      }),
      subscribe(result => {
        if (result.data) {
          const { status, errorMessages, studyGroupUrl, requestedSessions } =
            result.data.acceptStudyGroupTutorReplacement

          if (errorMessages.length) {
            setErrorMessages(errorMessages)
            setStatus()
            submitClicked.current = false
            unsubscribe()
          }
          if (status === "in-progress") {
            setStatus(
              <>
                In Progress <br />
                (Please be patient, this process can take some time)
              </>
            )
          }
          if (status === "success") {
            setStatus(
              <div>
                <div> Registration successful!</div>
                <div>
                  <a href={studyGroupUrl}>Click here to view your new group</a>
                </div>

                {requestedSessions?.length > 0 && (
                  <div>
                    This group also has the following outstanding session(s):
                    <ul className="list-unstyled">
                      {requestedSessions.map(session => {
                        const day = dayjs(session.startsAt)
                          .startOf("day")
                          .isSame(dayjs().startOf("day"))
                          ? "today"
                          : dayjs(session.startsAt).format("ddd, MMM D")
                        return (
                          <a href={session.showPath} key={session.id}>
                            {day} at {dayjs(session.startsAt).format("h:mm A")}
                          </a>
                        )
                      })}
                    </ul>
                    If you are available to pick any of them up, please accept
                    as soon as possible.
                  </div>
                )}
              </div>
            )
            unsubscribe()
          }
        } else if (result.error) {
          setStatus()
          setErrorMessages([result.error?.message])
          setInFlight(false)
          submitClicked.current = false
          unsubscribe()
        }
      })
    )
  }

  const resetState = () => {
    setStatus()
    setErrorMessages([])
    setInFlight(false)
    submitClicked.current = false
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Accept Study Group"
      modalClassName="bootstrap-modal"
      buttonText="Accept Study Group"
      buttonClassName="btn solid green"
      afterClose={resetState}
    >
      {({ closeModal }) => (
        <UrqlLoadingIndicator result={result}>
          {({
            data: {
              viewer: {
                studyGroupTutorReplacement: { studyGroup },
              },
            },
          }) => (
            <>
              <div className="modal-body">
                {(studyGroup.maxSubsEstimate ||
                  studyGroup.maxSubsEstimate === 0) && (
                  <ConsistentTutorWarning
                    minTutorConsistencyRate={studyGroup.minTutorConsistencyRate}
                    maxSubsEstimate={studyGroup.maxSubsEstimate}
                  />
                )}
                <p>
                  Click confirm below to accept this group and all its scheduled
                  sessions.
                </p>
              </div>

              <div className="modal-footer flex-col justify-between">
                {status && (
                  <div className="alert my-3 !mr-0 !justify-center bg-blue-500 p-3">
                    {status}
                  </div>
                )}

                {errorMessages.map(error => (
                  <div
                    key={error}
                    className={`alert alert-danger my-3 !mr-0 p-3`}
                  >
                    <div>
                      <p className="m-0">{error}</p>
                    </div>
                  </div>
                ))}

                <div className="w-full justify-between">
                  <button onClick={closeModal} className="btn solid red">
                    Cancel
                  </button>
                  <button
                    disabled={inFlight}
                    onClick={acceptRequest}
                    className="btn solid green"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          )}
        </UrqlLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const replacementQuery = gql`
  query replacementQuery($id: ID!) {
    viewer {
      studyGroupTutorReplacement(id: $id) {
        studyGroup {
          id
          minTutorConsistencyRate
          maxSubsEstimate
        }
      }
    }
  }
`

const acceptRequestSubscription = gql`
  subscription ($studyGroupTutorReplacementId: ID!) {
    acceptStudyGroupTutorReplacement(
      studyGroupTutorReplacementId: $studyGroupTutorReplacementId
    ) {
      status
      errorMessages
      studyGroupUrl
      requestedSessions {
        id
        startsAt
        showPath
      }
    }
  }
`

export default AcceptReplacementRequest
