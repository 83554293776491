import React from "react"

import { useField } from "formik"

import ErrorMessage from "./ErrorMessage"

const TextAreaField = ({ className = "", ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="form-group">
      <label className="form-label w-100">
        <span>{props.label}</span>
        <textarea
          className={`form-control ${className}`}
          rows={5}
          {...field}
          {...props}
        />
        {props.maxLength && (
          <p className="mt-0 text-sm italic">
            {props.maxLength - field.value.length}/{props.maxLength} characters
            remaining
          </p>
        )}
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default TextAreaField
