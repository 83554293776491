import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  mergeSchoolId: Yup.string().required("You must choose a school"),
})

const MergeSchoolsModal = ({ school: { id, name } }) => {
  const formState = { schoolId: id, mergeSchoolId: "" }
  const client = useClient()
  const [, mergeSchools] = useMutation(MergeSchoolMutation, client)
  const handleSubmit = (values, actions) => {
    mergeSchools(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures, redirectTo } = result.data.mergeSchool
          if (failures) handleFailure(actions, failures)
          else window.location = redirectTo
        },
        e => handleFailure(actions, [e])
      )
      .catch(e => handleFailure(actions, [e]))
  }
  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-info"
      buttonText="Merge with Another School"
      modalTitle="Choose a School"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div
                  className="form-group mb-4"
                  css={css`
                    min-height: 75px;
                  `}
                >
                  <AutocompleteField
                    name="mergeSchoolId"
                    api="/api/admins/schools/autocomplete_name"
                    isOptionDisabled={opt => opt.value === parseInt(id)}
                  />
                  <p>
                    Submitting this form will delete {name}. All its teachers,
                    students, and study groups will be transferred to the school
                    selected above.
                  </p>
                </div>
              </div>
              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="d-flex justify-content-between w-full">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton isSubmitting={isSubmitting} text="Merge" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const MergeSchoolMutation = gql`
  mutation ($schoolId: ID!, $mergeSchoolId: ID!) {
    mergeSchool(schoolToKeepId: $mergeSchoolId, schoolToDeleteId: $schoolId) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default MergeSchoolsModal
