import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  organizationId: Yup.string().required("Please specify an organization"),
})

const NewSchool = ({ organizationId, organizationName }) => {
  const formState = {
    name: "",
    organizationId: organizationId || "",
  }

  const { runMutation } = useMutation(buildMutation(createSchoolMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages, redirectTo } = response.createSchool
          if (errorMessages) {
            actions.setStatus(errorMessages)
            actions.setSubmitting(false)
          }
          if (redirectTo) {
            window.location = redirectTo
          }
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      buttonText="New School"
      buttonClassName="btn btn-success"
      modalTitle="New School"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          initialValues={formState}
          onSubmit={handleSubmit}
        >
          {({ status, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="w-100">
                    Name
                    <Field name="name" className="form-control" />
                  </label>
                  <ErrorMessage name="name" />
                </div>

                <div className="form-group">
                  <label className="w-100">Organization</label>
                  <AutocompleteSelect
                    api="/api/admins/organizations/autocomplete_name"
                    onChange={selected =>
                      setFieldValue("organizationId", selected.value)
                    }
                    isClearable={false}
                    menuPortalTarget={document.body}
                    defaultValue={{
                      label: organizationName,
                      value: organizationId,
                    }}
                  />
                  <ErrorMessage name="organizationId" />
                </div>
              </div>

              <div
                className="modal-footer"
                css={css`
                  flex-flow: column;
                `}
              >
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton text="Submit" isSubmitting={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createSchoolMutation = compress`
  mutation($name: String!, $organizationId: ID!) {
    createSchool(name: $name, organizationId: $organizationId) {
      errorMessages
      redirectTo
    }
  }
`

export default NewSchool
