import React from "react"

import { compress } from "micro-graphql-react"

import { studyGroupFields, teacherSubjectFields } from "./Shared/fragments"

import GeneratedStudyGroups from "./GeneratedStudyGroups"

const WithUnassignedStudents = ({ organizationId }) => {
  return (
    <GeneratedStudyGroups
      query={groupsQuery}
      organizationId={organizationId}
      title="Generations with unassigned students"
    />
  )
}

const groupsQuery = compress`
  ${studyGroupFields}
  ${teacherSubjectFields}
  query($id: ID!, $page: Int!) {
    organization(id: $id) {
      studyGroupGenerations: studyGroupGenerationsWithUnassignedStudents(page: $page) {
        currentPage
        totalPages
        data {
          id
          minimumGroupSize
          maximumGroupSize
          sessionsPerWeek
          teacherSubject {
            ...TeacherSubjectFields
          }
          studyGroups {
            ...StudyGroupFields
          }
        }
      }
    }
  }
`

export default WithUnassignedStudents
