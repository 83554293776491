import React from "react"

import { useField } from "formik"

import { Field, SelectField } from "components/Forms/Formik/hookComponents"

const SchoolForm = ({ organization }) => {
  const [field] = useField({ name: "schoolId" })

  return (
    <>
      {organization?.schools ? (
        <SelectField
          name={field.name}
          label="School"
          options={organization.schools}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          defaultValue={organization.schools.find(
            school => school.id === field.value
          )}
          valueAttribute="id"
          menuPortalTarget={document.body}
        />
      ) : (
        <Field name="schoolName" label="School" autoFocus />
      )}
    </>
  )
}

SchoolForm.modalTitle = "What school are you attending this year?"
SchoolForm.displayName = "School"
SchoolForm.providedFields = ["schoolId", "schoolName"]
SchoolForm.step = "School"
export default SchoolForm
