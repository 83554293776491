import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const EditAdminNotes = ({ tutorApplication }) => {
  const formState = {
    id: tutorApplication.id,
    adminNotes: tutorApplication.adminNotes || "",
  }
  const [, updateAdminNotes] = useMutation(updateAdminNotesMutation)

  const handleSubmit = (values, actions, closeModal) => {
    updateAdminNotes(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [{ message: result.error.message }])
            return
          }

          const { failures } = result.data.updateTutorApplicationAdminNotes
          if (failures) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.info("Admin notes saved")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Admin Notes"
      buttonText="Edit Admin Notes"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <TextAreaField name="adminNotes" label="Admin Notes" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Update" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateAdminNotesMutation = gql`
  mutation ($id: ID!, $adminNotes: String!) {
    updateTutorApplicationAdminNotes(
      tutorApplicationId: $id
      adminNotes: $adminNotes
    ) {
      tutorApplication {
        id
      }
      failures {
        message
      }
    }
  }
`

export default EditAdminNotes
