import React from "react"

import dayjs from "dayjs"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import { tutorClient as client } from "src/urql-client"

import { gql, useMutation } from "hooks/urql"

import {
  DateField,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  startsOn: Yup.string().required(
    "Please provide a start date for the replacement tutor"
  ),
  details: Yup.string().required(
    "Please provide relevant details about the group"
  ),
  substituteNotes: Yup.string().when("substituteRequired", {
    is: substituteRequired => substituteRequired,
    then: Yup.string().required(
      "Please leave a note for the tutor who will pick up the session"
    ),
  }),
})

const ReplacementRequestForm = ({ studyGroup, closeModal }) => {
  const formState = {
    substituteRequired: false,
    studyGroupId: studyGroup.id,
    startsOn: "",
    details: "",
    substituteNotes: "",
  }
  const [, submitRequest] = useMutation(submitRequestMutation, client)

  const handleDateChange = (selectedDate, setFieldValue) => {
    setFieldValue("startsOn", selectedDate)
    if (!studyGroup.imminentSession) return false
    const imminentSessionWithinRange = dayjs(selectedDate)
      .startOf("day")
      .isBefore(studyGroup.imminentSession.startsAt)
    setFieldValue("substituteRequired", imminentSessionWithinRange)
  }

  const handleSubmit = (values, actions) => {
    const { startsOn, substituteRequired, ...rest } = values
    const startsAt = dayjs(startsOn.toDate()).startOf("day")

    submitRequest({ startsAt: startsAt.format(), ...rest }).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { failures } = result.data.requestStudyGroupTutorReplacement
      if (failures) {
        handleFailure(actions, failures)
      } else {
        window.location.reload()
      }
    })
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="modal-body">
            <div className="alert alert-danger mb-3 p-3 text-left">
              <p>
                If you give up this group, you will be unable to accept new
                groups for <span className="font-semibold">48 hours!</span>
              </p>
              <p className="font-semibold italic">
                Think carefully before proceeding!
              </p>
            </div>
            <DateField
              name="startsOn"
              label="Start Date"
              minDate={new Date()}
              onChange={date => {
                handleDateChange(date, setFieldValue)
              }}
            />
            <TextAreaField
              name="details"
              label={
                <>
                  <div>Details</div>
                  <div className="text-sm italic">
                    Provide relevant details about the group for your
                    replacement tutor
                  </div>
                </>
              }
            />
            {values.substituteRequired && (
              <>
                <p>This group has a session within the next 12 hours.</p>

                <TextAreaField
                  name="substituteNotes"
                  label={
                    <>
                      <div>Session Notes</div>
                      <div className="text-sm italic">
                        These will be provided to the tutor who picks up your
                        session
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>

          <div className="modal-footer flex flex-col">
            <FormFailures />
            <div className="flex w-full justify-between">
              <button onClick={closeModal} className="btn solid red">
                Cancel
              </button>
              <SubmitButton text="Request Replacement" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const submitRequestMutation = gql`
  mutation (
    $studyGroupId: ID!
    $startsAt: DateTime!
    $details: String!
    $substituteNotes: String
  ) {
    requestStudyGroupTutorReplacement(
      studyGroupId: $studyGroupId
      startsAt: $startsAt
      details: $details
      substituteNotes: $substituteNotes
    ) {
      failures {
        message
      }
    }
  }
`

export default ReplacementRequestForm
