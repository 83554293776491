import React from "react"

import { buildQuery, useQuery } from "micro-graphql-react"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import Form from "./Form"
import loadStudentQuery from "./loadStudentQuery"

const EditStudentForm = props => {
  const { id } = props
  const loadingState = useQuery(buildQuery(loadStudentQuery, { id }))

  const buildFormState = student => {
    const {
      grade,
      school,
      parent,
      partner,
      organization,
      tags,
      ...studentFields
    } = student

    const normalizedGrade = grade
      ? {
          value: grade.id,
          label: grade.name,
        }
      : null

    const normalizedSchool = school
      ? {
          value: school.id,
          label: school.name,
        }
      : null

    const normalizedPartner = partner
      ? {
          label: partner.fullName,
          value: partner.id,
        }
      : null

    const normalizedParent = parent
      ? {
          label: parent.fullName,
          value: parent.id,
        }
      : null

    const normalizedOrganization = organization
      ? {
          label: organization.name,
          value: organization.id,
        }
      : null

    const normalizedTags = tags.map(tag => ({ value: tag.id, label: tag.name }))

    return {
      id,
      grade: normalizedGrade,
      school: normalizedSchool,
      parent: normalizedParent,
      partner: normalizedPartner,
      organization: normalizedOrganization,
      tags: normalizedTags,
      ...studentFields,
    }
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading the requested student"
    >
      {({ data }) => <Form formState={buildFormState(data.student)} />}
    </WithLoadingIndicator>
  )
}

export default EditStudentForm
