import React from "react"

import { useField } from "formik"

import ordered from "src/ordered"

import { RadioField } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

const ScheduleSelection = ({ courses, nextStep }) => {
  const [subjectField] = useField("subjectId")
  const [durationField] = useField("durationWeeks")
  const [courseSectionField] = useField("courseSectionId")

  const courseSections = courses
    .filter(
      course =>
        String(course.subject.id) === String(subjectField.value) &&
        String(course.durationWeeks) === String(durationField.value)
    )
    .map(course => course.studentRegisterableCourseSections)
    .flat()

  if (courseSections.length === 0) {
    return <h3>No courses found</h3>
  }

  const handleNextStep = () => {
    nextStep()
  }

  return (
    <>
      <RadioField
        name="courseSectionId"
        label="Select Schedule Option"
        options={ordered(
          ordered(
            ordered(courseSections, "availabilities", "0", "weekdayNumber"),
            "availabilities",
            "0",
            "startsAt"
          ),
          "startsOn"
        ).map(courseSection => ({
          value: courseSection.id,
          label: (
            <>
              <div className="font-medium">
                <LocalTime timestamp={courseSection.startsOn} omitTime />{" "}
                &mdash; <LocalTime timestamp={courseSection.endsOn} omitTime />
              </div>
              <ul className="m-0">
                {ordered(courseSection.availabilities, "weekdayNumber").map(
                  availability => (
                    <li key={availability.id}>
                      <div>{availability.weekday}s</div>
                      <div className="ml-3 italic">
                        <LocalTime timestamp={availability.startsAt} omitDate />{" "}
                        &mdash;{" "}
                        <LocalTime timestamp={availability.endsAt} omitDate />
                      </div>
                    </li>
                  )
                )}
              </ul>
            </>
          ),
        }))}
      />
      <button
        type="button"
        onClick={handleNextStep}
        disabled={courseSectionField.value === ""}
        className="btn solid blue"
      >
        Next: Review Selected Course
      </button>
    </>
  )
}

export default ScheduleSelection
