import React, { useRef } from "react"

import { camelcase } from "camelcase-input"
import Select from "react-select"
import ReactTable from "react-table"

import { formatDate } from "src/date-helpers"
import { statementStateOptions } from "src/enums"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import StatementSettings from "components/Admin/Organization/Statements/Settings"
import { DateRangePicker } from "components/Forms/DatePicker"

import SendEmail from "../Details/SendEmail"

import CombinePdf from "./CombinePdf"

import "react-table/react-table.css"

const OrganizationStatements = ({
  apiEndpoint,
  byOrganization,
  organizationId,
  newStatementPath,
  budgetsPath,
  statementConfigurationsPath,
}) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint,
  })
  const table = useRef(null)
  const { exportToCSV } = useExportable({
    apiEndpoint,
    tableRef: table.current,
  })

  const columns = [
    {
      id: "starts_at",
      Header: "Statement Start Date",
      accessor: "starts_at_display",
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ startsAtStart: null, startsAtEnd: null })
              return
            }

            onChange({
              startsAtStart: formatDate(startDate.toDate()),
              startsAtEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "ends_at",
      Header: "Statement End Date",
      accessor: "ends_at_display",
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ endsAtStart: null, endsAtEnd: null })
              return
            }

            onChange({
              endsAtStart: formatDate(startDate.toDate()),
              endsAtEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "total",
      Header: "Total Dollar Amount",
      accessor: "total",
      filterable: false,
      Cell: props =>
        props.value < 0 ? (
          <span className="text-red-500">{props.original.formatted_total}</span>
        ) : (
          props.original.formatted_total
        ),
    },
    {
      id: "invoice_number",
      Header: "Invoice Number",
      accessor: "invoice_number",
      filterable: false,
    },
    {
      id: "budget_name",
      Header: "Budget",
      accessor: "budget",
      filterable: false,
      Cell: props => (
        <a href={props.value.show_path}>
          {props.value.name}
          {props.value.po_number && (
            <>
              <br />
              PO {props.value.po_number}
            </>
          )}
        </a>
      ),
    },
    {
      id: "remaining_balance",
      Header: "Budget Balance",
      accessor: "budget.balance",
      filterable: false,
      Cell: props => {
        return props.value.total < 0 ? (
          <span className="text-red-500">{props.value.formatted}</span>
        ) : (
          props.value.formatted
        )
      },
    },
    {
      id: "aasm_state",
      Header: "Status",
      accessor: "aasm_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={eventArray =>
              onChange({ aasm_states: eventArray.map(event => event.value) })
            }
            setValue={filter ? filter.value : "all"}
            options={statementStateOptions}
            isMulti={true}
            isClearable
          />
        )
      },
      Cell: props => (
        <>
          <div>{props.value}</div>
          {props.original.may_submit ? (
            <SendEmail
              small
              organization={camelcase(props.original.budget.payer)}
              statement={camelcase(props.original)}
            />
          ) : null}
        </>
      ),
    },
  ]

  if (!byOrganization) {
    columns.push({
      id: "payer_id",
      Header: "Organization",
      accessor: "budget.payer",
      filterable: false,
      Cell: props => <a href={props.value.show_path}>{props.value.name}</a>,
      visible: false,
      isVisible: false,
      Visible: false,
    })
  }

  columns.push({
    id: "links",
    Header: "Actions",
    accessor: "links",
    filterable: false,
    sortable: false,
    Cell: props => (
      <a className="btn btn-sm btn-info" href={props.value.show_path}>
        Details
      </a>
    ),
  })

  return (
    <>
      {newStatementPath && (
        <div>
          <a href={newStatementPath} className="btn btn-success">
            New Statement
          </a>
          <StatementSettings organizationId={organizationId} />
          <a href={statementConfigurationsPath} className="btn btn-info">
            Statement Configurations
          </a>
        </div>
      )}
      <div>
        <button type="button" onClick={exportToCSV} className="btn">
          CSV Export
        </button>
        <a className="btn btn-info" href={budgetsPath}>
          View Budgets
        </a>
        <CombinePdf organizationId={organizationId} />
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default OrganizationStatements
