import React from "react"

import ReactModal from "react-modal"

import emptyFunction from "src/emptyFunction"

function modalHOC(WrappedComponent) {
  class Modal extends React.Component {
    constructor(props) {
      super(props)
      this.state = { isOpen: props.isOpen }
    }

    openModal = () => {
      this.setState({ isOpen: true })
    }

    closeModal = () => {
      this.setState({ isOpen: false })
      if (this.props.afterClose) this.props.afterClose()
    }

    afterOpenModal = emptyFunction

    render() {
      return (
        <WrappedComponent
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.isOpen}
          {...this.props}
        />
      )
    }
  }

  Modal.displayName = `ModalHOC(${WrappedComponent.displayName})`
  return Modal
}

class DefaultModal extends React.Component {
  render() {
    let modalTrigger
    if (!this.props.hideTrigger)
      modalTrigger = (
        <button
          onClick={this.props.openModal}
          className={this.props.buttonClassName}
        >
          {this.props.buttonText}
        </button>
      )

    return (
      <div>
        {modalTrigger}
        <ReactModal
          isOpen={this.props.isOpen}
          onAfterOpen={this.props.afterOpenModal}
          onRequestClose={this.props.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel={this.props.buttonClassName}
          bodyOpenClassName="modal-open"
          ariaHideApp={false}
          className={`modal show ${this.props.modalClassName}`}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2
                  className="modal-title"
                  ref={subtitle => (this.subtitle = subtitle)}
                >
                  {this.props.modalTitle}
                </h2>
                <button
                  type="button"
                  className="close"
                  onClick={this.props.closeModal}
                  aria-label="Close"
                  tabIndex="-1"
                >
                  <span aria-hidden="true">
                    <i className="nc-icon nc-simple-remove text-danger" />
                  </span>
                </button>
              </div>
              <div
                className="modal-body"
                dangerouslySetInnerHTML={{ __html: this.props.content }}
              />
              <div className="modal-footer">
                <div className="d-flex justify-content-between w-100">
                  <a
                    href={this.props.confirmURL}
                    className={this.props.confirmClassName}
                    data-method={this.props.confirmMethod}
                  >
                    {this.props.confirmText}
                  </a>
                  <button
                    className={this.props.cancelClassName}
                    onClick={this.props.closeModal}
                  >
                    {this.props.cancelText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

DefaultModal.defaultProps = {
  confirmClassName: "btn btn-info",
  confirmText: "OK",
  confirmMethod: "get",
  cancelClassName: "btn btn-danger",
  cancelText: "Close",
  content: "<p class='lead'>Are you sure?</p>",
  isOpen: false,
}

export { modalHOC }
export default modalHOC(DefaultModal)
