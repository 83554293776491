import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  AutocompleteField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide a display name"),
  adminId: Yup.string().required("Please select an admin"),
  calendlyUrl: Yup.string().required("Please provide a calendly URL"),
})

const EditInterviewer = ({ interviewer }) => {
  const formState = {
    tutorApplicationInterviewerId: interviewer.id,
    name: interviewer.name,
    adminId: interviewer.admin.id,
    calendlyUrl: interviewer.calendlyUrl,
  }
  const [, updateInterviewer] = useMutation(updateInterviewerMutation)

  const handleSubmit = (values, actions, closeModal) => {
    updateInterviewer(values)
      .then(
        response => {
          const { failures } = response.data.updateTutorApplicationInterviewer
          if (failures) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.info("Interviewer updated")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Interviewer"
      buttonText="Edit Interviewer"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <Field
                name="name"
                label="Display Name (this will be displayed to the applicant)"
              />
              <AutocompleteField
                name="adminId"
                label="Admin"
                api="/api/admins/admins/autocomplete_full_name"
                defaultValue={{
                  value: interviewer.admin.id,
                  label: interviewer.admin.fullName,
                }}
              />
              <Field name="calendlyUrl" label="Calendly URL" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <button type="button" className="btn" onClick={closeModal}>
                  Cancel
                </button>
                <SubmitButton text="Update" className="btn btn-danger" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateInterviewerMutation = gql`
  mutation (
    $tutorApplicationInterviewerId: ID!
    $name: String!
    $adminId: ID!
    $calendlyUrl: String!
  ) {
    updateTutorApplicationInterviewer(
      tutorApplicationInterviewerId: $tutorApplicationInterviewerId
      name: $name
      adminId: $adminId
      calendlyUrl: $calendlyUrl
    ) {
      failures {
        message
      }
      tutorApplicationInterviewer {
        id
        name
        calendlyUrl
        admin {
          id
          fullName
        }
      }
    }
  }
`

export default EditInterviewer
