import React, { useState } from "react"

import { motion } from "framer-motion"
import { NotificationContainer } from "react-notifications"

import { gql, useQuery } from "hooks/urql"

import EditableList, { UrqlItem } from "components/EditableList"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import AddUser from "./AddUser"

import "react-notifications/lib/notifications.css"

const ForeignLanguageDetails = ({ foreignLanguageId }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: foreignLanguageQuery,
    variables: { foreignLanguageId, page },
  })

  return (
    <>
      <NotificationContainer />
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <h4 className="mt-0">Users</h4>
            <EditableList>
              {data.foreignLanguage.users.data.map(user => (
                <motion.li key={user.id}>
                  <UrqlItem
                    mutation={removeUserMutation}
                    mutationProps={{
                      foreignLanguageId,
                      userId: user.id,
                    }}
                  >
                    {user.fullName}
                  </UrqlItem>
                </motion.li>
              ))}
            </EditableList>
            <Pagination
              currentPage={data.foreignLanguage.users.currentPage}
              totalPages={data.foreignLanguage.users.totalPages}
              changePage={setPage}
            />
            <AddUser foreignLanguageId={foreignLanguageId} />
          </>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const foreignLanguageQuery = gql`
  query ($foreignLanguageId: ID!, $page: Int) {
    foreignLanguage(id: $foreignLanguageId) {
      id
      name
      users(page: $page) {
        currentPage
        totalPages
        data {
          id
          fullName
          showPath
        }
      }
    }
  }
`

const removeUserMutation = gql`
  mutation ($foreignLanguageId: ID!, $userId: ID!) {
    foreignLanguageRemoveUser(
      foreignLanguageId: $foreignLanguageId
      userId: $userId
    ) {
      foreignLanguage {
        id
      }
      failures {
        message
      }
    }
  }
`

export default ForeignLanguageDetails
