import React, { useEffect, useMemo, useRef } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"
import { tutorClient } from "src/graphql-config"

import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"
import TimeProtectedLink from "components/TimeProtectedLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const AirtutorsSessionResources = ({ id }) => {
  const loadingState = useQuery(
    buildQuery(
      resourcesQuery,
      { id },
      {
        client: tutorClient,
        onMutation: [
          {
            when: "createGoogledoc",
            run: ({ softReset, currentResults }, response) => {
              const airtutorsSession = response.createGoogledoc.airtutorsSession
              currentResults.airtutorsSession.googledocPending =
                airtutorsSession.googledocPending
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const channelProps = useMemo(() => {
    return { id }
  }, [id])

  useWebsocket({
    channelName: "AirtutorsSessionChannel",
    channelProps,
  })

  const reload = useRef()
  reload.current = loadingState.reload

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      reload.current()
    })
  }, [])

  const { runMutation } = useMutation(
    buildMutation(createGoogledocMutation, { client: tutorClient })
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div
          css={css`
            margin-top: 15px;
          `}
        >
          {data.airtutorsSession.realtimeboards.map(realtimeboard => (
            <div className="tutor__resource" key={realtimeboard.id}>
              <TimeProtectedMiroLink
                startsAt={data.airtutorsSession.startsAt}
                email={data.viewer.email}
                boardId={realtimeboard.id}
                buttonText={realtimeboard.displayName}
              />
            </div>
          ))}

          {data.airtutorsSession.realtimeboardPending && (
            <div className="tutor__resource">
              <button className="btn gray">
                <span>Whiteboard Pending</span>
                <div
                  css={css`
                    display: inline-block;
                    margin-left: 15px;
                  `}
                >
                  <Loading />
                </div>
              </button>
            </div>
          )}

          {data.airtutorsSession.googledocUrl && (
            <div className="tutor__resource">
              <TimeProtectedLink
                text="Google Doc"
                href={data.airtutorsSession.googledocUrl}
                startsAt={data.airtutorsSession.startsAt}
                className="btn solid green"
                target="_blank"
              />
            </div>
          )}

          {data.airtutorsSession.googleSpreadsheetUrl && (
            <div className="tutor__resource">
              <TimeProtectedLink
                text="Google Spreadsheet"
                href={data.airtutorsSession.googleSpreadsheetUrl}
                startsAt={data.airtutorsSession.startsAt}
                className="btn solid green"
                target="_blank"
              />
            </div>
          )}

          {!data.airtutorsSession.googledocUrl &&
            (data.airtutorsSession.googledocPending ? (
              <div className="tutor__resource">
                <button className="btn gray">
                  <span>Google Doc Pending</span>
                  <div
                    css={css`
                      display: inline-block;
                      margin-left: 15px;
                    `}
                  >
                    <Loading />
                  </div>
                </button>
              </div>
            ) : (
              <div className="tutor__resource">
                <button
                  onClick={() => runMutation({ id })}
                  className="btn solid blue"
                >
                  Create Google Doc
                </button>
              </div>
            ))}
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const resourcesQuery = compress`
  query($id: ID!) {
    viewer {
      email
    }
    airtutorsSession(id: $id) {
      startsAt
      googledocUrl
      googledocPending
      realtimeboardPending
      realtimeboards {
        id
        displayName
      }
    }
  }
`

const createGoogledocMutation = compress`
  mutation($id: ID!) {
    createGoogledoc(id: $id) {
      airtutorsSession {
        googledocPending
      }
    }
  }
`

AirtutorsSessionResources.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default AirtutorsSessionResources
