import React, { createContext, useContext, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import { NotificationManager } from "react-notifications"

import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import studentFields from "./studentFields"

const ModalContext = createContext([])

const StudentInfoModal = () => {
  const [student, setStudent] = useContext(ModalContext)
  const [errors, setErrors] = useState()
  const [contactedAt, setContactedAt] = useState()
  const [contactedBy, setContactedBy] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(studentContactedMutation)
  )

  const closeModal = () => {
    setErrors()
    setStudent()
  }

  const studentContacted = () => {
    runMutation({ studentId: student.id })
      .then(
        response => {
          const { errorMessages, contactedAt, contactedBy } =
            response.studentContacted
          if (errorMessages) {
            setErrors(errorMessages)
            setContactedAt(contactedAt)
            setContactedBy(contactedBy)
          } else {
            NotificationManager.info("Student has been marked as contacted")
            setErrors()
            setStudent()
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Student Info"
      isOpen={!!student}
      hideTrigger
      hideCloseButton
    >
      {!student ? null : (
        <>
          <div className="modal-body">
            <p className="lead font-italic">
              Please click the contact button
              <span className="font-weight-bold">&nbsp;before&nbsp;</span>
              contacting them to ensure multiple admins are not sending the same
              message.
            </p>

            <dl>
              <dt>Name</dt>
              <dd>{student.fullName}</dd>

              <dt>Email</dt>
              <dd>{student.email}</dd>

              <dt>Phone</dt>
              <dd>{student.phoneNumber}</dd>

              {student.school && (
                <>
                  <dt>School</dt>
                  <dd>{student.school.name}</dd>
                </>
              )}

              {student.grade && (
                <>
                  <dt>Grade</dt>
                  <dd>{student.grade.name}</dd>
                </>
              )}

              <dt>Parent Name</dt>
              <dd>{student.parentName}</dd>

              <dt>Parent Email</dt>
              <dd>{student.parentEmail}</dd>

              <dt>Parent Phone</dt>
              <dd>{student.parentPhoneNumber}</dd>
            </dl>
          </div>

          <div className="modal-footer flex-column">
            {errors && (
              <div className="alert alert-danger">
                <div>{errors}</div>

                {contactedAt && (
                  <div>
                    Contacted at: <LocalTime timestamp={contactedAt} />
                  </div>
                )}

                {contactedBy && (
                  <div>By: {contactedBy.fullName || contactedBy.email}</div>
                )}
              </div>
            )}

            <div className="w-100 d-flex justify-content-between">
              <button onClick={closeModal} className="btn btn-danger">
                Cancel
              </button>
              <button
                onClick={studentContacted}
                className="btn btn-success"
                disabled={running}
              >
                Contact
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const studentContactedMutation = compress`
  ${studentFields}
  mutation($studentId: ID!) {
    studentContacted(studentId: $studentId) {
      errorMessages
      contactedAt
      contactedBy {
        fullName
        email
      }
      student {
        ...StudentFields
        organization {
          id
        }
      }
    }
  }
`

export default StudentInfoModal
export { ModalContext }
