import { compress } from "micro-graphql-react"

export default compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      grade {
        tier {
          id
        }
      }
      subjects {
        id
        name
      }
    }
  }
`
