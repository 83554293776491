import React, { useRef } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { tutorApplicationStates } from "src/enums"
import ordered from "src/ordered"

import { useRemoteTableData, useSubjectBucketOptions } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const AdminTutorApplicationsTable = props => {
  const subjectBucketOptions = useSubjectBucketOptions()
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/tutor_applications",
  })
  const fullNameSelect = useRef(null)
  const emailSelect = useRef(null)

  const clearableSelects = [fullNameSelect.current, emailSelect.current]

  const columns = [
    {
      id: "tutor_applications.full_name",
      Header: "Name",
      accessor: "full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={fullNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutor_applications/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "tutor_applications.email",
      Header: "Email",
      accessor: "email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={emailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutor_applications/autocomplete_email"
          />
        )
      },
    },
    {
      id: "subject_buckets",
      Header: "Subjects",
      accessor: "subject_buckets",
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(ordered(props.value, "name"), "tier", "order").map(
            subjectBucket => (
              <li key={subjectBucket.id}>
                {subjectBucket.name} ({subjectBucket.tier.grade_range})
              </li>
            )
          )}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => (event ? onChange(event.value) : onChange(""))}
            setValue={filter ? filter.value : "all"}
            options={subjectBucketOptions}
            isClearable
          />
        )
      },
    },
    {
      id: "teaching_experience",
      Header: "Teaching Experience",
      accessor: "teaching_experience",
      sortable: false,
      filterable: false,
      Cell: props => (
        <ul className="list-unstyled">
          <li>K-2 {props.value.teaching_experience_k_2}</li>
          <li>3-5 {props.value.teaching_experience_3_5}</li>
          <li>6-8 {props.value.teaching_experience_6_8}</li>
          <li>9-12 {props.value.teaching_experience_9_12}</li>
          <li>College {props.value.teaching_experience_college}</li>
        </ul>
      ),
    },
    {
      id: "availabilities",
      Header: "Availability",
      accessor: "availabilities",
      sortable: false,
      filterable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(ordered(props.value, "weekday"), "starts_at").map(
            availability => (
              <li key={availability.id}>
                <LocalTime
                  timestamp={availability.starts_at}
                  format="dddd LT"
                />{" "}
                &mdash; <LocalTime timestamp={availability.ends_at} omitDate />
              </li>
            )
          )}
        </ul>
      ),
    },
    {
      id: "current_assignee",
      Header: "Current Assignee",
      accessor: "current_assignee",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/tutor_applications/autocomplete_assignee"
          />
        )
      },
    },
    {
      id: "resume_submitted_at",
      Header: "Resume Submitted",
      accessor: "resume_submitted_at",
      filterable: false,
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
    },
    {
      Header: "Application State",
      accessor: "application_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => onChange(event.value)}
            setValue={filter ? filter.value : ""}
            options={tutorApplicationStates}
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="d-flex justify-content-center flex-wrap">
          <a href={props.value.show_path} className="btn btn-sm btn-info">
            Details
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminTutorApplicationsTable
