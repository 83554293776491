import { compress } from "micro-graphql-react"

export default compress`
  query($id: ID!) {
    student(id: $id) {
      firstName
      lastName
      email
      username
      phoneNumber
      emailTimeZone
      parentName
      parentEmail
      parentPhoneNumber
      noShowCount
      couponCode
      enableSessionEmails
      automatedTwilioSessionsEnabled
      smsNotificationsEnabled
      hasActAccess
      hasSatAccess
      hasIseeAccess
      notes
      prepaidHours

      school {
        id
        name
      }

      tags {
        id
        name
      }

      referralSource {
        label
        value
      }

      subjects {
        id
        name
      }

      grade {
        id
        name
      }

      parent {
        id
        fullName
        email
        phoneNumber
      }

      partner {
        id
        fullName
      }

      organization {
        id
        name
      }
    }
  }
`
