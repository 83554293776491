import React from "react"

import { ArrowUpIcon } from "@heroicons/react/20/solid"

import RadioField from "components/Forms/Formik/hookComponents/RadioField"

export type TailoredQuestionProps = {
  question: any
  scrollTo?: string
}

const TailoredQuestion: React.FC<TailoredQuestionProps> = ({
  question,
  scrollTo,
}) => {
  return (
    <div
      className="my-4 rounded-md border-solid border-gray-400 p-4 font-mono"
      key={question.id}
    >
      <h5>
        {question.type === "text" ? (
          question.text
        ) : (
          <img src={question.image.url} alt={question.image.filename} />
        )}
      </h5>

      {scrollTo && (
        <span
          onClick={() => document.getElementById(scrollTo)?.scrollIntoView()}
          className="flex items-center font-sans text-gray-500 hover:cursor-pointer"
        >
          Jump to Reference Image(s) <ArrowUpIcon className="ml-2 h-5 w-5" />
        </span>
      )}

      <hr />
      <div className="grid grid-cols-2">
        <RadioField
          options={question.tailoredAnswers.map(answer => ({
            label:
              answer.type === "text" ? (
                answer.text
              ) : (
                <img src={answer.image.url} alt={answer.image.filename} />
              ),
            value: answer.id,
          }))}
          name={`studentAnswers[${question.id}]answerId`}
        />

        {question.diagram && (
          <div>
            <img src={question.diagram.url} alt={question.diagram.filename} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TailoredQuestion
