import React from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { weekdayOptions } from "src/enums"

import { DateObject, TimePicker } from "components/Forms/DatePicker"
import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  startsAt: Yup.string().required(),
  endsAt: Yup.string().required(),
  blockDuration: Yup.string().required(),
  weekdays: Yup.array().min(1),
})

const GenerateSetAvailabilities = ({ availabilitySetId }) => {
  const formState = {
    availabilitySetId,
    startsAt: new DateObject().setHour(9).setMinute(0).setSecond(0),
    endsAt: new DateObject().setHour(21).setMinute(0).setSecond(0),
    blockDuration: "",
    weekdays: [],
  }

  const { runMutation } = useMutation(
    buildMutation(createAvailabilitiesMutation)
  )

  const handleSubmit = (values, actions) => {
    const { startsAt, endsAt, ...rest } = values

    const startsAtDate = startsAt.toDate()
    const endsAtDate = endsAt.toDate()

    if (startsAtDate.getDate() !== endsAtDate.getDate()) {
      endsAtDate.setFullYear(startsAtDate.getFullYear())
      endsAtDate.setMonth(startsAtDate.getMonth())
      endsAtDate.setDate(startsAtDate.getDate())
    }

    runMutation({
      startsAt: moment(startsAtDate).format(),
      endsAt: moment(endsAtDate).format(),
      ...rest,
    })
      .then(
        response => {
          if (response.availabilitySetGenerate.success) {
            window.location.reload()
          } else {
            actions.setStatus(
              response.availabilitySetGenerate.errors.join(", ")
            )
          }
        },
        error => {
          actions.setStatus("An error occurred")
        }
      )
      .catch(error => {
        actions.setStatus("An error occurred")
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Generate Availabilities"
      buttonText="Generate Availabilities"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {({ isSubmitting, values, setFieldValue, status }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <div
                    className="my-3"
                    css={css`
                      display: flex;
                      justify-content: space-around;
                    `}
                  >
                    <h4 className="m-0">Start Time</h4>
                    <span>&nbsp;</span>
                    <h4 className="m-0">End Time</h4>
                  </div>

                  <div
                    css={css`
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    `}
                  >
                    <div>
                      <TimePicker
                        value={values.startsAt}
                        onChange={date => setFieldValue("startsAt", date)}
                      />
                    </div>

                    <span className="mx-3">&mdash;</span>

                    <div>
                      <TimePicker
                        value={values.endsAt}
                        onChange={date => setFieldValue("endsAt", date)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <ErrorMessage name="startsAt" />
                  <ErrorMessage name="endsAt" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Block Length (hours:minutes ie 1:00 for 1 hour windows)
                    <Field name="blockDuration" className="form-control" />
                    <ErrorMessage name="blockDuration" />
                  </label>
                </div>

                <div className="form-group">
                  <FieldArray name="weekdays">
                    {arrayHelpers => (
                      <RadioGroup
                        type="checkbox"
                        name="weekdays"
                        values={weekdayOptions}
                        onChange={e => {
                          if (e.currentTarget.checked) {
                            arrayHelpers.push(e.currentTarget.value)
                          } else {
                            arrayHelpers.remove(
                              values.weekdays.findIndex(
                                s => s === e.currentTarget.value
                              )
                            )
                          }
                        }}
                      />
                    )}
                  </FieldArray>
                  <ErrorMessage name="weekdays" />
                </div>
              </div>
              <div className="modal-footer flex-column">
                {status && (
                  <div className="alert alert-danger mt-3">{status}</div>
                )}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Generate"
                  containerCss={css`
                    margin-right: 15px;
                  `}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createAvailabilitiesMutation = compress`
  mutation(
    $availabilitySetId: ID!
    $startsAt: DateTime!
    $endsAt: DateTime!
    $weekdays: [WeekdayEnum!]!
    $blockDuration: String!
  ) {
    availabilitySetGenerate(
      availabilitySetId: $availabilitySetId
      startsAt: $startsAt
      endsAt: $endsAt
      weekdays: $weekdays
      blockDuration: $blockDuration
    ) {
      success
      errors
    }
  }
`

export default GenerateSetAvailabilities
