import React from "react"

import ExpandableImageCollection from "components/ExpandableImageCollection"

import TailoredQuestionPreview from "./TailoredQuestionPreview"

const TailoredSection = ({ tailoredSection }) => {
  const { name, tailoredQuestions, tailoredQuestionGroups } = tailoredSection

  return (
    <React.Fragment>
      <h3>{name}</h3>

      {tailoredQuestions.map(tailoredQuestion => (
        <TailoredQuestionPreview
          key={tailoredQuestion.id}
          question={tailoredQuestion}
        />
      ))}

      {tailoredQuestionGroups.map(
        tailoredQuestionGroup =>
          tailoredQuestionGroup.tailoredQuestions.length > 0 && (
            <div key={tailoredQuestionGroup.id}>
              <div id={`group-${tailoredQuestionGroup.id}`}>
                <p className="text-xl font-semibold">
                  The next {tailoredQuestionGroup.tailoredQuestions.length}{" "}
                  questions will reference the following image(s). Click on an
                  image to toggle enlarged view.
                </p>
                <ExpandableImageCollection
                  images={tailoredQuestionGroup.diagrams}
                />
              </div>

              {tailoredQuestionGroup.tailoredQuestions.map(tailoredQuestion => (
                <TailoredQuestionPreview
                  key={tailoredQuestion.id}
                  question={tailoredQuestion}
                  scrollTo={`group-${tailoredQuestionGroup.id}`}
                />
              ))}
            </div>
          )
      )}
    </React.Fragment>
  )
}

export default TailoredSection
