import React, { useState } from "react"

import ordered from "src/ordered"

import { gql, useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const PaginatedStudentList = ({ schoolId }) => {
  const [page, setPage] = useState(1)

  const [result] = useQuery({
    query: studentQuery,
    variables: { schoolId, page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <div>
          <h4 className="mt-0">Students</h4>
          {data.school.students.data.length === 0 ? (
            <p className="italic">No students</p>
          ) : (
            <>
              <ul className="list-unstyled">
                {ordered(data.school.students.data, "lastName").map(
                  ({ id, fullName, showPath }) => (
                    <li key={id}>
                      <a href={showPath}>{fullName}</a>
                    </li>
                  )
                )}
              </ul>

              <Pagination
                currentPage={data.school.students.currentPage}
                totalPages={data.school.students.totalPages}
                changePage={setPage}
              />
            </>
          )}
        </div>
      )}
    </UrqlLoadingIndicator>
  )
}

const studentQuery = gql`
  query ($schoolId: ID!, $page: Int!) {
    school(id: $schoolId) {
      students(page: $page) {
        totalPages
        currentPage
        data {
          id
          fullName
          lastName
          showPath
        }
      }
    }
  }
`

export default PaginatedStudentList
