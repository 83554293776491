import {
  adminClient,
  organizationAdminClient,
  parentClient,
  publicClient,
  studentClient,
  teacherClient,
  tutorClient,
} from "src/urql-client"

const useClient = () => {
  const namespace = window.location.pathname.split("/")[1]
  let client
  switch (namespace) {
    case "admin":
      client = adminClient
      break
    case "parent":
      client = parentClient
      break
    case "tutor":
      client = tutorClient
      break
    case "student":
      client = studentClient
      break
    case "organization_admin":
      client = organizationAdminClient
      break
    case "teacher":
      client = teacherClient
      break
    default:
      client = publicClient
  }

  return client
}

export default useClient
