import React, { useState } from "react"

import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CloudRoom from "components/TwilioCloudRoom/QualityControl"

const TwilioCloudRoomContainer = ({ session }) => {
  const [muted, setMuted] = useState(true)

  const toggleMute = () => {
    setMuted(!muted)
  }

  return (
    <div
      key={session.id}
      className="aspect-[4/3] h-[20vh] overflow-hidden rounded-md border-solid border-gray-300"
    >
      <h4 className="m-0 my-1 ml-2 flex justify-between">
        <a
          href={session.showPath}
          target="_blank"
          rel="noopener noreferrer"
          className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
        >
          {session.tutor.fullName}: {session.subject.name}
        </a>
        <FontAwesomeIcon
          icon={muted ? faVolumeMute : faVolumeUp}
          onClick={toggleMute}
          className="ml-2 mr-4 basis-auto self-center"
        />
      </h4>
      <div>
        <CloudRoom
          key={session.id}
          id={session.id}
          accessToken={session.accessToken}
          muted={muted}
        />
      </div>
    </div>
  )
}

export default TwilioCloudRoomContainer
