import React, { useState } from "react"

import { DefaultModality } from "amazon-chime-sdk-js"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import RemoteTile from "components/ChimeCloudroom/Shared/RemoteTile"
import useChimeCloudroom from "components/ChimeCloudroom/hooks/useChimeCloudroom"

export type QualityControlLayoutProps = {
  session: any
  localUser: {
    type: "Admin"
    id: string
  }
  muted: boolean
}

const QualityControlLayout: React.FC<QualityControlLayoutProps> = ({
  session,
  localUser,
  muted,
}) => {
  const [roster, setRoster] = useState<Record<string, any>>({})
  const cloudroomInfo = useChimeCloudroom({
    session,
    localUser,
    setRoster,
    ghost: true,
    muteOutput: muted,
  })

  if (muted) {
    cloudroomInfo.audioVideo?.unbindAudioElement()
  } else {
    const audioElement: HTMLAudioElement = document.querySelector("#room-audio")
    cloudroomInfo.audioVideo?.bindAudioElement(audioElement)
    setTimeout(() => audioElement.play(), 500)
  }

  const localAttendeeId = session.chimeJoinInfo.attendee.attendeeId
  const remoteAttendeeIds = Object.keys(roster).filter(
    id => new DefaultModality(id).base() !== localAttendeeId
  )

  const primaryAttendeeId = remoteAttendeeIds.find(id => {
    if (roster[id].sharingContent) return true

    return roster[id].externalUserId.split("-")[0] === "Tutor"
  })

  return primaryAttendeeId ? (
    <CloudroomContext.Provider value={{ ...cloudroomInfo, roster, localUser }}>
      <audio className="hidden" id="room-audio" />
      {primaryAttendeeId && (
        <RemoteTile attendeeId={primaryAttendeeId} qualityControlView={true} />
      )}
    </CloudroomContext.Provider>
  ) : (
    <p className="pt-6 text-center italic">
      The cloudroom is active, but there is no tutor present.
    </p>
  )
}

export default QualityControlLayout
