import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { tutorClient } from "src/graphql-config"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import createRealtimeboardMutation from "./createRealtimeboardMutation"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const TutorCreateRealtimeboard = props => {
  const { id } = props
  const initialFormState = {
    name: "",
  }

  const { runMutation } = useMutation(
    buildMutation(createRealtimeboardMutation, { client: tutorClient })
  )

  const handleSubmit = (values, actions) => {
    runMutation({ id, name: values.name }).then(response => {
      if (!response.createRealtimeboard.success) {
        actions.setFieldError(
          "name",
          response.createRealtimeboard.errorMessages.join(", ")
        )
      }
    })
  }

  return (
    <>
      <ModalWithProvidedBody
        buttonText="Add Whiteboard"
        buttonClassName="btn solid blue"
        modalClassName="bootstrap-modal"
        modalTitle="Add Whiteboard"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={initialFormState}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="form-label">
                      Name
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        autoFocus
                      />
                      <ErrorMessage name="name" />
                      <p>
                        Enter a short name to differentiate this board from the
                        default
                      </p>
                    </label>
                  </div>

                  <div className="modal-footer">
                    <div className="d-flex justify-content-between w-100">
                      <SubmitButton isSubmitting={isSubmitting} />
                      <div
                        css={css`
                          display: inline-block;
                          margin: 15px 15px 0 0;
                        `}
                      >
                        <button className="btn solid red" onClick={closeModal}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

export default TutorCreateRealtimeboard
