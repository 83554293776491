import React, { useEffect, useRef, useState } from "react"

import { Form, Formik } from "formik"
import moment from "moment-timezone"
import { pipe, subscribe } from "wonka"
import * as Yup from "yup"

import { gql } from "@urql/core"

import createBlob from "src/createBlob"
import client from "src/urql-client"

import {
  DateRangeField,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  dateRange: Yup.array().min(1, "You must select a date range"),
})

const CombinePdf = ({ organizationId }) => {
  const formState = { dateRange: [] }
  const [status, setStatus] = useState()
  const [downloadUrl, setDownloadUrl] = useState()
  const downloadLink = useRef()

  const handleSubmit = (values, actions, closeModal) => {
    const {
      dateRange: [startsAt, endsAt],
    } = values

    const params = { organizationId }
    params.startsAt = moment(startsAt.toDate()).startOf("day").format()
    if (endsAt) {
      params.endsAt = moment(endsAt.toDate()).endOf("day").format()
    } else {
      params.endsAt = moment(startsAt.toDate()).endOf("day").format()
    }

    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, params),
      subscribe(result => {
        if (result.data?.combineStatementPdf?.status === "done") {
          const blobUrl = createBlob(
            result.data.combineStatementPdf.blob,
            "application/pdf"
          )
          setDownloadUrl(blobUrl)
          unsubscribe()
          setStatus()
          closeModal()
        } else if (result.data?.combineStatementPdf?.status) {
          setStatus(result.data.combineStatementPdf.status)
        }

        if (result.data?.combineStatementPdf?.errorMessages?.length) {
          handleFailure(
            actions,
            result.data.combineStatementPdf.errorMessages.map(message => ({
              message,
            }))
          )
          unsubscribe()
        }

        if (result.error) {
          handleFailure(actions, [result.error])
        }
      })
    )
  }

  useEffect(() => {
    if (!downloadUrl) return
    downloadLink.current.click()
    setDownloadUrl()
    setTimeout(() => URL.revokeObjectURL(downloadUrl), 0)
  }, [downloadUrl])

  return (
    <>
      <a
        ref={downloadLink}
        href={downloadUrl}
        download={`combined statements.pdf`}
        className="hidden"
      >
        csv
      </a>

      <ModalWithProvidedBody
        modalTitle="Combined Statement Generation"
        buttonText="Combine Statements"
        buttonClassName="btn bg-purple-500 hover:!bg-purple-700 focus:!bg-purple-700 active:focus:!bg-purple-900"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <p>
                  This will download existing statements and combine them into a
                  single PDF. It will <em>not</em> regenerate the existing
                  statements.
                </p>
                <DateRangeField
                  name="dateRange"
                  label="Statement Start Date Range"
                />
              </div>
              <div className="modal-footer flex-col">
                <FormFailures />
                {status && (
                  <div className="alert w-full bg-purple-500 text-center">
                    {status}
                  </div>
                )}
                <div className="flex w-full justify-between">
                  <SubmitButton text="Generate" />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const exportSubscription = gql`
  subscription ($organizationId: ID, $startsAt: DateTime!, $endsAt: DateTime!) {
    combineStatementPdf(
      organizationId: $organizationId
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      status
      blob
      errorMessages
    }
  }
`

export default CombinePdf
