import React from "react"

import { css } from "@emotion/core"

import classNames from "src/classNames"

import { Loading } from "components/Icons"

const SubmitButton = ({ isSubmitting, text, ...props }) => {
  const containerCss = props.containerCss
    ? props.containerCss
    : css`
        display: inline-block;
        margin: 15px 15px 0 0;
      `

  return (
    <div css={containerCss}>
      <button
        type="submit"
        className={classNames("btn btn-info solid blue", props.className)}
        disabled={isSubmitting}
      >
        <span>{text}</span>
        {isSubmitting && (
          <div
            css={css`
              display: inline-block;
              margin-left: 15px;
            `}
          >
            <Loading />
          </div>
        )}
      </button>
    </div>
  )
}

SubmitButton.defaultProps = {
  text: "Save",
}

export default SubmitButton
