import { compress } from "micro-graphql-react"

export default compress`
  fragment StudentFields on Student {
    id
    createdAt
    fullName
    showPath
    email
    phoneNumber
    parentName
    parentEmail
    parentPhoneNumber
    school {
      name
    }
    grade {
      name
    }
  }
`
