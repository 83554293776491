import React from "react"

import { useField } from "formik"

import { participationLevelOptions } from "src/enums"
import ordered from "src/ordered"

import {
  ErrorMessage,
  RadioField,
} from "components/Forms/Formik/hookComponents"

const StudentParticipation = ({ students, sessionPresences }) => {
  const [, meta] = useField("studentParticipation")
  const totalDuration = student => {
    const duration = sessionPresences
      .filter(presence => presence.user.id === student.id)
      .map(presence => presence.duration)
      .reduce((prev, curr) => prev + curr, 0)
    return Math.round(duration * 100) / 100
  }

  return (
    <div>
      <div className="mb-5 text-center">
        <h3 className="my-0 font-medium">Student Participation</h3>
        <div className="italic">
          Do not enter a participation level for{" "}
          <span className="font-semibold">ABSENT</span> students
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
        {ordered(students, "lastName").map(student => (
          <div key={student.id} className="mb-3">
            <RadioField
              name={`studentParticipation[${student.id}].participationLevel`}
              hideError
              label={
                <div className="mb-2">
                  <div className="font-medium">{student.fullName}</div>
                  <div className="italic">
                    (Duration: {totalDuration(student)} minutes)
                  </div>
                </div>
              }
              options={participationLevelOptions}
            />
          </div>
        ))}
      </div>

      <ErrorMessage {...meta} />
    </div>
  )
}

export default StudentParticipation
