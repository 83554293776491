import React, { useRef, useState } from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const AddUser = ({ foreignLanguageId }) => {
  const select = useRef()
  const [errors, setErrors] = useState([])
  const [, addUser] = useMutation(addForeignLanguageMutation)

  const addForeignLanguage = selected => {
    const userId = selected?.id
    if (!userId) return

    addUser({ foreignLanguageId, userId })
      .then(
        result => {
          const { failures } = result.data.foreignLanguageAddUser
          if (failures) {
            setErrors(failures)
          } else {
            NotificationManager.info("User added successfully")
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
      .finally(() => setTimeout(() => select.current.clearValue(), 0))
  }

  return (
    <>
      <AutocompleteSelect
        api="/api/admins/users/autocomplete_full_name"
        placeholder="Add User"
        onChange={addForeignLanguage}
        ref={select}
      />

      <div className="mt-3">
        {errors.map(error => (
          <div key={error.message}>
            <div className="d-inline-flex alert alert-danger">
              {error.message}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const addForeignLanguageMutation = gql`
  mutation ($foreignLanguageId: ID!, $userId: ID!) {
    foreignLanguageAddUser(
      foreignLanguageId: $foreignLanguageId
      userId: $userId
    ) {
      foreignLanguage {
        id
      }
      failures {
        message
      }
    }
  }
`

export default AddUser
