import React from "react"

import classNames from "src/classNames"

import RealtimeboardDirectLink from "components/RealtimeboardDirectLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"

export type MiroButtonProps = {
  user: {
    type: "Student" | "Tutor" | "Teacher" | "Admin"
    ssoEnabled: boolean
    ssoEmail: string
    id: string
  }
  session: {
    realtimeboards: {
      id: string
    }
    startsAt: string
  }
}

const MiroButton: React.FC<MiroButtonProps> = ({ session, user }) => {
  const buttonClassNames = classNames(
    "btn solid green mb-1 btn-success",
    user.type === "Tutor" && "w-full",
    user.type === "Teacher" && "bg-success-700 px-3 py-1 rounded font-semibold"
  )

  return user.ssoEnabled ? (
    <TimeProtectedMiroLink
      email={user.ssoEmail}
      boardId={session.realtimeboards[0]?.id}
      startsAt={session.startsAt}
      buttonClassName={buttonClassNames}
    />
  ) : (
    <RealtimeboardDirectLink
      boardId={session.realtimeboards[0]?.id}
      className={buttonClassNames}
    />
  )
}

export default MiroButton
