import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import ordered from "src/ordered"

import {
  Field,
  FormStatus,
  SelectField,
  SessionDateRangeField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  dates: Yup.array().min(2, "You must specify both a start and and end date"),
  subjectId: Yup.string().required("Please select a subject"),
})

const GenerateSessions = ({ studyGroupId, subjects }) => {
  const formState = {
    studyGroupId,
    subjectId: "",
    dates: [],
    skipDays: "",
  }
  const { runMutation } = useMutation(buildMutation(generateSessionsMutation))

  const handleSubmit = (values, actions) => {
    const { dates, skipDays, ...rest } = values
    const startsOn = moment(dates[0].toDate()).format()
    const endsOn = moment(dates[1].toDate()).format()

    const variables = { startsOn, endsOn, ...rest }
    if (skipDays !== "") {
      variables.skipDays = skipDays
    }

    runMutation(variables)
      .then(
        response => {
          const { errorMessages } = response.studyGroupGenerateSessions
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Generate Sessions"
      buttonText="Generate Sessions"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <SessionDateRangeField name="dates" label="Date Range" />
              <Field
                name="skipDays"
                type="number"
                label="Skip Days (leave this blank if you are using normal availability based scheduling)"
              />

              <SelectField
                name="subjectId"
                label="Subject"
                valueAttribute="id"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={ordered(subjects, "name")}
                menuPortalTarget={document.body}
              />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="d-flex justify-content-between w-full">
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Generate" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const generateSessionsMutation = compress`
  mutation(
    $studyGroupId: ID!
    $subjectId: ID!
    $startsOn: Date!
    $endsOn: Date!
    $skipDays: Int
  ) {
    studyGroupGenerateSessions(
      studyGroupId: $studyGroupId
      subjectId: $subjectId
      startsOn: $startsOn
      endsOn: $endsOn
      skipDays: $skipDays
    ) {
      errorMessages
    }
  }
`

export default GenerateSessions
