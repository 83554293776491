import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import CollapsibleContainer from "components/CollapsibleContainer"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Availability from "./Availability"
import EditGrades from "./EditGrades"
import EditName from "./EditName"
import GenerateAvailabilities from "./GenerateAvailabilities"
import NewAvailabilitySet from "./NewAvailabilitySet"
import RemoveAvailabilitySet from "./RemoveAvailabilitySet"

const Availabilities = ({ organizationId }) => {
  const loadingState = useQuery(
    buildQuery(
      availabilitiesQuery,
      { organizationId },
      {
        onMutation: [
          {
            when: "availabilityRemove",
            run: ({ softReset, currentResults }, response) => {
              const { availability } = response.availabilityRemove
              if (!availability) return

              const index =
                currentResults.organization.availabilitySets.findIndex(
                  set => set.id === availability.owner.id
                )

              currentResults.organization.availabilitySets[
                index
              ].availabilities = currentResults.organization.availabilitySets[
                index
              ].availabilities.filter(av => av.id !== availability.id)
              softReset(currentResults)
            },
          },
          {
            when: "availabilitySetCreate",
            run: ({ softReset, currentResults }, response) => {
              const { availabilitySet } = response.availabilitySetCreate
              if (!availabilitySet) return

              currentResults.organization.availabilitySets.push(availabilitySet)
              softReset(currentResults)
            },
          },
          {
            when: "availabilitySetUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { availabilitySet } = response.availabilitySetUpdate
              if (!availabilitySet) return

              const index =
                currentResults.organization.availabilitySets.findIndex(
                  set => set.id === availabilitySet.id
                )
              const existingSet =
                currentResults.organization.availabilitySets[index]

              if (availabilitySet.grades) {
                existingSet.grades = availabilitySet.grades
              }
              if (availabilitySet.name) {
                existingSet.name = availabilitySet.name
              }
              currentResults.organization.availabilitySets[index] = existingSet
              softReset(currentResults)
            },
          },
          {
            when: "availabilitySetRemove",
            run: ({ softReset, currentResults }, response) => {
              const { availabilitySet } = response.availabilitySetRemove
              if (!availabilitySet) return

              currentResults.organization.availabilitySets =
                currentResults.organization.availabilitySets.filter(
                  set => set.id !== availabilitySet.id
                )
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <NewAvailabilitySet organizationId={organizationId} />
          {ordered(data.organization.availabilitySets, "name").map(
            availabilitySet => (
              <CollapsibleContainer
                title={<h4 className="m-0">{availabilitySet.name}</h4>}
                key={availabilitySet.id}
              >
                <EditName
                  availabilitySetId={availabilitySet.id}
                  name={availabilitySet.name}
                />
                <RemoveAvailabilitySet availabilitySetId={availabilitySet.id} />
                <div className="grid md:grid-flow-col md:grid-cols-2">
                  <div>
                    <h5>Availabilities</h5>
                    <GenerateAvailabilities
                      availabilitySetId={availabilitySet.id}
                    />
                    <ul className="list-unstyled">
                      {ordered(
                        availabilitySet.availabilities,
                        "weekdayNumber"
                      ).map(availability => (
                        <Availability
                          key={availability.id}
                          organizationId={organizationId}
                          availability={availability}
                        />
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h5>Grades</h5>
                    <EditGrades
                      availabilitySetId={availabilitySet.id}
                      grades={availabilitySet.grades}
                    />
                    <ul className="list-unstyled">
                      {ordered(availabilitySet.grades, "number").map(grade => (
                        <li key={grade.id}>{grade.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </CollapsibleContainer>
            )
          )}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const availabilitiesQuery = compress`
  query($organizationId: ID!) {
    organization(id: $organizationId) {
      availabilitySets {
        id
        name
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
        grades {
          id
          name
          number
        }
      }
    }
  }
`

export default Availabilities
