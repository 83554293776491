import React, { useEffect, useState } from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import ProgramRegistration from "components/Family/ProgramRegistration"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import StudentSelection from "./StudentSelection"

const ParentProgramRegistration = ({ newStudentPath }) => {
  const [student, setStudent] = useState()
  const client = useClient()
  const [result] = useQuery({ query, client })

  useEffect(() => {
    if (!result.data) {
      return
    }

    if (result.data.students.length === 1) {
      setStudent(result.data.students[0])
    }
  }, [result.data])

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) =>
        student ? (
          <ProgramRegistration student={student} isParent />
        ) : (
          <StudentSelection
            students={data.students}
            setStudent={setStudent}
            newStudentPath={newStudentPath}
          />
        )
      }
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query {
    students {
      id
      firstName
      grade {
        id
        name
        tier {
          id
          gradeRange
        }
      }
    }
  }
`

export default ParentProgramRegistration
