import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import useAssessmentSectionOptions from "hooks/useAssessmentSectionOptions"

import {
  FileField,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  assessmentSectionId: Yup.string().required(
    "Please select an assessment section"
  ),
  file: Yup.mixed().test("file", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const AssessmentScoreBulkUploadForm = () => {
  const formState = {
    assessmentSectionId: "",
    file: {},
  }
  const { runMutation } = useMutation(buildMutation(createMutation))

  const assessmentSectionOptions = useAssessmentSectionOptions()

  const handleSubmit = (values, actions) => {
    const { file, ...rest } = values
    const reader = new FileReader()
    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      runMutation({
        file: b64,
        filename: values.file.name || values.file.fileName,
        ...rest,
      }).then(response => {
        const { success, redirectTo, errorMessage } =
          response.assessmentScoreBulkUpload
        if (success) {
          window.location = redirectTo
        } else {
          actions.setStatus(errorMessage)
        }
      })
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Bulk Upload"
      buttonText="New Upload"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <FileField name="file" label="CSV File" accept=".csv" />
              <SelectField
                name="assessmentSectionId"
                label="Assessment Section"
                options={assessmentSectionOptions}
                valueAttribute="id"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                menuPortalTarget={document.body}
              />
            </div>

            <div className="modal-footer flex-col">
              <FormStatus />
              <SubmitButton text="Upload" />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = compress`
  mutation(
    $file: String!,
    $filename: String!,
    $assessmentSectionId: ID!,
  ) {
    assessmentScoreBulkUpload(
      file: $file
      filename: $filename
      assessmentSectionId: $assessmentSectionId
    ) {
      success
      redirectTo
      errorMessage
      errors {
        field
        messages
      }
    }
  }
`

export default AssessmentScoreBulkUploadForm
