import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  feedback: Yup.string().required(),
})

const AdminListExpense = ({ expense }) => {
  const formState = { feedback: "" }
  const [, runApproveMutation] = useMutation(approveMutation)
  const [, runDenyMutation] = useMutation(denyMutation)
  const [, runRequestChangesMutation] = useMutation(requestChangesMutation)

  const approve = (values, actions) => {
    runApproveMutation({ id: expense.id })
      .then(
        response => {
          const { failures } = response.data.approveExpense
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const deny = (values, actions) => {
    runDenyMutation({ id: expense.id, feedback: values.feedback })
      .then(
        response => {
          const { failures } = response.data.denyExpense
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const requestChanges = (values, actions) => {
    runRequestChangesMutation({
      id: expense.id,
      feedback: values.feedback,
    })
      .then(
        response => {
          const { failures } = response.data.requestChangesExpense
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <>
      <div className="grid md:grid-cols-2">
        <div>
          <dl>
            <dt>Tutor</dt>
            <dd>
              <a href={expense.tutor.showPath}>{expense.tutor.fullName}</a>
            </dd>

            <dt>Description</dt>
            <dd>{expense.description}</dd>

            {expense.comments && (
              <>
                <dt>Tutor Comments</dt>
                <dd className="whitespace-pre-line">{expense.comments}</dd>
              </>
            )}

            {expense.feedback && (
              <>
                <dt>Admin Feedback</dt>
                <dd className="whitespace-pre-line">{expense.feedback}</dd>
              </>
            )}

            <dt>Type</dt>
            <dd>{expense.expenseType}</dd>

            <dt>Amount</dt>
            <dd>{expense.formattedAmount}</dd>

            {expense.approvedAt && (
              <>
                <dt>Approved</dt>
                <dd>
                  <LocalTime timestamp={expense.approvedAt} omitTime />
                </dd>
              </>
            )}

            {expense.deniedAt && (
              <>
                <dt>Denied</dt>
                <dd>
                  <LocalTime timestamp={expense.deniedAt} omitTime />
                </dd>
              </>
            )}

            {expense.paidAt && (
              <>
                <dt>Payment Submitted</dt>
                <dd>
                  <LocalTime timestamp={expense.paidAt} omitTime />
                </dd>
              </>
            )}

            <dt>Receipts</dt>
            <dd>
              <ul className="list-unstyled">
                {expense.receipts.map(receipt => (
                  <li key={receipt.id}>
                    <a href={receipt.downloadUrl}>{receipt.filename}</a>
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
        </div>

        <div>
          {expense.receipts.map(receipt => (
            <div key={receipt.id}>
              <a href={receipt.downloadUrl}>
                <img src={receipt.previewUrl} alt={receipt.filename} />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div>
        {expense.mayApprove && (
          <ModalWithProvidedBody
            modalTitle="Approve Tutor Expense"
            buttonText="Approve"
            buttonClassName="btn btn-success"
          >
            {({ closeModal }) => (
              <Formik onSubmit={approve} initialValues={formState}>
                <Form>
                  <div className="modal-body">
                    <p>
                      Are you sure? For non-fingerprint, non-intro session
                      expenses, this will issue the tutor money via Bill.com
                      immediately.
                    </p>
                  </div>

                  <div className="modal-footer flex-col">
                    <FormFailures />
                    <div className="flex w-full justify-between">
                      <SubmitButton text="Show them the money!" />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
          </ModalWithProvidedBody>
        )}

        {expense.mayDeny && (
          <ModalWithProvidedBody
            modalTitle="Deny Tutor Expense"
            buttonText="Deny"
            buttonClassName="btn btn-danger"
          >
            {({ closeModal }) => (
              <Formik
                initialValues={formState}
                onSubmit={deny}
                validationSchema={ValidationSchema}
              >
                <Form>
                  <div className="modal-body">
                    <p>
                      Are you sure? Please provide feedback below that will be
                      sent to the tutor.
                    </p>
                    <TextAreaField name="feedback" label="Feedback" />
                  </div>

                  <div className="modal-footer flex-col">
                    <FormFailures />
                    <div className="flex justify-between">
                      <SubmitButton text="Yes, deny the expense request" />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
          </ModalWithProvidedBody>
        )}

        {expense.mayRequestChanges && (
          <ModalWithProvidedBody
            modalTitle="Request More Information"
            buttonText="Request More Information"
            buttonClassName="btn btn-warning"
          >
            {({ closeModal }) => (
              <Formik
                initialValues={formState}
                onSubmit={requestChanges}
                validationSchema={ValidationSchema}
              >
                <Form>
                  <div className="modal-body">
                    <TextAreaField
                      name="feedback"
                      label="Please provide feedback to be provided to the tutor"
                    />
                  </div>

                  <div className="modal-footer flex-col">
                    <FormFailures />
                    <div className="flex justify-between">
                      <SubmitButton text="Request Feedback" />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
          </ModalWithProvidedBody>
        )}
      </div>
      <hr />
    </>
  )
}

const approveMutation = gql`
  mutation ($id: ID!) {
    approveExpense(id: $id) {
      failures {
        message
      }
    }
  }
`

const denyMutation = gql`
  mutation ($id: ID!, $feedback: String!) {
    denyExpense(id: $id, feedback: $feedback) {
      failures {
        message
      }
    }
  }
`

const requestChangesMutation = gql`
  mutation ($id: ID!, $feedback: String!) {
    requestChangesExpense(id: $id, feedback: $feedback) {
      failures {
        message
      }
    }
  }
`

export default AdminListExpense
