import React, { ReactNode, useState } from "react"

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import classNames from "src/classNames"

type CollapsibleContainerProps = {
  children: ReactNode
  title: ReactNode
  buttonClassName?: string
  childrenClassName?: string
  defaultCollapsed?: boolean
  defer?: boolean
}

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  children,
  title,
  buttonClassName = "btn btn-sm btn-link p-2 mr-1 my-0",
  childrenClassName = "ml-5",
  defaultCollapsed = true,
  defer = false,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center mb-3">
        <button
          onClick={() => setCollapsed(c => !c)}
          className={buttonClassName}
        >
          <FontAwesomeIcon
            icon={collapsed ? faChevronRight : faChevronDown}
            fixedWidth
          />
        </button>
        {title}
      </div>
      {(!collapsed || (collapsed && !defer)) && (
        <div className={classNames(childrenClassName, collapsed && "d-none")}>
          {children}
        </div>
      )}
    </div>
  )
}

export default CollapsibleContainer
