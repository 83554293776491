import React, { useRef } from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { motion } from "framer-motion"
import {
  buildMutation,
  buildQuery,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import Select from "react-select"
import styled from "styled-components"

import ordered from "src/ordered"

import { useGradeOptions } from "hooks/remoteTable"

import { ActionContainer } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Checkbox from "components/Forms/Checkbox"
import {
  ErrorMessage,
  SubmitButton,
  TimeZoneField,
} from "components/Forms/Formik"
import RemoveButton from "components/RemoveButton"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import loadReferralSources from "./loadReferralSources"
import saveStudentMutation from "./saveStudentMutation"

const Item = styled(motion.li)`
  display: flex;
  align-items: center;
`

const StudentForm = props => {
  const { newStudent, formState } = props
  const subjectSelect = useRef()
  const gradeOptions = useGradeOptions()
  const loadingState = useQuery(buildQuery(loadReferralSources))
  const { runMutation } = useMutation(buildMutation(saveStudentMutation))

  const handleSubmit = (values, actions) => {
    const {
      referralSource: referralSourceObject,
      grade,
      school,
      organization,
      parent,
      partner,
      subjects,
      tags,
      ...rest
    } = values
    const referralSource = referralSourceObject?.value
    const gradeId = grade?.value
    const schoolId = school?.value
    const organizationId = organization && organization.value
    const parentId = parent && parent.value
    const partnerId = partner && partner.value
    const subjectIds = subjects.map(s => s.id)
    const tagList = tags.map(t => t.label)

    runMutation({
      input: {
        referralSource,
        gradeId,
        schoolId,
        organizationId,
        parentId,
        partnerId,
        subjectIds,
        tagList,
        ...rest,
      },
    })
      .then(({ saveStudent }) => {
        actions.setSubmitting(false)
        if (saveStudent.success) {
          window.location = saveStudent.redirectTo
        } else {
          actions.setStatus(
            `Failed to save student. Please correct errors:
          ${saveStudent.errorMessage}`
          )
          saveStudent.errors.forEach(error => {
            actions.setFieldError(error.field, error.messages.join(", "))
          })
        }
      })
      .catch(err => {
        actions.setStatus(
          "An error occurred. Please refresh or try again after correcting any validation errors"
        )
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ values, status, isSubmitting, setFieldValue }) => (
            <Form>
              <h4 className="mt-0">Student</h4>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label w-100">
                      First Name
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                      />
                      <ErrorMessage name="firstName" />
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Last Name
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                      />
                      <ErrorMessage name="lastName" />
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Email
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage name="email" />
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Username
                      <Field
                        type="text"
                        name="username"
                        className="form-control"
                      />
                      <ErrorMessage name="username" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Phone Number
                      <Field
                        type="text"
                        name="phoneNumber"
                        className="form-control"
                      />
                      <ErrorMessage name="phoneNumber" />
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Grade
                      <Field type="text" name="grade" className="form-control">
                        {({ field: { name, value } }) => (
                          <Select
                            options={gradeOptions}
                            defaultValue={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            styles={{
                              container: provided => ({
                                ...provided,
                                maxWidth: 250,
                              }),
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="grade" />
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Time Zone
                      <TimeZoneField
                        name="emailTimeZone"
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage name="emailTimeZone" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Referral Source
                      <Field
                        type="text"
                        name="referralSource"
                        className="form-control"
                      >
                        {({ field: { name, value } }) => (
                          <Select
                            options={data.referralSources}
                            defaultValue={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            styles={{
                              container: provided => ({
                                ...provided,
                                maxWidth: 250,
                              }),
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="referralSource" />
                    </label>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Referred By
                      <Field
                        name="partner"
                        type="text"
                        className="form-control"
                      >
                        {({ field: { name, value } }) => (
                          <AutocompleteSelect
                            api="/api/admins/partners/autocomplete_full_name"
                            selected={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            placeholder="Search for partner"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="partner" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="form-label w-100">Tags</label>
                    <AutocompleteSelect
                      api="/api/admins/students/autocomplete_tags"
                      isMulti
                      creatable
                      value={values.tags}
                      onChange={selected => setFieldValue("tags", selected)}
                    />
                  </div>
                </div>
              </div>

              {newStudent && (
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="form-label w-100">
                        Password
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          autoComplete="new-password"
                        />
                        <ErrorMessage name="password" />
                        <div>
                          Leave blank to have the system auto-generate a
                          password
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="form-label w-100">
                        Password Confirmation
                        <Field
                          type="password"
                          name="passwordConfirmation"
                          className="form-control"
                        />
                        <ErrorMessage name="passwordConfirmation" />
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {newStudent && (
                <>
                  <h4>Subjects</h4>
                  <div className="row">
                    <div className="col-md-5">
                      <FieldArray name="subjects">
                        {arrayHelpers => (
                          <React.Fragment>
                            <ul className="list-unstyled">
                              {ordered(values.subjects, "name").map(subject => (
                                <Item key={subject.id} positionTransition>
                                  <ActionContainer>
                                    <RemoveButton
                                      onClick={() => {
                                        const index = values.subjects.findIndex(
                                          t => subject.id === t.id
                                        )
                                        arrayHelpers.remove(index)
                                      }}
                                    />
                                  </ActionContainer>
                                  {subject.name}
                                </Item>
                              ))}
                            </ul>
                            <AutocompleteSelect
                              api="/api/admins/subjects/autocomplete_name"
                              ref={subjectSelect}
                              onChange={selectedOption => {
                                if (!selectedOption?.value) return
                                const existingSubject = values.subjects.find(
                                  t => t.id === selectedOption.value
                                )
                                if (!existingSubject) {
                                  arrayHelpers.push({
                                    name: selectedOption.label,
                                    id: selectedOption.value,
                                  })
                                }
                                setTimeout(
                                  () => subjectSelect.current.clearValue(),
                                  0
                                )
                              }}
                            />
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <ErrorMessage name="subjects" />
                    </div>
                  </div>
                </>
              )}

              <h4>Link Existing Parent</h4>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      <Field name="parent" type="text" className="form-control">
                        {({ field: { name, value } }) => (
                          <AutocompleteSelect
                            api="/api/admins/parents/autocomplete_full_name"
                            selected={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            placeholder="Search for parent"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="parent" />
                    </label>
                  </div>
                </div>
              </div>

              <h4>Or Enter Parent Info</h4>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Name
                      <Field
                        type="text"
                        name="parentName"
                        className="form-control"
                        disabled={values.parent}
                      />
                      <ErrorMessage name="parentName" />
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Email
                      <Field
                        type="text"
                        name="parentEmail"
                        className="form-control"
                        disabled={values.parent}
                      />
                      <ErrorMessage name="parentEmail" />
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Phone Number
                      <Field
                        type="text"
                        name="parentPhoneNumber"
                        className="form-control"
                        disabled={values.parent}
                      />
                      <ErrorMessage name="parentPhoneNumber" />
                    </label>
                  </div>
                </div>
              </div>

              <h4>Other</h4>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      Notes
                      <Field name="notes">
                        {({ field, form }) => (
                          <textarea className="form-control" {...field} />
                        )}
                      </Field>
                      <ErrorMessage name="notes" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label w-100">
                      No Show Count
                      <Field
                        type="number"
                        name="noShowCount"
                        className="form-control"
                      />
                      <ErrorMessage name="noShowCount" />
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="form-label w-100">
                      Organization
                      <Field
                        name="organization"
                        type="text"
                        className="form-control"
                      >
                        {({ field: { name, value } }) => (
                          <AutocompleteSelect
                            api="/api/admins/organizations/autocomplete_name"
                            selected={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            placeholder="Search for organization"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="organization" />
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="form-label w-100">
                      School
                      <Field name="school" type="text" className="form-control">
                        {({ field: { name, value } }) => (
                          <AutocompleteSelect
                            api="/api/admins/schools/autocomplete_name"
                            searchOptions={`organization_id=${values.organization?.value}`}
                            selected={value}
                            onChange={selectedOption =>
                              setFieldValue(
                                name,
                                selectedOption ? selectedOption : null
                              )
                            }
                            placeholder="Search for school"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="school" />
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="form-label w-100">
                      Coupon Code
                      <Field
                        type="text"
                        name="couponCode"
                        className="form-control"
                      />
                      <ErrorMessage name="couponCode" />
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="form-label w-100">
                      Prepaid Hours
                      <Field
                        type="number"
                        step="0.5"
                        name="prepaidHours"
                        className="form-control"
                      />
                      <ErrorMessage name="prepaidHours" />
                    </label>
                  </div>

                  {newStudent && (
                    <div className="form-group">
                      <Field name="skipEmailConfirmation">
                        {({ field: { onChange, name, value } }) => (
                          <Checkbox
                            onChange={onChange}
                            name={name}
                            checked={value}
                            value={true}
                            classNames="d-inline-block mr-3"
                          >
                            Skip Email Confirmation
                          </Checkbox>
                        )}
                      </Field>
                      <ErrorMessage name="skipEmailConfirmation" />
                    </div>
                  )}

                  <div className="form-group">
                    <Field name="enableSessionEmails">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          Enable Parent Session Emails
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="enableSessionEmails" />
                  </div>

                  <div className="form-group">
                    <Field name="automatedTwilioSessionsEnabled">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          Auto Generate Twilio Numbers
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="automatedTwilioSessionsEnabled" />
                  </div>

                  <div className="form-group">
                    <Field name="smsNotificationsEnabled">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          SMS Notifications Enabled
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="smsNotificationsEnabled" />
                  </div>

                  <div className="form-group">
                    <Field name="hasActAccess">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          ACT Access
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="hasActAccess" />
                  </div>

                  <div className="form-group">
                    <Field name="hasSatAccess">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          SAT Access
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="hasSatAccess" />
                  </div>

                  <div className="form-group">
                    <Field name="hasIseeAccess">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          onChange={onChange}
                          name={name}
                          checked={value}
                          value={true}
                          classNames="d-inline-block mr-3"
                        >
                          ISEE Access
                        </Checkbox>
                      )}
                    </Field>
                    <ErrorMessage name="hasIseeAccess" />
                  </div>
                </div>
              </div>

              {status && (
                <div>
                  <div className="alert alert-danger d-inline-block">
                    {status}
                  </div>
                </div>
              )}

              <SubmitButton isSubmitting={isSubmitting} text="Save Student" />
            </Form>
          )}
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

export default StudentForm
