import React, { useRef } from "react"

import moment from "moment-timezone"
import ReactTable from "react-table"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"
import Modal from "components/Modal"

import Edit from "./Edit"

import "react-table/react-table.css"

const TestimonialsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/testimonials",
    defaultFilters: props.defaultFilters,
  })
  const table = useRef(null)
  const { exportToPDF, exportToCSV } = useExportable({
    apiEndpoint: "/api/admins/testimonials",
    tableRef: table.current,
  })

  let columns = [
    {
      id: "user",
      Header: "Name",
      accessor: "user",
      Cell: props =>
        props.original.user ? (
          <a href={props.original.user.show_path}>
            {props.original.user.full_name}
          </a>
        ) : (
          props.original.phone_number
        ),
      Filter: ({ filter, onChange }) => (
        <AutocompleteSelect
          onChange={onChange}
          api="/api/admins/testimonials/autocomplete_user"
        />
      ),
    },
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization",
      Cell: props =>
        props.original.organization ? (
          <a href={props.value.show_path}>{props.value.name}</a>
        ) : (
          "Unknown"
        ),
      Filter: ({ filter, onChange }) => (
        <AutocompleteSelect
          onChange={onChange}
          api="/api/admins/organizations/autocomplete_name"
        />
      ),
    },
    {
      id: "testimonial_created_at",
      Header: "Date",
      accessor: "created_at",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => {
        return (
          <>
            <DateRangePicker
              onChange={([startDate, endDate]) => {
                if (!startDate && !endDate) {
                  onChange({ startsAt: null, endsAt: null })
                  return
                }

                const startMoment = moment(startDate.toDate()).startOf("day")
                const endMoment = moment(
                  endDate?.toDate() || startMoment
                ).endOf("day")
                onChange({
                  startsAt: startMoment.format(),
                  endsAt: endMoment.format(),
                })
              }}
            />
          </>
        )
      },
    },
    {
      id: "message",
      Header: "Message",
      accessor: "message",
      filterable: false,
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: "actions",
      filterable: false,
      Cell: props => (
        <>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure? This will remove the specified testimonial permanently.</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove it!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep it for now"
            cancelClassName="btn btn-info"
          />
          <Edit row={props.original} />
        </>
      ),
    },
  ]

  return (
    <>
      <div className="row">
        <div className="col">
          <button onClick={exportToCSV} className="btn btn-success">
            Export to CSV
          </button>
          <button
            onClick={exportToPDF}
            className="btn btn-success"
            style={{ marginRight: 15, marginLeft: 15 }}
          >
            Export to PDF
          </button>
        </div>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default TestimonialsTable
