import React, { useRef, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import studyGroupFragment from "./studyGroupFragment"

const RegisterStudent = ({ course, courseSection }) => {
  const select = useRef()
  const [errors, setErrors] = useState()
  const { runMutation } = useMutation(buildMutation(registerStudentMutation))

  const register = selected => {
    if (!selected) return

    runMutation({
      courseSectionId: courseSection.id,
      studentId: selected.value,
    })
      .then(
        response => {
          const { errorMessages } = response.courseSectionRegisterStudent
          if (errorMessages) {
            setErrors(errorMessages)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
      .finally(() => select.current?.clearValue())
  }

  return (
    <>
      <AutocompleteSelect
        api="/api/admins/students/autocomplete_full_name"
        searchOptions={
          course.organization
            ? `organization_id=${course.organization.id}`
            : null
        }
        placeholder="Register Student"
        onChange={register}
        ref={select}
        withLoadingIndicator
      />
      {errors && (
        <div className="alert alert-danger d-flex align-items-center justify-content-between mt-3">
          {errors}
          <button
            onClick={() => setErrors()}
            className="btn text-dark m-0 bg-white"
          >
            Dismiss
          </button>
        </div>
      )}
    </>
  )
}

const registerStudentMutation = compress`
  ${studyGroupFragment}
  mutation($courseSectionId: ID!, $studentId: ID!) {
    courseSectionRegisterStudent(courseSectionId: $courseSectionId, studentId: $studentId) {
      errorMessages
      courseSection {
        id
        assignedStudyGroups {
          currentPage
          totalPages
          data {
            ...StudyGroupFields
          }
        }
        unassignedStudyGroups {
          currentPage
          totalPages
          data {
            ...StudyGroupFields
          }
        }
      }
    }
  }
`

export default RegisterStudent
