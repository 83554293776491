import React from "react"

import {
  RadioField,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"

const BackgroundChecks = () => {
  return (
    <>
      <h3>Background Checks</h3>
      <RadioField
        name="canCompleteFingerprint"
        label="As part of our onboarding process, our tutors complete physical fingerprint and background checks at locations within the United States of America. Will you be able to complete this process quickly (within 2 weeks) if offered a contract?"
        boolean
      />

      <TextAreaField
        name="backgroundCheckNotes"
        label="Are there any records you anticipate showing up on a background check? Leave blank if not, if yes please explain. (please note this is not necessarily an automatic exclusion)"
        maxLength={500}
      />
    </>
  )
}

export default BackgroundChecks
