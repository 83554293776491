import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import { parentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ActiveCourses = () => {
  const loadingState = useQuery(buildQuery(activeCoursesQuery, {}, { client }))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) =>
        data.students.map(student => (
          <React.Fragment key={student.id}>
            {student.activeCourseSections.length ? (
              <div>
                <h1 className="text-center">
                  Active Programs for {student.firstName}
                </h1>
                {ordered(student.activeCourseSections, "startsOn").map(
                  courseSection => (
                    <div key={courseSection.id}>
                      <h2
                        css={css`
                          font-weight: 500;
                        `}
                      >
                        {courseSection.courseCluster.name}
                      </h2>
                      <dl>
                        <dt>Dates</dt>
                        <dd>
                          <div>
                            <LocalTime
                              timestamp={courseSection.startsOn}
                              omitTime
                            />{" "}
                            &mdash;{" "}
                            <LocalTime
                              timestamp={courseSection.endsOn}
                              omitTime
                            />
                          </div>
                          <div>
                            {courseSection.availabilities.map(availability => (
                              <div key={availability.id}>
                                <div
                                  css={css`
                                    font-style: italic;
                                  `}
                                >
                                  {availability.weekday}s{" "}
                                  <LocalTime
                                    timestamp={availability.startsAt}
                                    omitDate
                                  />{" "}
                                  to{" "}
                                  <LocalTime
                                    timestamp={availability.endsAt}
                                    omitDate
                                  />{" "}
                                  ({availability.duration})
                                </div>
                              </div>
                            ))}
                          </div>
                        </dd>

                        <dt>Description</dt>
                        <dd>{courseSection.courseCluster.description}</dd>
                      </dl>
                      <hr />
                    </div>
                  )
                )}
              </div>
            ) : null}
          </React.Fragment>
        ))
      }
    </WithLoadingIndicator>
  )
}

const activeCoursesQuery = compress`
  query {
    students {
      id
      firstName
      activeCourseSections {
        id
        startsOn
        endsOn
        courseCluster {
          name
          description
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
          duration
        }
      }
    }
  }
`

export default ActiveCourses
