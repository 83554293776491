import { BuildURL } from "src/FetchTableData"

const useExportable = ({
  apiEndpoint,
  tableRef,
  defaultFilters,
  additionalProps = {},
}) => {
  const exportToPDF = () => {
    doExport("pdf")
  }

  const exportToCSV = () => {
    doExport("csv")
  }

  const doExport = type => {
    const state = { ...tableRef.state }
    state.page = 0
    state.pageSize = -1
    window.open(
      BuildURL(apiEndpoint, state, defaultFilters, {
        format: type,
        ...additionalProps,
      })
    )
  }

  return { exportToPDF, exportToCSV }
}

export default useExportable
