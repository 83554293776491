import React from "react"

import { gql, useMutation } from "urql"

import { Box, Button, Flex } from "@chakra-ui/core"

const NoShowNotification = ({ noShowNotification }) => {
  const [, dismissNotification] = useMutation(dismissNotificationMutation)

  return (
    <Flex justify="space-between" align="center" mb="4">
      <Box>
        {noShowNotification.student.fullName} has missed a total of{" "}
        {noShowNotification.student.noShowCount} sessions this year
      </Box>
      <Button
        variant="outline"
        variantColor="red"
        ml="4"
        onClick={() => dismissNotification({ id: noShowNotification.id })}
      >
        Dismiss
      </Button>
    </Flex>
  )
}

const dismissNotificationMutation = gql`
  mutation DismissNoShowNotification($id: ID!) {
    dismissNoShowNotification(noShowNotificationId: $id) {
      noShowNotification {
        id
      }
    }
  }
`

export default NoShowNotification
