import { compress } from "micro-graphql-react"

export default compress`
  query($tailoredAssignmentId: ID!, $studentId: ID!) {
    tailoredAssignment(id: $tailoredAssignmentId) {
      name
    }
    tailoredSectionSummaries(tailoredAssignmentId: $tailoredAssignmentId, studentId: $studentId) {
      correctCount
      incorrectCount
      skippedCount
      score
      reviewPath
      tailoredSection {
        id
        name
      }
    }
  }
`
