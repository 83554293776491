import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"

import { useGradeOptions } from "hooks/remoteTable"
import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ClearParentInfoForm = ({ organizationId }) => {
  const formState = {
    organizationId,
    schoolIds: [],
    gradeId: "",
  }
  const gradeOptions = useGradeOptions()
  const [, clearParentInfo] = useMutation(clearParentInfoMutation)

  const handleSubmit = (values, actions, closeModal) => {
    clearParentInfo(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures } = result.data.clearParentInfo
          if (failures) {
            handleFailure(actions, failures)
          } else {
            closeModal()
            NotificationManager.info("Parent info will be cleared momentarily")
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <>
      <ModalWithProvidedBody
        modalTitle="Clear Parent Info"
        buttonText="Clear Parent Info"
        buttonClassName="btn btn-danger"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <SelectField
                  name="gradeId"
                  label="Grade"
                  options={gradeOptions}
                />

                <AutocompleteField
                  api="/api/admins/schools/autocomplete_name"
                  searchOptions={`organization_id=${organizationId}`}
                  name="schoolIds"
                  label="Schools"
                  isMulti
                />
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton text="Clear Parent Info (THIS IS NOT REVERSIBLE!)" />
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const clearParentInfoMutation = gql`
  mutation ($organizationId: ID!, $schoolIds: [ID!], $gradeId: ID) {
    clearParentInfo(
      organizationId: $organizationId
      schoolIds: $schoolIds
      gradeId: $gradeId
    ) {
      failures {
        message
      }
    }
  }
`

export default ClearParentInfoForm
