import React from "react"

export type FieldErrorProps = {
  message: string
}

const FieldError: React.FC<FieldErrorProps> = ({ message }) => {
  return (
    <div className="flex h-full items-center">
      <p className="text-sm text-red-600">{message}</p>
    </div>
  )
}

export default FieldError
