import React from "react"

import { gql, useQuery } from "hooks/urql"

import LocalTime from "components/LocalTime"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type ImportStatusProps = {
  organizationId: number
  type: "clever" | "classlink"
}

const ImportStatus: React.FC<ImportStatusProps> = ({
  organizationId,
  type,
}) => {
  const [result] = useQuery({
    query: importStatusQuery,
    variables: { organizationId },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          {data.organization[`${type}ImportErrorMessage`] ? (
            <div className="alert alert-danger">
              <h4 className="mt-0 text-center">Import Error</h4>
              There was an error while performing the last import:
              <pre>{data.organization[`${type}ImportErrorMessage`]}</pre>
              Please reach out to your friendly neighborhood developer for help
              with resolving the error.
            </div>
          ) : (
            (data.organization[`${type}ImportStartedAt`] ||
              data.organization[`${type}ImportCompletedAt`]) && (
              <div className="alert alert-info">
                {data.organization[`${type}ImportStartedAt`] && (
                  <>
                    <h4 className="mt-0 text-center">
                      {type.toUpperCase()} IMPORT IN PROGRESS
                    </h4>
                    <div className="text-lg">
                      Import started:{" "}
                      <LocalTime
                        timestamp={data.organization[`${type}ImportStartedAt`]}
                      />
                    </div>
                  </>
                )}
                {data.organization[`${type}ImportCompletedAt`] && (
                  <div className="text-lg">
                    Last import completed:{" "}
                    <LocalTime
                      timestamp={data.organization[`${type}ImportCompletedAt`]}
                    />
                  </div>
                )}
              </div>
            )
          )}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const importStatusQuery = gql`
  query ($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      cleverImportStartedAt
      cleverImportCompletedAt
      cleverImportErrorMessage
      classlinkImportStartedAt
      classlinkImportCompletedAt
      classlinkImportErrorMessage
    }
  }
`

export default ImportStatus
