import React, { useState } from "react"

import DatePicker from "react-multi-date-picker"
import Toolbar from "react-multi-date-picker/plugins/toolbar"

import classNames from "src/classNames"

import CloseButton from "./Plugins/CloseButton"

const DateRangePicker = ({
  value,
  onChange,
  afterChange,
  fixed,
  inputClassNames,
  clear = true,
  ...props
}) => {
  const [datePickerClosed, setDatePickerClosed] = useState(true)
  const datePicker = React.useRef()

  const containerStyle = { display: "block" }
  if (fixed) {
    containerStyle.position = "fixed"
    containerStyle.zIndex = 100
  }

  const plugins = []
  if (clear) {
    plugins.push(
      <Toolbar
        position="bottom"
        sort={["deselect"]}
        names={{ deselect: "Clear" }}
      />
    )
  }
  plugins.push(
    <CloseButton position="bottom" setClosed={setDatePickerClosed} />
  )

  return (
    <DatePicker
      onOpen={() => setDatePickerClosed(false)}
      onClose={() => datePickerClosed}
      value={value}
      ref={datePicker}
      onChange={params => {
        onChange(params)
        if (params.length === 2 || params.length === 0) {
          setDatePickerClosed(true)
          setTimeout(() => datePicker.current.closeCalendar(), 0)
        }
        typeof afterChange === "function" && afterChange()
      }}
      containerStyle={containerStyle}
      inputClass={classNames("form-control", inputClassNames)}
      editable={false}
      range
      plugins={plugins}
      {...props}
    />
  )
}

export default DateRangePicker
