import React from "react"

import { Form, Formik } from "formik"

import {
  FormStatus,
  SubmitButton,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const AddPhoneNumbers = ({ addRecipients }) => {
  const formState = {
    phoneNumbers: "",
  }
  const handleSubmit = (values, actions, closeModal) => {
    addRecipients(
      values.phoneNumbers
        .split(/[,\n]/)
        .map(s => s.trim())
        .filter(s => s !== "")
    )
    closeModal()
  }
  return (
    <ModalWithProvidedBody
      modalTitle="Add Phone Numbers"
      buttonText="Add Phone Numbers"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <TextAreaField
                name="phoneNumbers"
                label="Phone Numbers (separate by commas or 1-per line)"
              />
            </div>
            <div className="modal-footer flex-column">
              <FormStatus />
              <SubmitButton text="Add Recipients" />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

export default AddPhoneNumbers
