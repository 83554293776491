import React from "react"

import { useFormikContext } from "formik"

import Spinner from "~tailwindui/shared/Spinner"

import { Loading } from "components/Icons"

const SubmitButton = ({
  text = "Save",
  submitting = undefined,
  className = undefined,
  tailwindui = false,
}) => {
  let { isSubmitting } = useFormikContext()
  if (submitting !== undefined) isSubmitting = submitting

  if (!className) {
    className = tailwindui
      ? "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      : "btn btn-info solid blue"
  }

  return (
    <div>
      <button type="submit" className={className} disabled={isSubmitting}>
        <span>{text}</span>
        {isSubmitting && (
          <div className="ml-2 inline-block">
            {tailwindui ? (
              <Spinner size="small" className="mt-0.5 fill-white" />
            ) : (
              <Loading />
            )}
          </div>
        )}
      </button>
    </div>
  )
}

export default SubmitButton
