import { compress } from "micro-graphql-react"

const studyGroupFields = compress`
  fragment StudyGroupFields on StudyGroup {
    id
    name
    showPath
    availabilities {
      id
      startsAt
      endsAt
      weekday
      weekdayNumber
    }
    tutor {
      id
      fullName
      lastName
      showPath
    }
    teachers {
      id
      fullName
      lastName
      showPath
    }
    students {
      id
      fullName
      lastName
      showPath
      studentAvailabilities {
        id
        consumed
        availability {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
      }
      assessmentScores {
        id
        score
        assessmentSection {
          id
          name
        }
      }
    }
  }
`

const teacherSubjectFields = compress`
  fragment TeacherSubjectFields on TeacherSubject {
    id
    teacher {
      id
      fullName
      lastName
    }
    subject {
      id
      name
    }
    unassignedStudents {
      id
      fullName
      lastName
      showPath
      studentAvailabilities {
        id
        consumed
        availability {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
      }
      assessmentScores {
        id
        score
        assessmentSection {
          id
          name
        }
      }
    }
  }
`

export { studyGroupFields, teacherSubjectFields }
