import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { courseTypeOptions } from "src/enums"

import { useGradeOptions, useSubjectOptions } from "hooks/remoteTable"
import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  CheckboxField,
  Field,
  FormFailures,
  RadioField,
  SelectField,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  organizationId: Yup.mixed()
    .when("publicAccess", {
      is: false,
      then: Yup.number().required(
        "Clusters must either be public or have an organization set"
      ),
    })
    .when("publicAccess", {
      is: true,
      then: Yup.string().oneOf(
        [""],
        "Cannot set organization for public clusters"
      ),
    }),
  schoolId: Yup.mixed().when("publicAccess", {
    is: true,
    then: Yup.string().oneOf([""], "Cannot set school for public clusters"),
  }),
  subjectId: Yup.string().required("Subject is required"),
  gradeIds: Yup.array()
    .of(Yup.string())
    .required("Grades are required")
    .min(1, "Must select at least 1 grade"),
  courseType: Yup.string()
    .required("Course type is required")
    .oneOf(["Academic", "Enrichment"], "Specified course type is invalid"),
  splitBill: Yup.boolean().when("prepaid", {
    is: true,
    then: Yup.boolean().oneOf(
      [false],
      "Clusters cannot be both split bill and prepaid"
    ),
  }),
  allowLongSessions: Yup.mixed().when("publicAccess", {
    is: true,
    then: Yup.mixed().required(
      "Cannot use organization default for public clusters"
    ),
  }),
})

const NewCourseCluster = () => {
  const formState = {
    name: "",
    description: "",
    organizationId: "",
    schoolId: "",
    subjectId: "",
    gradeIds: [],
    courseType: "",
    splitBill: false,
    immovable: true,
    prepaid: false,
    publicAccess: false,
    excusedAbsencesEnabled: false,
    requestStudyGroupConfirmations: false,
    canExceedScheduledDuration: false,
    inSchool: true,
    allowLongSessions: undefined,
  }

  const subjectOptions = useSubjectOptions()
  const gradeOptions = useGradeOptions()
  const [, createCourseCluster] = useMutation(createMutation)

  const handleSubmit = (values, actions) => {
    const { publicAccess, ...params } = values

    if (params.allowLongSessions !== undefined)
      params.allowLongSessions = params.allowLongSessions === "true"

    createCourseCluster(params)
      .then(
        result => {
          if (result.error) {
            handleFailure(result.error)
            return
          }

          const { redirectTo, failures } = result.data.createCourseCluster
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            window.location = redirectTo
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonText="New Course Cluster"
      buttonClassName="btn btn-success"
      modalTitle="New Course Cluster"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          initialValues={formState}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className="modal-body">
                <Field name="name" label="Name" autoFocus />
                <TextAreaField name="description" label="Description" />
                <CheckboxField name="publicAccess" label="Public" />
                <AutocompleteField
                  name="organizationId"
                  label="Organization"
                  api={"/api/admins/organizations/autocomplete_name"}
                />
                <AutocompleteField
                  label="School"
                  name="schoolId"
                  api={`/api/admins/schools/autocomplete_name`}
                  searchOptions={
                    values.organizationId
                      ? `organization_id=${values.organizationId}`
                      : null
                  }
                />
                <SelectField
                  name="subjectId"
                  options={subjectOptions}
                  label="Subject"
                />
                <SelectField
                  name="gradeIds"
                  options={gradeOptions}
                  label="Grade"
                  isMulti
                />
                <SelectField
                  name="courseType"
                  options={courseTypeOptions}
                  label="Course Type"
                />
                <CheckboxField name="prepaid" label="Prepaid" />
                <CheckboxField name="splitBill" label="Split Bill" />
                <CheckboxField
                  name="immovable"
                  label="Non-Cancelable, Non-Reschedulable"
                />
                <CheckboxField
                  name="excusedAbsencesEnabled"
                  label="Enable Excused Absences"
                />
                <CheckboxField
                  name="requestStudyGroupConfirmations"
                  label="Request Student Study Group Confirmations"
                />
                <CheckboxField
                  name="canExceedScheduledDuration"
                  label="Tutors can bill for longer than scheduled duration"
                />
                <CheckboxField name="inSchool" label="In-School Program" />

                <label className="form-label w-100">
                  Allow Sessions Longer Than 1 Hour?
                </label>
                <div className="ml-3">
                  <RadioField
                    name="allowLongSessions"
                    options={[
                      {
                        label: "Use Organization Default (Recommended)",
                        value: undefined,
                      },
                      { label: "Always Allow", value: true },
                      { label: "Never Allow", value: false },
                    ]}
                  />
                </div>
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton text="Create Cluster" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = gql`
  mutation (
    $name: String!
    $organizationId: ID
    $schoolId: ID
    $subjectId: ID!
    $gradeIds: [ID!]!
    $courseType: CourseTypeEnum!
    $splitBill: Boolean!
    $immovable: Boolean!
    $excusedAbsencesEnabled: Boolean!
    $requestStudyGroupConfirmations: Boolean!
    $canExceedScheduledDuration: Boolean!
    $inSchool: Boolean!
    $allowLongSessions: Boolean
    $prepaid: Boolean!
    $description: String
  ) {
    createCourseCluster(
      name: $name
      organizationId: $organizationId
      schoolId: $schoolId
      subjectId: $subjectId
      gradeIds: $gradeIds
      courseType: $courseType
      splitBill: $splitBill
      immovable: $immovable
      prepaid: $prepaid
      excusedAbsencesEnabled: $excusedAbsencesEnabled
      requestStudyGroupConfirmations: $requestStudyGroupConfirmations
      canExceedScheduledDuration: $canExceedScheduledDuration
      inSchool: $inSchool
      allowLongSessions: $allowLongSessions
      description: $description
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default NewCourseCluster
