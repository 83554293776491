import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import {
  CheckboxField,
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const UpdateNoShow = ({ airtutorsSessionId, noShow }) => {
  const formState = {
    airtutorsSessionId,
    noShow,
  }

  const [warning, setWarning] = useState()
  const { runMutation } = useMutation(buildMutation(updateNoShowMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages, sessionNeedsBilling } =
            response.airtutorsSessionUpdateNoShow
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else if (sessionNeedsBilling) {
            setWarning(
              "Session was auto-billed and has now been unbilled. Reach out to tutor to have them bill this session."
            )
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Update No Show"
      buttonText="Update No Show"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          <Form>
            <div className="modal-body">
              <CheckboxField name="noShow" label="No Show?" />
            </div>
            <div className="modal-footer flex-column">
              <FormStatus />
              {warning && (
                <div className="alert alert-warning text-black">{warning}</div>
              )}
              <div className="d-flex justify-content-between w-full">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Update" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateNoShowMutation = compress`
  mutation($airtutorsSessionId: ID!, $noShow: Boolean!) {
    airtutorsSessionUpdateNoShow(airtutorsSessionId: $airtutorsSessionId, noShow: $noShow) {
      errorMessages
      sessionNeedsBilling
      airtutorsSession {
        id
        noShow
      }
    }
  }
`

export default UpdateNoShow
