import React from "react"

import { css } from "@emotion/core"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

const SearchResults = ({ students }) => {
  return (
    <div
      css={css`
        max-width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
      `}
    >
      <table className="table">
        <thead className="text-primary">
          <tr>
            <td>Student Name</td>
            <td>Account Created</td>
            <td>Student Email</td>
            <td>Student Phone</td>
            <td>Parent Name</td>
            <td>Parent Email</td>
            <td>Parent Phone</td>
            <td>Private Bill?</td>
            <td>Organization</td>
            <td>School</td>
            <td>Grade</td>
            <td>Subjects</td>
            <td>Study Groups</td>
          </tr>
        </thead>

        <tbody>
          {ordered(students, "lastName").map(student => (
            <tr key={student.id}>
              <td>{student.fullName}</td>
              <td>
                <LocalTime timestamp={student.createdAt} omitTime />
              </td>
              <td>{student.email}</td>
              <td>{student.phoneNumber}</td>
              <td>{student.parentName}</td>
              <td>{student.parentEmail}</td>
              <td>{student.parentPhoneNumber}</td>
              <td>
                {student.hasPrivateBillingMethod ? (
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                ) : (
                  <FontAwesomeIcon icon={faTimes} className="text-danger" />
                )}
              </td>
              <td>{student.organization?.name}</td>
              <td>{student.school?.name}</td>
              <td>{student.grade?.name}</td>
              <td>
                <ul className="list-unstyled">
                  {ordered(student.subjects, "name").map(subject => (
                    <li key={subject.id}>{subject.name}</li>
                  ))}
                </ul>
              </td>
              <td>
                <ul className="list-unstyled">
                  {ordered(student.studyGroups, "name").map(studyGroup => (
                    <li key={studyGroup.id}>{studyGroup.name}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SearchResults
