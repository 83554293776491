import React from "react"

import { Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import { handleFailure } from "components/Forms/Formik/hookComponents"

import StatementConfigurationForm from "./Form"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  budgetId: Yup.string().required("Budget is required"),
})

const EditStatementConfiguration = ({
  statementConfiguration,
  stopEditing,
}) => {
  const initialState = {
    name: statementConfiguration.name,
    organizationId: statementConfiguration.organization.id,
    budgetId: statementConfiguration.budget.id,
    miscellaneousContent: statementConfiguration.miscellaneousContent,
    includeAllGroups: statementConfiguration.includeAllGroups,
    schoolIds: statementConfiguration.schools.map(school => school.id),
    includeSchoollessGroups: statementConfiguration.includeSchoollessGroups,
    filterByTags: statementConfiguration.studyGroupTags.length !== 0,
    studyGroupTags: statementConfiguration.studyGroupTags,
    useAutogeneratedBreakdowns:
      statementConfiguration.autogeneratesBreakdownsByCategory !== "",
    autogeneratesBreakdownsByCategory:
      statementConfiguration.autogeneratesBreakdownsByCategory,
    subjectGroupingsAttributes: statementConfiguration.subjectGroupings,
  }

  const [_, updateStatementConfiguration] = useMutation(
    updateStatementConfigurationMutation
  )

  const handleSubmit = (values, actions) => {
    const input = { ...values }
    delete input.filterByTags

    if (input.useAutogeneratedBreakdowns) {
      input.subjectGroupingsAttributes = input.subjectGroupingsAttributes.map(
        grouping => ({
          id: grouping.id,
          label: grouping.label,
          subjectIds: grouping.subjects.map(sub => sub.id),
        })
      )
    } else {
      delete input.autogeneratesBreakdownsByCategory
      delete input.subjectGroupingsAttributes
    }
    delete input.useAutogeneratedBreakdowns

    updateStatementConfiguration({
      id: statementConfiguration.id,
      input,
    })
      .then(
        result => {
          const { results } = result.data.updateStatementConfiguration
          if (results) {
            handleFailure(actions, results)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const handleCancel = setValues => {
    stopEditing()
    setValues(initialState)
  }

  return (
    <Formik
      initialValues={initialState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ setValues }) => (
        <>
          <StatementConfigurationForm
            formType="edit"
            statementConfiguration={statementConfiguration}
            organization={statementConfiguration.organization}
          />
          <button
            type="button"
            className="btn"
            onClick={() => handleCancel(setValues)}
          >
            Cancel
          </button>
        </>
      )}
    </Formik>
  )
}

const updateStatementConfigurationMutation = gql`
  mutation ($id: ID!, $input: StatementConfigurationInputObject!) {
    updateStatementConfiguration(id: $id, input: $input) {
      results {
        message
      }
    }
  }
`

export default EditStatementConfiguration
