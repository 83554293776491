import React from "react"

import Button from "./Button"

const RemoteAudioButton = props => {
  const { userId, muted, channel } = props

  const mute = () => {
    channel.perform("mute_user", { userId: userId, type: "audio" })
  }

  const unmute = () => {
    channel.perform("unmute_user", {
      userId: userId,
      type: "audio",
    })
  }

  const toggle = () => {
    muted ? unmute() : mute()
  }

  if (!channel) return null

  return (
    <Button
      onClick={toggle}
      muted={muted}
      styles={`
        margin-left: 15px;
      `}
    />
  )
}

export default RemoteAudioButton
