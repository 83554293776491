import React from "react"

import styles from "./styles.module.scss"

class RemoteStream extends React.Component {
  constructor(props) {
    super(props)
    this.state = { trackAttached: false }
  }

  componentDidMount = () => {
    if (this.props.track) {
      if (this.props.track.kind === "audio" && this.props.qualityControlMuted)
        return
      this.attachTrack()
    }
  }

  componentDidUpdate = () => {
    if (this.props.track?.kind === "audio" && this.props.qualityControlMuted) {
      if (this.state.trackAttached) {
        this.props.track.detach()
        this.setState({ trackAttached: false })
      }
      return
    }

    if (this.props.track && !this.state.trackAttached) {
      this.attachTrack()
    }

    const videoTag = this.refs.container.querySelector("video")
    if (videoTag) {
      if (
        this.props.track &&
        this.props.track.kind === "video" &&
        !this.props.track.isEnabled
      ) {
        videoTag.style.visibility = "hidden"
      } else {
        videoTag.style.visibility = "visible"
      }
    }
  }

  attachTrack = () => {
    this.refs.container.appendChild(this.props.track.attach())
    this.setState({ trackAttached: true })
  }

  render() {
    return (
      <div className={styles.container} ref="container">
        {this.props.children}
      </div>
    )
  }
}

export default RemoteStream
