import React from "react"

import { Button, useTheme } from "@chakra-ui/core"

import RealtimeboardButton from "components/Pages/RealtimeboardButton"
import Cloudroom from "components/TwilioCloudRoom/Tutor"

export type TeacherTwilioCloudroomProps = {
  viewer: {
    id: string
    ssoEmail: string
    hasRealtimeboardLicense: boolean
  }
  airtutorsSession: {
    id: string
    startsAt: string
    endsAt: string
    accessToken: string
    allVideoMuted: boolean
    allAudioMuted: boolean
    allControlsLocked: boolean
    controlsLockedUserIds: string[]
    studentScreenShareDisabled: boolean
    googledocUrl: string
    realtimeboards: {
      id: string
      url: string
    }
  }
}

const TeacherTwilioCloudroom: React.FC<TeacherTwilioCloudroomProps> = ({
  airtutorsSession,
  viewer,
}) => {
  const theme: Record<string, any> = useTheme()

  return (
    <Cloudroom
      id={airtutorsSession.id}
      startsAt={airtutorsSession.startsAt}
      endsAt={airtutorsSession.endsAt}
      api="teachers"
      currentUserId={viewer.id}
      accessToken={airtutorsSession.accessToken}
      audioMuted={airtutorsSession.allAudioMuted}
      videoMuted={airtutorsSession.allVideoMuted}
      controlsLocked={airtutorsSession.allControlsLocked}
      controlsLockedUserIds={airtutorsSession.controlsLockedUserIds}
      studentScreenShareDisabled={airtutorsSession.studentScreenShareDisabled}
      googledocComponent={
        airtutorsSession.googledocUrl && (
          <Button
            as="a"
            target="_blank"
            href={airtutorsSession.googledocUrl}
            size="sm"
            mb="2"
            css="flex"
            {...theme.buttonVariants.realtimeboard}
          >
            Google Doc
          </Button>
        )
      }
      realtimeboardComponent={
        airtutorsSession.realtimeboards[0] && (
          <RealtimeboardButton
            id={airtutorsSession.realtimeboards[0].id}
            url={airtutorsSession.realtimeboards[0].url}
            startsAt={airtutorsSession.startsAt}
            viewer={viewer}
            ssoEnabled={viewer.hasRealtimeboardLicense}
            ssoEmail={viewer.ssoEmail}
            css="flex w-full"
            displayName={airtutorsSession.realtimeboards[0].displayName}
          />
        )
      }
    />
  )
}

export default TeacherTwilioCloudroom
