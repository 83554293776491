import axios from "axios"
import { camelcase } from "camelcase-input"

const config = {
  transformResponse: [
    response => {
      try {
        return camelcase(JSON.parse(response), { deep: true })
      } catch {
        return camelcase(JSON.parse(response))
      }
    },
  ],
}

const customAxios = axios.create(config)

// TODO: optionally return an axios instance with a response interceptor to auto-extract data:
// customAxios.interceptors.response.use(r => r.data)
export default customAxios
