import React from "react"

import classNames from "src/classNames"

export type GoogleDocButtonProps = {
  googledocUrl: string
  userType: "Student" | "Tutor" | "Teacher" | "Admin"
}

const GoogleDocButton: React.FC<GoogleDocButtonProps> = ({
  googledocUrl,
  userType,
}) => {
  const buttonClassNames = classNames(
    "btn solid green mb-1",
    userType === "Admin" && "btn-success",
    userType === "Teacher" && "bg-success-700 px-3 py-1 rounded font-semibold"
  )

  return googledocUrl ? (
    <a
      className={buttonClassNames}
      href={googledocUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      Google Doc
    </a>
  ) : null
}

export default GoogleDocButton
