import React, { useState } from "react"

import { Form, Formik } from "formik"
import moment from "moment-timezone"
import { pipe, subscribe } from "wonka"
import * as Yup from "yup"

import client from "src/urql-client"

import { gql } from "hooks/urql"

import {
  AutocompleteField,
  DateField,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  tutorId: Yup.string().required("Please select a tutor"),
  startsOn: Yup.string().required("Please select a start date"),
  details: Yup.string().required(
    "Please specify details to be provided to the replacement tutor"
  ),
})

const CreateStudyGroupTutorReplacement = ({ studyGroupId }) => {
  const formState = {
    studyGroupId,
    tutorId: "",
    startsOn: "",
    details: "",
  }
  const [completed, setCompleted] = useState(false)

  const handleSubmit = (values, actions) => {
    const { startsOn, ...rest } = values
    const startsAt = moment(startsOn.toDate()).startOf("day")

    try {
      const { unsubscribe } = pipe(
        client.subscription(createReplacementSubscription, {
          startsAt: startsAt.format(),
          ...rest,
        }),
        subscribe(result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            unsubscribe()
            return
          }

          const { status, errorMessages } =
            result.data.createStudyGroupTutorReplacement

          if (errorMessages.length) {
            handleFailure(
              actions,
              errorMessages.map(message => ({ message }))
            )
            unsubscribe()
            return
          }

          if (status === "enqueued") {
            actions.setStatus([
              {
                type: "info",
                message: "Enqueued, please wait while we check for conflicts.",
              },
            ])
          }

          if (status === "success") {
            actions.setStatus([
              {
                type: "success",
                message:
                  "Replacement created. Please refresh for it to appear.",
              },
            ])
            setCompleted(true)
            unsubscribe()
          }
        })
      )
    } catch (e) {
      actions.setStatus([e])
      actions.setSubmitting(false)
    }
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Create Tutor Replacement"
      buttonText="Create Tutor Replacement"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <AutocompleteField
                name="tutorId"
                label="Tutor"
                api="/api/admins/tutors/autocomplete_full_name"
              />
              <DateField
                name="startsOn"
                label="Start Date"
                minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
              />
              <TextAreaField
                name="details"
                label={
                  <>
                    <div>Details</div>
                    <div className="text-sm italic">
                      Provide relevant details about the group for the
                      replacement tutor
                    </div>
                  </>
                }
              />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              {!completed && (
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton text="Create Replacement" />
                </div>
              )}
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createReplacementSubscription = gql`
  subscription (
    $studyGroupId: ID!
    $tutorId: ID!
    $startsAt: DateTime!
    $details: String!
  ) {
    createStudyGroupTutorReplacement(
      studyGroupId: $studyGroupId
      tutorId: $tutorId
      startsAt: $startsAt
      details: $details
    ) {
      status
      errorMessages
    }
  }
`

export default CreateStudyGroupTutorReplacement
