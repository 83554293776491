import React, { useEffect, useState } from "react"

import { useField } from "formik"
import { compress } from "micro-graphql-react"

import { parentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { Field, SelectField } from "components/Forms/Formik/hookComponents"

const SchoolField = () => {
  const [organizationField] = useField("organizationId")
  const [schools, setSchools] = useState([])

  useEffect(() => {
    if (!organizationField.value) {
      return
    }
    client
      .runQuery(schoolsQuery, { id: organizationField.value }, { client })
      .then(response => {
        setSchools(response.data.organization.schools)
      })
  }, [organizationField.value])

  if (schools.length === 0) {
    return <Field name="schoolName" label="School" />
  }

  return (
    <SelectField
      name="schoolId"
      label="School"
      options={ordered(schools, "name")}
      valueAttribute="id"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
    />
  )
}

const schoolsQuery = compress`
  query($id: ID!) {
    organization(id: $id) {
      schools {
        id
        name
      }
    }
  }
`

export default SchoolField
