import React from "react"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

const StudentAvailability = ({ availabilities }) => {
  return (
    <ul className="list-unstyled mb-0">
      {ordered(
        ordered(availabilities, "availability", "weekdayNumber"),
        "availability",
        "startsAt"
      ).map(availability => (
        <li
          key={availability.id}
          css={css`
            text-decoration: ${availability.consumed
              ? "line-through"
              : "inherit"};
          `}
        >
          {availability.availability.weekday}{" "}
          <LocalTime
            timestamp={availability.availability.startsAt}
            format="h:mm A"
          />
          &ndash;
          <LocalTime
            timestamp={availability.availability.endsAt}
            format="h:mm A z"
          />
        </li>
      ))}
    </ul>
  )
}

export default StudentAvailability
