import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $studentId: ID!
    $subjectId: ID!
  ) {
    removeStudentSubject(
      studentId: $studentId
      subjectId: $subjectId
    ) {
      subject {
        id
      }
    }
  }
`
