import React, { useEffect } from "react"

import { useParams } from "react-router-dom"

import EventEmitter from "src/EventEmitter"

import useWebsocket from "hooks/useWebsocket"

import Layer from "components/Pages/Layer"
import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"

import NoShowNotificationList from "./NoShowNotificationList"
import Semesters from "./Semesters"
import StudentSessionsTable from "./StudentSessionsTable"

const SessionsIndex = props => {
  const params = useParams()

  useWebsocket({ channelName: "UserChannel" })
  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      window.location.reload()
    })
  }, [])

  return (
    <>
      <ErrorBoundary name="noShowNotificationList">
        <NoShowNotificationList />
      </ErrorBoundary>

      <Layer>
        <Semesters semesterId={params.semesterId} />
      </Layer>

      <Layer>
        <ErrorBoundary name="studentSessionsTable">
          <StudentSessionsTable semesterId={params.semesterId} />
        </ErrorBoundary>
      </Layer>
    </>
  )
}

export default SessionsIndex
