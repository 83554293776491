import React, { useState } from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"
import { tutorClient as client } from "src/graphql-config"

import { DateObject } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import { SessionDateField } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

const ValidationSchema = Yup.object().shape({
  sessionDate: Yup.number().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

const RescheduleSession = ({ id, startsAt, durationMinutes }) => {
  const [errors, setErrors] = useState([])
  const formState = {
    id: id,
    sessionDate: new DateObject(new Date(startsAt)),
    durationMinutes: durationMinutes,
  }
  const { runMutation } = useMutation(
    buildMutation(rescheduleSessionMutation, { client })
  )

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values

    runMutation({ sessionDate: sessionDate.toDate(), ...rest })
      .then(
        response => {
          const { airtutorsSession, failures } =
            response.rescheduleAirtutorsSession
          if (airtutorsSession) {
            window.location = airtutorsSession.showPath
          } else {
            actions.setSubmitting(false)
            actions.setStatus(
              "Failed to reschedule session. Please correct validation errors."
            )
            setErrors(failures)
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          airbrake.notify({ error, name: "RescheduleSession" })
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        airbrake.notify({ error, name: "RescheduleSession" })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <div className="form-wrapper">
      <h1>Reschedule Session</h1>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div
              className="form-group"
              css={css`
                margin-bottom: 30px;
              `}
            >
              <SessionDateField
                value={values.sessionDate}
                name="sessionDate"
                label="Session Date"
              />
            </div>

            <div className="form-group">
              <label className="form-label">
                Duration in minutes
                <Field
                  name="durationMinutes"
                  className="form-control"
                  type="number"
                  min="15"
                  step="15"
                />
              </label>
              <ErrorMessage name="durationMinutes" />
            </div>

            {status && <div className="alert mt-3 p-2">{status}</div>}

            {errors.map((error, index) => (
              <div key={index} className="alert mt-3 p-2">
                <p>{error.message}</p>
                {error.conflictingSessions && (
                  <ul>
                    {error.conflictingSessions.map(session => (
                      <li key={session.id}>
                        <a
                          href={session.showPath}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <LocalTime timestamp={session.startsAt} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            <SubmitButton isSubmitting={isSubmitting} text="Reschedule" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const rescheduleSessionMutation = compress`
  mutation($id: ID!, $sessionDate: DateTime!, $durationMinutes: Int!) {
    rescheduleAirtutorsSession(id: $id, sessionDate: $sessionDate, durationMinutes: $durationMinutes) {
      airtutorsSession {
        showPath
      }
      failures {
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
    }
  }
`

export default RescheduleSession
