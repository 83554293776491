import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import EditTier from "./EditTier"
import NewSubjectBucket from "./NewSubjectBucket"
import SubjectBucketRow from "./SubjectBucketRow"
import TierContext from "./TierContext"

const SubjectBucketsIndex = () => {
  const loadingState = useQuery(
    buildQuery(
      bucketQuery,
      {},
      {
        onMutation: [
          {
            when: "tierUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { tier } = response.tierUpdate
              if (!tier) return

              const index = currentResults.tiers.findIndex(
                t => t.id === tier.id
              )
              currentResults.tiers[index].gradeRange = tier.gradeRange
              currentResults.tiers[index].hourlyRate = tier.hourlyRate
              currentResults.tiers[index].formattedHourlyRate =
                tier.formattedHourlyRate
              softReset(currentResults)
            },
          },
          {
            when: "subjectBucketCreate",
            run: ({ softReset, currentResults }, response) => {
              const { subjectBucket } = response.subjectBucketCreate
              if (!subjectBucket) return

              const index = currentResults.tiers.findIndex(
                t => t.id === subjectBucket.tier.id
              )
              currentResults.tiers[index].subjectBuckets.push(subjectBucket)
              softReset(currentResults)
            },
          },
          {
            when: "subjectBucketUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { subjectBucket } = response.subjectBucketUpdate
              if (!subjectBucket) return

              const flattenedBuckets = currentResults.tiers
                .map(t => t.subjectBuckets)
                .flat()
              const oldBucket = flattenedBuckets.find(
                b => b.id === subjectBucket.id
              )
              if (oldBucket.tier.id === subjectBucket.tier.id) {
                const tier = currentResults.tiers.find(
                  t => t.id === subjectBucket.tier.id
                )
                const bucketIndex = tier.subjectBuckets.findIndex(
                  b => b.id === subjectBucket.id
                )
                tier.subjectBuckets[bucketIndex] = subjectBucket
              } else {
                const tier = currentResults.tiers.find(
                  t => t.id === oldBucket.tier.id
                )
                tier.subjectBuckets = tier.subjectBuckets.filter(
                  b => b.id !== subjectBucket.id
                )

                const newTier = currentResults.tiers.find(
                  t => t.id === subjectBucket.tier.id
                )
                newTier.subjectBuckets.push(subjectBucket)
              }
              softReset(currentResults)
            },
          },
          {
            when: "subjectBucketRemove",
            run: ({ softReset, currentResults }, response) => {
              const { subjectBucket } = response.subjectBucketRemove
              if (!subjectBucket) return

              const tierIndex = currentResults.tiers.findIndex(
                t => t.id === subjectBucket.tier.id
              )
              const tier = currentResults.tiers[tierIndex]
              tier.subjectBuckets = tier.subjectBuckets.filter(
                b => b.id !== subjectBucket.id
              )
              softReset(currentResults)
            },
          },
          {
            when: "subjectCreate",
            run: ({ softReset, currentResults }, response) => {
              const { subject } = response.subjectCreate
              if (!subject) return

              const tierIndex = currentResults.tiers.findIndex(
                t => t.id === subject.tier.id
              )
              const tier = currentResults.tiers[tierIndex]
              const bucketIndex = tier.subjectBuckets.findIndex(
                b => b.id === subject.subjectBucket.id
              )
              tier.subjectBuckets[bucketIndex].subjects.push(subject)
              softReset(currentResults)
            },
          },
          {
            when: "subjectUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { subject } = response.subjectUpdate
              if (!subject) return

              const flattenedBuckets = currentResults.tiers
                .map(t => t.subjectBuckets)
                .flat()
              const flattenedSubjects = flattenedBuckets
                .map(b => b.subjects)
                .flat()

              const oldSubject = flattenedSubjects.find(
                s => s.id === subject.id
              )
              if (oldSubject.subjectBucket.id === subject.subjectBucket.id) {
                const tier = currentResults.tiers.find(
                  t => t.id === subject.tier.id
                )
                const bucket = tier.subjectBuckets.find(
                  b => b.id === subject.subjectBucket.id
                )
                const subjectIndex = bucket.subjects.findIndex(
                  s => s.id === subject.id
                )
                bucket.subjects[subjectIndex] = subject
              } else {
                const oldTier = currentResults.tiers.find(
                  t => t.id === oldSubject.tier.id
                )
                const oldBucket = oldTier.subjectBuckets.find(
                  b => b.id === oldSubject.subjectBucket.id
                )
                oldBucket.subjects = oldBucket.subjects.filter(
                  s => s.id !== subject.id
                )

                const newTier = currentResults.tiers.find(
                  t => t.id === subject.tier.id
                )
                const newBucket = newTier.subjectBuckets.find(
                  b => b.id === subject.subjectBucket.id
                )
                newBucket.subjects.push(subject)
              }
              softReset(currentResults)
            },
          },
          {
            when: "subjectRemove",
            run: ({ softReset, currentResults }, response) => {
              const { subject } = response.subjectRemove
              if (!subject) return

              const tierIndex = currentResults.tiers.findIndex(
                t => t.id === subject.tier.id
              )
              const bucketIndex = currentResults.tiers[
                tierIndex
              ].subjectBuckets.findIndex(b => b.id === subject.subjectBucket.id)
              const bucket =
                currentResults.tiers[tierIndex].subjectBuckets[bucketIndex]
              bucket.subjects = bucket.subjects.filter(s => s.id !== subject.id)
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <TierContext.Provider value={data.tiers}>
          {ordered(data.tiers, "order").map(tier => (
            <React.Fragment key={tier.id}>
              <h4>{tier.gradeRange}</h4>
              <p className="lead">Default Rate: {tier.formattedHourlyRate}</p>
              <EditTier tier={tier} />
              <div className="d-inline-block ml-3">
                <NewSubjectBucket tier={tier} />
              </div>

              <table className="table">
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Subject Type</th>
                    <th>Collaboration Type</th>
                    <th>Hourly Rate</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ordered(tier.subjectBuckets, "name").map(subjectBucket => (
                    <SubjectBucketRow
                      key={subjectBucket.id}
                      subjectBucket={subjectBucket}
                      tier={tier}
                    />
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          ))}
        </TierContext.Provider>
      )}
    </WithLoadingIndicator>
  )
}

const bucketQuery = compress`
  query {
    tiers {
      id
      name
      order
      gradeRange
      formattedHourlyRate
      hourlyRate

      subjectBuckets {
        id
        name
        subjectType
        collaborationType
        formattedHourlyRate
        hourlyRateOverride
        tier {
          id
          gradeRange
        }
        subjects {
          id
          name
          subjectBucket {
            id
          }
          tier {
            id
          }
          organization {
            id
            name
          }
        }
      }
    }
  }
`

export default SubjectBucketsIndex
