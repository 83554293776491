import React from "react"

import { Field, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import Select, { components as selectComponents } from "react-select"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { parentClient, studentClient } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SingleValue = props => (
  <selectComponents.SingleValue {...props}>
    {props.data.shortLabel}
  </selectComponents.SingleValue>
)

const ValidationSchema = Yup.object().shape({
  prepaidPackageId: Yup.string().required("Please select a package"),
})

const AddPrepaidPackage = ({ userType }) => {
  const client = userType === "Student" ? studentClient : parentClient
  const { runMutation } = useMutation(
    buildMutation(purchasePrepaidPackageMutation, { client })
  )
  const loadingState = useQuery(
    buildQuery(
      prepaidPackagesQuery,
      {},
      {
        client,
        onMutation: [
          {
            when: "purchasePrepaidPackage",
            run: ({ softReset, currentResults }, response) => {
              const payload = response.purchasePrepaidPackage
              if (payload.viewer) {
                currentResults.viewer.prepaidHours = payload.viewer.prepaidHours
                currentResults.invoice = payload.invoice
                softReset(currentResults)
              }
            },
          },
        ],
      }
    )
  )

  const formState = {
    prepaidPackageId: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const payload = response.purchasePrepaidPackage

          if (payload.viewer) {
            window.location.reload()
          } else {
            actions.setSubmitting(false)
            actions.setStatus(
              <div>
                Unable to add package
                <ul>
                  {payload.errorMessages.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            )
          }
        },
        rejected => {
          actions.setSubmitting(false)
          actions.setStatus("An error occurred while processing your request")
        }
      )
      .catch(err => {
        actions.setSubmitting(false)
        actions.setStatus("An error occurred while processing your request")
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <h2>Remaining Prepaid Hours: {data.viewer.prepaidHours}</h2>

          <ModalWithProvidedBody
            buttonText="Add Prepaid Package"
            buttonClassName="btn solid green"
            modalClassName="bootstrap-modal"
            modalTitle="Add Prepaid Package"
          >
            {({ closeModal }) => (
              <Formik
                initialValues={formState}
                onSubmit={(values, actions) =>
                  handleSubmit(values, actions, closeModal)
                }
                validationSchema={ValidationSchema}
              >
                {({ status, isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="modal-body">
                      <label>
                        Select Package
                        <Field name="prepaidPackageId" type="text">
                          {({ field: { name } }) => (
                            <Select
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              options={ordered(
                                data.prepaidPackages,
                                "hours"
                              ).map(prepaidPackage => ({
                                value: prepaidPackage.id,
                                shortLabel: `${prepaidPackage.name}: ${prepaidPackage.formattedPrice}`,
                                label: (
                                  <div>
                                    {prepaidPackage.name}:{" "}
                                    {prepaidPackage.formattedPrice}
                                    <br />
                                    {prepaidPackage.hours} hours
                                    <br />
                                    Additional Hours:{" "}
                                    {prepaidPackage.formattedHourlyRate} / hour
                                  </div>
                                ),
                              }))}
                              components={{ SingleValue }}
                              onChange={selectedOption =>
                                setFieldValue(
                                  "prepaidPackageId",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              placeholder="Select Package"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="prepaidPackageId" />
                      </label>
                    </div>
                    <div
                      className="modal-footer"
                      css={css`
                        flex-flow: column;
                      `}
                    >
                      {status && (
                        <div
                          className="alert alert-danger"
                          css={css`
                            justify-content: center !important;
                          `}
                        >
                          {status}
                        </div>
                      )}
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        text="Purchase Package"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </ModalWithProvidedBody>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const purchasePrepaidPackageMutation = compress`
  mutation($prepaidPackageId: ID!) {
    purchasePrepaidPackage(prepaidPackageId: $prepaidPackageId) {
      viewer {
        prepaidHours
      }
      invoice {
        hours
      }
      errorMessages
    }
  }
`

const prepaidPackagesQuery = compress`
  query {
    viewer {
      prepaidHours
    }
    prepaidPackages(amp: false) {
      id
      name
      hours
      formattedPrice
      formattedHourlyRate
    }
  }
`

export default AddPrepaidPackage
