import React, { useState } from "react"

import { useField } from "formik"
import Select from "react-select"

import ordered from "src/ordered"

const AddTeacherSubject = ({ teachers, arrayHelpers }) => {
  const [adding, setAdding] = useState(false)
  const [teacher, setTeacher] = useState()

  const [field] = useField({ name: "teacherSubjectIds" })

  if (!adding) {
    return (
      <button
        type="button"
        onClick={() => setAdding(true)}
        className="btn solid green"
      >
        Add Teacher
      </button>
    )
  }

  return (
    <div className="d-flex">
      <Select
        placeholder="Select Teacher"
        options={ordered(teachers, "lastName")}
        getOptionLabel={option => option.fullName}
        getOptionValue={option => option}
        menuPortalTarget={document.body}
        onChange={selectedOption => setTeacher(selectedOption)}
        styles={{
          container: provided => ({
            ...provided,
            width: 250,
          }),
        }}
      />

      {teacher && (
        <div className="ml-3">
          <Select
            placeholder="Select Subject"
            options={ordered(teacher.teacherSubjects, "subject", "name")}
            getOptionLabel={option => option.subject.name}
            getOptionValue={option => option.id}
            menuPortalTarget={document.body}
            onChange={selectedOption => {
              if (!field.value.includes(selectedOption?.id)) {
                arrayHelpers.push(selectedOption.id)
              }
              setTeacher()
              setAdding(false)
            }}
            styles={{
              container: provided => ({
                ...provided,
                width: 250,
              }),
            }}
          />
        </div>
      )}
    </div>
  )
}

AddTeacherSubject.step = "Add Teacher Subject"

export default AddTeacherSubject
