import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

class AutocompleteStudent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { studentId: this.props.studentId }
  }

  onChange = studentId => {
    this.setState({ studentId: studentId ? studentId.value : "" })
  }

  render() {
    return (
      <div className="form-group">
        <label className="form-control-label">Student</label>
        <input
          type="hidden"
          value={this.state.studentId}
          name={this.props.name}
        />
        <AutocompleteSelect
          onChange={this.onChange}
          api={"/api/admins/students/autocomplete_full_name"}
          searchOptions={this.props.searchOptions}
          selected={{
            value: this.props.studentId,
            label: this.props.studentName,
          }}
          clearableSelects={[]}
        />
      </div>
    )
  }
}

AutocompleteStudent.defaultProps = {
  studentId: "",
  studentName: "",
}

export default AutocompleteStudent
