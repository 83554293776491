import React, { useEffect, useState } from "react"

import dancing from "assets/dancing.gif"
import { Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { studentClient } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import LoadingIndicator from "components/LoadingIndicator"

import { RatingContainer } from "./Rating"

const StudentEvaluation = ({ airtutorsSessionId }) => {
  const [results, setResults] = useState({})

  const loadingState = useQuery(
    buildQuery(
      studentEvaluationQuery,
      { airtutorsSessionId },
      { client: studentClient }
    )
  )

  const { runMutation } = useMutation(
    buildMutation(studentEvaluationMutation, { client: studentClient })
  )

  useEffect(() => {
    if (!loadingState.data) return

    const groupedCompletions = {}
    const sortedCompletions = ordered(
      ordered(
        loadingState.data.airtutorsSession.lessonResourceCompletions,
        "lessonTopic",
        "sortOrder"
      ),
      "lessonResource",
      "sortOrder"
    )
    sortedCompletions.forEach(completion => {
      if (!groupedCompletions[completion.lessonPlan.name]) {
        groupedCompletions[completion.lessonPlan.name] = {}
      }
      if (
        !groupedCompletions[completion.lessonPlan.name][
          completion.lessonTopic.name
        ]
      ) {
        groupedCompletions[completion.lessonPlan.name][
          completion.lessonTopic.name
        ] = []
      }

      groupedCompletions[completion.lessonPlan.name][
        completion.lessonTopic.name
      ].push(completion)
    })

    let completedTopics = sortedCompletions
      .map(completion => ({
        lessonResourceCompletionId: completion.id,
        lessonTopic: completion.completedLessonTopic,
        start: completion.studentTopicMasteryStart,
        end: completion.studentTopicMasteryEnd,
      }))
      .filter(topic => topic.lessonTopic)
      .filter(
        // filter to list of uniq topics
        (topic, index, self) =>
          self.indexOf(
            self.find(c => c.lessonTopic.id === topic.lessonTopic.id)
          ) === index
      )

    setResults({ groupedCompletions, completedTopics })
  }, [loadingState.data])

  const handleSubmit = (values, actions) => {
    const mutationValues = { lessonResourceCompletions: [] }

    Object.keys(values.lessonResourceCompletions).forEach(completionId => {
      const matchingTopic = values.lessonTopics[completionId]
      mutationValues.lessonResourceCompletions.push({
        lessonResourceCompletionId: completionId,
        studentMastery: values.lessonResourceCompletions[completionId],
        studentTopicMasteryStart: matchingTopic?.start,
        studentTopicMasteryEnd: matchingTopic?.end,
      })
    })

    runMutation(mutationValues)
      .then(
        response => (window.location = "/"),
        err => {
          actions.setStatus(JSON.stringify(err))
          actions.setSubmitting(false)
        }
      )
      .catch(e => {
        actions.setStatus("Unable to save your results")
        actions.setSubmitting(false)
      })
  }

  if (!results.groupedCompletions) {
    if (loadingState.error)
      return <div className="alert alert-danger">An error occurred</div>

    return <LoadingIndicator />
  }

  const completedTopics = {}
  results.completedTopics.forEach(
    topic => (completedTopics[topic.lessonResourceCompletionId] = topic)
  )

  const completionValues = {}
  loadingState.data.airtutorsSession.lessonResourceCompletions.forEach(
    completion => (completionValues[completion.id] = completion.studentMastery)
  )

  const formState = {
    lessonResourceCompletions: completionValues,
    lessonTopics: completedTopics,
  }

  const ValidationSchema = Yup.object().shape({
    lessonResourceCompletions: Yup.mixed().test(
      "lessonResourceCompletions",
      "Please fill out all worksheet fields",
      completions => {
        const fulfilled =
          loadingState.data.airtutorsSession.lessonResourceCompletions.map(
            completion => {
              if (!completions[completion.id]) {
                return false
              } else {
                return true
              }
            }
          )
        if (fulfilled.indexOf(false) >= 0) {
          return false
        } else {
          return true
        }
      }
    ),
    lessonTopics: Yup.mixed().test(
      "lessonTopics",
      "Please fill out all chapter fields",
      topics => {
        const fulfilled = results.completedTopics.map(topic => {
          if (
            !topics[topic.lessonResourceCompletionId].start ||
            !topics[topic.lessonResourceCompletionId].end
          ) {
            return false
          } else {
            return true
          }
        })
        if (fulfilled.indexOf(false) >= 0) {
          return false
        } else {
          return true
        }
      }
    ),
  })

  return (
    <div className="text-center">
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting, setFieldValue, values }) => (
          <Form>
            <h3
              css={css`
                font-weight: 500;
              `}
            >
              <span>Woohoo! Congrats on finishing an assignment!</span>
              <span
                role="img"
                aria-label="congratulations"
                css={css`
                  margin-left: 15px;
                `}
              >
                🥳🥳🥳🥳
              </span>
            </h3>
            <h3
              css={css`
                font-weight: 500;
              `}
            >
              How do you feel about these worksheets?
            </h3>
            {Object.keys(results.groupedCompletions).map(planName => (
              <React.Fragment key={planName}>
                {Object.keys(results.groupedCompletions[planName]).map(
                  topicName => (
                    <React.Fragment key={topicName}>
                      <hr />
                      {results.groupedCompletions[planName][topicName].map(
                        completion => (
                          <RatingContainer
                            key={completion.id}
                            label={completion.lessonResource.name}
                            name={`lessonResourceCompletions[${completion.id}]`}
                            value={
                              values.lessonResourceCompletions[completion.id]
                            }
                            onChange={e =>
                              setFieldValue(
                                `lessonResourceCompletions[${completion.id}]`,
                                e.currentTarget.value
                              )
                            }
                          />
                        )
                      )}
                    </React.Fragment>
                  )
                )}
              </React.Fragment>
            ))}
            {results.completedTopics.length > 0 && (
              <>
                <img src={dancing} alt="Dancing" />
                <h3
                  css={css`
                    font-weight: 500;
                  `}
                >
                  And how about the chapter?
                </h3>
              </>
            )}
            {results.completedTopics.map(completedTopic => (
              <React.Fragment key={completedTopic.lessonTopic.id}>
                <hr />
                <h4
                  css={css`
                    font-weight: 500;
                  `}
                >
                  {completedTopic.lessonTopic.name}
                </h4>
                <RatingContainer
                  label="How did you feel at the start of this chapter?"
                  name={`lessonTopics[${completedTopic.lessonResourceCompletionId}].start`}
                  value={
                    values.lessonTopics[
                      completedTopic.lessonResourceCompletionId
                    ].start
                  }
                  onChange={e =>
                    setFieldValue(
                      `lessonTopics[${completedTopic.lessonResourceCompletionId}].start`,
                      e.currentTarget.value
                    )
                  }
                />
                <RatingContainer
                  label="How do you feel now?"
                  name={`lessonTopics[${completedTopic.lessonResourceCompletionId}].end`}
                  value={
                    values.lessonTopics[
                      completedTopic.lessonResourceCompletionId
                    ].end
                  }
                  onChange={e =>
                    setFieldValue(
                      `lessonTopics[${completedTopic.lessonResourceCompletionId}].end`,
                      e.currentTarget.value
                    )
                  }
                />
              </React.Fragment>
            ))}

            <div>
              <ErrorMessage name="lessonResourceCompletions" />
            </div>
            <div>
              <ErrorMessage name="lessonTopics" />
            </div>

            {status && <div className="alert alert-danger">{status}</div>}
            <SubmitButton isSubmitting={isSubmitting} text="Submit" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const studentEvaluationQuery = compress`
  query($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      lessonResourceCompletions {
        id
        studentMastery
        studentTopicMasteryStart
        studentTopicMasteryEnd
        lessonPlan {
          id
          name
        }
        lessonTopic {
          id
          name
          sortOrder
        }
        lessonResource {
          id
          name
          sortOrder
        }
        completedLessonTopic {
          id
          name
          sortOrder
        }
      }
    }
  }
`

const studentEvaluationMutation = compress`
  mutation(
    $lessonResourceCompletions: [StudentResourceEvaluationInputObject!]!
  ) {
    updateLessonEvaluations(
      lessonResourceCompletions: $lessonResourceCompletions
    ) {
      success
      errorMessage
    }
  }
`

export default StudentEvaluation
