import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql } from "@urql/core"

import airbrake from "src/airbrake-notifier"
import { countryOptions, regionOptions } from "src/enums"

import { useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FileField,
  FormStatus,
  SelectField,
  SubmitButton,
  TextAreaField,
  TimeZoneField,
  readFile,
} from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required(),
})

const TutorForm = ({ tutor, tiers, canAdminExpenses, canAdminBudgets }) => {
  const formState = {
    id: tutor?.id,
    firstName: tutor?.firstName || "",
    lastName: tutor?.lastName || "",
    email: tutor?.email || "",
    personalEmail: tutor?.personalEmail || "",
    phoneNumber: tutor?.phoneNumber || "",
    education: tutor?.education || "",
    bio: tutor?.bio || "",
    emailTimeZone: tutor?.emailTimeZone || "Pacific",
    country: tutor?.address?.country || "United States",
    region: tutor?.address?.region || "California",
    realtimeboardAdmin: tutor?.realtimeboardAdmin || false,
    onDemandTutor: tutor?.onDemandTutor || false,
    avatar: {},
    disableTutorNotifications: tutor?.disableTutorNotifications || false,
    activeCourseStudyGroupLimit: tutor?.activeCourseStudyGroupLimit || 20,
    tierIds: tutor?.tiers?.map(tier => tier.id) || [],
    canSubmitGeneralExpenses: tutor?.canSubmitGeneralExpenses || false,
    hasSubmittedFingerprintExpense:
      tutor?.hasSubmittedFingerprintExpense || false,
    hasSubmittedIntroSessionExpense:
      tutor?.hasSubmittedIntroSessionExpense || false,
    billableName: tutor?.billableName || "",
  }
  const [errors, setErrors] = useState([])
  const [, saveTutor] = useMutation(saveTutorMutation)

  const handleSubmit = (values, actions) => {
    readFile(values.avatar)
      .then(file => {
        values.avatar = file
        saveTutor({ input: values })
          .then(response => {
            const { failures, redirectTo } = response.data.tutorSave
            if (failures) {
              setErrors(failures)
            } else {
              window.location = redirectTo
            }
          })
          .catch(error => {
            setErrors([{ message: "Something went wrong" }])
            airbrake.notify({
              error,
              name: "AdminTutorForm#saveTutor",
            })
          })
          .finally(() => actions.setSubmitting(false))
      })
      .catch(error => {
        setErrors([{ message: "Something went wrong" }])
        airbrake.notify({
          error,
          name: "AdminTutorForm#readFile",
        })
      })
  }

  return (
    <div className="w-full md:w-2/3 lg:w-1/2">
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="firstName" label="First Name" />
          <Field name="lastName" label="Last Name" />
          {canAdminBudgets && (
            <Field
              name="billableName"
              label="Billable Name (their business name as it appears in Bill.com)"
            />
          )}
          <Field name="email" label="Airtutors Email" />
          <Field name="personalEmail" label="Personal Email" />
          <Field name="phoneNumber" label="Phone Number" />
          <TextAreaField name="education" label="Education" />
          <TextAreaField name="bio" label="Bio" />
          <TimeZoneField name="emailTimeZone" label="Time Zone" />
          <SelectField
            name="country"
            label="Country"
            options={countryOptions}
            defaultValue={countryOptions.find(
              o => o.value === formState.country
            )}
          />
          <SelectField
            name="region"
            label="Region"
            options={regionOptions.filter(r => r.value !== "Other")}
            defaultValue={regionOptions.find(o => o.value === formState.region)}
          />
          <CheckboxField
            name="realtimeboardAdmin"
            label="Miro Admin (this will automatically add tutor to ALL miro boards)"
          />
          <CheckboxField name="onDemandTutor" label="On-Demand Tutor?" />
          <FileField name="avatar" label="Avatar" accept="image/*" />
          <CheckboxField
            name="disableTutorNotifications"
            label="Disable Tutor Notifications"
          />
          <Field
            type="number"
            name="activeCourseStudyGroupLimit"
            label="Active Course Study Group Limit"
          />
          <label>Grade Ranges</label>

          {tiers.map(tier => (
            <CheckboxField
              key={tier.id}
              name="tierIds"
              value={tier.id}
              label={tier.gradeRange}
            />
          ))}

          {canAdminExpenses && (
            <>
              {tutor && !tutor.billDotComVendorId ? (
                <p className="alert alert-info">
                  This tutor does not have a linked Bill.com account, so we
                  cannot enable the expense system for them at this time. Please
                  reach out to development to have the tutors vendor ID set if
                  you need to enable the expense system for them.
                </p>
              ) : (
                <>
                  <label>Expenses</label>
                  <CheckboxField
                    name="canSubmitGeneralExpenses"
                    label="Can Submit General Expenses"
                  />
                  <CheckboxField
                    name="hasSubmittedFingerprintExpense"
                    label="Has Submitted Fingerprint Expense"
                  />
                  <CheckboxField
                    name="hasSubmittedIntroSessionExpense"
                    label="Has Submitted Intro Session Expense"
                  />
                </>
              )}
            </>
          )}

          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger">
              {error.message}
            </div>
          ))}
          <FormStatus />
          <SubmitButton />
        </Form>
      </Formik>
    </div>
  )
}

const saveTutorMutation = gql`
  mutation TutorSave($input: TutorInputObject!) {
    tutorSave(input: $input) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default TutorForm
