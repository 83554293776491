import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

import { tutorClient } from "src/graphql-config"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import CancelSessionButton from "components/Tutor/AirtutorsSessions/CancelSessionButton"
import RescheduleSessionButton from "components/Tutor/AirtutorsSessions/RescheduleSessionButton"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ScheduledSessions = props => {
  const { studentId } = props
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      scheduledSessionsQuery,
      { studentId: studentId, page: page },
      { client: tutorClient }
    )
  )

  return (
    <>
      <div className="row">
        <WithLoadingIndicator
          loadingState={loadingState}
          error="An error occurred while loading your scheduled sessions"
          heading={<h2>Scheduled Sessions</h2>}
        >
          {({ data }) =>
            data.airtutorsSessions.data.length === 0 ? (
              "No scheduled sessions"
            ) : (
              <React.Fragment>
                <table>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.airtutorsSessions.data.map(session => (
                      <tr key={session.id}>
                        <td>{session.subject.name}</td>
                        <td>
                          <LocalTime
                            format="ddd MMM D"
                            timestamp={session.startsAt}
                          />
                        </td>
                        <td>
                          <LocalTime omitDate timestamp={session.startsAt} />
                          &mdash;
                          <LocalTime omitDate timestamp={session.endsAt} />
                        </td>
                        <td className="button-cell">
                          <a
                            className="btn solid small blue"
                            href={session.showPath}
                          >
                            View
                          </a>
                          <RescheduleSessionButton
                            path={session.reschedulePath}
                          />
                          <CancelSessionButton id={session.id} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={page}
                  totalPages={data.airtutorsSessions.totalPages}
                  changePage={page => setPage(page)}
                />
              </React.Fragment>
            )
          }
        </WithLoadingIndicator>
      </div>
      <div
        css={css`
          padding: 1rem;
        `}
      />
    </>
  )
}

const scheduledSessionsQuery = compress`
  query($studentId: ID!, $page: Int!) {
    airtutorsSessions(studentId: $studentId, future: true, scheduled: true, unbilled: true, page: $page) {
      totalPages
      data {
        id
        startsAt
        endsAt
        showPath
        reschedulePath
        subject {
          name
        }
      }
    }
  }
`

ScheduledSessions.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default ScheduledSessions
