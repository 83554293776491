import React, { useState } from "react"

import moment from "moment-timezone"

import { Input } from "@chakra-ui/core"

import { DateObject, DateRangePicker } from "components/Forms/DatePicker"

const DateFilter = props => {
  const { updateFilter, startsAt, endsAt } = props
  const [startDate, setStartDate] = useState(
    startsAt && new DateObject(startsAt)
  )
  const [endDate, setEndDate] = useState(endsAt && new DateObject(endsAt))

  const maxDate = props.semester ? new DateObject(props.semester.endsOn) : null
  const minDate = props.semester
    ? new DateObject(props.semester.startsOn)
    : null

  return (
    <DateRangePicker
      onChange={([startDate, endDate]) => {
        const startMoment = moment(startDate.toDate()).startOf("day")
        const endMoment = moment(endDate?.toDate() || startMoment).endOf("day")

        setStartDate(startDate)
        setEndDate(endDate)
        updateFilter([
          {
            field: "startsAt",
            value: startMoment.format(),
          },
          {
            field: "endsAt",
            value: endMoment.format(),
          },
        ])
      }}
      minDate={minDate}
      maxDate={maxDate}
      value={[startDate, endDate]}
      clear={false}
    >
      <Input type="text" data-input />
    </DateRangePicker>
  )
}

export default DateFilter
