import React from "react"

import classNames from "src/classNames"
import emptyFunction from "src/emptyFunction"

type CardProps = {
  title: string
  description: string
  onClick?: typeof emptyFunction
  href?: string
  highlight?: boolean
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  onClick,
  href,
  highlight = false,
}) => {
  return (
    <div
      className={classNames(
        highlight ? "ring-2 ring-sky-600" : "ring-1 ring-gray-200",
        "grid rounded-3xl p-8"
      )}
    >
      <h2
        className={classNames(
          highlight ? "text-sky-600" : "text-gray-900",
          "text-lg/8 font-semibold"
        )}
      >
        {title}
      </h2>
      <p className="text-sm/6 mt-4 text-gray-600">{description}</p>
      {onClick ? (
        <button
          onClick={onClick}
          className={classNames(
            "w-full self-end bg-sky-600 text-white shadow-sm hover:bg-sky-500",
            "text-sm/6 mt-6 block rounded-md px-3 py-2 text-center font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600",
            "border-0"
          )}
        >
          Sign Up
        </button>
      ) : (
        <a
          href={href}
          className={classNames(
            "w-full self-end bg-sky-600 text-white shadow-sm hover:bg-sky-500",
            "text-sm/6 mt-6 block rounded-md px-3 py-2 text-center font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600",
            "hover:text-white"
          )}
        >
          Sign Up
        </a>
      )}
    </div>
  )
}

export default Card
