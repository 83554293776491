import React, { useState } from "react"

import { FieldArray, Form, Formik } from "formik"
import * as Yup from "yup"

import ordered from "src/ordered"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import { ErrorMessage, RadioGroup } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

export type SendSmsButtonProps = {
  airtutorsSessionId: string
}

const ValidationSchema = Yup.object().shape({
  studentIds: Yup.array().min(1, "Please select at least one student"),
})

const SendSmsButton: React.FC<SendSmsButtonProps> = ({
  airtutorsSessionId,
}) => {
  const formState = {
    studentIds: [],
  }

  const [submitted, setSubmitted] = useState(false)

  const client = useClient()

  const [result] = useQuery({
    query: studentsQuery,
    variables: { airtutorsSessionId },
    client,
  })

  const [_, runMutation]: any[] = useMutation(sendSmsMutation, client)

  const handleSubmit = (values, actions) => {
    runMutation({ id: airtutorsSessionId, ...values })
      .then(
        response => {
          const { failures } = response.data.airtutorsSessionSendLateSms
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            actions.setStatus()
            setSubmitted(true)
          }
        },
        err => actions.setStatus(`Rejected: ${err?.message}`)
      )
      .catch(err => actions.setStatus(`Caught: ${err?.message}`))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn solid purple w-100 mb-1"
      buttonText="Send Student SMS"
      modalClassName="bootstrap-modal"
      modalTitle="Send Student SMS"
    >
      {({ closeModal }) => (
        <>
          {submitted ? (
            <div>
              <div className="modal-body">
                <div>Your SMS will be sent in ~1 minute!</div>
              </div>
              <div className="modal-footer block">
                <button className="btn solid blue" onClick={closeModal}>
                  Okay
                </button>
              </div>
            </div>
          ) : (
            <UrqlLoadingIndicator result={result}>
              {({ data }) => (
                <Formik
                  initialValues={formState}
                  onSubmit={(values, actions) => handleSubmit(values, actions)}
                  validationSchema={ValidationSchema}
                >
                  {({ values, status, isSubmitting }) => (
                    <Form>
                      <div className="modal-body">
                        <p className="mt-0">
                          This will send the following SMS to the selected
                          students:
                        </p>
                        <p className="rounded-md border-solid border-black bg-[#eee] p-4">
                          Hey [Student Name]! It's [Tutor Name]{" "}
                          <span role="img" aria-label="happy">
                            🤗
                          </span>{" "}
                          Your session with me is happening right now! Any
                          chance you can make it?
                          <br />
                          <br />
                          Click this link to join the Cloudroom: [Cloudroom
                          Link]
                          <br />
                          <br />
                          BTW, if the arrow wheels keep spinning, click on the
                          camera icon in your URL address bar on the far right
                          and make sure airtutors.org is allowed. Lmk if you can
                          make it today!{" "}
                          <span role="img" aria-label="congratulations">
                            🥳
                          </span>
                        </p>
                        <FieldArray name="studentIds">
                          {arrayHelpers =>
                            ordered(
                              data.airtutorsSession.studentSessions,
                              "student",
                              "lastName"
                            ).map(studentSession => (
                              <RadioGroup
                                key={studentSession.id}
                                type="checkbox"
                                name="studentIds"
                                disabled={!studentSession.student.phoneNumber}
                                values={[
                                  {
                                    value: studentSession.student.id,
                                    label: `${
                                      studentSession.student.fullName
                                    } ${
                                      !studentSession.student.phoneNumber
                                        ? "(has no number on file)"
                                        : ""
                                    }${
                                      studentSession.lateSmsSentAt
                                        ? "(you can only send each student one SMS per session)"
                                        : ""
                                    }`,
                                    disabled:
                                      !studentSession.student.phoneNumber ||
                                      studentSession.lateSmsSentAt,
                                  },
                                ]}
                                onChange={e => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(studentSession.student.id)
                                  } else {
                                    arrayHelpers.remove(
                                      values.studentIds.findIndex(
                                        s => s === studentSession.student.id
                                      )
                                    )
                                  }
                                }}
                              />
                            ))
                          }
                        </FieldArray>
                        <ErrorMessage name="studentIds" />
                      </div>
                      <div className="modal-footer block">
                        <div className="flex flex-col">
                          {status && (
                            <div className="alert alert-danger mb-4 p-2">
                              {status}
                            </div>
                          )}
                          <div className="align-center flex justify-between">
                            <button
                              className="btn solid red btn-danger"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn solid blue"
                              disabled={isSubmitting}
                            >
                              Send SMS
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </UrqlLoadingIndicator>
          )}
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const studentsQuery = gql`
  query ($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      studentSessions {
        id
        lateSmsSentAt
        student {
          id
          fullName
          lastName
          phoneNumber
        }
      }
    }
  }
`

const sendSmsMutation = gql`
  mutation ($id: ID!, $studentIds: [ID!]!) {
    airtutorsSessionSendLateSms(id: $id, studentIds: $studentIds) {
      failures {
        message
      }
    }
  }
`

export default SendSmsButton
