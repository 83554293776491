import React from "react"

import { gql } from "urql"

import ordered from "src/ordered"

import { useClient, useQuery } from "hooks/urql"

import { SelectField } from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const GradeField = React.forwardRef((props, ref) => {
  const [results] = useQuery({
    query: gradesQuery,
    client: useClient(),
  })

  return (
    <UrqlLoadingIndicator result={results}>
      {({ data }) => (
        <SelectField
          name="gradeId"
          label="Grade"
          options={ordered(data.grades, "number")}
          valueAttribute="id"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          ref={ref}
          {...props}
        />
      )}
    </UrqlLoadingIndicator>
  )
})

const gradesQuery = gql`
  query {
    grades {
      id
      number
      name
    }
  }
`

export default GradeField
