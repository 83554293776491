import { compress } from "micro-graphql-react"

export default compress`
  query($parentId: ID!) {
    parent(id: $parentId) {
      students {
        id
        fullName
        email
        editPath
        showPath
        subjects {
          id
          name
        }
      }
    }
  }
`
