import React from "react"

import { useField } from "formik"

import { RadioField } from "components/Forms/Formik/hookComponents"

const Technology = () => {
  const [hasTabletField] = useField("hasTablet")
  const [techSavvinessField] = useField("techSavviness")

  return (
    <>
      <h3>Technology</h3>
      <RadioField
        name="techSavviness"
        label="What is your comfort level using technology? (online video platforms, cell phone, digital whiteboard, google docs, etc)"
        options={[
          { label: "1 (I'm horrible)", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
          { label: "4", value: 4 },
          { label: "5 (I'm amazing)", value: 5 },
        ]}
        numeric
      />

      {techSavvinessField.value === 3 ? (
        <div className="mb-2 bg-gray-100 p-3 py-1">
          <h4 className="font-medium">
            Can you help students and fellow tutors:
          </h4>
          <div className="ml-3">
            <RadioField
              name="canDownloadFiles"
              label="Download/scan/upload images, videos, documents into different applications"
              boolean
            />

            <RadioField
              name="canUseCommonWebapps"
              label="Navigate google drive, google doc, gmail, and other commonly used student webpages"
              boolean
            />

            <RadioField
              name="canTroubleshootWebcam"
              label="Troubleshoot simple tech issues - web camera, audio issues, etc"
              boolean
            />

            <RadioField
              name="canTroubleshootLogin"
              label="Log into different websites (ie., help diagnose and resolve why a student may not be able to sign into Google or other platforms)"
              boolean
            />
          </div>
        </div>
      ) : null}

      <RadioField
        name="highSpeedInternet"
        label="Do you have a computer/laptop (with webcam) and high-speed reliable internet?"
        boolean
      />
      <RadioField
        name="hasTablet"
        label="Do you have an iPad, Surface, a writing pad, or any other touchscreen device?"
        boolean
      />
      {hasTabletField.value === false && (
        <RadioField
          name="willPurchaseTablet"
          label="Would you be willing to purchase an iPad, Surface, or other touchscreen device?"
          boolean
        />
      )}
    </>
  )
}

export default Technology
