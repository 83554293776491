import React, { useCallback, useEffect, useState } from "react"

import { FieldArray } from "formik"

import axios from "src/axios"

import Pagination from "components/Pagination"

type QuestionOptionsProps = {
  values: {
    questionIds: number[]
  }
  tags: string[]
}

const QuestionOptions: React.FC<QuestionOptionsProps> = ({ values, tags }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [questions, setQuestions] = useState([])

  const loadQuestions = useCallback(
    page => {
      axios
        .get("/api/admins/tailored_questions", {
          params: { page, tags },
        })
        .then(response => {
          setQuestions(response.data.data)
          setTotalPages(response.data.totalPages)
          setCurrentPage(response.data.currentPage)
        })
    },
    [tags]
  )

  useEffect(() => {
    loadQuestions(currentPage)
  }, [loadQuestions, currentPage])

  return (
    <FieldArray name="questionIds">
      {arrayHelpers => (
        <React.Fragment>
          {questions.map(({ id, type, text, image }) => (
            <div className="form-check" key={id}>
              <label className="form-check-label">
                {type === "text" ? (
                  text
                ) : (
                  <img src={image.url} alt="question" />
                )}
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={id}
                  defaultChecked={values.questionIds.includes(id)}
                  onChange={e =>
                    e.target.checked
                      ? arrayHelpers.push(id)
                      : arrayHelpers.remove(values.questionIds.indexOf(id))
                  }
                />
                <span className="form-check-sign" />
              </label>
            </div>
          ))}
          <div className="row">
            <div className="col-4">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                changePage={loadQuestions}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </FieldArray>
  )
}

export default QuestionOptions
