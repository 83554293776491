import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { DateObject } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import { SessionDateField } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  sessionDate: Yup.number().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

const RescheduleSessionButton = ({ id, startsAt, durationMinutes }) => {
  const { runMutation } = useMutation(buildMutation(rescheduleSessionMutation))

  const formState = {
    id: id,
    sessionDate: new DateObject(new Date(startsAt)),
    durationMinutes: durationMinutes,
  }

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values

    runMutation({ sessionDate: sessionDate.toDate(), ...rest }).then(
      response => {
        const { airtutorsSession, errorMessages } =
          response.rescheduleAirtutorsSession
        if (airtutorsSession) {
          window.location.reload()
        } else if (errorMessages) {
          actions.setStatus(errorMessages)
          actions.setSubmitting(false)
        }
      }
    )
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-info"
      buttonText="Reschedule"
      modalTitle="Reschedule Session"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <Formik
            initialValues={formState}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ status, isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="modal-body">
                  <div
                    className="form-group mb-4"
                    css={css`
                      min-height: 75px;
                    `}
                  >
                    <SessionDateField
                      value={values.sessionDate}
                      name="sessionDate"
                      label="Session Date"
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      Duration in minutes
                      <Field
                        name="durationMinutes"
                        className="form-control"
                        type="number"
                        min="15"
                        step="15"
                      />
                    </label>
                    <ErrorMessage name="durationMinutes" />
                  </div>
                </div>

                <div className="modal-footer flex-col">
                  {status && <div className="alert alert-danger">{status}</div>}
                  <SubmitButton isSubmitting={isSubmitting} text="Reschedule" />
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

const rescheduleSessionMutation = compress`
  mutation($id: ID!, $sessionDate: DateTime!, $durationMinutes: Int!) {
    rescheduleAirtutorsSession(id: $id, sessionDate: $sessionDate, durationMinutes: $durationMinutes) {
      errorMessages
      airtutorsSession {
        showPath
      }
    }
  }
`

export default RescheduleSessionButton
