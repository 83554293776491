import React, { useState } from "react"

import { Form, Formik } from "formik"
import { compress } from "micro-graphql-react"

import { adminClient as client } from "src/graphql-config"

import { useGradeOptions } from "hooks/remoteTable"

import { ErrorMessage, requireInput } from "components/Forms/Formik"
import {
  AutocompleteField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import Results from "./Results"

const AssignStudents = ({ availability, organizationId }) => {
  const formState = {
    tagList: [],
    schoolId: [],
    gradeId: [],
    studentFullName: "",
  }

  const [students, setStudents] = useState()
  const gradeOptions = useGradeOptions()

  const performSearch = (values, actions) => {
    client
      .runQuery(studentSearchQuery, { organizationId, ...values })
      .then(
        response => {
          setStudents(response.data.students)
        },
        () => {
          actions.setStatus("An error occurred")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("An error occurred")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Assign Availability"
      buttonText="Assign Students"
      buttonClassName="ml-3 btn btn-sm btn-info"
      afterClose={() => setStudents()}
    >
      {({ closeModal }) =>
        students ? (
          <Results
            availabilityId={availability.id}
            students={students}
            closeModal={closeModal}
          />
        ) : (
          <Formik
            initialValues={formState}
            onSubmit={performSearch}
            validate={requireInput}
          >
            <Form>
              <div className="modal-body">
                <AutocompleteField
                  name="tagList"
                  label="Search by Tag"
                  api="/api/admins/students/autocomplete_tags"
                  valueAttribute="label"
                  isMulti
                />
                <AutocompleteField
                  name="schoolIds"
                  label="Schools"
                  api="/api/admins/schools/autocomplete_name"
                  isMulti
                />
                <SelectField
                  name="gradeIds"
                  label="Grades"
                  options={gradeOptions}
                  isClearable
                  isMulti
                  menuPortalTarget={document.body}
                />
                <Field name="studentFullName" label="Student Name" />
              </div>

              <div className="modal-footer flex-col">
                <FormStatus />
                <ErrorMessage name="base" touched={true} />
                <div className="d-flex justify-content-between w-full">
                  <button
                    className="btn btn-danger"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                  <SubmitButton text="Search" />
                </div>
              </div>
            </Form>
          </Formik>
        )
      }
    </ModalWithProvidedBody>
  )
}

const studentSearchQuery = compress`
  query($tagList: [String!], $organizationIds: [ID!], $schoolIds: [ID!], $gradeIds: [ID!], $studentFullName: String) {
    students(
      tagList: $tagList
      organizationIds: $organizationIds
      schoolIds: $schoolIds
      gradeIds: $gradeIds
      studentFullName: $studentFullName
    ) {
      id
      fullName
      lastName
      phoneNumber
      parentPhoneNumber
    }
  }
`

export default AssignStudents
