import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

class AutocompleteSubject extends React.Component {
  constructor(props) {
    super(props)
    this.state = { subjectId: this.props.subjectId }
  }

  onChange = subjectId => {
    this.setState({ subjectId: subjectId ? subjectId.value : "" })
    if (this.props.onChange) this.props.onChange(subjectId)
  }

  render() {
    return (
      <div className="form-group">
        <label className="form-control-label">Subject</label>
        <input
          type="hidden"
          value={this.state.subjectId}
          name={this.props.name}
        />
        <AutocompleteSelect
          onChange={this.onChange}
          api={"/api/admins/subjects/autocomplete_name"}
          selected={{
            value: this.props.subjectId,
            label: this.props.subjectName,
          }}
          clearableSelects={[]}
        />
      </div>
    )
  }
}

AutocompleteSubject.defaultProps = {
  subjectId: "",
  subjectName: "",
}

export default AutocompleteSubject
