import React, { useState } from "react"

import { gql } from "urql"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useQuery } from "hooks/urql"

import BasicDisplay from "components/BasicDisplay"
import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import AddPoAttachment from "./AddPoAttachment"
import EditBudget from "./Edit"
import PoAttachment from "./PoAttachment"
import RemoveBudget from "./RemoveBudget"
import StatementsTable from "./StatementsTable"

const BudgetDetails = ({ budgetId, newStatementPath }) => {
  const [studyGroupsPage, setStudyGroupsPage] = useState(1)
  const [result] = useQuery({
    query: budgetQuery,
    variables: { budgetId, studyGroupsPage },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { budget } }) => (
        <>
          {budget.statementCount === 0 && <RemoveBudget budget={budget} />}
          <EditBudget budget={budget} />
          <BasicDisplay
            label="Name"
            labelClassName="!mt-0"
            value={budget.name}
          />
          <BasicDisplay
            label={
              <>
                Active
                {budget.active ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="ml-3 text-green-600"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="ml-3 text-red-500"
                  />
                )}
              </>
            }
          />
          <BasicDisplay label="Date Range">
            <LocalTime timestamp={budget.startsOn} omitTime /> &mdash;{" "}
            <LocalTime timestamp={budget.endsOn} omitTime />
          </BasicDisplay>
          <BasicDisplay label="PO Number" value={budget.poNumber} />
          <BasicDisplay
            label={
              <>
                Prepaid
                {budget.prepaid ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="ml-3 text-green-600"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="ml-3 text-red-500"
                  />
                )}
              </>
            }
          />
          <BasicDisplay
            label="Original Amount"
            value={budget.formattedOriginalAmount}
          />
          <BasicDisplay label="Current Balance">
            {budget.balance < 0 ? (
              <span className="text-red-500">{budget.formattedBalance}</span>
            ) : (
              budget.formattedBalance
            )}
          </BasicDisplay>
          <BasicDisplay label="PO Attachments">
            <ul className="list-unstyled">
              {budget.poAttachments.map(poAttachment => (
                <PoAttachment
                  key={poAttachment.id}
                  budget={budget}
                  poAttachment={poAttachment}
                />
              ))}
            </ul>
            <AddPoAttachment budgetId={budget.id} />
          </BasicDisplay>
          <BasicDisplay label="Notes">
            <span
              dangerouslySetInnerHTML={{
                __html: budget.formattedNotes,
              }}
            />
          </BasicDisplay>

          <BasicDisplay label="Linked Study Groups">
            <ul className="list-unstyled">
              {budget.paginatedStudyGroups.data.map(studyGroup => (
                <li className="ml-4" key={studyGroup.id}>
                  <a href={studyGroup.showPath}>{studyGroup.name}</a>
                </li>
              ))}
            </ul>
            <Pagination
              currentPage={budget.paginatedStudyGroups.currentPage}
              totalPages={budget.paginatedStudyGroups.totalPages}
              changePage={setStudyGroupsPage}
            />
          </BasicDisplay>

          <hr />

          <StatementsTable
            budgetId={budgetId}
            newStatementPath={newStatementPath}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const budgetQuery = gql`
  query ($budgetId: ID!, $studyGroupsPage: Int!) {
    budget(id: $budgetId) {
      id
      name
      active
      poNumber
      prepaid
      notes
      formattedNotes
      startsOn
      endsOn
      balance
      formattedBalance
      formattedOriginalAmount
      statementCount
      poAttachments {
        id
        filename
        downloadUrl
      }
      paginatedStudyGroups(page: $studyGroupsPage) {
        totalPages
        currentPage
        data {
          id
          name
          showPath
        }
      }
      formattedOriginalAmount
      formattedBalance
    }
  }
`

export default BudgetDetails
