import React from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { css } from "@emotion/core"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import EditableList from "components/EditableList"
import EditableDisplay from "components/Forms/EditableDisplay"
import RemoveButton from "components/RemoveButton"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import AddConsentDocument from "./AddConsentDocument"

const OrganizationConsentDocumentation = ({ organizationId }) => {
  const loadingState = useQuery(
    buildQuery(
      consentDocumentationQuery,
      { organizationId },
      {
        onMutation: [
          {
            when: "organizationUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.organizationUpdate
              if (!organization) return
              currentResults.organization.consentText = organization.consentText
              currentResults.organization.parentConsentRequired =
                organization.parentConsentRequired
              currentResults.organization.studentConsentRequired =
                organization.studentConsentRequired
              softReset(currentResults)
            },
          },
          {
            when: "organizationAddConsentDocument",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.organizationAddConsentDocument
              if (!organization) return
              currentResults.organization.consentDocuments =
                organization.consentDocuments
              softReset(currentResults)
            },
          },
          {
            when: "organizationRemoveConsentDocument",
            run: ({ softReset, currentResults }, response) => {
              const { organization } =
                response.organizationRemoveConsentDocument
              if (!organization) return
              currentResults.organization.consentDocuments =
                organization.consentDocuments
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const { runMutation } = useMutation(buildMutation(updateOrganizationMutation))
  const { runMutation: runRemoveDocument } = useMutation(
    buildMutation(removeDocumentMutation)
  )

  const removeDocument = attachmentId => {
    runRemoveDocument({ organizationId, attachmentId })
      .then(
        response => {
          const { errorMessages } = response
          if (errorMessages) {
            alert(errorMessages)
          }
        },
        () => alert("Something went wrong")
      )
      .catch(() => alert("Something went wrong"))
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h4>Consent Documentation</h4>}
    >
      {({ data }) => (
        <>
          <div
            css={css`
              font-weight: 600;
            `}
          >
            Consent Text
          </div>
          <EditableDisplay
            displayValue={data.organization.consentText || ""}
            save={({ value }) =>
              runMutation({ organizationId, consentText: value })
            }
          />

          <div
            css={css`
              font-weight: 600;
            `}
          >
            Student Assent Required?
          </div>
          <EditableDisplay
            type="checkbox"
            displayValue={
              data.organization.studentConsentRequired ? (
                <FontAwesomeIcon icon={faCheck} className="text-success" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-danger" />
              )
            }
            editableValue={data.organization.studentConsentRequired}
            save={({ value }) =>
              runMutation({
                organizationId,
                studentConsentRequired: value,
              })
            }
          />

          <div
            css={css`
              font-weight: 600;
            `}
          >
            Parent Consent Required?
          </div>
          <EditableDisplay
            type="checkbox"
            displayValue={
              data.organization.parentConsentRequired ? (
                <FontAwesomeIcon icon={faCheck} className="text-success" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-danger" />
              )
            }
            editableValue={data.organization.parentConsentRequired}
            save={({ value }) =>
              runMutation({
                organizationId,
                parentConsentRequired: value,
              })
            }
          />

          <div
            css={css`
              font-weight: 600;
            `}
          >
            Documents
          </div>
          <EditableList>
            {ordered(data.organization.consentDocuments, "filename").map(
              ({ id, downloadUrl, filename }) => (
                <li key={id}>
                  <RemoveButton onClick={() => removeDocument(id)} />
                  <a
                    href={downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {filename}
                  </a>
                </li>
              )
            )}
          </EditableList>
          <AddConsentDocument organizationId={organizationId} />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const consentDocumentationQuery = compress`
  query($organizationId: ID!) {
    organization(id: $organizationId) {
      consentText
      parentConsentRequired
      studentConsentRequired
      consentDocuments {
        id
        downloadUrl
        filename
      }
    }
  }
`

const updateOrganizationMutation = compress`
  mutation($organizationId: ID!, $consentText: String, $parentConsentRequired: Boolean, $studentConsentRequired: Boolean) {
    organizationUpdate(
      organizationId: $organizationId
      consentText: $consentText
      parentConsentRequired: $parentConsentRequired
      studentConsentRequired: $studentConsentRequired
    ) {
      errorMessages
      organization {
        consentText
        parentConsentRequired
        studentConsentRequired
      }
    }
  }
`

const removeDocumentMutation = compress`
  mutation($organizationId: ID!, $attachmentId: ID!) {
    organizationRemoveConsentDocument(organizationId: $organizationId, attachmentId: $attachmentId) {
      errorMessages
      organization {
        consentDocuments {
          id
          downloadUrl
          filename
        }
      }
    }
  }
`

export default OrganizationConsentDocumentation
