import React from "react"

import Button from "./Button"

const VideoButton = props => {
  const { room, channel, disabled, muted, setMuted, controls } = props

  room.localParticipant.videoTracks.forEach(trackPublication => {
    if (muted) {
      trackPublication.track.disable()
    } else {
      trackPublication.track.enable()
    }
  })

  const toggle = () => {
    if (disabled) return
    room.localParticipant.videoTracks.forEach(trackPublication => {
      if (muted) {
        trackPublication.track.enable()
        channel && channel.perform("unmute_self", { type: "video" })
        setMuted(false)
      } else {
        trackPublication.track.disable()
        channel && channel.perform("mute_self", { type: "video" })
        setMuted(true)
      }
    })
  }

  if (!controls) return null

  return <Button onClick={toggle} muted={muted} disabled={disabled} />
}

VideoButton.defaultProps = {
  controls: true,
}

export default VideoButton
