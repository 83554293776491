import React, { useEffect, useRef, useState } from "react"

import { Form, Formik } from "formik"
import moment from "moment-timezone"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import createBlob from "src/createBlob"
import client from "src/urql-client"

import {
  DateRangeField,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const addParams = (params, prefix, dateRange = []) => {
  const [startsAt, endsAt] = dateRange
  if (startsAt) {
    params[`${prefix}StartsAt`] = moment(startsAt.toDate())
      .startOf("day")
      .format()
    params[`${prefix}EndsAt`] = moment(startsAt.toDate()).endOf("day").format()
  }
  if (endsAt) {
    params[`${prefix}EndsAt`] = moment(endsAt.toDate()).endOf("day").format()
  }

  return params
}

const ExpensesExport = () => {
  const formState = {
    dateRange: [],
  }
  const [csvUrl, setCsvUrl] = useState()
  const csvDownloadLink = useRef()

  const handleExport = (values, actions, closeModal) => {
    const {
      createdDateRange,
      approvedDateRange,
      deniedDateRange,
      paidDateRange,
    } = values
    let params = {}

    params = addParams({ ...params }, "created", createdDateRange)
    params = addParams({ ...params }, "approved", approvedDateRange)
    params = addParams({ ...params }, "denied", deniedDateRange)
    params = addParams({ ...params }, "paid", paidDateRange)

    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, { searchInput: params }),
      subscribe(result => {
        if (result.data?.expensesExport?.status === "done") {
          const blobUrl = createBlob(result.data.expensesExport.csv, "text/csv")
          setCsvUrl(blobUrl)
          actions.setSubmitting(false)
          closeModal()
          unsubscribe()
        } else if (result.data?.expensesExport?.status) {
          actions.setStatus(result.data.expensesExport.status)
        }

        if (result.data?.expensesExport?.errorMessages?.length) {
          handleFailure(
            actions,
            result.data.expensesExport.errorMessages.map(e => ({ message: e }))
          )

          actions.setSubmitting(false)
          unsubscribe()
        }

        if (result.error) {
          handleFailure(actions, [{ message: result.error?.message }])
          actions.setSubmitting(false)
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl()
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download={`airtutors sessions export.csv`}
        className="hidden"
      >
        csv
      </a>

      <ModalWithProvidedBody
        modalTitle="Expense Export"
        buttonText="Export"
        buttonClassName="btn btn-info"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            onSubmit={(values, actions) =>
              handleExport(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <DateRangeField
                  name="createdDateRange"
                  label="Created Date Range"
                />
                <DateRangeField
                  name="approvedDateRange"
                  label="Approved Date Range"
                />
                <DateRangeField
                  name="deniedDateRange"
                  label="Denied Date Range"
                />
                <DateRangeField name="paidDateRange" label="Paid Date Range" />
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <SubmitButton text="Export" />
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const exportSubscription = gql`
  subscription ($searchInput: ExpenseSearchInputObject) {
    expensesExport(searchInput: $searchInput) {
      status
      csv
      errorMessages
    }
  }
`

export default ExpensesExport
