import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { tutorClient } from "src/graphql-config"
import ordered from "src/ordered"

import LessonPlan from "components/LessonPlan"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const StudentLessonPlans = ({ studentId }) => {
  const loadingState = useQuery(
    buildQuery(lessonPlansQuery, { studentId }, { client: tutorClient })
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <div>
            {ordered(
              data.student.lessonPlanAssignments,
              "lessonPlan",
              "name"
            ).map(lessonPlanAssignment => (
              <React.Fragment key={lessonPlanAssignment.id}>
                <div>
                  <LessonPlan lessonPlanAssignment={lessonPlanAssignment} />
                </div>
                <hr />
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const lessonPlansQuery = compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      lessonPlanAssignments {
        id
        lessonPlan {
          id
          name

          lessonTopics {
            id
            name
            lessonResources {
              id
              name
              completed(studentId: $studentId)
              current(studentId: $studentId)
              lessonResourceCompletions(studentId: $studentId) {
                id
                createdAt
                studentMastery
                session {
                  showPath
                  startsAt
                  tutor {
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StudentLessonPlans
