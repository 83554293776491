import React, { useState } from "react"

import { useMutation } from "hooks/urql"

const ImportOptions = ({
  organizationId,
  mutation,
  provider,
  providerDisplayName,
  closeModal,
}) => {
  const [failures, setFailures] = useState([])
  const [running, setRunning] = useState(false)
  const [, runMutation] = useMutation(mutation)

  const doImport = () => {
    setRunning(true)
    runMutation({ organizationId })
      .then(
        response => {
          const dataKey = `organization${provider}Import`
          const { failures, redirectTo } = response.data[dataKey]
          if (failures) {
            setFailures(failures)
            setRunning(false)
          } else {
            window.location = redirectTo
          }
        },
        () => {
          setFailures([{ message: "Something went wrong" }])
          setRunning(false)
        }
      )
      .catch(() => {
        setFailures([{ message: "Something went wrong" }])
        setRunning(false)
      })
  }

  return (
    <div>
      <>
        <div className="modal-body">
          <p className="alert alert-warning font-medium">
            This will perform a bulk import of user and course data from&nbsp;
            {providerDisplayName}.
          </p>

          <p className="alert alert-info">
            Please note: this process will take an indeterminate amount of time.
            Once you click Import below, you will be redirected to the&nbsp;
            {providerDisplayName} users index page which will be blank at this
            time. Just refresh after a few minutes or come back later. Maybe go
            get some coffee? I hear thats what you corporeal beings like to
            drink!
          </p>
        </div>

        <div className="modal-footer w-full flex-col">
          <div className="flex w-full justify-between">
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={doImport}
              disabled={running}
            >
              Import
            </button>
          </div>

          <div className="w-full">
            {failures.map(failure => (
              <div key={failure.message} className="alert alert-danger">
                {failure.message}
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  )
}

export default ImportOptions
