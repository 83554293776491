import React, { useRef } from "react"

import { useField, useFormikContext } from "formik"
import Select from "react-select"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import { ErrorMessage } from "components/Forms/Formik"

const TeacherSubject = ({ teachers, teacherSubject, arrayHelpers, index }) => {
  const { values } = useFormikContext()
  const [schoolField] = useField("schoolId")
  const subjectSelect = useRef()

  let permittedTeachers = teachers
  if (schoolField.value) {
    const matchingTeachers = teachers.filter(
      teacher =>
        teacher.schools.filter(school => school.id === schoolField.value)
          .length > 0
    )

    if (matchingTeachers.length > 0) permittedTeachers = matchingTeachers
  }

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-top: 15px;
        `}
      >
        <div
          css={css`
            flex-basis: 33%;
          `}
        >
          <Select
            placeholder="Select Teacher"
            options={ordered(permittedTeachers, "lastName")}
            getOptionLabel={option =>
              [option.lastName, option.firstName].filter(Boolean).join(", ")
            }
            getOptionValue={option => option.id}
            onChange={selectedOption => {
              if (!selectedOption?.id) {
                arrayHelpers.replace(
                  values.teacherSubjects.findIndex(
                    s => s.uuid === teacherSubject.uuid
                  ),
                  {
                    uuid: teacherSubject.uuid,
                    teacher: null,
                  }
                )
                return
              }
              arrayHelpers.replace(
                values.teacherSubjects.findIndex(
                  s => s.uuid === teacherSubject.uuid
                ),
                {
                  uuid: teacherSubject.uuid,
                  teacher: selectedOption,
                }
              )
              subjectSelect.current && subjectSelect.current.select.clearValue()
            }}
            isClearable
          />
        </div>

        <div
          css={css`
            flex-basis: 33%;
            margin-left: 30px;
          `}
        >
          {teacherSubject.teacher ? (
            <>
              <Select
                ref={subjectSelect}
                placeholder="Select Subject"
                options={ordered(
                  teacherSubject.teacher.teacherSubjects,
                  "subject",
                  "name"
                )}
                getOptionLabel={option => option.subject.name}
                getOptionValue={option => option.id}
                onChange={selectedOption => {
                  if (!selectedOption?.id) return
                  arrayHelpers.replace(
                    values.teacherSubjects.findIndex(
                      s => s.uuid === teacherSubject.uuid
                    ),
                    {
                      uuid: teacherSubject.uuid,
                      id: selectedOption.id,
                      teacher: teacherSubject.teacher,
                      subject: selectedOption.subject,
                    }
                  )
                }}
              />
            </>
          ) : (
            <button
              onClick={() =>
                arrayHelpers.remove(
                  values.teacherSubjects.findIndex(
                    s => s.uuid === teacherSubject.uuid
                  )
                )
              }
              className="btn solid red"
            >
              Remove
            </button>
          )}
        </div>
      </div>

      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            flex-basis: 33%;
          `}
        >
          <ErrorMessage
            fieldName={`teacherSubjects[${index}].uuid`}
            name={`teacherSubjects[${index}].teacher`}
          />
        </div>
        <div
          css={css`
            flex-basis: 33%;
            margin-left: 30px;
          `}
        >
          {teacherSubject.teacher && (
            <ErrorMessage
              fieldName={`teacherSubjects[${index}].uuid`}
              name={`teacherSubjects[${index}].subject`}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default TeacherSubject
