import React, { useRef } from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import "react-table/react-table.css"

const AdminTutorApplicationInterviewersTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/tutor_application_interviewers",
  })
  const nameSelect = useRef(null)
  const adminSelect = useRef(null)
  const calendlyUrlSelect = useRef(null)

  const clearableSelects = [
    nameSelect.current,
    adminSelect.current,
    calendlyUrlSelect.current,
  ]

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={nameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutor_application_interviewers/autocomplete_name"
          />
        )
      },
    },
    {
      id: "admin",
      Header: "Admin",
      accessor: "admin.full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={adminSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/admins/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "calendly_url",
      Header: "Calendly URL",
      accessor: "calendly_url",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={calendlyUrlSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutor_application_interviewers/autocomplete_calendly_url"
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="d-flex justify-content-center flex-wrap">
          <a href={props.value.show_path} className="btn btn-sm btn-info">
            Details
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminTutorApplicationInterviewersTable
