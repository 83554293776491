import React from "react"

const SubHeader = ({ title }) => {
  return (
    <h1 className="py-5 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
      {title}
    </h1>
  )
}

export default SubHeader
