import React from "react"

import { useField, useFormikContext } from "formik"

import { regionOptions } from "src/enums"

import {
  AutocompleteField,
  Field,
  RadioField,
  SelectField,
} from "components/Forms/Formik/hookComponents"

const Country = () => {
  const { setFieldValue } = useFormikContext()
  const [usaField] = useField("inUsa")
  const [regionField] = useField("region")
  const [countryField] = useField("country")

  return (
    <>
      <RadioField
        name="inUsa"
        label="Do you live in the United States?"
        boolean
      />

      {usaField.value === true ? (
        <SelectField
          name="region"
          label="Select State"
          options={regionOptions.filter(r => r.value !== "Other")}
          defaultValue={regionOptions.find(
            region => region.value === regionField.value
          )}
        />
      ) : (
        <>
          <AutocompleteField
            api="/api/public/countries/autocomplete_name"
            name="country"
            label="Country"
            creatable
            defaultValue={
              countryField.value
                ? { label: countryField.value, value: countryField.value }
                : null
            }
            onChange={selected => setFieldValue("country", selected?.label)}
          />
          <Field
            name="region"
            label="State/Region/Province (no abbreviations)"
          />
        </>
      )}
    </>
  )
}

export default Country
