import React from "react"

import { compress } from "micro-graphql-react"

import { studyGroupFields, teacherSubjectFields } from "./Shared/fragments"

import GeneratedStudyGroups from "./GeneratedStudyGroups"

const AllStudyGroupGenerations = ({ organizationId }) => {
  return (
    <GeneratedStudyGroups
      query={groupsQuery}
      organizationId={organizationId}
      title="All Generations"
    />
  )
}

const groupsQuery = compress`
  ${studyGroupFields}
  ${teacherSubjectFields}
  query($id: ID!, $page: Int!) {
    organization(id: $id) {
      studyGroupGenerations(page: $page) {
        currentPage
        totalPages
        data {
          id
          minimumGroupSize
          maximumGroupSize
          sessionsPerWeek
          excusedAbsencesEnabled
          requestStudyGroupConfirmations
          canExceedScheduledDuration
          inSchool
          allowLongSessions
          startsOn
          endsOn
          maximumScoreDeviation
          assessmentSection {
            id
            name
            assessment {
              id
              name
            }
          }
          studyGroups {
            ...StudyGroupFields
          }
          teacherSubject {
            ...TeacherSubjectFields
          }
        }
      }
    }
  }
`

export default AllStudyGroupGenerations
