import React from "react"

import PropTypes from "prop-types"

import { tutorClient } from "src/graphql-config"

import TailoredAssignmentPreview from "components/TailoredAssignmentPreview"

const TutorTailoredAssignmentPreview = props => {
  const { id } = props
  return <TailoredAssignmentPreview id={id} client={tutorClient} />
}

TutorTailoredAssignmentPreview.propTypes = {
  id: PropTypes.number.isRequired,
}

export default TutorTailoredAssignmentPreview
