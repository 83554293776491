import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  to: Yup.string().required(),
  message: Yup.string().required(),
})

const SendEmail = ({ organization, statement, small = false }) => {
  const formState = {
    statementId: statement.id,
    to: organization.statementDefaultTo,
    message: organization.statementDefaultMessage,
  }
  const [, sendEmail] = useMutation(sendEmailMutation)

  const handleSubmit = (values, actions, closeModal) => {
    const variables = { ...values }
    variables.to = variables.to.split(",").map(s => s.trim())
    sendEmail(variables)
      .then(
        response => {
          const { failures } = response.data.sendStatementEmail
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Send Statement Email"
      buttonText={`Send Email${statement.maySubmit ? " & Mark Submitted" : ""}`}
      buttonClassName={`btn btn-info ${
        small ? "btn-sm" : null
      } whitespace-normal`}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              {statement.maySubmit && (
                <>
                  <p>
                    Once submitted, you will be able to mark the statement as
                    paid by uploading a payment receipt.
                  </p>
                  <p>
                    Submitting will mark the statement as submitted and update
                    the associated budget's remaining balance.
                  </p>
                </>
              )}
              <Field name="to" label="To (comma-separated)" />
              <TextAreaField
                name="message"
                label="Message"
                rows={5}
                className="!max-h-full"
              />
              <p className="rounded bg-purple-500 p-3 font-medium text-white">
                Statement PDF and sessions CSV for invoice{" "}
                {statement.invoiceNumber} will be attached to this email
              </p>
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton text="Send Email" />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const sendEmailMutation = gql`
  mutation ($statementId: ID!, $to: [String!]!, $message: String!) {
    sendStatementEmail(statementId: $statementId, to: $to, message: $message) {
      statement {
        id
      }
      failures {
        message
      }
    }
  }
`

export default SendEmail
