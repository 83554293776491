import React from "react"

import { useHistory } from "react-router-dom"

import { Box, Heading, PseudoBox } from "@chakra-ui/core"

import CellHeader from "./CellHeader"
import DateFilter from "./DateFilter"

const Wrapper = props => {
  const {
    rowComponent: RowComponent,
    data,
    title,
    columns,
    order,
    filters,
    filterKey,
    orderKey,
  } = props

  const history = useHistory()

  const startsAtFilter = filters.find(f => f.field === "startsAt")
  const endsAtFilter = filters.find(f => f.field === "endsAt")

  const updateOrder = order => {
    const locationCopy = { ...history.location }
    const searchParams = new URLSearchParams(locationCopy.search)

    if (order) {
      searchParams.set(
        orderKey,
        JSON.stringify({ field: order.field, direction: order.direction })
      )
    }
    locationCopy.search = searchParams.toString()
    history.replace(locationCopy)
  }

  const updateFilter = filters => {
    let filterArray = filters
    if (!Array.isArray(filters)) {
      filterArray = [filters]
    }

    const newFilters = [...props.filters]

    filterArray.forEach(({ field, value }) => {
      const filterIndex = newFilters.findIndex(f => f.field === field)
      if (filterIndex > -1) {
        newFilters[filterIndex] = { ...newFilters[filterIndex], value }
      } else {
        newFilters.push({ field, value })
      }
    })

    const locationCopy = { ...history.location }
    const searchParams = new URLSearchParams(locationCopy.search)
    searchParams.set(filterKey, JSON.stringify(newFilters))
    locationCopy.search = searchParams.toString()
    history.replace(locationCopy)
  }

  const removeFilter = field => {
    const fieldsArray = [field]
    if (field === "startsAt") {
      fieldsArray.push("endsAt")
    }

    const newFilters = props.filters.filter(f => !fieldsArray.includes(f.field))
    const locationCopy = { ...history.location }
    const searchParams = new URLSearchParams(locationCopy.search)
    searchParams.set(filterKey, JSON.stringify(newFilters))
    locationCopy.search = searchParams.toString()
    history.replace(locationCopy)
  }

  return (
    <>
      <Heading
        fontSize="3xl"
        position="absolute"
        height="16"
        maxW="50%"
        isTruncated
      >
        {title}
      </Heading>
      <Box as="table" minWidth="containers.lg" w="full" mt="16">
        <thead>
          <tr>
            {columns.map(column => (
              <PseudoBox
                as="th"
                w={column.width}
                key={column.title}
                pl="4"
                borderRight="2px"
                borderRightColor="gray.200"
                _last={{ borderRight: "none" }}
              >
                <CellHeader
                  field={column.field}
                  filter={
                    column.isFilterable &&
                    filters.find(f => f.field === column.field)
                  }
                  order={order}
                  orderDisabled={!column.isOrderable}
                  filterElement={
                    column.isDateFilter && (
                      <DateFilter
                        startsAt={startsAtFilter && startsAtFilter.value}
                        endsAt={endsAtFilter && endsAtFilter.value}
                        updateFilter={updateFilter}
                        semester={column.semester}
                      />
                    )
                  }
                  isDateFilter={column.isDateFilter}
                  updateOrder={updateOrder}
                  updateFilter={updateFilter}
                  removeFilter={removeFilter}
                >
                  {typeof column.isDateFilter === "object"
                    ? column.isDateFilter.label
                    : column.title}
                </CellHeader>
              </PseudoBox>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <PseudoBox
              key={row.id}
              as="tr"
              border="1px"
              borderColor="transparent"
              borderBottomColor="gray.200"
              _last={{ border: "none" }}
            >
              <RowComponent data={row} />
            </PseudoBox>
          ))}
        </tbody>
      </Box>
    </>
  )
}

export default Wrapper
