import React, { useState } from "react"

import { timezoneOptions } from "src/enums"

import { gql, useMutation } from "hooks/urql"

import EditableDisplay from "components/Forms/EditableDisplay"

const TeacherEmailTimeZone = ({
  teacherId,
  emailTimeZone: initialEmailTimeZone,
}) => {
  const [emailTimeZone, setEmailTimeZone] = useState(initialEmailTimeZone)
  const [, runMutation] = useMutation(updateTeacherMutation)

  const updateTeacher = ({ value }) =>
    runMutation({ teacherId, emailTimeZone: value }).then(response => {
      const { teacher } = response.data.teacherSetEmailTimeZone
      if (teacher) {
        setEmailTimeZone(teacher.emailTimeZone)
      } else {
        alert("Unable to update teacher. Please ensure the record is valid.")
      }
    })

  return (
    <EditableDisplay
      displayValue={emailTimeZone}
      type="select"
      selectOptions={timezoneOptions}
      save={updateTeacher}
    />
  )
}

const updateTeacherMutation = gql`
  mutation ($teacherId: ID!, $emailTimeZone: TimeZoneEnum!) {
    teacherSetEmailTimeZone(
      teacherId: $teacherId
      emailTimeZone: $emailTimeZone
    ) {
      teacher {
        id
        emailTimeZone
      }
    }
  }
`

export default TeacherEmailTimeZone
