import React, { useState } from "react"

import { css } from "@emotion/core"
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import EditSubject from "./EditSubject"
import EditSubjectBucket from "./EditSubjectBucket"
import NewSubject from "./NewSubject"
import RemoveSubject from "./RemoveSubject"
import RemoveSubjectBucket from "./RemoveSubjectBucket"

const SubjectBucketRow = ({ subjectBucket, tier }) => {
  const [subjectsVisible, setSubjectsVisible] = useState(false)

  return (
    <>
      <tr>
        <td>
          <button
            className="btn btn-simple d-flex align-items-center w-100 border-0"
            onClick={() => setSubjectsVisible(v => !v)}
          >
            <FontAwesomeIcon
              icon={subjectsVisible ? faCaretDown : faCaretRight}
              className="mr-1"
              css={css`
                font-size: 18px;
              `}
            />
            {subjectBucket.name}
          </button>
        </td>
        <td>{subjectBucket.subjectType}</td>
        <td>{subjectBucket.collaborationType}</td>
        <td>{subjectBucket.formattedHourlyRate}</td>
        <td>
          <div className="mb-1">
            <EditSubjectBucket subjectBucket={subjectBucket} tier={tier} />
          </div>
          <div className="mb-1">
            <RemoveSubjectBucket subjectBucket={subjectBucket} />
          </div>
          <NewSubject subjectBucket={subjectBucket} />
        </td>
      </tr>
      {subjectsVisible && (
        <tr>
          <td colSpan="4">
            <table className="table">
              <thead className="text-secondary">
                <tr>
                  <th width="33%">Name</th>
                  <th width="33%">Organization</th>
                  <th width="33%" colSpan="3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordered(subjectBucket.subjects, "name").map(subject => (
                  <tr key={subject.id}>
                    <td>{subject.name}</td>
                    <td>
                      {subject.organization ? subject.organization.name : "Any"}
                    </td>
                    <td>
                      <div className="mb-1">
                        <EditSubject
                          subject={subject}
                          subjectBucket={subjectBucket}
                          tier={tier}
                        />
                      </div>
                      <RemoveSubject subject={subject} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  )
}

export default SubjectBucketRow
