import React, { useEffect } from "react"

import { useFormikContext } from "formik"

import { CheckboxField } from "components/Forms/Formik/hookComponents"

const ImportAllCheckboxField = ({ userIdsKey, allUserIds }) => {
  const { values, setFieldValue } = useFormikContext()
  const { importAll } = values
  const selectedUserIds = values[userIdsKey]

  useEffect(() => {
    if (selectedUserIds.toString() !== allUserIds.toString())
      setFieldValue("importAll", false)
  }, [allUserIds, selectedUserIds, setFieldValue])

  const handleImportAllChange = () => {
    if (importAll) {
      setFieldValue("importAll", false)
      setFieldValue(userIdsKey, [])
    } else {
      setFieldValue("importAll", true)
      setFieldValue(userIdsKey, allUserIds)
    }
  }

  return (
    <CheckboxField
      onChange={handleImportAllChange}
      name="importAll"
      label="Import All"
    />
  )
}

export default ImportAllCheckboxField
