import React, { useEffect, useMemo, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import EventEmitter from "src/EventEmitter"
import { parentClient, studentClient, tutorClient } from "src/graphql-config"

import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"

const RequestProxyNumber = ({ tutorId, studentId, userType }) => {
  const client =
    userType === "Tutor"
      ? tutorClient
      : userType === "Student"
      ? studentClient
      : parentClient
  const { runMutation, running } = useMutation(
    buildMutation(requestNumberMutation, { client })
  )

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const channelProps = useMemo(() => {
    if (userType === "Tutor") {
      return {
        channelName: "StudentChannel",
        channelProps: { id: studentId },
      }
    }
    return {
      channelName: "TutorChannel",
      channelProps: { id: tutorId, studentId: studentId },
    }
  }, [studentId, tutorId, userType])
  useWebsocket(channelProps)

  useEffect(() => {
    EventEmitter.subscribe("numberCreated", data => {
      window.location.reload()
    })
  }, [])

  const handleClick = () => {
    runMutation({ studentId })
      .then(
        response => {
          const { success, errorMessages } = response.requestProxyNumber
          if (success) {
            setLoading(true)
          } else {
            setErrorMessage(errorMessages)
          }
        },
        () => {
          setErrorMessage("Something went wrong")
        }
      )
      .catch(() => setErrorMessage("Something went wrong"))
  }

  return (
    <>
      {errorMessage && <div className="alert mb-3">{errorMessage}</div>}
      <button
        onClick={handleClick}
        className="btn small orange d-flex align-items-center"
        disabled={loading || running}
      >
        <span>Request Number</span>
        {(loading || running) && <Loading className="ml-3" />}
      </button>
    </>
  )
}

const requestNumberMutation = compress`
  mutation($studentId: ID!) {
    requestProxyNumber(studentId: $studentId) {
      success
      errorMessages
    }
  }
`

export default RequestProxyNumber
