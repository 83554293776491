import React, { useRef } from "react"

import PropTypes from "prop-types"

import { css } from "@emotion/core"

import LoadingIndicator from "components/LoadingIndicator"

const AvatarPreview = props => {
  const { thumbUrl, pendingAvatar, isUploading } = props
  const imgRef = useRef(null)

  if (!thumbUrl && !pendingAvatar) return null

  if (pendingAvatar) {
    const reader = new FileReader()
    reader.onload = e => (imgRef.current.src = e.target.result)
    reader.readAsDataURL(pendingAvatar)
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        span {
          margin-right: 15px;
        }
        img {
          max-height: 100px;
          margin-right: 15px;
        }
      `}
    >
      <img src={thumbUrl} ref={imgRef} alt="Avatar Preview" />
      {isUploading && <LoadingIndicator size="small" />}
    </div>
  )
}

AvatarPreview.propTypes = {
  thumbUrl: PropTypes.string,
  pendingAvatar: PropTypes.object,
  isUploading: PropTypes.bool,
}

export default AvatarPreview
