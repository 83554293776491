import React, { useContext } from "react"

import classNames from "src/classNames"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"

export type RemoteScreenShareProps = {
  userId: string
}

const RemoteScreenShare: React.FC<RemoteScreenShareProps> = ({ userId }) => {
  const { channel, roster } = useContext(CloudroomContext)
  const attendeeId = Object.keys(roster).find(
    id => roster[id].externalUserId === `Student-${userId}`
  )

  const screenSharing = roster[attendeeId].sharingContent

  const baseClassNames =
    "ml-15px inline-flex items-center justify-around rounded-sm border-none p-2 leading-4 text-white outline-none transition duration-500"

  const endScreenShare = () => {
    channel.perform("end_screen_share", {
      userId: userId,
    })
  }

  const requestScreenShare = () => {
    channel.perform("screen_share_response", {
      approved: true,
      userId: userId,
    })
  }

  return (
    <button
      className={classNames(
        baseClassNames,
        screenSharing ? "bg-brand-600" : "bg-blue-700"
      )}
      onClick={screenSharing ? endScreenShare : requestScreenShare}
    >
      {screenSharing ? "End Screen Share" : "Request Screen Share"}
    </button>
  )
}

export default RemoteScreenShare
