import React, { ReactNode, useRef, useState } from "react"

import moment from "moment-timezone"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type TimeProtectedLinkProps = {
  text?: string
  children?: ReactNode
  href: string
  startsAt: string
  method?: string
  target: string
}

const TimeProtectedLink: React.FC<TimeProtectedLinkProps> = props => {
  const { children, text, href, startsAt, method, ...rest } = props
  const hiddenLink = useRef<HTMLAnchorElement>()
  const [showModal, setShowModal] = useState(false)
  const [timeString, setTimeString] = useState<string | number>(0)

  const onClick = () => {
    const timeDiff = moment(startsAt).unix() - moment().unix()
    const timeDiffHours = timeDiff / 60 / 60

    if (timeDiffHours <= 1) {
      hiddenLink.current.click()
    } else {
      const hours = Math.floor(timeDiffHours)
      const minutes = Math.floor((timeDiffHours - hours) * 60)
      if (hours > 24) {
        const days = Math.floor(hours / 24)
        setTimeString(`${days} ${days === 1 ? "day" : "days"}`)
      } else {
        setTimeString(`${hours} hours and ${minutes} minutes`)
      }
      setShowModal(true)
    }
  }

  return (
    <>
      <a
        ref={hiddenLink}
        style={{ display: "none" }}
        href={href}
        data-method={method === "get" ? null : method}
        {...rest}
      >
        Hidden
      </a>
      <a onClick={onClick} {...rest}>
        {children ? children : text}
      </a>
      <ModalWithProvidedBody
        isOpen={showModal}
        hideTrigger
        modalTitle="This is an upcoming session"
        closeModal={() => setShowModal(false)}
        modalClassName="bootstrap-modal"
      >
        {({ closeModal }) => (
          <>
            <div className="modal-body">
              <p className="lead">
                This session is not scheduled to start for {timeString}. Are you
                sure this is the session you are trying to access?
              </p>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <button
                  onClick={() => {
                    hiddenLink.current.click()
                    closeModal()
                  }}
                  className="btn solid green"
                >
                  Yes
                </button>
                <button onClick={closeModal} className="btn solid red">
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

export default TimeProtectedLink
