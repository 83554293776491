import React, { ReactNode } from "react"

export type SectionProps = {
  title: string
  subtitle?: string
  children: ReactNode
}

const Section: React.FC<SectionProps> = ({
  title,
  subtitle = "",
  children,
}) => {
  return (
    <div className="mt-10">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        {title}
      </h2>

      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
        {subtitle}
      </p>

      <div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
        {children}
      </div>
    </div>
  )
}

export default Section
