import React from "react"

import moment from "moment-timezone"

class LocalZone extends React.Component {
  render() {
    const zone = moment.tz.guess()
    window.zone = moment.tz(zone)
    return moment.tz(zone).zoneName()
  }
}
export default LocalZone
