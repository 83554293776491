import React from "react"

import { compress } from "micro-graphql-react"

import Users from "./Users"

const Students = ({
  students,
  studyGroupId,
  organizationId,
  errorMessages,
  requestConfirmations,
}) => {
  return (
    <>
      <Users
        users={students}
        studyGroupId={studyGroupId}
        addMutation={addStudentMutation}
        removeMutation={removeStudentMutation}
        title="Students"
        includeAddToAllSessionsButton
        requestConfirmations={requestConfirmations}
        autocompleteProps={{
          api: "/api/admins/students/autocomplete_full_name",
          searchOptions: organizationId && `organization_id=${organizationId}`,
          placeholder: "Add Student",
        }}
      />
      {errorMessages && (
        <div className="alert alert-danger">{errorMessages}</div>
      )}
    </>
  )
}

const addStudentMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupAddUser(studyGroupId: $studyGroupId, userId: $userId, userType: Student) {
      errorMessages
      studyGroup {
        students {
          id
          fullName
          lastName
          showPath
          lessonPlans {
            id
            name
          }
          studyGroupUser(studyGroupId: $studyGroupId) {
            id
            confirmedAt
          }
        }
      }
    }
  }
`

const removeStudentMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupRemoveUser(studyGroupId: $studyGroupId, userId: $userId, userType: Student) {
      studyGroup {
        students {
          id
          fullName
          lastName
          showPath
          lessonPlans {
            id
            name
          }
          studyGroupUser(studyGroupId: $studyGroupId) {
            id
            confirmedAt
          }
        }
      }
    }
  }
`

export default Students
