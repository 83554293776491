import React, { useState } from "react"

import DatePicker from "react-multi-date-picker"

import CloseButton from "./Plugins/CloseButton"
import TimePicker from "./Plugins/TimePicker"

const Picker = ({ value, onChange, fixed, ...props }) => {
  const [datePickerClosed, setDatePickerClosed] = useState(true)

  const containerStyle = { display: "block" }
  if (fixed) {
    containerStyle.position = "fixed"
    containerStyle.zIndex = 100
  }

  return (
    <DatePicker
      value={value}
      onChange={onChange}
      onOpen={() => setDatePickerClosed(false)}
      onClose={() => datePickerClosed}
      format="h:mma"
      containerStyle={containerStyle}
      inputClass="form-control"
      editable={false}
      disableDayPicker
      plugins={[
        <TimePicker
          key="timePicker"
          position="right"
          hideSeconds
          minuteStep={5}
        />,
        <CloseButton
          key="closeButton"
          position="bottom"
          setClosed={setDatePickerClosed}
        />,
      ]}
      {...props}
    />
  )
}

export default Picker
