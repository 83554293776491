import { compress } from "micro-graphql-react"

export default compress`
  mutation($id: ID!, $name: String!) {
    createRealtimeboard(id: $id, name: $name) {
      success
      errorMessages
      airtutorsSession {
        realtimeboardPending
      }
    }
  }
`
