import DisplayOnly from "./DisplayOnly"
import EditOnly from "./EditOnly"
import Failures from "./Failures"
import MultiSelect, { type MultiSelectProps } from "./MultiSelect"
import PasswordInput, { type PasswordInputProps } from "./PasswordInput"
import SingleSelect, { type SingleSelectProps } from "./SingleSelect"
import TextInput, { type TextInputProps } from "./TextInput"
import ToggleInput, { type ToggleInputProps } from "./ToggleInput"
import Wrapper, { type WrapperProps } from "./Wrapper"

export default {
  DisplayOnly,
  EditOnly,
  Failures,
  MultiSelect,
  PasswordInput,
  SingleSelect,
  TextInput,
  ToggleInput,
  Wrapper,
}
export type {
  MultiSelectProps,
  SingleSelectProps,
  TextInputProps,
  ToggleInputProps,
  PasswordInputProps,
  WrapperProps,
}
