import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import lessonTopicsFragment from "./lessonTopicsFragment"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
})

const NewLessonPlanButton = ({ lessonPlanId }) => {
  const { runMutation } = useMutation(buildMutation(createLessonTopicMutation))

  const formState = {
    name: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ name: values.name, lessonPlanId }).then(r => {
      closeModal()
    })
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-success"
      buttonText="New Chapter"
      modalClassName="bootstrap-modal"
      modalTitle="New Chapter"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field className="form-control" name="name" autoFocus />
                    <ErrorMessage name="name" />
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <SubmitButton isSubmitting={isSubmitting} text="Create" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createLessonTopicMutation = compress`
  ${lessonTopicsFragment}
  mutation($lessonPlanId: ID!, $name: String!) {
    createLessonTopic(name: $name, lessonPlanId: $lessonPlanId) {
      lessonPlan {
        lessonTopics {
          ...LessonTopicsFields
        }
      }
    }
  }
`

export default NewLessonPlanButton
