import React from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { courseTypeOptions } from "src/enums"
import ordered from "src/ordered"

import {
  useGradeOptions,
  useRemoteTableData,
  useSubjectOptions,
} from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"
import Modal from "components/Modal"

import "react-table/react-table.css"

const CourseClustersTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/course_clusters",
  })

  const subjectOptions = useSubjectOptions()
  const gradeOptions = useGradeOptions()

  let columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api={"/api/admins/course_clusters/autocomplete_name"}
          />
        )
      },
    },
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization",
      Cell: props =>
        props.value ? (
          <a href={props.value.show_path}>{props.value.name}</a>
        ) : (
          "Public"
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api={"/api/admins/organizations/autocomplete_name"}
          />
        )
      },
    },
    {
      id: "school",
      Header: "School",
      accessor: "school",
      Cell: props =>
        props.value ? (
          <a href={props.value.show_path}>{props.value.name}</a>
        ) : null,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api={"/api/admins/schools/autocomplete_name"}
          />
        )
      },
    },
    {
      id: "grade",
      Header: "Grade",
      accessor: "grades",
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "number").map(grade => (
            <li key={grade.id}>{grade.name}</li>
          ))}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => (event ? onChange(event.value) : onChange(""))}
            setValue={filter ? filter.value : ""}
            options={gradeOptions}
            isClearable
          />
        )
      },
    },
    {
      id: "subject",
      Header: "Subject",
      accessor: "subject.name",
      Filter: ({ filter, onChange }) => (
        <Select options={subjectOptions} onChange={onChange} isClearable />
      ),
    },
    {
      id: "course_type",
      Header: "Type",
      accessor: "course_type",
      Filter: ({ filer, onChange }) => (
        <Select options={courseTypeOptions} onChange={onChange} isClearable />
      ),
    },
    {
      id: "student_count",
      Header: "Registered Students",
      accessor: "student_count",
      filterable: false,
      sortable: false,
    },
    {
      id: "course_cluster_published_at",
      Header: "Published",
      accessor: "published_at",
      filterable: false,
      sortable: true,
      Cell: props =>
        props.value ? (
          <LocalTime timestamp={props.value} omitTime />
        ) : (
          "Not Published"
        ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure? This will remove ${props.row["name"]} and all associated courses.</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove it!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep it for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default CourseClustersTable
