import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"
import Select from "react-select"

import { studentClient } from "src/graphql-config"
import ordered from "src/ordered"

import WithLoadingIndicator from "components/WithLoadingIndicator"

const Tutors = ({ subjectId, onChange }) => {
  const loadingState = useQuery(
    buildQuery(tutorsQuery, { subjectId }, { client: studentClient })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading your tutors"
    >
      {({ data }) => (
        <Select
          options={ordered(data.availableTutors, "lastName")}
          onChange={onChange}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.fullName}
        />
      )}
    </WithLoadingIndicator>
  )
}

const tutorsQuery = compress`
  query($subjectId: ID!) {
    availableTutors(subjectId: $subjectId) {
      id
      fullName
      lastName
    }
  }
`

Tutors.propTypes = {
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Tutors
