import React from "react"

import { Field, Form, Formik } from "formik"
import Select from "react-select"

import { useGradeOptions } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { SubmitButton } from "components/Forms/Formik"

const FormField = ({ children }) => (
  <div className="col-xl-3 col-lg-6 col-sm-8 col-12">
    <div className="form-group">
      <label className="form-label w-100">{children}</label>
    </div>
  </div>
)

const SearchForm = ({ setFormParams, queryInProgress }) => {
  const gradeOptions = useGradeOptions()
  const formState = {
    organizationId: undefined,
    schoolId: undefined,
    gradeId: undefined,
    studyGroupId: undefined,
    tutorId: undefined,
    tags: undefined,
    studentFullName: "",
    studentEmail: "",
    studentPhoneNumber: "",
  }

  const handleSubmit = (values, actions) => {
    setFormParams({ values, page: 1 })
    actions.setSubmitting(false)
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <FormField>
              Organization
              <AutocompleteSelect
                api="/api/admins/organizations/autocomplete_name"
                onChange={selected =>
                  setFieldValue("organizationId", selected?.value)
                }
              />
            </FormField>

            <FormField>
              School
              <AutocompleteSelect
                api="/api/admins/schools/autocomplete_name"
                onChange={selected =>
                  setFieldValue("schoolId", selected?.value)
                }
              />
            </FormField>

            <FormField>
              Grade
              <Select
                options={gradeOptions}
                isClearable
                onChange={selected => setFieldValue("gradeId", selected?.value)}
                styles={{
                  container: provided => ({
                    ...provided,
                    maxWidth: 250,
                  }),
                }}
              />
            </FormField>

            <FormField>
              Study Group
              <AutocompleteSelect
                api="/api/admins/study_groups/autocomplete_name"
                onChange={selected =>
                  setFieldValue("studyGroupId", selected?.value)
                }
              />
            </FormField>
          </div>

          <div className="row">
            <FormField>
              Matched Tutor
              <AutocompleteSelect
                api="/api/admins/tutors/autocomplete_full_name"
                onChange={selected => setFieldValue("tutorId", selected?.value)}
              />
            </FormField>

            <FormField>
              Tags
              <AutocompleteSelect
                api="/api/admins/students/autocomplete_tags"
                isMulti
                onChange={selected =>
                  setFieldValue(
                    "tags",
                    selected.map(tag => tag.label)
                  )
                }
              />
            </FormField>
          </div>

          <div className="row">
            <FormField>
              Student Name
              <Field
                type="text"
                name="studentFullName"
                className="form-control"
              />
            </FormField>

            <FormField>
              Student Email
              <Field type="text" name="studentEmail" className="form-control" />
            </FormField>

            <FormField>
              Student Phone
              <Field
                type="text"
                name="studentPhoneNumber"
                className="form-control"
              />
            </FormField>
          </div>

          <SubmitButton
            isSubmitting={isSubmitting || queryInProgress}
            text="Search"
          />
        </Form>
      )}
    </Formik>
  )
}

export default SearchForm
