import React from "react"

import { gql, useQuery } from "hooks/urql"

import TagSelect from "components/Forms/TagSelect"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const StudyGroupTags = ({ studyGroupId }) => {
  const [result] = useQuery({ query: tagsQuery, variables: { studyGroupId } })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <TagSelect
          api="/api/admins/study_groups/autocomplete_tags"
          tagList={data.studyGroup.tags.map(tag => ({
            value: tag.id,
            label: tag.name,
          }))}
          modelKey="tagId"
          recordId={studyGroupId}
          recordKey="studyGroupId"
          createMutation={createTagMutation}
          addMutation={addTagMutation}
          removeMutation={removeTagMutation}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const tagsQuery = gql`
  query ($studyGroupId: ID!) {
    studyGroup(id: $studyGroupId) {
      id
      tags {
        id
        name
      }
    }
  }
`
const createTagMutation = gql`
  mutation ($name: String!, $studyGroupId: ID!) {
    studyGroupCreateTag(name: $name, studyGroupId: $studyGroupId) {
      studyGroup {
        id
      }
      failures {
        message
      }
    }
  }
`

const addTagMutation = gql`
  mutation ($tagId: ID!, $studyGroupId: ID!) {
    studyGroupAddTag(tagId: $tagId, studyGroupId: $studyGroupId) {
      studyGroup {
        id
      }
      failures {
        message
      }
    }
  }
`

const removeTagMutation = gql`
  mutation ($tagId: ID!, $studyGroupId: ID!) {
    studyGroupRemoveTag(tagId: $tagId, studyGroupId: $studyGroupId) {
      studyGroup {
        id
      }
      failures {
        message
      }
    }
  }
`
export default StudyGroupTags
