import React, { useState } from "react"

import { NotificationContainer } from "react-notifications"

import { gql, useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import EditInterviewer from "./EditInterviewer"
import RemoveInterviewer from "./RemoveInterviewer"

import "react-notifications/lib/notifications.css"

const InterviewerDetails = ({ id }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: tutorApplicationInterviewerQuery,
    variables: { id, page },
  })

  return (
    <>
      <NotificationContainer />
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <div>
              <div className="d-inline-block mr-3">
                <EditInterviewer
                  interviewer={data.tutorApplicationInterviewer}
                />
              </div>
              <RemoveInterviewer id={id} />
            </div>
            <dl>
              <dt>Name</dt>
              <dd>{data.tutorApplicationInterviewer.name}</dd>

              <dt>Admin</dt>
              <dd>
                <a href={data.tutorApplicationInterviewer.admin.showPath}>
                  {data.tutorApplicationInterviewer.admin.fullName}
                </a>
              </dd>

              <dt>Calendly URL</dt>
              <dd>
                <a href={data.tutorApplicationInterviewer.calendlyUrl}>
                  {data.tutorApplicationInterviewer.calendlyUrl}
                </a>
              </dd>

              <dt>Scheduled Tutor Applications</dt>
              <dd>
                <table className="table">
                  <thead className="text-primary">
                    <tr>
                      <th>Applicant</th>
                      <th>Current State</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.tutorApplicationInterviewer.tutorApplications.data.map(
                      tutorApplication => (
                        <tr key={tutorApplication.id}>
                          <td>
                            {tutorApplication.fullName ||
                              tutorApplication.email}
                          </td>
                          <td>{tutorApplication.applicationState}</td>
                          <td>
                            <a
                              href={tutorApplication.showPath}
                              className="btn btn-sm btn-info"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>

                <Pagination
                  currentPage={
                    data.tutorApplicationInterviewer.tutorApplications
                      .currentPage
                  }
                  totalPages={
                    data.tutorApplicationInterviewer.tutorApplications
                      .totalPages
                  }
                  changePage={setPage}
                />
              </dd>
            </dl>
          </>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const tutorApplicationInterviewerQuery = gql`
  query ($id: ID!, $page: Int) {
    tutorApplicationInterviewer(id: $id) {
      id
      name
      calendlyUrl
      showPath
      admin {
        id
        fullName
      }

      tutorApplications(page: $page) {
        currentPage
        totalPages
        data {
          id
          fullName
          email
          applicationState
          showPath
        }
      }
    }
  }
`

export default InterviewerDetails
