import React from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import NewAssessment from "./NewAssessment"

import "react-table/react-table.css"

const AssessmentTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/assessments",
  })

  let columns = [
    {
      id: "assessment_name",
      Header: "Name",
      accessor: "name",
      Cell: props => <a href={props.original.show_path}>{props.value}</a>,
      Filter: ({ onChange }) => (
        <AutocompleteSelect
          onChange={onChange}
          api="/api/admins/assessments/autocomplete_name"
        />
      ),
    },
    {
      id: "assessment_sections",
      Header: "Sections",
      accessor: "assessment_sections",
      filterable: false,
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {props.value.map(section => (
            <li key={section.id}>{section.name}</li>
          ))}
        </ul>
      ),
    },
  ]

  return (
    <>
      <NewAssessment />
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        manual
        filterable
      />
    </>
  )
}

export default AssessmentTable
