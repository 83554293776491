import React from "react"

import { FieldArray, useField, useFormikContext } from "formik"

import { css } from "@emotion/core"

import { ErrorMessage } from "components/Forms/Formik/hookComponents"

import TeacherSubject from "./TeacherSubject"

const TeacherSubjectsFields = ({ organization }) => {
  const { values } = useFormikContext()
  const [, meta] = useField({ name: "teacherSubjects" })

  return (
    <FieldArray name="teacherSubjects">
      {arrayHelpers => (
        <div className="form-group">
          <div
            css={css`
              margin: 15px 0;
            `}
          >
            {values.teacherSubjects.map((teacherSubject, index) => (
              <TeacherSubject
                key={teacherSubject.uuid}
                teachers={organization.teachers}
                teacherSubject={teacherSubject}
                index={index}
                arrayHelpers={arrayHelpers}
              />
            ))}
          </div>
          <div>
            <button
              onClick={() => arrayHelpers.push({ uuid: Date.now() })}
              type="button"
              className="btn solid blue"
            >
              Add Subject
            </button>
          </div>
          <div className="mt-3">
            <ErrorMessage {...meta} />
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export default TeacherSubjectsFields
