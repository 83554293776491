import React from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import EditConfiguration from "./EditConfiguration"

import "react-table/react-table.css"

const AdminConfigurationsTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/application_configurations",
  })

  const columns = [
    {
      id: "label",
      Header: "Label",
      accessor: "label",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/application_configurations/autocomplete_label"
          />
        )
      },
    },
    {
      id: "slug",
      Header: "Slug",
      accessor: "slug",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/application_configurations/autocomplete_slug"
          />
        )
      },
    },
    {
      id: "value",
      Header: "Value",
      accessor: "value",
      sortable: false,
      filterable: false,
      Cell: props => <div className="text-center">{props.value}</div>,
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "id",
      Cell: props => (
        <EditConfiguration applicationConfiguration={props.original} />
      ),
    },
  ]

  return (
    <>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        manual
        filterable
      />
    </>
  )
}

export default AdminConfigurationsTable
