import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

const ChangeTutor = ({ id }) => {
  const [errors, setErrors] = useState([])
  const { runMutation } = useMutation(buildMutation(changeTutorMutation))

  const handleSubmit = selected => {
    runMutation({ airtutorsSessionId: id, tutorId: selected.value })
      .then(
        response => {
          const { failures } = response.airtutorsSessionChangeTutor
          if (failures) {
            setErrors(failures)
          } else {
            window.location.reload()
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
  }

  return (
    <>
      <AutocompleteSelect
        api="/api/admins/tutors/autocomplete_full_name"
        onChange={handleSubmit}
        isClearable={false}
        placeholder="Change Tutor"
      />

      {errors.map((error, index) => (
        <div key={index} className="alert alert-danger mb-0 mt-3 p-2">
          <div className="ml-3">
            <p>{error.message}</p>
            {error.conflictingSessions && (
              <ul className="list-unstyled mb-2">
                {error.conflictingSessions.map(session => (
                  <li key={session.id}>
                    <a
                      href={session.showPath}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-white"
                    >
                      <LocalTime timestamp={session.startsAt} />
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

const changeTutorMutation = compress`
  mutation($airtutorsSessionId: ID!, $tutorId: ID!) {
    airtutorsSessionChangeTutor(airtutorsSessionId: $airtutorsSessionId, tutorId: $tutorId) {
      failures {
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
    }
  }
`

export default ChangeTutor
