import React from "react"

import { css } from "@emotion/core"
import { faCheck, faMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import LessonResourceCompletion from "./LessonResourceCompletion"

const LessonPlan = ({
  studentId,
  lessonPlanAssignment,
  removeLessonPlan,
  setCurrentResource,
}) => {
  const { lessonPlan } = lessonPlanAssignment

  return (
    <>
      <div className="d-flex align-items-center pb-3">
        <ModalWithProvidedBody
          buttonClassName={`btn btn-sm btn-outline-danger red rounded-circle p-1 text-danger nc-icon nc-simple-remove m-0 mr-3`}
          modalTitle="Remove Lesson Plan Assignment"
        >
          {({ closeModal }) => (
            <>
              <div className="modal-body">
                Are you sure? This will remove the selected lesson plan from the
                student's profile.
              </div>

              <div className="modal-footer">
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={removeLessonPlan}
                  >
                    Yes, remove it
                  </button>
                </div>
              </div>
            </>
          )}
        </ModalWithProvidedBody>
        <h4 className="m-0">
          <a href={lessonPlan.showPath}>{lessonPlan.name}</a>
        </h4>
      </div>
      {lessonPlan.lessonTopics.map(lessonTopic => (
        <div
          key={lessonTopic.id}
          className="pt-3"
          css={css`
            @media screen and (min-width: 769px) {
              width: 75%;
            }
            @media screen and (min-width: 1025px) {
              width: 50%;
            }
          `}
        >
          <h5>Chapter: {lessonTopic.name}</h5>
          {lessonTopic.lessonResources.map(lessonResource => (
            <div key={lessonResource.id} className="mb-1">
              {lessonResource.completed ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-success green-text fa-fw mr-3"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  className="text-warning orange-text fa-fw mr-3"
                />
              )}
              {lessonResource.name}
              {lessonResource.current ? (
                <button className="btn btn-sm btn-success solid small green ml-3">
                  Current
                </button>
              ) : (
                <button
                  onClick={() => setCurrentResource(lessonResource)}
                  className="btn btn-sm btn-outline-info small blue ml-3"
                >
                  Make current
                </button>
              )}
              {ordered(
                lessonResource.lessonResourceCompletions,
                "session",
                "startsAt"
              ).map(completion => (
                <LessonResourceCompletion
                  key={completion.id}
                  studentId={studentId}
                  lessonResourceCompletion={completion}
                />
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default LessonPlan
