import React from "react"

import { Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import Select from "react-select"
import * as Yup from "yup"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import { studyGroupFields } from "./fragments"

const ValidationSchema = Yup.object().shape({
  availabilityIds: Yup.array().min(
    1,
    "Please select an availability time slot"
  ),
})

const NewGeneratedGroup = ({ generation, organizationId }) => {
  const formState = {
    availabilityIds: [],
  }

  const loadingState = useQuery(
    buildQuery(availabilitiesQuery, { organizationId })
  )
  const { runMutation } = useMutation(
    buildMutation(createGeneratedGroupMutation)
  )

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ studyGroupGenerationId: generation.id, ...values })
      .then(
        response => {
          const { errorMessages } = response.createGeneratedStudyGroup
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        e => actions.setStatus("Something went wrong")
      )
      .catch(e => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Study Group"
      buttonText="New Group"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <WithLoadingIndicator loadingState={loadingState}>
          {({ data }) => (
            <Formik
              initialValues={formState}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, closeModal)
              }
              validationSchema={ValidationSchema}
            >
              {({ status, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Availability Times</label>
                      <Select
                        menuPortalTarget={document.body}
                        isMulti
                        options={ordered(
                          data.organization.availabilities,
                          "weekdayNumber"
                        ).map(availability => ({
                          value: availability.id,
                          label: (
                            <>
                              {availability.weekday}{" "}
                              <LocalTime
                                timestamp={availability.startsAt}
                                format="h:mm A"
                              />
                              &ndash;
                              <LocalTime
                                timestamp={availability.endsAt}
                                format="h:mm A z"
                              />
                            </>
                          ),
                        }))}
                        onChange={selected =>
                          setFieldValue(
                            "availabilityIds",
                            selected.map(o => o?.value)
                          )
                        }
                      />
                      <ErrorMessage name="availabilityIds" />
                    </div>
                  </div>
                  <div className="modal-footer flex-column">
                    {status && (
                      <div className="alert alert-danger w-100">{status}</div>
                    )}
                    <div className="d-flex justify-content-between w-100">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        text="Create"
                        containerCss={css`
                          margin: 0;
                        `}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </WithLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const availabilitiesQuery = compress`
  query($organizationId: ID!) {
    organization(id: $organizationId) {
      availabilities {
        id
        startsAt
        endsAt
        weekday
        weekdayNumber
      }
    }
  }
`

const createGeneratedGroupMutation = compress`
  ${studyGroupFields}
  mutation(
    $studyGroupGenerationId: ID!
    $availabilityIds: [ID!]!
  ) {
    createGeneratedStudyGroup(
      studyGroupGenerationId: $studyGroupGenerationId
      availabilityIds: $availabilityIds
    ) {
      errorMessages
      studyGroupGeneration {
        id
        studyGroups {
          ...StudyGroupFields
        }
      }
    }
  }
`

export default NewGeneratedGroup
