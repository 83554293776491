import React from "react"

import { ButtonVariant, Size } from "~tailwindui/types/enums"

import {
  buttonColorClassNames,
  buttonSizeClassNames,
} from "~tailwindui/helpers/classNameHelpers"

import { default as classNamesHelper } from "src/classNames"

export type ButtonProps = {
  text: string
  size?: Size
  variant?: ButtonVariant
  classNames?: string
}

const Button: React.FC<
  ButtonProps & React.SelectHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  size = Size.Medium,
  variant = ButtonVariant.Primary,
  classNames = "",
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNamesHelper(
        "font-semibold shadow-sm",
        buttonSizeClassNames(size),
        buttonColorClassNames(variant),
        classNames
      )}
      {...props}
    >
      {text}
    </button>
  )
}

export default Button
