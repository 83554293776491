import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  Field,
  FileField,
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  file: Yup.mixed().test(
    "file",
    "File must be present",
    file => file.name || file.fileName
  ),
  filename: Yup.string().required("You must specify a filename"),
})

const AddConsentDocument = ({ organizationId }) => {
  const formState = {
    organizationId: organizationId,
    file: {},
    filename: "",
  }

  const { runMutation } = useMutation(buildMutation(updateMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      const extension = (values.file.name || values.file.fileName)
        .split(".")
        .pop()
      const filenameWithExtension = values.filename.endsWith(extension)
        ? values.filename
        : `${values.filename}.${extension}`

      runMutation({
        organizationId,
        document: {
          file: b64,
          filename: filenameWithExtension,
        },
      })
        .then(
          response => {
            const { errorMessages } = response.organizationAddConsentDocument
            if (errorMessages) {
              actions.setStatus(errorMessages)
            } else {
              closeModal()
            }
          },
          () => actions.setStatus("Something went wrong")
        )
        .catch(() => actions.setStatus("Something went wrong"))
        .finally(() => actions.setSubmitting(false))
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Consent Document"
      buttonClassName="btn btn-info"
      buttonText="Add Consent Document"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <FileField name="file" label="Consent Document" />
              <Field name="filename" label="Display Name" />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <SubmitButton text="Upload Document" />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateMutation = compress`
  mutation(
    $organizationId: ID!
    $document: FileInputObject!
  ) {
    organizationAddConsentDocument(
      organizationId: $organizationId
      document: $document
    ) {
      errorMessages
      organization {
        consentDocuments {
          id
          downloadUrl
          filename
        }
      }
    }
  }
`

export default AddConsentDocument
