import React, { useRef } from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

type AddTailoredAssignmentProps = {
  studyGroupId: number
}

const AddTailoredAssignment: React.FC<AddTailoredAssignmentProps> = ({
  studyGroupId,
}) => {
  const assignmentSelectRef = useRef(null)
  const [, addTailoredAssignment]: any[] = useMutation(addAssignmentMutation)

  return (
    <div className="mb-3">
      <h4>Add Tailored Assignment</h4>
      <p className="italic">
        Use this field to auto-assign an assignment to all students in this
        group. This is for convenience only and the system will not
        automatically add/remove this assignment to students added/removed from
        this group in the future.
      </p>
      <AutocompleteSelect
        ref={assignmentSelectRef}
        api="/api/admins/tailored_assignments/autocomplete_name"
        placeholder="Assignment Name"
        onChange={selected => {
          if (!selected?.value) return
          addTailoredAssignment({
            studyGroupId,
            tailoredAssignmentId: selected.value,
          })
            .then(result => {
              const { failures, addedCount } =
                result.data.studyGroupAddAssignment
              if (failures.length > 0) {
                NotificationManager.error(
                  <>
                    <ul className="list-unstyled">
                      {failures.map(failure => (
                        <li key={failure.message}>{failure.message}</li>
                      ))}
                    </ul>
                  </>
                )
              } else {
                NotificationManager.success(
                  `Assignment added to ${addedCount} students`
                )
              }
            })
            .finally(r => assignmentSelectRef.current.clearValue())
        }}
      />
    </div>
  )
}

const addAssignmentMutation = gql`
  mutation StudyGroupAddAssignment(
    $studyGroupId: ID!
    $tailoredAssignmentId: ID!
  ) {
    studyGroupAddAssignment(
      studyGroupId: $studyGroupId
      tailoredAssignmentId: $tailoredAssignmentId
    ) {
      addedCount
      failures {
        message
      }
    }
  }
`

export default AddTailoredAssignment
