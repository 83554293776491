import React, { useRef } from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import client from "src/urql-client"

import ExternalUserImportStatus from "components/Admin/Organization/ExternalUserImportStatus"
import {
  AutocompleteField,
  CheckboxField,
  FormFailures,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import RemoteGradeField from "components/Forms/Formik/hookComponents/RemoteGradeField"

import CleverUsersTable from "./Table"

const OrganizationCleverUsers = ({ organizationId }) => {
  const formState = {
    userType: "Student",
    page: 1,
    Student: {
      organizationId,
      importAll: false,
      cleverUserIds: [],
      filters: {
        cleverSchoolIds: [],
        gradeNumbers: [],
        cleverCourseIds: [],
        cleverSectionIds: [],
        districtUserIdentifiers: [],
      },
    },
    Teacher: {
      organizationId,
      importAll: false,
      cleverUserIds: [],
      filters: {
        cleverSchoolIds: [],
        cleverCourseIds: [],
        cleverSectionIds: [],
        districtUserIdentifiers: [],
      },
    },
  }
  const selectRefs = useRef({})

  const clearSelectFields = () => {
    Object.values(selectRefs.current).forEach(ref => {
      if (!ref) return // the teacher form doesn't have a grade select
      const clearValue = ref.clearValue
        ? ref.clearValue
        : ref.select?.clearValue
      clearValue()
    })
  }

  const handleSubmit = (values, actions) => {
    if (values.userType === "Student") convertStudents(values, actions)
    if (values.userType === "Teacher") convertTeachers(values, actions)
  }

  const convertStudents = (values, actions) => {
    const { organizationId, importAll, filters, cleverUserIds } =
      values[values.userType]
    const params = { organizationId, importAll, cleverUserIds, ...filters }
    const { unsubscribe } = pipe(
      client.subscription(convertStudentsSubscription, params),
      subscribe(result => {
        if (result.data?.convertCleverStudents?.status === "done") {
          actions.setStatus([
            {
              type: "info",
              message:
                "Import Completed Successfully. Please refresh to see updated data.",
            },
          ])
          actions.setSubmitting(false)
          unsubscribe()
        } else if (result.data?.convertCleverStudents?.errorMessages.length) {
          actions.setStatus(
            result.data.convertCleverStudents.errorMessages.map(message => ({
              message,
            }))
          )
          actions.setSubmitting(false)
          unsubscribe()
        }
      })
    )
  }

  const convertTeachers = (values, actions) => {
    const { organizationId, importAll, filters, cleverUserIds } =
      values[values.userType]
    const params = { organizationId, importAll, cleverUserIds, ...filters }
    const { unsubscribe } = pipe(
      client.subscription(convertTeachersSubscription, params),
      subscribe(result => {
        if (result.data?.convertCleverTeachers?.status === "done") {
          actions.setStatus([
            {
              type: "info",
              message:
                "Import Completed Successfully. Please refresh to see updated data.",
            },
          ])
          actions.setSubmitting(false)
          unsubscribe()
        } else if (result.data?.convertCleverTeachers?.errorMessages.length) {
          actions.setStatus(
            result.data.convertCleverTeachers.errorMessages.map(message => ({
              message,
            }))
          )
          actions.setSubmitting(false)
          unsubscribe()
        }
      })
    )
  }

  return (
    <>
      <ExternalUserImportStatus organizationId={organizationId} type="clever" />
      <Formik initialValues={formState} onSubmit={handleSubmit}>
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <div className="mb-3 flex justify-start border-x-0 border-b-2 border-t-0 border-solid border-sky-600 p-3">
              <button
                className={`btn btn-link px-3 text-lg font-medium ${
                  "Student" === values.userType
                    ? "bg-slate-300 !text-blue-700 focus:!bg-slate-300 focus:!text-blue-700 active:!bg-slate-300 active:!text-blue-700"
                    : ""
                }`}
                type="button"
                onClick={() => {
                  resetForm()
                  clearSelectFields()
                  setFieldValue("userType", "Student")
                }}
              >
                Students
              </button>

              <button
                className={`btn btn-link px-3 text-lg font-medium ${
                  "Teacher" === values.userType
                    ? "bg-slate-300 !text-blue-700 focus:!bg-slate-300 focus:!text-blue-700 active:!bg-slate-300 active:!text-blue-700"
                    : ""
                }`}
                type="button"
                onClick={() => {
                  resetForm()
                  clearSelectFields()
                  setFieldValue("userType", "Teacher")
                }}
              >
                Teachers
              </button>
            </div>

            <br />

            <div className="flex justify-start">
              <div className="mr-3">
                <h6 className="mt-0">Filter by School</h6>
                <div className="mb-3">
                  <AutocompleteField
                    ref={ref => (selectRefs.current.schools = ref)}
                    name={`${values.userType}.filters.cleverSchoolIds`}
                    api="/api/admins/clever_schools/autocomplete_name"
                    searchOptions={`organization_id=${organizationId}`}
                    placeholder="Find School"
                    isMulti
                    isClearable
                    onChange={selectedOpts => {
                      setFieldValue(
                        `${values.userType}.filters.cleverSchoolIds`,
                        selectedOpts.map(opt => opt.value)
                      )
                      setFieldValue("page", 1)
                    }}
                  />
                </div>
              </div>

              {values.userType === "Student" && (
                <div className="mr-3">
                  <h6 className="mt-0">Filter by Grade</h6>
                  <div className="mb-3">
                    <RemoteGradeField
                      ref={ref => (selectRefs.current.grades = ref)}
                      label=""
                      placeholder="Find Grade"
                      isMulti
                      isClearable
                      onChange={selectedOpts => {
                        setFieldValue(
                          "Student.filters.gradeNumbers",
                          selectedOpts.map(opt => opt.number)
                        )
                        setFieldValue("page", 1)
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="mr-3">
                <h6 className="mt-0">Filter by Course</h6>
                <div className="mb-3">
                  <AutocompleteField
                    ref={ref => (selectRefs.current.courses = ref)}
                    name={`${values.userType}.filters.cleverCourseIds`}
                    api="/api/admins/clever_courses/autocomplete_name"
                    searchOptions={`organization_id=${organizationId}`}
                    placeholder="Find Course"
                    isMulti
                    isClearable
                    onChange={selectedOpts => {
                      setFieldValue(
                        `${values.userType}.filters.cleverCourseIds`,
                        selectedOpts.map(opt => opt.value)
                      )
                      setFieldValue("page", 1)
                    }}
                  />
                </div>
              </div>

              <div className="mr-3">
                <h6 className="mt-0">Filter by Section</h6>
                <div className="mb-3">
                  <AutocompleteField
                    ref={ref => (selectRefs.current.classes = ref)}
                    name={`${values.userType}.filters.cleverSectionIds`}
                    api="/api/admins/clever_sections/autocomplete_name"
                    searchOptions={`organization_id=${organizationId}`}
                    placeholder="Find Section"
                    isMulti
                    isClearable
                    onChange={selectedOpts => {
                      setFieldValue(
                        `${values.userType}.filters.cleverSectionIds`,
                        selectedOpts.map(opt => opt.value)
                      )
                      setFieldValue("page", 1)
                    }}
                  />
                </div>
              </div>

              <div className="mr-3">
                <h6 className="mt-0">Filter by District Identifier</h6>
                <div className="mb-3">
                  <AutocompleteField
                    ref={ref => (selectRefs.current.districtNumber = ref)}
                    name={`${values.userType}.filters.districtUserIdentifiers`}
                    api="/api/admins/clever_users/autocomplete_district_user_identifier"
                    searchOptions={`organization_id=${organizationId}`}
                    placeholder="Find User"
                    isClearable
                    isMulti
                    onChange={value => {
                      setFieldValue(
                        `${values.userType}.filters.districtUserIdentifiers`,
                        value.map(v => v.label)
                      )
                      setFieldValue("page", 1)
                    }}
                  />
                </div>
              </div>
            </div>

            <CleverUsersTable
              userType={values.userType}
              filters={values[values.userType].filters}
              organizationId={organizationId}
              page={values.page}
              setPage={val => setFieldValue("page", val)}
            />

            <div className="ml-2 mt-5">
              <CheckboxField
                name={`${values.userType}.importAll`}
                label="Import All"
              />
              <SubmitButton text={`Import Selected ${values.userType}s`} />
              <FormFailures />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

const convertStudentsSubscription = gql`
  subscription (
    $organizationId: ID!
    $importAll: Boolean!
    $cleverSchoolIds: [ID!]!
    $cleverCourseIds: [ID!]!
    $cleverSectionIds: [ID!]!
    $gradeNumbers: [Int!]!
    $cleverUserIds: [ID!]
    $districtUserIdentifiers: [String!]
  ) {
    convertCleverStudents(
      organizationId: $organizationId
      importAll: $importAll
      cleverSchoolIds: $cleverSchoolIds
      cleverCourseIds: $cleverCourseIds
      cleverSectionIds: $cleverSectionIds
      gradeNumbers: $gradeNumbers
      cleverStudentIds: $cleverUserIds
      districtUserIdentifiers: $districtUserIdentifiers
    ) {
      status
      errorMessages
    }
  }
`

const convertTeachersSubscription = gql`
  subscription (
    $organizationId: ID!
    $importAll: Boolean!
    $cleverSchoolIds: [ID!]!
    $cleverCourseIds: [ID!]!
    $cleverSectionIds: [ID!]!
    $cleverUserIds: [ID!]
    $districtUserIdentifiers: [String!]
  ) {
    convertCleverTeachers(
      organizationId: $organizationId
      importAll: $importAll
      cleverSchoolIds: $cleverSchoolIds
      cleverCourseIds: $cleverCourseIds
      cleverSectionIds: $cleverSectionIds
      cleverTeacherIds: $cleverUserIds
      districtUserIdentifiers: $districtUserIdentifiers
    ) {
      status
      errorMessages
    }
  }
`

export default OrganizationCleverUsers
