import React, { useRef, useState } from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { formatDate } from "src/date-helpers"
import ordered from "src/ordered"
import client from "src/urql-client"

import { useMutation } from "hooks/urql"

import {
  CheckboxField,
  DateRangeField,
  Field,
  FileField,
  FormFailures,
  SelectField,
  SubmitButton,
  TextAreaField,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"

import AutogeneratedBreakdowns from "./AutogeneratedBreakdowns"
import Budgets from "./Budgets"
import Schools from "./Schools"
import Tags from "./Tags"

const ValidationSchema = Yup.object().shape({
  dateRange: Yup.array()
    .min(2, "Date range must have a start and end date")
    .max(2, "Date range must have a start and end date"),
  invoiceNumber: Yup.string().required("Invoice number is required"),
  budgetId: Yup.string().required(
    "Please select a budget to locate sessions & extract funds from"
  ),
  total: Yup.mixed().when("autogeneratedStatement", {
    is: false,
    then: Yup.number().required(
      "Total is required for non-autogenerated statements"
    ),
  }),
  pdf: Yup.mixed().when("autogeneratedStatement", {
    is: false,
    then: Yup.mixed().test(
      "pdf",
      "Attachment is required for non-autogenerated statements",
      file => file?.name || file?.fileName
    ),
  }),
  configurationName: Yup.mixed().when("createConfiguration", {
    is: true,
    then: Yup.number().required("Configuration name is required"),
  }),
})

const StatementForm = ({
  organizationId,
  schools,
  statementConfigurations,
  budgets,
}) => {
  const formState = {
    dateRange: [],
    miscellaneousContent: "",
    invoiceNumber: "",
    includeAllGroups: true,
    schoolIds: [],
    includeSchoollessGroups: false,
    filterByTags: false,
    studyGroupTags: [],
    useAutogeneratedBreakdowns: false,
    autogeneratesBreakdownsByCategory: "",
    subjectGroupingsAttributes: [],
    autogeneratedStatement: true,
    statementConfigurationId: "",
    persistConfigurationChanges: false,
    createConfiguration: false,
    configurationName: "",
    total: "",
    pdf: {},
  }

  const [, createStatement] = useMutation(createMutation)
  const budgetRef = useRef(null)
  const studyGroupTagsRef = useRef(null)
  const [selectedConfiguration, setSelectedConfiguration] = useState()

  const setConfig = (selected, values, setValues) => {
    if (selected?.id) {
      client
        .query(statementConfigQuery, { id: selected.id })
        .toPromise()
        .then(({ data: { statementConfiguration } }) => {
          setSelectedConfiguration(statementConfiguration)
          const {
            id,
            name,
            miscellaneousContent,
            budget,
            studyGroupTags,
            schools,
            includeAllGroups,
            includeSchoollessGroups,
            autogeneratesBreakdownsByCategory,
            subjectGroupings,
          } = statementConfiguration

          setValues({
            ...values,
            statementConfigurationId: id,
            configurationName: name,
            autogeneratedStatement: true,
            miscellaneousContent,
            studyGroupTags,
            filterByTags: studyGroupTags.length > 0,
            schoolIds: schools.map(school => school.id),
            includeAllGroups,
            includeSchoollessGroups,
            useAutogeneratedBreakdowns: !!autogeneratesBreakdownsByCategory,
            autogeneratesBreakdownsByCategory,
            subjectGroupingsAttributes: subjectGroupings,
            budgetId: budget.id,
          })

          budgetRef.current.onChange(budget)
          if (studyGroupTags.length > 0) {
            studyGroupTagsRef.current.ref.select.setState({
              value: studyGroupTags.map(tag => ({ label: tag, value: tag })),
            })
          }
        })
    } else {
      setValues({
        ...values,
        statementConfigurationId: "",
        autogeneratedStatement: true,
        miscellaneousContent: "",
        filterByTags: false,
        studyGroupTags: [],
        schoolIds: [],
        includeAllGroups: true,
        includeSchoollessGroups: false,
        useAutogeneratedBreakdowns: false,
        autogeneratesBreakdownsByCategory: "",
        subjectGroupingsAttributes: [],
        budgetId: "",
      })
      budgetRef.current.select.clearValue()
      studyGroupTagsRef.current?.clearSelects()
    }
  }

  const handleSubmit = (values, actions) => {
    const {
      dateRange: [startsOn, endsOn],
      filterByTags,
      useAutogeneratedBreakdowns,
      createConfiguration,
      persistConfigurationChanges,
      configurationName,
      pdf,
      ...statementInput
    } = values
    statementInput.startsOn = formatDate(startsOn.toDate())
    statementInput.endsOn = formatDate(endsOn.toDate())

    if (statementInput.autogeneratedStatement) {
      delete statementInput.total
    }

    if (useAutogeneratedBreakdowns) {
      statementInput.subjectGroupingsAttributes =
        statementInput.subjectGroupingsAttributes.map(grouping => ({
          id: grouping.id,
          label: grouping.label,
          subjectIds: grouping.subjects.map(sub => sub.id),
        }))
    } else {
      delete statementInput.autogeneratesBreakdownsByCategory
      delete statementInput.subjectGroupingsAttributes
    }

    readFile(pdf).then(file => {
      statementInput.pdf = file
      const params = {
        organizationId,
        input: statementInput,
        createConfiguration,
        persistConfigurationChanges,
        configurationName,
      }

      createStatement(params)
        .then(
          result => {
            const { statement, failures } = result.data.createStatement
            if (failures) {
              handleFailure(actions, failures)
            } else {
              window.location = statement.showPath
            }
          },
          () => handleFailure(actions)
        )
        .catch(() => handleFailure(actions))
    })
  }

  return (
    <>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form>
            <DateRangeField name="dateRange" label="Date Range" />
            <Field name="invoiceNumber" label="Invoice Number" />
            <CheckboxField
              name="autogeneratedStatement"
              label="Auto-Generated Statement"
            />
            {values.autogeneratedStatement ? (
              <>
                <SelectField
                  name="statementConfigurationId"
                  label="Choose Configuration"
                  options={ordered(
                    ordered(statementConfigurations, "budget", "name"),
                    "name"
                  )}
                  valueAttribute="id"
                  labelAttribute="name"
                  getOptionValue={option => option.id}
                  getOptionLabel={option =>
                    `${option.name} (${option.budget.name})`
                  }
                  onChange={selected => setConfig(selected, values, setValues)}
                  isClearable
                />
                {values.statementConfigurationId && (
                  <span className="italic">
                    If you want to update this configuration's autogeneration
                    schedule, you can do so{" "}
                    <a href={selectedConfiguration.showPath}>here</a>.
                  </span>
                )}
              </>
            ) : (
              <>
                <Field name="total" label="Total" type="number" step={0.01} />
                <FileField name="pdf" label="PDF" />
              </>
            )}

            <hr />

            <Budgets budgets={budgets} ref={budgetRef} />

            {values.autogeneratedStatement && (
              <>
                <TextAreaField
                  name="miscellaneousContent"
                  label="Miscellaneous Content"
                />

                <Schools schools={schools} />
                <Tags ref={studyGroupTagsRef} />
                <AutogeneratedBreakdowns />
              </>
            )}

            <hr />

            {values.statementConfigurationId === "" ? (
              <CheckboxField
                name="createConfiguration"
                label="Create New Configuration"
              />
            ) : (
              <CheckboxField
                name="persistConfigurationChanges"
                label="Update Current Configuration"
              />
            )}

            {(values.createConfiguration ||
              values.persistConfigurationChanges) && (
              <Field
                name="configurationName"
                label={
                  values.createConfiguration
                    ? "Configuration Name"
                    : "Update Configuration Name"
                }
              />
            )}

            <FormFailures />
            <SubmitButton text="Create Statement" />
          </Form>
        )}
      </Formik>
    </>
  )
}

const statementConfigQuery = gql`
  query statementConfig($id: ID!) {
    statementConfiguration(id: $id) {
      id
      name
      miscellaneousContent
      showPath
      budget {
        id
        name
      }
      autogeneratesBreakdownsByCategory
      subjectGroupings {
        id
        label
        subjects {
          id
          name
        }
      }
      studyGroupTags
      includeAllGroups
      includeSchoollessGroups
      schools {
        id
        name
      }
    }
  }
`

const createMutation = gql`
  mutation (
    $organizationId: ID!
    $input: StatementInputObject!
    $createConfiguration: Boolean!
    $persistConfigurationChanges: Boolean!
    $configurationName: String
  ) {
    createStatement(
      organizationId: $organizationId
      input: $input
      createConfiguration: $createConfiguration
      persistConfigurationChanges: $persistConfigurationChanges
      configurationName: $configurationName
    ) {
      failures {
        message
      }
      statement {
        id
        showPath
      }
    }
  }
`

export default StatementForm
