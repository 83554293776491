import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import CollapsibleContainer from "components/CollapsibleContainer"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import StudentsTable from "./StudentsTable"
import studentFields from "./studentFields"

const NonOrganizationStudents = () => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      studentsQuery,
      { page },
      {
        onMutation: [
          {
            when: "studentContacted",
            run: ({ softReset, currentResults }, response) => {
              const { student } = response.studentContacted
              if (!student || student?.organization) return

              currentResults.students.data =
                currentResults.students.data.filter(s => s.id !== student.id)
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div>
          <CollapsibleContainer
            title={
              <label
                css={css`
                  font-size: 1.5rem;
                  margin: 0;
                `}
              >
                Non-Organization Students
              </label>
            }
            defaultCollapsed={false}
          >
            <StudentsTable students={data.students.data} />
            <Pagination
              totalPages={data.students.totalPages}
              currentPage={data.students.currentPage}
              changePage={setPage}
            />
          </CollapsibleContainer>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const studentsQuery = compress`
  ${studentFields}
  query($page: Int!) {
    students: studentSearch(page: $page, params: { uncontacted: true, noOrganization: true }) {
      totalPages
      currentPage
      data {
        ...StudentFields
      }
    }
  }
`

export default NonOrganizationStudents
