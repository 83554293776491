import React, { useRef } from "react"

import ReactTable from "react-table"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const AdminStudyGroupsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/study_groups",
    defaultFilters: props.defaultFilters,
  })
  const table = useRef(null)
  const { exportToCSV } = useExportable({
    apiEndpoint: "/api/admins/study_groups",
    defaultFilters: props.defaultFilters,
    tableRef: table.current,
  })
  const teacherSelect = useRef()
  const tutorSelect = useRef()
  const futureTutorSelect = useRef()
  const studentSelect = useRef()
  const clearableSelects = [
    teacherSelect.current,
    tutorSelect.current,
    futureTutorSelect.current,
    studentSelect.current,
  ]

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Cell: props => (
        <div>
          <div
            css={css`
              color: black;
            `}
          >
            {props.value}
          </div>
          {props.original.course_section && (
            <>
              <div>
                <LocalTime
                  timestamp={props.original.course_section.starts_on}
                  omitTime
                />{" "}
                &mdash;{" "}
                <LocalTime
                  timestamp={props.original.course_section.ends_on}
                  omitTime
                />
              </div>

              <ul className="list-unstyled">
                {props.original.course_section.availabilities.map(
                  availability => (
                    <li key={availability.id}>
                      {availability.weekday}{" "}
                      <LocalTime timestamp={availability.starts_at} omitDate />{" "}
                      &ndash;{" "}
                      <LocalTime timestamp={availability.ends_at} omitDate />
                    </li>
                  )
                )}
              </ul>
            </>
          )}
          <ul className="list-unstyled">
            {props.original.availabilities.map(availability => (
              <li key={availability.id}>
                {availability.weekday}{" "}
                <LocalTime timestamp={availability.starts_at} omitDate />{" "}
                &ndash; <LocalTime timestamp={availability.ends_at} omitDate />
              </li>
            ))}
          </ul>
        </div>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/admins/study_groups/autocomplete_name"
            searchOptions={
              props.defaultFilters?.active === "false" ? "active=false" : ""
            }
          />
        )
      },
    },
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization",
      Cell: props =>
        props.value ? (
          <a href={props.value.show_path}>{props.value.name}</a>
        ) : (
          "Public"
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/admins/organizations/autocomplete_name"
          />
        )
      },
    },
    {
      id: "teachers",
      Header: "Teachers",
      accessor: "teachers",
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "last_name").map(teacher => (
            <li key={teacher.id}>
              <a href={teacher.show_path}>{teacher.full_name}</a>
            </li>
          ))}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={teacherSelect}
            clearableSelects={clearableSelects}
            isClearable
            onChange={onChange}
            api="/api/admins/teachers/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "tutor",
      Header: "Tutor",
      accessor: "tutor",
      sortable: false,
      Cell: props =>
        props.value && (
          <a href={props.value.show_path}>{props.value.full_name}</a>
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            ref={tutorSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "future_tutor",
      Header: "Future Tutor",
      accessor: "future_study_group_tutor_replacements",
      sortable: false,
      Cell: props =>
        props.value && (
          <ul className="list-unstyled">
            {ordered(props.value, "new_tutor", "last_name").map(replacement => (
              <li key={replacement.id}>
                <a href={replacement.new_tutor.show_path}>
                  {replacement.new_tutor.full_name}
                  <br />
                  (Starts{" "}
                  <LocalTime timestamp={replacement.starts_at} omitTime />)
                </a>
              </li>
            ))}
          </ul>
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            ref={futureTutorSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "students",
      Header: "Students",
      accessor: "students",
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "last_name").map(student => (
            <li key={student.id}>
              <a href={student.show_path}>{student.full_name}</a>
            </li>
          ))}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            ref={studentSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/students/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="row">
        <div className="col">
          <button onClick={exportToCSV} className="btn btn-success">
            Export to CSV
          </button>
        </div>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default AdminStudyGroupsTable
