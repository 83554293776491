import React, { useEffect, useMemo, useRef, useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"
import { tutorClient } from "src/graphql-config"

import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const CompleteWorksheetButtons = ({ airtutorsSessionId }) => {
  const resourceDownloadLink = useRef()
  const [downloadUrl, setDownloadUrl] = useState("")
  const [errors, setErrors] = useState()

  useEffect(() => {
    if (downloadUrl === "") return
    resourceDownloadLink.current.click()
  }, [downloadUrl])

  const { runMutation: completeResource, running: completeResourceRunning } =
    useMutation(
      buildMutation(completeLessonResourceMutation, { client: tutorClient })
    )
  const { runMutation: downloadResource, running: downloadResourceRunning } =
    useMutation(
      buildMutation(downloadLessonResourceMutation, { client: tutorClient })
    )

  const loadingState = useQuery(
    buildQuery(
      currentLessonResourcesQuery,
      { airtutorsSessionId },
      {
        client: tutorClient,
        onMutation: [
          {
            when: "completeLessonResource",
            run: ({ softReset, currentResults }, response) => {
              if (response.completeLessonResource?.errorMessages) {
                setErrors(response.completeLessonResource.errorMessages)
              } else {
                setErrors()
              }

              if (response.completeLessonResource?.airtutorsSession) {
                currentResults.airtutorsSession =
                  response.completeLessonResource.airtutorsSession
                softReset(currentResults)
              }
              if (response.completeLessonResource?.nextResource) {
                const nextResource =
                  response.completeLessonResource.nextResource
                downloadResource({
                  airtutorsSessionId,
                  lessonResourceId: nextResource.id,
                }).then(response => {
                  setDownloadUrl(
                    response.downloadLessonResource.lessonResource.file
                      .downloadUrl
                  )
                })
              }
            },
          },
        ],
      }
    )
  )

  const channelProps = useMemo(() => {
    return { id: airtutorsSessionId }
  }, [airtutorsSessionId])

  useWebsocket({
    channelName: "AirtutorsSessionChannel",
    channelProps,
  })

  const reload = useRef()
  reload.current = loadingState.reload

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      reload.current()
    })
  }, [])

  return (
    <>
      <a
        ref={resourceDownloadLink}
        href={downloadUrl}
        download
        css={css`
          display: none !important;
        `}
      >
        download
      </a>
      <WithLoadingIndicator loadingState={loadingState}>
        {({ data }) =>
          data.airtutorsSession.currentLessonResources.map(lessonResource => (
            <ModalWithProvidedBody
              key={lessonResource.id}
              buttonText="Mark Worksheet Completed"
              buttonClassName="btn solid orange w-100"
              modalClassName="bootstrap-modal"
              modalTitle={`Complete ${lessonResource.name}`}
              afterClose={() => setErrors()}
            >
              {({ closeModal }) => (
                <>
                  <div className="modal-body">
                    <p>
                      This will complete {lessonResource.name} and automatically
                      download the next worksheet.
                    </p>
                    <p>
                      So make sure to let students know to complete the survey
                      at the end of this session!
                    </p>
                  </div>

                  <div
                    className="modal-footer"
                    css={css`
                      display: block !important;
                    `}
                  >
                    {errors && <div className="alert mb-3 p-3">{errors}</div>}
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <button className="btn solid red" onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        className="btn solid blue"
                        disabled={
                          completeResourceRunning || downloadResourceRunning
                        }
                        onClick={() => {
                          completeResource({
                            airtutorsSessionId,
                            lessonResourceId: lessonResource.id,
                          })
                        }}
                      >
                        <span>Complete + Download</span>
                        {(completeResourceRunning ||
                          downloadResourceRunning) && (
                          <div
                            css={css`
                              display: inline-block;
                              margin-left: 15px;
                            `}
                          >
                            <Loading />
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </ModalWithProvidedBody>
          ))
        }
      </WithLoadingIndicator>
    </>
  )
}

const currentLessonResourcesQuery = compress`
  query($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      currentLessonResources {
        id
        name
      }
    }
  }
`

const completeLessonResourceMutation = compress`
  mutation($airtutorsSessionId: ID!, $lessonResourceId: ID!) {
    completeLessonResource(airtutorsSessionId: $airtutorsSessionId, lessonResourceId: $lessonResourceId) {
      errorMessages
      airtutorsSession {
        currentLessonResources {
          id
          name
        }
      }
      nextResource {
        id
        name
        file {
          downloadUrl
          fileName
        }
      }
    }
  }
`

const downloadLessonResourceMutation = compress`
  mutation($airtutorsSessionId: ID!, $lessonResourceId: ID!) {
    downloadLessonResource(airtutorsSessionId: $airtutorsSessionId, lessonResourceId: $lessonResourceId) {
      lessonResource {
        id
        file {
          downloadUrl
        }
      }
    }
  }
`

export default CompleteWorksheetButtons
