import React from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { css } from "@emotion/core"

import { Loading } from "components/Icons"

const RemoveSection = ({ id }) => {
  const { runMutation, running } = useMutation(
    buildMutation(removeSectionMutation)
  )

  return (
    <button
      onClick={() => runMutation({ id })}
      className="btn btn-danger"
      disabled={running}
    >
      <span>Remove Section</span>
      {running && (
        <div
          css={css`
            display: inline-block;
            margin-left: 15px;
          `}
        >
          <Loading />
        </div>
      )}
    </button>
  )
}

const removeSectionMutation = compress`
  mutation($id: ID!) {
    removeCourseSection(id: $id) {
      errorMessages
      courseSection {
        id
      }
    }
  }
`

export default RemoveSection
