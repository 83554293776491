import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import dayjs from "src/dayjs"

import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  DateField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

import QuestionSelection from "./QuestionSelection"

const ValidationSchema = Yup.object().shape({
  organizationId: Yup.string().required("Please select an organization"),
  questionIds: Yup.array()
    .of(Yup.string())
    .min(1, "At least one question is required"),
  name: Yup.string().required(
    "Please add a descriptive name for administration purposes"
  ),
  startsOn: Yup.date().required("Please select the start date for this survey"),
})

type NewSurveyProps = Record<string, never>

const NewSurvey: React.FC<NewSurveyProps> = () => {
  const formState = {
    name: "",
    organizationId: "",
    questionIds: [],
    startsOn: dayjs().add(1, "week").startOf("day").toDate(),
  }

  const [, createSurvey]: any[] = useMutation(createSurveyMutation)

  const handleSubmit = (values, actions) => {
    const params = { ...values }
    if (params.startsOn.toDate !== undefined) {
      params.startsOn = params.startsOn.toDate()
    }

    createSurvey({ input: params })
      .then(
        result => {
          const { failures, survey } = result.data.createSurvey
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            window.location = survey.showPath
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <div className="md:w-2/3 lg:w-1/2">
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        <Form>
          <Field name="name" label="Name (for admin use only)" autoFocus />
          <AutocompleteField
            api="/api/admins/organizations/autocomplete_name"
            name="organizationId"
            label="Organization"
          />
          <DateField
            name="startsOn"
            label="What date should the survey start to appear?"
          />
          <QuestionSelection />

          <FormFailures />
          <SubmitButton text="Create Survey" />
        </Form>
      </Formik>
    </div>
  )
}

const createSurveyMutation = gql`
  mutation CreateSurvey($input: SurveyInputObject!) {
    createSurvey(input: $input) {
      failures {
        message
      }
      survey {
        id
        showPath
      }
    }
  }
`

export default NewSurvey
