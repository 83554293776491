import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { publicClient } from "src/graphql-config"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import Form from "./Form"

const StudentIntake = ({
  organization: { id: organizationId },
  tier: { id: tierId },
}) => {
  const loadingState = useQuery(
    buildQuery(
      studentIntakeQuery,
      { organizationId, tierId },
      { client: publicClient }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => <Form data={data} />}
    </WithLoadingIndicator>
  )
}

const studentIntakeQuery = compress`
  query($organizationId: ID!, $tierId: ID!) {
    grades(tierId: $tierId) {
      id
      name
      number
    }
    organization(id: $organizationId) {
      id
      intakeBlurb
      minimumAvailabilityWindows
      allowedDomains
      intakeParentRequired
      emailTimeZone
      consentText
      parentConsentRequired
      studentConsentRequired
      consentDocuments {
        id
        downloadUrl
        filename
      }
      schools {
        id
        name
        permittedGrades {
          id
        }
      }
      teachers {
        id
        fullName
        firstName
        lastName
        schools {
          id
        }
        teacherSubjects {
          id
          subject {
            id
            name
          }
        }
      }
    }
  }
`

StudentIntake.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  tier: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
}

export default StudentIntake
