import React, { ReactNode } from "react"

export type FieldDescriptionProps = {
  description: string | ReactNode
}

const FieldDescription: React.FC<FieldDescriptionProps> = ({ description }) => {
  return (
    <div className="flex h-full items-center">
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  )
}

export default FieldDescription
