import React from "react"

import { useField } from "formik"

import { weekdayOptions } from "src/enums"

import { Field, SelectField } from "components/Forms/Formik/hookComponents"

const WeeklySettings = () => {
  const [mode] = useField("reportScheduleMode")
  const [weekdayField] = useField("reportWeekday")

  if (mode.value !== "weekly") return null

  return (
    <>
      <Field
        name="reportWeeklyFrequency"
        label="Weekly Frequency"
        type="number"
        min={1}
      />
      <SelectField
        name="reportWeekday"
        label="Day of Week"
        options={weekdayOptions}
        defaultValue={weekdayOptions.find(o => o.value === weekdayField.value)}
        menuPortalTarget={document.body}
      />
    </>
  )
}

export default WeeklySettings
