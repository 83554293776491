import { compress } from "micro-graphql-react"

export default compress`
  fragment StudyGroupFields on StudyGroup {
    id
    name
    showPath
    students {
      id
      fullName
      lastName
      showPath
    }
    tutor {
      id
      fullName
      lastName
      showPath
    }
  }
`
