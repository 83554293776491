import React, { useCallback, useEffect, useRef, useState } from "react"

import Ding from "assets/ding.mp3"
import { Composition } from "atomic-layout"
import { Map as ImmutableMap } from "immutable"
import Div100Vh from "react-div-100vh"
import { NotificationContainer } from "react-notifications"
import * as colors from "styles/colors"
import Video from "twilio-video"

import { useDisclosure } from "@chakra-ui/core"
import { css } from "@emotion/core"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EventEmitter from "src/EventEmitter"

import LoadingIndicator from "components/LoadingIndicator"
import RealtimeboardDirectLink from "components/RealtimeboardDirectLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"
import EndSession from "components/TwilioCloudRoom/Admin/EndSession"
import AudioButton from "components/TwilioCloudRoom/AudioButton"
import ErrorModal from "components/TwilioCloudRoom/ErrorModal"
import RemoteParticipant from "components/TwilioCloudRoom/RemoteParticipant"
import VideoButton from "components/TwilioCloudRoom/VideoButton"
import useCloudRoom from "components/TwilioCloudRoom/hooks/useCloudRoom"
import useScreenShareActive from "components/TwilioCloudRoom/hooks/useScreenShareActive"

import MissingTutorModal from "./MissingTutorModal"
import ScreenShareModal from "./ScreenShareModal"
import SessionCanceledModal from "./SessionCanceledModal"
import SessionReassignmentModal from "./SessionReassignmentModal"
import StudentAssessmentModal from "./StudentAssessmentModal"
import SurveyModal from "./SurveyModal"
import styles from "./styles.module.scss"

import "react-notifications/lib/notifications.css"

const StudentCloudRoom = props => {
  const {
    id,
    startsAt,
    currentUserId,
    boardId,
    googledocUrl,
    ssoEnabled,
    ssoEmail,
    studentScreenShareDisabled,
    phoneNumber,
    missingTutor,
    userType,
    surveyId,
  } = props

  const {
    isOpen: screenShareModalIsOpen,
    onOpen: screenShareModalOnOpen,
    onClose: screenShareModalOnClose,
  } = useDisclosure()
  const {
    isOpen: studentAssessmentModalIsOpen,
    onOpen: studentAssessmentModalOnOpen,
    onClose: studentAssessmentModalOnClose,
  } = useDisclosure()
  const {
    isOpen: surveyModalIsOpen,
    onOpen: surveyModalOnOpen,
    onClose: surveyModalOnClose,
  } = useDisclosure()
  const {
    isOpen: sessionReassignmentModalIsOpen,
    onOpen: sessionReassignmentModalOnOpen,
    onClose: sessionReassignmentModalOnClose,
  } = useDisclosure()
  const {
    isOpen: sessionCanceledModalIsOpen,
    onOpen: sessionCanceledModalOnOpen,
    onClose: sessionCanceledModalOnClose,
  } = useDisclosure()
  const {
    isOpen: missingTutorModalIsOpen,
    onOpen: missingTutorModalOnOpen,
    onClose: missingTutorModalOnClose,
  } = useDisclosure(missingTutor)
  const previewRef = useRef(null)
  const screenShareTrack = useRef(null)
  const [error, setError] = useState()
  const [screenSharing, setScreenSharing] = useState(false)
  const [screenShareFailed, setScreenShareFailed] = useState(false)
  const [screenShareRequestPending, setScreenShareRequestPending] =
    useState(false)
  const [screenShareDisabled, setScreenShareDisabled] = useState(
    studentScreenShareDisabled
  )
  const [sessionReassignmentPath, setSessionReassignmentPath] = useState()
  const reassignmentTimeout = useRef()
  const [audioMuted, setAudioMuted] = useState(props.audioMuted)
  const [videoMuted, setVideoMuted] = useState(props.videoMuted)
  const [controlsLocked, setControlsLocked] = useState(props.controlsLocked)
  const [users, setUsers] = useState(ImmutableMap())

  const { room, localVideoTrack, channel } = useCloudRoom({
    previewRef,
    setUsers,
    setError,
    ...props,
  })
  const screenShareActive = useScreenShareActive(users)

  const requestScreenShare = () => {
    setScreenShareRequestPending(true)
    channel.perform("screen_share_request", {
      userId: currentUserId,
    })

    setTimeout(() => {
      setScreenShareRequestPending(false)
    }, 10000)
  }

  const disableScreenShare = useCallback(
    data => {
      room.localParticipant.unpublishTrack(screenShareTrack.current)
      room.localParticipant.publishTrack(localVideoTrack.current)
      screenShareTrack.current.stop()
      setScreenSharing(false)
    },
    [localVideoTrack, room]
  )

  useEffect(() => {
    if (!room) return

    EventEmitter.subscribe("mute_all", data => {
      if (data.type === "audio") setAudioMuted(true)
      if (data.type === "video") setVideoMuted(true)
    })

    EventEmitter.subscribe("mute_user", data => {
      if (data.userId !== currentUserId) return
      if (data.type === "audio") setAudioMuted(true)
      if (data.type === "video") setVideoMuted(true)
    })

    EventEmitter.subscribe("unmute_all", data => {
      if (data.type === "audio") setAudioMuted(false)
      if (data.type === "video") setVideoMuted(false)
      setControlsLocked(false)
    })

    EventEmitter.subscribe("unmute_user", data => {
      if (data.userId !== currentUserId) return
      if (data.type === "audio") setAudioMuted(false)
      if (data.type === "video") setVideoMuted(false)
    })

    EventEmitter.subscribe("mute_all", data => {
      setControlsLocked(true)
    })

    EventEmitter.subscribe("unlock_user", data => {
      if (data.userId !== currentUserId) return
      setControlsLocked(false)
    })

    EventEmitter.subscribe("lock_user", data => {
      if (data.userId !== currentUserId) return
      setControlsLocked(true)
    })

    EventEmitter.subscribe("lock_all", data => {
      setControlsLocked(true)
    })

    EventEmitter.subscribe("unlock_all", data => {
      setControlsLocked(false)
    })

    EventEmitter.subscribe("screen_share_response", async data => {
      setScreenShareRequestPending(false)
      if (data.approved) {
        try {
          const constraints = { video: true }
          const tracks = await navigator.mediaDevices.getDisplayMedia(
            constraints
          )
          screenShareTrack.current = tracks.getTracks()[0]
          screenShareTrack.current.onended = () => disableScreenShare()

          room.localParticipant.unpublishTrack(localVideoTrack.current)
          const namedTrack = Video.LocalVideoTrack(screenShareTrack.current, {
            name: "screenshare",
          })
          room.localParticipant.publishTrack(namedTrack)
          setScreenSharing(true)
        } catch (e) {
          setScreenShareFailed(true)
          screenShareModalOnOpen()
        }
      } else {
        screenShareModalOnOpen()
        setScreenShareDisabled(true)
      }
    })

    EventEmitter.subscribe("end_screen_share", () => {
      disableScreenShare()
    })

    EventEmitter.subscribe("end_session", data => {
      window.location = data.studentRedirectTo
    })

    EventEmitter.subscribe("student_assessment", () => {
      studentAssessmentModalOnOpen()
    })

    EventEmitter.subscribe("student_survey", () => {
      surveyModalOnOpen()
    })

    EventEmitter.subscribe("missing_tutor", () => {
      missingTutorModalOnOpen()
    })

    EventEmitter.subscribe("session_reassignment", data => {
      missingTutorModalOnClose()
      new Audio(Ding).play()
      setSessionReassignmentPath(data.redirectTo)
      sessionReassignmentModalOnOpen()
      reassignmentTimeout.current = setTimeout(() => {
        window.location = data.redirectTo
      }, 5000)
    })

    EventEmitter.subscribe("session_canceled", () => {
      missingTutorModalOnClose()
      new Audio(Ding).play()
      sessionCanceledModalOnOpen()
    })
  }, [
    currentUserId,
    disableScreenShare,
    localVideoTrack,
    screenShareModalOnOpen,
    studentAssessmentModalOnOpen,
    surveyModalOnOpen,
    room,
    sessionReassignmentModalOnOpen,
    sessionCanceledModalOnOpen,
    missingTutorModalOnOpen,
    missingTutorModalOnClose,
  ])

  const tutors = Array.from(users.values()).filter(
    u => u.type === "tutor" || u.type === "teacher"
  )
  const students = Array.from(users.values()).filter(
    u => u.type !== "tutor" && u.type !== "teacher"
  )

  useEffect(() => {
    if (tutors.length > 0) missingTutorModalOnClose()
  }, [tutors, missingTutorModalOnClose])

  let primaryUsers = tutors
  let otherUsers = students
  const usersArray = Array.from(users.values())
  if (screenShareActive) {
    const screenShareUser = usersArray.filter(
      u => u.videoTrack && u.videoTrack.name === "screenshare"
    )[0]
    primaryUsers = [screenShareUser]
    otherUsers = tutors
      .concat(students)
      .filter(u => !u.videoTrack || u.videoTrack.name !== "screenshare")
  }

  let desktopLayout = `
    main other
    main buttons
  `

  let mobileLayout = `
    main
    other
    buttons
  `

  const realtimeboardButton = ssoEnabled ? (
    <>
      <TimeProtectedMiroLink
        email={ssoEmail}
        boardId={boardId}
        startsAt={startsAt}
      />
    </>
  ) : (
    <RealtimeboardDirectLink boardId={boardId} />
  )
  const googledocButton = googledocUrl ? (
    <a
      className="btn solid green"
      href={googledocUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      Google Doc
    </a>
  ) : null

  if (error) {
    return (
      <ErrorModal
        isOpen={true}
        onClose={() => window.location.reload()}
        userType="Student"
        sessionId={id}
        phoneNumber={phoneNumber}
        {...error}
      />
    )
  }

  return (
    <Div100Vh className={styles.pageContainer}>
      <NotificationContainer />
      {!room ? (
        <div
          css={css`
            padding-top: 20vh;
            i {
              font-size: 64px;
              color: white;
            }
          `}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <ScreenShareModal
            isOpen={screenShareModalIsOpen}
            screenShareFailed={screenShareFailed}
            onClose={() => {
              setScreenShareFailed(false)
              screenShareModalOnClose()
            }}
          />
          <StudentAssessmentModal
            isOpen={studentAssessmentModalIsOpen}
            onClose={studentAssessmentModalOnClose}
            sessionId={id}
          />
          {surveyId && (
            <SurveyModal
              isOpen={surveyModalIsOpen}
              onClose={surveyModalOnClose}
              sessionId={id}
            />
          )}
          <SessionReassignmentModal
            isOpen={sessionReassignmentModalIsOpen}
            onClose={sessionReassignmentModalOnClose}
            redirectTo={sessionReassignmentPath}
            timeout={reassignmentTimeout}
          />
          <SessionCanceledModal
            isOpen={sessionCanceledModalIsOpen}
            onClose={sessionCanceledModalOnClose}
          />
          <MissingTutorModal
            isOpen={missingTutorModalIsOpen}
            onClose={missingTutorModalOnClose}
          />

          <Composition
            areas={mobileLayout}
            areasSm={desktopLayout}
            templateCols="1fr"
            templateRows="50% auto"
            templateColsSm="60% auto"
            templateRowsSm="1fr"
            className={styles.gridContainer}
          >
            {({ Main, Other, Buttons }) => (
              <React.Fragment>
                <div className="absolute top-2 left-2 z-10 h-min" height={100}>
                  {userType === "Admin" && (
                    <EndSession airtutorsSessionId={id} userType={userType} />
                  )}
                </div>
                <Main
                  flex
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  className={styles.tutorContainer}
                >
                  {primaryUsers.map(user => (
                    <RemoteParticipant
                      key={user.identity}
                      user={user}
                      api={props.api}
                      {...props}
                    />
                  ))}
                </Main>

                <Other
                  templateCols="1fr 1fr"
                  gapCol={25}
                  alignItems="start"
                  className={styles.studentsContainer}
                >
                  <React.Fragment>
                    {!props.ghost && (
                      <div className={styles.previewContainer}>
                        <div ref={previewRef} />
                        <div className={styles.muteButtonContainer}>
                          <div
                            css={css`
                              position: absolute;
                              bottom: 6px;
                              right: 6px;
                            `}
                          >
                            <AudioButton
                              room={room}
                              channel={channel}
                              muted={audioMuted}
                              setMuted={setAudioMuted}
                              disabled={controlsLocked}
                            />
                            <VideoButton
                              room={room}
                              channel={channel}
                              muted={videoMuted}
                              setMuted={setVideoMuted}
                              disabled={controlsLocked}
                              controls={false}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {otherUsers.map((user, index) => {
                      return (
                        <RemoteParticipant
                          key={`${user.identity}-${index}`}
                          user={user}
                          api={props.api}
                          {...props}
                        />
                      )
                    })}
                  </React.Fragment>
                </Other>

                <Buttons>
                  <div
                    css={css`
                      margin-top: 10px;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        a {
                          margin-bottom: 6px;
                        }
                      `}
                    >
                      {realtimeboardButton}
                      {googledocButton}
                    </div>
                    {!screenShareDisabled && (
                      <div
                        css={css`
                          margin-top: 10px;
                        `}
                      >
                        {screenSharing ? (
                          <button
                            className="btn solid red"
                            onClick={disableScreenShare}
                          >
                            Stop Screen Share
                          </button>
                        ) : (
                          <button
                            className="btn solid blue"
                            onClick={requestScreenShare}
                            disabled={screenShareRequestPending}
                            style={{
                              backgroundColor: colors.blue,
                              color: "white",
                            }}
                          >
                            Share Your Screen
                            {screenShareRequestPending && (
                              <FontAwesomeIcon
                                icon={faCircleNotch}
                                spin
                                pull="right"
                                style={{ animationDuration: "1s" }}
                              />
                            )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Buttons>
              </React.Fragment>
            )}
          </Composition>
        </>
      )}
    </Div100Vh>
  )
}

StudentCloudRoom.defaultProps = {
  api: "students",
}

export default StudentCloudRoom
