import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import {
  DateRangeField,
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  holidays: Yup.array().min(1, "Please select at least one day"),
})

const AddHolidays = ({ organizationId }) => {
  const formState = {
    organizationId,
    holidays: [],
  }
  const { runMutation } = useMutation(buildMutation(addHolidaysMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const {
      holidays: [startsOn, endsOn],
      ...rest
    } = values

    const variables = {
      ...rest,
      startsOn: moment(startsOn.toDate()).startOf("day").format(),
    }
    if (endsOn) {
      variables.endsOn = moment(endsOn.toDate()).startOf("day").format()
    }

    runMutation(variables)
      .then(
        response => {
          const { errorMessages } = response.organizationAddHolidays
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => {
          actions.setSubmitting(false)
          actions.setStatus("Something went wrong")
        }
      )
      .catch(() => {
        actions.setSubmitting(false)
        actions.setStatu("Something went wrong")
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Holiday"
      buttonText="Add Holiday"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <DateRangeField name="holidays" label="Holidays" />
            </div>
            <div className="modal-footer flex-column">
              <FormStatus />
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const addHolidaysMutation = compress`
  mutation($organizationId: ID!, $startsOn: Date!, $endsOn: Date) {
    organizationAddHolidays(organizationId: $organizationId, startsOn: $startsOn, endsOn: $endsOn) {
      errorMessages
      organization {
        id
        holidays
      }
    }
  }
`

export default AddHolidays
