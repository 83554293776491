import React from "react"

import { useField, useFormikContext } from "formik"

import ErrorMessage from "./ErrorMessage"

const FileField = props => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  // exclude onChange and value field props
  const { onChange, value, ...fieldProps } = field

  return (
    <div className="form-group">
      <label className="form-label w-100">
        <span>{props.label}</span>
        <input
          type="file"
          className="form-control"
          onChange={e => setFieldValue(props.name, e.currentTarget.files[0])}
          {...fieldProps}
          {...props}
        />
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

const readFile = field => {
  const fileExists = field?.name || field?.fileName
  const reader = new FileReader()
  const promise = new Promise(resolve => {
    if (!fileExists) {
      resolve()
      return
    }

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      const fileObject = { file: b64 }
      const filename = field.name || field.fileName
      const extension = (field.name || field.fileName).split(".").pop()
      if (!filename) {
        fileObject.filename = `${filename}.${extension}`
      } else {
        fileObject.filename = filename
      }
      resolve(fileObject)
    }
  })
  if (fileExists) {
    reader.readAsDataURL(field)
  }
  return promise
}

export default FileField
export { readFile }
