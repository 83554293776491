import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $sourceUserId: ID!,
    $matchUserId: ID!,
  ) {
    unmatchUser(
      studentId: $sourceUserId,
      tutorId: $matchUserId,
    ) {
      tutor {
        id
      }
    }
  }
`
