import React from "react"

import { Box } from "@chakra-ui/core"

const Layer = props => {
  const { children, ...other } = props
  return (
    <Box
      bg="gray.100"
      borderRadius="md"
      p="4"
      mb="8"
      overflowX="auto"
      boxShadow="sm"
      {...other}
    >
      {children}
    </Box>
  )
}

export default Layer
