import React, { useRef } from "react"

import { motion } from "framer-motion"

import ordered from "src/ordered"

import { gql, useMutation, useQuery } from "hooks/urql"

import EditableList, { UrqlItem } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const SchoolMemberships = ({ teacherId }) => {
  const selectRef = useRef(null)
  const [, addSchool] = useMutation(addSchoolMutation)
  const [result] = useQuery({ query: schoolsQuery, variables: { teacherId } })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <h4 className="mt-0">Schools</h4>
          <EditableList>
            {ordered(data.teacher.schools, "name").map(school => (
              <motion.li key={school.id} positionTransition>
                <UrqlItem
                  mutation={removeSchoolMutation}
                  mutationProps={{
                    teacherId,
                    schoolId: school.id,
                  }}
                >
                  {school.name}
                </UrqlItem>
              </motion.li>
            ))}
          </EditableList>
          <div className="mb-3">
            <AutocompleteSelect
              ref={selectRef}
              onChange={option => {
                if (!option?.value) return
                addSchool({ teacherId, schoolId: option.value }).then(() => {
                  selectRef.current.clearValue()
                })
              }}
              withLoadingIndicator
              api="/api/admins/schools/autocomplete_name"
              searchOptions={`organization_id=${data.teacher.organization.id}`}
              placeholder="Add School"
            />
          </div>
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const schoolsQuery = gql`
  query ($teacherId: ID!) {
    teacher(id: $teacherId) {
      schools {
        id
        name
      }
      organization {
        id
      }
    }
  }
`

const addSchoolMutation = gql`
  mutation ($teacherId: ID!, $schoolId: ID!) {
    teacherAddSchool(teacherId: $teacherId, schoolId: $schoolId) {
      teacher {
        schools {
          id
          name
        }
      }
    }
  }
`

const removeSchoolMutation = gql`
  mutation ($teacherId: ID!, $schoolId: ID!) {
    teacherRemoveSchool(teacherId: $teacherId, schoolId: $schoolId) {
      teacher {
        schools {
          id
          name
        }
      }
    }
  }
`
export default SchoolMemberships
