import styled from "styled-components"

const ActionContainer = styled.div`
  margin-right: 1.25rem;
  padding: 0.25rem;
  button {
    margin-right: 2px !important;
  }
`

export default ActionContainer
