import React from "react"

import { useField, useFormikContext } from "formik"

import { TimePicker } from "components/Forms/DatePicker"

import ErrorMessage from "./ErrorMessage"

const TimeField = props => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  return (
    <div className="form-group">
      <label className="form-label">
        <span>{props.label}</span>
        <TimePicker
          {...field}
          onChange={date => setFieldValue(field.name, date)}
          {...props}
        />
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default TimeField
