const classNames = (...classes) => {
  return classes
    .filter(Boolean)
    .map(s => s.split("\n"))
    .flat()
    .map(s => s.trim())
    .filter(Boolean) // remove empty strings
    .join(" ")
}

export default classNames
