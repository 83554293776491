import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import {
  AutocompleteField,
  Field,
  FileField,
  FormFailures,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

const validationSchema = Yup.object().shape({
  internalName: Yup.string().required("Internal name is required"),
  tagList: Yup.array().of(Yup.string()).min(1, "At least one tag is required"),
})

const TailoredQuestionGroupForm = ({ internalName, tagList, handleSubmit }) => {
  const initialValues = {
    internalName,
    tagList,
    diagrams: [],
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Field
            name="internalName"
            label="Internal Name (must be unique)"
            autoFocus
          />
          <p className="mb-1 font-semibold italic">
            Please upload files in the order in which they should be displayed
          </p>
          {[...values.diagrams, undefined].map((_, idx) => {
            return (
              <FileField
                key={idx}
                name={`diagrams[${idx}]`}
                label={`Diagram/Image ${idx + 1}`}
              />
            )
          })}
          <AutocompleteField
            name="tagList"
            label="Tags"
            api="/api/admins/tailored_question_groups/autocomplete_unified_tags"
            valueAttribute="label"
            selected={tagList.map(tag => ({ label: tag, value: tag }))}
            creatable
            isMulti
          />
          <FormFailures />
          <SubmitButton text="Save Group" />
        </Form>
      )}
    </Formik>
  )
}

export default TailoredQuestionGroupForm
