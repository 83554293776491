import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import ordered from "src/ordered"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import {
  AutocompleteField,
  CheckboxField,
  Field,
  FormFailures,
  GradeField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import SlackChannelHint from "components/SlackChannelHint"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import MergeSchoolsModal from "./MergeSchoolsModal"
import PaginatedStudentList from "./PaginatedStudentList"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const EditSchool = ({ id }) => {
  const [result] = useQuery({
    query: schoolQuery,
    variables: { id },
  })

  const buildFormState = school => ({
    id: id,
    name: school.name || "",
    organizationId: school.organization?.id || "",
    onDemandEnabled: school.onDemandEnabled || false,
    chimeCloudroomEnabled: school.chimeCloudroomEnabled,
    hourlyRate: school.hourlyRate || "",
    slackChannelId: school.slackChannelId || "",
    startingGradeId: school.startingGrade?.id || "",
    endingGradeId: school.endingGrade?.id || "",
  })

  const client = useClient()
  const [, updateSchool] = useMutation(updateSchoolMutation, client)
  const handleSubmit = (values, actions) => {
    const params = { ...values }
    if (params.hourlyRate === "") delete params.hourlyRate

    updateSchool(params)
      .then(result => {
        if (result.error) {
          handleFailure(actions, [{ message: result.error.message }])
          return
        }

        const { failures } = result.data.updateSchool
        if (failures) handleFailure(actions, failures)
        else window.location.reload()
      })
      .catch(e => handleFailure(actions, [e]))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { school, tiers } }) => (
        <div className="grid gap-8 sm:grid-cols-2">
          <div>
            <Formik
              initialValues={buildFormState(school)}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Field name="name" label="Name" autoFocus />
                <AutocompleteField
                  name="organizationId"
                  label="Organization"
                  api="/api/admins/organizations/autocomplete_name"
                  defaultValue={
                    school.organization
                      ? {
                          label: school.organization.name,
                          value: school.organization.id,
                        }
                      : null
                  }
                />
                <Field type="number" name="hourlyRate" label="Hourly Rate" />
                <Field name="slackChannelId" label="Slack Channel ID" />
                <SlackChannelHint />
                <GradeField
                  name="startingGradeId"
                  label="Grade Range Start"
                  tiers={tiers}
                  defaultGradeId={school.startingGrade.id}
                />
                <GradeField
                  name="endingGradeId"
                  label="Grade Range End"
                  tiers={tiers}
                  defaultGradeId={school.endingGrade.id}
                />
                <CheckboxField
                  name="chimeCloudroomEnabled"
                  label="Use Chime Cloudrooms"
                />
                <CheckboxField
                  name="onDemandEnabled"
                  label="On Demand Enabled"
                />
                <FormFailures />
                <SubmitButton text="Update School" className="btn btn-danger" />
              </Form>
            </Formik>
            <MergeSchoolsModal school={school} />
          </div>

          <div className="grid gap-4 md:grid-cols-2">
            <PaginatedStudentList schoolId={school.id} />

            <div>
              <h4 className="mt-0">Teachers</h4>
              {school.teachers.length === 0 ? (
                <p className="italic">No teachers</p>
              ) : (
                <ul className="list-unstyled">
                  {ordered(school.teachers, "lastName").map(
                    ({ id, fullName, showPath }) => (
                      <li key={id}>
                        <a href={showPath}>{fullName}</a>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </UrqlLoadingIndicator>
  )
}

const schoolQuery = gql`
  query ($id: ID!) {
    school(id: $id) {
      id
      name
      hourlyRate
      slackChannelId
      onDemandEnabled
      chimeCloudroomEnabled
      startingGrade {
        id
      }
      endingGrade {
        id
      }
      organization {
        id
        name
      }
      teachers {
        id
        fullName
        lastName
        showPath
      }
    }

    tiers {
      id
      gradeRange
      grades {
        id
        name
        number
      }
    }
  }
`

const updateSchoolMutation = gql`
  mutation (
    $id: ID!
    $name: String!
    $organizationId: ID!
    $chimeCloudroomEnabled: Boolean!
    $onDemandEnabled: Boolean!
    $hourlyRate: Float
    $slackChannelId: String!
    $startingGradeId: ID!
    $endingGradeId: ID!
  ) {
    updateSchool(
      id: $id
      name: $name
      organizationId: $organizationId
      chimeCloudroomEnabled: $chimeCloudroomEnabled
      onDemandEnabled: $onDemandEnabled
      hourlyRate: $hourlyRate
      slackChannelId: $slackChannelId
      startingGradeId: $startingGradeId
      endingGradeId: $endingGradeId
    ) {
      failures {
        message
      }
    }
  }
`

export default EditSchool
