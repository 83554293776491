import { Notifier } from "@airbrake/browser"

const airbrake = new Notifier({
  projectId:
    process.env.NODE_ENV === "production"
      ? process.env.AIRBRAKE_PROJECT_ID
      : "test",
  projectKey:
    process.env.NODE_ENV === "production"
      ? process.env.AIRBRAKE_API_KEY
      : "test",
})
airbrake.addFilter(notice => {
  if (notice.errors[0].message.match(/Extension context invalidated/i)) {
    return null
  }
  notice.context.environment = process.env.AIRBRAKE_ENV
  const metadata = document.querySelector("meta[name=airtutors]")?.dataset
  notice.session.user = {
    id: metadata?.id,
    type: metadata?.type,
  }
  return notice
})

export default airbrake

const reportGraphQLError = ({
  result,
  component = "Unknown",
  action = "Root Query",
  ...args
}) => {
  const params = {
    component,
    action,
    args,
    query: result.operation.query.loc.source.body,
    variables: result.operation.variables,
  }

  airbrake.notify({
    error: result.error,
    name: `${component}#${action}`,
    params,
  })

  if (process.env.NODE_ENV === "development") {
    logger.error({ result, component, action, args })
  }
}
export { reportGraphQLError }
