import React from "react"

import { FieldArray, useField, useFormikContext } from "formik"

import { ErrorMessage } from "components/Forms/Formik"

import AvailabilityInput from "./AvailabilityInput"

const AvailabilitiesInput = () => {
  const { values } = useFormikContext()
  const [, meta] = useField({ name: "availabilities" })

  return (
    <FieldArray name="availabilities">
      {arrayHelpers => (
        <div className="form-group">
          {values.availabilities.map((availability, index) => (
            <AvailabilityInput
              key={index}
              availability={availability}
              arrayHelpers={arrayHelpers}
              index={index}
            />
          ))}

          <button
            type="button"
            onClick={() => {
              arrayHelpers.push({
                id: Date.now(),
                startsAt: null,
                endsAt: null,
                weekday: "",
              })
            }}
            className="btn btn-info"
          >
            Add Availability
          </button>
          <div className="mt-3">
            <ErrorMessage {...meta} />
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export default AvailabilitiesInput
