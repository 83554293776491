import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation, useQuery } from "hooks/urql"

import { SubmitButton } from "components/Forms/Formik"
import {
  AutocompleteField,
  FormFailures,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const ValidationSchema = Yup.object().shape({
  organizationId: Yup.string().required("Organization is required"),
  tutorId: Yup.string().required("Tutor is required"),
  studentId: Yup.string().required("Student is required"),
})

const OneOnOneForm = () => {
  const [result] = useQuery({ query: viewerQuery })
  const formState = {
    name: "",
    details: "",
    organizationId: "",
    tutorId: "",
    studentId: "",
    subjectIds: [],
    tagList: [],
    budgetId: "",
    minimumStudentsThreshold: 1,
  }
  const [, runMutation] = useMutation(createOneonOneMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(response => {
        const { failures, redirectTo } = response.data.oneOnOneStudyGroupCreate
        if (failures) {
          handleFailure(actions, failures)
        } else {
          window.location = redirectTo
        }
      })
      .catch(() => handleFailure(actions))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <AutocompleteField
                      name="organizationId"
                      label="Organization"
                      api="/api/admins/organizations/autocomplete_name"
                      placeholder="Select Organization"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <AutocompleteField
                      name="studentId"
                      label="Student"
                      api="/api/admins/students/autocomplete_full_name"
                      searchOptions={
                        values.organizationId
                          ? `organization_id=${values.organizationId}`
                          : null
                      }
                      placeholder="Select Student"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <AutocompleteField
                      name="tutorId"
                      label="Tutor"
                      api="/api/admins/tutors/autocomplete_full_name"
                      searchOptions="published=true"
                      placeholder="Select Tutor"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <AutocompleteField
                      name="subjectIds"
                      label="Subjects"
                      api="/api/admins/subjects/autocomplete_name"
                      isMulti
                    />
                  </div>
                </div>
              </div>

              {data.viewer.canAdminBudgets && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <AutocompleteField
                        name="budgetId"
                        label="Budget"
                        api="/api/admins/budgets/autocomplete_name"
                        searchOptions={`organization_id=${values.organizationId}`}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <AutocompleteField
                      name="tagList"
                      label="Tags"
                      valueAttribute="label"
                      api="/api/admins/study_groups/autocomplete_tags"
                      isMulti
                      creatable
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <TextAreaField name="details" label="Details" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormFailures />
                  <SubmitButton isSubmitting={isSubmitting} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </UrqlLoadingIndicator>
  )
}

const viewerQuery = gql`
  query viewer {
    viewer {
      canAdminBudgets
    }
  }
`

const createOneonOneMutation = gql`
  mutation (
    $organizationId: ID!
    $tutorId: ID!
    $studentId: ID!
    $subjectIds: [ID!]
    $details: String
    $tagList: [String!]
    $budgetId: ID
  ) {
    oneOnOneStudyGroupCreate(
      organizationId: $organizationId
      tutorId: $tutorId
      studentId: $studentId
      subjectIds: $subjectIds
      details: $details
      tagList: $tagList
      budgetId: $budgetId
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default OneOnOneForm
