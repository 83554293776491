import React from "react"

import { useField } from "formik"

import { tutorApplicationReferralSources } from "src/enums"

import { Field, RadioField } from "components/Forms/Formik/hookComponents"

const ReferralSource = () => {
  const [referralSourceField] = useField("referralSource")

  return (
    <>
      <RadioField
        name="referralSource"
        label="How did you hear about Air Tutors?"
        options={tutorApplicationReferralSources}
      />
      {referralSourceField.value === "other" && (
        <Field
          name="referralSourceOther"
          placeholder="Please tell us how you heard about us"
        />
      )}
    </>
  )
}

export default ReferralSource
