import React from "react"

import moment from "moment-timezone"
import PropTypes from "prop-types"

window.moment = moment

class LocalTime extends React.Component {
  render() {
    if (!this.props.timestamp) return ""
    let format = this.props.format
    if (this.props.omitTime) format = "ll"
    if (this.props.omitDate) format = "h:mm A"
    return moment(this.props.timestamp).tz(moment.tz.guess()).format(format)
  }
}

LocalTime.propTypes = {
  timestamp: PropTypes.string,
  format: PropTypes.string,
  omitTime: PropTypes.bool,
  omitDate: PropTypes.bool,
}

LocalTime.defaultProps = {
  format: "lll",
}

const zoneName = moment().tz(moment.tz.guess()).format("z")

export default LocalTime
export { zoneName }
