import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { useGradeOptions } from "hooks/remoteTable"

import {
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const EditGrades = ({ availabilitySetId, grades }) => {
  const formState = {
    availabilitySetId,
    gradeIds: grades.map(grade => grade.id),
  }

  const { runMutation } = useMutation(buildMutation(updateMutation))
  const gradeOptions = useGradeOptions()

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.availabilitySetUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
            actions.setSubmitting(false)
          } else {
            closeModal()
          }
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Grades"
      buttonText="Edit Grades"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <SelectField
                name="gradeIds"
                label="Grades"
                options={gradeOptions}
                isMulti
                menuPortalTarget={document.body}
                defaultValue={grades.map(grade => ({
                  value: grade.id,
                  label: grade.name,
                }))}
              />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="d-flex justify-content-between w-full">
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateMutation = compress`
  mutation($availabilitySetId: ID!, $gradeIds: [ID!]) {
    availabilitySetUpdate(availabilitySetId: $availabilitySetId, gradeIds: $gradeIds) {
      errorMessages
      availabilitySet {
        id
        grades {
          id
          name
          number
        }
      }
    }
  }
`

export default EditGrades
