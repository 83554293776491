import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const StudentScreenShareModal = ({ isOpen, onClose, screenShareFailed }) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle={
        screenShareFailed
          ? "Screen Share Failed"
          : "Screen Share Request Denied"
      }
      closeModal={onClose}
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            {screenShareFailed
              ? "If you did not click cancel, please use the latest version of Chrome on your desktop/laptop."
              : "The tutor has denied your screen share request"}
          </div>
          <div className="modal-footer">
            <button className="btn solid blue btn-info mr-3" onClick={onClose}>
              Okay
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default StudentScreenShareModal
