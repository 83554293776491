import React from "react"

import { publicClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"
import useSessionStorage from "hooks/useSessionStorage"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Card from "./Card"
import CollegeForm from "./CollegeForm"
import FormStateContext from "./FormStateContext"
import GradeOptionsContext from "./GradeOptionsContext"
import ParentForm from "./ParentForm"

const RegistrationWizard = () => {
  const [result] = useQuery({ query: gradesQuery, client })
  const [store, setStore] = useSessionStorage("registrationWizard", {})

  const userType = store.registrationUserType
  const setUserType = type => {
    setStore({ ...store, registrationUserType: type })
  }

  const reset = () => {
    setUserType()
  }

  return (
    <FormStateContext.Provider value={[store, setStore]}>
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <GradeOptionsContext.Provider
            value={{
              tiers: data.tiers,
              grades: data.tiers.map(t => t.grades).flat(),
            }}
          >
            <div className="mx-auto w-full lg:w-3/4">
              <h1 className="text-center">Air Tutors Registration</h1>
              {userType ? null : (
                <>
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
                    <Card
                      title="New to Air Tutors"
                      description="Create parent and student accounts"
                      highlight={true}
                      onClick={() => setUserType("parent")}
                    />
                    <Card
                      title={
                        <>
                          My Student Uses{" "}
                          <span className="whitespace-nowrap">Air Tutors</span>
                        </>
                      }
                      description="Create a parent account to manage my student's Air Tutors account"
                      highlight={true}
                      href="/users/sign_in?openParentModal=true"
                    />
                    <Card
                      title="I'm a College+ Student"
                      description="Create an account to manage your own Air Tutors experience"
                      highlight={true}
                      onClick={() => setUserType("college")}
                    />
                  </div>
                </>
              )}
              {userType === "parent" && <ParentForm reset={reset} />}
              {userType === "college" && <CollegeForm reset={reset} />}

              <div className="mt-5 text-center">
                <div>Already have an account?</div>
                <div>
                  <a href="/users/sign_in?programRegistration=true">
                    Click here to login
                  </a>
                </div>
              </div>
            </div>
          </GradeOptionsContext.Provider>
        )}
      </UrqlLoadingIndicator>
    </FormStateContext.Provider>
  )
}

const gradesQuery = gql`
  query {
    tiers {
      id
      name
      gradeRange
      grades {
        id
        name
        number
      }
    }
  }
`

export default RegistrationWizard
