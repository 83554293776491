import React from "react"

import { CheckboxField } from "components/Forms/Formik/hookComponents"

const TermsField = () => (
  <CheckboxField
    name="termsAccepted"
    label={
      <>
        I am giving Air Tutors permission to contact my family regarding setting
        up tutoring services and accept the{" "}
        <a href="/privacy-policy" target="_blank">
          Air Tutors privacy policy
        </a>
      </>
    }
  />
)

export default TermsField
