import React, { useContext } from "react"

import airbrake from "src/airbrake-notifier"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type ScreenShareRequestedModalProps = Record<any, never>

const ScreenShareRequestedModal: React.FC<
  ScreenShareRequestedModalProps
> = () => {
  const { channel, screenShareRequestedBy, setScreenShareRequestedBy } =
    useContext(CloudroomContext)

  const approveRequest = () => {
    if (!screenShareRequestedBy.userId) {
      airbrake.notify({
        error: "Not submitting screen share response, no userId is present",
        name: "ChimeCloudRoom/Tutor",
        params: { screenShareRequestedBy },
      })
    } else {
      channel.perform("screen_share_response", {
        approved: true,
        userId: screenShareRequestedBy.userId,
      })
    }
    setScreenShareRequestedBy()
  }

  const denyRequest = () => {
    channel.perform("screen_share_response", {
      approved: false,
      userId: screenShareRequestedBy.userId,
    })
    setScreenShareRequestedBy()
  }

  return (
    <ModalWithProvidedBody
      isOpen={!!screenShareRequestedBy}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Screen Share Request"
    >
      <div className="modal-body">
        {screenShareRequestedBy?.name} has requested to share their screen
      </div>
      <div className="modal-footer">
        <button
          className="btn solid blue btn-info"
          onClick={() => approveRequest()}
        >
          Allow
        </button>
        <button
          className="btn solid red btn-danger"
          onClick={() => denyRequest()}
        >
          Reject
        </button>
      </div>
    </ModalWithProvidedBody>
  )
}

export default ScreenShareRequestedModal
