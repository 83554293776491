import React from "react"

import { Form, Formik } from "formik"
import { OperationResult } from "urql"

import ordered from "src/ordered"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  FormFailures,
  RadioField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

type StudyGroup = {
  id: number
  subjects: { name: string }[]
  allAvailabilities: {
    id: number
    startsAt: Date
    endsAt: Date
    weekday?: string
    weekdayNumber?: number
  }[]
  nextSession: { startsAt: Date }
}

type StudyGroupUser = {
  confirmedAt: Date
  studyGroup: StudyGroup
  user: { id: number; firstName: string }
}

export type ConfirmStudyGroupFormProps = {
  viewerType: string
  studyGroupUser: StudyGroupUser
}

const ConfirmStudyGroupForm: React.FC<ConfirmStudyGroupFormProps> = ({
  viewerType,
  studyGroupUser,
}) => {
  const client = useClient()
  const [, runMutation] = useMutation(confirmMutation, client) as [
    any,
    (variables: any, context?: any) => Promise<OperationResult<any, any>>
  ]

  const formState = {
    cancel: false,
    shouldContact: true,
  }

  const handleSubmit = (values, actions, studyGroupUser) => {
    runMutation({
      ...values,
      studyGroupId: studyGroupUser.studyGroup.id,
      studentId: studyGroupUser.user.id,
    })
      .then(
        result => {
          const { failures, studyGroup } = result.data.studyGroupStudentConfirm
          if (failures.length > 0) handleFailure(actions, failures)
          else if (studyGroup) window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={(values, actions) =>
        handleSubmit(values, actions, studyGroupUser)
      }
    >
      {({ values }) => (
        <Form>
          <div className="mt-10 flex-col text-xl">
            <p>
              {viewerType === "Student"
                ? "You have"
                : `${studyGroupUser.user.firstName} has`}{" "}
              been registered for a {studyGroupUser.studyGroup.subjects[0].name}{" "}
              tutoring group. The group begins on{" "}
              <LocalTime
                timestamp={studyGroupUser.studyGroup.nextSession.startsAt}
              />{" "}
              and meets at the following times:{" "}
            </p>

            <div className="grid whitespace-nowrap sm:grid-cols-[min-content_max-content] sm:gap-y-2 sm:gap-x-3">
              {ordered(
                studyGroupUser.studyGroup.allAvailabilities,
                "weekdayNumber"
              ).map(availability => (
                <React.Fragment key={availability.id}>
                  <div>{availability.weekday}s </div>
                  <div className="mb-3 sm:mb-0">
                    <LocalTime timestamp={availability.startsAt} format="LT" />
                    <span className="mx-3">&mdash;</span>
                    <LocalTime
                      timestamp={availability.endsAt}
                      format="h:mm A z"
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>

            <p className="py-4">Please select one of the following:</p>

            <div>
              <RadioField
                name="cancel"
                boolean
                options={[
                  {
                    label: `Yes, these meeting times work for ${
                      viewerType === "Student"
                        ? "me"
                        : studyGroupUser.user.firstName
                    }. I understand that ${
                      viewerType === "Student" ? "I" : "they"
                    } will have the opportunity to cancel individual sessions if needed.`,
                    value: false,
                  },
                  {
                    label: `No, these meeting times do not work for ${
                      viewerType === "Student"
                        ? "me"
                        : studyGroupUser.user.firstName
                    }. Please remove ${
                      viewerType === "Student" ? "me" : "them"
                    } from this group.`,
                    value: true,
                  },
                ]}
              />
            </div>

            {values.cancel && (
              <>
                <p className="py-4">
                  Would{" "}
                  {viewerType === "Student"
                    ? "you"
                    : studyGroupUser.user.firstName}{" "}
                  accept {studyGroupUser.studyGroup.subjects[0].name} tutoring
                  if it was available at another time?
                </p>
                <RadioField
                  name="shouldContact"
                  boolean
                  options={[
                    {
                      label: `Yes, please reach out to ${
                        viewerType === "Student" ? "me" : "us"
                      } with other available tutoring options.`,
                      value: true,
                    },
                    {
                      label: `No, ${
                        viewerType === "Student" ? "I'm" : "we're"
                      } not interested in ${
                        studyGroupUser.studyGroup.subjects[0].name
                      } tutoring at this time.`,
                      value: false,
                    },
                  ]}
                />
              </>
            )}
          </div>

          <br />

          <FormFailures />
          <SubmitButton text="Submit Response" />
        </Form>
      )}
    </Formik>
  )
}

const confirmMutation = gql`
  mutation studyGroupStudentConfirm(
    $studyGroupId: ID!
    $studentId: ID!
    $cancel: Boolean!
    $shouldContact: Boolean!
  ) {
    studyGroupStudentConfirm(
      studyGroupId: $studyGroupId
      studentId: $studentId
      cancel: $cancel
      shouldContact: $shouldContact
    ) {
      failures {
        message
      }
      studyGroup {
        id
      }
    }
  }
`

export default ConfirmStudyGroupForm
