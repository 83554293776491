import React from "react"

import { gql } from "urql"

import { useQuery } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const StatementSettings = ({ organizationId }) => {
  const [result] = useQuery({
    query: statementSettingsQuery,
    variables: { organizationId },
  })

  return (
    <ModalWithProvidedBody
      modalTitle="Statement Settings"
      buttonClassName="btn btn-info"
      buttonText="Statement Settings"
      modalDialogClassName="md:max-w-[50vw]"
    >
      {({ closeModal }) => (
        <UrqlLoadingIndicator result={result}>
          {({ data }) => (
            <Form organization={data.organization} closeModal={closeModal} />
          )}
        </UrqlLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const statementSettingsQuery = gql`
  query ($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      statementDefaultTo
      statementDefaultMessage
      statementNoShowLineItem
      statementStudentsColumn
    }
  }
`

export default StatementSettings
