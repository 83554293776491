import React from "react"

import Certificates from "./Certificates"
import Degrees from "./Degrees"

const Education = () => (
  <>
    <h3>Education</h3>
    <Degrees />
    <Certificates />
  </>
)

export default Education
