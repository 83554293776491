import React, { useEffect, useMemo, useRef } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import { NotificationContainer } from "react-notifications"

import EventEmitter from "src/EventEmitter"
import { tutorClient } from "src/graphql-config"
import ordered from "src/ordered"

import useWebsocket from "hooks/useWebsocket"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import LessonPlan from "./LessonPlan"
import LessonResource from "./LessonResource"

import "react-notifications/lib/notifications.css"

const LessonResources = ({ airtutorsSessionId }) => {
  const loadingState = useQuery(
    buildQuery(
      lessonResourcesQuery,
      { airtutorsSessionId },
      {
        client: tutorClient,
        onMutation: [
          {
            when: "completeLessonResource",
            run: ({ softReset, currentResults }, response) => {
              if (response.completeLessonResource.airtutorsSession) {
                currentResults.airtutorsSession =
                  response.completeLessonResource.airtutorsSession
                softReset(currentResults)
              }
            },
          },
          {
            when: "removeLessonResourceCompletion",
            run: ({ softReset, currentResults }, response) => {
              if (!response.removeLessonResourceCompletion.errorMessage) {
                window.location.reload()
              }
            },
          },
        ],
      }
    )
  )

  const channelProps = useMemo(() => {
    return { id: airtutorsSessionId }
  }, [airtutorsSessionId])

  useWebsocket({
    channelName: "AirtutorsSessionChannel",
    channelProps,
  })

  const reload = useRef<any>()
  reload.current = loadingState.reload

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      reload.current()
    })
  }, [])

  return (
    <div>
      <NotificationContainer />
      <WithLoadingIndicator loadingState={loadingState}>
        {({ data }) => (
          <>
            <h1 className="text-center">Current Lesson Resources</h1>
            <ul className="inline-block text-left">
              {ordered(
                ordered(
                  ordered(
                    data.airtutorsSession.currentLessonResources,
                    "sortOrder"
                  ),
                  "lessonTopic",
                  "sortOrder"
                ),
                "lessonPlan",
                "name"
              ).map(lessonResource => (
                <React.Fragment key={lessonResource.id}>
                  <h2>{lessonResource.lessonPlan.name}</h2>
                  <div className="align-center flex flex-col justify-between">
                    <h3 className="ml-3 font-medium">
                      {lessonResource.lessonTopic.name}
                    </h3>
                    <LessonResource
                      key={lessonResource.id}
                      airtutorsSessionId={airtutorsSessionId}
                      lessonResource={lessonResource}
                      isCurrent={true}
                    />
                  </div>
                </React.Fragment>
              ))}
            </ul>

            <div className="text-left">
              <h1 className="text-center">Lesson Plans</h1>
              {data.airtutorsSession.lessonPlans.map(lessonPlan => (
                <LessonPlan
                  key={lessonPlan.id}
                  sessionId={airtutorsSessionId}
                  lessonPlanId={lessonPlan.id}
                  name={lessonPlan.name}
                />
              ))}
            </div>
          </>
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const lessonResourcesQuery = compress`
  query($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      lessonPlans {
        id
        name
      }
      currentLessonResources {
        id
        name
        sortOrder
        file {
          thumbUrl
          previewUrl
        }
        lessonTopic {
          id
          name
          sortOrder
        }
        lessonPlan {
          id
          name
        }
        lessonResourceCompletions(sessionId: $airtutorsSessionId) {
          id
          createdAt
          studentMastery
          student {
            id
            fullName
          }
        }
      }
    }
  }
`

export default LessonResources
