import React from "react"

import { buildQuery, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import ordered from "src/ordered"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import AddTutorSubject from "./AddTutorSubject"
import TutorSubject from "./TutorSubject"
import loadTutorSubjectsQuery from "./loadTutorSubjectsQuery"
import styles from "./styles.module.scss"

const TutorSubjects = ({ tutorId }) => {
  const loadingState = useQuery(
    buildQuery(
      loadTutorSubjectsQuery,
      { tutorId },
      {
        onMutation: [
          {
            when: /(approve|reject)TutorSubject/,
            run: ({ softReset, currentResults }, response) => {
              let updatedTutorSubject
              if (response.approveTutorSubject) {
                updatedTutorSubject = response.approveTutorSubject.tutorSubject
              } else {
                updatedTutorSubject = response.rejectTutorSubject.tutorSubject
              }
              currentResults.tutor.tutorSubjects.find(
                ts => ts.id === updatedTutorSubject.id
              ).approvalState = updatedTutorSubject.approvalState
              softReset(currentResults)
            },
          },
          {
            when: "addTutorSubject",
            run: ({ softReset, currentResults }, response) => {
              const addedTutorSubject = response.addTutorSubject.tutorSubject
              const existingTutorSubject =
                currentResults.tutor.tutorSubjects.find(
                  ts => ts.id === addedTutorSubject.id
                )
              if (existingTutorSubject) {
                existingTutorSubject.approvalState =
                  addedTutorSubject.approvalState
              } else {
                currentResults.tutor.tutorSubjects.push(addedTutorSubject)
              }
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const tutorSubjectsForTier = (data, tier) => {
    const filteredSubjects = data.tutor.tutorSubjects.filter(
      tutorSubject => tutorSubject.subjectBucket.tier.id === tier.id
    )
    return ordered(filteredSubjects, "subjectBucket", "name")
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading tutor subjects"
    >
      {({ data }) => (
        <div className={styles.container}>
          {data.tutor.tiers.map(tier => (
            <React.Fragment key={tier.id}>
              <h4>{tier.gradeRange}</h4>
              {tutorSubjectsForTier(data, tier).map(tutorSubject => (
                <div key={tutorSubject.id} className={styles.subjectContainer}>
                  <TutorSubject
                    tutorSubject={tutorSubject}
                    canAdminTutorApplications={
                      data.viewer.canAdminTutorApplications
                    }
                  />
                </div>
              ))}
              {data.viewer.canAdminTutorApplications && (
                <AddTutorSubject tutorId={tutorId} tier={tier} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </WithLoadingIndicator>
  )
}

TutorSubjects.propTypes = {
  tutorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default TutorSubjects
