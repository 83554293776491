import React from "react"

import { useField } from "formik"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import { studentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import {
  CheckboxField,
  ErrorMessage,
} from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const AvailabilityForm = () => {
  const [, meta] = useField({ name: "availabilityIds" })
  const [gradeField] = useField({ name: "gradeId" })

  const loadingState = useQuery(
    buildQuery(availabilitiesQuery, { gradeId: gradeField.value }, { client })
  )

  const groupedAvailabilities = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  }

  if (loadingState.data) {
    const availabilitySets =
      loadingState.data.viewer.organization.availabilitySets
    availabilitySets.forEach(availabilitySet => {
      availabilitySet.availabilities.forEach(availability => {
        groupedAvailabilities[availability.weekday].push(availability)
      })
    })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          {Object.keys(groupedAvailabilities).map(
            weekday =>
              groupedAvailabilities[weekday].length > 0 && (
                <div key={weekday} className="mt-3">
                  <h4
                    css={css`
                      font-weight: 500;
                    `}
                  >
                    {weekday}
                  </h4>
                  {ordered(groupedAvailabilities[weekday], "startsAt").map(
                    availability => (
                      <CheckboxField
                        key={availability.id}
                        name="availabilityIds"
                        label={
                          <>
                            <LocalTime
                              timestamp={availability.startsAt}
                              format="LT"
                            />
                            &nbsp;&mdash;&nbsp;
                            <LocalTime
                              timestamp={availability.endsAt}
                              format="LT"
                            />
                          </>
                        }
                        value={availability.id}
                        hideError
                      />
                    )
                  )}
                </div>
              )
          )}
          <ErrorMessage {...meta} touched={true} />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const availabilitiesQuery = compress`
  query($gradeId: ID!) {
    viewer {
      organization {
        availabilitySets(gradeId: $gradeId) {
          id
          availabilities {
            id
            startsAt
            endsAt
            weekday
          }
        }
      }
    }
  }
`

AvailabilityForm.modalTitle = "Select Your Availabilities"
AvailabilityForm.displayName = "Availabilities"
AvailabilityForm.providedFields = ["availabilityIds"]
AvailabilityForm.step = "Availability"
export default AvailabilityForm
