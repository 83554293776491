import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ordered from "src/ordered"

import {
  CheckboxField,
  FormStatus,
  SelectAll,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

const Results = ({ availabilityId, students, closeModal }) => {
  const formState = { availabilityId, studentIds: [] }
  const [errors, setErrors] = useState([])
  const { runMutation } = useMutation(buildMutation(assignStudentsMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { failures } = response.availabilityAssignStudents
          if (failures) {
            setErrors(failures)
          } else {
            closeModal()
          }
        },
        () => {
          actions.setSubmitting(false)
          setErrors([{ message: "Something went wrong" }])
        }
      )
      .catch(() => {
        actions.setSubmitting(false)
        setErrors([{ message: "Something went wrong" }])
      })
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="modal-body">
          {students.length === 0 ? (
            <p className="lead">No results</p>
          ) : (
            <>
              <SelectAll fieldName="studentIds" items={students} />
              <hr />

              <ul className="list-unstyled">
                {ordered(students, "lastName").map(student => (
                  <li key={student.id}>
                    <CheckboxField
                      name="studentIds"
                      value={student.id}
                      label={student.fullName}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        <div className="modal-footer flex-column">
          <FormStatus />
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger">
              {error.message}
            </div>
          ))}
          <SubmitButton text="Add Selected Students" />
        </div>
      </Form>
    </Formik>
  )
}

const assignStudentsMutation = compress`
  mutation($studentIds: [ID!]!, $availabilityId: ID!) {
    availabilityAssignStudents(studentIds: $studentIds, availabilityId: $availabilityId) {
      failures {
        message
      }
    }
  }
`

export default Results
