import React from "react"

import { useFormikContext } from "formik"

import useAssessmentSectionOptions from "hooks/useAssessmentSectionOptions"

import { Field, SelectField } from "components/Forms/Formik/hookComponents"

const AssessmentScoreFields = () => {
  const { values } = useFormikContext()
  const assessmentSectionOptions = useAssessmentSectionOptions()

  if (!values.groupByAssessmentScore) return null

  return (
    <>
      <SelectField
        name="assessmentSectionId"
        label="Assessment Section"
        options={assessmentSectionOptions}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        valueAttribute="id"
        menuPortalTarget={document.body}
      />
      <Field
        name="maximumScoreDeviation"
        label="Maximum Score Deviation (score range will be 2x this value - above and below)"
        type="number"
        min={1}
      />
    </>
  )
}

export default AssessmentScoreFields
