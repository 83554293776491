import React, { useState } from "react"

import { NotificationManager } from "react-notifications"

import { reportGraphQLError } from "src/airbrake-notifier"
import ordered from "src/ordered"

import { gql, useClient } from "hooks/urql"

import LessonResource from "./LessonResource"

type LessonTopicProps = {
  sessionId: number
  lessonTopicId: number
  name: string
}

const LessonTopic: React.FC<LessonTopicProps> = ({
  sessionId,
  lessonTopicId,
  name,
}) => {
  const client = useClient()
  const [resources, setResources] = useState([])
  const [showResources, setShowResources] = useState(false)

  const loadResources = () => {
    client
      .query(loadResourcesQuery, { sessionId, lessonTopicId })
      .toPromise()
      .then(result => {
        if (!result.data) {
          if (result.error?.response?.status === 422) {
            window.location.reload()
          } else {
            NotificationManager.error("Unable to load resources")
            reportGraphQLError({
              result,
              component: "Tutor/AirtutorsSessions/LessonResources/LessonTopic",
              action: "loadResources",
            })
          }
          return
        }
        setResources(result.data.airtutorsSession.lessonTopic.lessonResources)
        setShowResources(true)
      })
  }

  const toggleResources = () => {
    if (resources.length === 0) loadResources()
    else setShowResources(!showResources)
  }

  return (
    <div>
      <div className="space-between flex items-center">
        <h3>{name}</h3>
        <button
          className="btn small ml-3 h-fit outline"
          onClick={toggleResources}
        >
          {showResources ? "Hide Resources" : "Show Resources"}
        </button>
      </div>

      {showResources &&
        ordered(resources, "sortOrder").map(resource => (
          <LessonResource
            key={resource.id}
            airtutorsSessionId={sessionId}
            lessonResource={resource}
            isCurrent={false}
          />
        ))}
    </div>
  )
}

const loadResourcesQuery = gql`
  query ($sessionId: ID!, $lessonTopicId: ID!) {
    airtutorsSession(id: $sessionId) {
      lessonTopic(id: $lessonTopicId) {
        lessonResources {
          id
          name
          sortOrder
          file {
            thumbUrl
            previewUrl
          }
          lessonTopic {
            id
            name
          }
          lessonPlan {
            id
            name
          }
          lessonResourceCompletions(sessionId: $sessionId) {
            id
            createdAt
            studentMastery
            student {
              id
              fullName
            }
          }
        }
      }
    }
  }
`

export default LessonTopic
