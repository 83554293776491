import React, { useEffect, useRef, useState } from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import createBlob from "src/createBlob"
import ordered from "src/ordered"
import client from "src/urql-client"

import { useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { Loading } from "components/Icons"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TutorSearch = () => {
  const [page, setPage] = useState(1)
  const [formParams, setFormParams] = useState({})
  const [result] = useQuery({
    query: loadTutorsQuery,
    variables: {
      ...formParams,
      page: page,
    },
  })

  const formState = {
    subjectId: "",
    tagList: [],
    foreignLanguageId: "",
  }

  const [csvUrl, setCsvUrl] = useState()
  const [errors, setErrors] = useState([])
  const [exportMessage, setExportMessage] = useState()
  const [exportRunning, setExportRunning] = useState(false)
  const csvDownloadLink = useRef()

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl()
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  const handleSubmit = (values, actions) => {
    setPage(1)
    setFormParams(values)
    actions.setSubmitting(false)
  }

  const handleExport = values => {
    setExportRunning(true)
    setExportMessage("Export in progress...")
    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, values),
      subscribe(result => {
        if (result.data?.tutorsExport?.status === "done") {
          const blobUrl = createBlob(result.data.tutorsExport.csv, "text/csv")
          setCsvUrl(blobUrl)
          setExportRunning(false)
          setExportMessage("Your download is complete")
          setErrors([])
          unsubscribe()
        }

        if (result.data?.tutorsExport?.errorMessages?.length) {
          setErrors(
            result.data.tutorsExport.errorMessages.map(e => ({
              message: e,
            }))
          )
          setExportMessage()
          setExportRunning(false)
          unsubscribe()
        }

        if (result.error) {
          setErrors([{ message: result.error?.message }])
          setExportMessage()
          setExportRunning(false)
        }
      })
    )
  }

  return (
    <div className="row">
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download={`tutors_export.csv`}
        className="hidden"
      >
        csv
      </a>
      <div className="col-lg-4 col-md-6">
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="form-group">
                <label>Subject</label>
                <AutocompleteSelect
                  onChange={selected =>
                    setFieldValue("subjectId", selected ? selected.value : "")
                  }
                  api={"/api/admins/subjects/autocomplete_name"}
                />
              </div>

              <div className="form-group">
                <label>Foreign Language</label>
                <AutocompleteSelect
                  onChange={selected =>
                    setFieldValue(
                      "foreignLanguageId",
                      selected ? selected.value : ""
                    )
                  }
                  api={"/api/admins/foreign_languages/autocomplete_name"}
                />
              </div>

              <div className="form-group">
                <label>Tags</label>
                <AutocompleteSelect
                  onChange={selected =>
                    setFieldValue(
                      "tagList",
                      selected.map(tag => tag.label)
                    )
                  }
                  api="/api/admins/tutors/autocomplete_tags"
                  isMulti
                />
              </div>

              <div className="mr-4 mt-4 inline-block">
                <button
                  type="submit"
                  className="btn solid btn-info"
                  disabled={isSubmitting}
                >
                  <span>Search</span>
                  {isSubmitting && (
                    <div className="ml-4 inline-block">
                      <Loading />
                    </div>
                  )}
                </button>
                <br />
                <button
                  className="btn solid btn-success"
                  disabled={isSubmitting || exportRunning}
                  onClick={() => handleExport(values)}
                  type="button"
                >
                  Export
                </button>
                {exportMessage && (
                  <div className="alert alert-info">{exportMessage}</div>
                )}
                {errors.map((error, index) => (
                  <div key={index} className="alert alert-danger">
                    {error.message}
                  </div>
                ))}
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="col-lg-6 offset-lg-2 col-md-6">
        <UrqlLoadingIndicator result={result}>
          {({ data }) => (
            <React.Fragment>
              <div className="ml-md-0 mr-3 ml-3 mb-3">
                {data.tutorSearch.data.length < 1 && (
                  <span className="font-italic">No tutors found.</span>
                )}
                {data.tutorSearch.data.map(tutor => {
                  return (
                    <div
                      key={tutor.id}
                      className="row cursor-pointer rounded-md py-4 hover:bg-[#e3e3e3]"
                      onClick={() => (window.location = tutor.showPath)}
                    >
                      <div className="col-3 d-flex align-items-center">
                        <img alt="Avatar" src={tutor.avatar.thumbUrl} />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <span>{tutor.fullName}</span>
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <span>{tutor.phoneNumber}</span>
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <ul className="list-unstyled">
                          {ordered(tutor.tagList).map(tag => (
                            <li
                              key={tag}
                              className="mb-2 rounded-md bg-[#5596c1] p-2 text-white"
                            >
                              {tag}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )
                })}
              </div>
              <Pagination
                totalPages={data.tutorSearch.totalPages}
                currentPage={data.tutorSearch.currentPage}
                changePage={page => setPage(page)}
              />
            </React.Fragment>
          )}
        </UrqlLoadingIndicator>
      </div>
    </div>
  )
}

const loadTutorsQuery = gql`
  query (
    $subjectId: ID
    $foreignLanguageId: ID
    $tagList: [String!]
    $page: Int
  ) {
    tutorSearch(
      subjectId: $subjectId
      foreignLanguageId: $foreignLanguageId
      tagList: $tagList
      page: $page
    ) {
      totalPages
      currentPage
      data {
        id
        fullName
        phoneNumber
        tagList
        showPath
        avatar {
          thumbUrl
        }
      }
    }
  }
`

const exportSubscription = gql`
  subscription (
    $tagList: [String!]!
    $subjectId: ID!
    $foreignLanguageId: ID!
  ) {
    tutorsExport(
      tagList: $tagList
      subjectId: $subjectId
      foreignLanguageId: $foreignLanguageId
    ) {
      status
      csv
      errorMessages
    }
  }
`

export default TutorSearch
