import React, { useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Checkbox from "components/Forms/Checkbox"
import EditableDisplay from "components/Forms/EditableDisplay"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Clone from "./Clone"
import NewRecipient from "./NewRecipient"
import Recipient from "./Recipient"

const BatchTextDetails = ({ id }) => {
  const [errorMessage, setErrorMessage] = useState()

  const loadingState = useQuery(
    buildQuery(
      loadBatchTextQuery,
      { id },
      {
        onMutation: [
          {
            when: "batchTextUpdate",
            run: ({ softReset, currentResults }, response) => {
              const { batchText, errorMessages } = response.batchTextUpdate
              if (errorMessages) {
                setErrorMessage(errorMessages)
                return
              }
              currentResults.batchText.notes = batchText.notes
              currentResults.batchText.formattedNotes = batchText.formattedNotes
              currentResults.batchText.message = batchText.message
              currentResults.batchText.formattedMessage =
                batchText.formattedMessage
              currentResults.batchText.tags = batchText.tags
              currentResults.batchText.skipExistingUsers =
                batchText.skipExistingUsers

              softReset(currentResults)
            },
          },
          {
            when: "batchTextRemoveRecipient",
            run: ({ softReset, currentResults }, response) => {
              const { batchText } = response.batchTextRemoveRecipient
              if (batchText) {
                currentResults.batchText.batchTextRecipients =
                  batchText.batchTextRecipients
                softReset(currentResults)
              }
            },
          },
          {
            when: "batchTextAddRecipient",
            run: ({ softReset, currentResults }, response) => {
              const { batchText } = response.batchTextAddRecipient
              if (batchText) {
                currentResults.batchText.batchTextRecipients =
                  batchText.batchTextRecipients
                softReset(currentResults)
              }
            },
          },
        ],
      }
    )
  )

  const { runMutation } = useMutation(buildMutation(updateMutation))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <Clone id={id} />
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <dl>
            <dt>Message</dt>
            <dd>
              <EditableDisplay
                displayValue={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.batchText.formattedMessage,
                    }}
                  />
                }
                editableValue={data.batchText.message}
                type="textarea"
                height="auto"
                save={({ value }) => runMutation({ id, message: value })}
              />
            </dd>

            <dt>Send At</dt>
            <dd>
              <LocalTime timestamp={data.batchText.sendAt} />
            </dd>

            <dt>Skip Existing Users?</dt>
            <dd>
              <Checkbox
                name="skipExistingUsers"
                onChange={event =>
                  runMutation({
                    id,
                    skipExistingUsers: event.currentTarget.checked,
                  })
                }
                checked={data.batchText.skipExistingUsers}
                value={true}
              >
                (This will only work for numbers provided in the free-form
                number input)
              </Checkbox>
            </dd>

            <dt>Notes</dt>
            <dd>
              <EditableDisplay
                displayValue={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.batchText.formattedNotes,
                    }}
                  />
                }
                editableValue={data.batchText.notes}
                type="textarea"
                height="auto"
                save={({ value }) => runMutation({ id, notes: value })}
              />
            </dd>

            <dt>Tags</dt>
            <dd>
              <AutocompleteSelect
                api="/api/admins/batch_texts/autocomplete_tags"
                value={data.batchText.tags.map(tag => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                isMulti
                creatable
                onChange={selected =>
                  runMutation({ id, tagList: selected.map(tag => tag.label) })
                }
              />
            </dd>
          </dl>

          <h3>Recipients</h3>
          <NewRecipient batchTextId={id} />
          <table className="w-100 table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Sent At</th>
              </tr>
            </thead>
            <tbody>
              {data.batchText.batchTextRecipients.map(recipient => (
                <Recipient key={recipient.id} recipient={recipient} />
              ))}
            </tbody>
          </table>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const loadBatchTextQuery = compress`
  query($id: ID!) {
    batchText(id: $id) {
      message
      formattedMessage
      sendAt
      notes
      formattedNotes
      skipExistingUsers
      tags {
        id
        name
      }
      batchTextRecipients {
        id
        phoneNumber
        sentAt
        user {
          id
          fullName
          phoneNumber
        }
      }
    }
  }
`

const updateMutation = compress`
  mutation(
    $id: ID!
    $notes: String
    $message: String
    $tagList: [String!]
    $skipExistingUsers: Boolean
  ) {
    batchTextUpdate(
      id: $id
      notes: $notes
      message: $message
      tagList: $tagList
      skipExistingUsers: $skipExistingUsers
    ) {
      errorMessages
      batchText {
        notes
        formattedNotes
        message
        formattedMessage
        skipExistingUsers
        tags {
          id
          name
        }
      }
    }
  }
`

export default BatchTextDetails
