import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $organizationId: ID!
    $name: String!
    $startsOn: Date!
    $endsOn: Date!
  ) {
    createSemester(
      organizationId: $organizationId
      name: $name
      startsOn: $startsOn
      endsOn: $endsOn
    ) {
      organization {
        semesters {
          id
          name
          startsOn
          endsOn
        }
      }
    }
  }
`
