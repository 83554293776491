import React from "react"

import ordered from "src/ordered"

import { CheckboxField } from "components/Forms/Formik/hookComponents"

const SubjectsForm = ({ subjectBuckets }) => {
  return (
    <>
      {ordered(subjectBuckets, "name").map(subjectBucket => (
        <React.Fragment key={subjectBucket.id}>
          <h3>{subjectBucket.name}</h3>
          {ordered(subjectBucket.subjects, "name").map(subject => (
            <CheckboxField
              key={subject.id}
              name="subjectIds"
              value={subject.id}
              label={subject.name}
            />
          ))}

          <hr />
        </React.Fragment>
      ))}
    </>
  )
}

SubjectsForm.modalTitle = "What subjects do you need help in?"
SubjectsForm.displayName = "Subjects"
SubjectsForm.providedFields = ["subjectIds"]
SubjectsForm.step = "Subjects"
export default SubjectsForm
