import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const IncomingStudentsModal = ({ isOpen, onClose }) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Incoming Students"
      closeModal={onClose}
      hideCloseButton
    >
      <>
        <div className="modal-body">
          <p className="lead">
            Another tutor was unable to make their session. One or more students
            have been reassigned to your session and will be joining
            momentarily.
          </p>
        </div>
        <div className="modal-footer">
          <button className="btn solid blue" onClick={onClose}>
            Dismiss
          </button>
        </div>
      </>
    </ModalWithProvidedBody>
  )
}

export default IncomingStudentsModal
