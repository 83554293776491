import React from "react"

import { reportGraphQLError } from "src/airbrake-notifier"

import LoadingIndicator from "components/LoadingIndicator"

const UrqlLoadingIndicator = props => {
  const { result, heading, children } = props

  if (result.fetching)
    return (
      <React.Fragment>
        {heading}
        <LoadingIndicator />
      </React.Fragment>
    )

  if (result.error) {
    reportGraphQLError({
      result,
      component: "UrqlLoadingIndicator",
    })
    return (
      <React.Fragment>
        {heading}
        <div className="alert alert-danger">
          {result.error.message.replace("[GraphQL] ", "")}
        </div>
      </React.Fragment>
    )
  }
  if (result.data) {
    return (
      <React.Fragment>
        {heading}
        {typeof children === "function"
          ? children({ data: result.data })
          : children}
      </React.Fragment>
    )
  }

  return null
}

UrqlLoadingIndicator.defaultProps = {
  error: "An error occurred",
}

export default UrqlLoadingIndicator
