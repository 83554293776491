import React, { useRef } from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import StatementSettings from "components/Admin/Organization/Statements/Settings"

import "react-table/react-table.css"

const OrganizationStatementConfigurations = ({
  apiEndpoint,
  organizationId,
  newStatementConfigurationPath,
  statementsPath,
}) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint,
  })
  const table = useRef(null)

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      filterable: false,
    },
    {
      id: "budget_name",
      Header: "Budget",
      accessor: "budget",
      filterable: false,
      Cell: props => (
        <a href={props.value.show_path}>
          {props.value.name}
          {props.value.po_number && (
            <>
              <br />
              PO {props.value.po_number}
            </>
          )}
        </a>
      ),
    },
  ]

  columns.push({
    id: "links",
    Header: "Actions",
    accessor: "links",
    filterable: false,
    sortable: false,
    Cell: props => (
      <a className="btn btn-sm btn-info" href={props.value.show_path}>
        Details
      </a>
    ),
  })

  return (
    <>
      {newStatementConfigurationPath && (
        <div>
          <a href={newStatementConfigurationPath} className="btn btn-success">
            New Statement Configuration
          </a>
          <StatementSettings organizationId={organizationId} />
        </div>
      )}
      <div>
        <a className="btn btn-info" href={statementsPath}>
          View Statements
        </a>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default OrganizationStatementConfigurations
