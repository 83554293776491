import React, { useEffect } from "react"

import { Cache, buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import { RadioGroup } from "components/Forms/Formik"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SearchResults = ({ params, arrayHelpers, values, setFieldValue }) => {
  const loadingState = useQuery(
    buildQuery(tutorSearchQuery, params, { cache: new Cache(0) })
  )

  useEffect(() => {
    const tutors = loadingState.data?.tutors || []
    if (loadingState.loaded) {
      setFieldValue(
        "tutorIds",
        tutors.map(tutor => tutor.id)
      )
    }
  }, [loadingState.data, loadingState.loaded, setFieldValue])

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <RadioGroup
          type="checkbox"
          name="tutorIds"
          values={ordered(data.tutors, "lastName").map(tutor => ({
            value: tutor.id,
            label: tutor.fullName,
            checked: values.tutorIds.includes(tutor.id),
          }))}
          onChange={e => {
            if (e.currentTarget.checked) {
              arrayHelpers.push(e.currentTarget.value)
            } else {
              arrayHelpers.remove(
                values.tutorIds.findIndex(s => s === e.currentTarget.value)
              )
            }
          }}
        />
      )}
    </WithLoadingIndicator>
  )
}

const tutorSearchQuery = compress`
  query($tagList: [String!], $fullName: String, $subjectId: ID) {
    tutors(tagList: $tagList, fullName: $fullName, subjectId: $subjectId) {
      id
      fullName
      lastName
    }
  }
`

export default SearchResults
