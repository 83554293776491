import React from "react"

import { useFormikContext } from "formik"

import { SelectOption } from "~tailwindui/types/SelectOption"

import FormikForm from "~tailwindui/FormikForm"
import InlineCalendar from "~tailwindui/FormikForm/InlineCalendar"

import { timezoneOptions } from "src/enums"
import ordered from "src/ordered"

type ExportFormProps = Record<string, never>

const ExportForm: React.FC<ExportFormProps> = () => {
  const { values } = useFormikContext()
  const formValues: any = values

  return (
    <>
      <input name="focus-hack" className="absolute top-[-100%]" />
      <InlineCalendar
        range
        startFieldName="startingAt"
        endFieldName="endingAt"
        label="Date Range"
        description={
          <>
            <span className="block">
              {formValues.startingAt?.format("M/D/YY")} &mdash;{" "}
              {formValues.endingAt?.format("M/D/YY")}
            </span>

            <span className="block">
              Select a start and end date for your export
            </span>
          </>
        }
        value={[formValues.startingAt?.toDate(), formValues.endingAt?.toDate()]}
      />
      <FormikForm.SingleSelect
        label="Time Zone"
        name="timezone"
        options={ordered(timezoneOptions, "label") as SelectOption[]}
        description=""
        portal
        inputWrapperClassNames="col-span-2"
      />
    </>
  )
}

export default ExportForm
