import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { css } from "@emotion/core"
import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Loading } from "components/Icons"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import studentFragment from "./studentFragment"

const RatingIcon = ({ value }) => {
  let icon
  const classNames = []
  if (value === "weak") {
    icon = faFrown
    classNames.push("text-danger red-text")
  }
  if (value === "moderate") {
    icon = faMeh
    classNames.push("text-warning orange-text")
  }
  if (value === "strong") {
    icon = faSmile
    classNames.push("text-success green-text")
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      className={classNames.join(" ")}
      css={css`
        font-size: 36px;
      `}
    />
  )
}

const LessonResourceCompletion = ({ studentId, lessonResourceCompletion }) => {
  const [status, setStatus] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(removeCompletionMutation)
  )

  return (
    <div key={lessonResourceCompletion.id}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <a href={lessonResourceCompletion.session.showPath}>
          Session with {lessonResourceCompletion.session.tutor?.fullName} on{" "}
          <LocalTime
            timestamp={lessonResourceCompletion.session.startsAt}
            omitTime
          />
        </a>
        <ModalWithProvidedBody
          buttonClassName="btn btn-sm btn-danger"
          buttonText="Redo"
          modalTitle="Remove Worksheet Completion"
          afterClose={() => setStatus()}
        >
          {({ closeModal }) => (
            <>
              <div className="modal-body">
                <p>
                  Are you sure? This will remove the selected completion and
                  adjust the current worksheet for this student.
                </p>
              </div>
              <div
                className="modal-footer"
                css={css`
                  display: block !important;
                `}
              >
                {status && (
                  <div
                    className="alert alert-danger"
                    css={css`
                      padding: 9px;
                      margin-bottom: 15px;
                    `}
                  >
                    {status}
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <button className="btn btn-danger" onClick={closeModal}>
                    Nevermind
                  </button>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-info"
                      disabled={running}
                      onClick={() => {
                        runMutation({
                          studentId: studentId,
                          id: lessonResourceCompletion.id,
                        })
                          .then(
                            r => {
                              if (
                                r.removeLessonResourceCompletion.errorMessage
                              ) {
                                setStatus(
                                  r.removeLessonResourceCompletion.errorMessage
                                )
                              }
                            },
                            () =>
                              setStatus(
                                "An error occurred. Please refresh and try again."
                              )
                          )
                          .catch(() =>
                            setStatus(
                              "An error occurred. Please refresh and try again."
                            )
                          )
                      }}
                    >
                      <span>Remove It</span>
                      {running && (
                        <div
                          css={css`
                            display: inline-block;
                            margin-left: 15px;
                          `}
                        >
                          <Loading />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalWithProvidedBody>
      </div>
      <ul
        className="list-unstyled"
        css={css`
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
          }
        `}
      >
        {lessonResourceCompletion.studentMastery && (
          <li>
            <span>Student</span>
            <RatingIcon value={lessonResourceCompletion.studentMastery} />
          </li>
        )}
        {lessonResourceCompletion.studentTopicMasteryStart && (
          <li>
            <span>Student Chapter Start</span>
            <RatingIcon
              value={lessonResourceCompletion.studentTopicMasteryStart}
            />
          </li>
        )}
        {lessonResourceCompletion.studentTopicMasteryEnd && (
          <li>
            <span>Student Chapter End</span>
            <RatingIcon
              value={lessonResourceCompletion.studentTopicMasteryEnd}
            />
          </li>
        )}
        {lessonResourceCompletion.tutorMastery && (
          <li>
            <span>Tutor</span>
            <RatingIcon value={lessonResourceCompletion.tutorMastery} />
          </li>
        )}
        {lessonResourceCompletion.tutorTopicMastery && (
          <li>
            <span>Tutor Chapter</span>
            <RatingIcon value={lessonResourceCompletion.tutorTopicMastery} />
          </li>
        )}
      </ul>
      <hr />
    </div>
  )
}

const removeCompletionMutation = compress`
  ${studentFragment}
  mutation($id: ID!, $studentId: ID!) {
    removeLessonResourceCompletion(id: $id) {
      errorMessage
      removedLessonResourceCompletion {
        id
        student {
          ...StudentLessonFields
        }
      }
    }
  }
`

export default LessonResourceCompletion
