import React from "react"

import Button from "./Button"

const MuteButton = props => {
  const { room, channel, disabled, muted, setMuted, styles, ...rest } = props

  room.localParticipant.audioTracks.forEach(trackPublication => {
    if (muted) {
      trackPublication.track.disable()
    } else {
      trackPublication.track.enable()
    }
  })

  const toggle = () => {
    if (disabled) return
    room.localParticipant.audioTracks.forEach(trackPublication => {
      if (muted) {
        trackPublication.track.enable()
        channel && channel.perform("unmute_self", { type: "audio" })
        setMuted(false)
      } else {
        trackPublication.track.disable()
        channel && channel.perform("mute_self", { type: "audio" })
        setMuted(true)
      }
    })
  }

  return (
    <Button
      onClick={toggle}
      disabled={disabled}
      muted={muted}
      styles={{ ...styles, marginBottom: "6px" }}
      {...rest}
    />
  )
}

export default MuteButton
