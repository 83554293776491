import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RegenerateStatement = ({ statementId }) => {
  const formState = { statementId }

  const [, regenerateStatement] = useMutation(regenerateStatementMutation)

  const handleSubmit = (values, actions) => {
    regenerateStatement(values)
      .then(
        result => {
          const { failures } = result.data.regenerateStatement
          if (failures) {
            handleFailure(actions, failures)
          } else {
            actions.setStatus([
              {
                message:
                  "Statement will be generated momentarily. Please refresh in a few seconds.",
                type: "success",
              },
            ])
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Regenerate Statement"
      buttonText="Regenerate Statement"
      buttonClassName="btn btn-sm btn-outline-info"
    >
      {({ closeModal }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ submitting }) => (
            <Form>
              <div className="modal-body">
                Regenerating will remove and regenerate the currently attached
                PDF and re-calculate the statement total
              </div>
              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="w-100 flex justify-between">
                  <SubmitButton text="Regenerate" />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const regenerateStatementMutation = gql`
  mutation ($statementId: ID!) {
    regenerateStatement(statementId: $statementId) {
      failures {
        message
      }
      statement {
        id
      }
    }
  }
`

export default RegenerateStatement
