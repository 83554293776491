import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import { handleFailure } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody, { Footer } from "components/ModalWithProvidedBody"

type RemoveSurveyProps = {
  survey: { id: number; name: string }
}

const RemoveSurvey: React.FC<RemoveSurveyProps> = ({ survey }) => {
  const formState = { id: survey.id }
  const [, removeSurvey]: any[] = useMutation(removeSurveyMutation)

  const handleSubmit = (values, actions, closeModal) => {
    removeSurvey(values).then(result => {
      const { failures } = result.data.removeSurvey
      if (failures.length > 0) {
        handleFailure(actions, failures)
      } else {
        NotificationManager.info("Survey removed successfully")
        closeModal()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Delete Survey"
      modalClassName="bootstrap-modal"
      buttonText="Delete"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <p>
                Are you sure? This will remove the survey and any submitted
                answers. This process is IRREVERSIBLE.
              </p>
            </div>
            <Footer
              cancelAction={closeModal}
              cancelText="No, keep it!"
              submitText="Yes, remove this survey"
              isDangerous
            />
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const removeSurveyMutation = gql`
  mutation RemoveSurvey($id: ID!) {
    removeSurvey(id: $id) {
      failures {
        message
      }
      survey {
        id
      }
    }
  }
`

export default RemoveSurvey
