import { useState } from "react"

import FetchTableData from "src/FetchTableData"

const useRemoteTableData = ({
  apiEndpoint,
  defaultFilters = undefined,
  additionalProps = undefined,
}) => {
  const [data, setData] = useState([])
  const [pages, setPages] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = (tableState, instance) => {
    setLoading(true)
    FetchTableData(apiEndpoint, tableState, defaultFilters, additionalProps)
      .then(
        response => {
          const { data, pages } = response.data
          setData(data)
          setPages(pages)
          setLoading(false)
        },
        error => {
          alert(
            "Error while fetching table data. This is usually caused by an unsupported combination of sorting + filtering. Please refresh."
          )
        }
      )
      .catch(error => {
        alert(
          "Caught error while fetching table data. This is usually caused by an unsupported combination of sorting + filtering. Please refresh."
        )
      })
  }

  return { data, pages, loading, fetchData }
}

export default useRemoteTableData
