import React from "react"

import { compress } from "micro-graphql-react"

import Users from "./Users"

const Teachers = ({ teachers, studyGroupId, organizationId }) => {
  return (
    <Users
      users={teachers}
      studyGroupId={studyGroupId}
      addMutation={addTeacherMutation}
      removeMutation={removeTeacherMutation}
      title="Teachers"
      autocompleteProps={{
        api: "/api/admins/teachers/autocomplete_full_name",
        searchOptions: organizationId && `organization_id=${organizationId}`,
        placeholder: "Add Teacher",
      }}
    />
  )
}

const addTeacherMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupAddUser(studyGroupId: $studyGroupId, userId: $userId, userType: Teacher) {
      studyGroup {
        teachers {
          id
          fullName
          showPath
        }
      }
    }
  }
`

const removeTeacherMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupRemoveUser(studyGroupId: $studyGroupId, userId: $userId, userType: Teacher) {
      studyGroup {
        teachers {
          id
          fullName
          showPath
        }
      }
    }
  }
`

export default Teachers
