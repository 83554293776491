import React from "react"

import { publicClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const EditTutorApplication = ({ token }) => {
  const [result] = useQuery({
    query: tutorApplicationQuery,
    variables: { token },
    client,
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Form tutorApplication={data.tutorApplication} client={client} />
      )}
    </UrqlLoadingIndicator>
  )
}

const tutorApplicationQuery = gql`
  query ($token: String!) {
    tutorApplication(token: $token) {
      id
      smsOptIn
      token

      # personal details
      firstName
      lastName
      email
      phoneNumber
      referralSource
      referralSourceOther
      inUsa
      country
      region
      timeZone

      # demographics
      canCompleteFingerprint
      backgroundCheckNotes

      # education
      certificates
      noCollegeEducation
      degrees {
        id
        college
        year
        country
        degree
        degreeType
        level
        levelOther
        inUsa
        diplomaMill
      }

      # technology
      techSavviness
      highSpeedInternet
      hasTablet
      willPurchaseTablet
      canDownloadFiles
      canUseCommonWebapps
      canTroubleshootWebcam
      canTroubleshootLogin

      # teaching experience
      teachingExperienceYears
      teachingExperienceK2
      teachingExperienceK2Other
      teachingExperience35
      teachingExperience35Other
      teachingExperience68
      teachingExperience68Other
      teachingExperience912
      teachingExperience912Other
      teachingExperienceCollege
      teachingExperienceCollegeOther
      learningDifferencesTeachingAbility
      learningDifferencesExperienceNotes
      behavioralDifferencesTeachingAbility
      behavioralDifferencesExperienceNotes
      englishLanguageLearnersTeachingAbility
      englishLanguageLearnersExperienceNotes
      teachingStyle
      teachingReason
      camaraderieNotes
      parentCommunicationAbility
      yearsSinceLastTeachingExperience

      subjectBuckets {
        id
      }
      availabilities {
        id
      }

      foreignLanguageProficiencies {
        id
        fluency
        academicAbility
        foreignLanguage {
          id
          name
        }
      }
    }
  }
`

export default EditTutorApplication
