import React from "react"

import { components as selectComponents } from "react-select"

import { SelectField } from "components/Forms/Formik/hookComponents"

const SingleValue = props => (
  <selectComponents.SingleValue {...props}>
    {props.data.name} ({props.data.formattedBalance} Remaining)
  </selectComponents.SingleValue>
)

const Budgets = React.forwardRef(({ budgets, ...props }, ref) => {
  return (
    <SelectField
      name="budgetId"
      label="Budget"
      ref={ref}
      options={budgets}
      valueAttribute="id"
      labelAttribute="name"
      getOptionValue={option => option.id}
      getOptionLabel={option =>
        `${option.name} ${option.formattedBalance} ${option.startsOn} ${option.endsOn}`
      }
      formatOptionLabel={option => (
        <div>
          {option.name}
          <br />
          Remaining: {option.formattedBalance}
          <br />
          {option.startsOn} &mdash; {option.endsOn}
        </div>
      )}
      components={{ SingleValue }}
      {...props}
    />
  )
})

export default Budgets
