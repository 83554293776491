import React, { Dispatch, SetStateAction, useEffect } from "react"

import { FormikValues, useFormikContext } from "formik"

import { ButtonVariant } from "~tailwindui/types/enums"

import Form from "~tailwindui/Form"
import FormikForm from "~tailwindui/FormikForm"

export type ChangePasswordFormProps = {
  changePassword: boolean
  setChangePassword: Dispatch<SetStateAction<boolean>>
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  changePassword,
  setChangePassword,
}) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>()
  const togglePasswordChange = () => {
    if (changePassword) {
      setChangePassword(false)
      setFieldValue("currentPassword", "")
      setFieldValue("password", "")
      setFieldValue("passwordConfirmation", "")
    } else {
      setChangePassword(true)
    }
  }

  useEffect(() => {
    if (changePassword) togglePasswordChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.editing])
  return (
    <>
      {changePassword ? (
        <>
          <FormikForm.PasswordInput
            label="Current Password"
            name="currentPassword"
            autoComplete="current-password"
            subfield
          />
          <FormikForm.PasswordInput
            label="New Password"
            name="password"
            autoComplete="new-password"
            subfield
          />
          <FormikForm.PasswordInput
            label="Password Confirmation"
            name="passwordConfirmation"
            autoComplete="new-password"
            subfield
          />
          <div className="ml-8">
            <Form.Button
              text="Cancel"
              onClick={togglePasswordChange}
              variant={ButtonVariant.Light}
              classNames="my-6"
            />
          </div>
        </>
      ) : (
        <div>
          <Form.Button
            text="Change Password"
            onClick={togglePasswordChange}
            variant={ButtonVariant.Light}
            classNames="my-6"
          />
        </div>
      )}
    </>
  )
}

export default ChangePasswordForm
