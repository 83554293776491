import React from "react"

import { tutorClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const ReplacementRequest = ({ id }) => {
  const [result] = useQuery({
    query: studyGroupQuery,
    variables: { id },
    client,
  })
  return (
    <>
      <ModalWithProvidedBody
        modalTitle="Request Replacement Tutor"
        modalClassName="bootstrap-modal"
        buttonText="Give up group"
        buttonClassName="btn solid orange"
      >
        {({ closeModal }) => (
          <UrqlLoadingIndicator result={result}>
            {({ data: { studyGroup } }) => (
              <Form studyGroup={studyGroup} closeModal={closeModal} />
            )}
          </UrqlLoadingIndicator>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const studyGroupQuery = gql`
  query studyGroupQuery($id: ID!) {
    studyGroup(id: $id) {
      id
      imminentSession {
        id
        startsAt
      }
    }
  }
`

export default ReplacementRequest
