import React, { useState } from "react"

import { pipe, subscribe } from "wonka"

import client from "src/urql-client"

import { gql } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ReassignStudentsButton = ({ airtutorsSessionId }) => {
  const [running, setRunning] = useState(false)
  const [status, setStatus] = useState()
  const [errorMessages, setErrorMessages] = useState([])

  const reassignStudents = closeModal => {
    setRunning(true)
    setStatus("Starting")
    setErrorMessages([])

    const { unsubscribe } = pipe(
      client.subscription(reassignStudentsSubscription, {
        airtutorsSessionId,
      }),
      subscribe(result => {
        if (result.data) {
          const { status, errorMessages } =
            result.data.airtutorsSessionReassignStudents
          setStatus(status)
          setErrorMessages(errorMessages)
          if (errorMessages.length) {
            setErrorMessages(errorMessages)
            unsubscribe()
          }
          if (status === "in-progress") {
            setStatus("In Progress")
          }
          if (status === "success") {
            setErrorMessages([])
            setStatus(
              <>
                Reassignment Successful!
                <br />
                Students have been reassigned and will be redirected to their
                new session momentarily.
                <br />
                <button onClick={closeModal} className="btn btn-outline">
                  Dismiss
                </button>
              </>
            )
            unsubscribe()
          }
          if (status === "previously-reassigned") {
            setErrorMessages([])
            setStatus(
              <>
                No action taken
                <br />
                Students were already reassigned either by another admin or by
                an automated process.
                <br />
                <button onClick={closeModal} className="btn btn-outline">
                  Dismiss
                </button>
              </>
            )
          }
          if (status === "session-canceled") {
            setErrorMessages([])
            setStatus(
              <>
                We were unable to find any matching sessions. This session has
                been canceled and the students will be notified momentarily.
                <br />
                <button onClick={closeModal} className="btn btn-outline">
                  Dismiss
                </button>
              </>
            )
            unsubscribe()
          }
          if (status === "tutor-found") {
            setErrorMessages([])
            setStatus(
              <>
                Good news! The tutor is currently present in the session, so no
                action was taken.
                <br />
                <button onClick={closeModal} className="btn btn-outline">
                  Dismiss
                </button>
              </>
            )
            unsubscribe()
          }
        } else if (result.error) {
          setStatus()
          setErrorMessages([result.error?.message])
          unsubscribe()
        }
      })
    )
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Auto-Reassign Students"
      buttonText="Reassign Students"
      buttonClassName="btn bg-purple-500 hover:!bg-purple-700 focus:!bg-purple-700 active:focus:!bg-purple-900"
      hideCloseButton
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p>
              Click Reassign Students below to trigger the student reassignment
              flow. This will automatically reassign students to matching
              sessions currently in progress.
            </p>
            <p>
              If no session can be found, this session will be canceled and all
              students currently in the cloudroom will be notified.
            </p>
          </div>
          <div className="modal-footer flex-col">
            {status && (
              <div className="alert bg-blue-400 p-3">
                Current Status: {status}
              </div>
            )}
            {errorMessages.map((msg, index) => (
              <div className="alert alert-danger p-3" key={index}>
                {msg}
              </div>
            ))}
            <div className="flex w-full justify-between">
              <button
                disabled={running}
                onClick={() => reassignStudents(closeModal)}
                className="btn btn-warning"
              >
                Reassign Students
              </button>
              <button
                disabled={running}
                onClick={closeModal}
                className="btn btn-danger"
              >
                Nevermind!
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const reassignStudentsSubscription = gql`
  subscription ($airtutorsSessionId: ID!) {
    airtutorsSessionReassignStudents(airtutorsSessionId: $airtutorsSessionId) {
      status
      errorMessages
    }
  }
`

export default ReassignStudentsButton
