import React from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import "react-table/react-table.css"

const AdminLessonPlansTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/lesson_plans",
  })

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/admins/lesson_plans/autocomplete_name"
          />
        )
      },
    },
    {
      id: "topics",
      Header: "Topics",
      accessor: "lesson_topics",
      filterable: false,
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {props.value.map(topic => (
            <li key={topic.id}>{topic.name}</li>
          ))}
        </ul>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminLessonPlansTable
