import React, { Fragment } from "react"

import dayjs, { Dayjs } from "dayjs"
import dayjsTimezone from "dayjs/plugin/timezone"
import dayjsUtc from "dayjs/plugin/utc"
import { gql } from "urql"

import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

import { TimezoneOption } from "~tailwindui/types/enums"

import ExportForm from "./ExportForm"
import ExportModal from "./ExportModal"

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

export type ExportDropDownProps = {
  startingAt: Dayjs
  endingAt: Dayjs
  timezone: TimezoneOption
  schools?: { name: string; id: number }[]
}

const ExportDropDown: React.FC<ExportDropDownProps> = ({
  startingAt,
  endingAt,
  timezone,
  schools = [],
}) => {
  const initialValues = {
    startingAt,
    endingAt,
    timezone,
    schools,
  }

  const convertFormValues = ({ startingAt, endingAt, ...rest }) => {
    return {
      startingAt: dayjs(startingAt).tz(`US/${rest.timezone}`, true).toString(),
      endingAt: dayjs(endingAt).tz(`US/${rest.timezone}`, true).toString(),
      schoolIds: schools.map(school => school.id),
      ...rest,
    }
  }

  const schoolNames = `Air Tutors ${schools
    .map(school => school.name)
    .join(" ")}`

  const exportOptions = [
    {
      label: "Sessions",
      subscription: ungroupedSessionsExportSubscription,
      subscriptionName: "sessionsDataSetExport",
      filename: `${schoolNames} Sessions.csv`,
    },
    {
      label: "Students",
      subscription: ungroupedStudentsExportSubscription,
      subscriptionName: "studentsDataSetExport",
      filename: `${schoolNames} Students.csv`,
    },
    {
      label: "Session Attendance",
      subscription: ungroupedAttendanceExportSubscription,
      subscriptionName: "attendanceDataSetExport",
      filename: `${schoolNames} Session Attendance.csv`,
    },
    {
      label: "Student Attendance Summary",
      subscription: studentAttendanceSummaryExportSubscription,
      subscriptionName: "studentAttendanceSummaryExport",
      filename: `${schoolNames} Student Attendance.csv`,
    },
  ]

  return (
    <div className="my-5 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        disabled
        className="relative inline-flex items-center rounded-l-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white  ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
      >
        Export Data
      </button>
      <Disclosure as="div" className="relative -ml-px block">
        <Disclosure.Button className="relative inline-flex items-center rounded-r-md bg-indigo-600 px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Disclosure.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Disclosure.Panel className="absolute right-0 z-10 -mr-1 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {({ close }) => (
              <div className="py-1">
                {exportOptions.map(item => (
                  <ExportModal
                    key={item.label}
                    title={`Export ${item.label}`}
                    buttonText={item.label}
                    buttonClassNames={
                      "w-full text-left hover:bg-gray-100 hover:text-gray-900 gb-white text-gray-700 block px-4 py-2 text-sm"
                    }
                    onClose={close}
                    subscription={item.subscription}
                    subscriptionName={item.subscriptionName}
                    convertFormValues={convertFormValues}
                    initialValues={initialValues}
                    filename={item.filename}
                  >
                    <ExportForm />
                  </ExportModal>
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  )
}

const ungroupedSessionsExportSubscription = gql`
  subscription sessionsUngroupedExport(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $timezone: TimeZoneEnum!
    $schoolIds: [ID!]
  ) {
    sessionsDataSetExport(
      filters: {
        startingAt: $startingAt
        endingAt: $endingAt
        schoolIds: $schoolIds
      }
      groupByOptions: { groups: [] }
      timezone: $timezone
    ) {
      status
      errorMessages
      csv
    }
  }
`

const ungroupedStudentsExportSubscription = gql`
  subscription studentsUngroupedExport(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $timezone: TimeZoneEnum!
    $schoolIds: [ID!]
  ) {
    studentsDataSetExport(
      filters: {
        startingAt: $startingAt
        endingAt: $endingAt
        schoolIds: $schoolIds
        attending: true
      }
      groupByOptions: { groups: [] }
      timezone: $timezone
    ) {
      status
      errorMessages
      csv
    }
  }
`

const ungroupedAttendanceExportSubscription = gql`
  subscription attendanceUngroupedExport(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $timezone: TimeZoneEnum!
    $schoolIds: [ID!]
  ) {
    attendanceDataSetExport(
      filters: {
        startingAt: $startingAt
        endingAt: $endingAt
        schoolIds: $schoolIds
      }
      groupByOptions: { groups: [] }
      timezone: $timezone
    ) {
      status
      errorMessages
      csv
    }
  }
`

const studentAttendanceSummaryExportSubscription = gql`
  subscription studentAttendanceSummaryExport(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $timezone: TimeZoneEnum!
    $schoolIds: [ID!]!
  ) {
    studentAttendanceSummaryExport(
      startDate: $startingAt
      endDate: $endingAt
      schoolIds: $schoolIds
      timezone: $timezone
    ) {
      status
      errorMessages
      csv
    }
  }
`

export default ExportDropDown
