import React from "react"

import LocalTime from "components/LocalTime"

const Availability = ({ availability }) => {
  return (
    <div className="grid grid-cols-2">
      <div>{availability.weekday}</div>
      <div>
        <LocalTime timestamp={availability.startsAt} omitDate /> &ndash;{" "}
        <LocalTime timestamp={availability.endsAt} omitDate />
      </div>
    </div>
  )
}

export default Availability
