import React, { useState } from "react"

import { buildMutation, useMutation } from "micro-graphql-react"

import AdminNotification from "components/AdminNotification"
import { Loading } from "components/Icons"

import publishTutorMutation from "./publishTutorMutation"
import unpublishTutorMutation from "./unpublishTutorMutation"

const PublishButton = props => {
  const { id, published: initialPublished } = props
  const [published, setPublished] = useState(initialPublished)
  const [errorMessage, setErrorMessage] = useState()
  const { runMutation: publishTutor, running: publishRunning } = useMutation(
    buildMutation(publishTutorMutation)
  )
  const { runMutation: unpublishTutor, running: unpublishRunning } =
    useMutation(buildMutation(unpublishTutorMutation))

  const publish = () => {
    publishTutor({ id }).then(response => {
      setPublished(response.publishTutor.published)
      if (response.publishTutor.errorMessage) {
        setErrorMessage(
          `Tutor is invalid for publishing:
          ${response.publishTutor.errorMessage}`
        )
      }
    })
  }

  const unpublish = () => {
    unpublishTutor({ id }).then(response => {
      setPublished(response.unpublishTutor.published)
    })
  }

  return published ? (
    <button
      onClick={unpublish}
      className="btn btn-sm btn-danger"
      disabled={unpublishRunning}
    >
      Unpublish
      {unpublishRunning && (
        <div className="d-inline-block ml-2">
          <Loading />
        </div>
      )}
    </button>
  ) : (
    <>
      {errorMessage && (
        <AdminNotification type="error" content={errorMessage} />
      )}
      <button
        onClick={publish}
        className="btn btn-sm btn-success"
        disabled={publishRunning}
      >
        Publish
        {publishRunning && (
          <div className="d-inline-block ml-2">
            <Loading />
          </div>
        )}
      </button>
    </>
  )
}

export default PublishButton
