import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  SubmitButton,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import studyGroupFragment from "./studyGroupFragment"

const ValidationSchema = Yup.object().shape({
  emailsString: Yup.mixed().test(
    "emailsString",
    "You must provide at least 1 email",
    emailsString =>
      emailsString && emailsString.split(/,|\n/).filter(Boolean).length
  ),
})

const RegisterStudent = ({ courseSection }) => {
  const formState = { emailsString: "" }
  const [errors, setErrors] = useState([])
  const [unknownEmails, setUnknownEmails] = useState([])
  const { runMutation } = useMutation(buildMutation(registerStudentMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const { emailsString } = values
    const emails = emailsString
      .split(/,|\n/)
      .filter(Boolean)
      .map(email => email.trim())
    runMutation({
      courseSectionId: courseSection.id,
      emails: emails,
    })
      .then(
        response => {
          const { failures, unknownEmails } =
            response.courseSectionBulkRegisterStudents
          if (failures?.length) {
            setErrors(failures)
            return
          }
          if (unknownEmails?.length) {
            setUnknownEmails(unknownEmails)
            return
          }
          closeModal()
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Bulk Register Students"
      buttonText="Bulk Register"
      buttonClassName="btn btn-info"
      afterClose={() => {
        setErrors([])
        setUnknownEmails([])
      }}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <TextAreaField
                name="emailsString"
                label="Emails (one per line or separate by commas)"
              />
            </div>

            <div className="modal-footer flex flex-col">
              {errors.map(error => (
                <div className="alert alert-danger" key={error.message}>
                  {error.message}
                </div>
              ))}
              {unknownEmails.length ? (
                <div className="alert alert-danger">
                  The following emails could not be mapped to a student and were
                  skipped (the other students were added successfully):
                  <ul className="list-unstyled">
                    {unknownEmails.map(email => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Register" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const registerStudentMutation = compress`
  ${studyGroupFragment}
  mutation($courseSectionId: ID!, $emails: [String!]!) {
    courseSectionBulkRegisterStudents(courseSectionId: $courseSectionId, emails: $emails) {
      unknownEmails
      failures {
        message
      }
      courseSection {
        id
        assignedStudyGroups {
          currentPage
          totalPages
          data {
            ...StudyGroupFields
          }
        }
        unassignedStudyGroups {
          currentPage
          totalPages
          data {
            ...StudyGroupFields
          }
        }
      }
    }
  }
`

export default RegisterStudent
