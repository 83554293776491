import React from "react"

import { useField, useFormikContext } from "formik"

import { DateRangePicker } from "components/Forms/DatePicker"

import ErrorMessage from "./ErrorMessage"

const DateRangeField = props => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  return (
    <div className="form-group">
      <label className="form-label">
        <span>{props.label}</span>
        <DateRangePicker
          {...field}
          onChange={dates => setFieldValue(field.name, dates)}
          {...props}
        />
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default DateRangeField
