import React, { ReactNode } from "react"

import AlertMessageBox from "~tailwindui/Form/AlertMessageBox"
import Spinner from "~tailwindui/shared/Spinner"

import { reportGraphQLError } from "src/airbrake-notifier"

export type WithLoadingIndicatorProps = {
  result: any
  children: ReactNode
  header?: ReactNode | string
}

const WithLoadingIndicator = ({ result, children, header = null }) => {
  if (result.fetching)
    return (
      <React.Fragment>
        {header}
        <Spinner />
      </React.Fragment>
    )

  if (result.error) {
    reportGraphQLError({
      result,
      component: "tailwindui/shared/WithLoadingIndicator",
    })
    return (
      <React.Fragment>
        <AlertMessageBox
          messages={[
            { message: result.error.message.replace("[GraphQL] ", "") },
          ]}
        />
      </React.Fragment>
    )
  }

  if (result.data) {
    return (
      <React.Fragment>
        {header}
        {typeof children === "function"
          ? children({ data: result.data })
          : children}
      </React.Fragment>
    )
  }
  return null
}

export default WithLoadingIndicator
