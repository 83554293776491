import { compress } from "micro-graphql-react"

export default compress`
  fragment LessonResourcesFields on LessonResource {
    id
    name
    sortOrder
    file {
      fileName
      url
      thumbUrl
    }
  }
`
