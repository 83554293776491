import React, { useState } from "react"

import { DateObject, DatePicker } from "components/Forms/DatePicker"

const RailsDatePicker = props => {
  const [selectedDate, setSelectedDate] = useState(
    props.value ? new DateObject(props.value) : null
  )

  return (
    <div className="form-group">
      <label className="form-control-label">{props.label}</label>
      <input
        name={props.name}
        type="hidden"
        value={selectedDate ? selectedDate.toDate().toString() : ""}
      />
      <DatePicker onChange={date => setSelectedDate(date)} />
    </div>
  )
}

export default RailsDatePicker
