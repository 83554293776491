import React, { ReactNode } from "react"

export type HeaderProps = {
  title: string
  children?: ReactNode
}

const Header: React.FC<HeaderProps> = ({ title, children = null }) => {
  return (
    <div className="pb-5 md:flex md:items-center md:justify-start">
      <div className="min-w-0">
        <h2 className="text-lg font-medium leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
      </div>
      <div className="mt-4 flex items-center md:ml-4 md:mt-0">{children}</div>
    </div>
  )
}

export default Header
