import React from "react"

import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import Chip from "~tailwindui/ChipSimple"

import type { TimePeriod } from "src/types"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type StudentsDataSetChipProps = {
  label: string
  startingAt: Dayjs
  endingAt: Dayjs
  groups?: [string]
  timePeriod?: TimePeriod
  firstSession?: boolean
  attending?: boolean
}

const StudentsDataSetChip: React.FC<StudentsDataSetChipProps> = ({
  label,
  startingAt,
  endingAt,
  groups = [],
  timePeriod,
  firstSession = false,
  attending = false,
}) => {
  const today = dayjs().format("MM/DD/YYYY")
  const [result] = useQuery({
    query,
    variables: {
      startingAt,
      endingAt,
      lastThirtyStart: dayjs(today).subtract(30, "days").startOf("day"),
      lastThirtyEnd: dayjs(today).endOf("day"),
      groups,
      timePeriod,
      firstSession,
      attending,
    },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({
        data: {
          viewer: {
            organization: { customRange, lastThirty },
          },
        },
      }) => (
        <Chip.Item
          label={label}
          value={customRange.total}
          valueFormat={ValueFormat.Number}
          subtitle="Last 30 Days:"
          subtitleValue={lastThirty.total}
          subtitleValueFormat={ValueFormat.Number}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query StudentsDataSetChipQuery(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $lastThirtyStart: DateTime!
    $lastThirtyEnd: DateTime!
    $groups: [GroupsEnum!]!
    $timePeriod: GroupByTimePeriodInputObject
    $firstSession: Boolean!
    $attending: Boolean!
  ) {
    viewer {
      organization {
        customRange: studentsDataSet(
          filters: {
            startingAt: $startingAt
            endingAt: $endingAt
            firstSession: $firstSession
            attending: $attending
          }
          groupByOptions: { groups: $groups, timePeriod: $timePeriod }
        ) {
          total
        }

        lastThirty: studentsDataSet(
          filters: {
            startingAt: $lastThirtyStart
            endingAt: $lastThirtyEnd
            firstSession: $firstSession
            attending: $attending
          }
          groupByOptions: { groups: $groups, timePeriod: $timePeriod }
        ) {
          total
        }
      }
    }
  }
`

export default StudentsDataSetChip
