import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $tutorId: ID!,
    $fullNameReviewRequired: Boolean!,
    $fullNameReviewFeedback: String,
    $phoneNumberReviewRequired: Boolean!,
    $phoneNumberReviewFeedback: String,
    $bioReviewRequired: Boolean!,
    $bioReviewFeedback: String,
    $educationReviewRequired: Boolean!,
    $educationReviewFeedback: String,
    $emailTimeZoneReviewRequired: Boolean!,
    $emailTimeZoneReviewFeedback: String,
    $avatarReviewRequired: Boolean!,
    $avatarReviewFeedback: String,
    $tierIdsReviewRequired: Boolean!,
    $tierIdsReviewFeedback: String,
  ) {
    updateTutorReview(
      tutorId: $tutorId,
      fullNameReviewRequired: $fullNameReviewRequired,
      fullNameReviewFeedback: $fullNameReviewFeedback,
      phoneNumberReviewRequired: $phoneNumberReviewRequired,
      phoneNumberReviewFeedback: $phoneNumberReviewFeedback,
      bioReviewRequired: $bioReviewRequired,
      bioReviewFeedback: $bioReviewFeedback,
      educationReviewRequired: $educationReviewRequired,
      educationReviewFeedback: $educationReviewFeedback,
      emailTimeZoneReviewRequired: $emailTimeZoneReviewRequired,
      emailTimeZoneReviewFeedback: $emailTimeZoneReviewFeedback,
      avatarReviewRequired: $avatarReviewRequired,
      avatarReviewFeedback: $avatarReviewFeedback,
      tierIdsReviewRequired: $tierIdsReviewRequired,
      tierIdsReviewFeedback: $tierIdsReviewFeedback,
    ) {
      success
    }
  }
`
