import React, { useState } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { useDisclosure } from "@chakra-ui/core"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import Edit from "./Edit"
import Remove from "./Remove"

import "react-table/react-table.css"

const AdminCollegesTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/colleges",
  })
  const [college, setCollege] = useState()
  const {
    isOpen: isEditing,
    onOpen: startEditing,
    onClose: stopEditing,
  } = useDisclosure()
  const {
    isOpen: isRemoving,
    onOpen: startRemoving,
    onClose: stopRemoving,
  } = useDisclosure()

  const editCollege = collegeProps => {
    setCollege(collegeProps.original)
    startEditing()
  }

  const removeCollege = collegeProps => {
    setCollege(collegeProps.original)
    startRemoving()
  }

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/colleges/autocomplete_name"
          />
        )
      },
    },
    {
      id: "diploma_mill",
      Header: "Diploma Mill",
      accessor: "diploma_mill",
      Cell: props =>
        props.value ? (
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faTimes} className="text-danger" />
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => onChange(event.value)}
            setValue={filter ? filter.value : ""}
            options={[
              { value: "", label: "All" },
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-items-start">
          <button
            type="button"
            onClick={() => editCollege(props)}
            className="btn btn-sm btn-info"
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() => removeCollege(props)}
            className="btn btn-sm btn-danger"
          >
            Remove
          </button>
        </div>
      ),
    },
  ]

  return (
    <>
      <Edit
        isOpen={isEditing}
        college={college}
        onClose={() => {
          setCollege()
          stopEditing()
        }}
      />
      <Remove
        isOpen={isRemoving}
        college={college}
        onClose={() => {
          setCollege()
          stopRemoving()
        }}
      />
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        manual
        filterable
      />
    </>
  )
}

export default AdminCollegesTable
