import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { DateObject, DateTimePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  publishedAt: Yup.number().required("Please select a publish date"),
})

const UpdatePublishedAt = ({ courseCluster, update }) => {
  const formState = {
    id: courseCluster.id,
    publishedAt: courseCluster.publishedAt
      ? new DateObject(new Date(courseCluster.publishedAt))
      : new DateObject().setHour(12).setMinute(0).setSecond(0),
  }

  const { runMutation: runUnpublishMutation } = useMutation(
    buildMutation(unpublishMutation)
  )

  const unpublish = (setStatus, closeModal) => {
    runUnpublishMutation({ id: courseCluster.id })
      .then(
        response => {
          const { errorMessages } = response
          if (errorMessages) {
            setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => setStatus("Something went wrong")
      )
      .catch(() => setStatus("Something went wrong"))
  }

  const handleSubmit = (values, actions, closeModal) => {
    const { publishedAt, ...rest } = values
    update({ publishedAt: publishedAt.toDate(), ...rest })
      .then(
        response => {
          const { errorMessages } = response.unpublishCourseCluster
          if (errorMessages) {
            actions.setStatus(errorMessages)
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => {
        closeModal()
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      buttonText="Change Publish Date"
      buttonClassName="btn btn-sm btn-outline-info"
      modalTitle="Set Publish Date"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ status, isSubmitting, values, setFieldValue, setStatus }) => (
            <Form>
              <div className="modal-body">
                <div
                  className="form-group"
                  css={css`
                    min-height: 150px;
                  `}
                >
                  <label className="form-label w-100">
                    Publish Date
                    <DateTimePicker
                      fixed
                      value={values.publishedAt}
                      onChange={date => setFieldValue("publishedAt", date)}
                    />
                  </label>
                  <ErrorMessage name="publishedAt" />
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && (
                  <div className="alert alert-danger w-100">{status}</div>
                )}
                <div className="w-100 d-flex justify-content-between">
                  <button
                    onClick={() => unpublish(setStatus, closeModal)}
                    type="button"
                    className="btn btn-outline-danger"
                  >
                    Unpublish
                  </button>
                  <SubmitButton
                    text="Update"
                    isSubmitting={isSubmitting}
                    containerCss={css`
                      margin: 0;
                    `}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const unpublishMutation = compress`
  mutation($id: ID!) {
    unpublishCourseCluster(id: $id) {
      errorMessages
      courseCluster {
        id
        publishedAt
      }
    }
  }
`

export default UpdatePublishedAt
