import React, { useContext } from "react"

import LocalTime from "components/LocalTime"

import { ModalContext } from "./index"

const StudentsTable = ({ students }) => {
  const [, setStudent] = useContext(ModalContext)

  if (students.length === 0) return "No students found"
  return (
    <table className="table">
      <thead className="text-primary">
        <tr>
          <th>Name</th>
          <th>Account Created</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {students.map(student => (
          <tr key={student.id}>
            <td>
              <a href={student.showPath}>{student.fullName}</a>
            </td>
            <td>
              <LocalTime timestamp={student.createdAt} omitTime />
            </td>
            <td>
              <button
                className="btn btn-info"
                onClick={() => setStudent(student)}
              >
                Contact
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StudentsTable
