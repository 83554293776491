import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { participationLevelOptions } from "src/enums"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const PresentValidationSchema = Yup.object().shape({
  participationLevel: Yup.string().required("Participation level is required"),
})

const AbsentValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required("This field is required"),
})

const StudentPresenceStatus = ({
  studentSessionId,
  present,
  noShow,
  excusedAbsence,
}) => {
  const formState = { participationLevel: "" }

  const client = useClient()
  const [, runPresentMutation] = useMutation(markPresentMutation, client)
  const [, runNoShowMutation] = useMutation(markNoShowMutation, client)

  const markPresent = (values, actions) => {
    runPresentMutation({
      studentSessionId,
      participationLevel: values.participationLevel,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkPresent
          if (failures) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const markNoShow = (values, actions) => {
    runNoShowMutation({
      studentSessionId,
      cancellationReason: values.cancellationReason,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkNoShow
          if (failures) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <div className="text-center">
      {excusedAbsence ? (
        <div className="btn disabled !opacity-100">Excused</div>
      ) : (
        <>
          {present ? (
            <div className="btn disabled !opacity-100">Present</div>
          ) : (
            <ModalWithProvidedBody
              modalTitle="Mark as Present"
              buttonText="Mark as present"
              modalClassName="bootstrap-modal"
              buttonClassName="btn solid green"
            >
              {({ closeModal }) => (
                <Formik
                  initialValues={formState}
                  validationSchema={PresentValidationSchema}
                  onSubmit={markPresent}
                >
                  <Form>
                    <div className="modal-body">
                      <SelectField
                        name="participationLevel"
                        label="Participation Level"
                        options={participationLevelOptions}
                      />
                    </div>

                    <div className="modal-footer flex-col">
                      <FormFailures />
                      <div className="flex w-full justify-between">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <SubmitButton text="Update" />
                      </div>
                    </div>
                  </Form>
                </Formik>
              )}
            </ModalWithProvidedBody>
          )}

          <div>
            {noShow ? (
              <div className="btn disabled !opacity-100">No Show</div>
            ) : (
              <ModalWithProvidedBody
                buttonClassName="btn solid red"
                buttonText="Mark as No Show"
                modalClassName="bootstrap-modal"
                modalTitle="Submit No Show"
              >
                {({ closeModal }) => (
                  <Formik
                    initialValues={{ cancellationReason: "" }}
                    validationSchema={AbsentValidationSchema}
                    onSubmit={markNoShow}
                  >
                    <Form>
                      <div className="modal-body">
                        <Field
                          name="cancellationReason"
                          label="Why are you updating this student's no show status?"
                        />
                      </div>
                      <div
                        className="modal-footer"
                        style={{ flexFlow: "column" }}
                      >
                        <FormFailures />
                        <div className="w-100 d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn solid blue btn-info"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <SubmitButton
                            text="Submit"
                            className="btn solid red btn-danger"
                          />
                        </div>
                      </div>
                    </Form>
                  </Formik>
                )}
              </ModalWithProvidedBody>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const markPresentMutation = gql`
  mutation markPresent(
    $studentSessionId: ID!
    $participationLevel: ParticipationLevelEnum!
  ) {
    studentSessionMarkPresent(
      studentSessionId: $studentSessionId
      participationLevel: $participationLevel
    ) {
      failures {
        message
      }
    }
  }
`

const markNoShowMutation = gql`
  mutation markNoShow($studentSessionId: ID!, $cancellationReason: String!) {
    studentSessionMarkNoShow(
      studentSessionId: $studentSessionId
      cancellationReason: $cancellationReason
    ) {
      failures {
        message
      }
    }
  }
`

export default StudentPresenceStatus
