import React from "react"

import { gql, useQuery } from "urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import StudyGroupsList from "./StudyGroupsList"

const StudyGroupsIndex = props => {
  const [result] = useQuery({ query: studyGroupsQuery })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => <StudyGroupsList studyGroups={data.studyGroups.data} />}
    </UrqlLoadingIndicator>
  )
}

const studyGroupsQuery = gql`
  query StudyGroupsQuery {
    studyGroups {
      data {
        id
        name
        students {
          id
          fullName
          lastName
        }
      }
    }
  }
`

export default StudyGroupsIndex
