import React from "react"

import { Field } from "formik"

import "./styles.scss"

function RadioGroup(props) {
  let { values, defaultValue, name, type, onChange, checked } = props
  if (!type) type = "radio"

  const contents = values.map(
    ({ value, label, disabled, checked: valueChecked }) => {
      const checkedProps = {}
      const isChecked = checked || valueChecked
      if (isChecked !== null && isChecked !== undefined) {
        checkedProps.checked = isChecked
      } else {
        checkedProps.defaultChecked = defaultValue === value
      }

      return (
        <div className="form-check" key={value}>
          <label className={`form-check-label ${disabled ? "disabled" : ""}`}>
            {label}
            <input
              className="form-check-input"
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              disabled={disabled}
              {...checkedProps}
            />
            <span className="form-check-sign" />
          </label>
        </div>
      )
    }
  )

  return <Field component="div">{contents}</Field>
}

export default RadioGroup
