import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import NewSchool from "components/Admin/Schools/New"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type OrganizationSchoolsProps = {
  organizationId: string
  organizationName: string
}

const OrganizationSchools: React.FC<OrganizationSchoolsProps> = ({
  organizationId,
  organizationName,
}) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: schoolsQuery,
    variables: { organizationId, page },
  })

  return (
    <UrqlLoadingIndicator
      result={result}
      heading={
        <div className="mb-3 flex items-center">
          <h4 className="m-0 mr-3">Schools</h4>
          <NewSchool
            organizationName={organizationName}
            organizationId={organizationId}
          />
        </div>
      }
    >
      {({ data }) => (
        <>
          <AutocompleteSelect
            api="/api/admins/schools/autocomplete_name"
            searchOptions={`organization_id=${organizationId}`}
            onChange={selectedOption => {
              if (selectedOption)
                window.location.href = `/admin/schools/${selectedOption.value}`
            }}
            placeholder="Jump to school"
          />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Hourly Rate</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {data.organization.schools.data.map(school => (
                <tr key={school.id}>
                  <td>{school.name}</td>
                  <td>{school.formattedHourlyRate}</td>
                  <td>
                    <a className="btn btn-sm btn-info" href={school.showPath}>
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={data.organization.schools.currentPage}
            totalPages={data.organization.schools.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const schoolsQuery = gql`
  query ($organizationId: ID!, $page: Int!) {
    organization(id: $organizationId) {
      schools: paginatedSchools(page: $page) {
        currentPage
        totalPages
        data {
          id
          name
          formattedHourlyRate
          showPath
        }
      }
    }
  }
`

export default OrganizationSchools
