import React, { ReactHTMLElement, useRef, useState } from "react"

import moment from "moment-timezone"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

type TimeProtectedMiroLinkProps = {
  email: string
  boardId: string
  startsAt: string
  buttonClassName?: string
  buttonText?: string
}

const TimeProtectedMiroLink: React.FC<TimeProtectedMiroLinkProps> = ({
  email,
  boardId,
  startsAt,
  buttonClassName = "btn solid green",
  buttonText = "Whiteboard",
}) => {
  const form = useRef<HTMLFormElement>()
  const [showModal, setShowModal] = useState(false)
  const [timeString, setTimeString] = useState("")

  const onClick = () => {
    const timeDiff = moment(startsAt).unix() - moment().unix()
    const timeDiffHours = timeDiff / 60 / 60

    if (timeDiffHours <= 1) {
      form.current.submit()
    } else {
      const hours = Math.floor(timeDiffHours)
      const minutes = Math.floor((timeDiffHours - hours) * 60)
      if (hours > 24) {
        const days = Math.floor(hours / 24)
        setTimeString(`${days} ${days === 1 ? "day" : "days"}`)
      } else {
        setTimeString(`${hours} hours and ${minutes} minutes`)
      }
      setShowModal(true)
    }
  }

  return (
    <>
      <form
        ref={form}
        method="POST"
        action="https://miro.com/sso/api/process-email"
        target="_blank"
      >
        <input name="email" type="hidden" value={email} />
        <input name="openBoardId" type="hidden" value={boardId} />
        <button type="button" onClick={onClick} className={buttonClassName}>
          {buttonText}
        </button>
      </form>
      <ModalWithProvidedBody
        isOpen={showModal}
        hideTrigger
        modalTitle="This is an upcoming session"
        closeModal={() => setShowModal(false)}
        modalClassName="bootstrap-modal"
      >
        {({ closeModal }) => (
          <>
            <div className="modal-body">
              <p className="lead">
                This session is not scheduled to start for {timeString}. Are you
                sure this is the session you are trying to access?
              </p>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <button
                  onClick={() => {
                    form.current.submit()
                    closeModal()
                  }}
                  className="btn solid green"
                >
                  Yes
                </button>
                <button onClick={closeModal} className="btn solid red">
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

export default TimeProtectedMiroLink
