import React, { useEffect, useState } from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import EventEmitter from "src/EventEmitter"

import { useGradeOptions } from "hooks/remoteTable"
import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters in length"),
  passwordConfirmation: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
})

const BulkPasswordForm = ({ organizationId }) => {
  const formState = {
    organizationId,
    password: "",
    passwordConfirmation: "",
    schoolIds: [],
    gradeId: "",
  }
  const gradeOptions = useGradeOptions()
  const [, updatePasswords] = useMutation(bulkChangePasswordsMutation)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    EventEmitter.subscribe("bulkPasswordChangeComplete", data => {
      setSubmitting(false)
      if (data.success)
        NotificationManager.info("Bulk password change completed")
      else
        NotificationManager.error(
          "Something went wrong. No passwords were changed."
        )
    })
  }, [])

  const handleSubmit = (values, actions) => {
    updatePasswords(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures } = result.data.bulkPasswordChange
          if (failures) {
            handleFailure(actions, failures)
          } else {
            setSubmitting(true)
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <>
      <ModalWithProvidedBody
        modalTitle="Bulk Password Change"
        buttonText="Bulk Password Change"
        buttonClassName="btn btn-danger"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <SelectField
                  name="gradeId"
                  label="Grade"
                  options={gradeOptions}
                  isClearable
                />

                <AutocompleteField
                  api="/api/admins/schools/autocomplete_name"
                  searchOptions={`organization_id=${organizationId}`}
                  name="schoolIds"
                  label="Schools"
                  isMulti
                />

                <Field
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="new-password"
                />
                <Field
                  name="passwordConfirmation"
                  type="password"
                  label="Confirm Password"
                  autoComplete="new-password"
                />
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                {submitting && (
                  <div className="alert alert-danger">
                    This may take a moment, please wait.
                  </div>
                )}
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    text="Update Passwords"
                    submitting={submitting}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const bulkChangePasswordsMutation = gql`
  mutation (
    $organizationId: ID!
    $schoolIds: [ID!]
    $gradeId: ID
    $password: String!
  ) {
    bulkPasswordChange(
      organizationId: $organizationId
      schoolIds: $schoolIds
      gradeId: $gradeId
      password: $password
    ) {
      failures {
        message
      }
    }
  }
`

export default BulkPasswordForm
