import React, { useRef } from "react"

import { buildMutation, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import addTutorSubjectMutation from "./addTutorSubjectMutation"

const AddTutorSubject = props => {
  const { tutorId, tier } = props
  const selectRef = useRef(null)

  const { runMutation } = useMutation(buildMutation(addTutorSubjectMutation))

  const bucketSelected = option => {
    if (!option?.value) return
    const subjectBucketId = option.value
    runMutation({ tutorId, subjectBucketId }).then(() => {
      selectRef.current.clearValue()
    })
  }

  return (
    <AutocompleteSelect
      ref={selectRef}
      onChange={bucketSelected}
      api={"/api/admins/subject_buckets/autocomplete_name"}
      searchOptions={`tier_id=${tier.id}`}
      placeholder={`Add ${tier.gradeRange} subject`}
    />
  )
}

AddTutorSubject.propTypes = {
  tutorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tier: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gradeRange: PropTypes.string,
  }).isRequired,
}

export default AddTutorSubject
