import React from "react"

import { Field } from "formik"
import PropTypes from "prop-types"
import Select from "react-select"

import { timezoneOptions } from "src/enums"

const TimeZoneField = props => {
  const { name, setFieldValue, ...selectProps } = props
  return (
    <Field type="text" name={name} className="form-control">
      {({ field: { name, value } }) => (
        <Select
          options={timezoneOptions}
          defaultValue={timezoneOptions.find(option => option.value === value)}
          onChange={selectedOption => setFieldValue(name, selectedOption.value)}
          styles={{
            container: provided => ({
              ...provided,
              maxWidth: 250,
            }),
          }}
          {...selectProps}
        />
      )}
    </Field>
  )
}

TimeZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default TimeZoneField
