import React, { useState } from "react"

import { FormikContextType, FormikValues, useFormikContext } from "formik"

import { gql } from "@urql/core"

import ordered from "src/ordered"
import client from "src/urql-client"

import { useQuery } from "hooks/urql"

import { ErrorMessage } from "components/Forms/Formik"
import {
  AutocompleteField,
  CheckboxField,
  DateRangeField,
  Field,
  FormStatus,
  RadioField,
  SubmitButton,
  TimeField,
} from "components/Forms/Formik/hookComponents"
import { Loading } from "components/Icons"
import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Availability from "./Availability"

const StudyGroupResults = ({
  variables,
  warningMessage,
  setWarningMessage,
  errors,
  page,
  setPage,
  resetForm,
}) => {
  const { setFieldValue, values }: FormikContextType<FormikValues> =
    useFormikContext()
  const [selectAllInProgress, setSelectAllInProgress] = useState(false)
  const { submitted, ...input } = variables

  const [result] = useQuery({
    query: studyGroupsQuery,
    variables: { page: page, input },
  })

  const resetConfirmation = () => {
    if (["cancel", "reschedule"].includes(values.action)) {
      setWarningMessage()
      setFieldValue("performAction", false)
    }
  }

  const selectAll = async () => {
    resetForm()
    setSelectAllInProgress(true)
    let currentPage = 1
    let allGroupIds = []
    while (currentPage <= result.data.studyGroups.totalPages) {
      const results = await client
        .query(studyGroupsQuery, {
          page: currentPage,
          input,
        })
        .toPromise()
      allGroupIds = allGroupIds.concat(
        results.data.studyGroups.data.map(studyGroup => studyGroup.id)
      )
      currentPage++
    }
    setFieldValue("resourceIds", allGroupIds)
    setSelectAllInProgress(false)
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { studyGroups } }) =>
        studyGroups.data.length < 1 ? (
          <span className="font-italic">
            No study groups found with the current filters.
          </span>
        ) : (
          <>
            <ul className="list-unstyled">
              {studyGroups.data.map(studyGroup => (
                <li key={studyGroup.id}>
                  <CheckboxField
                    hideError
                    name="resourceIds"
                    value={studyGroup.id}
                    afterChange={resetConfirmation}
                    label={
                      <>
                        <div className="grid w-full grid-cols-3">
                          <dl className="mr-8">
                            <dt>Name</dt>
                            <dd>
                              <a
                                href={studyGroup.showPath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {studyGroup.name}
                              </a>
                            </dd>
                            <dt>Organization</dt>
                            <dd>{studyGroup.organization?.name}</dd>
                            <dt>School</dt>
                            <dd>{studyGroup.school?.name}</dd>
                            <dt>Tags</dt>
                            <dd>
                              {studyGroup.tags.map(tag => tag.name).join(", ")}
                            </dd>
                            {studyGroup.courseSection && (
                              <>
                                <dt>Course Cluster</dt>
                                <dd>
                                  {studyGroup.courseSection.courseCluster.name}
                                </dd>

                                <dt>Course Starts</dt>
                                <dd>
                                  <LocalTime
                                    timestamp={
                                      studyGroup.courseSection.startsOn
                                    }
                                    omitTime
                                  />
                                </dd>

                                <dt>Course Ends</dt>
                                <dd>
                                  <LocalTime
                                    timestamp={studyGroup.courseSection.endsOn}
                                    omitTime
                                  />
                                </dd>
                              </>
                            )}
                          </dl>
                          <dl className="mr-8">
                            <dt>Availabilities</dt>
                            <dd>
                              {studyGroup.courseSection
                                ? ordered(
                                    studyGroup.courseSection.availabilities,
                                    "weekdayNumber"
                                  ).map(availability => (
                                    <Availability
                                      key={availability.id}
                                      availability={availability}
                                    />
                                  ))
                                : ordered(
                                    studyGroup.availabilities,
                                    "weekdayNumber"
                                  ).map(availability => (
                                    <Availability
                                      key={availability.id}
                                      availability={availability}
                                    />
                                  ))}
                            </dd>
                          </dl>
                          <dl className="mr-8">
                            <dt>Tutor</dt>
                            <dd>
                              {studyGroup.tutor ? (
                                <a
                                  href={studyGroup.tutor.showPath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {studyGroup.tutor.fullName}
                                </a>
                              ) : null}
                            </dd>

                            <dt>Students</dt>
                            <dd>
                              <ul className="list-unstyled">
                                {ordered(studyGroup.students, "lastName").map(
                                  student => (
                                    <li key={student.id}>
                                      <a
                                        href={student.showPath}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {student.fullName}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </dd>
                          </dl>
                        </div>
                        <hr />
                      </>
                    }
                  />
                </li>
              ))}
            </ul>
            <ErrorMessage name="resourceIds" />

            <Pagination
              currentPage={studyGroups.currentPage}
              totalPages={studyGroups.totalPages}
              changePage={setPage}
            />

            <div className="d-flex">
              <button
                onClick={selectAll}
                type="button"
                className="btn btn-outline-info"
                disabled={selectAllInProgress}
              >
                Select All
                {selectAllInProgress && (
                  <span className="ml-3">
                    <Loading />
                  </span>
                )}
              </button>

              <button
                onClick={() => setFieldValue("resourceIds", [])}
                type="button"
                className="btn btn-outline-danger ml-3"
              >
                De-select All
              </button>
            </div>

            <div className="alert alert-info">
              {values.resourceIds.length} groups currently selected
            </div>

            <FormStatus />
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger mb-3 mt-3 p-2">
                <div className="ml-3">
                  <p>{error.message}</p>
                </div>
              </div>
            ))}

            <h5>Choose an Action</h5>
            <RadioField
              name="action"
              options={[
                { label: "Cancel Sessions", value: "cancel" },
                { label: "Schedule Sessions", value: "schedule" },
                { label: "Reschedule Sessions", value: "reschedule" },
                {
                  label: "Enable Excused Absences",
                  value: "excused_absences",
                },
                { label: "Add Tags", value: "tag" },
                { label: "Assign Budget", value: "assign_budget" },
              ]}
              afterChange={resetConfirmation}
            />

            {values.action === "schedule" && (
              <Field
                name="skipDays"
                label="Skip Days (leave blank to use default scheduling)"
                type="number"
              />
            )}

            {values.action === "reschedule" && (
              <>
                <TimeField name="newStartsAt" label="New Session Start Time" />
                <Field
                  name="durationMinutes"
                  label="Session Duration"
                  type="number"
                  min={15}
                  step={15}
                />
              </>
            )}

            {values.action === "tag" && (
              <>
                <AutocompleteField
                  name="tags"
                  api="/api/admins/study_groups/autocomplete_tags"
                  isMulti
                  valueAttribute="label"
                  label="Tags"
                  onChange={selected => setFieldValue("tags", selected)}
                  creatable
                />
              </>
            )}

            {values.action === "assign_budget" && (
              <>
                <AutocompleteField
                  name="budgetId"
                  label="Budget"
                  api="/api/admins/budgets/autocomplete_name"
                  searchOptions={
                    variables.organizationIds.length > 0
                      ? `organization_id=${variables.organizationIds}`
                      : ``
                  }
                />
              </>
            )}

            {["cancel", "schedule", "reschedule"].includes(values.action) && (
              <>
                <DateRangeField
                  name="dateRange"
                  label="Date Range"
                  minDate={new Date()}
                  afterChange={resetConfirmation}
                />

                <CheckboxField
                  name="skipStatusNotifications"
                  label="Skip Notifications"
                />
              </>
            )}

            {warningMessage && (
              <CheckboxField
                name="performAction"
                label={
                  <div className="alert alert-warning font-semibold text-black">
                    {warningMessage}
                  </div>
                }
              />
            )}

            {values.action && (
              <SubmitButton
                text={
                  values.action === "cancel"
                    ? `Cancel Sessions`
                    : values.action === "schedule"
                    ? "Schedule Sessions"
                    : values.action === "reschedule"
                    ? "Reschedule Sessions"
                    : values.action === "excused_absences"
                    ? "Enable Excused Absences"
                    : values.action === "tag"
                    ? "Add Tags"
                    : values.action === "assign_budget"
                    ? "Add Budgets"
                    : "Invalid Action"
                }
                className="btn btn-danger"
              />
            )}
          </>
        )
      }
    </UrqlLoadingIndicator>
  )
}

const studyGroupsQuery = gql`
  query ($page: Int!, $input: StudyGroupSearchInputObject!) {
    studyGroups(page: $page, input: $input) {
      currentPage
      totalPages
      data {
        id
        name
        showPath
        organization {
          id
          name
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
        students {
          id
          fullName
          lastName
          showPath
        }
        tutor {
          id
          fullName
          showPath
        }
        courseSection {
          id
          startsOn
          endsOn
          courseCluster {
            id
            name
          }
          availabilities {
            id
            startsAt
            endsAt
            weekday
            weekdayNumber
          }
        }
        school {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`

export default StudyGroupResults
