import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide a display name"),
  adminId: Yup.string().required("Please select an admin"),
  calendlyUrl: Yup.string().required("Please provide a calendly URL"),
})

const NewTutorApplicationInterviewer = () => {
  const formState = { name: "", adminId: "", calendlyUrl: "" }
  const [, createTutorApplicationInterviewer] = useMutation(
    createTutorApplicationInterviewerMutation
  )

  const handleSubmit = (values, actions) => {
    createTutorApplicationInterviewer(values).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { tutorApplicationInterviewer, failures } =
        result.data.createTutorApplicationInterviewer
      if (failures) {
        handleFailure(actions, failures)
        return
      }

      window.location = tutorApplicationInterviewer.showPath
    })
  }

  return (
    <ModalWithProvidedBody
      buttonText="Add Interviewer"
      buttonClassName="btn btn-info"
      modalTitle="New Tutor Application Interviewer"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field
                name="name"
                label="Display Name (this will be displayed to the applicant)"
                placeholder="Example: All Subjects K-5"
                autoFocus
              />
              <AutocompleteField
                name="adminId"
                label="Admin"
                api="/api/admins/admins/autocomplete_full_name"
              />
              <Field name="calendlyUrl" label="Calendly URL" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />

              <div className="w-100 flex justify-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createTutorApplicationInterviewerMutation = gql`
  mutation ($name: String!, $adminId: ID!, $calendlyUrl: String!) {
    createTutorApplicationInterviewer(
      name: $name
      adminId: $adminId
      calendlyUrl: $calendlyUrl
    ) {
      failures {
        message
      }
      tutorApplicationInterviewer {
        showPath
      }
    }
  }
`

export default NewTutorApplicationInterviewer
