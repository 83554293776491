import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { DateObject } from "components/Forms/DatePicker"
import {
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import AvailabilitiesInput from "./AvailabilitiesInput"

const ValidationSchema = Yup.object().shape({
  availabilities: Yup.array()
    .of(
      Yup.object().shape({
        startsAt: Yup.number().required("Please specify a start time"),
        endsAt: Yup.number().required("Please specify an end time"),
        weekday: Yup.string().required(
          "Please specify the weekday for this availability"
        ),
      })
    )
    .min(1, "Please add at least one availability"),
})

const AddAvailability = ({ courseSection }) => {
  const formState = {
    courseSectionId: courseSection.id,
    availabilities: [
      {
        id: Date.now(),
        startsAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
        endsAt: new DateObject().setHour(13).setMinute(0).setSecond(0),
        weekday: "",
      },
    ],
  }

  const { runMutation } = useMutation(
    buildMutation(createAvailabilitiesMutation)
  )

  const handleSubmit = (values, actions, closeModal) => {
    const { availabilities, ...rest } = values

    runMutation({
      availabilities: availabilities.map(availability => ({
        startsAt: availability.startsAt.toDate(),
        endsAt: availability.endsAt.toDate(),
        weekday: availability.weekday,
      })),
      ...rest,
    })
      .then(
        response => {
          const { errorMessages } = response.courseSectionCreateAvailabilities
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-sm btn-info"
      buttonText="Add Availability"
      modalTitle="Add Availability"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <AvailabilitiesInput />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createAvailabilitiesMutation = compress`
  mutation($courseSectionId: ID!, $availabilities: [AvailabilityInputObject!]!) {
    courseSectionCreateAvailabilities(courseSectionId: $courseSectionId, availabilities: $availabilities) {
      errorMessages
      courseSection {
        id
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

export default AddAvailability
