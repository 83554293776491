import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const OrganizationRequiredTutorTags = ({ organizationId, initialTags }) => {
  const [tags, setTags] = useState(initialTags)
  const { runMutation } = useMutation(buildMutation(setTagsMutation))

  return (
    <AutocompleteSelect
      api="/api/admins/tutors/autocomplete_tags"
      isMulti
      value={tags}
      onChange={selected => {
        const tagList = selected.map(t => t.label)
        runMutation({ organizationId, tagList })
          .then(
            response => {
              const { organization, errorMessages } =
                response.organizationSetTags
              if (errorMessages) {
                alert(errorMessages)
              } else {
                setTags(
                  organization.requiredTutorTags.map(tag => ({
                    value: tag.id,
                    label: tag.name,
                  }))
                )
              }
            },
            () => alert("Something went wrong")
          )
          .catch(() => alert("Something went wrong"))
      }}
    />
  )
}

const setTagsMutation = compress`
  mutation($organizationId: ID!, $tagList: [String!]!) {
    organizationSetTags(organizationId: $organizationId, tagList: $tagList) {
      organization {
        requiredTutorTags {
          id
          name
        }
      }
    }
  }
`

export default OrganizationRequiredTutorTags
