import React, { useEffect, useRef, useState } from "react"

import Select from "react-select"

import ordered from "src/ordered"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import RemoveButton from "components/RemoveButton"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type AdminStudentTeacherSubjectsProps = {
  studentId: string
}

type TeacherOptionType = {
  value: string
  label: string
}

type TeacherSubjectOptionType = {
  id: number
  subject: {
    id: number
    name: string
  }
}

const AdminStudentTeacherSubjects: React.FC<
  AdminStudentTeacherSubjectsProps
> = ({ studentId }) => {
  const client = useClient()
  const [teacher, setTeacher] = useState<TeacherOptionType>()
  const [teacherSubjects, setTeacherSubjects] =
    useState<TeacherSubjectOptionType[]>()
  const teacherSelect = useRef(null)
  const subjectSelect = useRef(null)

  const [result] = useQuery({
    query: studentTeacherSubjectsQuery,
    variables: { studentId },
  })

  useEffect(() => {
    if (!teacher) return

    client
      .query(teacherSubjectsQuery, { teacherId: teacher.value })
      .toPromise()
      .then(result => {
        setTeacherSubjects(result.data.teacher.teacherSubjects)
      })
  }, [client, teacher])

  const [, addTeacherSubject]: any[] = useMutation(addTeacherSubjectMutation)
  const [, removeTeacherSubject]: any[] = useMutation(
    removeTeacherSubjectMutation
  )

  return (
    <UrqlLoadingIndicator
      result={result}
      error="An error occurred while loading student classes"
      heading={<h4>Classes</h4>}
    >
      {({ data }) => (
        <div className="row">
          <div className="col-12">
            {ordered(
              ordered(data.student.teacherSubjects, "subject", "name"),
              "teacher",
              "lastName"
            ).map(teacherSubject => (
              <div key={teacherSubject.id} className="mb-3 flex items-center">
                <RemoveButton
                  onClick={() =>
                    removeTeacherSubject({
                      studentId,
                      teacherSubjectId: teacherSubject.id,
                    })
                  }
                />
                <span>
                  {teacherSubject.teacher.fullName}:{" "}
                  {teacherSubject.subject.name}
                </span>
              </div>
            ))}
            <div className="mb-3 flex flex-col sm:flex-row">
              <div className="mb-3 mr-3 w-[250px]">
                <AutocompleteSelect
                  ref={teacherSelect}
                  placeholder="Select Teacher"
                  api="/api/admins/teachers/autocomplete_full_name"
                  searchOptions={`organization_id=${data.student.organization.id}`}
                  onChange={selectedOption => {
                    setTeacher(selectedOption)
                    setTeacherSubjects(undefined)
                  }}
                  isClearable
                />
              </div>

              <div className="w-[250px]">
                {teacherSubjects && (
                  <Select
                    ref={subjectSelect}
                    placeholder="Select Subject"
                    options={ordered(teacherSubjects, "subject", "name")}
                    getOptionLabel={option => option.subject.name}
                    getOptionValue={option => option.id}
                    onChange={selectedOption => {
                      if (!selectedOption?.id) return
                      addTeacherSubject({
                        studentId,
                        teacherSubjectId: selectedOption.id,
                      })
                      setTimeout(() => {
                        teacherSelect.current &&
                          teacherSelect.current.clearValue()
                        subjectSelect.current &&
                          subjectSelect.current.select.clearValue()
                      }, 0)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </UrqlLoadingIndicator>
  )
}

const studentTeacherSubjectsQuery = gql`
  query ($studentId: ID!) {
    student(id: $studentId) {
      organization {
        id
      }
      teacherSubjects {
        id
        teacher {
          id
          fullName
          lastName
        }
        subject {
          id
          name
        }
      }
    }
  }
`

const teacherSubjectsQuery = gql`
  query ($teacherId: ID!) {
    teacher(id: $teacherId) {
      teacherSubjects {
        id
        subject {
          id
          name
        }
      }
    }
  }
`

const addTeacherSubjectMutation = gql`
  mutation ($studentId: ID!, $teacherSubjectId: ID!) {
    studentAddTeacherSubject(
      studentId: $studentId
      teacherSubjectId: $teacherSubjectId
    ) {
      student {
        id
      }
    }
  }
`

const removeTeacherSubjectMutation = gql`
  mutation ($studentId: ID!, $teacherSubjectId: ID!) {
    studentRemoveTeacherSubject(
      studentId: $studentId
      teacherSubjectId: $teacherSubjectId
    ) {
      student {
        id
      }
    }
  }
`

export default AdminStudentTeacherSubjects
