import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import { DateObject } from "components/Forms/DatePicker"
import {
  DateField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import MonthlySettings from "./MonthlySettings"
import WeeklySettings from "./WeeklySettings"

const ValidationSchema = Yup.object().shape({
  reportScheduleMode: Yup.string().required("Please select a schedule mode"),
  reportWeeklyFrequency: Yup.mixed().when("reportScheduleMode", {
    is: "weekly",
    then: Yup.number()
      .required("Please specify a weekly frequency")
      .min(1, "Weekly frequency must be at least 1"),
  }),
  reportWeekday: Yup.mixed().when("reportScheduleMode", {
    is: "weekly",
    then: Yup.string().required(
      "Please select a weekday for reports to be sent"
    ),
  }),
  reportDays: Yup.mixed().when("reportScheduleMode", {
    is: "monthly",
    then: Yup.array().min(
      1,
      "Please add at least 1 day for monthly reports to be sent"
    ),
  }),
})

const reportScheduleModes = [
  { label: "Disabled", value: "disabled" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
]

const AutomatedReports = ({ organization }) => {
  const formState = {
    organizationId: organization.id,
    reportScheduleMode: organization.reportScheduleMode,
    reportWeeklyFrequency: organization.reportWeeklyFrequency || 1,
    reportWeekday: organization.reportWeekday,
    reportDays: organization.reportDays,
    reportContactEmail: organization.reportContactEmail || "",
    reportLastLoginAt: organization.reportLastLoginAt
      ? new DateObject(new Date(organization.reportLastLoginAt))
      : null,
  }

  const { runMutation } = useMutation(buildMutation(updateMutation))

  const handleSubmit = (values, actions) => {
    const { reportLastLoginAt, ...variables } = values
    if (reportLastLoginAt) {
      variables.reportLastLoginAt = moment(reportLastLoginAt.toDate())
        .startOf("day")
        .format()
    }

    runMutation(variables)
      .then(
        response => {
          const { errorMessages } = response.organizationUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Automated Report Frequency"
      buttonText="Automated Report Configuration"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <SelectField
                name="reportScheduleMode"
                label="Schedule Mode"
                options={reportScheduleModes}
                defaultValue={reportScheduleModes.find(
                  mode => mode.value === organization.reportScheduleMode
                )}
                menuPortalTarget={document.body}
              />
              <WeeklySettings />
              <MonthlySettings />
              <DateField
                name="reportLastLoginAt"
                label="Last Login Start Date"
              />
              <Field
                name="reportContactEmail"
                label="Organization's Report Contact Email"
              />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="d-flex justify-content-between w-full">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateMutation = compress`
  mutation(
    $organizationId: ID!
    $reportScheduleMode: ReportScheduleModeEnum
    $reportWeeklyFrequency: Int
    $reportWeekday: WeekdayEnum
    $reportDays: [Int!]
    $reportContactEmail: String
    $reportLastLoginAt: DateTime
  ) {
    organizationUpdate(
      organizationId: $organizationId
      reportScheduleMode: $reportScheduleMode
      reportWeeklyFrequency: $reportWeeklyFrequency
      reportWeekday: $reportWeekday
      reportDays: $reportDays
      reportContactEmail: $reportContactEmail
      reportLastLoginAt: $reportLastLoginAt
    ) {
      errorMessages
      organization {
        id
        reportScheduleMode
        reportWeeklyFrequency
        reportWeekday
        reportDays
        reportContactEmail
      }
    }
  }
`

export default AutomatedReports
