import React from "react"

import { Field, Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/core"

const ValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Please enter your current password"),
  password: Yup.string().required("Please enter a new password"),
  passwordConfirmation: Yup.string().required(
    "Please confirm your new password"
  ),
})

const EditPasswordForm = props => {
  const toast = useToast()
  const history = useHistory()
  const formState = {
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  }
  const [, runMutation] = useMutation(updatePasswordMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          actions.setSubmitting(false)
          if (result.error) {
            toast({
              title: "Error",
              description:
                "An error occurred while changing your password. Please resolve any validation errors and try again.",
              status: "error",
              variant: "solid",
              duration: null,
              isClosable: true,
              position: "top",
            })
          } else if (result.data.updatePassword.errors) {
            actions.setStatus(
              "Password change failed. Please correct validation errors and resubmit."
            )
            response.updatePassword.errors.forEach(error => {
              actions.setFieldError(error.field, error.messages.join(", "))
            })
          } else {
            toast({
              title: "Password Updated",
              status: "success",
              variant: "solid",
              duration: 10000,
              isClosable: true,
              position: "top",
            })
            history.push("/")
          }
        },
        err => {
          actions.setSubmitting(false)
          toast({
            title: "Error",
            description:
              "An error occurred while updating your password. Please resolve any validation errors and try again.",
            status: "error",
            variant: "solid",
            duration: null,
            isClosable: true,
            position: "top",
          })
          throw err
        }
      )
      .catch(err => {
        actions.setSubmitting(false)
        toast({
          title: "Error",
          description:
            "An error occurred while updating your password. Please resolve any validation errors and try again.",
          status: "error",
          variant: "solid",
          duration: null,
          isClosable: true,
          position: "top",
        })
        throw err
      })
  }

  return (
    <>
      <Heading fontSize="xl">Edit Password</Heading>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <Field name="currentPassword">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    !!form.touched[field.name] && !!form.errors[field.name]
                  }
                  mt="4"
                >
                  <FormLabel htmlFor={field.name}>Current Password</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input
                      type="password"
                      id={field.name}
                      {...field}
                      flexBasis="33%"
                    />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="password">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    !!form.touched[field.name] && !!form.errors[field.name]
                  }
                  mt="4"
                >
                  <FormLabel htmlFor={field.name}>New Password</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input
                      type="password"
                      id={field.name}
                      {...field}
                      flexBasis="33%"
                    />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="passwordConfirmation">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    !!form.touched[field.name] && !!form.errors[field.name]
                  }
                  mt="4"
                >
                  <FormLabel htmlFor={field.name}>
                    Confirm New Password
                  </FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input
                      type="password"
                      id={field.name}
                      {...field}
                      flexBasis="33%"
                    />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex align="center" mt="4">
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Saving..."
                variant="solid"
                variantColor="gray"
              >
                Update Password
              </Button>
              {status && (
                <Box bg="red.500" color="white" p="4" ml="8" rounded="md">
                  {status}
                </Box>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

const updatePasswordMutation = gql`
  mutation UpdatePasswordMutation(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export default EditPasswordForm
