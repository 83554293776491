import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { red } from "styles/colors"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import { css } from "@emotion/core"

import ordered from "src/ordered"
import titlecase from "src/titlecase"
import { tutorClient as client } from "src/urql-client"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"
import LocalTime from "components/LocalTime"

const StudyGroup = ({ studyGroup, invitationId }) => {
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState()
  const [info, setInfo] = useState()
  const [inFlight, setInFlight] = useState(false)

  const register = () => {
    setInFlight(true)
    const { unsubscribe } = pipe(
      client.subscription(registerSubscription, {
        studyGroupId: studyGroup.id,
        studyGroupTutorInvitationId: invitationId,
      }),
      subscribe(response => {
        const { results, studyGroup } =
          response.data.studyGroupInvitationRegister

        const status = results[0].status
        setErrors(results.filter(result => !result.success))

        if (status === "failure" || status === "success") {
          setInFlight(false)
          unsubscribe()
          setStatus()
        } else {
          setStatus(titlecase(status))
        }

        if (status === "success") {
          NotificationManager.info(
            `${studyGroup.name} registration completed successfully`
          )
          setInfo(
            <>
              Registration successful!{" "}
              <a href={studyGroup.showPath}>
                Click here to view your new group
              </a>
            </>
          )
        }
      })
    )
  }

  return (
    <>
      <dl>
        <dt>Name</dt>
        <dd>{studyGroup.name}</dd>
        <dt>Details</dt>
        <dd dangerouslySetInnerHTML={{ __html: studyGroup.formattedDetails }} />

        {studyGroup.subjects.map(subject => (
          <React.Fragment key={subject.id}>
            <dt>Subject</dt>
            <dd>{subject.name}</dd>
          </React.Fragment>
        ))}

        {studyGroup.foreignLanguage && (
          <>
            <dt>Language</dt>
            <dd>{studyGroup.foreignLanguage.name}</dd>
          </>
        )}
      </dl>

      {studyGroup.studyGroupGeneration.startsOn && (
        <div
          css={css`
            font-weight: 500;
            color: ${red};
          `}
          className="mb-3"
        >
          <span
            css={css`
              margin-right: 15px;
            `}
          >
            Session Dates
          </span>
          <div>
            <LocalTime
              timestamp={studyGroup.studyGroupGeneration.startsOn}
              omitTime
            />{" "}
            &ndash;{" "}
            <LocalTime
              timestamp={studyGroup.studyGroupGeneration.endsOn}
              omitTime
            />
          </div>
        </div>
      )}

      <div
        css={css`
          font-weight: 500;
          color: ${red};
        `}
      >
        <span
          css={css`
            margin-right: 15px;
          `}
        >
          Localized Session Times:
        </span>
        <ul className="m-0">
          {ordered(studyGroup.availabilities, "weekdayNumber").map(
            availability => (
              <li key={availability.id}>
                {availability.weekday}{" "}
                <LocalTime timestamp={availability.startsAt} format="h:mm A" />
                <span> &ndash; </span>
                <LocalTime timestamp={availability.endsAt} format="h:mm A z" />
              </li>
            )
          )}
        </ul>

        <div className="my-3">
          {errors.map((error, index) => (
            <div key={index}>
              <div className="d-inline-block alert mb-3 p-5">
                {error.message}
                {error.conflictingSessions && (
                  <ul>
                    {error.conflictingSessions.map(session => (
                      <li key={session.id}>
                        <a
                          href={session.showPath}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <LocalTime timestamp={session.startsAt} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}

          {(studyGroup.maxSubsEstimate || studyGroup.maxSubsEstimate === 0) && (
            <ConsistentTutorWarning
              minTutorConsistencyRate={studyGroup.minTutorConsistencyRate}
              maxSubsEstimate={studyGroup.maxSubsEstimate}
            />
          )}

          <div className="flex items-center">
            {info ? (
              <div className="alert inline-block min-w-[20%] bg-emerald-500 p-3 text-left text-white">
                {info}
              </div>
            ) : (
              <>
                <button
                  className="btn solid blue"
                  type="button"
                  disabled={inFlight}
                  onClick={register}
                >
                  Register
                </button>

                {status && (
                  <div className="alert ml-5 min-w-[20%] bg-slate-500/60 p-2 text-white">
                    {status}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

const registerSubscription = gql`
  subscription ($studyGroupId: ID!, $studyGroupTutorInvitationId: ID!) {
    studyGroupInvitationRegister(
      studyGroupId: $studyGroupId
      studyGroupTutorInvitationId: $studyGroupTutorInvitationId
    ) {
      results {
        success
        status
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
      studyGroup {
        id
        name
        showPath
      }
    }
  }
`

export default StudyGroup
