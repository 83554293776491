import React, { ReactNode } from "react"

import { LayoutDirection } from "~tailwindui/types/enums"

type ChipListProps = {
  heading?: string | ReactNode
  layoutDirection?: LayoutDirection
  children: ReactNode
}

const ChipList: React.FC<ChipListProps> = ({
  heading,
  layoutDirection = LayoutDirection.Horizontal,
  children,
}) => {
  return (
    <div>
      <h3 className="ml-3 mt-2 text-base font-semibold leading-6 text-gray-900">
        {heading}
      </h3>
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {children}
      </dl>
    </div>
  )
}

export default ChipList
export type { ChipListProps }
