import React from "react"

import { compress } from "micro-graphql-react"

import Users from "./Users"

const Tutor = ({ tutor, studyGroupId }) => {
  return (
    <Users
      users={[tutor]}
      studyGroupId={studyGroupId}
      addMutation={addTutorMutation}
      removeMutation={removeTutorMutation}
      title="Tutor"
      autocompleteProps={{
        api: "/api/admins/tutors/autocomplete_full_name",
        searchOptions: `published=true`,
        placeholder: "Select Tutor",
      }}
    />
  )
}

const addTutorMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupAddUser(studyGroupId: $studyGroupId, userId: $userId, userType: Tutor) {
      studyGroup {
        tutor {
          id
          fullName
          showPath
        }
      }
    }
  }
`

const removeTutorMutation = compress`
  mutation($studyGroupId: ID!, $userId: ID!) {
    studyGroupRemoveUser(studyGroupId: $studyGroupId, userId: $userId, userType: Tutor) {
      studyGroup {
        tutor {
          id
          fullName
          showPath
        }
      }
    }
  }
`

export default Tutor
