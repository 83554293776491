import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const DurationUpdateRequests = () => {
  const loadingState = useQuery(buildQuery(sessionsQuery))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) =>
        data.airtutorsSessions.data.length === 0 ? (
          <p className="lead">No requests found</p>
        ) : (
          <table className="table">
            <thead className="text-primary">
              <tr>
                <th>Tutor</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.airtutorsSessions.data.map(airtutorsSession => (
                <tr key={airtutorsSession.id}>
                  <td>
                    <a href={airtutorsSession.tutor.showPath}>
                      {airtutorsSession.tutor.fullName}
                    </a>
                  </td>
                  <td>
                    <LocalTime timestamp={airtutorsSession.startsAt} omitTime />
                  </td>
                  <td>
                    <a
                      href={airtutorsSession.changeDurationPath}
                      className="btn btn-sm btn-info"
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      }
    </WithLoadingIndicator>
  )
}

const sessionsQuery = compress`
  query {
    airtutorsSessions(searchInput: { durationChangeStates: [approval_needed] }) {
      data {
        id
        startsAt
        changeDurationPath
        tutor {
          fullName
          showPath
        }
      }
    }
  }
`

export default DurationUpdateRequests
