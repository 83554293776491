import React from "react"

import { Field, FieldArray, useField } from "formik"

import EditableList from "components/EditableList"
import RemoveButton from "components/RemoveButton"

const Certificates = () => {
  const [certificatesField] = useField("certificates")

  return (
    <div className="mb-4">
      <h3>Certificates</h3>
      <FieldArray name="certificates">
        {arrayHelpers => (
          <>
            <EditableList>
              {certificatesField.value.map((certificateName, index) => (
                <li key={index}>
                  <RemoveButton onClick={() => arrayHelpers.remove(index)} />
                  <Field
                    name={`certificates[${index}]`}
                    className="d-inline-block"
                  />
                </li>
              ))}
            </EditableList>
            <button
              type="button"
              className="btn solid blue"
              onClick={() => arrayHelpers.push("")}
            >
              Add Certificate
            </button>
          </>
        )}
      </FieldArray>
    </div>
  )
}

export default Certificates
