import React, { useRef } from "react"

import { Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation, useQuery } from "hooks/urql"

import { handleFailure } from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import StatementConfigurationForm from "./Form"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  invoiceNumberPrefix: Yup.string().when("scheduledGeneration", {
    is: true,
    then: Yup.string().required("Prefix is required"),
  }),
  generationFrequency: Yup.string().when("scheduledGeneration", {
    is: true,
    then: Yup.string().required("Generation frequency is required"),
  }),
  startDate: Yup.string().when("scheduledGeneration", {
    is: true,
    then: Yup.string().required("Start Date is required"),
  }),
  budgetId: Yup.string().required("Budget is required"),
})

const NewStatementConfiguration = ({ organizationId }) => {
  const formState = {
    name: "",
    organizationId,
    scheduledGeneration: true,
    startDate: "",
    generationFrequency: "",
    invoiceNumberPrefix: "",
    miscellaneousContent: "",
    includeAllGroups: true,
    schoolIds: [],
    includeSchoollessGroups: false,
    filterByTags: false,
    studyGroupTags: [],
    useAutogeneratedBreakdowns: false,
    autogeneratesBreakdownsByCategory: "",
    subjectGroupingsAttributes: [],
  }

  const [result] = useQuery({
    query: organizationQuery,
    variables: { organizationId },
  })
  const [, createStatementConfiguration] = useMutation(createMutation)
  const budgetRef = useRef()

  const handleSubmit = (values, actions) => {
    const { useAutogeneratedBreakdowns, startDate, filterByTags, ...input } =
      values

    if (useAutogeneratedBreakdowns) {
      input.subjectGroupingsAttributes = input.subjectGroupingsAttributes.map(
        grouping => ({
          id: grouping.id,
          label: grouping.label,
          subjectIds: grouping.subjects.map(sub => sub.id),
        })
      )
    } else {
      delete input.autogeneratesBreakdownsByCategory
      delete input.subjectGroupingsAttributes
    }

    if (input.scheduledGeneration) {
      input.startDate = startDate.toDate().toString()
    }

    createStatementConfiguration({ input })
      .then(
        result => {
          const { statementConfiguration, results } =
            result.data.createStatementConfiguration
          if (results) {
            handleFailure(actions, results)
          } else {
            window.location = statementConfiguration.showPath
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { organization } }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <StatementConfigurationForm
            formType="new"
            statementConfiguration={undefined}
            organization={organization}
            budgetRef={budgetRef}
          />
        </Formik>
      )}
    </UrqlLoadingIndicator>
  )
}

const organizationQuery = gql`
  query ($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      slug
      schools {
        id
        name
      }
      budgets: activeBudgets(per: 100) {
        data {
          id
          name
          poNumber
          active
          formattedOriginalAmount
          formattedBalance
          startsOn
          endsOn
        }
      }
    }
  }
`

const createMutation = gql`
  mutation ($input: StatementConfigurationInputObject!) {
    createStatementConfiguration(input: $input) {
      results {
        message
      }
      statementConfiguration {
        id
        showPath
      }
    }
  }
`

export default NewStatementConfiguration
