import React, { ReactNode } from "react"

import { LayoutDirection } from "~tailwindui/types/enums"

import classNames from "src/classNames"

type ChipListProps = {
  heading: string
  layoutDirection?: LayoutDirection
  children: ReactNode
}

const ChipList: React.FC<ChipListProps> = ({
  heading,
  layoutDirection = LayoutDirection.Horizontal,
  children,
}) => {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {heading}
      </h3>

      <dl
        className={classNames(
          layoutDirection === "horizontal" &&
            "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
          layoutDirection === "vertical" && "grid-rows-1",
          "mt-5 grid gap-5"
        )}
      >
        {children}
      </dl>
    </div>
  )
}

export default ChipList
export type { ChipListProps }
