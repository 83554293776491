import React, { useEffect, useState } from "react"

import { gql } from "urql"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const EditTeacher = ({ teacherId }) => {
  const [result] = useQuery({ query: teacherQuery, variables: { teacherId } })
  const [formState, setFormState] = useState()

  useEffect(() => {
    if (!result.data) return
    const { __typename, organization, ...teacher } = result.data.teacher
    setFormState({
      ...teacher,
      organizationId: organization.id,
    })
  }, [result.data])

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Form formState={formState} organization={data.teacher.organization} />
      )}
    </UrqlLoadingIndicator>
  )
}

const teacherQuery = gql`
  query ($teacherId: ID!) {
    teacher(id: $teacherId) {
      id
      firstName
      lastName
      email
      phoneNumber
      hasRealtimeboardLicense
      teacherDigestsEnabled
      organization {
        id
        name
      }
      avatar {
        thumbUrl
      }
    }
  }
`

export default EditTeacher
