import React from "react"

import { findGradeOption, gradeOptionsFromTiers } from "src/grade-options"

import SelectField from "./SelectField"

const GradeField = ({ tiers, defaultGradeId, ...props }) => {
  const gradeOptions = gradeOptionsFromTiers(tiers)
  const defaultValue = findGradeOption(gradeOptions, defaultGradeId)

  return (
    <SelectField
      options={gradeOptions}
      defaultValue={defaultValue}
      {...props}
    />
  )
}

export default GradeField
