import React, { useRef } from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Modal from "components/Modal"

import "react-table/react-table.css"

const AdminTeachersTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/teachers",
    defaultFilters: props.defaultFilters,
  })
  const teacherFullNameSelect = useRef(null)
  const teacherEmailSelect = useRef(null)
  const clearableSelects = [
    teacherFullNameSelect.current,
    teacherEmailSelect.current,
  ]

  let columns = [
    {
      id: "full_name",
      Header: "Name",
      accessor: "full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={teacherFullNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api={"/api/admins/teachers/autocomplete_full_name"}
            searchOptions={
              props.defaultFilters?.organization &&
              `organization_id=${props.defaultFilters.organization}`
            }
          />
        )
      },
    },
    {
      id: "email",
      Header: "Email",
      accessor: "email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={teacherEmailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api={"/api/admins/teachers/autocomplete_email"}
            searchOptions={
              props.defaultFilters?.organization &&
              `organization_id=${props.defaultFilters.organization}`
            }
          />
        )
      },
    },
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization",
      Cell: props => <a href={props.value.show_path}>{props.value.name}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api={"/api/admins/organizations/autocomplete_name"}
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-between">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <a
            href={props.value.edit_path}
            className="btn btn-sm btn-outline-dark fa fa-pencil"
          >
            <span className="hidden">Edit</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure? This will remove ${props.row["full_name"]}</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove them!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep them for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ]

  if (props.hideOrganizationName)
    columns = columns.filter(c => c.id !== "organization")

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminTeachersTable
