import React, { useState } from "react"

import { NotificationContainer } from "react-notifications"

import { gql, useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import EditQuestion from "./EditQuestion"
import NewQuestion from "./NewQuestion"
import RemoveQuestion from "./RemoveQuestion"

import "react-notifications/lib/notifications.css"

type SurveyQuestionIndexProps = {
  surveysPath: string
}

const SurveyQuestionIndex: React.FC<SurveyQuestionIndexProps> = ({
  surveysPath,
}) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: surveyQuestionsQuery,
    variables: { page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <NotificationContainer />
          <NewQuestion />
          <a href={surveysPath} className="btn btn-info">
            View Surveys
          </a>
          {data.surveyQuestions.data.map(question => (
            <div key={question.id}>
              <p>{question.text}</p>
              <div>
                <EditQuestion question={question} />
                <RemoveQuestion question={question} />
              </div>
            </div>
          ))}
          <Pagination
            currentPage={page}
            changePage={setPage}
            totalPages={data.surveyQuestions.totalPages}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const surveyQuestionsQuery = gql`
  query SurveyQuestions($page: Int) {
    surveyQuestions(page: $page) {
      currentPage
      totalPages
      data {
        id
        text
      }
    }
  }
`

export default SurveyQuestionIndex
