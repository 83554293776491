import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { tutorClient } from "src/graphql-config"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import ConfirmSessionButton from "./ConfirmSessionButton"
import DeclineSessionButton from "./DeclineSessionButton"

const RequestedSessions = props => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(requestedSessionsQuery, { page: page }, { client: tutorClient })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading your requested sessions"
      heading={<h2>Requested Sessions</h2>}
    >
      {({ data }) =>
        data.requestedSessions.data.length === 0 ? (
          "No requested sessions"
        ) : (
          <React.Fragment>
            <table>
              <thead>
                <tr>
                  <th>Study Group</th>
                  <th>Subject</th>
                  <th>Students</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.requestedSessions.data.map(session => (
                  <tr key={session.id}>
                    <td>{session.studyGroup?.name}</td>
                    <td>{session.subject.name}</td>
                    <td>
                      <ul>
                        {session.students.map(student => (
                          <li key={student.id}>
                            <a href={student.showPath}>{student.fullName}</a>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <LocalTime
                        format="ddd MMM D"
                        timestamp={session.startsAt}
                      />
                    </td>
                    <td>
                      <LocalTime omitDate timestamp={session.startsAt} />
                      &mdash;
                      <LocalTime omitDate timestamp={session.endsAt} />
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: session.formattedSubstituteNotes,
                        }}
                      />
                    </td>
                    <td className="button-cell">
                      <ConfirmSessionButton id={session.id} />
                      <DeclineSessionButton id={session.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={page}
              totalPages={data.requestedSessions.totalPages}
              changePage={page => setPage(page)}
            />
          </React.Fragment>
        )
      }
    </WithLoadingIndicator>
  )
}

const requestedSessionsQuery = compress`
  query($page: Int!) {
    requestedSessions(page: $page) {
      totalPages
      data {
        id
        startsAt
        endsAt
        formattedStatusState
        formattedSubstituteNotes
        studyGroup {
          id
          name
        }
        students {
          id
          fullName
          showPath
        }
        subject {
          id
          name
        }
      }
    }
  }
`

export default RequestedSessions
