import React from "react"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

import Register from "./Register"

const Courses = ({ courseCluster, student }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {ordered(courseCluster.courses, "durationWeeks")
        .reverse()
        .map(course => (
          <div key={course.id}>
            <h3
              css={css`
                font-weight: 500;
              `}
            >
              {course.durationWeeks} Week Program
            </h3>
            <h4
              css={css`
                font-style: italic;
              `}
            >
              Meets {course.sessionsPerWeek} times per week
            </h4>
            <h4>Select a program time below</h4>
            <hr />

            {course.courseSections.map(section => (
              <div key={section.id}>
                <div>
                  <LocalTime timestamp={section.startsOn} omitTime /> &mdash;{" "}
                  <LocalTime timestamp={section.endsOn} omitTime />
                </div>
                {section.availabilities.map(availability => (
                  <div key={availability.id}>
                    {availability.weekday}s
                    <div
                      css={css`
                        font-style: italic;
                      `}
                    >
                      <LocalTime timestamp={availability.startsAt} omitDate />{" "}
                      to <LocalTime timestamp={availability.endsAt} omitDate />{" "}
                      ({availability.duration})
                    </div>
                  </div>
                ))}
                <div className="mt-3">
                  <Register
                    courseSection={section}
                    student={student}
                    courseClusterName={courseCluster.name}
                  />
                </div>
                <hr />
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}

export default Courses
