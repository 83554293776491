import React, { useEffect, useState } from "react"

import { FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { studentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { RadioGroup, SubmitButton } from "components/Forms/Formik"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const EditStudentSubjects = () => {
  const loadingState = useQuery(buildQuery(subjectsQuery, {}, { client }))
  const { runMutation } = useMutation(
    buildMutation(updateSubjectsMutation, { client })
  )

  const [formState, setFormState] = useState({ subjectIds: [] })

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.subjectsUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location = "/"
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  useEffect(() => {
    if (!loadingState.data) return
    setFormState({ subjectIds: loadingState.data.subjects.map(s => s.id) })
  }, [loadingState.data, loadingState.loaded])

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ status, isSubmitting, values }) => (
            <Form>
              <FieldArray name="subjectIds">
                {arrayHelpers =>
                  ordered(data.subjectBuckets, "name").map(subjectBucket => (
                    <React.Fragment key={subjectBucket.id}>
                      <h3>{subjectBucket.name}</h3>
                      <RadioGroup
                        type="checkbox"
                        name="subjectIds"
                        values={ordered(subjectBucket.subjects, "name").map(
                          subject => ({
                            value: subject.id,
                            label: subject.name,
                            checked: values.subjectIds.includes(subject.id),
                          })
                        )}
                        onChange={e => {
                          if (e.currentTarget.checked) {
                            arrayHelpers.push(e.currentTarget.value)
                          } else {
                            arrayHelpers.remove(
                              values.subjectIds.findIndex(
                                id =>
                                  parseInt(id) ===
                                  parseInt(e.currentTarget.value)
                              )
                            )
                          }
                        }}
                      />
                      <hr />
                    </React.Fragment>
                  ))
                }
              </FieldArray>

              {status && <div className="alert">{status}</div>}

              <SubmitButton isSubmitting={isSubmitting} text="Save" />
            </Form>
          )}
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

const subjectsQuery = compress`
  query {
    subjectBuckets {
      id
      name
      subjects {
        id
        name
      }
    }
    subjects {
      id
    }
  }
`

const updateSubjectsMutation = compress`
  mutation($subjectIds: [ID!]!) {
    subjectsUpdate(subjectIds: $subjectIds) {
      errorMessages
    }
  }
`

export default EditStudentSubjects
