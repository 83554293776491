import React from "react"

import { NotificationContainer } from "react-notifications"

import { gql, useClient, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import StudentLayout from "./StudentLayout"
import TutorLayout from "./TutorLayout"

import "react-notifications/lib/notifications.css"

export type ChimeCloudroomProps = {
  sessionId: number
  ghost?: boolean
}

const ChimeCloudroom: React.FC<ChimeCloudroomProps> = ({
  sessionId,
  ghost = false,
}) => {
  const [result] = useQuery({
    query,
    client: useClient(),
    variables: { id: sessionId, ghost },
  })

  return (
    <div className="min-h-screen overflow-auto bg-[#111] text-white">
      <NotificationContainer />
      <UrqlLoadingIndicator result={result}>
        {({ data: { viewer, airtutorsSession } }) =>
          airtutorsSession.chimeJoinInfo ? (
            <>
              {viewer.type === "Tutor" ? (
                <TutorLayout
                  session={airtutorsSession}
                  localUser={viewer}
                  ssoEnabled={viewer.ssoEnabled}
                />
              ) : (
                <StudentLayout
                  session={airtutorsSession}
                  localUser={viewer}
                  ghost={ghost}
                />
              )}
            </>
          ) : (
            <div className="mt-10 flex justify-center">
              {viewer.type === "Admin" && ghost
                ? "No users have joined this cloudroom yet. (This page will not auto-update -- you will need to refresh this page once users have joined)"
                : "You do not have access to this session."}
            </div>
          )
        }
      </UrqlLoadingIndicator>
    </div>
  )
}

const query = gql`
  query airtutorsSessionQuery($id: ID!, $ghost: Boolean!) {
    viewer {
      id
      type
      ssoEmail
      ssoEnabled
      fullName
    }
    airtutorsSession(id: $id) {
      id
      endsAt
      googledocUrl
      studyGroupPath
      studentPath
      lowBandwidthCloudroom
      studentScreenShareDisabled
      audioMutedUserIds
      videoMutedUserIds
      controlsLockedUserIds
      screenShareRejectedStudentIds
      realtimeboards {
        id
      }
      survey {
        id
      }
      chimeJoinInfo(ghost: $ghost) {
        attendee {
          attendeeId
          capabilities
          externalUserId
          joinToken
        }
        meeting
      }
    }
  }
`

export default ChimeCloudroom
