import React, { useEffect, useState } from "react"

import { css } from "@emotion/core"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import CourseCluster from "./CourseCluster"
import Courses from "./Courses"

const CourseClusters = ({
  academicCourseClusters,
  enrichmentCourseClusters,
  student,
  setHeading,
}) => {
  const [selectedCourseCluster, setSelectedCourseCluster] = useState()

  useEffect(() => {
    setHeading(selectedCourseCluster?.name)
  }, [selectedCourseCluster, setHeading])

  if (
    academicCourseClusters.length === 0 &&
    enrichmentCourseClusters.length === 0
  ) {
    return "No programs available at this time"
  }

  if (selectedCourseCluster) {
    return (
      <>
        <button
          onClick={() => setSelectedCourseCluster()}
          className="btn solid small"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
          Back to program listing
        </button>
        <Courses courseCluster={selectedCourseCluster} student={student} />
      </>
    )
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 768px) {
          flex-flow: column;
        }
        > div {
          flex-basis: 50%;
          padding: 0 25px;
        }
      `}
    >
      {academicCourseClusters.length === 0 ? null : (
        <div>
          <h2
            css={css`
              font-weight: 500;
            `}
          >
            Academic Programs
          </h2>
          {ordered(
            ordered(academicCourseClusters, "name"),
            "subject",
            "name"
          ).map(courseCluster => (
            <CourseCluster
              key={courseCluster.id}
              courseCluster={courseCluster}
              setSelectedCourseCluster={setSelectedCourseCluster}
            />
          ))}
        </div>
      )}

      {enrichmentCourseClusters.length === 0 ? null : (
        <div>
          <h2
            css={css`
              font-weight: 500;
            `}
          >
            Enrichment Programs
          </h2>
          {ordered(
            ordered(enrichmentCourseClusters, "name"),
            "subject",
            "name"
          ).map(courseCluster => (
            <CourseCluster
              key={courseCluster.id}
              courseCluster={courseCluster}
              setSelectedCourseCluster={setSelectedCourseCluster}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CourseClusters
