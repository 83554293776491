import { compress } from "micro-graphql-react"

export default compress`
  mutation($id: ID!) {
    removeSemester(id: $id) {
      organization {
        semesters {
          id
          name
          startsOn
          endsOn
        }
      }
    }
  }
`
