import React from "react"

const Heading = ({ children, className, ...props }) => {
  const classNames = `mt-0 ${className}`
  return (
    <h2 className={classNames} {...props}>
      {children}
    </h2>
  )
}

const FormGroup = ({ children, className, ...props }) => {
  const classNames = `mb-3 ${className}`
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  )
}

export { FormGroup, Heading }
