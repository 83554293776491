import React from "react"

import { css } from "@emotion/core"

const Instructions = () => (
  <>
    <p>
      Since its been a while since we last saw you, I bet some things have
      changed!
    </p>

    <p>
      Lets get your profile updated so we can be sure to meet all your needs.
    </p>

    <p className="pt-3 italic">
      <span>Para ayuda en español por favor nos llama al numero </span>
      <span
        css={css`
          white-space: nowrap;
        `}
      >
        (800) 211-1986
      </span>
    </p>
  </>
)

Instructions.modalTitle = "Welcome Back!"
Instructions.step = "Instructions"
export default Instructions
