import React, { useEffect, useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import emptyFunction from "src/emptyFunction"
import { studentClient as client } from "src/graphql-config"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import AvailabilityForm from "./AvailabilityForm"
import Done from "./Done"
import GradeForm from "./GradeForm"
import Instructions from "./Instructions"
import PhoneNumberForm from "./PhoneNumberForm"
import SchoolForm from "./SchoolForm"
import SubjectsForm from "./SubjectsForm"
import TeacherSubjectsForm from "./TeacherSubjectsForm"
import Wizard from "./Wizard"
import WizardContext from "./WizardContext"

const defaultSteps = [Instructions, GradeForm, SchoolForm, PhoneNumberForm]

const ReturningUserWizard = ({ gradeId }) => {
  const [open, setOpen] = useState(true)
  const loadingState = useQuery(
    buildQuery(returningUserQuery, { gradeId }, { client })
  )
  const [step, setStep] = useState(0)

  const [wizardSteps, setWizardSteps] = useState(defaultSteps)
  const [fieldToStepMap, setFieldToStepMap] = useState({})

  useEffect(() => {
    if (!loadingState.data) return

    const updatedSteps = [...defaultSteps]
    if (
      !loadingState.data.organization ||
      loadingState.data.organization.teachers
        .map(teacher => teacher.teacherSubjects)
        .flat().length === 0
    ) {
      updatedSteps.push(SubjectsForm)
    } else {
      updatedSteps.push(TeacherSubjectsForm)
    }

    if (loadingState.data.organization?.availabilitySets?.length > 0) {
      updatedSteps.push(AvailabilityForm)
    }

    updatedSteps.push(Done)
    setWizardSteps(updatedSteps)

    const updatedFieldToStepMap = {}
    updatedSteps.forEach((component, index) => {
      component.providedFields?.forEach(fieldName => {
        updatedFieldToStepMap[fieldName] = { index, component }
      })
    })
    setFieldToStepMap(updatedFieldToStepMap)
  }, [loadingState.data])

  return (
    <WizardContext.Provider
      value={{ setStep: setStep, fieldToStepMap: fieldToStepMap }}
    >
      <ModalWithProvidedBody
        isOpen={open}
        closeModal={() => setOpen(false)}
        onRequestClose={emptyFunction}
        modalTitle={wizardSteps[step]?.modalTitle || "Welcome Back"}
        modalClassName="bootstrap-modal"
        hideTrigger
        hideCloseButton
      >
        {({ closeModal }) => (
          <WithLoadingIndicator loadingState={loadingState}>
            {({ data }) => (
              <Wizard
                data={data}
                closeModal={closeModal}
                step={step}
                setStep={setStep}
                FormComponent={wizardSteps[step]}
              />
            )}
          </WithLoadingIndicator>
        )}
      </ModalWithProvidedBody>
    </WizardContext.Provider>
  )
}

const returningUserQuery = compress`
  fragment TeacherSubjectFields on TeacherSubject {
    id
    teacher {
      id
      fullName
      lastName
    }
    subject {
      id
      name
    }
  }
  query($gradeId: ID!) {
    viewer {
      phoneNumber
      parentPhoneNumber
      grade {
        id
      }
      school {
        id
      }
      teacherSubjects {
        ...TeacherSubjectFields
      }
      subjects {
        id
      }
      availabilities {
        id
      }
    }
    grades {
      id
      name
      number
    }
    subjectBuckets {
      id
      name
      subjects {
        id
        name
      }
    }
    organization {
      minimumAvailabilityWindows
      schools {
        id
        name
      }
      teachers {
        id
        fullName
        lastName
        teacherSubjects {
          ...TeacherSubjectFields
        }
      }
      availabilitySets(gradeId: $gradeId) {
        id
        availabilities {
          id
          startsAt
          endsAt
        }
      }
    }
  }
`

export default ReturningUserWizard
