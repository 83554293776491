const Logger = require("js-logger")
// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults()

if (process.env.NODE_ENV === "production") {
  if (process.env.JS_LOG_LEVEL) {
    Logger.setLevel(Logger[process.env.JS_LOG_LEVEL])
  } else {
    Logger.setLevel(Logger.OFF)
  }
}
