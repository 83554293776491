import React from "react"

import { motion } from "framer-motion"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import NewScore from "./NewScore"

const AssessmentScores = ({ id }) => {
  const loadingState = useQuery(
    buildQuery(
      scoresQuery,
      { id },
      {
        onMutation: [
          {
            when: "assessmentScoreCreate",
            run: ({ hardReset }, response) => {
              const { failures } = response.assessmentScoreCreate
              if (failures) return
              hardReset()
            },
          },
          {
            when: "assessmentScoreRemove",
            run: ({ softReset, currentResults }, response) => {
              const { assessmentScore } = response.assessmentScoreRemove
              if (!assessmentScore) return

              const assessment = currentResults.student.assessments.find(
                assessment => assessment.id === assessmentScore.assessment.id
              )
              const section = assessment.assessmentSections.find(
                section => section.id === assessmentScore.assessmentSection.id
              )
              section.assessmentScores.data =
                section.assessmentScores.data.filter(
                  score => score.id !== assessmentScore.id
                )
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h4>Assessment Scores</h4>}
    >
      {({ data }) => (
        <>
          <NewScore userId={id} />
          {ordered(data.student.assessments, "name").map(assessment => (
            <div key={assessment.id}>
              <h5>{assessment.name}</h5>
              {ordered(assessment.assessmentSections, "name")
                .filter(section => section.assessmentScores.data.length > 0)
                .map(section => (
                  <div
                    key={section.id}
                    className="mb-3 border-t-0 border-l-0 border-r-0 border-b-2 border-solid border-gray-200"
                  >
                    <h6>
                      {section.name} (Subject: {section.subject.name})
                    </h6>
                    <EditableList>
                      {ordered(section.assessmentScores.data, "takenOn")
                        .reverse()
                        .map(score => (
                          <motion.li key={score.id} positionTransition>
                            <Item
                              mutation={removeScoreMutation}
                              mutationProps={{ assessmentScoreId: score.id }}
                            >
                              <div key={score.id}>
                                <dl>
                                  <dt>Score</dt>
                                  <dd>{score.score}</dd>

                                  <dt>Taken On</dt>
                                  <dd>
                                    {score.takenOn ? (
                                      <LocalTime
                                        timestamp={score.takenOn}
                                        omitTime
                                      />
                                    ) : (
                                      "Unknown"
                                    )}
                                  </dd>
                                </dl>
                              </div>
                            </Item>
                          </motion.li>
                        ))}
                    </EditableList>
                  </div>
                ))}
            </div>
          ))}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const removeScoreMutation = compress`
  mutation($assessmentScoreId: ID!) {
    assessmentScoreRemove(assessmentScoreId: $assessmentScoreId) {
      failures {
        message
      }
      assessmentScore {
        id
        assessment {
          id
        }
        assessmentSection {
          id
        }
      }
    }
  }
`

const scoresQuery = compress`
  query($id: ID!) {
    student(id: $id) {
      assessments {
        id
        name
        assessmentSections {
          id
          name
          subject {
            id
            name
          }
          assessmentScores(pageSize: 100, userId: $id) {
            data {
              id
              score
              takenOn
            }
          }
        }
      }
    }
  }
`

export default AssessmentScores
