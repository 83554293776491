import React from "react"

import { gql } from "urql"

import { tutorClient as client } from "src/urql-client"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const EditExpense = ({ id }) => {
  const [result] = useQuery({ query: expenseQuery, variables: { id }, client })
  let formState
  if (result.data) {
    formState = { ...result.data.expense, receipts: [], receiptsOptional: true }
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => <Form formState={formState} tutor={result.data.viewer} />}
    </UrqlLoadingIndicator>
  )
}

const expenseQuery = gql`
  query ($id: ID!) {
    viewer {
      canSubmitGeneralExpenses
      hasSubmittedFingerprintExpense
      hasSubmittedIntroSessionExpense
    }
    expense(id: $id) {
      id
      expenseType
      amount
      description
      comments
    }
  }
`

export default EditExpense
