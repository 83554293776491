import React, { ReactNode } from "react"

import { FormikValues, useFormikContext } from "formik"

const DisplayOnly: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { values } = useFormikContext() as FormikValues
  return values.editing ? null : <>{children}</>
}

export default DisplayOnly
