import React from "react"

import { FieldArray, useField } from "formik"

import ordered from "src/ordered"

import { CheckboxField } from "components/Forms/Formik/hookComponents"

import AddTeacherSubject from "./AddTeacherSubject"

const TeacherSubjectsForm = ({ organization: { teachers } }) => {
  const [field] = useField({ name: "teacherSubjectIds" })

  const allTeacherSubjects = teachers
    .map(teacher => teacher.teacherSubjects)
    .flat()
  const selectedTeacherSubjects = allTeacherSubjects.filter(teacherSubject =>
    field.value.includes(teacherSubject.id)
  )

  return (
    <FieldArray name={field.name}>
      {arrayHelpers => (
        <>
          {ordered(
            ordered(selectedTeacherSubjects, "teacher", "lastName"),
            "subject",
            "name"
          ).map(teacherSubject => (
            <CheckboxField
              key={teacherSubject.id}
              name="teacherSubjectIds"
              label={`${teacherSubject.teacher.fullName}: ${teacherSubject.subject.name}`}
              value={teacherSubject.id}
            />
          ))}
          <AddTeacherSubject teachers={teachers} arrayHelpers={arrayHelpers} />
        </>
      )}
    </FieldArray>
  )
}

TeacherSubjectsForm.modalTitle = "Select Teachers + Courses"
TeacherSubjectsForm.displayName = "Teachers + Courses"
TeacherSubjectsForm.providedFields = ["teacherSubjectIds"]
TeacherSubjectsForm.step = "Teacher Subjects"
export default TeacherSubjectsForm
