import React from "react"

import { motion } from "framer-motion"

import ordered from "src/ordered"

import { gql } from "hooks/urql"

import CollapsibleContainer from "components/CollapsibleContainer"
import EditableList, { UrqlItem } from "components/EditableList"

import AddTutors from "./AddTutors"
import NotifyTutors from "./NotifyTutors"

type TutorsProps = {
  courseCluster: {
    id: string
    tutors: [{ id: string; fullName: string }]
    subject: { id: string }
  }
}

const Tutors: React.FC<TutorsProps> = ({ courseCluster }) => {
  return (
    <>
      <CollapsibleContainer
        title={<h2 className="m-0 text-xl">Tutors</h2>}
        childrenClassName="ml-3"
      >
        <ul className="list-unstyled">
          <EditableList>
            {ordered(courseCluster.tutors, "lastName").map(tutor => (
              <motion.li key={tutor.id} positionTransition>
                <UrqlItem
                  mutation={removeTutorMutation}
                  mutationProps={{
                    courseClusterId: courseCluster.id,
                    tutorId: tutor.id,
                  }}
                >
                  {tutor.fullName}
                </UrqlItem>
              </motion.li>
            ))}
          </EditableList>
        </ul>
      </CollapsibleContainer>
      <AddTutors courseCluster={courseCluster} />
      <NotifyTutors courseClusterId={courseCluster.id} />
    </>
  )
}

const removeTutorMutation = gql`
  mutation RemoveTutor($courseClusterId: ID!, $tutorId: ID!) {
    courseClusterRemoveTutor(
      courseClusterId: $courseClusterId
      tutorId: $tutorId
    ) {
      courseCluster {
        tutors {
          id
          fullName
          lastName
        }
      }
    }
  }
`

export default Tutors
