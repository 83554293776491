import React, { useEffect } from "react"

import dayjs, { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import DateRangeSelectButton from "./DateRangeSelectButton"

export type DataDateRangeProps = {
  startDate: Dayjs
  setStartDate: (date: Dayjs) => void
  endDate: Dayjs
  setEndDate: (date: Dayjs) => void
}

// when using this component, but sure to NOT nest it inside a WithLoadingIndicator component!!

const DataDateRange: React.FC<DataDateRangeProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [viewerResult] = useQuery({ query: viewerQuery })

  useEffect(() => {
    if (viewerResult?.data?.viewer.organization.activeSemester) {
      const { startsOn, endsOn } =
        viewerResult.data.viewer.organization.activeSemester
      setStartDate(dayjs(startsOn))
      setEndDate(dayjs(endsOn))
    }
  }, [viewerResult, setStartDate, setEndDate])

  const updateDateRange = dates => {
    setStartDate(dayjs(dates[0]).startOf("day"))
    setEndDate(dayjs(dates[1]).endOf("day"))
  }

  return (
    <div className="mb-6 flex items-center text-lg font-semibold leading-6 text-gray-900">
      Displaying data for {startDate.format("MMM D, YYYY")} -{" "}
      {endDate.format("MMM D, YYYY")}
      <div className="ml-3">
        <DateRangeSelectButton updateDateRange={updateDateRange} />
      </div>
    </div>
  )
}

const viewerQuery = gql`
  query viewer {
    viewer {
      organization {
        activeSemester {
          id
          startsOn
          endsOn
        }
      }
    }
  }
`

export default DataDateRange
