import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import PropTypes from "prop-types"
import * as Yup from "yup"

import { DateObject, DateRangePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"

import createSemesterMutation from "./createSemesterMutation"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  startsOn: Yup.number().required("Start Date is required"),
  endsOn: Yup.number().required("End Date is required"),
})

const NewOrganizationSemesterForm = props => {
  const { organizationId, cancel } = props

  const formState = {
    organizationId,
    name: "",
    startsOn: new DateObject(),
    endsOn: new DateObject(),
  }

  const { runMutation } = useMutation(buildMutation(createSemesterMutation))

  const handleSubmit = (values, actions) => {
    const { startsOn, endsOn, ...rest } = values
    const startMoment = moment(startsOn.toDate()).startOf("day")
    const endMoment = moment(endsOn.toDate()).endOf("day")

    runMutation({
      startsOn: startMoment.format(),
      endsOn: endMoment.format(),
      ...rest,
    }).then(response => {
      cancel()
    })
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ status, isSubmitting, setFieldValue, values }) => (
        <Form>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label w-100">
                  Name
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage name="name" />
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 cold-md-6 col-lg-4">
              <div className="form-group">
                <label className="form-label w-100">
                  Date Range
                  <DateRangePicker
                    onChange={([startDate, endDate]) => {
                      setFieldValue("startsOn", startDate)
                      setFieldValue("endsOn", endDate)
                    }}
                  />
                  <ErrorMessage name="startsOn" className="mr-3" />
                  <ErrorMessage name="endsOn" />
                </label>
              </div>
            </div>
          </div>

          {status && (
            <div>
              <div className="alert alert-danger d-inline-block">{status}</div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <SubmitButton isSubmitting={isSubmitting} />
              <div className="d-inline-block">
                <button
                  type="reset"
                  className="btn btn-danger"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

NewOrganizationSemesterForm.propTypes = {
  organizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  cancel: PropTypes.func.isRequired,
}

export default NewOrganizationSemesterForm
