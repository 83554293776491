import React from "react"

import { Field, FileField } from "components/Forms/Formik/hookComponents"

import Location from "./Location"

const PersonalInfo = () => {
  return (
    <>
      <h3>Personal Details</h3>
      <Field name="firstName" label="First Name" />
      <Field name="lastName" label="Last Name" />
      <Field
        name="phoneNumber"
        label="What is your phone number? Format: Area Code-XXX-XXXX (cell phones only please, no google numbers or false numbers)"
        type="tel"
      />
      <FileField name="resume" label="Resume" />
      <Location />
    </>
  )
}

export default PersonalInfo
