import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

import { publicClient } from "src/graphql-config"

import WithLoadingIndicator from "components/WithLoadingIndicator"

const SubjectBucketSelection = ({
  tierId,
  subjectBucketIds,
  tutorSubjects,
  arrayHelpers,
}) => {
  const loadingState = useQuery(
    buildQuery(loadSubjectBucketsQuery, { tierId }, { client: publicClient })
  )

  const approvalStateFor = subjectBucketId => {
    const tutorSubject = tutorSubjects.find(
      tutorSubject => tutorSubject.subjectBucket.id === subjectBucketId
    )
    if (!tutorSubject) return null
    return tutorSubject.approvalState
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="Unable to load subjects"
    >
      {({ data }) => (
        <div>
          <h3>{data.tier.gradeRange} Subjects</h3>
          <div
            css={css`
              max-height: 250px;
              overflow-y: scroll;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 6px;
            `}
          >
            {data.subjectBuckets.map(({ id, name }) => (
              <label key={id} style={{ display: "block" }}>
                <input
                  type="checkbox"
                  name={`subjectBucketIds.${id}`}
                  value={id}
                  checked={subjectBucketIds.includes(id)}
                  disabled={["approved", "rejected"].includes(
                    approvalStateFor(id)
                  )}
                  onChange={e => {
                    if (e.target.checked) {
                      arrayHelpers.push(id)
                    } else {
                      arrayHelpers.remove(subjectBucketIds.indexOf(id))
                    }
                  }}
                />
                {name}{" "}
                {approvalStateFor(id) && <span>({approvalStateFor(id)})</span>}
              </label>
            ))}
          </div>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const loadSubjectBucketsQuery = compress`
  query($tierId: ID!) {
    subjectBuckets(tierId: $tierId) {
      id
      name
    }
    tier(id: $tierId) {
      id
      gradeRange
    }
  }
`

SubjectBucketSelection.propTypes = {
  tierId: PropTypes.number.isRequired,
  subjectBucketIds: PropTypes.array.isRequired,
  tutorSubjects: PropTypes.array.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
}

export default SubjectBucketSelection
