import axios from "axios"
import moment from "moment-timezone"

const BuildURL = (apiEndpoint, state, defaultFilters, additionalProps) => {
  let sortArray = []
  state.sorted.forEach(sort => {
    if (sort.desc) {
      sortArray.push(`${sort.id}:desc`)
    } else {
      sortArray.push(`${sort.id}:asc`)
    }
  })

  let filterQuery = ""
  state.filtered.forEach(filter => {
    if (filter.value === undefined || filter.value === null) return null
    if (
      typeof filter.value === "object" &&
      !filter.value.hasOwnProperty("value")
    ) {
      Object.keys(filter.value).forEach(key => {
        let value = filter.value[key]
        if (moment.isMoment(value)) value = value.format()
        if (value) filterQuery += `&filter[${key}]=${value}`
      })
    } else {
      let value
      if (typeof filter.value === "object") {
        if (filter.value.hasOwnProperty("value")) {
          value = filter.value.value
        }
      } else {
        value = filter.value
      }
      filterQuery += `&filter[${filter.id}]=${value}`
    }
  })
  if (defaultFilters)
    Object.keys(defaultFilters).forEach(key => {
      if (defaultFilters[key])
        filterQuery += `&filter[${key}]=${defaultFilters[key]}`
    })
  filterQuery = encodeURI(filterQuery)

  let additionalQuery = ""
  if (additionalProps)
    Object.keys(additionalProps).forEach(key => {
      additionalQuery += `&${key}=${additionalProps[key]}`
    })

  let orderQuery = `&order[]=${sortArray.join("&order[]=")}`
  return `${apiEndpoint}?page=${state.page + 1}&per_page=${
    state.pageSize
  }${orderQuery}${filterQuery}${additionalQuery}`
}

const FetchTableData = (
  apiEndpoint,
  state,
  defaultFilters,
  additionalProps
) => {
  return axios.get(
    BuildURL(apiEndpoint, state, defaultFilters, additionalProps)
  )
}

export { BuildURL }
export default FetchTableData
