import React from "react"

import { NavLink } from "react-router-dom"

import { useTheme } from "@chakra-ui/core"

const AirtutorsNavLink = props => {
  const theme = useTheme()
  return (
    <NavLink
      activeStyle={{
        borderBottom: `2px solid ${theme.colors.brand}`,
        backgroundColor: theme.colors.brandShades[700],
        color: theme.colors.white,
      }}
      {...props}
    />
  )
}

export default AirtutorsNavLink
