import React from "react"

import { useField, useFormikContext } from "formik"

const RecipientsList = () => {
  const [recipientsField] = useField("recipients")
  const { setFieldValue } = useFormikContext()

  const removeRecipient = recipient => {
    setFieldValue(
      "recipients",
      recipientsField.value.filter(r => r !== recipient)
    )
  }

  return (
    <table className="table">
      <thead className="text-primary">
        <tr>
          <th>Phone Number</th>
          <th>User</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        {recipientsField.value.map(recipient => (
          <tr key={[recipient.user?.id, recipient.phoneNumber]}>
            <td>{recipient.phoneNumber}</td>
            <td>{recipient.user?.fullName}</td>
            <td>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => removeRecipient(recipient)}
              >
                Remove
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default RecipientsList
