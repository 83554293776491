import { compress } from "micro-graphql-react"

export default compress`
  mutation($input: StudentInputObject!) {
    saveStudent(input: $input) {
      success
      redirectTo
      errors {
        field
        messages
      }
      errorMessage
    }
  }
`
