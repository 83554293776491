import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Checkbox from "components/Forms/Checkbox"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import ChimeCloudRoomContainer from "./ChimeCloudRoomContainer"
import TwilioCloudRoomContainer from "./TwilioCloudRoomContainer"
import styles from "./styles.module.scss"

export type QualityControlDashboardProps = Record<string, never>

const QualityControlDashboard: React.FC<QualityControlDashboardProps> = () => {
  const [page, setPage] = useState(1)
  const [organization, setOrganization] = useState(null)
  const [school, setSchool] = useState(null)
  const [newTutorsOnly, setNewTutorsOnly] = useState(false)
  const debugString = new URLSearchParams(window.location.search).get("debug")
  const [result] = useQuery({
    query: sessionsQuery,
    variables: {
      page,
      debug: debugString === "true",
      organizationId: organization?.value,
      schoolId: school?.value,
      newTutorsOnly,
    },
  })

  const forcePlay = () => {
    document.querySelectorAll("video").forEach(video => video.play())
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <div className="m-6">
          <div className="grid grid-cols-2">
            <div>
              <button className="btn btn-sm btn-warn" onClick={forcePlay}>
                Force Play
              </button>
              <Pagination
                totalPages={data.airtutorsSessions.totalPages}
                currentPage={data.airtutorsSessions.currentPage}
                changePage={setPage}
              />
            </div>

            <div className="col-start-2 w-[600px] items-end justify-self-end">
              <div className="space-between h-100 grid grid-cols-3 items-center gap-2">
                <div className="mt-2 flex justify-end">
                  <Checkbox
                    name="newTutorsOnly"
                    checked={newTutorsOnly}
                    onChange={ev => {
                      setPage(1)
                      setNewTutorsOnly(ev.currentTarget.checked)
                    }}
                  >
                    New Tutors Only
                  </Checkbox>
                </div>

                <div className="flex justify-end">
                  <AutocompleteSelect
                    api="/api/admins/schools/autocomplete_name"
                    onChange={selected => {
                      setPage(1)
                      setSchool(selected)
                    }}
                    placeholder="Filter by School"
                    defaultValue={{
                      value: school?.value,
                      label: school?.label || "Filter by School",
                    }}
                    isClearable
                    className="grow"
                  />
                </div>

                <div className="flex justify-end">
                  <AutocompleteSelect
                    api="/api/admins/organizations/autocomplete_name"
                    onChange={selected => {
                      setPage(1)
                      setOrganization(selected)
                    }}
                    placeholder="Filter by Organization"
                    defaultValue={{
                      value: organization?.value,
                      label: organization?.label || "Filter by Organization",
                    }}
                    isClearable
                    className="grow"
                  />
                </div>
              </div>
            </div>
          </div>

          {data.airtutorsSessions.data.length === 0 ? (
            <h3 className="text-center">No active sessions</h3>
          ) : (
            <div
              className={`grid grow grid-flow-row grid-cols-4 gap-3 overflow-scroll ${styles.qcContainer}`}
            >
              {data.airtutorsSessions.data.map(session =>
                session.chimeCloudroomEnabled ? (
                  <ChimeCloudRoomContainer
                    key={session.id}
                    session={session}
                    localUser={data.viewer}
                  />
                ) : (
                  <TwilioCloudRoomContainer
                    key={session.id}
                    session={session}
                  />
                )
              )}
            </div>
          )}
        </div>
      )}
    </UrqlLoadingIndicator>
  )
}

const sessionsQuery = gql`
  query (
    $page: Int
    $debug: Boolean
    $organizationId: ID
    $schoolId: ID
    $newTutorsOnly: Boolean
  ) {
    viewer {
      id
      type
    }
    airtutorsSessions: inProgressSessions(
      page: $page
      organizationId: $organizationId
      schoolId: $schoolId
      newTutorsOnly: $newTutorsOnly
      debug: $debug
    ) {
      totalPages
      currentPage
      data {
        id
        chimeCloudroomEnabled
        showPath
        accessToken(qualityControl: true)
        tutor {
          id
          fullName
        }
        subject {
          id
          name
        }
        realtimeboards {
          id
        }
        googledocUrl
        chimeJoinInfo(ghost: true) {
          attendee {
            attendeeId
            capabilities
            externalUserId
            joinToken
          }
          meeting
        }
      }
    }
  }
`

export default QualityControlDashboard
