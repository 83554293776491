import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ScreenShareRequestedModal = ({
  isOpen,
  studentName,
  approveRequest,
  denyRequest,
}) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Screen Share Request"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            {studentName} has requested to share their screen
          </div>
          <div className="modal-footer">
            <button
              className="btn solid blue btn-info"
              onClick={approveRequest}
            >
              Allow
            </button>
            <button className="btn solid red btn-danger" onClick={denyRequest}>
              Reject
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default ScreenShareRequestedModal
