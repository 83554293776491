import React from "react"

import PropTypes from "prop-types"

import { Button, useTheme } from "@chakra-ui/core"

const RealtimeboardButton = ({
  viewer,
  url,
  ssoEnabled,
  displayName,
  ...props
}) => {
  const theme = useTheme()

  if (ssoEnabled) {
    return (
      <form
        method="post"
        action="https://miro.com/sso/api/process-email"
        target="_blank"
        key={props.id}
      >
        <Button
          type="submit"
          size="sm"
          mb="2"
          {...props}
          {...theme.buttonVariants.realtimeboard}
        >
          {displayName}
        </Button>
        <input name="email" type="hidden" value={viewer.ssoEmail} />
        <input name="openBoardId" type="hidden" value={props.id} />
      </form>
    )
  } else {
    return (
      <Button
        as="a"
        target="_blank"
        href={url}
        size="sm"
        mb="2"
        textAlign="center"
        key={url}
        {...props}
        {...theme.buttonVariants.realtimeboard}
      >
        {displayName}
      </Button>
    )
  }
}

RealtimeboardButton.propTypes = {
  url: PropTypes.string.isRequired,
  ssoEnabled: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
}

export default RealtimeboardButton
