import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { studentClient as client } from "src/graphql-config"

import CourseClusters from "components/CourseClusters"
import courseClusterFragment from "components/CourseClusters/fragment.js"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const StudentCourseClusters = () => {
  const [heading, setHeading] = useState()
  const loadingState = useQuery(buildQuery(courseClustersQuery, {}, { client }))

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={
        <h1 className="text-center">{heading || "Available Programs"}</h1>
      }
    >
      {({ data }) => <CourseClusters {...data} setHeading={setHeading} />}
    </WithLoadingIndicator>
  )
}

const courseClustersQuery = compress`
  ${courseClusterFragment}
  query {
    academicCourseClusters: organizationRegisterableCourseClusters(courseType: Academic) {
      ...CourseClusterFields
    }
    enrichmentCourseClusters: organizationRegisterableCourseClusters(courseType: Enrichment) {
      ...CourseClusterFields
    }
  }
`

export default StudentCourseClusters
