import React, { useState } from "react"

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import OneOnOneTutoring from "./OneOnOneTutoring"
import ProgramSelection from "./ProgramSelection"
import SmallClassTutoring from "./SmallClassTutoring"
import SmallGroupTutoring from "./SmallGroupTutoring"

const ProgramRegistration = ({ student, isParent }) => {
  const [programType, setProgramType] = useState()

  const reset = () => setProgramType()

  let TutoringComponent

  switch (programType) {
    case "1-1":
      TutoringComponent = (
        <OneOnOneTutoring tier={student.grade.tier} student={student} />
      )
      break
    case "smallGroup":
      TutoringComponent = <SmallGroupTutoring student={student} />
      break
    case "smallClass":
      TutoringComponent = <SmallClassTutoring student={student} />
      break
    default:
      return (
        <ProgramSelection
          setProgramType={setProgramType}
          studentName={student.firstName}
          isParent={isParent}
        />
      )
  }

  return (
    <>
      <div className="mt-5">
        <button onClick={reset} className="btn small blue">
          <FontAwesomeIcon icon={faChevronLeft} /> Change tutoring type
        </button>
      </div>
      {TutoringComponent}
    </>
  )
}

// keep this outside the component
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)
const StripeWrapper = ({ student, isParent }) => {
  return (
    <Elements stripe={stripePromise}>
      <ProgramRegistration student={student} isParent={isParent} />
    </Elements>
  )
}

export default StripeWrapper
