import { pipe, subscribe } from "wonka"

export const getCacheForClient = client => {
  if (!client._react) {
    const reclaim = new Set()
    const map = new Map()

    if (client.operations$ /* not available in mocks */) {
      pipe(
        client.operations$,
        subscribe(operation => {
          if (operation.kind === "teardown" && reclaim.has(operation.key)) {
            reclaim.delete(operation.key)
            map.delete(operation.key)
          }
        })
      )
    }

    client._react = {
      get(key) {
        return map.get(key)
      },
      set(key, value) {
        reclaim.delete(key)
        map.set(key, value)
      },
      dispose(key) {
        reclaim.add(key)
      },
    }
  }

  return client._react
}
