import React, { useState } from "react"

import { Form, Formik } from "formik"
import { compress } from "micro-graphql-react"
import moment from "moment-timezone"

import { adminClient as client } from "src/graphql-config"

import { useGradeOptions, useSubjectOptions } from "hooks/remoteTable"

import { ErrorMessage, requireInput } from "components/Forms/Formik"
import {
  AutocompleteField,
  DateRangeField,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import SessionResults from "./SessionResults"

const AddSessions = ({ addRecipients }) => {
  const formState = {
    organizationIds: [],
    gradeIds: [],
    schoolIds: [],
    subjectIds: [],
    dates: [],
  }

  const [sessions, setSessions] = useState()
  const subjectOptions = useSubjectOptions()
  const gradeOptions = useGradeOptions()

  const performSearch = (values, actions) => {
    const {
      dates: [startsAt, endsAt],
      ...rest
    } = values

    const params = { ...rest }
    if (startsAt) {
      params.startsAt = moment(startsAt.toDate()).startOf("day").format()
    }
    if (endsAt) {
      params.endsAt = moment(endsAt.toDate()).endOf("day").format()
    }

    client
      .runQuery(sessionsSearchQuery, { searchInput: params })
      .then(
        response => {
          setSessions(response.data.airtutorsSessions.data)
        },
        () => {
          actions.setStatus("An error occurred")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("An error occurred")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Sessions"
      buttonClassName="btn btn-info"
      buttonText="Add Sessions"
      afterClose={() => {
        setSessions()
      }}
    >
      {({ closeModal }) =>
        sessions ? (
          <SessionResults
            sessions={sessions}
            addRecipients={addRecipients}
            closeModal={closeModal}
          />
        ) : (
          <Formik
            initialValues={formState}
            onSubmit={performSearch}
            validate={requireInput}
          >
            <Form>
              <div className="modal-body">
                <AutocompleteField
                  name="organizationIds"
                  label="Organizations"
                  api="/api/admins/organizations/autocomplete_name"
                  isMulti
                />
                <SelectField
                  name="subjectIds"
                  label="Subjects"
                  options={subjectOptions}
                  isMulti
                  isClearable
                  styles={{
                    container: provided => ({
                      ...provided,
                      maxWidth: 250,
                    }),
                  }}
                />
                <SelectField
                  name="gradeIds"
                  label="Grades"
                  options={gradeOptions}
                  isMulti
                  isClearable
                  styles={{
                    container: provided => ({
                      ...provided,
                      maxWidth: 250,
                    }),
                  }}
                />
                <AutocompleteField
                  name="schoolIds"
                  label="Schools"
                  api="/api/admins/schools/autocomplete_name"
                  isMulti
                />

                <DateRangeField name="dates" label="Date Range" />
              </div>

              <div className="modal-footer flex-column">
                <FormStatus />
                <ErrorMessage name="base" touched={true} />
                <SubmitButton text="Search" />
              </div>
            </Form>
          </Formik>
        )
      }
    </ModalWithProvidedBody>
  )
}

const sessionsSearchQuery = compress`
  query(
    $searchInput: AirtutorsSessionSearchInputObject!
  ) {
    airtutorsSessions(
      paginated: false
      searchInput: $searchInput
    ) {
      data {
        id
        startsAt
        organization {
          id
          name
        }
        subject {
          id
          name
        }
        tutor {
          id
          fullName
          lastName
          phoneNumber
        }
        students {
          id
          fullName
          lastName
          phoneNumber
          parentPhoneNumber
        }
      }
    }
  }
`

export default AddSessions
