import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, File, SubmitButton } from "components/Forms/Formik"
import { AutocompleteField } from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  organizationId: Yup.string().nullable().required(),
  file: Yup.mixed().test("file", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const TeacherBulkUploadForm = props => {
  const { runMutation } = useMutation(
    buildMutation(createTeacherBulkUploadMutation)
  )
  const formState = {
    organizationId: "",
    schoolId: "",
    file: {},
  }

  const handleSubmit = (values, actions) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")
      const { file, ...rest } = values

      runMutation({
        file: b64,
        filename: values.file.name || values.file.fileName,
        ...rest,
      }).then(response => {
        const { success, redirectTo, errorMessage } =
          response.createTeacherBulkUpload
        if (success) {
          window.location = redirectTo
        } else {
          actions.setStatus(errorMessage)
        }
      })
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ values, status, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <label className="form-label w-100">
                  CSV File
                  <File
                    name="file"
                    className="form-control"
                    accept=".csv"
                    setFieldValue={setFieldValue}
                  />
                </label>
                <ErrorMessage name="file" />
              </div>
            </div>

            <div className="form-group col-12">
              <div className="form-group">
                <label className="form-label w-100">
                  Organization
                  <Field
                    name="organizationId"
                    type="text"
                    className="form-control"
                  >
                    {({ field: { name, value } }) => (
                      <AutocompleteSelect
                        api="/api/admins/organizations/autocomplete_name"
                        selected={value}
                        onChange={selectedOption =>
                          setFieldValue(
                            name,
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        placeholder="Search for organization"
                        isClearable={false}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="organizationId" />
                </label>
              </div>
            </div>

            <div className="col-12">
              <AutocompleteField
                label="School"
                name="schoolId"
                api="/api/admins/schools/autocomplete_name"
                searchOptions={`organization_id=${values.organizationId}`}
              />
            </div>

            <div className="col-12">
              {status && (
                <div>
                  <div className="alert alert-danger d-inline-block">
                    {status}
                  </div>
                </div>
              )}

              <SubmitButton isSubmitting={isSubmitting} text="Upload" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const createTeacherBulkUploadMutation = compress`
  mutation(
    $file: String!
    $filename: String!
    $organizationId: ID!
    $schoolId: ID
  ) {
    createTeacherBulkUpload(
      file: $file
      filename: $filename
      organizationId: $organizationId
      schoolId: $schoolId
    ) {
      success
      redirectTo
      errorMessage
      errors {
        field
        messages
      }
    }
  }
`

export default TeacherBulkUploadForm
