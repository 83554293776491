import React, { ReactNode, useContext } from "react"

import { useField, useFormikContext } from "formik"
import { Calendar } from "react-multi-date-picker"

import Form from "~tailwindui/Form"
import ValueDisplay from "~tailwindui/Form/ValueDisplay"

import classNames from "src/classNames"
import dig from "src/dig"

import PersistedValuesContext from "./PersistedValuesContext"

export type InlineCalendarProps = {
  startFieldName: string
  endFieldName: string
  label: string
  description?: string | ReactNode
  range?: boolean
  value?: Date[]
}

const InlineCalendar: React.FC<InlineCalendarProps> = ({
  startFieldName,
  endFieldName,
  label,
  description = "",
  range = false,
  value = null,
  ...props
}) => {
  const [_startField, startMeta] = useField(startFieldName)
  const [_endField, endMeta] = useField(endFieldName)
  const persistedValues = useContext(PersistedValuesContext)
  const persistedStartValue = dig(persistedValues, startFieldName.split("."))
  const persistedEndValue = dig(persistedValues, endFieldName.split("."))
  const displayValue =
    persistedStartValue && persistedEndValue
      ? `${persistedStartValue.format("MM/DD/YYYY")} - ${persistedEndValue}`
      : ""
  const { values, setFieldValue } = useFormikContext() as any
  const startError = startMeta.touched && startMeta.error ? startMeta.error : ""
  const endError = endMeta.touched && endMeta.error ? endMeta.error : ""
  const error = [startError, endError].filter(Boolean).join(", ")
  return values.editing ? (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label
        htmlFor={startFieldName}
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        {label}
      </label>

      <div className={classNames(error && "relative rounded-md", "col-span-2")}>
        <Calendar
          range={range}
          onChange={dates => {
            setFieldValue(startFieldName, dates[0])
            setFieldValue(endFieldName, dates[1])
          }}
          value={value}
        />
        <div className="pt-3">
          {error ? (
            <Form.FieldError message={error} />
          ) : (
            <Form.FieldDescription description={description} />
          )}
        </div>
      </div>
    </div>
  ) : (
    <ValueDisplay
      label={label}
      description={description}
      {...props}
      value={displayValue}
    />
  )
}

export default InlineCalendar
