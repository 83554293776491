import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { parentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { SelectField } from "components/Forms/Formik/hookComponents"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const OrganizationField = () => {
  const loadingState = useQuery(buildQuery(gradesQuery, {}, { client }))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <SelectField
            name="organizationId"
            label="Organization"
            options={ordered(data.organizations, "name")}
            valueAttribute="id"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            isClearable
          />

          <a href="mailto:info@airtutors.org">
            Don't see your organization? Click here to contact us.
          </a>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const gradesQuery = compress`
  query {
    organizations {
      id
      name
    }
  }
`

export default OrganizationField
