import React, { useState } from "react"

import { Field, Form, Formik } from "formik"
import { buildQuery, compress, useQuery } from "micro-graphql-react"
import moment from "moment-timezone"
import Select from "react-select"

import { css } from "@emotion/core"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  billOptions,
  durationChangeOptions,
  payerOptions,
  regionOptions,
  statusOptions,
} from "src/enums"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Checkbox from "components/Forms/Checkbox"
import { DateRangePicker } from "components/Forms/DatePicker"
import { SelectField } from "components/Forms/Formik/hookComponents"
import { Loading } from "components/Icons"
import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Export from "./Export"

const SearchAirtutorsSessions = ({ canAdminBudgets }) => {
  const [page, setPage] = useState(1)
  const [queryVariables, setQueryVariables] = useState({})
  const loadingState = useQuery(
    buildQuery(searchSessionsQuery, { page: page, searchInput: queryVariables })
  )

  const formState = {
    tutorIds: [],
    studentIds: [],
    organizationIds: [],
    schoolIds: [],
    subjectIds: [],
    statusStates: [],
    billStates: [],
    durationChangeStates: [],
    payerType: undefined,
    startsAt: undefined,
    endsAt: undefined,
    minDuration: "",
    maxDuration: "",
    noShow: false,
    studentTagList: [],
    organizationRegion: "",
  }

  const handleSubmit = (values, actions) => {
    setPage(1)
    const variables = { ...values }
    if (variables.startsAt) {
      variables.startsAt = moment(variables.startsAt.toDate())
        .startOf("day")
        .format()
    }
    if (variables.endsAt) {
      variables.endsAt = moment(variables.endsAt.toDate()).endOf("day").format()
    }
    setQueryVariables(variables)
  }

  return (
    <>
      <Formik initialValues={formState} onSubmit={handleSubmit}>
        {({ status, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Tutor
                    <AutocompleteSelect
                      api="/api/admins/tutors/autocomplete_full_name"
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "tutorIds",
                          selected.map(s => s?.value)
                        )
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Student
                    <AutocompleteSelect
                      api="/api/admins/students/autocomplete_full_name"
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "studentIds",
                          selected.map(s => s?.value)
                        )
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Organization
                    <AutocompleteSelect
                      api="/api/admins/organizations/autocomplete_name"
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "organizationIds",
                          selected.map(s => s?.value)
                        )
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    School
                    <AutocompleteSelect
                      api="/api/admins/schools/autocomplete_name"
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "schoolIds",
                          selected.map(s => s?.value)
                        )
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Subject
                    <AutocompleteSelect
                      api="/api/admins/subjects/autocomplete_name"
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "subjectIds",
                          selected.map(s => s?.value)
                        )
                      }
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Scheduled
                    <Select
                      options={statusOptions}
                      isClearable
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "statusStates",
                          selected.map(s => s?.value)
                        )
                      }
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Billed
                    <Select
                      options={billOptions}
                      isClearable
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "billStates",
                          selected.map(s => s?.value)
                        )
                      }
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Duration Change
                    <Select
                      options={durationChangeOptions}
                      isClearable
                      isMulti
                      onChange={selected =>
                        setFieldValue(
                          "durationChangeStates",
                          selected.map(s => s?.value)
                        )
                      }
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    Payer Type
                    <Select
                      options={payerOptions}
                      isClearable
                      onChange={selected =>
                        setFieldValue("payerType", selected?.value)
                      }
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label w-100">
                    Date Range
                    <DateRangePicker
                      onChange={([startDate, endDate]) => {
                        setFieldValue("startsAt", startDate)
                        setFieldValue("endsAt", endDate)
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label w-100">
                    Minimum Duration
                    <Field
                      name="minDuration"
                      type="text"
                      className="form-control"
                    />
                  </label>
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label className="form-label w-100">
                    Maximum Duration
                    <Field
                      name="maxDuration"
                      type="text"
                      className="form-control"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label w-100">
                    <Field name="noShow">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          name={name}
                          onChange={onChange}
                          checked={value}
                          value={true}
                        >
                          No Show
                        </Checkbox>
                      )}
                    </Field>
                  </label>
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    <Field name="onDemand">
                      {({ field: { onChange, name, value } }) => (
                        <Checkbox
                          name={name}
                          onChange={onChange}
                          checked={value}
                          value={true}
                        >
                          On Demand
                        </Checkbox>
                      )}
                    </Field>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label w-100">Student Tags</label>
                  <AutocompleteSelect
                    api="/api/admins/students/autocomplete_tags"
                    isMulti
                    onChange={selected =>
                      setFieldValue(
                        "studentTagList",
                        selected.map(tag => tag.label)
                      )
                    }
                  />
                </div>
              </div>

              <div
                className="col-4"
                css={css`
                  label > span {
                    margin-bottom: 0.5rem;
                    display: block;
                  }
                `}
              >
                <SelectField
                  name="organizationRegion"
                  label="Organization Region"
                  options={regionOptions}
                  isClearable
                />
              </div>
            </div>

            {status && (
              <div
                className="alert alert-danger"
                css={css`
                  margin-top: 15px;
                `}
              >
                {status}
              </div>
            )}

            <div
              css={css`
                display: inline-block;
                margin: 15px 15px 0 0;
              `}
            >
              <button
                type="submit"
                className="btn solid blue"
                disabled={loadingState.loading}
              >
                <span>Search</span>
                {loadingState.loading && (
                  <div
                    css={css`
                      display: inline-block;
                      margin-left: 15px;
                    `}
                  >
                    <Loading />
                  </div>
                )}
              </button>
            </div>

            <WithLoadingIndicator loadingState={loadingState}>
              {({ data }) => (
                <>
                  <Export canAdminBudgets={canAdminBudgets} />

                  <table className="table">
                    <thead className="text-primary">
                      <tr>
                        <th>Subject</th>
                        <th>Tutor</th>
                        <th>Students</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Actual Duration</th>
                        <th>Billed Duration</th>
                        <th>No Show?</th>
                        <th>Scheduled State</th>
                        <th>Bill State</th>
                        <th>Payer Type</th>
                        <th>Duration Change State</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.airtutorsSessions.data.map(session => (
                        <tr key={session.id}>
                          <td>{session.subject.name}</td>
                          <td>
                            {session.tutor && (
                              <a href={session.tutor.showPath}>
                                {session.tutor.fullName}
                              </a>
                            )}
                          </td>
                          <td>
                            <ul className="list-unstyled">
                              {session.students.map(student => (
                                <li key={student.id}>
                                  <a href={student.showPath}>
                                    {student.fullName}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <LocalTime
                              timestamp={session.startsAt}
                              omitTime={true}
                            />
                          </td>
                          <td>
                            <LocalTime
                              timestamp={session.startsAt}
                              format="LT"
                            />
                            &mdash;
                            <LocalTime timestamp={session.endsAt} format="LT" />
                          </td>
                          <td>{session.formattedActualDuration}</td>
                          <td>{session.formattedBilledDuration}</td>
                          <td>
                            {session.noShow && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-danger mr-3"
                              />
                            )}
                          </td>
                          <td>{session.statusState}</td>
                          <td>{session.billState}</td>
                          <td>
                            {session.organization ? (
                              <a href={session.organization.showPath}>
                                {session.organization.name}
                              </a>
                            ) : (
                              session.payerType
                            )}
                          </td>
                          <td>{session.durationChangeState}</td>
                          <td>
                            <a
                              className="btn btn-sm btn-info"
                              href={session.showPath}
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={data.airtutorsSessions.currentPage}
                    totalPages={data.airtutorsSessions.totalPages}
                    changePage={page => setPage(page)}
                  />
                </>
              )}
            </WithLoadingIndicator>
          </Form>
        )}
      </Formik>
    </>
  )
}

const searchSessionsQuery = compress`
  query(
    $page: Int
    $searchInput: AirtutorsSessionSearchInputObject!
  ) {
    airtutorsSessions(
      page: $page
      searchInput: $searchInput
    ) {
      totalPages
      currentPage
      data {
        id
        startsAt
        endsAt
        formattedActualDuration
        formattedBilledDuration
        noShow
        statusState
        billState
        payerType
        durationChangeState
        showPath

        organization {
          id
          name
          showPath
        }
        subject {
          name
        }
        tutor {
          fullName
          showPath
        }
        students {
          id
          fullName
          showPath
        }
      }
    }
  }
`

export default SearchAirtutorsSessions
