import React from "react"

import { FieldArray, useField } from "formik"

import { css } from "@emotion/core"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ErrorMessage, Field } from "components/Forms/Formik/hookComponents"

const MonthlySettings = () => {
  const [mode] = useField("reportScheduleMode")
  const [, meta] = useField("reportDays")

  if (mode.value !== "monthly") return null

  return (
    <FieldArray name="reportDays">
      {arrayHelpers => (
        <>
          {arrayHelpers.form.values.reportDays.map((_, index) => (
            <div
              key={index}
              className="d-flex align-items-center mb-3"
              css={css`
                .form-group,
                label {
                  margin: 0;
                }
              `}
            >
              <button
                type="button"
                onClick={() => arrayHelpers.remove(index)}
                className="mr-3 cursor-pointer border-none bg-transparent text-xl"
              >
                <FontAwesomeIcon icon={faTimes} className="text-red-500" />
              </button>
              <Field
                name={`reportDays[${index}]`}
                type="number"
                min={1}
                max={28}
              />
            </div>
          ))}
          <button
            type="button"
            className="btn btn-info"
            onClick={() => arrayHelpers.push(1)}
          >
            Add Day of Month
          </button>
          <ErrorMessage {...meta} />
        </>
      )}
    </FieldArray>
  )
}

export default MonthlySettings
