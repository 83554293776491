const titlecase = (string: string): string => {
  if (!string) return ""
  const parts = string.split("_")
  const capitalizedParts = parts.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  )
  return capitalizedParts.join(" ")
}

export default titlecase
