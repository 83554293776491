import React from "react"

import styles from "./styles.module.scss"

const LoadingIndicator = props => {
  const { size } = props
  let containerClassName = "h2 text-center mt-4"
  if (size === "small") {
    containerClassName = `d-flex align-items-center ml-4 ${styles.small}`
  }
  return (
    <div className={`${containerClassName} ${styles.loading}`}>
      <i className="nc-icon nc-refresh-69 spinner" />
    </div>
  )
}

export default LoadingIndicator
