const AIRTUTORS_SESSION_FILTER = "airtutorsSessionsFilters"
const AIRTUTORS_SESSION_ORDER = "airtutorsSessionsOrder"
const STUDENT_SESSION_FILTER = "studentSessionsFilters"
const STUDENT_SESSION_ORDER = "studentSessionsOrder"

export {
  AIRTUTORS_SESSION_FILTER,
  AIRTUTORS_SESSION_ORDER,
  STUDENT_SESSION_FILTER,
  STUDENT_SESSION_ORDER,
}
