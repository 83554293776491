import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { useGradeOptions } from "hooks/remoteTable"

import {
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  gradeIds: Yup.array().min(1, "Please select at least 1 grade"),
})

const NewAvailabilitySet = ({ organizationId }) => {
  const gradeOptions = useGradeOptions()
  const flatGradeOptions = gradeOptions.map(group => group.options).flat()
  const formState = {
    organizationId,
    name: "",
    gradeIds: flatGradeOptions.map(option => option.value),
  }

  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.availabilitySetCreate
          if (errorMessages) {
            actions.setStatus(errorMessages)
            actions.setSubmitting(false)
          } else {
            closeModal()
          }
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Availability Set"
      buttonText="New Availability Set"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <Field name="name" label="Name (for admin use only)" />
                <SelectField
                  name="gradeIds"
                  label="Grades"
                  options={flatGradeOptions}
                  defaultValue={flatGradeOptions}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>

              <div className="modal-footer flex-column">
                <FormStatus />
                <div className="d-flex justify-content-between w-full">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = compress`
  mutation($organizationId: ID!, $name: String!, $gradeIds: [ID!]!) {
    availabilitySetCreate(organizationId: $organizationId, name: $name, gradeIds: $gradeIds) {
      errorMessages
      availabilitySet {
        id
        name
        grades {
          id
          name
          number
        }
        availabilities {
          id
          startsAt
          endsAt
        }
      }
    }
  }
`

export default NewAvailabilitySet
