import * as colors from "styles/colors"

import { theme } from "@chakra-ui/core"

const defaultButtonProps = {
  variant: "solid",
  textDecoration: "none",
}

theme.breakpoints.sm = "20rem"
theme.breakpoints.md = "42rem"
theme.breakpoints[0] = theme.breakpoints.sm
theme.breakpoints[1] = theme.breakpoints.md

// generated from https://palx.jxnblk.com/ using a mix of
// airtutors pink (#EC3255) and rgb(53, 105, 192) (blue) as base

theme.colors.brand = colors.red
theme.colors.brandStatic = {
  500: theme.colors.brand,
}
theme.colors.brandShades = {
  50: "#fef8f9",
  100: "#fde9ed",
  200: "#fbd8df",
  300: "#fac6d0",
  400: "#f8b1bf",
  500: "#f598ab",
  600: "#f37a92",
  700: "#ef4f6f",
  800: "#cb2b4b",
  900: "#791a2d",
}

// from blue palette
theme.colors.gray = {
  50: "#f9f9fa",
  100: "#eceef0",
  200: "#dee1e5",
  300: "#cfd3d9",
  400: "#bfc4cc",
  500: "#adb3be",
  600: "#98a0ae",
  700: "#7f8a9a",
  800: "#626d7e",
  900: "#39404a",
}

theme.colors.orange = {
  50: "#fef8f6",
  100: "#fceae2",
  200: "#fadacc",
  300: "#f8c9b5",
  400: "#f6b59a",
  500: "#f39e7a",
  600: "#ef8253",
  700: "#df642f",
  800: "#b04f25",
  900: "#682f16",
}

theme.colors.yellow = {
  50: "#fdfaeb",
  100: "#f9eec0",
  200: "#f5e190",
  300: "#f0d158",
  400: "#e5c130",
  500: "#d1b02c",
  600: "#bb9e28",
  700: "#a08722",
  800: "#7f6b1b",
  900: "#4a3f10",
}

theme.colors.cyan = {
  50: "#ebfdfa",
  100: "#bff9ed",
  200: "#87f4de",
  300: "#34ecc8",
  400: "#2edbb9",
  500: "#2bc9a9",
  600: "#26b397",
  700: "#219a82",
  800: "#1a7a67",
  900: "#0f473c",
}
theme.colors.green = theme.colors.cyan

// from blue palette
theme.colors.blue = {
  50: "#f8f9fd",
  100: "#e8eef8",
  200: "#d7e1f3",
  300: "#c5d4ed",
  400: "#b0c5e7",
  500: "#9ab4df",
  600: "#80a1d7",
  700: "#608acd",
  800: "#366ac0",
  900: "#1f3f71",
}

theme.colors.indigo = {
  50: "#f8f9fe",
  100: "#e9edfd",
  200: "#d9e0fb",
  300: "#c8d2fa",
  400: "#b4c2f8",
  500: "#9fb0f6",
  600: "#859bf4",
  700: "#6682f1",
  800: "#3a5eed",
  900: "#1f3692",
}

theme.colors.violet = {
  50: "#faf9fe",
  100: "#f0ebfd",
  200: "#e6dcfc",
  300: "#daccfa",
  400: "#cdbaf9",
  500: "#bea6f7",
  600: "#ad8ff5",
  700: "#9871f2",
  800: "#7a48ee",
  900: "#48229f",
}
theme.colors.purple = theme.colors.violet

theme.colors.fuschia = {
  50: "#fdf8fe",
  100: "#f9e8fd",
  200: "#f4d7fb",
  300: "#efc5fa",
  400: "#e9b0f8",
  500: "#e397f5",
  600: "#da79f3",
  700: "#cf4fef",
  800: "#ad2bcd",
  900: "#671a7a",
}

theme.colors.pink = {
  50: "#fef7fc",
  100: "#fde8f6",
  200: "#fbd6f0",
  300: "#f9c3e9",
  400: "#f7ade1",
  500: "#f592d7",
  600: "#f271cb",
  700: "#ed3eb9",
  800: "#c12993",
  900: "#731858",
}

theme.buttonVariants = {
  view: {
    ...defaultButtonProps,
    variantColor: "gray",
  },

  realtimeboard: {
    ...defaultButtonProps,
    variantColor: "cyan",
    bg: "cyan.700",
    _hover: { bg: "cyan.800" },
    _active: { bg: "cyan.900" },
  },

  cloudroom: {
    ...defaultButtonProps,
    variantColor: "blue",
    bg: "blue.700",
    _hover: { bg: "blue.800" },
    _active: { bg: "blue.900" },
  },

  recording: {
    ...defaultButtonProps,
    variantColor: "red",
  },
}

theme.fonts.body = "Lato, 'Helvetica Neue', Helvetica, sans-serif"
theme.fonts.heading = "Raleway, Montserrat, Arial, sans-serif"

export default theme
