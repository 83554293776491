import React from "react"

import { FormikValues, useFormikContext } from "formik"

import {
  AutocompleteField,
  CheckboxField,
} from "components/Forms/Formik/hookComponents"

const StatementGenerationTags = React.forwardRef<HTMLSelectElement>(
  (props, ref) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>()

    return (
      <>
        <CheckboxField name="filterByTags" label="Filter by Study Group Tags" />

        {values.filterByTags && (
          <div className="ml-4">
            <p className="italic text-red-600">
              Sessions from study groups containing any of these tags will be
              included in this statement.
            </p>
            <AutocompleteField
              name="studyGroupTags"
              api="/api/admins/study_groups/autocomplete_tags"
              onChange={selected =>
                setFieldValue(
                  "studyGroupTags",
                  selected.map(tag => tag.label)
                )
              }
              isMulti
              ref={ref}
              {...props}
            />
          </div>
        )}
      </>
    )
  }
)

export default StatementGenerationTags
