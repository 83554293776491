import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
})

const NewSubject = ({ subjectBucket }) => {
  const { runMutation } = useMutation(buildMutation(createSubjectMutation))
  const formState = {
    subjectBucketId: subjectBucket.id,
    name: "",
    organizationId: undefined,
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.subjectCreate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Subject"
      buttonClassName="btn btn-sm btn-success"
      buttonText="Add Subject"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ isSubmitting, status, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field name="name" className="form-control" autoFocus />
                  </label>
                  <ErrorMessage name="name" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Organization (optional)
                    <AutocompleteSelect
                      api="/api/admins/organizations/autocomplete_name"
                      onChange={selected =>
                        setFieldValue("organizationId", selected?.value)
                      }
                      isClearable
                      menuPortalTarget={document.body}
                    />
                  </label>
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Create Subject"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createSubjectMutation = compress`
  mutation(
    $subjectBucketId: ID!
    $name: String!
    $organizationId: ID
  ) {
    subjectCreate(
      subjectBucketId: $subjectBucketId
      name: $name
      organizationId: $organizationId
    ) {
      subject {
        id
        name
        tier {
          id
        }
        subjectBucket {
          id
        }
        organization {
          id
          name
        }
      }
    }
  }
`

export default NewSubject
