import { ValueFormat } from "~tailwindui/types/enums"

const formatValue = (val: number, format: ValueFormat): string => {
  if (!val) return "0"

  switch (format) {
    case ValueFormat.Number:
      return Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
      }).format(val)
    case ValueFormat.Currency:
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(val)
    case ValueFormat.Percent:
      return Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
      }).format(val)
    default:
      return val.toLocaleString()
  }
}

export default formatValue
