import React from "react"

import { css } from "@emotion/core"

const MuteAllButton = props => {
  const blue = "rgba(53, 105, 192, 0.75)"
  const red = "rgba(236, 50, 85, 0.75)"

  const blueHover = "rgba(42, 83, 152, 0.75)"
  const redHover = "rgba(215, 20, 57, 0.75)"

  return (
    <button
      onClick={props.onClick}
      css={css`
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        padding: 0.25rem;
        color: white;
        border: none;
        transition: all 0.5s;
        background-color: ${props.muted ? red : blue};
        border-radius: 0.15em;
        svg {
          margin-right: 0.5rem;
        }
        &:hover {
          background-color: ${props.muted ? redHover : blueHover};
        }
      `}
    >
      {props.children}
    </button>
  )
}

export default MuteAllButton
