import React from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import classNames from "src/classNames"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const EndSession = ({ airtutorsSessionId, userType }) => {
  const { runMutation, running } = useMutation(
    buildMutation(endSessionMutation)
  )

  const closeCloudroom = () => {
    runMutation({ airtutorsSessionId }).then(response => {
      const { redirectTo, failures } = response.airtutorsSessionCloseCloudroom
      if (failures.length > 0) {
        alert(failures[0].message)
      } else {
        window.location = redirectTo
      }
    })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="EMERGENCY END SESSION"
      buttonText="EMERGENCY! END SESSION FOR ALL USERS"
      buttonClassName={classNames(
        userType === "Admin" && "btn btn-danger",
        userType === "Teacher" && "bg-brand-700 px-3 py-1 rounded font-semibold"
      )}
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="alert alert-danger font-weight-bold text-center">
              THIS WILL END THE CLOUDROOM SESSION FOR ALL USERS
            </p>

            <p className="lead">
              If you just want to leave the room, simply close your browser tab
            </p>
          </div>

          <div className="modal-footer">
            <div className="grid w-full grid-cols-2 gap-x-4">
              <button
                className="btn btn-outline-info whitespace-normal"
                type="button"
                onClick={closeModal}
              >
                No, do not end this session.
              </button>
              <button
                className={`btn btn-danger whitespace-normal ${
                  running ? "disabled" : ""
                }`}
                type="button"
                onClick={closeCloudroom}
                disalbed={running}
              >
                YES. Close this cloudroom immediately.
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const endSessionMutation = compress`
  mutation($airtutorsSessionId: ID!) {
    airtutorsSessionCloseCloudroom(airtutorsSessionId: $airtutorsSessionId) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default EndSession
