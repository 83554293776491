import { useMemo, useRef } from "react"

import { createRequest } from "@urql/core"

/** Creates a request from a query and variables but preserves reference equality if the key isn't changing */
export function useRequest(query, variables) {
  const prev = useRef(undefined)

  return useMemo(() => {
    const request = createRequest(query, variables)
    // We manually ensure reference equality if the key hasn't changed
    if (prev.current !== undefined && prev.current.key === request.key) {
      return prev.current
    } else {
      prev.current = request
      return request
    }
  }, [query, variables])
}
