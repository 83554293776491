import React from "react"

const ProgramSelection = ({ setProgramType, studentName, isParent }) => {
  return (
    <div>
      <h1>Program Registration</h1>
      <h3>
        {isParent
          ? `What type of tutoring does ${studentName} need?`
          : "What type of tutoring do you need?"}
      </h3>

      <div className="flex h-[200px] flex-col justify-evenly">
        <div className="flex w-full justify-center">
          <button
            type="button"
            onClick={() => setProgramType("1-1")}
            className="btn blue outline"
          >
            1-1 Tutoring
          </button>
        </div>

        <div className="flex w-full justify-center">
          <button
            type="button"
            onClick={() => setProgramType("smallGroup")}
            className="btn blue outline"
          >
            Small Group Tutoring (up to 5 students, $600)
          </button>
        </div>

        <div className="flex w-full justify-center">
          <button
            type="button"
            onClick={() => setProgramType("smallClass")}
            className="btn blue outline"
          >
            Small Class Tutoring (up to 10 students, $300)
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProgramSelection
