import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required("Message cannot be blank"),
})

const EditTestimonial = ({ row }) => {
  const { runMutation } = useMutation(buildMutation(updateMutation))
  const formState = {
    id: row.id,
    message: row.message,
  }

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.testimonialUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Testimonial"
      buttonText={<FontAwesomeIcon icon={faEdit} />}
      buttonClassName="btn btn-sm btn-outline-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Message
                    <Field
                      name="message"
                      className="form-control"
                      component="textarea"
                      rows={10}
                      css={css`
                        max-height: initial !important;
                      `}
                    />
                  </label>
                  <ErrorMessage name="message" />
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton isSubmitting={isSubmitting} text="Update" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateMutation = compress`
  mutation($id: ID!, $message: String!) {
    testimonialUpdate(id: $id, message: $message) {
      errorMessages
    }
  }
`

export default EditTestimonial
