import React from "react"

import { gql, useQuery } from "hooks/urql"

import TagSelect from "components/Forms/TagSelect"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const StudentTags = ({ studentId }) => {
  const [result] = useQuery({ query: tagsQuery, variables: { studentId } })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <TagSelect
          api="/api/admins/students/autocomplete_tags"
          tagList={data.student.tags.map(tag => ({
            value: tag.id,
            label: tag.name,
          }))}
          modelKey="tagId"
          recordId={studentId}
          createMutation={createTagMutation}
          addMutation={addTagMutation}
          removeMutation={removeTagMutation}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const tagsQuery = gql`
  query ($studentId: ID!) {
    student(id: $studentId) {
      id
      tags {
        id
        name
      }
    }
  }
`
const createTagMutation = gql`
  mutation ($name: String!, $userId: ID!) {
    userCreateTag(name: $name, userId: $userId) {
      user {
        ... on Student {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const addTagMutation = gql`
  mutation ($tagId: ID!, $userId: ID!) {
    userAddTag(tagId: $tagId, userId: $userId) {
      user {
        ... on Student {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const removeTagMutation = gql`
  mutation ($tagId: ID!, $userId: ID!) {
    userRemoveTag(tagId: $tagId, userId: $userId) {
      user {
        ... on Student {
          id
        }
      }
      failures {
        message
      }
    }
  }
`
export default StudentTags
