import React from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Modal from "components/Modal"

import "react-table/react-table.css"

const AdminOrganizationsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/organizations",
  })

  let columns = [
    {
      id: "organization",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api={"/api/admins/organizations/autocomplete_name"}
          />
        )
      },
    },
    {
      id: "organization.contact_email",
      Header: "Contact Email",
      accessor: "contact_email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      filterable: false,
      sortable: false,
    },
  ]

  columns = columns.concat([
    {
      id: "organization.slug",
      Header: "Signup URL",
      accessor: "slug",
      filterable: false,
      sorable: false,
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-between">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <a
            href={props.value.edit_path}
            className="btn btn-sm btn-outline-dark fa fa-pencil"
          >
            <span className="hidden">Edit</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure you want to remove ${props.row["organization"]}?</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove them!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep them for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ])

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminOrganizationsTable
