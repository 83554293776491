import { useEffect, useState } from "react"

import { singletonHook } from "react-singleton-hook"

import axios from "src/axios"
import ordered from "src/ordered"

const useGradeOptions = () => {
  const [gradeOptions, setGradeOptions] = useState([])

  useEffect(() => {
    axios.get("/api/admins/grades").then(response => {
      const json = response.data,
        gradeOptions = []
      json.data.forEach(grade => {
        let arrayIndex = gradeOptions.findIndex(h => h.label === grade.tierName)
        if (arrayIndex < 0) {
          gradeOptions.push({ label: grade.tierName, options: [] })
          arrayIndex = gradeOptions.length - 1
        }
        const optionGroup = gradeOptions[arrayIndex]
        optionGroup.options.push(grade)
        optionGroup.options = ordered(optionGroup.options, "number")
        gradeOptions[arrayIndex] = optionGroup
      })
      setGradeOptions(gradeOptions)
    })
  }, [])

  return gradeOptions
}

export default singletonHook([], useGradeOptions)
