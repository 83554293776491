import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const StudentSearch = props => {
  const studentSelected = value => {
    if (!value?.value) return
    window.location.href = `/tutor/students/${value.value}`
  }

  return (
    <div>
      <AutocompleteSelect
        api="/api/tutors/students/autocomplete_full_name"
        placeholder={props.placeholder}
        onChange={studentSelected}
      />
    </div>
  )
}

StudentSearch.defaultProps = {
  placeholder: "Search by name",
}

export default StudentSearch
