import React from "react"

import { Form, Formik } from "formik"

import ordered from "src/ordered"

import {
  CheckboxField,
  FormStatus,
  RadioField,
  SelectAll,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

const Results = ({
  users,
  source = "student",
  sourceOptions,
  addRecipients,
  closeModal,
}) => {
  const formState = { userIds: [], source: source }

  const handleSubmit = values => {
    const { userIds } = values

    const selectedUsers = users.filter(user => userIds.includes(user.id))
    if (values.source === "tutor") {
      addRecipients(selectedUsers, "phoneNumber", "parentPhoneNumber")
    }
    if (values.source === "student") {
      addRecipients(selectedUsers, "phoneNumber", "parentPhoneNumber")
    } else if (values.source === "parent") {
      addRecipients(selectedUsers, "parentPhoneNumber")
    }
    closeModal()
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="modal-body">
          {users.length === 0 ? (
            <p className="lead">No results</p>
          ) : (
            <>
              <SelectAll fieldName="userIds" items={users} />
              <RadioField name="source" options={sourceOptions} />
              <hr />

              <ul className="list-unstyled">
                {ordered(users, "lastName").map(user => (
                  <li key={user.id}>
                    <CheckboxField
                      name="userIds"
                      value={user.id}
                      label={user.fullName}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        <div className="modal-footer flex-column">
          <FormStatus />
          <SubmitButton text="Add Selected Users" />
        </div>
      </Form>
    </Formik>
  )
}

export default Results
