import { useCallback, useEffect, useMemo, useState } from "react"

import Ding from "assets/ding.mp3"

import { useDisclosure } from "@chakra-ui/core"

import EventEmitter from "src/EventEmitter"

import useWebsocket from "hooks/useWebsocket"

export type useCloudroomControlsProps = {
  audioVideo: any
  localUser: {
    type: "Student" | "Tutor" | "Teacher" | "Admin"
    id: string
  }
  session: any
  ghost?: boolean
  roster?: any
}

const useCloudroomControls = ({
  audioVideo,
  session,
  localUser,
  roster,
  ghost = false,
}: useCloudroomControlsProps) => {
  // SET UP STATE
  const lowBandwidthStudent =
    session.lowBandwidthCloudroom && localUser.type === "Student"

  const [localControlsLocked, setLocalControlsLocked] = useState<boolean>(
    session.controlsLockedUserIds.includes(localUser.id)
  )
  const [localScreenShareDisabled, setLocalScreenShareDisabled] =
    useState<boolean>(
      localUser.type === "Student" &&
        (session.studentScreenShareDisabled ||
          session.screenShareRejectedStudentIds.includes(localUser.id))
    )
  const [screenShareRequestPending, setScreenShareRequestPending] =
    useState<boolean>(false)
  const [screenShareRequestedBy, setScreenShareRequestedBy] = useState<{
    userId: string
    name: string
  }>()

  const [allAudioMuted, setAllAudioMuted] = useState<boolean>(false)
  const [allVideoMuted, setAllVideoMuted] = useState<boolean>(false)
  const [allControlsLocked, setAllControlsLocked] = useState<boolean>(false)
  const [controlsLockedUserIds, setControlsLockedUserIds] = useState<string[]>(
    session.controlsLockedUserIds.map(id => id)
  )
  const [studentScreenShareMessage, setStudentScreenShareMessage] = useState<
    string | undefined
  >()

  const [incomingStudents, setIncomingStudents] = useState<boolean>(false)
  const {
    isOpen: surveyModalIsOpen,
    onOpen: surveyModalOnOpen,
    onClose: surveyModalOnClose,
  } = useDisclosure()

  const channelProps = useMemo(() => {
    return { id: session.id }
  }, [session.id])
  const { channel }: { channel: any } = useWebsocket({
    channelName: "CloudroomChannel",
    channelProps,
  })
  useWebsocket({ channelName: "CloudroomUserChannel", channelProps })

  // SET UP SUBSCRIPTIONS
  useEffect(() => {
    if (!audioVideo || !channel || !localUser.type) return

    const isTutor = localUser.type === "Tutor"
    const isStudent = localUser.type === "Student"

    EventEmitter.subscribe("mute_all", data => {
      if (isTutor) {
        if (data.type === "audio") setAllAudioMuted(true)
        if (data.type === "video") setAllVideoMuted(true)
        setAllControlsLocked(true)
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent) {
        if (data.type === "audio") muteLocalAudio()
        if (data.type === "video") muteLocalVideo()
        setLocalControlsLocked(true)
      }
    })

    EventEmitter.subscribe("unmute_all", data => {
      if (isTutor) {
        if (data.type === "audio") setAllAudioMuted(false)
        if (data.type === "video") setAllVideoMuted(false)
        setAllControlsLocked(false)
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent) {
        if (data.type === "audio") unmuteLocalAudio()
        if (data.type === "video") unmuteLocalVideo()
        setLocalControlsLocked(false)
      }
    })

    EventEmitter.subscribe("mute_user", data => {
      if (isStudent && data.userId === localUser.id) {
        if (data.type === "audio") muteLocalAudio()
        if (data.type === "video") muteLocalVideo()
      }
    })

    EventEmitter.subscribe("unmute_user", data => {
      if (isTutor) {
        if (data.type === "audio") setAllAudioMuted(false)
        if (data.type === "video") setAllVideoMuted(false)
      } else if (isStudent && data.userId === localUser.id) {
        if (data.type === "audio") unmuteLocalAudio()
        if (data.type === "video") unmuteLocalVideo()
      }
    })

    EventEmitter.subscribe("lock_all", data => {
      if (isTutor) {
        setAllControlsLocked(true)
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent) {
        setLocalControlsLocked(true)
      }
    })

    EventEmitter.subscribe("unlock_all", data => {
      if (isTutor) {
        setAllControlsLocked(false)
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent) {
        setLocalControlsLocked(false)
      }
    })

    EventEmitter.subscribe("lock_user", data => {
      if (isTutor) {
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent && data.userId === localUser.id) {
        setLocalControlsLocked(true)
      }
    })

    EventEmitter.subscribe("unlock_user", data => {
      if (isTutor) {
        setAllControlsLocked(false)
        setControlsLockedUserIds(data.controlsLockedUserIds)
      } else if (isStudent && data.userId === localUser.id) {
        setLocalControlsLocked(false)
      }
    })

    EventEmitter.subscribe("screen_share_request", data => {
      setScreenShareRequestedBy(data)
    })

    EventEmitter.subscribe("screen_share_response", data => {
      if (data.approved) {
        startLocalScreenShare()
      } else {
        setLocalScreenShareDisabled(true)
        setStudentScreenShareMessage(
          "The tutor has denied your screen share request"
        )
      }
      setScreenShareRequestPending(false)
    })

    EventEmitter.subscribe("end_screen_share", () => {
      stopLocalScreenShare()
    })

    EventEmitter.subscribe("incoming_students", () => {
      if (!isTutor) return

      setIncomingStudents(true)
      new Audio(Ding).play()
    })

    EventEmitter.subscribe("student_survey", () => {
      surveyModalOnOpen()
    })

    EventEmitter.subscribe("admin_or_teacher_joined", () => {
      if (!isTutor) return

      new Audio(Ding).play()
    })

    EventEmitter.subscribe("end_session", data => {
      if (ghost) return

      if (isStudent) {
        window.location.href = data.studentRedirectTo
      } else if (localUser.type === "Tutor") {
        window.location.href = data.tutorRedirectTo
      } else {
        window.location.href = "/"
      }
    })

    // eslint-disable-next-line
  }, [audioVideo, channel, localUser.type])

  // RETURN FUNCTIONS TO USE
  const muteLocalAudio = useCallback(() => {
    if (audioVideo.realtimeIsLocalAudioMuted()) return

    audioVideo.realtimeMuteLocalAudio()
    audioVideo.stopAudioInput()
  }, [audioVideo])

  const unmuteLocalAudio = useCallback(() => {
    if (!audioVideo.realtimeIsLocalAudioMuted()) return

    audioVideo.listAudioInputDevices().then(devices => {
      const audioInputDeviceInfo = devices[0]
      audioVideo.startAudioInput(audioInputDeviceInfo.deviceId)
    })
    audioVideo.realtimeUnmuteLocalAudio()
  }, [audioVideo])

  const muteLocalVideo = useCallback(() => {
    audioVideo.stopLocalVideoTile()
    audioVideo.stopVideoInput()
  }, [audioVideo])

  const unmuteLocalVideo = useCallback(() => {
    if (lowBandwidthStudent) return

    audioVideo.listVideoInputDevices().then(devices => {
      const videoInputDeviceInfo = devices[0]
      audioVideo.startVideoInput(videoInputDeviceInfo.deviceId).then(() => {
        audioVideo.startLocalVideoTile()
      })
    })
  }, [audioVideo, lowBandwidthStudent])

  const toggleAllAudio = useCallback(() => {
    if (allAudioMuted) {
      channel.perform("unmute_all", { type: "audio" })
    } else {
      channel.perform("mute_all", { type: "audio" })
    }
  }, [channel, allAudioMuted])

  const toggleAllVideo = useCallback(() => {
    if (allVideoMuted) {
      channel.perform("unmute_all", { type: "video" })
    } else {
      channel.perform("mute_all", { type: "video" })
    }
  }, [channel, allVideoMuted])

  const toggleAllControlsLocked = useCallback(() => {
    if (allControlsLocked) {
      channel.perform("unlock_all")
    } else {
      channel.perform("lock_all")
    }
  }, [channel, allControlsLocked])

  const startLocalScreenShare = useCallback(async () => {
    muteLocalVideo()
    await audioVideo
      .startContentShareFromScreenCapture()
      .then(stream => audioVideo.startContentShare(stream))
      .catch(e => {
        setStudentScreenShareMessage(
          "If you did not click cancel, please use the latest version of Chrome on your desktop/laptop."
        )
      })
  }, [audioVideo, muteLocalVideo])

  const stopLocalScreenShare = useCallback(() => {
    audioVideo.stopContentShare()
    unmuteLocalVideo()
  }, [audioVideo, unmuteLocalVideo])

  const reattachVideoElements = useCallback(() => {
    const localAttendeeId = session.chimeJoinInfo.attendee.attendeeId

    Object.keys(roster).forEach(attendeeId => {
      if (roster[attendeeId].videoTileId === undefined) return

      const videoElementId =
        attendeeId === localAttendeeId ? "#video-local" : `#video-${attendeeId}`
      audioVideo.bindVideoElement(
        roster[attendeeId].videoTileId,
        document.querySelector(videoElementId)
      )
    })
  }, [audioVideo, roster, session.chimeJoinInfo.attendee.attendeeId])

  return {
    channel,
    muteLocalAudio,
    unmuteLocalAudio,
    muteLocalVideo,
    unmuteLocalVideo,
    localControlsLocked,
    allAudioMuted,
    toggleAllAudio,
    allVideoMuted,
    toggleAllVideo,
    allControlsLocked,
    toggleAllControlsLocked,
    controlsLockedUserIds,
    startLocalScreenShare,
    stopLocalScreenShare,
    localScreenShareDisabled,
    screenShareRequestPending,
    setScreenShareRequestPending,
    screenShareRequestedBy,
    setScreenShareRequestedBy,
    studentScreenShareMessage,
    setStudentScreenShareMessage,
    incomingStudents,
    setIncomingStudents,
    reattachVideoElements,
    surveyModalIsOpen,
    surveyModalOnClose,
    surveyModalOnOpen,
  }
}

export default useCloudroomControls
