import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import { weekdayOptions } from "src/enums"

import { DateObject } from "components/Forms/DatePicker"
import {
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import TimeInputs from "./TimeInputs"

const ValidationSchema = Yup.object().shape({
  startsAt: Yup.number().required("Please specify a start time"),
  endsAt: Yup.number().required("Please specify an end time"),
  weekday: Yup.string().required(
    "Please specify the weekday for this availability"
  ),
})

const AddAvailability = ({ studyGroupId }) => {
  const formState = {
    studyGroupId,
    startsAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
    endsAt: new DateObject().setHour(13).setMinute(0).setSecond(0),
    weekday: "",
  }
  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const { startsAt, endsAt, ...rest } = values
    runMutation({
      startsAt: moment(startsAt.toDate()).format(),
      endsAt: moment(endsAt.toDate()).format(),
      ...rest,
    })
      .then(
        response => {
          const { errorMessages } = response.studyGroupAddAvailability
          if (errorMessages) {
            actions.setStatus(errorMessages)
            actions.setSubmitting(false)
          } else {
            closeModal()
          }
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Availability"
      buttonText="Add Availability"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <TimeInputs />
              <SelectField
                name="weekday"
                label="Day of Week"
                options={weekdayOptions}
                menuPortalTarget={document.body}
              />
            </div>

            <div className="modal-footer flex-column">
              <FormStatus />
              <div className="d-flex justify-content-between w-full">
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = compress`
  mutation($studyGroupId: ID!, $startsAt: DateTime!, $endsAt: DateTime!, $weekday: WeekdayEnum!) {
    studyGroupAddAvailability(studyGroupId: $studyGroupId, startsAt: $startsAt, endsAt: $endsAt, weekday: $weekday) {
      errorMessages
      availability {
        id
        startsAt
        endsAt
        weekday
        weekdayNumber
      }
    }
  }
`

export default AddAvailability
