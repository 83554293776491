import React from "react"

import { gql } from "urql"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const NewStatement = ({ organizationId }) => {
  const [result] = useQuery({
    query: organizationQuery,
    variables: { organizationId },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Form
          schools={data.organization.schools}
          budgets={data.organization.activeBudgets.data}
          statementConfigurations={data.organization.statementConfigurations}
          organizationId={organizationId}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const organizationQuery = gql`
  query ($organizationId: ID!) {
    organization(id: $organizationId) {
      schools {
        id
        name
      }

      statementConfigurations {
        id
        name
        budget {
          id
          name
        }
      }

      activeBudgets(per: 100) {
        data {
          id
          name
          poNumber
          active
          formattedOriginalAmount
          formattedBalance
          startsOn
          endsOn
        }
      }
    }
  }
`

export default NewStatement
