import React from "react"

import NewCourseClusterButton from "./New"
import CourseClustersTable from "./Table"

const CourseClustersIndex = ({ bulkUploadsPath }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <NewCourseClusterButton />
          <a href={bulkUploadsPath} className="btn btn-info">
            Bulk Uploads
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <CourseClustersTable />
        </div>
      </div>
    </>
  )
}

export default CourseClustersIndex
