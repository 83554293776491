import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  AutocompleteField,
  Field,
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  subjectId: Yup.string().required("Please select a subject"),
})

const NewSection = ({ assessmentId }) => {
  const formState = { assessmentId, name: "", subjectId: "" }
  const [errors, setErrors] = useState([])
  const { runMutation } = useMutation(buildMutation(createSectionMutation))

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { failures } = response.assessmentSectionCreate
          if (failures) {
            setErrors(failures)
            actions.setSubmitting(false)
          } else {
            closeModal()
          }
        },
        () => {
          setErrors([{ message: "Something went wrong" }])
        }
      )
      .catch(err => {
        setErrors([{ message: "Something went wrong" }])
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Section"
      buttonText="New Section"
      buttonClassName="btn btn-outline-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <Field name="name" label="Name" autoFocus />
              <AutocompleteField
                name="subjectId"
                label="Subject"
                api="/api/admins/subjects/autocomplete_name"
              />
            </div>

            <div className="modal-footer flex-col">
              {errors.map((error, index) => (
                <div key={index} className="alert alert-danger">
                  <div>{error.message}</div>
                </div>
              ))}
              <FormStatus />
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createSectionMutation = compress`
  mutation($assessmentId: ID!, $subjectId: ID!, $name: String!) {
    assessmentSectionCreate(assessmentId: $assessmentId, subjectId: $subjectId, name: $name) {
      failures {
        message
      }
      assessmentSection {
        id
        name
        subject {
          id
          name
        }
      }
    }
  }
`

export default NewSection
