import { useEffect, useState } from "react"

import { singletonHook } from "react-singleton-hook"

import axios from "src/axios"

const useSubjectOptions = () => {
  const [subjectOptions, setSubjectOptions] = useState([])

  useEffect(() => {
    axios.get("/api/admins/subjects").then(response => {
      const json = response.data,
        subjectOptions = []
      json.data.forEach(subject => {
        let arrayIndex = subjectOptions.findIndex(
          h => h.label === subject.tierName
        )
        if (arrayIndex < 0) {
          subjectOptions.push({ label: subject.tierName, options: [] })
          arrayIndex = subjectOptions.length - 1
        }
        const optionGroup = subjectOptions[arrayIndex]
        optionGroup.options.push(subject)
        subjectOptions[arrayIndex] = optionGroup
      })
      setSubjectOptions(subjectOptions)
    })
  }, [])

  return subjectOptions
}

export default singletonHook([], useSubjectOptions)
