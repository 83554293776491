import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import Select from "react-select"
import * as Yup from "yup"

import { collaborationTypeOptions, subjectTypeOptions } from "src/enums"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  subjectType: Yup.string().required("Please select a subject type"),
  collaborationType: Yup.string().required(
    "Please select a collaboration type"
  ),
})

const NewSubjectBucket = ({ tier }) => {
  const { runMutation } = useMutation(
    buildMutation(createSubjectBucketMutation)
  )
  const formState = {
    tierId: tier.id,
    name: "",
    subjectType: "",
    collaborationType: "",
    hourlyRate: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.subjectBucketCreate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Subject Bucket"
      buttonClassName="btn btn-success"
      buttonText="Add Subject Bucket"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ isSubmitting, status, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field name="name" className="form-control" autoFocus />
                  </label>
                  <ErrorMessage name="name" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Subject Type
                    <Select
                      options={subjectTypeOptions}
                      onChange={selected =>
                        setFieldValue("subjectType", selected?.value)
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                  <ErrorMessage name="subjectType" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Collaboration Type
                    <Select
                      options={collaborationTypeOptions}
                      onChange={selected =>
                        setFieldValue("collaborationType", selected?.value)
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </label>
                  <ErrorMessage name="collaborationType" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Hourly Rate Override
                    <Field name="hourlyRate" className="form-control" />
                  </label>
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Create Subject Bucket"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createSubjectBucketMutation = compress`
  mutation(
    $tierId: ID!
    $name: String!
    $subjectType: SubjectTypeEnum!
    $collaborationType: CollaborationTypeEnum!
    $hourlyRate: String
  ) {
    subjectBucketCreate(
      tierId: $tierId
      name: $name
      subjectType: $subjectType
      collaborationType: $collaborationType
      hourlyRate: $hourlyRate
    ) {
      subjectBucket {
        id
        name
        subjectType
        collaborationType
        formattedHourlyRate
        hourlyRateOverride
        tier {
          id
          gradeRange
        }
        subjects {
          id
          name
        }
      }
    }
  }
`

export default NewSubjectBucket
