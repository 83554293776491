import React from "react"

import { useFormikContext } from "formik"

import Form from "~tailwindui/Form"

const Failures = () => {
  const { status } = useFormikContext()
  return Array.isArray(status) ? (
    <Form.AlertMessageBox messages={status} />
  ) : null
}

export default Failures
