import React from "react"

import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import Chip from "~tailwindui/ChipSimple"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

type AttendanceChipProps = {
  label: string
  startDate: Dayjs
  endDate: Dayjs
  groupRate: boolean
}

const AttendanceChip: React.FC<AttendanceChipProps> = ({
  label,
  startDate,
  endDate,
  groupRate,
}) => {
  const today = dayjs().format("MM/DD/YYYY")
  const [result] = useQuery({
    query,
    variables: {
      startingAt: startDate,
      endingAt: endDate,
      lastThirtyStart: dayjs(today).subtract(30, "days").startOf("day"),
      lastThirtyEnd: dayjs(today).endOf("day"),
      groupRate,
    },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({
        data: {
          viewer: {
            organization: { customRange, lastThirty },
          },
        },
      }) => (
        <Chip.Item
          label={label}
          value={customRange.total}
          valueFormat={ValueFormat.Percent}
          subtitle="Last 30 Days:"
          subtitleValue={lastThirty.total}
          subtitleValueFormat={ValueFormat.Percent}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query AttendanceChipQuery(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $lastThirtyStart: DateTime!
    $lastThirtyEnd: DateTime!
    $groupRate: Boolean
  ) {
    viewer {
      organization {
        customRange: attendanceDataSet(
          filters: {
            startingAt: $startingAt
            endingAt: $endingAt
            groupRate: $groupRate
          }
          groupByOptions: { groups: [] }
        ) {
          total
        }

        lastThirty: attendanceDataSet(
          filters: {
            startingAt: $lastThirtyStart
            endingAt: $lastThirtyEnd
            groupRate: $groupRate
          }
          groupByOptions: { groups: [] }
        ) {
          total
        }
      }
    }
  }
`

export default AttendanceChip
