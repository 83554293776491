import React, { useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import Header from "~tailwindui/Form/Header"
import Table from "~tailwindui/Table/Table"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import formatValue from "src/formatValue"

import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"

export type SchoolsPageProps = Record<string, never>

const SchoolsPage: React.FC<SchoolsPageProps> = () => {
  const [page, setPage] = useState(1)

  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const [result] = useQuery({
    query: schoolsQuery,
    variables: { page, startingAt, endingAt },
  })

  return (
    <>
      <Header title="Schools" />
      <DataDateRange
        startDate={startingAt}
        setStartDate={setStartingAt}
        endDate={endingAt}
        setEndDate={setEndingAt}
      />

      <WithLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <Table
              data={data.viewer.organization.schools}
              changePage={setPage}
              headers={[
                "Name",
                "Active Students",
                "Active Study Groups",
                "Group Attendance Rate",
                "Individual Attendance Rate",
                "Groups with No Shows",
              ]}
              valueMapper={school => [
                school.name,
                school.activeStudentsCount,
                school.activeStudyGroupsCount,
                formatValue(school.groupAttendance, ValueFormat.Percent),
                formatValue(school.attendance, ValueFormat.Percent),
                school.noShowstudyGroupsCount,
              ]}
              rowCallback={school =>
                (window.location.href = `/organization_admin/schools/${school.id}`)
              }
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const schoolsQuery = gql`
  query schools($page: Int!, $startingAt: DateTime!, $endingAt: DateTime!) {
    viewer {
      organization {
        schools: paginatedSchools(page: $page) {
          currentPage
          totalPages
          data {
            id
            name
            activeStudentsCount: studentsCount(
              startDate: $startingAt
              endDate: $endingAt
              active: true
            )
            activeStudyGroupsCount: studyGroupsCount(
              startDate: $startingAt
              endDate: $endingAt
              active: true
            )
            groupAttendance: attendance(
              startingAt: $startingAt
              endingAt: $endingAt
              groupRate: true
            )
            attendance(startingAt: $startingAt, endingAt: $endingAt)
            noShowstudyGroupsCount: studyGroupsCount(
              startDate: $startingAt
              endDate: $endingAt
              noShow: true
            )
          }
        }
      }
    }
  }
`

export default SchoolsPage
