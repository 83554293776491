import React, { useState } from "react"

import { gql } from "urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import ImportOptions from "./ImportOptions"

const ImportModal = ({ id, cleverDistrictId, classlinkDistrictId }) => {
  const providerOptions = []
  if (cleverDistrictId)
    providerOptions.push({ value: "Clever", displayName: "Clever" })
  if (classlinkDistrictId)
    providerOptions.push({ value: "Classlink", displayName: "ClassLink" })

  const [provider, setProvider] = useState()
  const [providerInfo, setProviderInfo] = useState({})

  const handleProviderChoice = choice => {
    setProvider(choice)
    switch (choice) {
      case "Clever": {
        setProviderInfo({
          mutation: cleverImportMutation,
          providerDisplayName: "Clever",
        })
        break
      }
      case "Classlink": {
        setProviderInfo({
          mutation: classlinkImportMutation,
          providerDisplayName: "ClassLink",
        })
        break
      }
    }
  }

  return (
    <ModalWithProvidedBody
      modalTitle={provider ? `Import from ${provider}` : "Choose Import Source"}
      buttonText="External User Import"
      buttonClassName="btn"
      afterClose={() => setProvider()}
    >
      {({ closeModal }) =>
        provider ? (
          <ImportOptions
            organizationId={id}
            mutation={providerInfo.mutation}
            provider={provider}
            providerDisplayName={providerInfo.providerDisplayName}
            closeModal={closeModal}
          />
        ) : (
          <div className="m-3">
            Please select where you would like to import from:
            <div className="flex flex-col items-center">
              {providerOptions.map(option => (
                <button
                  type="button"
                  className="btn btn-info m-2 w-min"
                  key={option.value}
                  onClick={() => handleProviderChoice(option.value)}
                >
                  {option.displayName}
                </button>
              ))}
            </div>
          </div>
        )
      }
    </ModalWithProvidedBody>
  )
}

const classlinkImportMutation = gql`
  mutation ($organizationId: ID!) {
    organizationClasslinkImport(organizationId: $organizationId) {
      failures {
        message
      }
      redirectTo
    }
  }
`

const cleverImportMutation = gql`
  mutation ($organizationId: ID!) {
    organizationCleverImport(organizationId: $organizationId) {
      failures {
        message
      }
      redirectTo
    }
  }
`

export default ImportModal
