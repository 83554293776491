import React from "react"

import { red } from "styles/colors"

import { css } from "@emotion/core"

const ErrorMessage = ({ touched = false, error = undefined }) => {
  if (!error) return null

  const message =
    typeof error === "string"
      ? error
      : typeof error[0] === "string"
      ? error.join(", ")
      : Array.isArray(error)
      ? error.map(e => e && Object.values(e).join(", ")).join(", ")
      : Object.values(error).join(", ")

  return (
    <div className="form-error">
      {touched && error && (
        <div
          css={css`
            display: inline-block;
            margin: 10px 0;
            border: 1px solid ${red};
            border-radius: 6px;
            padding: 5px 10px;
            color: ${red};
          `}
        >
          {message}
        </div>
      )}
    </div>
  )
}

export default ErrorMessage
