import React from "react"

import { ExclamationCircleIcon } from "@heroicons/react/20/solid"

export type ErrorIconProps = Record<string, never>

const ErrorIcon: React.FC<ErrorIconProps> = () => {
  return (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <ExclamationCircleIcon
        className="h-5 w-5 text-red-500"
        aria-hidden="true"
      />
    </div>
  )
}

export default ErrorIcon
