import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import TutorRow from "./TutorRow"

const UnobservedTutors = () => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      tutorsQuery,
      { page },
      {
        onMutation: [
          {
            when: "tutorMarkForObservation",
            run: ({ softReset, currentResults }, response) => {
              const { tutor } = response.tutorMarkForObservation
              if (!tutor) return

              currentResults.tutors.data = currentResults.tutors.data.filter(
                t => t.id !== tutor.id
              )
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <>
      <WithLoadingIndicator loadingState={loadingState}>
        {({ data }) =>
          data.tutors.data.length === 0 ? (
            <p className="lead">No tutors found</p>
          ) : (
            <>
              <table className="table">
                <thead className="text-primary">
                  <tr>
                    <th>Tutor</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.tutors.data.map(tutor => (
                    <TutorRow key={tutor.id} tutor={tutor} />
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={data.tutors.currentPage}
                totalPages={data.tutors.totalPages}
                changePage={setPage}
              />
            </>
          )
        }
      </WithLoadingIndicator>
    </>
  )
}

const tutorsQuery = compress`
  query($page: Int!) {
    tutors: unobservedTutors(page: $page) {
      currentPage
      totalPages
      data {
        id
        fullName
        showPath
        airtutorsSessions(statusState: scheduled, future: true) {
          data {
            id
            startsAt
            students {
              id
              fullName
            }
            subject {
              name
            }
            organization {
              name
            }
            studyGroup {
              name
            }
          }
        }
      }
    }
  }
`

export default UnobservedTutors
