import { compress } from "micro-graphql-react"

export default compress`
  query($organizationId: ID!) {
    organization(id: $organizationId) {
      semesters {
        id
        name
        startsOn
        endsOn
      }
    }
  }
`
