import React from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CourseCluster = ({ courseCluster, setSelectedCourseCluster }) => {
  return (
    <div>
      <h3>{courseCluster.name}</h3>
      <dl>
        <dt>Subject</dt>
        <dd>{courseCluster.subject.name}</dd>

        <dt>Description</dt>
        <dd>
          <p
            dangerouslySetInnerHTML={{
              __html: courseCluster.formattedDescription,
            }}
          />
        </dd>

        <dt>Paid for by your school?</dt>
        <dd>
          {courseCluster.splitBill ? (
            <FontAwesomeIcon icon={faTimes} className="red-text" />
          ) : (
            <FontAwesomeIcon icon={faCheck} className="green-text" />
          )}
        </dd>
      </dl>

      <button
        onClick={() => setSelectedCourseCluster(courseCluster)}
        className="btn solid green"
      >
        View Options
      </button>
      <hr />
    </div>
  )
}

export default CourseCluster
