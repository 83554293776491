import React from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { useRemoteTableData, useSubjectOptions } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const AssessmentScoreTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/assessment_scores",
  })
  const subjectOptions = useSubjectOptions()

  let columns = [
    {
      id: "user",
      Header: "Student",
      accessor: "user",
      Cell: props => (
        <a href={props.value.show_path}>{props.value.full_name}</a>
      ),
      Filter: ({ onChange }) => (
        <AutocompleteSelect
          onChange={onChange}
          api="/api/admins/students/autocomplete_full_name"
        />
      ),
    },
    {
      id: "score",
      Header: "Score",
      accessor: "score",
      filterable: false,
    },
    {
      id: "taken_on",
      Header: "Taken On",
      accessor: "taken_on",
      Cell: props =>
        props.value ? (
          <LocalTime timestamp={props.value} omitTime />
        ) : (
          "Unknown"
        ),
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            onChange({
              takenOnStart: startDate?.toDate(),
              takenOnEnd: endDate?.toDate(),
            })
          }}
        />
      ),
    },
    {
      id: "assessment",
      Header: "Assessment",
      accessor: "assessment.name",
      Filter: ({ onChange }) => (
        <AutocompleteSelect
          onChange={onChange}
          api="/api/admins/assessments/autocomplete_name"
        />
      ),
    },
    {
      id: "assessment_section",
      Header: "Section",
      accessor: "assessment_section.name",
      sortable: false,
      filterable: false,
    },
    {
      id: "subject",
      Header: "Subject",
      accessor: "assessment_section.subject.name",
      Filter: ({ onChange, filter }) => (
        <Select
          onChange={event => (event ? onChange(event.value) : onChange(""))}
          setValue={filter ? filter.value : "all"}
          options={subjectOptions}
          isClearable
        />
      ),
    },
  ]

  return (
    <>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        manual
        filterable
      />
    </>
  )
}

export default AssessmentScoreTable
