import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import Select from "react-select"

import { studentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

type Subject = {
  id: number
  name: string
}

type TeacherSubject = {
  id: number
  subject: Subject
}

type Teacher = {
  id: number
  fullName: string
  lastName: string
  teacherSubjects: [TeacherSubject]
}

type AddTeacherSubjectProps = {
  teachers: [Teacher]
}

const AddTeacherSubject: React.FC<AddTeacherSubjectProps> = ({ teachers }) => {
  const [adding, setAdding] = useState(false)
  const [teacher, setTeacher] = useState<Teacher>()
  const [errors, setErrors] = useState("")
  const { runMutation } = useMutation(
    buildMutation(addTeacherSubjectMutation, { client })
  )

  const addTeacherSubject = teacherSubjectId => {
    runMutation({ teacherSubjectId })
      .then(
        response => {
          const { errorMessages } = response.teacherSubjectAdd
          if (errorMessages) {
            setErrors(errorMessages)
          } else {
            setAdding(false)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  if (!adding) {
    return (
      <button className="btn solid green" onClick={() => setAdding(true)}>
        Add Teacher
      </button>
    )
  }

  return (
    <>
      {errors && <div className="alert">{errors}</div>}
      <div className="d-flex">
        <Select
          placeholder="Select Teacher"
          options={ordered(teachers, "lastName")}
          getOptionLabel={option => option.fullName}
          getOptionValue={option => option}
          onChange={selectedOption => setTeacher(selectedOption)}
          styles={{
            container: provided => ({
              ...provided,
              width: 250,
            }),
          }}
        />

        {teacher && (
          <div className="ml-3">
            <Select
              placeholder="Select Subject"
              options={ordered(teacher.teacherSubjects, "subject", "name")}
              getOptionLabel={option => option.subject.name}
              getOptionValue={option => option.id}
              onChange={selectedOption => addTeacherSubject(selectedOption.id)}
              styles={{
                container: provided => ({
                  ...provided,
                  width: 250,
                }),
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

const addTeacherSubjectMutation = compress`
  mutation($teacherSubjectId: ID!) {
    teacherSubjectAdd(teacherSubjectId: $teacherSubjectId) {
      errorMessages
      teacherSubject {
        id
        teacher {
          id
          fullName
          lastName
        }
        subject {
          id
          name
        }
      }
    }
  }
`

export default AddTeacherSubject
