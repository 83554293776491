import React from "react"

import Layer from "components/Pages/Layer"
import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"

import EditPasswordForm from "./EditPasswordForm"

const EditPassword = props => {
  return (
    <Layer>
      <ErrorBoundary name="editPassword">
        <EditPasswordForm />
      </ErrorBoundary>
    </Layer>
  )
}

export default EditPassword
