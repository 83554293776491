import React from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Question from "./TailoredQuestionReview"

export type TailoredSectionReviewProps = {
  studentId: string
  tailoredSectionId: string
}

const TailoredSectionReview: React.FC<TailoredSectionReviewProps> = ({
  studentId,
  tailoredSectionId,
}) => {
  const [result] = useQuery({
    query,
    variables: { tailoredSectionId, studentId },
    client: useClient(),
  })

  const findStudentAnswer = (section, question) => {
    return section.tailoredStudentAnswers.find(
      studentAnswer => studentAnswer.tailoredQuestion.id === question.id
    )
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { tailoredSection, student } }) =>
        tailoredSection && student ? (
          <>
            <h1>
              Review {tailoredSection.name} Answers for {student.fullName}
            </h1>

            {tailoredSection.tailoredQuestions.map(question => (
              <Question
                key={question.id}
                question={question}
                studentAnswer={findStudentAnswer(tailoredSection, question)}
              />
            ))}

            {tailoredSection.tailoredQuestionGroups.map(
              group =>
                group.tailoredQuestions.length > 0 && (
                  <>
                    <div id={`group-${group.id}`}>
                      <p className="text-xl font-semibold">
                        The next {group.tailoredQuestions.length} questions will
                        reference the following image(s).
                      </p>
                      <div className="flex space-x-4">
                        {group.diagrams.map(diagram => (
                          <img
                            className="h-80"
                            key={diagram.url}
                            src={diagram.url}
                            alt={diagram.filename}
                          />
                        ))}
                      </div>
                    </div>
                    {group.tailoredQuestions.map(question => (
                      <Question
                        key={question.id}
                        question={question}
                        scrollTo={`group-${group.id}`}
                        studentAnswer={findStudentAnswer(
                          tailoredSection,
                          question
                        )}
                      />
                    ))}
                  </>
                )
            )}
          </>
        ) : (
          "Not Found"
        )
      }
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query ($tailoredSectionId: ID!, $studentId: ID!) {
    student(id: $studentId) {
      id
      fullName
    }
    tailoredSection(id: $tailoredSectionId) {
      id
      name
      tailoredStudentAnswers(studentId: $studentId) {
        id
        correct
        tailoredQuestion {
          id
        }
        tailoredAnswer {
          id
        }
      }
      tailoredQuestionGroups {
        id

        diagrams {
          url
          filename
        }
        tailoredQuestions {
          id
          type
          text
          image {
            url
            filename
          }
          diagram {
            url
            filename
          }
          tailoredAnswers {
            id
            type
            text
            correct
            image {
              url
              filename
            }
          }
        }
      }
      tailoredQuestions {
        id
        type
        text
        image {
          url
          filename
        }
        diagram {
          url
          filename
        }
        tailoredAnswers {
          id
          type
          text
          correct
          image {
            url
            filename
          }
        }
      }
    }
  }
`

export default TailoredSectionReview
