import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
})

const NewLessonPlanButton = () => {
  const { runMutation } = useMutation(buildMutation(createLessonPlanMutation))

  const formState = {
    name: "",
  }

  const handleSubmit = (values, actions) => {
    runMutation({ name: values.name }).then(r => {
      window.location = r.createLessonPlan.lessonPlan.showPath
    })
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-success"
      buttonText="New Lesson Plan"
      modalClassName="bootstrap-modal"
      modalTitle="New Lesson Plan"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field className="form-control" name="name" />
                    <ErrorMessage name="name" />
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <SubmitButton isSubmitting={isSubmitting} text="Create" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createLessonPlanMutation = compress`
  mutation($name: String!) {
    createLessonPlan(name: $name) {
      lessonPlan {
        showPath
      }
    }
  }
`

export default NewLessonPlanButton
