import { compress } from "micro-graphql-react"

export default compress`
  mutation($id: ID!) {
    publishTutor(id: $id) {
      published
      errorMessage
    }
  }
`
