import React from "react"

import { useField } from "formik"

import { RadioField } from "components/Forms/Formik/hookComponents"

const DurationSelection = ({ courses, nextStep }) => {
  const [durationField] = useField("durationWeeks")
  const durations = courses.map(course => course.durationWeeks)
  const uniqDurations = durations
    .filter((duration, index) => durations.indexOf(duration) === index)
    .sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    }) // default js sort does bs lexical sort instead of numeric

  const handleNextStep = () => {
    nextStep()
  }

  return (
    <>
      <RadioField
        name="durationWeeks"
        label="Select Program Duration"
        options={uniqDurations.map(duration => ({
          value: duration,
          label: `${duration} weeks`,
        }))}
      />
      <button
        type="button"
        onClick={handleNextStep}
        disabled={durationField.value === ""}
        className="btn solid blue"
      >
        Next: Schedule Selection
      </button>
    </>
  )
}

export default DurationSelection
