import React, { useState } from "react"

import { FormikContextType, FormikValues, useFormikContext } from "formik"

import { gql } from "@urql/core"

import client from "src/urql-client"

import { useQuery } from "hooks/urql"

import { ErrorMessage } from "components/Forms/Formik"
import {
  AutocompleteField,
  CheckboxField,
  FormStatus,
  RadioField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import { Loading } from "components/Icons"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const CourseClusterResults = ({
  variables,
  warningMessage,
  setWarningMessage,
  errors,
  page,
  setPage,
  resetForm,
}) => {
  const { setFieldValue, values }: FormikContextType<FormikValues> =
    useFormikContext()
  const [selectAllInProgress, setSelectAllInProgress] = useState(false)

  const input = {
    organizationId: variables.organizationId,
    schoolIds: variables.schoolIds,
    subjectIds: variables.subjectIds,
    gradeId: variables.gradeId,
  }

  const [result] = useQuery({
    query: courseClustersQuery,
    variables: { page: page, input },
  })

  const selectAll = async () => {
    resetForm()
    setSelectAllInProgress(true)
    let currentPage = 1
    let allGroupIds = []
    while (currentPage <= result.data.courseClusters.totalPages) {
      const results = await client
        .query(courseClustersQuery, {
          page: currentPage,
          input,
        })
        .toPromise()
      allGroupIds = allGroupIds.concat(
        results.data.courseClusters.data.map(courseCluster => courseCluster.id)
      )
      currentPage++
    }
    setFieldValue("resourceIds", allGroupIds)
    setSelectAllInProgress(false)
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { courseClusters } }) => {
        return courseClusters.data.length < 1 ? (
          <span className="font-italic">
            No course clusters containing study groups found with the current
            filters.
          </span>
        ) : (
          <>
            <ul className="list-unstyled">
              {courseClusters.data.map(courseCluster => (
                <li key={courseCluster.id}>
                  <CheckboxField
                    hideError
                    name="resourceIds"
                    value={courseCluster.id}
                    label={
                      <>
                        <div className="grid w-full grid-cols-3">
                          <dl className="mr-8">
                            <dt>Name</dt>
                            <dd>
                              <a
                                href={courseCluster.showPath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {courseCluster.name}
                              </a>
                            </dd>
                            <dt>Description</dt>
                            <dd>{courseCluster.description}</dd>
                            <dt>Organization</dt>
                            <dd>{courseCluster.organization?.name}</dd>
                            <dt>School</dt>
                            <dd>{courseCluster.school?.name}</dd>
                          </dl>
                          <dl>
                            <dt>Subject</dt>
                            <dd>{courseCluster.subject.name}</dd>
                            <dt>Grades</dt>
                            <dd>
                              {courseCluster.grades
                                .map(grade => grade.name)
                                .join(", ")}
                            </dd>
                          </dl>
                          <dl>
                            <dt>Study Groups</dt>
                            <dd>
                              <ul className="list-unstyled">
                                {courseCluster.studyGroups.map(studyGroup => (
                                  <li key={studyGroup.id}>
                                    <a
                                      href={studyGroup.showPath}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {studyGroup.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </dd>
                          </dl>
                        </div>
                        <hr />
                      </>
                    }
                  />
                </li>
              ))}
            </ul>
            <ErrorMessage name="resourceIds" />

            <Pagination
              currentPage={courseClusters.currentPage}
              totalPages={courseClusters.totalPages}
              changePage={setPage}
            />

            <div className="d-flex">
              <button
                onClick={selectAll}
                type="button"
                className="btn btn-outline-info"
                disabled={selectAllInProgress}
              >
                Select All
                {selectAllInProgress && (
                  <span className="ml-3">
                    <Loading />
                  </span>
                )}
              </button>

              <button
                onClick={() => setFieldValue("resourceIds", [])}
                type="button"
                className="btn btn-outline-danger ml-3"
              >
                De-select All
              </button>
            </div>

            <div className="alert alert-info">
              {values.resourceIds.length} groups currently selected
            </div>

            <FormStatus />
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger mb-3 mt-3 p-2">
                <div className="ml-3">
                  <p>{error.message}</p>
                </div>
              </div>
            ))}

            <h5>Choose an Action</h5>
            <RadioField
              name="action"
              options={[
                { label: "Add Tags", value: "tag" },
                { label: "Assign Budget", value: "assign_budget" },
              ]}
            />

            {values.action === "tag" && (
              <>
                <AutocompleteField
                  name="tags"
                  label="Tags"
                  api="/api/admins/study_groups/autocomplete_tags"
                  isMulti
                  creatable
                  onChange={selected => setFieldValue("tags", selected)}
                />
              </>
            )}

            {values.action === "assign_budget" && (
              <>
                <AutocompleteField
                  name="budgetId"
                  label="Budget"
                  api="/api/admins/budgets/autocomplete_name"
                  onChange={selected =>
                    setFieldValue("budgetId", selected.value)
                  }
                />
              </>
            )}

            {warningMessage && (
              <CheckboxField
                name="performAction"
                label={
                  <div className="alert alert-warning font-semibold text-black">
                    {warningMessage}
                  </div>
                }
              />
            )}

            {values.action && (
              <SubmitButton
                text={
                  values.action === "tag"
                    ? "Add Tags"
                    : values.action === "assign_budget"
                    ? "Add Budgets"
                    : "Invalid Action"
                }
                className="btn btn-danger"
              />
            )}
          </>
        )
      }}
    </UrqlLoadingIndicator>
  )
}

const courseClustersQuery = gql`
  query ($page: Int!, $input: CourseClusterSearchInputObject!) {
    courseClusters(page: $page, input: $input) {
      currentPage
      totalPages
      data {
        id
        name
        description
        showPath
        organization {
          id
          name
        }
        school {
          id
          name
        }
        grades {
          id
          name
        }
        subject {
          id
          name
        }
        studyGroups {
          id
          name
          showPath
        }
      }
    }
  }
`

export default CourseClusterResults
