import { useEffect, useState } from "react"

import { singletonHook } from "react-singleton-hook"

import axios from "src/axios"

const useSubjectBucketOptions = () => {
  const [subjectBucketOptions, setSubjectBucketOptions] = useState([])

  useEffect(() => {
    axios.get("/api/admins/subject_buckets").then(response => {
      const json = response.data,
        subjectBucketOptions = []
      json.data.forEach(subjectBucket => {
        let arrayIndex = subjectBucketOptions.findIndex(
          h => h.label === subjectBucket.tierName
        )
        if (arrayIndex < 0) {
          subjectBucketOptions.push({
            label: subjectBucket.tierName,
            options: [],
          })
          arrayIndex = subjectBucketOptions.length - 1
        }
        const optionGroup = subjectBucketOptions[arrayIndex]
        optionGroup.options.push(subjectBucket)
        subjectBucketOptions[arrayIndex] = optionGroup
      })
      setSubjectBucketOptions(subjectBucketOptions)
    })
  }, [])

  return subjectBucketOptions
}

export default singletonHook([], useSubjectBucketOptions)
