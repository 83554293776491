import React, { useRef } from "react"

import ReactTable from "react-table"

import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { formatDate } from "src/date-helpers"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import StatementSettings from "components/Admin/Organization/Statements/Settings"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const OrganizationBudgets = ({
  apiEndpoint,
  byOrganization,
  organizationId,
  newBudgetPath,
  statementsPath,
  statementConfigurationsPath,
}) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint,
  })
  const table = useRef(null)
  const { exportToCSV } = useExportable({
    apiEndpoint,
    tableRef: table.current,
  })

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      filterable: false,
      Cell: props => (
        <span>
          {props.original.active && (
            <FontAwesomeIcon icon={faCheck} className="ml-3 text-green-600" />
          )}
          {props.value}
        </span>
      ),
    },
    {
      id: "starts_on",
      Header: "Budget Start Date",
      accessor: "starts_on",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ startsOnStart: null, startsOnEnd: null })
              return
            }

            onChange({
              startsOnStart: formatDate(startDate.toDate()),
              startsOnEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "ends_on",
      Header: "Budget End Date",
      accessor: "ends_on",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ endsOnStart: null, endsOnEnd: null })
              return
            }

            onChange({
              endsOnStart: formatDate(startDate.toDate()),
              endsOnEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "original_amount",
      Header: "Original Dollar Amount",
      accessor: "original_amount",
      filterable: false,
    },
    {
      id: "balance",
      Header: "Current Balance",
      accessor: "balance",
      filterable: false,
      Cell: props =>
        props.value < 0 ? (
          <span className="text-red-500">
            {props.original.formatted_balance}
          </span>
        ) : (
          props.original.formatted_balance
        ),
    },
    {
      id: "po_number",
      Header: "PO Number",
      accessor: "po_number",
      filterable: false,
    },
  ]

  if (!byOrganization) {
    columns.push({
      id: "payer_id",
      Header: "Organization",
      accessor: "payer",
      filterable: false,
      Cell: props => <a href={props.value.show_path}>{props.value.name}</a>,
      visible: false,
      isVisible: false,
      Visible: false,
    })
  }
  columns.push({
    id: "links",
    Header: "Actions",
    accessor: "links",
    filterable: false,
    sortable: false,
    Cell: props => (
      <a className="btn btn-sm btn-info" href={props.value.show_path}>
        Details
      </a>
    ),
  })

  return (
    <>
      {newBudgetPath && (
        <div>
          <a href={newBudgetPath} className="btn btn-success">
            New Budget
          </a>
          <StatementSettings organizationId={organizationId} />
          <a href={statementConfigurationsPath} className="btn btn-info">
            Statement Configurations
          </a>
        </div>
      )}

      <div>
        <button type="button" onClick={exportToCSV} className="btn">
          CSV Export
        </button>
        <a className="btn btn-info" href={statementsPath}>
          View Statements
        </a>
      </div>

      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default OrganizationBudgets
