import React, { ReactNode } from "react"

import { Dialog } from "@headlessui/react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import classNames from "src/classNames"

export type ModalBodyProps = {
  children: ReactNode
  title: string
  Icon?: React.FC<any>
  iconClassNames?: string
}

const ModalBody = ({
  title,
  children,
  Icon = InformationCircleIcon,
  iconClassNames = "text-blue-400",
}) => {
  return (
    <div className="max-h-[calc(70vh-100px)] overflow-y-scroll bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto -mt-[3px] flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
          <Icon
            className={classNames(iconClassNames, "h-6 w-6")}
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="mt-1 text-base font-semibold leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalBody
