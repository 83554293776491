import React from "react"

import { useField } from "formik"

import ordered from "src/ordered"

import { SubmitButton } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

import AddPaymentMethod from "../AddPaymentMethod"
import ExistingPaymentMethods from "../ExistingPaymentMethods"

const Review = ({ courses, viewer }) => {
  const [courseSectionField] = useField("courseSectionId")
  const course = courses.find(course =>
    course.studentRegisterableCourseSections
      .map(cs => cs.id)
      .includes(courseSectionField.value)
  )
  const courseSection = course.studentRegisterableCourseSections.find(
    cs => cs.id === courseSectionField.value
  )

  return (
    <>
      <dl>
        <dt>Course</dt>
        <dd>{course.courseCluster.name}</dd>

        <dt>Description</dt>
        <dd>
          <span
            dangerouslySetInnerHTML={{
              __html: course.courseCluster.formattedDescription,
            }}
          />
        </dd>

        <dt>Subject</dt>
        <dd>{course.subject.name}</dd>

        <dt>Duration</dt>
        <dd>{course.durationWeeks} Weeks</dd>

        <dt>Schedule</dt>
        <dd>
          <div className="font-medium">
            <LocalTime timestamp={courseSection.startsOn} omitTime /> &mdash;{" "}
            <LocalTime timestamp={courseSection.endsOn} omitTime />
          </div>
          <ul className="m-0">
            {ordered(courseSection.availabilities, "weekdayNumber").map(
              availability => (
                <div key={availability.id}>
                  <div>{availability.weekday}s</div>
                  <div className="ml-3 italic">
                    <LocalTime timestamp={availability.startsAt} omitDate />{" "}
                    &mdash;{" "}
                    <LocalTime timestamp={availability.endsAt} omitDate />
                  </div>
                </div>
              )
            )}
          </ul>
        </dd>

        <dt>Price</dt>
        <dd>{course.formattedPrepaidPrice}</dd>
      </dl>

      {viewer.stripePaymentMethods.length > 0 ? (
        <ExistingPaymentMethods viewer={viewer} />
      ) : (
        <AddPaymentMethod />
      )}

      <SubmitButton text="Purchase" />
    </>
  )
}

export default Review
