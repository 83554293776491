import React, { useRef } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { conversationTypeOptions, proxyStateOptions } from "src/enums"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import "react-table/react-table.css"

const AdminTwilioProxySessionsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/twilio_proxy_sessions",
  })
  const tutorFullNameSelect = useRef(null)
  const studentFullNameSelect = useRef(null)
  const clearableSelects = [
    tutorFullNameSelect.current,
    studentFullNameSelect.current,
  ]

  const columns = [
    {
      id: "tutor",
      Header: "Tutor Name",
      accessor: "tutor",
      sortable: false,
      Cell: props =>
        props.value ? (
          <a href={props.value.href}>{props.value.full_name}</a>
        ) : (
          ""
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={tutorFullNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api={
              "/api/admins/twilio_proxy_sessions/autocomplete_tutor_full_name"
            }
          />
        )
      },
    },
    {
      id: "student",
      Header: "Student Name",
      accessor: "student",
      sortable: false,
      Cell: props =>
        props.value ? (
          <a href={props.value.href}>{props.value.full_name}</a>
        ) : (
          ""
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={studentFullNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api={
              "/api/admins/twilio_proxy_sessions/autocomplete_student_full_name"
            }
          />
        )
      },
    },
    {
      Header: "Status",
      accessor: "aasm_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => onChange(event.value)}
            setValue={filter ? filter.value : "all"}
            options={proxyStateOptions}
          />
        )
      },
    },
    {
      Header: "Conversation Type",
      accessor: "conversation_type",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => onChange(event.value)}
            setValue={filter ? filter.value : "all"}
            options={conversationTypeOptions}
          />
        )
      },
    },
    {
      Header: "Phone Search",
      accessor: "proxy_identifier",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <div className="form-group" style={{ margin: 0 }}>
            <input
              type="text"
              className="form-control"
              onChange={e => {
                onChange(e.currentTarget.value)
              }}
            />
          </div>
        )
      },
      Cell: props => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {props.value && props.value.tutor && (
            <div>
              Tutor Dials:
              <br />
              {props.value.tutor}
            </div>
          )}
          {props.value && props.value.student && (
            <div>
              Student Dials:
              <br />
              {props.value.student}
            </div>
          )}
          {props.value && props.value.parent && (
            <div>
              Parent Dials:
              <br />
              {props.value.parent}
            </div>
          )}
        </div>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminTwilioProxySessionsTable
