import React, { useState } from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"
import { tutorClient } from "src/graphql-config"

import { DateObject } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import { SessionDateField } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

import StudentSubjects from "./StudentSubjects"
import StudyGroupSubjects from "./StudyGroupSubjects"
import TutorSubjects from "./TutorSubjects"

const ValidationSchema = Yup.object().shape({
  subjectId: Yup.string().nullable().required("Please select a subject"),
  sessionDate: Yup.number().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

const ScheduleSession = ({ subjectId, startsAt, studyGroup, student }) => {
  const [errors, setErrors] = useState([])

  const sessionDate = startsAt
    ? new DateObject(new Date(startsAt))
    : new DateObject().setHour(12).setMinute(0).setSecond(0)

  const formState = {
    subjectId: subjectId,
    sessionDate: sessionDate,
    durationMinutes: 60,
    studyGroupId: studyGroup?.id,
    studentIds: [student?.id],
  }

  const { runMutation } = useMutation(
    buildMutation(scheduleSessionMutation, { client: tutorClient })
  )

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values
    runMutation({ sessionDate: sessionDate.toDate(), ...rest })
      .then(
        response => {
          const { success, failures } = response.scheduleAirtutorsSession
          if (success) {
            window.location = "/"
          } else {
            actions.setStatus("Failed to schedule session")
            setErrors(failures)
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          airbrake.notify({ error, name: "RescheduleSession" })
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        airbrake.notify({ error, name: "RescheduleSession" })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div>
              <label>Subject</label>
              {studyGroup?.id ? (
                <StudyGroupSubjects
                  studyGroupId={studyGroup.id}
                  onChange={selected => setFieldValue("subjectId", selected.id)}
                  subjectId={subjectId}
                />
              ) : student?.id ? (
                <StudentSubjects
                  studentId={student.id}
                  onChange={selected => setFieldValue("subjectId", selected.id)}
                  subjectId={subjectId}
                />
              ) : (
                <TutorSubjects
                  onChange={selected => setFieldValue("subjectId", selected.id)}
                  subjectId={subjectId}
                />
              )}
              <ErrorMessage name="subjectId" />
            </div>

            <div
              css={css`
                margin: 30px 0;
              `}
              className="d-flex justify-content-between"
            >
              <SessionDateField
                value={values.sessionDate}
                name="sessionDate"
                label="Session Date"
              />

              <div>
                <label>
                  Duration in minutes
                  <Field
                    name="durationMinutes"
                    type="number"
                    min="15"
                    step="15"
                  />
                </label>
                <ErrorMessage name="durationMinutes" />
              </div>
            </div>

            {status && <div className="alert mt-3 p-2">{status}</div>}

            {errors.map((error, index) => (
              <div key={index} className="alert mt-3 p-2">
                <p>{error.message}</p>
                {error.conflictingSessions && (
                  <ul>
                    {error.conflictingSessions.map(session => (
                      <li key={session.id}>
                        <a
                          href={session.showPath}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <LocalTime timestamp={session.startsAt} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            <SubmitButton isSubmitting={isSubmitting} text="Schedule" />
          </Form>
        )}
      </Formik>
    </>
  )
}

const scheduleSessionMutation = compress`
  mutation(
    $subjectId: ID!
    $studyGroupId: ID
    $studentIds: [ID!]
    $sessionDate: DateTime!
    $durationMinutes: Int!
  ) {
    scheduleAirtutorsSession(
      subjectId: $subjectId
      studyGroupId: $studyGroupId
      studentIds: $studentIds
      sessionDate: $sessionDate
      durationMinutes: $durationMinutes
    ) {
      success
      failures {
        message
        conflictingSessions {
          id
          startsAt
          showPath
        }
      }
    }
  }
`

export default ScheduleSession
