import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import { Field, handleFailure } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody, { Footer } from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  text: Yup.string().required("Question text is required"),
})

type EditQuestionProps = {
  question: {
    id: number
    text: string
  }
}

const EditQuestion: React.FC<EditQuestionProps> = ({ question }) => {
  const formState = { id: question.id, text: question.text }
  const [, updateQuestion]: any[] = useMutation(updateQuestionMutation)

  const handleSubmit = (values, actions, closeModal) => {
    updateQuestion(values)
      .then(
        result => {
          const { failures } = result.data.updateSurveyQuestion
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Question updated successfully")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      buttonText="Edit"
      buttonClassName="btn btn-info"
      modalTitle="Edit Survey Question"
      modalClassName="bootstrap-modal"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <Field name="text" label="Question Text" autoFocus />
            </div>

            <Footer cancelAction={closeModal} submitText="Update Question" />
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateQuestionMutation = gql`
  mutation UpdateSurveyQuestion($id: ID!, $text: String!) {
    updateSurveyQuestion(id: $id, text: $text) {
      question {
        id
        text
      }
      failures {
        message
      }
    }
  }
`

export default EditQuestion
