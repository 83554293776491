import React from "react"

import { Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { tutorClient } from "src/graphql-config"

import { SubmitButton } from "components/Forms/Formik"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Evaluation from "./Evaluation"

const EditEvaluation = ({ airtutorsSessionId }) => {
  const loadingState = useQuery(
    buildQuery(
      airtutorsSessionQuery,
      { airtutorsSessionId },
      { client: tutorClient }
    )
  )
  const { runMutation } = useMutation(
    buildMutation(updateEvaluationMutation, { client: tutorClient })
  )

  const formState = {
    lessonResourceCompletions: {},
  }

  if (loadingState.data) {
    const resourceCompletions = {}
    loadingState.data.airtutorsSession.lessonResourceCompletions.forEach(
      completion => {
        resourceCompletions[completion.id] = {
          id: completion.id,
          tutorMastery: completion.tutorMastery,
          tutorTopicMastery: completion.tutorTopicMastery,
        }
      }
    )
    formState.lessonResourceCompletions = resourceCompletions
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      airtutorsSessionId,
      lessonResourceCompletions: Object.values(
        values.lessonResourceCompletions
      ),
    })
      .then(
        r => {
          const { errorMessage, airtutorsSession } =
            r.updateLessonResourceCompletions
          if (errorMessage) {
            actions.setStatus(errorMessage)
            actions.setSubmitting(false)
          } else {
            window.location = airtutorsSession.showPath
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ values, setFieldValue, isSubmitting, status }) => (
            <Form>
              <Evaluation
                data={data}
                values={values}
                setFieldValue={setFieldValue}
              />

              <div className="text-center">
                {status && <div className="alert mt-3">{status}</div>}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Update Evaluation"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

const airtutorsSessionQuery = compress`
  query($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      lessonResourceCompletions {
        id
        tutorMastery
        tutorTopicMastery
        lessonResource {
          name
          sortOrder
        }
        lessonTopic {
          sortOrder
        }
        student {
          fullName
        }
        completedLessonTopic {
          id
          name
          sortOrder
        }
      }
    }
  }
`

const updateEvaluationMutation = compress`
  mutation($airtutorsSessionId: ID!, $lessonResourceCompletions: [LessonResourceCompletionInputObject!]!) {
    updateLessonResourceCompletions(airtutorsSessionId: $airtutorsSessionId, lessonResourceCompletions: $lessonResourceCompletions) {
      errorMessage
      airtutorsSession {
        showPath
      }
    }
  }
`

export default EditEvaluation
