import React from "react"

const BasicDisplay = ({ label, labelClassName, value, children }) => (
  <div className={`my-3 ${labelClassName}`}>
    <div className="font-medium">{label}</div>
    <div>{children || value}</div>
  </div>
)

export default BasicDisplay
