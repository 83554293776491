import React, { useRef, useState } from "react"

import { motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import { pipe, subscribe } from "wonka"

import ordered from "src/ordered"

import { gql, useClient } from "hooks/urql"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

const Users = ({
  users,
  addMutation,
  removeMutation,
  studyGroupId,
  title,
  autocompleteProps,
  includeAddToAllSessionsButton,
  requestConfirmations,
}) => {
  const selectRef = useRef(null)
  const [error, setError] = useState({})
  const [enqueued, setEnqueued] = useState({})
  const client = useClient()

  const { runMutation } = useMutation(buildMutation(addMutation))
  const { runMutation: runConfirmStudentMutation } = useMutation(
    buildMutation(confirmStudentMutation)
  )

  const addToAllSessions = student => {
    const { unsubscribe } = pipe(
      client.subscription(addToAllSessionsSubscription, {
        studyGroupId,
        studentId: student.id,
      }),
      subscribe(result => {
        const { status, failures } =
          result.data.studyGroupAddStudentToFutureSessions

        if (status === "error") {
          setEnqueued()
          setError({
            studentId: student.id,
            message: failures[0].message,
            conflictingSessions: failures[0].conflictingSessions,
          })
          unsubscribe()
        } else if (status === "enqueued") {
          setEnqueued(student.id)
        } else if (status === "success") {
          window.location.reload()
        }
      })
    )
  }

  const confirmUser = student => {
    runConfirmStudentMutation({
      studyGroupId,
      studentId: student.id,
    }).then(() => window.location.reload())
  }

  return (
    <React.Fragment>
      <h4 className="mt-0">{title}</h4>
      <EditableList>
        {ordered(users.filter(Boolean), "lastName").map(user => (
          <motion.li
            key={user.id}
            positionTransition
            className="p-2 last:border-b-0 odd:rounded-md odd:bg-slate-200/50 even:border-x-0 even:border-solid even:border-slate-400/50"
          >
            <Item
              className="justify-content-between"
              mutation={removeMutation}
              mutationProps={{
                studyGroupId,
                userId: user.id,
              }}
            >
              <div className="flex-grow">
                <a className="flex-grow-1" href={user.showPath}>
                  {user.fullName}
                </a>

                <div>
                  {includeAddToAllSessionsButton && (
                    <button
                      onClick={() => addToAllSessions(user)}
                      className="btn btn-sm btn-info"
                      disabled={enqueued === user.id}
                    >
                      Add to Future Sessions
                    </button>
                  )}

                  {error?.studentId === user.id && (
                    <div className="alert alert-danger">
                      <p>{error?.message}</p>
                      {error?.conflictingSessions && (
                        <ul className="list-unstyled mb-2">
                          {error?.conflictingSessions.map(session => (
                            <li key={session.id}>
                              <a
                                href={session.showPath}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="text-white"
                              >
                                <LocalTime timestamp={session.startsAt} />
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}

                  {enqueued === user.id && (
                    <div className="alert alert-info">
                      Checking for conflicts...
                    </div>
                  )}

                  {requestConfirmations && !user.studyGroupUser.confirmedAt && (
                    <button
                      onClick={() => confirmUser(user)}
                      className="btn btn-sm btn-info"
                    >
                      Confirm User for Group
                    </button>
                  )}
                </div>

                {user.lessonPlans?.length > 0 && (
                  <div>
                    <label className="text-lg font-medium">Lesson Plans</label>
                    <div>
                      <ul className="list-unstyled">
                        {ordered(user.lessonPlans, "name").map(lessonPlan => (
                          <li key={lessonPlan.id}>{lessonPlan.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </Item>
          </motion.li>
        ))}
      </EditableList>
      <div className="mb-3">
        <AutocompleteSelect
          ref={selectRef}
          onChange={option => {
            if (!option?.value) return
            runMutation({ studyGroupId, userId: option.value }).then(() => {
              selectRef.current.clearValue()
            })
          }}
          withLoadingIndicator
          {...autocompleteProps}
        />
      </div>
    </React.Fragment>
  )
}

const addToAllSessionsSubscription = gql`
  subscription ($studyGroupId: ID!, $studentId: ID!) {
    studyGroupAddStudentToFutureSessions(
      studyGroupId: $studyGroupId
      studentId: $studentId
    ) {
      status
      failures {
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
    }
  }
`

const confirmStudentMutation = compress`
  mutation ($studyGroupId: ID!, $studentId: ID!) {
    studyGroupConfirmStudent(
      studyGroupId: $studyGroupId
      studentId: $studentId
      cancel: false
      shouldContact: false
    ) {
      studyGroup {
        id
      }
    }
  }
`

export default Users
