import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  phoneNumbers: Yup.string().required("Please specify at least 1 phone number"),
})

const NewRecipient = ({ batchTextId }) => {
  const formState = {
    batchTextId: batchTextId,
    phoneNumbers: "",
  }

  const { runMutation } = useMutation(buildMutation(addRecipientMutation))

  const handleSubmit = (values, actions, closeModal) => {
    const phoneNumbers = values.phoneNumbers
      .split(/[,\n]/)
      .map(s => s.trim())
      .filter(s => s !== "")
    runMutation({ batchTextId, phoneNumbers })
      .then(
        response => {
          const { errorMessages } = response.batchTextAddRecipient
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonText="Add Recipient"
      buttonClassName="btn btn-info"
      modalTitle="Add Recipient"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ status, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="w-100">
                    Phone Numbers (separate by commas or 1-per line)
                    <Field name="phoneNumbers">
                      {({ field, form }) => (
                        <textarea className="form-control" {...field} />
                      )}
                    </Field>
                  </label>
                  <ErrorMessage name="phoneNumbers" />
                </div>
              </div>

              <div
                className="modal-footer"
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                {status && (
                  <div
                    className="alert alert-danger"
                    css={css`
                      margin-top: 15px;
                    `}
                  >
                    {status}
                  </div>
                )}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Add"
                  containerCss={css`
                    margin-right: 15px;
                  `}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const addRecipientMutation = compress`
  mutation($batchTextId: ID!, $phoneNumbers: [String!]!) {
    batchTextAddRecipient(batchTextId: $batchTextId, phoneNumbers: $phoneNumbers) {
      errorMessages
      batchText {
        batchTextRecipients {
          id
          phoneNumber
          sentAt
          user {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`

export default NewRecipient
