import React from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EditableDisplay from "./EditableDisplay"

export type EditableDisplayWithResetProps = {
  value: string | boolean | number
  update: (value: any, selectedId: string) => Promise<undefined>
  reset: () => void
  source?: string
}

const EditableDisplayWithReset: React.FC<EditableDisplayWithResetProps> = ({
  value,
  update,
  reset,
  source = "organization",
}) => {
  return (
    <EditableDisplay
      displayValue={
        value === true ? (
          <>
            <FontAwesomeIcon icon={faCheck} className="text-success mr-3" />
            <button
              type="button"
              onClick={reset}
              className="btn btn-sm btn-outline-info"
            >
              Reset to {source} default
            </button>
          </>
        ) : value === false ? (
          <>
            <FontAwesomeIcon icon={faTimes} className="text-danger mr-3" />
            <button
              type="button"
              onClick={reset}
              className="btn btn-sm btn-outline-info"
            >
              Reset to {source} default
            </button>
          </>
        ) : (
          `Uses ${source} default`
        )
      }
      editableValue={!!value}
      type="checkbox"
      save={update}
    />
  )
}

export default EditableDisplayWithReset
