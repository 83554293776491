import React from "react"

export type HeaderTabsProps = {
  options: { label: string; value: string; onClick: () => void }[]
  selected: string
}

const HeaderTabs: React.FC<HeaderTabsProps> = ({ options, selected }) => {
  return (
    <div className="mb-3 flex justify-start border-x-0 border-b-2 border-t-0 border-solid border-sky-600 py-3">
      {options.map(option => (
        <button
          key={option.value}
          className={`btn btn-link mr-2 px-3 text-lg font-medium ${
            option.value === selected
              ? "bg-slate-300 !text-blue-700 focus:!bg-slate-300 focus:!text-blue-700 active:!bg-slate-300 active:!text-blue-700"
              : ""
          }`}
          type="button"
          onClick={() => option.onClick()}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}

export default HeaderTabs
