import React from "react"

function Checkbox(props) {
  const { name, value, checked, onChange, disabled, classNames } = props

  return (
    <div className={`form-check ${classNames}`}>
      <label className="form-check-label">
        {props.children}
        <input
          name={name}
          className="form-check-input"
          type="checkbox"
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="form-check-sign" />
      </label>
    </div>
  )
}

export default Checkbox
