import React from "react"

import { motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EditableDisplay from "components/Forms/EditableDisplay"
import RemoveButton from "components/RemoveButton"

import LessonResource from "./LessonResource"
import NewResource from "./NewResource"
import lessonTopicsFragment from "./lessonTopicsFragment"

const LessonTopic = ({ topic, first, last }) => {
  const { runMutation } = useMutation(buildMutation(updateLessonTopicMutation))
  const { runMutation: incrementOrder, running: incrementOrderRunning } =
    useMutation(buildMutation(incrementOrderMutation))
  const { runMutation: decrementOrder, running: decrementOrderRunning } =
    useMutation(buildMutation(decrementOrderMutation))

  const TopicName = ({ children }) => {
    const { runMutation } = useMutation(
      buildMutation(removeLessonTopicMutation)
    )

    return (
      <>
        <RemoveButton
          onClick={() => runMutation({ id: topic.id })}
          buttonCss={`
            order: initial !important;
          `}
        />
        <h4 className="d-inline-block m-0">{children}</h4>
      </>
    )
  }

  return (
    <motion.li positionTransition>
      <div className="row">
        <div className="col-6 col-md-4">
          <div>
            <EditableDisplay
              displayValue={topic.name}
              save={({ value }) => runMutation({ id: topic.id, name: value })}
              tag={TopicName}
              height="auto"
            />
          </div>
          <div>
            <button
              className="mx-2"
              onClick={() => incrementOrder({ id: topic.id })}
              disabled={last || incrementOrderRunning}
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </button>
            <button
              className="mx-2"
              onClick={() => decrementOrder({ id: topic.id })}
              disabled={first || decrementOrderRunning}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </button>
          </div>
        </div>
        <div className="col-6 col-md-8">
          <ul className="list-unstyled">
            {topic.lessonResources.map((resource, idx) => (
              <LessonResource
                resource={resource}
                key={resource.id}
                first={resource === topic.lessonResources[0]}
                last={
                  resource ===
                  topic.lessonResources[topic.lessonResources.length - 1]
                }
              />
            ))}
          </ul>
          <div className="d-flex align-items-center">
            <NewResource lessonTopicId={topic.id} signedUrl={topic.signedUrl} />
          </div>
        </div>
      </div>
      <hr />
    </motion.li>
  )
}

const updateLessonTopicMutation = compress`
  mutation($id: ID!, $name: String!) {
    updateLessonTopic(id: $id, name: $name) {
      lessonTopic {
        id
        name
      }
    }
  }
`

const decrementOrderMutation = compress`
  ${lessonTopicsFragment}
  mutation($id: ID!) {
    decrementLessonTopicOrder(id: $id) {
      lessonPlan {
        lessonTopics {
          ...LessonTopicsFields
        }
      }
    }
  }
`

const incrementOrderMutation = compress`
  ${lessonTopicsFragment}
  mutation($id: ID!) {
    incrementLessonTopicOrder(id: $id) {
      lessonPlan {
        lessonTopics {
          ...LessonTopicsFields
        }
      }
    }
  }
`

const removeLessonTopicMutation = compress`
  ${lessonTopicsFragment}
  mutation($id: ID!) {
    removeLessonTopic(id: $id) {
      lessonPlan {
        lessonTopics {
          ...LessonTopicsFields
        }
      }
    }
  }
`

export default LessonTopic
