import React from "react"

import { FieldArray, useField } from "formik"

import { ErrorMessage } from "components/Forms/Formik"
import { FileField } from "components/Forms/Formik/hookComponents"

const ReceiptsInput = () => {
  const [field] = useField("receipts")
  const [expenseType] = useField("expenseType")

  if (expenseType.value === "intro_session") return null
  return (
    <FieldArray name="receipts">
      {arrayHelpers => (
        <>
          <button
            className="btn blue mb-3"
            type="button"
            onClick={() => arrayHelpers.push({})}
          >
            Add Receipt
          </button>

          {field.value.map((receipt, index) => (
            <FileField
              key={index}
              name={`receipts[${index}]`}
              label="Receipt"
            />
          ))}

          <div>
            <ErrorMessage name="receipts" />
          </div>
        </>
      )}
    </FieldArray>
  )
}

export default ReceiptsInput
