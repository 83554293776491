import React from "react"

import moment from "moment-timezone"
import Select from "react-select"
import ReactTable from "react-table"

import { statusOptions } from "src/enums"
import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const TutorAbandonedSessions = ({ tutorId }) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: `/api/admins/tutors/${tutorId}/airtutors_sessions/abandoned`,
  })

  let columns = [
    {
      id: "subject",
      Header: "Subject",
      accessor: "subject.name",
      sortable: false,
      filterable: false,
    },
    {
      id: "starts_at",
      Header: "Date",
      accessor: "starts_at",
      Cell: props => (
        <LocalTime timestamp={props.value} format="M/D/YY h:mm A" />
      ),
      Filter: ({ onChange }) => {
        return (
          <DateRangePicker
            onChange={([startDate, endDate]) => {
              if (!startDate && !endDate) {
                onChange({ startsAt: null, endsAt: null })
                return
              }

              const startMoment = moment(startDate.toDate()).startOf("day")
              const endMoment = moment(endDate?.toDate() || startMoment).endOf(
                "day"
              )
              onChange({
                startsAt: startMoment.format(),
                endsAt: endMoment.format(),
              })
            }}
          />
        )
      },
    },
    {
      id: "substitute_requested_at",
      Header: "Substitute Lead Time",
      accessor: "substitute_requested_at",
      sortable: false,
      filterable: false,
      Cell: props =>
        `${moment(props.original.starts_at)
          .diff(props.value, "hours", true)
          .toFixed(2)} hours`,
    },
    {
      id: "students",
      Header: "Students",
      accessor: "students",
      sortable: false,
      filterable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "last_name").map(student => (
            <li key={student.id}>
              <a href={student.show_path}>{student.full_name}</a>
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "status_state",
      Header: "Scheduled",
      accessor: "status_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={eventArray =>
              onChange({ status_states: eventArray.map(event => event.value) })
            }
            setValue={filter ? filter.value : "all"}
            options={statusOptions}
            isMulti={true}
            isClearable
          />
        )
      },
    },
    {
      id: "substitute_reason",
      Header: "Reason",
      accessor: "substitute_reason",
      filterable: false,
      sortable: false,
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-between">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default TutorAbandonedSessions
