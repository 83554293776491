import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import formatValue from "src/formatValue"
import titlecase from "src/titlecase"
import type { BarChartOptions } from "src/types/echarts"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import BarChart from "./BarChart"

const GroupedBarChart = ({
  query,
  variables,
  groupByField,
  uniqBy = null,
  nameField = null,
  dataSetKey,
  title,
}) => {
  const [chartData, setChartData] = useState<BarChartOptions>()
  const [result] = useQuery({ query, variables })

  useEffect(() => {
    if (!result.data) return
    const dataset = result.data.viewer.organization[dataSetKey].groupedData
    const groupedByDate = {}
    dataset.forEach(
      datapoint =>
        (groupedByDate[datapoint.groupingStartDate] = [
          ...(groupedByDate[datapoint.groupingStartDate] || []),
          datapoint,
        ])
    )

    type DateWithDayJsType = { source: string; dayjs: Dayjs }
    const sortedDates = Object.keys(groupedByDate)
      .map(
        (dateString: string): DateWithDayJsType => ({
          source: dateString,
          dayjs: dayjs(dateString),
        })
      )
      .sort((a, b) => (a.dayjs > b.dayjs ? 1 : a.dayjs < b.dayjs ? -1 : 0))

    const groupTypes = dataset.map(group => group[groupByField])
    let uniqueGroupTypes
    if (uniqBy) {
      uniqueGroupTypes = Array.from(
        new Set(groupTypes.map(item => item?.[uniqBy]))
      ).map(uniqIdentifier => {
        return groupTypes.find(group => group?.[uniqBy] === uniqIdentifier)
      })
    } else {
      uniqueGroupTypes = Array.from(new Set(groupTypes))
    }

    const dataByGroupType = []
    uniqueGroupTypes.forEach(groupType => {
      const relatedData = dataset.filter(group => {
        if (!groupType) {
          return group[groupByField] === null
        }
        if (uniqBy) {
          if (group[groupByField]) {
            return group[groupByField][uniqBy] === groupType[uniqBy]
          } else {
            return false
          }
        } else {
          return group[groupByField] === groupType
        }
      })
      const dataForChart = []
      relatedData.forEach(datapoint => {
        const dateIndex = sortedDates.findIndex(
          d => d.source === datapoint.groupingStartDate
        )
        dataForChart[dateIndex] =
          dataSetKey === "attendanceDataSet"
            ? datapoint.presences / datapoint.totalCount
            : datapoint.value
      })
      let name
      if (nameField) {
        name = groupType ? groupType[nameField] : "Other"
      } else {
        name = groupType
      }
      dataByGroupType.push({
        name: titlecase(name),
        values: dataForChart,
        data: relatedData,
      })
    })

    const newData: BarChartOptions = {
      xAxis: {
        data: sortedDates.map(date => date.dayjs.format("MMM 'YY")),
      },
      yAxis: {},
      series: dataByGroupType.map(datapoints => ({
        type: "bar",
        name: datapoints.name,
        data: datapoints.values,
      })),
      // note: we don't actually have to set any options here,
      // but we must at a minimum set a tooltip key for echarts to render them:
      // tooltip: {} is the bare minimum
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0.7,
        confine: true,
        trigger: "axis",
        position: "inside",
        axisPointer: {
          type: "none",
        },
        valueFormatter: val =>
          dataSetKey === "attendanceDataSet"
            ? formatValue(val as number, ValueFormat.Percent)
            : (val as string),
      },
    }

    setChartData(newData)
  }, [result.data, dataSetKey, groupByField, nameField, title, uniqBy])

  const noDataPresent =
    result.data?.viewer?.organization[dataSetKey]?.groupedData?.length === 0
  return noDataPresent ? (
    <div>
      <h2 className="text-lg font-medium">{title}</h2>
      <div className="my-4 ml-3">
        There is no data to display for this date range.
      </div>
    </div>
  ) : (
    <UrqlLoadingIndicator result={result}>
      <h2 className="text-lg font-medium">{title}</h2>
      <BarChart height="medium" data={chartData} />
    </UrqlLoadingIndicator>
  )
}

export default GroupedBarChart
