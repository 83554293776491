import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ResetStudentSubjects = ({ organizationId }) => {
  const [error, setError] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(resetStudentSubjectsMutation)
  )

  const clearData = () => {
    runMutation({ organizationId })
      .then(
        response => {
          const { errorMessages } = response.organizationResetStudentSubjects
          if (errorMessages) {
            setError(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => setError("Something went wrong")
      )
      .catch(() => setError("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Reset Student Subjects"
      buttonClassName="btn btn-danger"
      buttonText="Reset Student Subjects"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <div className="lead font-weight-bold mb-3">
              This is a very destructive action!
            </div>

            <div className="mb-3">
              All students in this organization will have their subjects,
              teacher subjects, and availabilities removed. PERMANENTLY.
            </div>

            <div>
              Additionally, all group generations will be removed (the groups
              themselves will still exist but no longer be attached to a
              generation)
            </div>
          </div>
          <div className="modal-footer flex-column">
            {error && (
              <div className="alert alert-danger w-100 mb-3">{error}</div>
            )}
            <div className="d-flex justify-content-between w-100">
              <button
                className="btn btn-danger"
                type="button"
                onClick={clearData}
                disabled={running}
              >
                Yes, clear the datas
              </button>
              <button
                className="btn btn-info"
                type="button"
                onClick={closeModal}
              >
                Uhhh....perhaps not
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const resetStudentSubjectsMutation = compress`
  mutation($organizationId: ID!) {
    organizationResetStudentSubjects(organizationId: $organizationId) {
      errorMessages
    }
  }
`

export default ResetStudentSubjects
