import React from "react"

const ExistingPaymentMethods = ({ viewer }) => {
  return (
    <div>
      <h3>Payment Methods</h3>
      {viewer.stripePaymentMethods.map(paymentMethod => (
        <div key={paymentMethod.id} className="billing__payment-method">
          <div className="billing__payment-method-image">
            {paymentMethod.default ? " (default)" : null}
          </div>
          <div className="billing__payment-method-details">
            <strong>{paymentMethod.brand}</strong> ending in{" "}
            {paymentMethod.last4}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ExistingPaymentMethods
