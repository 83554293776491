import { useCallback, useEffect, useRef, useState } from "react"

import { pipe, toPromise } from "wonka"

import { createRequest } from "@urql/core"

import urqlClient from "src/urql-client"

import { initialState } from "./state"

export function useMutation(query, client = urqlClient) {
  const isMounted = useRef(true)

  const [state, setState] = useState(initialState)

  const executeMutation = useCallback(
    (variables, context) => {
      setState({ ...initialState, fetching: true })

      return pipe(
        client.executeMutation(createRequest(query, variables), context || {}),
        toPromise
      ).then(result => {
        if (isMounted.current) {
          setState({
            fetching: false,
            stale: !!result.stale,
            data: result.data,
            error: result.error,
            extensions: result.extensions,
            operation: result.operation,
          })
        }
        return result
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, query, setState]
  )

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return [state, executeMutation]
}
