import React from "react"

import {
  ArrowDownIcon,
  ArrowUpIcon,
  MinusIcon,
} from "@heroicons/react/20/solid"

import { ChangeDirection, ValueFormat } from "~tailwindui/types/enums"

import classNames from "src/classNames"
import formatValue from "src/formatValue"

type ChipItemProps = {
  label: string
  value: number
  changeValue?: number
  valueFormat?: ValueFormat
  changeValueFormat?: ValueFormat
  changeDirection?: ChangeDirection
  subtitle?: string
  subtitleValue?: number
  subtitleValueFormat?: ValueFormat
}

const ChipItem: React.FC<ChipItemProps> = ({
  label,
  value,
  changeValue,
  valueFormat,
  changeValueFormat,
  changeDirection,
  subtitle,
  subtitleValue,
  subtitleValueFormat,
}) => {
  return (
    <div className="relative flex flex-wrap items-center justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
      <dt className="text-sm font-medium leading-6 text-gray-500">{label}</dt>
      {changeDirection && (
        <dd
          className={classNames(
            changeDirection === ChangeDirection.Decrease
              ? "text-rose-600"
              : "text-gray-700",
            "text-xs font-medium"
          )}
        >
          <p
            className={classNames(
              changeDirection === ChangeDirection.Increase &&
                "text-emerald-600",
              changeDirection === ChangeDirection.Decrease && "text-rose-600",
              changeDirection === ChangeDirection.Constant && "text-gray-700",
              "flex items-center"
            )}
          >
            {changeDirection === ChangeDirection.Increase ? (
              <ArrowUpIcon
                className="h-5 w-5 flex-shrink-0 self-center text-emerald-600"
                aria-hidden="true"
              />
            ) : changeDirection === ChangeDirection.Decrease ? (
              <ArrowDownIcon
                className="h-5 w-5 flex-shrink-0 self-center text-rose-600"
                aria-hidden="true"
              />
            ) : changeDirection === ChangeDirection.Constant ? (
              <MinusIcon
                className="h-5 w-5 flex-shrink-0 self-center text-gray-700"
                aria-hidden="true"
              />
            ) : null}
            {formatValue(changeValue, changeValueFormat)}
          </p>
        </dd>
      )}
      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
        {formatValue(value, valueFormat)}
      </dd>
      {subtitle && (
        <dd className="text-md w-full flex-none tracking-tight text-gray-400">
          <div className="absolute inset-x-4 md:inset-x-6 lg:inset-x-7">
            {subtitle} {formatValue(subtitleValue, subtitleValueFormat)}
          </div>
        </dd>
      )}
    </div>
  )
}

export default ChipItem
export type { ChipItemProps }
