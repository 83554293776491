import React from "react"

import NotificationContainer from "components/NotificationContainer"

import Form from "./Form"

const ClearParentInfo = ({ organizationId }) => {
  return (
    <>
      <NotificationContainer />
      <Form organizationId={organizationId} />
    </>
  )
}

export default ClearParentInfo
