import React from "react"

import { useFormikContext } from "formik"

import { DateObject } from "components/Forms/DatePicker"
import { TimeField } from "components/Forms/Formik/hookComponents"

const TimeInputs = () => {
  const { setFieldValue } = useFormikContext()
  return (
    <>
      <TimeField
        name="startsAt"
        label="Starts At"
        onChange={date => {
          setFieldValue("startsAt", date)
          setFieldValue("endsAt", new DateObject(date).add(1, "hour"))
        }}
      />
      <TimeField name="endsAt" label="Ends At" />
    </>
  )
}

export default TimeInputs
