import React, { useEffect, useState } from "react"

import axios from "axios"
import { camelcase } from "camelcase-input"

import dayjs from "src/dayjs"

import Form from "./Form"

const AdminTailoredAssignmentFormContainer = ({ id }) => {
  const [initialFormState, setInitialFormState] = useState({
    name: "",
    dueAt: dayjs().set("hour", 12).startOf("hour").toDate(),
    sections: [],
  })

  useEffect(() => {
    if (id) {
      axios.get(`/api/admins/tailored_assignments/${id}`).then(response => {
        const assignment = response.data

        const sectionTags = {}
        assignment.sections.forEach(section => {
          sectionTags[section.id] = {
            tagList: section.tag_list,
            groupTagList: section.group_tag_list,
          }
        })

        const camelcaseAssignment = camelcase(assignment, { deep: true })
        camelcaseAssignment.sections.forEach(section => {
          section.tagList = sectionTags[section.id].tagList
          section.groupTagList = sectionTags[section.id].groupTagList
          section.tagSearch = ""
        })

        if (camelcaseAssignment.dueAt === null) {
          camelcaseAssignment.dueAt = dayjs()
            .set("hour", 12)
            .startOf("hour")
            .toDate()
        } else {
          camelcaseAssignment.dueAt = dayjs(camelcaseAssignment.dueAt).toDate()
        }

        setInitialFormState(camelcaseAssignment)
      })
    }
  }, [id])

  return <Form id={id} initialFormState={initialFormState} />
}

export default AdminTailoredAssignmentFormContainer
