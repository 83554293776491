import React from "react"

import AssignedStudyGroups from "./AssignedStudyGroups"
import FutureStudyGroups from "./FutureStudyGroups"

const StudyGroupsIndex = () => {
  return (
    <>
      <AssignedStudyGroups />
      <FutureStudyGroups />
    </>
  )
}

export default StudyGroupsIndex
