import React from "react"

import NewConfiguration from "./NewConfiguration"
import Table from "./Table"

const ApplicationConfigurationIndex = () => {
  return (
    <>
      <NewConfiguration />
      <Table />
    </>
  )
}

export default ApplicationConfigurationIndex
