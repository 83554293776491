import React, { useRef } from "react"

import { motion } from "framer-motion"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const TeacherSubjects = ({ teacherId }) => {
  const selectRef = useRef(null)
  const { runMutation: addSubject } = useMutation(
    buildMutation(addSubjectMutation)
  )
  const loadingState = useQuery(
    buildQuery(
      subjectsQuery,
      { teacherId },
      {
        onMutation: [
          {
            when: "teacherAddSubject",
            run: ({ softReset, currentResults }, response) => {
              const payload = response.teacherAddSubject
              if (payload.teacher) {
                currentResults.teacher.subjects = payload.teacher.subjects
                softReset(currentResults)
              }
            },
          },
          {
            when: "teacherRemoveSubject",
            run: ({ softReset, currentResults }, response) => {
              const payload = response.teacherRemoveSubject
              if (payload.teacher) {
                currentResults.teacher.subjects = payload.teacher.subjects
                softReset(currentResults)
              }
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <h4 className="mt-0">Subjects</h4>
          <EditableList>
            {ordered(data.teacher.subjects, "name").map(subject => (
              <motion.li key={subject.id} positionTransition>
                <Item
                  mutation={removeSubjectMutation}
                  mutationProps={{
                    teacherId,
                    subjectId: subject.id,
                  }}
                >
                  {subject.name}
                </Item>
              </motion.li>
            ))}
          </EditableList>
          <div className="mb-3">
            <AutocompleteSelect
              ref={selectRef}
              onChange={option => {
                if (!option?.value) return
                addSubject({ teacherId, subjectId: option.value }).then(() => {
                  selectRef.current.clearValue()
                })
              }}
              withLoadingIndicator
              api="/api/admins/subjects/autocomplete_name"
              placeholder="Add Subject"
            />
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const subjectsQuery = compress`
  query($teacherId: ID!) {
    teacher(id: $teacherId) {
      subjects {
        id
        name
      }
    }
  }
`

const addSubjectMutation = compress`
  mutation($teacherId: ID!, $subjectId: ID!) {
    teacherAddSubject(teacherId: $teacherId, subjectId: $subjectId) {
      teacher {
        subjects {
          id
          name
        }
      }
    }
  }
`

const removeSubjectMutation = compress`
  mutation($teacherId: ID!, $subjectId: ID!) {
    teacherRemoveSubject(teacherId: $teacherId, subjectId: $subjectId) {
      teacher {
        subjects {
          id
          name
        }
      }
    }
  }
`
export default TeacherSubjects
