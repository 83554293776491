import React from "react"

import ordered from "src/ordered"

import { CheckboxField, Field } from "components/Forms/Formik/hookComponents"

const OrganizationConsent = ({
  label,
  signatureLabel,
  acceptanceName,
  signatureName,
  organization,
}) => {
  if (!organization.consentText) return null
  return (
    <div className="form-group">
      <label className="form-label">
        <span>{label}</span>
      </label>
      <CheckboxField name={acceptanceName} label={organization.consentText} />
      <Field name={signatureName} label={signatureLabel} />
      <ul>
        {ordered(organization.consentDocuments, "filename").map(document => (
          <li key={document.id}>
            <a
              href={document.downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document.filename}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default OrganizationConsent
