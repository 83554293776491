import React, { useState } from "react"

import { useField, useFormikContext } from "formik"
import moment from "moment-timezone"

import DateRangeField from "./DateRangeField"

const SessionDateRangeField = props => {
  const [sessionTooSoon, setSessionTooSoon] = useState(false)
  const [field] = useField(props)
  const { setFieldValue } = useFormikContext()

  const handleDateChange = value => {
    setSessionTooSoon(moment(new Date(value[0])).diff(moment(), "hours") < 48)
    setFieldValue(field.name, value)
  }
  return (
    <div>
      <div className="inline-block">
        <DateRangeField onChange={handleDateChange} field={field} {...props} />
      </div>
      {sessionTooSoon && (
        <div className="alert mt-3 bg-yellow-400 p-2 text-black">
          This session is less than 2 days away. If possible, please schedule
          the session further out.
        </div>
      )}
    </div>
  )
}

export default SessionDateRangeField
