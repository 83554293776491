import React from "react"

import { useField, useFormikContext } from "formik"

import ErrorMessage from "./ErrorMessage"

const RadioField = ({
  options,
  hideError = false,
  boolean = false,
  numeric = false,
  afterChange = undefined,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props)

  if (!options && boolean) {
    options = [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ]
  }

  return (
    <div className="form-group">
      {props.label && <label>{props.label}</label>}
      {options.map(option => (
        <div key={`${option.label}-${option.value}`} className="form-check">
          <label
            className={`form-check-label ${option.disabled ? "disabled" : ""}`}
          >
            {option.label}
            <input
              {...field}
              {...props}
              onChange={e => {
                field.onChange(e)
                if (numeric) {
                  setFieldValue(props.name, parseInt(e.currentTarget.value))
                }
                if (boolean) {
                  const value = e.currentTarget.value
                  if (value === "false") {
                    setFieldValue(props.name, false)
                  }
                  if (value === "true") {
                    setFieldValue(props.name, true)
                  }
                }
                typeof afterChange === "function" && afterChange(e)
              }}
              className="form-check-input"
              type="radio"
              value={option.value}
              defaultChecked={field.value === option.value}
            />
            <span className="form-check-sign" />
          </label>
        </div>
      ))}
      {!hideError && <ErrorMessage {...meta} touched={true} />}
    </div>
  )
}

export default RadioField
