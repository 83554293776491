import React, { useEffect, useState } from "react"

import defaultAxios from "axios"
import { Field, FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import axios from "src/axios"
import { countryOptions, regionOptions } from "src/enums"
import { publicClient } from "src/graphql-config"

import { ErrorMessage, TimeZoneField } from "components/Forms/Formik"
import { SelectField } from "components/Forms/Formik/hookComponents"
import LoadingIndicator from "components/LoadingIndicator"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import AvatarPreview from "./AvatarPreview"
import SubjectBucketSelection from "./SubjectBucketSelection"
import styles from "./styles.module.scss"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  emailTimeZone: Yup.string().required("Time Zone is required"),
  country: Yup.string().required("Country is required"),
  region: Yup.string().required("Region is required"),
  bio: Yup.string().required("Bio is required"),
  education: Yup.string().required("Education is required"),
  avatar: Yup.string().required("Avatar is required"),
})

const TutorQuestionnaire = props => {
  const { token } = props
  publicClient.fetchOptions.headers["X-QUESTIONNAIRE-TOKEN"] = token

  const [formValues, setFormValues] = useState(null)
  const [pendingAvatar, setPendingAvatar] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const loadingState = useQuery(
    buildQuery(loadTutorQuery, {}, { client: publicClient })
  )
  const { loaded, data } = loadingState

  const { runMutation } = useMutation(
    buildMutation(updateTutorMutation, { client: publicClient })
  )

  useEffect(() => {
    if (loaded && data) {
      const tierIds = data.tutor.tiers.map(tier => tier.id)
      const subjectBucketIds = data.tutor.tutorSubjects.map(
        tutorSubject => tutorSubject.subjectBucket.id
      )
      setFormValues({
        email: data.tutor.email,
        firstName: data.tutor.firstName || "",
        lastName: data.tutor.lastName || "",
        phoneNumber: data.tutor.phoneNumber || "",
        emailTimeZone: data.tutor.emailTimeZone || "",
        country: data.tutor.address?.country || "United States",
        region: data.tutor.address?.region || "California",
        bio: data.tutor.bio || "",
        education: data.tutor.education || "",
        avatar: data.tutor.avatar.url || "",
        tierIds: tierIds,
        subjectBucketIds: subjectBucketIds,
      })
    }
  }, [loaded, data])

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true)
    actions.setStatus({ msg: "Saving..." })
    runMutation({ ...values })
      .then(response => {
        if (response.updateTutor.success === true) {
          axios
            .post("/api/set_flash", {
              type: "notice",
              message:
                "Your profile has been updated. You will be contacted if further modifications are required.",
            })
            .then(response => {
              window.location = `/`
            })
        } else {
          actions.setSubmitting(false)
          actions.setStatus({
            err: `Unable to update profile: ${response.updateTutor.errorMessage}`,
          })
        }
      })
      .catch(err => {
        actions.setSubmitting(false)
        actions.setStatus({})
        actions.setStatus({ err: "Unable to update profile" })
      })
  }

  const handleAvatarUpload = ({ event, setFieldValue }) => {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("key", data.tutor.avatar.signedUrl.key)
    formData.append(
      "success_action_status",
      data.tutor.avatar.signedUrl.successActionStatus
    )
    formData.append("acl", data.tutor.avatar.signedUrl.acl)
    formData.append("policy", data.tutor.avatar.signedUrl.policy)
    formData.append("x-amz-credential", data.tutor.avatar.signedUrl.credential)
    formData.append("x-amz-algorithm", data.tutor.avatar.signedUrl.algorithm)
    formData.append("x-amz-date", data.tutor.avatar.signedUrl.date)
    formData.append("x-amz-signature", data.tutor.avatar.signedUrl.signature)
    formData.append("x-amz-server-side-encryption", "AES256")
    formData.append("Content-Type", file.type)
    formData.append("file", file)

    setIsUploading(true)
    setPendingAvatar(file)

    defaultAxios
      .post(data.tutor.avatar.signedUrl.url, formData)
      .then(response => {
        const xmlDocument = new DOMParser().parseFromString(
          response.data,
          "text/xml"
        )
        const location =
          xmlDocument.getElementsByTagName("Location")[0].innerHTML
        setFieldValue("avatar", decodeURIComponent(location))
        setIsUploading(false)
      })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading your profile. Please refresh or contact an administrator"
      heading={<h1>Tutor Questionnaire</h1>}
    >
      {({ data }) =>
        formValues !== null ? (
          <div className="form-wrapper">
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={ValidationSchema}
            >
              {({ values, status, setFieldValue, isSubmitting }) => (
                <Form className="full-width-forms">
                  <input type="text" disabled value={values.email} />

                  <div className={styles.formGroup}>
                    <label>
                      First Name
                      <Field
                        name="firstName"
                        type="text"
                        className="form-control"
                      />
                    </label>
                    {data.tutor.fullNameReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.fullNameReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>
                      Last Name
                      <Field
                        name="lastName"
                        type="text"
                        className="form-control"
                      />
                    </label>
                    <div>
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>
                      Cell Phone Number (Do not use google numbers! Real cell
                      phone numbers)
                      <Field
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                      />
                    </label>
                    {data.tutor.phoneNumberReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.phoneNumberReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="phoneNumber" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>
                      Time Zone
                      <TimeZoneField
                        name="emailTimeZone"
                        setFieldValue={setFieldValue}
                      />
                    </label>
                    {data.tutor.emailTimeZoneReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.emailTimeZoneReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="emailTimeZone" />
                    </div>
                  </div>

                  <SelectField
                    name="country"
                    label="Country"
                    options={countryOptions}
                    defaultValue={countryOptions.find(
                      o => o.value === formValues.country
                    )}
                  />
                  <SelectField
                    name="region"
                    label="Region"
                    options={regionOptions.filter(r => r.value !== "Other")}
                    defaultValue={regionOptions.find(
                      o => o.value === formValues.region
                    )}
                  />

                  <div className={styles.formGroup}>
                    <label>
                      Bio
                      <Field
                        name="bio"
                        type="text"
                        component="textarea"
                        className="form-control"
                      />
                    </label>
                    {data.tutor.bioReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.bioReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="bio" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>
                      Education (include degrees earned and university(ies)
                      attended)
                      <Field
                        name="education"
                        type="text"
                        component="textarea"
                        className="form-control"
                      />
                    </label>
                    {data.tutor.educationReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.educationReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="education" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <div className="row">
                      <div className="col-1-2">
                        <label>
                          Avatar
                          <Field
                            name="avatar"
                            type="text"
                            className="form-control"
                          >
                            {({ field: { onChange, name, value } }) => (
                              <input
                                type="file"
                                accept="image/*"
                                name={name}
                                onChange={e =>
                                  handleAvatarUpload({
                                    event: e,
                                    setFieldValue,
                                  })
                                }
                              />
                            )}
                          </Field>
                        </label>
                      </div>

                      <div className="col-1-2">
                        <AvatarPreview
                          thumbUrl={data.tutor.avatar.thumbUrl}
                          pendingAvatar={pendingAvatar}
                          isUploading={isUploading}
                        />
                      </div>

                      <div className="col-1-1">
                        <p className="rounded bg-yellow-300 p-5 italic">
                          This should be an appropriate selfie of yourself and
                          not any other type of image (ie., no pictures of dogs,
                          objects, etc)
                        </p>
                      </div>
                      <div className="clear" />
                    </div>
                    {data.tutor.avatarReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.avatarReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="avatar" />
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    Grade Ranges
                    <FieldArray name="tierIds">
                      {arrayHelpers =>
                        data.tiers.map(({ id, gradeRange }) => (
                          <label key={id} style={{ display: "block" }}>
                            <input
                              type="checkbox"
                              name={`tierIds.${id}`}
                              value={id}
                              checked={values.tierIds.includes(id)}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(id)
                                } else {
                                  arrayHelpers.remove(
                                    values.tierIds.indexOf(id)
                                  )
                                }
                              }}
                            />
                            {gradeRange}
                          </label>
                        ))
                      }
                    </FieldArray>
                    {data.tutor.tierIdsReviewFeedback && (
                      <div className={styles.reviewFeedback}>
                        {data.tutor.tierIdsReviewFeedback}
                      </div>
                    )}
                    <div>
                      <ErrorMessage name="tierIds" />
                    </div>
                  </div>

                  <div className="form-group">
                    <FieldArray name="subjectBucketIds">
                      {arrayHelpers =>
                        values.tierIds
                          .sort()
                          .map(tierId => (
                            <SubjectBucketSelection
                              key={tierId}
                              tierId={parseInt(tierId, 10)}
                              subjectBucketIds={values.subjectBucketIds}
                              tutorSubjects={data.tutor.tutorSubjects}
                              arrayHelpers={arrayHelpers}
                            />
                          ))
                      }
                    </FieldArray>
                  </div>

                  <div className={styles.actions}>
                    <button
                      type="submit"
                      className={`${styles.submitButton} btn solid blue`}
                      disabled={isSubmitting || isUploading}
                    >
                      Submit Items for Review
                    </button>
                    {isSubmitting && <LoadingIndicator size="small" />}
                    {status && status.err && (
                      <span className={`alert ${styles.statusMessage}`}>
                        {status.err}
                      </span>
                    )}
                    {status && status.msg && (
                      <span className={`notice ${styles.statusMessage}`}>
                        {status.msg}
                      </span>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          ""
        )
      }
    </WithLoadingIndicator>
  )
}

const loadTutorQuery = compress`
  query {
    tutor {
      email
      firstName
      lastName
      fullNameReviewFeedback
      phoneNumber
      phoneNumberReviewFeedback
      emailTimeZone
      emailTimeZoneReviewFeedback
      bio
      bioReviewFeedback
      education
      educationReviewFeedback
      avatarReviewFeedback
      tierIdsReviewFeedback
      address {
        country
        region
      }
      avatar {
        url
        thumbUrl
        signedUrl {
          url
          key
          successActionStatus
          acl
          policy
          credential
          algorithm
          date
          signature
        }
      }
      tiers {
        id
      }
      tutorSubjects {
        approvalState
        subjectBucket {
          id
          name
        }
      }
    }
    tiers {
      id
      gradeRange
    }
  }
`

const updateTutorMutation = compress`
  mutation(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $emailTimeZone: TimeZoneEnum!
    $country: String!
    $region: String!
    $bio: String!
    $education: String!
    $avatar: String
    $tierIds: [ID!]!
    $subjectBucketIds: [ID!]!
  ) {
    updateTutor(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      emailTimeZone: $emailTimeZone
      country: $country
      region: $region
      bio: $bio
      education: $education
      avatar: $avatar
      tierIds: $tierIds
      subjectBucketIds: $subjectBucketIds
    ) {
      success
      errorMessage
    }
  }
`

export default TutorQuestionnaire
