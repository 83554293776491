import React from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"

import CancelSessionButton from "components/AirtutorsSession/CancelSessionButton"

const AdminCancelSessionButton = props => {
  const { runMutation } = useMutation(buildMutation(cancelSessionMutation))

  return <CancelSessionButton runMutation={runMutation} {...props} />
}

const cancelSessionMutation = compress`
  mutation($id: ID!, $cancellationReason: String!) {
    cancelAirtutorsSession(id: $id, cancellationReason: $cancellationReason) {
      success
      errorMessage
    }
  }
`

AdminCancelSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default AdminCancelSessionButton
