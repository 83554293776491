import React, { useRef } from "react"

import ReactTable from "react-table"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const UnassignedCourseGroups = () => {
  const table = useRef()
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/study_groups/unassigned_course_groups",
  })
  const { exportToCSV } = useExportable({
    apiEndpoint: "/api/admins/study_groups/unassigned_course_groups",
    tableRef: table.current,
  })

  const columns = [
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization.name",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/organizations/autocomplete_name"
          />
        )
      },
    },
    {
      id: "course_cluster",
      Header: "Course Cluster",
      accessor: "course_cluster.name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/course_clusters/autocomplete_name"
          />
        )
      },
    },
    {
      id: "duration_weeks",
      Header: "Duration (Weeks)",
      accessor: "duration_weeks",
      Cell: props => <div className="text-center">{props.value}</div>,
    },
    {
      id: "course_section_starts_on",
      Header: "Start Date",
      accessor: "starts_on",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => {
        return (
          <DateRangePicker
            placeholder="Select date range"
            onChange={([startDate, endDate]) => {
              onChange({
                startsOnStart: startDate?.toDate(),
                startsOnEnd: endDate?.toDate(),
              })
            }}
          />
        )
      },
    },
    {
      id: "course_section_ends_on",
      Header: "End Date",
      accessor: "ends_on",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => (
        <DateRangePicker
          placeholder="Select date range"
          onChange={([startDate, endDate]) =>
            onChange({
              endsOnStart: startDate?.toDate(),
              endsOnEnd: endDate?.toDate(),
            })
          }
        />
      ),
    },
    {
      id: "availabilities",
      Header: "Meets",
      sortable: false,
      filterable: false,
      accessor: "availabilities",
      Cell: props => (
        <ul className="list-unstyled">
          {props.value.map(availability => (
            <li key={availability.id}>
              {availability.weekday}{" "}
              <LocalTime timestamp={availability.starts_at} omitDate /> &mdash;{" "}
              <LocalTime timestamp={availability.ends_at} omitDate />
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "student_count",
      Header: "Registered Students",
      sortable: false,
      filterable: false,
      accessor: "student_count",
      Cell: props => <div className="text-center">{props.value}</div>,
    },
    {
      id: "study_group_created_at",
      Header: "Group Created",
      filterable: false,
      accessor: "created_at",
      Cell: props => (
        <LocalTime timestamp={props.value} format="M/D/YY h:mm A" />
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="d-flex justify-content-center flex-wrap">
          <a href={props.value.show_path} className="btn btn-sm btn-info">
            View Course
          </a>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="row">
        <div className="col">
          <button onClick={exportToCSV} className="btn btn-success">
            Export to CSV
          </button>
        </div>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default UnassignedCourseGroups
