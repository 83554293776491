import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  FileField,
  FormFailures,
  SubmitButton,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  poAttachment: Yup.mixed().test(
    "poAttachment",
    "File must be present",
    file => {
      return file.name || file.fileName
    }
  ),
})

const AddPoAttachment = ({ budgetId }) => {
  const formState = {
    budgetId,
    poAttachment: {},
  }

  const [, addPoAttachment] = useMutation(addPoAttachmentMutation)

  const handleSubmit = (values, actions, closeModal) => {
    const { poAttachment, ...variables } = values
    readFile(poAttachment)
      .then(file => {
        variables.poAttachment = file
        addPoAttachment(variables)
          .then(
            result => {
              const { failures } = result.data.budgetAddPoAttachment
              if (failures) {
                handleFailure(actions, failures)
              } else {
                closeModal()
              }
            },
            () => handleFailure(actions)
          )
          .catch(() => handleFailure(actions))
      })
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add PO Attachment"
      buttonText="Add PO Attachment"
      buttonClassName="btn btn-sm"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <FileField name="poAttachment" label="PO Attachment" />
            </div>
            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const addPoAttachmentMutation = gql`
  mutation ($budgetId: ID!, $poAttachment: FileInputObject!) {
    budgetAddPoAttachment(budgetId: $budgetId, poAttachment: $poAttachment) {
      failures {
        message
      }
      budget {
        id
      }
    }
  }
`

export default AddPoAttachment
