import React from "react"

import defaultAxios from "axios"
import { useField, useFormikContext } from "formik"

import { Loading } from "components/Icons"

import ErrorMessage from "./ErrorMessage"

const AwsFileField = ({
  name,
  label,
  signedUrl,
  isUploading,
  setIsUploading,
  ...props
}) => {
  const [field, meta] = useField(`${name}Url`)
  const { setFieldValue } = useFormikContext()

  const handleFileUpload = event => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append("key", signedUrl.key)
    formData.append("success_action_status", signedUrl.successActionStatus)
    formData.append("acl", signedUrl.acl)
    formData.append("policy", signedUrl.policy)
    formData.append("x-amz-credential", signedUrl.credential)
    formData.append("x-amz-algorithm", signedUrl.algorithm)
    formData.append("x-amz-date", signedUrl.date)
    formData.append("x-amz-signature", signedUrl.signature)
    formData.append("x-amz-server-side-encryption", "AES256")
    formData.append("Content-Type", file.type)
    formData.append("file", file)

    setIsUploading(true)

    defaultAxios.post(signedUrl.url, formData).then(response => {
      const xmlDocument = new DOMParser().parseFromString(
        response.data,
        "text/xml"
      )
      const location = xmlDocument.getElementsByTagName("Location")[0].innerHTML
      setFieldValue(`${name}Url`, decodeURIComponent(location))
      setFieldValue(`${name}Filename`, file.name || file.fileName)
      setIsUploading(false)
    })
  }

  // exclude value and onChange field props
  const { value, onChange, ...fieldProps } = field

  return (
    <div className="form-group">
      <label className="form-label w-100">
        <span>{label}</span>
        <input
          type="file"
          className="form-control"
          onChange={handleFileUpload}
          {...fieldProps}
          {...props}
        />
        {isUploading && (
          <div className="mt-3 inline-flex rounded bg-sky-300 p-3 align-middle text-black">
            <div className="d-inline-block mx-2">
              <Loading />
            </div>
            <div>Uploading...</div>
          </div>
        )}
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default AwsFileField
