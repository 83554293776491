import React, { useEffect, useRef, useState } from "react"

import {
  faCheck,
  faCloudDownloadAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { statementStateOptions } from "src/enums"
import ordered from "src/ordered"

import { gql, useMutation, useQuery } from "hooks/urql"

import AdminNotification from "components/AdminNotification"
import BasicDisplay from "components/BasicDisplay"
import EditableDisplay from "components/Forms/EditableDisplay"
import LocalTime from "components/LocalTime"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import AddReceipt from "./AddReceipt"
import BudgetlessSessions from "./BudgetlessSessions"
import PaymentReceived from "./PaymentReceived"
import Receipt from "./Receipt"
import RegenerateStatement from "./RegenerateStatement"
import RemoveStatement from "./RemoveStatement"
import SendEmail from "./SendEmail"
import UnbilledSessions from "./UnbilledSessions"

const StatementDetails = ({ statementId }) => {
  const [result] = useQuery({
    query: statementQuery,
    variables: { statementId },
  })

  const [title, setTitle] = useState("")
  const [infoMessage, setInfoMessage] = useState("")
  useEffect(() => {
    if (result.data) setTitle(result.data.statement.title)
  }, [result])

  const [_, updateStatement] = useMutation(updateStatementMutation)

  const updateTitle = ({ value }) =>
    updateStatement({ statementId, title: value }).then(response => {
      setTitle(response.data.updateStatement.statement.title)
      setInfoMessage(
        "You will need to regenerate the statement to have the new title take effect"
      )
    })

  const csvDownloadLink = useRef()

  const statementLabel = state =>
    statementStateOptions.find(({ value }) => value === state).label

  return (
    <>
      {infoMessage && <AdminNotification type="info" content={infoMessage} />}
      <UrqlLoadingIndicator result={result}>
        {({ data: { statement } }) => (
          <>
            <BasicDisplay label="Name">
              <EditableDisplay
                displayValue={title || statement.title}
                save={updateTitle}
                height="auto"
              />
            </BasicDisplay>

            {statement.budget.balance < 0 && (
              <div className="alert alert-danger">
                Warning: the budget associated with this statement currently has
                a negative balance
              </div>
            )}

            {statement.mayPaymentReceived && (
              <div>
                <PaymentReceived statementId={statementId} />
              </div>
            )}

            <BasicDisplay label="State" labelClassName="!mt-0">
              <div>{statementLabel(statement.aasmState)}</div>

              {statement.pdf?.downloadUrl && (
                <div className="flex">
                  <a
                    ref={csvDownloadLink}
                    className="hidden"
                    href={statement.sessionsCsv.downloadUrl}
                  >
                    sessions csv
                  </a>

                  <a
                    className="btn-link text-info my-auto pl-0"
                    href={statement.pdf.downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => csvDownloadLink.current.click()}
                  >
                    <FontAwesomeIcon
                      icon={faCloudDownloadAlt}
                      className="mr-2"
                    />
                    PDF (Generated{" "}
                    <LocalTime timestamp={statement.pdfGeneratedAt} />)
                  </a>

                  {statement.mayRegenerate && (
                    <div>
                      <RegenerateStatement statementId={statementId} />
                    </div>
                  )}
                </div>
              )}

              <SendEmail
                organization={statement.organization}
                statement={statement}
              />
            </BasicDisplay>

            <BasicDisplay label="Amount" value={statement.formattedTotal} />
            {statement.unbilledSessionsCount ? (
              <BasicDisplay
                label="Unbilled Sessions Count"
                labelClassName="bold alert alert-danger"
                value={statement.unbilledSessionsCount}
              />
            ) : null}

            {statement.budgetlessSessionsCount ? (
              <BasicDisplay
                label="Budgetless Sessions Count"
                labelClassName="bold alert alert-danger"
                value={statement.budgetlessSessionsCount}
              />
            ) : null}

            <BasicDisplay
              label="Invoice Number"
              value={statement.invoiceNumber}
            />

            <BasicDisplay label="Date Range">
              {statement.dateRangeDisplay}
            </BasicDisplay>

            {statement.statementConfiguration?.id && (
              <BasicDisplay label="Autogenerated by">
                <a href={statement.statementConfiguration.showPath}>
                  {statement.statementConfiguration.name}
                </a>
              </BasicDisplay>
            )}

            <BasicDisplay label="Autogenerated Breakdowns">
              {statement.autogeneratesBreakdownsByCategory
                ? `By ${statement.autogeneratesBreakdownsByCategory.replace(
                    /[A-Z]/g,
                    letter => ` and ${letter.toLowerCase()}`
                  )}`
                : "None"}
            </BasicDisplay>

            {statement.subjectGroupings.length > 0 && (
              <BasicDisplay label="Subject Line Items">
                <ul className="list-unstyled">
                  {statement.subjectGroupings.map(grouping => (
                    <li key={grouping.label}>
                      <span className="italic">
                        <span className="font-bold">"{grouping.label}" </span>(
                        {ordered(grouping.subjects)
                          .map(subject => subject.name)
                          .join(", ")}
                        )
                      </span>
                    </li>
                  ))}
                </ul>
                <span className="italic">
                  All other subjects each have their own line item.
                </span>
              </BasicDisplay>
            )}

            {statement.studyGroupTags.length > 0 && (
              <BasicDisplay label="Only Including Groups Tagged With One of">
                <ul className="list-unstyled">
                  {ordered(statement.studyGroupTags).map(tag => (
                    <li key={tag}>{tag}</li>
                  ))}
                </ul>
              </BasicDisplay>
            )}

            {statement.includeAllGroups ? (
              <BasicDisplay
                label={
                  <>
                    All Schools Included
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="ml-3 text-green-600"
                    />
                  </>
                }
              />
            ) : (
              <BasicDisplay label="Included Schools">
                <ul className="list-unstyled">
                  {ordered(statement.schools, "name").map(school => (
                    <li key={school.id}>
                      <a href={school.showPath}>{school.name}</a>
                    </li>
                  ))}
                  {statement.includeSchoollessGroups && (
                    <li key="schoolless">Sessions with No School Assigned</li>
                  )}
                </ul>
              </BasicDisplay>
            )}

            <BasicDisplay label="Receipts">
              <ul className="list-unstyled mt-3">
                {statement.receipts.map(receipt => (
                  <Receipt
                    key={receipt.id}
                    statement={statement}
                    receipt={receipt}
                  />
                ))}
              </ul>
              {statement.aasmState === "payment_received" && (
                <AddReceipt statementId={statementId} />
              )}
            </BasicDisplay>

            <BasicDisplay label="Budget Details">
              <div className="ml-3">
                <div className="mb-2">
                  <a href={statement.budget.showPath}>
                    {statement.budget.name}
                  </a>
                </div>

                <div className="mb-2">
                  <span className="mr-2 font-medium">Prepaid</span>
                  {statement.budget.prepaid ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="ml-3 text-green-600"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="ml-3 text-red-500"
                    />
                  )}
                </div>

                <div className="mb-2">
                  <div className="font-medium">Date Range</div>
                  <LocalTime timestamp={statement.budget.startsOn} omitTime />
                  &nbsp;&mdash;&nbsp;
                  <LocalTime timestamp={statement.budget.endsOn} omitTime />
                </div>

                <div className="mb-2">
                  <div className="font-medium">Remaining Balance</div>
                  {statement.budget.balance < 0 ? (
                    <span className="text-red-500">
                      {statement.budget.formattedBalance}
                    </span>
                  ) : (
                    statement.budget.formattedBalance
                  )}
                </div>
              </div>
            </BasicDisplay>

            {(statement.aasmState === "generated" ||
              statement.aasmState === "pending") && (
              <RemoveStatement statement={statement} />
            )}

            <hr />

            <BudgetlessSessions statementId={statement.id} />
            <UnbilledSessions statementId={statement.id} />
          </>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const statementQuery = gql`
  query ($statementId: ID!) {
    statement(id: $statementId) {
      id
      title
      aasmState
      mayPaymentReceived
      mayRegenerate
      maySubmit
      formattedTotal
      pdfGeneratedAt
      invoiceNumber
      dateRangeDisplay
      unbilledSessionsCount
      budgetlessSessionsCount
      includeAllGroups
      includeSchoollessGroups
      studyGroupTags
      autogeneratesBreakdownsByCategory
      subjectGroupings {
        label
        subjects {
          id
          name
        }
      }
      statementConfiguration {
        id
        name
        showPath
      }
      schools {
        id
        name
        showPath
      }
      budget {
        id
        name
        prepaid
        balance
        formattedBalance
        startsOn
        endsOn
        showPath
      }
      pdf {
        downloadUrl
      }
      sessionsCsv {
        downloadUrl
      }
      receipts {
        id
        filename
        downloadUrl
      }
      organization: payer {
        id
        statementDefaultTo
        statementDefaultMessage
      }
    }
  }
`

const updateStatementMutation = gql`
  mutation ($statementId: ID!, $title: String!) {
    updateStatement(statementId: $statementId, title: $title) {
      failures {
        message
      }
      statement {
        title
      }
    }
  }
`

export default StatementDetails
