import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const StudentSessionHistory = ({ studentId }) => {
  const [page, setPage] = useState(1)

  const loadingState = useQuery(
    buildQuery(loadSessionsQuery, { studentId, page })
  )

  const changePage = newPage => {
    setPage(newPage)
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error ocurred while loading Air Tutors sessions"
      heading={<h4>Air Tutors Session History</h4>}
    >
      {({ data }) => {
        if (data.student.airtutorsSessions.data.length === 0) {
          return "No Sessions"
        }
        return (
          <>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Tutor</th>
                  <th>Scheduled State</th>
                  <th>Bill State</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.student.airtutorsSessions.data.map(airtutorsSession => (
                  <tr key={airtutorsSession.id}>
                    <td>
                      <LocalTime
                        omitTime
                        timestamp={airtutorsSession.startsAt}
                      />
                      &nbsp;
                      <LocalTime
                        omitDate
                        timestamp={airtutorsSession.startsAt}
                      />
                      &nbsp;&mdash;&nbsp;
                      <LocalTime omitDate timestamp={airtutorsSession.endsAt} />
                    </td>
                    <td>{airtutorsSession.subject.name}</td>
                    <td>
                      {airtutorsSession.tutor && (
                        <a href={airtutorsSession.tutor.showPath}>
                          {airtutorsSession.tutor.fullName}
                        </a>
                      )}
                    </td>
                    <td>{airtutorsSession.statusState}</td>
                    <td>{airtutorsSession.billState}</td>
                    <td>
                      <a
                        href={airtutorsSession.showPath}
                        className="btn btn-sm btn-info"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPages={data.student.airtutorsSessions.totalPages}
              currentPage={page}
              changePage={changePage}
            />
          </>
        )
      }}
    </WithLoadingIndicator>
  )
}

const loadSessionsQuery = compress`
  query($studentId: ID!, $page: Int) {
    student(id: $studentId) {
      airtutorsSessions(page: $page) {
        totalPages
        data {
          id
          startsAt
          endsAt
          statusState
          billState
          showPath
          subject {
            name
          }
          tutor {
            id
            fullName
            showPath
          }
        }
      }
    }
  }
`

StudentSessionHistory.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default StudentSessionHistory
