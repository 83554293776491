import React from "react"

import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import Chip from "~tailwindui/ChipSimple"

import type { TimePeriod } from "src/types"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type AmountBilledDataSetChipProps = {
  label: string
  startingAt: Dayjs
  endingAt: Dayjs
  groups?: [string]
  timePeriod?: TimePeriod
}

const AmountBilledDataSetChip: React.FC<AmountBilledDataSetChipProps> = ({
  label,
  startingAt,
  endingAt,
  groups = [],
  timePeriod,
}) => {
  const today = dayjs().format("MM/DD/YYYY")
  const [result] = useQuery({
    query,
    variables: {
      startingAt,
      endingAt,
      lastThirtyStart: dayjs(today).subtract(30, "days").startOf("day"),
      lastThirtyEnd: dayjs(today).endOf("day"),
      groups,
      timePeriod,
    },
  })
  return (
    <UrqlLoadingIndicator result={result}>
      {({
        data: {
          viewer: {
            organization: { customRange, lastThirty },
          },
        },
      }) => (
        <Chip.Item
          label={label}
          value={customRange.total / 100}
          valueFormat={ValueFormat.Currency}
          subtitle="Last 30 Days:"
          subtitleValue={lastThirty.total / 100}
          subtitleValueFormat={ValueFormat.Currency}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query AmountBilledDataSetChipQuery(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $lastThirtyStart: DateTime!
    $lastThirtyEnd: DateTime!
    $groups: [GroupsEnum!]!
    $timePeriod: GroupByTimePeriodInputObject
  ) {
    viewer {
      organization {
        customRange: amountBilledDataSet(
          filters: { startingAt: $startingAt, endingAt: $endingAt }
          groupByOptions: { groups: $groups, timePeriod: $timePeriod }
        ) {
          total
        }
        lastThirty: amountBilledDataSet(
          filters: { startingAt: $lastThirtyStart, endingAt: $lastThirtyEnd }
          groupByOptions: { groups: $groups, timePeriod: $timePeriod }
        ) {
          total
        }
      }
    }
  }
`

export default AmountBilledDataSetChip
