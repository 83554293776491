import React from "react"

import { useField } from "formik"

import { RadioField } from "components/Forms/Formik/hookComponents"

const SubjectSelection = ({ courses, nextStep }) => {
  const [subjectField] = useField("subjectId")
  const subjects = courses.map(course => course.subject)
  // find uniq subjects by subject#id
  const uniqSubjects = subjects.filter(
    (subject, index) => subjects.findIndex(s => s.id === subject.id) === index
  )

  const handleNextStep = () => {
    nextStep()
  }

  return (
    <>
      <RadioField
        name="subjectId"
        label="Select Subject"
        options={uniqSubjects.map(subject => ({
          value: subject.id,
          label: subject.name,
        }))}
      />
      <button
        type="button"
        onClick={handleNextStep}
        disabled={subjectField.value === ""}
        className="btn solid blue"
      >
        Next: Program Duration
      </button>
    </>
  )
}

export default SubjectSelection
