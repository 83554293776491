import React from "react"

import { NotificationContainer } from "react-notifications"

import { gql, useQuery } from "hooks/urql"

import CollapsibleContainer from "components/CollapsibleContainer"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import BudgetlessStudyGroups from "./BudgetlessStudyGroups"
import DurationUpdateRequests from "./DurationUpdateRequests"
import NeedsMatchingUsers from "./NeedsMatchingUsers"
import NoShowStudents from "./NoShowStudents"
import OpenStudyGroupReplacements from "./OpenStudyGroupReplacements"
import PendingTutorObservations from "./PendingTutorObservations"
import PendingTutorSubjects from "./PendingTutorSubjects"
import UnassignedCourseGroups from "./UnassignedCourseGroups"
import UncontactedStudents from "./UncontactedStudents"
import UnobservedTutors from "./UnobservedTutors"

import "react-notifications/lib/notifications.css"

const Heading = ({ children }) => <h1 className="m-0 text-3xl">{children}</h1>

const AdminDashboard = () => {
  const [result] = useQuery({ query: adminQuery })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <NotificationContainer />
          {data.viewer.canAdminTutorApplications && (
            <>
              <CollapsibleContainer
                title={<Heading>Pending Tutor Subjects</Heading>}
                defer
              >
                <PendingTutorSubjects />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminSessions && (
            <>
              <CollapsibleContainer
                title={<Heading>Duration Update Requests</Heading>}
                defer
              >
                <DurationUpdateRequests />
              </CollapsibleContainer>
              <hr />
              <CollapsibleContainer
                title={<Heading>Open Study Group Tutor Replacements</Heading>}
                defer
              >
                <OpenStudyGroupReplacements />
              </CollapsibleContainer>
              <hr />
              <CollapsibleContainer
                title={<Heading>No Show Students</Heading>}
                defer
              >
                <NoShowStudents />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUsers && (
            <>
              <CollapsibleContainer
                title={<Heading>Uncontacted Students</Heading>}
                defer
              >
                <UncontactedStudents />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUserNeedsMatching && (
            <>
              <CollapsibleContainer
                title={
                  <Heading>Needs Matching - Recently added CC/Package</Heading>
                }
                defer
              >
                <NeedsMatchingUsers />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUsers && (
            <>
              <CollapsibleContainer
                title={<Heading>Unobserved Tutors</Heading>}
                defer
              >
                <UnobservedTutors />
              </CollapsibleContainer>
              <hr />

              <CollapsibleContainer
                title={<Heading>Your Pending Tutor Observations</Heading>}
                defer
              >
                <PendingTutorObservations />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminOrganizations && (
            <>
              <CollapsibleContainer
                title={<Heading>Unassigned Course Groups</Heading>}
                defer
              >
                <UnassignedCourseGroups />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminBudgets && (
            <>
              <CollapsibleContainer
                title={<Heading>Study Groups Needing Budgets</Heading>}
                defer
              >
                <BudgetlessStudyGroups />
              </CollapsibleContainer>
            </>
          )}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const adminQuery = gql`
  query DashboardPermissions {
    viewer {
      canAdminUsers
      canAdminUserNeedsMatching
      canAdminSessions
      canAdminOrganizations
      canAdminTutorApplications
      canAdminBudgets
    }
  }
`

export default AdminDashboard
