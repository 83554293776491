const requireInput = values => {
  if (
    Object.values(values).every(
      p =>
        !p ||
        p?.length === 0 ||
        (typeof p === "string" && p.trim().length === 0)
    )
  ) {
    return { base: "Please specify at least 1 search parameter" }
  }
}

export default requireInput
