import React from "react"

const Positive = ({ className = "", TagName = "i" }) => (
  <TagName
    className={`nc-icon nc-check-2 font-weight-bold text-success ${className}`}
  />
)

const Negative = ({ className, TagName = "i" }) => (
  <TagName
    className={`nc-icon nc-simple-remove font-weight-bold text-danger ${className}`}
  />
)

const Neutral = ({ className, TagName = "i" }) => (
  <TagName className={`text-warning ${className}`}>&mdash;</TagName>
)

const Loading = ({ className = "", TagName = "i" }) => (
  <TagName className={`nc-icon nc-refresh-69 spinner ${className}`} />
)

export { Loading, Negative, Neutral, Positive }
