import React from "react"

import { useFormikContext } from "formik"

const ValidationNotice = () => {
  const { isValid, errors } = useFormikContext()

  const extractMessages = error => {
    if (typeof error === "string") {
      return [error]
    } else if (Array.isArray(error)) {
      return error
        .filter(Boolean)
        .map(e => Object.values(e))
        .flat()
    } else {
      return ""
    }
  }

  if (isValid) return null
  return (
    <div className="alert alert-danger d-inline-block my-5 p-3 text-left">
      <div>
        Please correct the following validation errors
        <ul className="ml-5">
          {Object.values(errors).map(error =>
            extractMessages(error).map(message => (
              <li key={message} className="list-disc">
                {message}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
}

export default ValidationNotice
