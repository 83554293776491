import React from "react"

import { gql, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const ForeignLanguagesTable = () => {
  const [result] = useQuery({ query: foreignLanguagesQuery })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <table className="table">
            <thead className="text-primary">
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {data.foreignLanguages.map(foreignLanguage => (
                <tr key={foreignLanguage.id}>
                  <td>{foreignLanguage.name}</td>
                  <td>
                    <a
                      href={foreignLanguage.showPath}
                      className="btn btn-sm btn-info"
                    >
                      Details
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const foreignLanguagesQuery = gql`
  query {
    foreignLanguages {
      id
      name
      showPath
    }
  }
`

export default ForeignLanguagesTable
