import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ConfirmPrivateSession = ({ schedulePath, oneOnOnesPath }) => {
  return (
    <ModalWithProvidedBody
      buttonClassName="btn solid green"
      buttonText="Schedule Privately Billed Session"
      modalClassName="bootstrap-modal"
      modalTitle="Confirm Privately Billed Session"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <div className="modal-body">
            <p>
              This will schedule a session billed directly to the student.{" "}
              <span className="bold">
                If you are trying to schedule a 1-1 session via the student's
                organization, please{" "}
                {oneOnOnesPath ? (
                  <a href={oneOnOnesPath}>go here.</a>
                ) : (
                  "reach out to admin."
                )}
              </span>
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="btn solid blue"
              onClick={() => {
                closeModal()
                window.location = schedulePath
              }}
            >
              Yes
            </button>
            <button className="btn solid red" onClick={closeModal}>
              No
            </button>
          </div>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

export default ConfirmPrivateSession
