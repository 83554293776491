import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import UserInfoModal from "./UserInfoModal"

const NeedsMatchingUsers = () => {
  const [page, setPage] = useState(1)
  const [user, setUser] = useState()
  const [result] = useQuery({ query: needsMatchingQuery, variables: { page } })

  const reset = () => {
    setUser()
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <UserInfoModal user={user} reset={reset} />
          <table className="table">
            <thead className="text-primary">
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.users.data.map(user => (
                <tr key={user.id}>
                  <td>
                    <a href={user.showPath}>{user.fullName}</a>
                  </td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => setUser(user)}
                    >
                      Contact
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            currentPage={data.currentPage}
            totalPages={data.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const needsMatchingQuery = gql`
  query ($page: Int!) {
    users: needsMatchingUsers(page: $page) {
      currentPage
      totalPages
      data {
        id
        fullName
        email
        phoneNumber
        showPath
        type
      }
    }
  }
`

export default NeedsMatchingUsers
