import React, { useEffect, useState } from "react"

import { FieldArray } from "formik"

import axios from "src/axios"

import Pagination from "components/Pagination"

type QuestionGroupOptionsProps = {
  values: {
    questionGroupIds: number[]
  }
  tags: string[]
}

const QuestionGroupOptions: React.FC<QuestionGroupOptionsProps> = ({
  values,
  tags,
}) => {
  const [questionGroups, setQuestionGroups] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    axios
      .get("/api/admins/tailored_question_groups", {
        params: { page, tags: tags },
      })
      .then(response => {
        const { pages, data: questionGroups } = response.data
        setTotalPages(pages)
        setQuestionGroups(questionGroups)
      })
  }, [tags, page])

  return (
    <FieldArray name="questionGroupIds">
      {arrayHelpers => (
        <React.Fragment>
          {questionGroups.map(
            ({ id, internalName, diagrams, questionCount }) => (
              <div className="form-check" key={id}>
                <label className="form-check-label">
                  <div className="mb-3 italic">
                    {internalName}: {questionCount} included questions
                  </div>
                  {diagrams.map(diagram => (
                    <div key={diagram.url}>
                      <img
                        src={diagram.url}
                        alt="diagram"
                        className="mb-5 max-h-[50vh]"
                      />
                    </div>
                  ))}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={id}
                    defaultChecked={values.questionGroupIds.includes(id)}
                    onChange={e =>
                      e.target.checked
                        ? arrayHelpers.push(id)
                        : arrayHelpers.remove(
                            values.questionGroupIds.indexOf(id)
                          )
                    }
                  />
                  <span className="form-check-sign" />
                </label>
              </div>
            )
          )}
          <div className="row">
            <div className="col-4">
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                changePage={setPage}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </FieldArray>
  )
}

export default QuestionGroupOptions
