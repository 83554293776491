import React from "react"

import { useFormikContext } from "formik"

import { Field } from "components/Forms/Formik/hookComponents"
import OrganizationConsentFields from "components/Student/Intake/OrganizationConsentFields"

const ParentAccountFields = ({ organization }) => {
  const { values } = useFormikContext()

  if (!values.createParentAccount) return null
  return (
    <>
      <Field name="parentEmail" type="email" label="Parent Email" disabled />
      <Field
        name="parentPassword"
        type="password"
        label="Parent Password"
        autoComplete="new-password"
      />
      <Field
        name="parentPasswordConfirmation"
        type="password"
        label="Parent Password Confirmation"
        autoComplete="new-password"
      />
      {organization.parentConsentRequired && (
        <OrganizationConsentFields
          label="Parent Consent"
          signatureLabel="Parent Signature"
          acceptanceName="parentOrganizationConsentAccepted"
          signatureName="parentOrganizationConsentSignature"
          organization={organization}
        />
      )}
      <hr />
    </>
  )
}

export default ParentAccountFields
