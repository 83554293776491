import React from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import InlineConfirmation from "components/InlineConfirmation"

const RemoveQuestionGroup = ({ id }) => {
  const [, removeQuestionGroup]: any[] = useMutation(
    removeQuestionGroupMutation
  )

  const removeGroup = () => {
    return removeQuestionGroup({ id })
      .then(
        result => {
          const { failures } = result.data.tailoredQuestionRemoveGroup
          if (failures.length > 0) {
            NotificationManager.error(
              <ul className="list-unstyled m-0">
                {failures.map(f => (
                  <li key={f.message}>{f.message}</li>
                ))}
              </ul>,
              "Failed to delete group"
            )
          } else {
            NotificationManager.success("Group Deleted")
          }
        },
        () => NotificationManager.error("Failed to delete group")
      )
      .catch(() => NotificationManager.error("Failed to delete group"))
  }

  return (
    <div className="flex justify-between">
      <InlineConfirmation
        buttonText="Remove Group"
        type="danger"
        size="small"
        handleSubmit={removeGroup}
      />
    </div>
  )
}

const removeQuestionGroupMutation = gql`
  mutation tailoredQuestionRemoveGroup($id: ID!) {
    tailoredQuestionRemoveGroup(id: $id) {
      tailoredQuestionGroup {
        id
      }
      failures {
        message
      }
    }
  }
`

export default RemoveQuestionGroup
