import { compress } from "micro-graphql-react"

export default compress`
  query($tutorId: ID!) {
    tutor(id: $tutorId) {
      email

      fullName
      fullNameReviewRequired
      fullNameReviewFeedback

      phoneNumber
      phoneNumberReviewRequired
      phoneNumberReviewFeedback

      bio
      bioReviewRequired
      bioReviewFeedback

      education
      educationReviewRequired
      educationReviewFeedback

      emailTimeZone
      emailTimeZoneReviewRequired
      emailTimeZoneReviewFeedback

      avatarReviewRequired
      avatarReviewFeedback

      tierIdsReviewRequired
      tierIdsReviewFeedback

      avatar {
        url
        thumbUrl
      }
      tiers {
        id
      }
    }
    tiers {
      id
      gradeRange
    }
  }
`
