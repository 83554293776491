import React, { useEffect, useRef, useState } from "react"

import Ding from "assets/ding.mp3"
import { Composition, useViewportChange } from "atomic-layout"
import { Map as ImmutableMap } from "immutable"
import Div100Vh from "react-div-100vh"
import convertStyle from "react-div-100vh/lib/convertStyle"
import Video from "twilio-video"

import { ThemeProvider, useDisclosure } from "@chakra-ui/core"
import { css } from "@emotion/core"
import {
  faDesktop,
  faLock,
  faLockOpen,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EventEmitter from "src/EventEmitter"
import airbrake from "src/airbrake-notifier"

import LoadingIndicator from "components/LoadingIndicator"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import theme from "components/Pages/theme"
import RealtimeboardDirectLink from "components/RealtimeboardDirectLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"
import AudioButton from "components/TwilioCloudRoom/AudioButton"
import ErrorModal from "components/TwilioCloudRoom/ErrorModal"
import VideoButton from "components/TwilioCloudRoom/VideoButton"
import useCloudRoom from "components/TwilioCloudRoom/hooks/useCloudRoom"
import useScreenShareActive from "components/TwilioCloudRoom/hooks/useScreenShareActive"

import SurveyModal from "../Student/SurveyModal"

import CompleteSessionModal from "./CompleteSessionModal"
import CompleteWorksheetButtons from "./CompleteWorksheetButtons"
import IncomingStudentsModal from "./IncomingStudentsModal"
import MuteAllButton from "./MuteAllButton"
import ScreenShareLayout from "./ScreenShareLayout"
import ScreenShareRequestedModal from "./ScreenShareRequestedModal"
import SendSmsButton from "./SendSmsButton"
import VideoLayout from "./VideoLayout"

const TutorCloudRoom = props => {
  const {
    id,
    startsAt,
    endsAt,
    api,
    ssoEnabled,
    boardId,
    ssoEmail,
    studentScreenShareDisabled,
    phoneNumber,
    surveyId,
  } = props

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: incomingStudentsModalIsOpen,
    onOpen: incomingStudentsModalOnOpen,
    onClose: incomingStudentsModalOnClose,
  } = useDisclosure()
  const {
    isOpen: surveyModalIsOpen,
    onOpen: surveyModalOnOpen,
    onClose: surveyModalOnClose,
  } = useDisclosure()
  const previewRef = useRef(null)
  const screenShareTrack = useRef(null)
  const [error, setError] = useState()
  const [screenSharing, setScreenSharing] = useState(false)
  const [screenShareRequestedBy, setScreenShareRequestedBy] = useState({})
  const [audioMuted, setAudioMuted] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const [audioMuteAll, setAudioMuteAll] = useState(props.audioMuted)
  const [videoMuteAll, setVideoMuteAll] = useState(props.videoMuted)
  const [controlsLocked, setControlsLocked] = useState(props.controlsLocked)
  const [controlsLockedUserIds, setControlsLockedUserIds] = useState(
    props.controlsLockedUserIds
  )
  const [users, setUsers] = useState(ImmutableMap())
  const { room, localVideoTrack, channel } = useCloudRoom({
    previewRef,
    setUsers,
    setError,
    ...props,
  })
  const screenShareActive = useScreenShareActive(users)

  useEffect(() => {
    if (!room) return

    EventEmitter.subscribe("mute_all", data => {
      if (data.type === "audio") setAudioMuteAll(true)
      if (data.type === "video") setVideoMuteAll(true)
      setControlsLocked(true)
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("unmute_all", data => {
      if (data.type === "audio") setAudioMuteAll(false)
      if (data.type === "video") setVideoMuteAll(false)
      setControlsLocked(false)
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("lock_user", data => {
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("unlock_user", data => {
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("lock_all", data => {
      setControlsLocked(true)
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("unlock_all", data => {
      setControlsLocked(false)
      setControlsLockedUserIds(data.controlsLockedUserIds)
    })

    EventEmitter.subscribe("screen_share_request", data => {
      setScreenShareRequestedBy(data)
      onOpen()
    })

    EventEmitter.subscribe("end_session", data => {
      if (api !== "teachers") {
        window.location = data.tutorRedirectTo
      } else {
        window.location = "/"
      }
    })

    EventEmitter.subscribe("student_survey", () => {
      surveyModalOnOpen()
    })

    EventEmitter.subscribe("incoming_students", () => {
      new Audio(Ding).play()
      incomingStudentsModalOnOpen()
    })
  }, [api, onOpen, room, incomingStudentsModalOnOpen, surveyModalOnOpen])

  const [rvh, setRvh] = useState({})
  useViewportChange(() => {
    setRvh(
      convertStyle(
        { 20: "20rvh", 80: "80rvh", 100: "100rvh" },
        window.innerHeight
      )
    )
  }, 500)

  const fixVideoHeight = `
    @media (max-width: 770px) {
      .preview video {
        max-height: ${rvh[20]} !important;
      }
    }
  `

  const toggleAllAudio = () => {
    if (audioMuteAll) {
      channel.perform("unmute_all", { type: "audio" })
    } else {
      channel.perform("mute_all", { type: "audio" })
    }
  }

  const toggleAllVideo = () => {
    if (videoMuteAll) {
      channel.perform("unmute_all", { type: "video" })
    } else {
      channel.perform("mute_all", { type: "video" })
    }
  }

  const toggleControlsLocked = () => {
    if (controlsLocked) {
      channel.perform("unlock_all")
    } else {
      channel.perform("lock_all")
    }
  }

  const enableScreenShare = async () => {
    try {
      const constraints = { video: true }
      const tracks = await navigator.mediaDevices.getDisplayMedia(constraints)
      screenShareTrack.current = tracks.getTracks()[0]
      screenShareTrack.current.onended = () => disableScreenShare()

      room.localParticipant.unpublishTrack(localVideoTrack.current)
      const namedTrack = Video.LocalVideoTrack(screenShareTrack.current, {
        name: "screenshare",
      })
      room.localParticipant.publishTrack(namedTrack)
      setScreenSharing(true)
    } catch (e) {
      setScreenSharing(false)
      alert(
        "Screen Share failed. If you did not reject the request, please use the latest version of Chrome on your desktop/laptop."
      )
    }
  }

  const disableScreenShare = () => {
    room.localParticipant.unpublishTrack(screenShareTrack.current)
    room.localParticipant.publishTrack(localVideoTrack.current)
    screenShareTrack.current.stop()
    setScreenSharing(false)
  }

  let realtimeboardButton = null

  if (props.realtimeboardComponent) {
    realtimeboardButton = props.realtimeboardComponent
  } else if (boardId) {
    realtimeboardButton = ssoEnabled ? (
      <>
        <TimeProtectedMiroLink
          email={ssoEmail}
          boardId={boardId}
          startsAt={startsAt}
          buttonClassName="btn solid green w-full"
        />
      </>
    ) : (
      <RealtimeboardDirectLink boardId={boardId} />
    )
  }

  let googledocButton = null
  if (props.googledocComponent) {
    googledocButton = props.googledocComponent
  } else if (props.googledocUrl) {
    googledocButton = (
      <a
        className="btn solid green"
        href={props.googledocUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Doc
      </a>
    )
  }

  let allSessionsButton = null
  if (props.studyGroupPath) {
    allSessionsButton = (
      <a
        href={props.studyGroupPath}
        className="btn solid red"
        target="_blank"
        rel="noopener noreferrer"
      >
        Previous Sessions
      </a>
    )
  } else if (props.studentPath) {
    allSessionsButton = (
      <a
        href={props.studentPath}
        className="btn solid red"
        target="_blank"
        rel="noopener noreferrer"
      >
        Previous Sessions
      </a>
    )
  }

  const desktopLayout = `
    preview main
  `

  const mobileLayout = `
    main
    preview
  `

  if (error) {
    return (
      <ErrorModal
        isOpen={true}
        onClose={() => window.location.reload()}
        userType={api === "teachers" ? "Teacher" : "Tutor"}
        sessionId={id}
        phoneNumber={phoneNumber}
        {...error}
      />
    )
  }

  return (
    <Div100Vh
      css={css`
        background-color: #111;
        @media (min-width: 769px) {
          overflow: hidden;
        }
      `}
    >
      <style dangerouslySetInnerHTML={{ __html: fixVideoHeight }} />
      {!room ? (
        <div
          css={css`
            padding-top: 20vh;
            i {
              font-size: 64px;
              color: white;
            }
          `}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <ScreenShareRequestedModal
            isOpen={isOpen}
            studentName={screenShareRequestedBy.name}
            approveRequest={() => {
              if (!screenShareRequestedBy.userId) {
                airbrake.notify({
                  error:
                    "Not submitting screen share response, no userId is present",
                  name: "TwilioCloudRoom/Tutor",
                  params: { screenShareRequestedBy },
                })
              } else {
                channel.perform("screen_share_response", {
                  approved: true,
                  userId: screenShareRequestedBy.userId,
                })
              }
              setScreenShareRequestedBy({})
              onClose()
            }}
            denyRequest={() => {
              channel.perform("screen_share_response", {
                approved: false,
                userId: screenShareRequestedBy.userId,
              })
              setScreenShareRequestedBy({})
              onClose()
            }}
          />
          <IncomingStudentsModal
            isOpen={incomingStudentsModalIsOpen}
            onClose={incomingStudentsModalOnClose}
          />
          {surveyId && (
            <SurveyModal
              isOpen={surveyModalIsOpen}
              onClose={surveyModalOnClose}
              sessionId={id}
              observationMode={true}
            />
          )}

          <Composition
            areas={mobileLayout}
            templateCols="1fr"
            templateRows="80% 20%"
            areasMd={desktopLayout}
            templateColsMd="20% 80%"
            templateRowsMd="auto"
            gapCol={25}
            css={css`
              height: 100%;
              padding: 10px;
              video {
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
              }
            `}
          >
            {({ Preview, Main }) => (
              <>
                <Preview
                  css={css`
                    position: relative;
                    display: grid !important;
                    @media (min-width: 769px) {
                      grid-template-rows: 3fr 1fr;
                      grid-template-areas:
                        "info"
                        "localPreview";
                      height: 100vh;
                      padding: 10px 0;
                    }
                    @media (max-width: 768px) {
                      grid-template-rows: 1fr;
                      grid-template-columns: 1fr 1fr;
                      grid-template-areas: "info localPreview";
                    }
                  `}
                >
                  <>
                    <div
                      css={css`
                        grid-area: info;
                        overflow: hidden;
                        overflow-y: auto;
                        padding-right: 12px;
                      `}
                    >
                      <div>
                        {channel && (
                          <>
                            <div>
                              <MuteAllButton
                                muted={audioMuteAll}
                                onClick={toggleAllAudio}
                              >
                                {audioMuteAll ? (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Unmute Students
                                    </div>
                                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Mute Students
                                    </div>
                                    <FontAwesomeIcon icon={faMicrophone} />
                                  </>
                                )}
                              </MuteAllButton>
                            </div>

                            <div>
                              <MuteAllButton
                                muted={videoMuteAll}
                                onClick={toggleAllVideo}
                              >
                                {videoMuteAll ? (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Enable Student Video
                                    </div>
                                    <FontAwesomeIcon icon={faVideoSlash} />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Disable Student Video
                                    </div>
                                    <FontAwesomeIcon icon={faVideo} />
                                  </>
                                )}
                              </MuteAllButton>
                            </div>

                            <div>
                              <MuteAllButton
                                muted={controlsLocked}
                                onClick={toggleControlsLocked}
                              >
                                {controlsLocked ? (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Unlock Student Controls
                                    </div>
                                    <FontAwesomeIcon icon={faLock} />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      css={css`
                                        width: 100%;
                                      `}
                                    >
                                      Lock Student Controls
                                    </div>
                                    <FontAwesomeIcon icon={faLockOpen} />
                                  </>
                                )}
                              </MuteAllButton>
                            </div>
                          </>
                        )}

                        {navigator.mediaDevices &&
                          navigator.mediaDevices.getDisplayMedia && (
                            <div>
                              <MuteAllButton
                                muted={screenSharing}
                                onClick={
                                  screenSharing
                                    ? disableScreenShare
                                    : enableScreenShare
                                }
                              >
                                <div
                                  css={css`
                                    width: 100%;
                                  `}
                                >
                                  {screenSharing
                                    ? "Stop Sharing Your Screen"
                                    : "Share Your Screen"}
                                </div>
                                <FontAwesomeIcon icon={faDesktop} />
                              </MuteAllButton>
                            </div>
                          )}

                        <div
                          css={css`
                            a,
                            button {
                              display: block;
                              margin-bottom: 6px;
                            }
                          `}
                        >
                          {allSessionsButton}
                          {api !== "teachers" && (
                            <CompleteWorksheetButtons airtutorsSessionId={id} />
                          )}
                          {realtimeboardButton}
                          {googledocButton}
                          {api !== "teachers" && (
                            <>
                              <ModalWithProvidedBody
                                buttonClassName="btn solid blue w-100"
                                buttonText="End Session"
                                modalClassName="bootstrap-modal"
                                modalTitle="End Session"
                              >
                                {({ closeModal }) => (
                                  <CompleteSessionModal
                                    id={id}
                                    endsAt={endsAt}
                                    closeModal={closeModal}
                                    channel={channel}
                                  />
                                )}
                              </ModalWithProvidedBody>
                              <ThemeProvider theme={theme}>
                                <SendSmsButton airtutorsSessionId={id} />
                              </ThemeProvider>
                            </>
                          )}
                        </div>

                        {surveyId && (
                          <button
                            onClick={surveyModalOnOpen}
                            className="btn solid blue w-100 mb-1"
                          >
                            Re-Open Survey Modal
                          </button>
                        )}

                        {(props.studyGroupDetails || props.studentNotes) && (
                          <div
                            css={css`
                              background-color: white;
                              border: 1px solid black;
                              border-radius: 6px;
                              overflow-y: scroll;
                              padding: 6px;
                            `}
                          >
                            {props.studyGroupDetails && (
                              <>
                                <h2
                                  css={css`
                                    margin: 0;
                                  `}
                                >
                                  Group Details
                                </h2>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: props.studyGroupDetails,
                                  }}
                                />
                              </>
                            )}

                            {props.studentNotes && (
                              <>
                                <h2>Student Notes</h2>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: props.studentNotes,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      css={css`
                        grid-area: localPreview;
                        position: relative;
                        z-index: 100;
                      `}
                    >
                      <div ref={previewRef} />
                      <div
                        css={css`
                          position: absolute;
                          bottom: 10px;
                          right: 6px;
                        `}
                      >
                        <AudioButton
                          room={room}
                          channel={channel}
                          muted={audioMuted}
                          setMuted={setAudioMuted}
                          styles={{ display: "flex" }}
                        />
                        <VideoButton
                          room={room}
                          channel={channel}
                          muted={videoMuted}
                          setMuted={setVideoMuted}
                          styles={{ display: "flex" }}
                        />
                      </div>
                    </div>
                  </>
                </Preview>

                <Main
                  css={css`
                    overflow: hidden;
                  `}
                >
                  {screenShareActive ? (
                    <ScreenShareLayout
                      users={users}
                      channel={channel}
                      room={room}
                      rvh={rvh}
                      studentScreenShareDisabled={studentScreenShareDisabled}
                      controlsLockedUserIds={controlsLockedUserIds}
                      id={id}
                      api={api}
                    />
                  ) : (
                    <VideoLayout
                      users={users}
                      channel={channel}
                      room={room}
                      rvh={rvh}
                      studentScreenShareDisabled={studentScreenShareDisabled}
                      controlsLockedUserIds={controlsLockedUserIds}
                      id={id}
                      api={api}
                    />
                  )}
                </Main>
              </>
            )}
          </Composition>
        </>
      )}
    </Div100Vh>
  )
}

TutorCloudRoom.defaultProps = {
  api: "tutors",
}

export default TutorCloudRoom
