import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const MissingTutorModal = ({ isOpen, onClose }) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Please wait, session will begin soon"
      closeModal={onClose}
      hideCloseButton
    >
      <>
        <div className="modal-body">
          <p className="lead">
            Your tutor is running late. Please wait while we contact them or
            find a replacement for your session.
          </p>
        </div>
      </>
    </ModalWithProvidedBody>
  )
}

export default MissingTutorModal
