import React, { useEffect, useRef, useState } from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import { NotificationContainer, NotificationManager } from "react-notifications"

import { useDisclosure } from "@chakra-ui/core"
import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"
import { adminClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateObject } from "components/Forms/DatePicker"
import { RadioGroup, SubmitButton } from "components/Forms/Formik"
import { DateTimeField } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import "react-notifications/lib/notifications.css"

const TutorInviteTrigger = ({ organizationId }) => {
  const formState = {
    organizationId: organizationId,
    tutorIds: [],
    maxRegistrations: 1,
    expiresAt: new DateObject()
      .setHour(0)
      .setMinute(0)
      .setSecond(0)
      .add(2, "days"),
  }
  const selectRef = useRef()
  const tutorIdsArrayHelpers = useRef()
  const { runMutation } = useMutation(buildMutation(createTutorInvitesMutation))
  const [tags, setTags] = useState([])
  const [matchingTutors, setMatchingTutors] = useState([])
  const [errors, setErrors] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (tags === []) {
      setMatchingTutors([])
      return
    }

    client
      .runQuery(tutorSearchQuery, { tagList: tags.map(tag => tag.label) })
      .then(
        response => {
          if (response.data) {
            setMatchingTutors(response.data.tutors)
            if (tutorIdsArrayHelpers.current) {
              const ids = response.data.tutors.map(tutor => tutor.id)
              tutorIdsArrayHelpers.current.form.setFieldValue("tutorIds", ids)
            }
          } else {
            setErrors("Error while loading tutors")
            airbrake.notify({
              name: "TutorInviteTrigger#loadTutors (success)",
              params: { response },
            })
          }
        },
        error => {
          setErrors(`Something went wrong: ${error?.message}`)
          airbrake.notify({
            error,
            name: "TutorInviteTrigger#loadTutors (rejected)",
          })
        }
      )
      .catch(error => {
        setErrors(`Something went wrong: ${error?.message}`)
        airbrake.notify({
          error,
          name: "TutorInviteTrigger#loadTutors (catch)",
        })
      })
  }, [tags])

  const handleSubmit = (values, actions, closeModal) => {
    const { expiresAt, ...params } = values
    runMutation({
      expiresAt: expiresAt.toDate(),
      ...params,
    })
      .then(
        response => {
          const { success, errorMessages } =
            response.createStudyGroupTutorInvites
          if (success) {
            closeModal()
            NotificationManager.info("Invitations will be sent momentarily")
          } else {
            actions.setStatus(errorMessages)
          }
        },
        error => {
          actions.setStatus(`Request rejected: ${JSON.stringify(error)}`)
          airbrake.notify({
            error,
            name: "TutorInviteTrigger#createInvites (rejected)",
          })
        }
      )
      .catch(error => {
        actions.setStatus(`Error caught: ${JSON.stringify(error)}`)
        airbrake.notify({
          error,
          name: "TutorInviteTrigger#createInvites (caught)",
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <>
      <NotificationContainer />
      <ModalWithProvidedBody
        isOpen={isOpen}
        closeModal={() => {
          setMatchingTutors([])
          setTags([])
          onClose()
        }}
        openModal={onOpen}
        buttonClassName="btn btn-info"
        buttonText="Invite Tutors"
        modalTitle="Invite Tutors"
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
          >
            {({ values, status, isSubmitting }) => (
              <Form
                onKeyDown={e => {
                  if (e.keyCode === 13) e.preventDefault()
                }}
              >
                <div className="modal-body">
                  <div className="form-group">
                    <label className="w-100">
                      Find tutors by tag
                      <AutocompleteSelect
                        menuPortalTarget={document.body}
                        api="/api/admins/tutors/autocomplete_tags"
                        isMulti
                        ref={selectRef}
                        onChange={selected => {
                          selected && setTags(selected)
                        }}
                      />
                    </label>
                  </div>

                  <div>
                    <FieldArray name="tutorIds">
                      {arrayHelpers => {
                        tutorIdsArrayHelpers.current = arrayHelpers
                        return (
                          <RadioGroup
                            type="checkbox"
                            name="tutorIds"
                            values={ordered(matchingTutors, "lastName").map(
                              tutor => ({
                                value: tutor.id,
                                label: tutor.fullName,
                                checked: values.tutorIds.includes(tutor.id),
                              })
                            )}
                            onChange={e => {
                              if (e.currentTarget.checked) {
                                arrayHelpers.push(e.currentTarget.value)
                              } else {
                                arrayHelpers.remove(
                                  values.tutorIds.findIndex(
                                    s => s === e.currentTarget.value
                                  )
                                )
                              }
                            }}
                          />
                        )
                      }}
                    </FieldArray>
                  </div>

                  <div className="form-group">
                    <label>
                      Maximum Registrations per tutor
                      <div>
                        <Field
                          name="maxRegistrations"
                          type="number"
                          min="1"
                          className="form-control"
                        />
                      </div>
                    </label>
                  </div>
                  <DateTimeField name="expiresAt" label="Expiration" />
                </div>
                <div className="modal-footer flex-column">
                  {status && (
                    <div className="alert alert-danger w-100">{status}</div>
                  )}
                  {errors && (
                    <div className="alert alert-danger w-100">{errors}</div>
                  )}
                  <div className="d-flex justify-content-between w-100">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      containerCss={css`
                        margin: 0;
                      `}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const tutorSearchQuery = compress`
  query($tagList: [String!]) {
    tutors(tagList: $tagList) {
      id
      fullName
      lastName
    }
  }
`

const createTutorInvitesMutation = compress`
  mutation($organizationId: ID!, $tutorIds: [ID!]!, $maxRegistrations: Int!, $expiresAt: DateTime!) {
    createStudyGroupTutorInvites(
      organizationId: $organizationId
      tutorIds: $tutorIds
      maxRegistrations: $maxRegistrations
      expiresAt: $expiresAt
    ) {
      success
      errorMessages
    }
  }
`

export default TutorInviteTrigger
