import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useClient, useMutation } from "hooks/urql"

import Checkbox from "components/Forms/Checkbox"
import { ErrorMessage } from "components/Forms/Formik"
import {
  Field,
  FileField,
  FormFailures,
  SubmitButton,
  TextAreaField,
  TimeZoneField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import GradeField from "components/Forms/Formik/hookComponents/RemoteGradeField"

import OrganizationField from "./OrganizationField"
import SchoolField from "./SchoolField"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please specify a first name"),
  lastName: Yup.string().required("Please specify a last name"),
  email: Yup.mixed().when("username", {
    is: username => !username || username.trim() === "",
    then: Yup.string().required("Email or username is required"),
  }),
  gradeId: Yup.string().required("Please select a grade"),
  schoolId: Yup.mixed().when("organizationId", {
    is: id => id,
    then: Yup.string().required("Please select a school"),
  }),
  schoolName: Yup.mixed().when("organizationId", {
    is: id => !id,
    then: Yup.string().required("Please select a school"),
  }),
  emailTimeZone: Yup.string().required("Please select a time zone"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("password")], "Passwords do not match"),
})

const NewStudent = () => {
  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    phoneNumber: "",
    gradeId: "",
    organizationId: "",
    schoolId: "",
    schoolName: "",
    emailTimeZone: "",
    password: "",
    passwordConfirmation: "",
    avatar: {},
    notes: "",
  }
  const [registerWithUsername, setRegisterWithUsername] = useState(false)
  const client = useClient()
  const [, runMutation] = useMutation(createMutation, client)

  const handleSubmit = (values, actions) => {
    const { avatar, ...params } = { ...values }
    if (params.schoolId) delete params.schoolName
    const unusedLoginKey = registerWithUsername ? "email" : "username"
    params[unusedLoginKey] = ""

    const createStudent = params => {
      runMutation({ input: params })
        .then(
          response => {
            if (response.error) {
              handleFailure(actions, [response.error])
              return
            }

            const { failures } = response.data.studentCreate
            if (failures) {
              handleFailure(actions, failures)
            } else {
              window.location = "/"
            }
          },
          e => handleFailure(actions, [e])
        )
        .catch(e => handleFailure(actions, [e]))
    }

    if (avatar.name || avatar.fileName) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
        const b64 = reader.result.replace(/^data:.+;base64,/, "")

        const avatarObject = { file: b64 }
        const filename = avatar.name || avatar.fileName
        const extension = (avatar.name || avatar.fileName).split(".").pop()
        if (!filename) {
          avatarObject.filename = `${filename}.${extension}`
        } else {
          avatarObject.filename = filename
        }
        params.avatar = avatarObject

        createStudent(params)
      }
      reader.readAsDataURL(avatar)
    } else {
      createStudent(params)
    }
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Field name="firstName" label="First Name" autoFocus />
        <Field name="lastName" label="Last Name" />
        {registerWithUsername ? (
          <>
            <Field name="username" label="Username" />
            <ErrorMessage name="email" className="!mt-[2px] !mb-[18px]" />
          </>
        ) : (
          <Field name="email" label="Email" autoComplete="email" />
        )}
        <Checkbox
          name="registerWithUsername"
          onChange={e => {
            if (e.currentTarget.checked) {
              setRegisterWithUsername(true)
            } else {
              setRegisterWithUsername(false)
            }
          }}
        >
          Register with username instead
        </Checkbox>
        <Field name="phoneNumber" label="Phone Number" />
        <GradeField />
        <OrganizationField />
        <SchoolField />
        <TimeZoneField name="emailTimeZone" />
        <FileField name="avatar" label="Avatar" accept="image/*" />
        <Field name="password" type="password" label="Password" />
        <Field
          name="passwordConfirmation"
          type="password"
          label="Confirm Password"
        />
        <TextAreaField name="notes" label="Notes" />

        <FormFailures />
        <SubmitButton />
      </Form>
    </Formik>
  )
}

const createMutation = gql`
  mutation ($input: StudentInputObject!) {
    studentCreate(input: $input) {
      failures {
        message
      }
    }
  }
`

export default NewStudent
