import React, { useEffect, useMemo, useRef, useState } from "react"

import EventEmitter from "src/EventEmitter"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"
import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

export type CompleteWorksheetButtonsProps = {
  airtutorsSessionId: string
}

const CompleteWorksheetButtons: React.FC<CompleteWorksheetButtonsProps> = ({
  airtutorsSessionId,
}) => {
  const resourceDownloadLink = useRef<HTMLAnchorElement>()
  const [downloadUrl, setDownloadUrl] = useState<string>("")
  const [errors, setErrors] = useState<string>("")

  const client = useClient()

  useEffect(() => {
    if (downloadUrl === "" || !resourceDownloadLink.current) return
    resourceDownloadLink.current.click()
  }, [downloadUrl])

  const [result, refetchSession] = useQuery({
    query: currentLessonResourcesQuery,
    variables: { airtutorsSessionId },
    client,
  })

  const [completeResourceState, runCompleteResource]: any[] = useMutation(
    completeLessonResourceMutation,
    client
  )

  const [downloadResourceState, runDownloadResource]: any[] = useMutation(
    downloadLessonResourceMutation,
    client
  )

  const completeResource = lessonResource => {
    runCompleteResource({
      airtutorsSessionId,
      lessonResourceId: lessonResource.id,
    }).then(response => {
      const { errorMessages, airtutorsSession, nextResource } =
        response.data.completeLessonResource
      if (errorMessages) {
        setErrors(errorMessages)
        return
      }

      setErrors("")
      if (airtutorsSession) refetchSession()
      if (nextResource) {
        runDownloadResource({
          airtutorsSessionId,
          lessonResourceId: nextResource.id,
        }).then(response => {
          setDownloadUrl(
            response.data.downloadLessonResource.lessonResource.file.downloadUrl
          )
        })
      }
    })
  }

  const channelProps = useMemo(() => {
    return { id: airtutorsSessionId }
  }, [airtutorsSessionId])

  useWebsocket({
    channelName: "AirtutorsSessionChannel",
    channelProps,
  })

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      refetchSession()
    })
  })

  return (
    <>
      <a
        ref={resourceDownloadLink}
        href={downloadUrl}
        download
        className="hidden"
      >
        download
      </a>
      <UrqlLoadingIndicator result={result}>
        {({ data }) =>
          data.airtutorsSession.currentLessonResources.map(lessonResource => (
            <ModalWithProvidedBody
              key={lessonResource.id}
              buttonText={`Complete ${lessonResource.name}`}
              buttonClassName="btn solid orange w-100 mb-1 truncate"
              modalClassName="bootstrap-modal"
              modalTitle={`Complete ${lessonResource.name}`}
              afterClose={() => setErrors("")}
            >
              {({ closeModal }) => (
                <>
                  <div className="modal-body">
                    <p>
                      This will complete {lessonResource.name} and automatically
                      download the next worksheet.
                    </p>
                    <p>
                      So make sure to let students know to complete the survey
                      at the end of this session!
                    </p>
                  </div>

                  <div className="modal-footer block">
                    <div className="flex flex-col">
                      {errors && <div className="alert mb-3 p-3">{errors}</div>}
                      <div className="flex justify-between">
                        <button className="btn solid red" onClick={closeModal}>
                          Cancel
                        </button>
                        <button
                          className="btn solid blue"
                          disabled={
                            completeResourceState.fetching ||
                            downloadResourceState.fetching
                          }
                          onClick={() => completeResource(lessonResource)}
                        >
                          <span>Complete + Download</span>
                          {(completeResourceState.fetching ||
                            downloadResourceState.fetching) && (
                            <div className="ml-4 inline-block">
                              <Loading />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ModalWithProvidedBody>
          ))
        }
      </UrqlLoadingIndicator>
    </>
  )
}

const currentLessonResourcesQuery = gql`
  query ($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      currentLessonResources {
        id
        name
      }
    }
  }
`

const completeLessonResourceMutation = gql`
  mutation ($airtutorsSessionId: ID!, $lessonResourceId: ID!) {
    completeLessonResource(
      airtutorsSessionId: $airtutorsSessionId
      lessonResourceId: $lessonResourceId
    ) {
      errorMessages
      airtutorsSession {
        currentLessonResources {
          id
          name
        }
      }
      nextResource {
        id
        name
        file {
          downloadUrl
          fileName
        }
      }
    }
  }
`

const downloadLessonResourceMutation = gql`
  mutation ($airtutorsSessionId: ID!, $lessonResourceId: ID!) {
    downloadLessonResource(
      airtutorsSessionId: $airtutorsSessionId
      lessonResourceId: $lessonResourceId
    ) {
      lessonResource {
        id
        file {
          downloadUrl
        }
      }
    }
  }
`

export default CompleteWorksheetButtons
