import React from "react"

import Form from "./Form"

const NewStudentForm = props => {
  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    phoneNumber: "",
    grade: null,
    school: null,
    emailTimeZone: "Pacific",
    referralSource: null,
    password: "",
    passwordConfirmation: "",
    parent: null,
    parentName: "",
    parentEmail: "",
    parentPhoneNumber: "",
    partner: null,
    noShowCount: 0,
    organization: null,
    couponCode: "",
    skipEmailConfirmation: false,
    enableSessionEmails: true,
    automatedTwilioSessionsEnabled: true,
    smsNotificationsEnabled: true,
    hasActAccess: false,
    hasSatAccess: false,
    hasIseeAccess: false,
    notes: "",
    prepaidHours: 0,
    subjects: [],
    tags: [],
  }

  return (
    <>
      <Form formState={formState} newStudent={true} />
    </>
  )
}

export default NewStudentForm
