import React from "react"

import ordered from "src/ordered"

const StudentSelection = ({ students, setStudent, newStudentPath }) => {
  return (
    <>
      <h1>Student Selection</h1>
      <h3>Who would you like to register for tutoring?</h3>
      <div>
        {ordered(students, "firstName").map(student => (
          <div key={student.id} className="mb-5 flex w-full justify-center">
            <button
              onClick={() => setStudent(student)}
              className="btn blue px-10 outline"
            >
              {student.firstName} {student.lastName}
              <br />
              {student.grade.name}
            </button>
          </div>
        ))}
        <div className="mb-5 flex w-full justify-center">
          <a href={newStudentPath} className="btn green outline">
            Add Student
          </a>
        </div>
      </div>
    </>
  )
}

export default StudentSelection
