import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { formatDate } from "src/date-helpers"

import { useMutation } from "hooks/urql"

import {
  CheckboxField,
  DateRangeField,
  Field,
  FileField,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  dateRange: Yup.array()
    .min(2, "Date range must have a start and end date")
    .max(2, "Date range must have a start and end date"),
  name: Yup.string().required("Name is required"),
  originalAmount: Yup.number().required(
    "Please specify the total budget amount"
  ),
  poAttachment: Yup.mixed().test("file", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const BudgetForm = ({ organizationId }) => {
  const formState = {
    dateRange: [],
    name: "",
    active: false,
    prepaid: false,
    poNumber: "",
    originalAmount: "",
    notes: "",
    poAttachment: {},
  }
  const [, createBudget] = useMutation(createMutation)

  const handleSubmit = (values, actions) => {
    const {
      poAttachment,
      dateRange: [startsOn, endsOn],
      ...variables
    } = values
    readFile(poAttachment)
      .then(file => {
        variables.poAttachment = file
        variables.startsOn = formatDate(startsOn.toDate())
        variables.endsOn = formatDate(endsOn.toDate())

        createBudget({ organizationId, input: variables })
          .then(
            result => {
              const { budget, failures } = result.data.createBudget
              if (failures) {
                handleFailure(actions, failures)
              } else {
                window.location = budget.showPath
              }
            },
            () => handleFailure(actions)
          )
          .catch(() => handleFailure(actions))
      })
      .catch(() => handleFailure(actions))
  }

  return (
    <>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <DateRangeField name="dateRange" label="Date Range" />
          <CheckboxField name="active" label="Active" />
          <Field name="name" label="Name" />
          <Field name="poNumber" label="PO Number" />
          <CheckboxField name="prepaid" label="Prepaid" />
          <Field
            name="originalAmount"
            label="Total Dollar Amount"
            type="number"
            step={0.01}
          />
          <TextAreaField name="notes" label="Notes" />
          <FileField name="poAttachment" label="PO Attachment" />

          <FormFailures />
          <SubmitButton />
        </Form>
      </Formik>
    </>
  )
}

const createMutation = gql`
  mutation ($organizationId: ID!, $input: BudgetInputObject!) {
    createBudget(organizationId: $organizationId, input: $input) {
      failures {
        message
      }
      budget {
        id
        showPath
      }
    }
  }
`

export default BudgetForm
