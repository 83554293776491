import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import axios from "src/axios"

import { ErrorMessage } from "components/Forms/Formik"

import QuestionGroupOptions from "./QuestionGroupOptions"
import QuestionOptions from "./QuestionOptions"

type TailoredSectionFormProps = {
  id: string
  formState: {
    questionIds: number[]
    questionGroupIds: number[]
  }
  maxQuestions: number
  maxQuestionGroups: number
  tags: string[]
  groupTags: string[]
}

const TailoredSectionForm: React.FC<TailoredSectionFormProps> = ({
  id,
  formState,
  maxQuestions,
  maxQuestionGroups,
  tags,
  groupTags,
}) => {
  const handleSubmit = (values, actions) => {
    axios
      .patch(`/api/admins/tailored_sections/${id}`, {
        tailored_section: {
          tailored_question_ids: values.questionIds,
          tailored_question_group_ids: values.questionGroupIds,
        },
      })
      .then(response => {
        const json = response.data
        if (json.success === true) {
          window.location = json.redirectTo
        } else {
          alert(
            `Unable to save assignment. Please ensure there are no validation errors and resubmit: ${json.errors.join(
              ", "
            )}`
          )
          actions.setSubmitting(false)
        }
      })
  }

  const sectionSchema = formikProps => {
    return Yup.object().shape({
      questionIds: Yup.array().max(
        maxQuestions,
        ({ max }) => `Please select at most ${max} question(s)`
      ),
      questionGroupIds: Yup.array().max(
        maxQuestionGroups,
        ({ max }) => `Please select at most ${max} question group(s)`
      ),
    })
  }

  return (
    <Formik
      initialValues={formState}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={sectionSchema}
    >
      {({ values, status, isSubmitting }) => (
        <Form>
          {maxQuestions && maxQuestions > 0 && (
            <>
              <h2>Possible Questions:</h2>
              <QuestionOptions values={values} tags={tags} />
              <div className="row">
                <ErrorMessage name="questionIds" />
              </div>
            </>
          )}

          {maxQuestionGroups && maxQuestionGroups > 0 && (
            <>
              <h2>Possible Question Groups:</h2>
              <QuestionGroupOptions values={values} tags={groupTags} />
              <div className="row">
                <ErrorMessage name="questionGroupIds" />
              </div>
            </>
          )}

          <div className="row">
            <div className="col-1">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-success solid green"
              >
                Save
              </button>
            </div>
            <div className="col-2 d-flex align-items-center">
              {status && status.msg && <div>{status.msg}</div>}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default TailoredSectionForm
