import { compress } from "micro-graphql-react"

export default compress`
  fragment CourseClusterFields on CourseCluster {
    id
    name
    formattedDescription
    courseType
    splitBill
    subject {
      id
      name
    }
    courses {
      id
      durationWeeks
      sessionsPerWeek
      courseSections: studentRegisterableCourseSections {
        id
        startsOn
        endsOn
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
          duration
        }
      }
    }
  }
`
