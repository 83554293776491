import React from "react"

import classNames from "src/classNames"

const Tabs = ({ tabs, selected, setSelected, inline = false }) => {
  return (
    <div className={classNames(inline ? "ml-4 inline-block" : "block", "mt-4")}>
      <nav className="flex space-x-4" aria-label="Tabs">
        {tabs.map(tab => (
          <button
            key={tab}
            onClick={() => setSelected(tab)}
            className={classNames(
              tab === selected
                ? "bg-indigo-100 text-indigo-700"
                : "text-gray-500 hover:text-gray-700",
              "cursor-pointer rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={tab === selected ? "page" : undefined}
          >
            {tab}
          </button>
        ))}
      </nav>
    </div>
  )
}

export default Tabs
