import React from "react"

import PropTypes from "prop-types"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RescheduleSessionButton = props => {
  const { path } = props
  if (!path) return ""

  return (
    <ModalWithProvidedBody
      buttonClassName={`btn solid ${
        props.size === "normal" ? "" : "small"
      } orange`}
      buttonText="Reschedule"
      modalClassName="bootstrap-modal"
      modalTitle="Reschedule Session"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <div className="modal-body">
            <p>First, reach out to student prior to rescheduling in the app</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn solid blue"
              onClick={() => {
                closeModal()
                window.location = path
              }}
            >
              Yes, I reached out
            </button>
            <button className="btn solid red" onClick={closeModal}>
              No, I will reach out
            </button>
          </div>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

RescheduleSessionButton.propTypes = {
  path: PropTypes.string,
}

export default RescheduleSessionButton
