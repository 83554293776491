import React from "react"

import { FieldArray, useField } from "formik"

import { foreignLanguageFluencyOptions } from "src/enums"

import { RadioField, SelectField } from "components/Forms/Formik/hookComponents"

const ForeignLanguageProficiencies = ({ foreignLanguageOptions }) => {
  const [foreignLanguagesField] = useField(
    "foreignLanguageProficienciesAttributes"
  )

  return (
    <div className="mb-4">
      <h3>Foreign Languages</h3>
      <p>
        Outside of English, do you have proficiency(ies) in any other languages?
      </p>

      <FieldArray name="foreignLanguageProficienciesAttributes">
        {arrayHelpers => (
          <>
            {foreignLanguagesField.value.map((foreignLanguage, index) =>
              foreignLanguage._destroy ? null : (
                <React.Fragment key={index}>
                  <SelectField
                    name={`foreignLanguageProficienciesAttributes[${index}].foreignLanguageId`}
                    label="Select language"
                    options={foreignLanguageOptions}
                    getOptionValue={l => l.id}
                    getOptionLabel={l => l.name}
                    valueAttribute="id"
                    defaultValue={foreignLanguageOptions.find(
                      l => l.id === foreignLanguage.foreignLanguageId
                    )}
                  />

                  <SelectField
                    name={`foreignLanguageProficienciesAttributes[${index}].fluency`}
                    label="How proficient are you in the selected language?"
                    options={foreignLanguageFluencyOptions}
                    defaultValue={foreignLanguageFluencyOptions.find(
                      l => l.value === foreignLanguage.fluency
                    )}
                    maxWidth={500}
                  />

                  <RadioField
                    name={`foreignLanguageProficienciesAttributes[${index}].academicAbility`}
                    label="What is your ability to provide academic instruction in this language?"
                    numeric
                    options={[
                      {
                        label: "1 (unable to teach in this language)",
                        value: 1,
                      },
                      { label: "2", value: 2 },
                      { label: "3", value: 3 },
                      { label: "4", value: 4 },
                      {
                        label: "5 (I'm confident in teaching in this language)",
                        value: 5,
                      },
                    ]}
                  />

                  <button
                    type="button"
                    className="btn solid red"
                    onClick={() =>
                      arrayHelpers.replace(index, {
                        ...foreignLanguage,
                        _destroy: true,
                      })
                    }
                  >
                    Remove Language
                  </button>
                  <hr />
                </React.Fragment>
              )
            )}

            <button
              type="button"
              className="btn solid blue"
              onClick={() =>
                arrayHelpers.push({
                  foreignLanguageId: "",
                  fluency: "",
                  academicAbility: 0,
                })
              }
            >
              Add Foreign Language
            </button>
          </>
        )}
      </FieldArray>
    </div>
  )
}

export default ForeignLanguageProficiencies
