import React from "react"

import dayjs from "dayjs"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required(
    "Please state your reason for missing class"
  ),
})

const CancelStudentSessionButton = ({
  studentSessionId,
  startsAt,
  canExcuseAbsences,
  size = "normal",
}) => {
  const client = useClient()
  const [, runMutation] = useMutation(cancelStudentSessionMutation, client)

  const inExcusedAbsenceWindow = () => {
    return canExcuseAbsences && dayjs(startsAt).diff(dayjs(), "hours") >= 24
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      studentSessionId,
      cancellationReason: values.cancellationReason,
    })
      .then(
        result => {
          const { failures } = result.data.cancelStudentSession
          if (failures) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName={`btn solid btn-danger red ${
        size === "normal" ? "" : "small"
      }`}
      buttonText="Cancel"
      modalClassName="bootstrap-modal"
      modalTitle="Cancel Session"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={{ cancellationReason: "" }}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <p>This will mark you as absent for this session.</p>
                <Field
                  name="cancellationReason"
                  label="What is your reason for missing class?"
                />
                {inExcusedAbsenceWindow() ? (
                  <p>
                    This will count as an excused absence. Your organization
                    and/or parent may be notified, but the session will not be
                    billed and you will not be counted as a no show. Continue?
                  </p>
                ) : (
                  <p>
                    <span className="bold">This will count as a no show.</span>{" "}
                    (Either there are less than 24 hours before the session, or
                    this session does not allow excused absences.) Your
                    organization and/or parent may be notified, and you will be
                    counted as a no show. Continue?
                  </p>
                )}
              </div>
              <div className="modal-footer" style={{ flexFlow: "column" }}>
                <FormFailures />
                <div className="w-100 d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn solid blue btn-info"
                    onClick={closeModal}
                  >
                    No, I Can Attend
                  </button>
                  <button
                    className="btn solid red btn-danger"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Yes, Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const cancelStudentSessionMutation = gql`
  mutation ($studentSessionId: ID!, $cancellationReason: String!) {
    cancelStudentSession(
      id: $studentSessionId
      cancellationReason: $cancellationReason
    ) {
      failures {
        message
      }
    }
  }
`
export default CancelStudentSessionButton
