import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import {
  FormFailures,
  RadioField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  interviewerId: Yup.string().required("Please select an interview option"),
})

const ScheduleInterviewForm = ({ interviewers, setInterviewer }) => {
  const formState = { interviewerId: "" }
  const handleSubmit = values => {
    const selectedInterviewer = interviewers.filter(
      i => i.id === values.interviewerId
    )[0]
    setInterviewer(selectedInterviewer)
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <RadioField
          name="interviewerId"
          label="Select a subject and grade level option for your teaching test"
          options={interviewers.map(i => ({
            label: i.name,
            value: i.id,
          }))}
        />
        <FormFailures />
        <SubmitButton text="Schedule Interview" />
      </Form>
    </Formik>
  )
}

export default ScheduleInterviewForm
