import React from "react"

import { Field, FieldArray } from "formik"

import { ErrorMessage, File, RadioGroup } from "components/Forms/Formik"

const TailoredAnswersFormGroup = props => {
  const { tailoredAnswers, setFieldValue } = props
  return (
    <FieldArray name="tailoredAnswers">
      {arrayHelpers => (
        <React.Fragment>
          <div className="form-group">
            <h5>Answers</h5>
            <div className="answers-list">
              {tailoredAnswers.map(
                (answer, index) =>
                  !answer._destroy && (
                    <div className="row" key={index}>
                      <div className="col-3">
                        <RadioGroup
                          type="checkbox"
                          name={`tailoredAnswers.${index}.correct`}
                          values={[{ value: true, label: "Correct" }]}
                          defaultValue={answer.correct}
                        />
                      </div>

                      <div className="col-3">
                        <RadioGroup
                          name={`tailoredAnswers.${index}.type`}
                          values={[
                            { value: "text", label: "Text" },
                            { value: "image", label: "Image" },
                          ]}
                          defaultValue={answer.type}
                        />
                      </div>

                      <div className="col-3">
                        {answer.type === "text" && (
                          <Field
                            type="text"
                            className="form-control"
                            name={`tailoredAnswers.${index}.text`}
                          />
                        )}
                        <ErrorMessage name={`tailoredAnswers.${index}.text`} />

                        {answer.type === "image" && (
                          <File
                            name={`tailoredAnswers.${index}.image`}
                            className="form-control"
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <ErrorMessage name={`tailoredAnswers.${index}.image`} />
                        {answer.type === "image" && answer.image.fileName && (
                          <React.Fragment>
                            <div>Current File:</div>
                            <a
                              href={answer.image.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={answer.image.thumbUrl}
                                alt="thumbnail"
                              />
                              <div>{answer.image.fileName}</div>
                            </a>
                          </React.Fragment>
                        )}
                      </div>

                      <div className="col-3">
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              `tailoredAnswers.${index}._destroy`,
                              true
                            )
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )
              )}
            </div>
            <button
              className="btn"
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  correct: false,
                  text: "",
                  type: "text",
                  image: {},
                })
              }
            >
              Add Answer
            </button>
          </div>
          <ErrorMessage name="tailoredAnswers" />
        </React.Fragment>
      )}
    </FieldArray>
  )
}

export default TailoredAnswersFormGroup
