import React from "react"

import BilledSessions from "./BilledSessions"
import RequestedSessions from "./RequestedSessions"
import ScheduledSessions from "./ScheduledSessions"
import UnbilledSessions from "./UnbilledSessions"

const AirtutorsSessions = props => {
  return (
    <React.Fragment>
      <RequestedSessions />
      <ScheduledSessions />
      <UnbilledSessions />
      <BilledSessions />
    </React.Fragment>
  )
}

export default AirtutorsSessions
