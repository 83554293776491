import React, { useState } from "react"

import { gql } from "urql"

import { useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const PoAttachment = ({ budget, poAttachment }) => {
  const [errors, setErrors] = useState([])
  const [, runMutation] = useMutation(deletePoAttachmentMutation)
  const removePoAttachment = () => {
    runMutation({ budgetId: budget.id, poAttachmentId: poAttachment.id }).then(
      result => {
        const { failures } = result.data.budgetDeletePoAttachment
        if (failures) {
          setErrors(failures)
        }
      }
    )
  }
  return (
    <li key={poAttachment.id} className="mb-3">
      {budget.statementCount === 0 && (
        <ModalWithProvidedBody
          buttonClassName="btn-link btn btn-sm btn-outline-danger red rounded-circle p-1 text-danger nc-icon nc-simple-remove m-0 mr-3"
          modalTitle="Remove PO Attachment"
        >
          {({ closeModal }) => (
            <>
              <div className="modal-body">
                <p>Are you sure?</p>
              </div>
              <div className="modal-footer flex-col">
                {errors.map(error => (
                  <div key={error.message} className="alert alert-danger mb-3">
                    {error.message}
                  </div>
                ))}
                <div className="w-100 flex justify-between">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={removePoAttachment}
                  >
                    Remove
                  </button>
                  <button type="button" className="btn" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </ModalWithProvidedBody>
      )}
      <a
        className="btn-link text-info pl-0"
        href={poAttachment.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {poAttachment.filename}
      </a>
    </li>
  )
}

const deletePoAttachmentMutation = gql`
  mutation ($budgetId: ID!, $poAttachmentId: ID!) {
    budgetDeletePoAttachment(
      budgetId: $budgetId
      poAttachmentId: $poAttachmentId
    ) {
      budget {
        id
      }
      failures {
        message
      }
    }
  }
`

export default PoAttachment
