import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as colors from "styles/colors"

import { css } from "@emotion/core"
import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { tutorClient } from "src/graphql-config"

import { Loading } from "components/Icons"
import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const LessonResourceCompletion = ({
  airtutorsSessionId,
  lessonResourceCompletion,
}) => {
  const [status, setStatus] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(removeCompletionMutation, { client: tutorClient })
  )

  const studentMasteryProps = {
    weak: { icon: faFrown, color: colors.red },
    moderate: { icon: faMeh, color: colors.orange },
    strong: { icon: faSmile, color: colors.green },
  }

  return (
    <div
      css={css`
        margin-bottom: 15px;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          flex-grow: 1;
          display: flex;
          align-items: center;
        `}
      >
        <span>
          {lessonResourceCompletion.student.fullName} completed on{" "}
          <LocalTime timestamp={lessonResourceCompletion.createdAt} />
        </span>
        {lessonResourceCompletion.studentMastery && (
          <span
            css={css`
              font-size: 32px;
              margin-left: 15px;
              color: ${studentMasteryProps[
                lessonResourceCompletion.studentMastery
              ].color};
            `}
          >
            <FontAwesomeIcon
              icon={
                studentMasteryProps[lessonResourceCompletion.studentMastery]
                  .icon
              }
            />
          </span>
        )}
      </div>
      <ModalWithProvidedBody
        buttonClassName="btn small solid red ml-3"
        buttonText="Redo"
        modalClassName="bootstrap-modal"
        modalTitle="Remove Worksheet Completion"
        afterClose={() => setStatus()}
      >
        {({ closeModal }) => (
          <>
            <div className="modal-body">
              <p>
                Are you sure? This will remove the selected completion and
                adjust the current worksheet for this student.
              </p>
            </div>
            <div
              className="modal-footer"
              css={css`
                display: block !important;
              `}
            >
              {status && (
                <div
                  className="alert alert-danger"
                  css={css`
                    padding: 9px;
                    margin-bottom: 15px;
                  `}
                >
                  {status}
                </div>
              )}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <button className="btn solid red" onClick={closeModal}>
                  Nevermind
                </button>
                <div>
                  <button
                    type="submit"
                    className="btn btn-info solid blue"
                    disabled={running}
                    onClick={() => {
                      runMutation({
                        airtutorsSessionId,
                        id: lessonResourceCompletion.id,
                      })
                        .then(
                          r => {
                            if (r.removeLessonResourceCompletion.errorMessage) {
                              setStatus(
                                r.removeLessonResourceCompletion.errorMessage
                              )
                            }
                          },
                          () =>
                            setStatus(
                              "An error occurred. Please refresh and try again."
                            )
                        )
                        .catch(() =>
                          setStatus(
                            "An error occurred. Please refresh and try again."
                          )
                        )
                    }}
                  >
                    <span>Remove It</span>
                    {running && (
                      <div
                        css={css`
                          display: inline-block;
                          margin-left: 15px;
                        `}
                      >
                        <Loading />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </ModalWithProvidedBody>
    </div>
  )
}

const removeCompletionMutation = compress`
  mutation($id: ID!, $airtutorsSessionId: ID!) {
    removeLessonResourceCompletion(id: $id, airtutorsSessionId: $airtutorsSessionId) {
      errorMessage
    }
  }
`

export default LessonResourceCompletion
