import { useEffect, useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

const useAssessmentSectionOptions = () => {
  const loadingState = useQuery(buildQuery(assessmentsQuery))
  const [options, setOptions] = useState([])
  useEffect(() => {
    if (!loadingState.data) return
    setOptions(
      ordered(loadingState.data.assessments, "name").map(assessment => ({
        label: assessment.name,
        options: ordered(assessment.assessmentSections, "name"),
      }))
    )
  }, [loadingState.data])
  return options
}

const assessmentsQuery = compress`
  query {
    assessments {
      id
      name
      assessmentSections {
        id
        name
      }
    }
  }
`

export default useAssessmentSectionOptions
