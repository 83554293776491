import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $file: String!,
    $filename: String!,
  ) {
    createTutorBulkUpload(
      file: $file
      filename: $filename
    ) {
      success
      redirectTo
      errorMessage
      errors {
        field
        messages
      }
    }
  }
`
