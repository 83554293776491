import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $studentId: ID!,
    $parentId: ID!,
  ) {
    linkStudent(
      studentId: $studentId,
      parentId: $parentId,
    ) {
      student {
        id
        fullName
        email
        editPath
        showPath
        subjects {
          id
          name
        }
      }
    }
  }
`
