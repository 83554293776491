import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import { NotificationContainer } from "react-notifications"

import StudentInfoModal, {
  ModalContext,
} from "components/Admin/Dashboard/UncontactedStudents/StudentInfoModal"
import studentFields from "components/Admin/Dashboard/UncontactedStudents/studentFields"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import "react-notifications/lib/notifications.css"

const ContactStudent = ({ studentId }) => {
  const loadingState = useQuery(buildQuery(studentQuery, { studentId }))
  const [student, setStudent] = useState()

  return (
    <ModalContext.Provider value={[student, setStudent]}>
      <WithLoadingIndicator loadingState={loadingState}>
        {({ data }) => (
          <>
            <NotificationContainer />
            <StudentInfoModal />
            <button
              onClick={() => setStudent(data.student)}
              className="btn btn-info"
            >
              Contact
            </button>
          </>
        )}
      </WithLoadingIndicator>
    </ModalContext.Provider>
  )
}

const studentQuery = compress`
  ${studentFields}
  query($studentId: ID!) {
    student(id: $studentId) {
      ...StudentFields
    }
  }
`

export default ContactStudent
