import React from "react"

import { Field, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import Select, { components as selectComponents } from "react-select"
import * as Yup from "yup"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SingleValue = props => (
  <selectComponents.SingleValue {...props}>
    {props.data.shortLabel}
  </selectComponents.SingleValue>
)

const ValidationSchema = Yup.object().shape({
  prepaidPackageId: Yup.string().required("Please select a package"),
})

const AddAmpPackage = ({ userId }) => {
  const { runMutation } = useMutation(buildMutation(purchaseAmpPackageMutation))
  const loadingState = useQuery(
    buildQuery(
      ampPackagesQuery,
      { userId },
      {
        onMutation: [
          {
            when: "purchaseAmpPackage",
            run: ({ softReset, currentResults }, response) => {
              const payload = response.purchaseAmpPackage
              if (payload.viewer) {
                currentResults.user.prepaidHours = payload.viewer.prepaidHours
                currentResults.invoice = payload.invoice
                softReset(currentResults)
              }
            },
          },
        ],
      }
    )
  )

  const formState = {
    prepaidPackageId: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ userId, ...values })
      .then(
        response => {
          const payload = response.purchaseAmpPackage
          actions.setSubmitting(false)

          if (payload.viewer) {
            closeModal()
            window.location.reload()
          } else {
            actions.setStatus(
              <div>
                Unable to add package
                <ul>
                  {payload.errorMessages.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            )
          }
        },
        rejected => {
          actions.setSubmitting(false)
          actions.setStatus("An error occurred while processing your request")
        }
      )
      .catch(err => {
        actions.setSubmitting(false)
        actions.setStatus("An error occurred while processing your request")
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <ModalWithProvidedBody
            buttonText="Add AMP Package"
            buttonClassName="btn btn-success"
            modalTitle="Add AMP Package"
          >
            {({ closeModal }) => (
              <Formik
                initialValues={formState}
                onSubmit={(values, actions) =>
                  handleSubmit(values, actions, closeModal)
                }
                validationSchema={ValidationSchema}
              >
                {({ status, isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="modal-body">
                      <label className="w-100">
                        Select Package
                        <Field name="prepaidPackageId" type="text">
                          {({ field: { name } }) => (
                            <Select
                              menuPortalTarget={document.body}
                              options={ordered(
                                data.user.prepaidPackages,
                                "hours"
                              ).map(prepaidPackage => ({
                                value: prepaidPackage.id,
                                shortLabel: `${prepaidPackage.name}: ${prepaidPackage.formattedPrice}`,
                                label: (
                                  <div>
                                    {prepaidPackage.name}:{" "}
                                    {prepaidPackage.formattedPrice}
                                    <br />
                                    {prepaidPackage.hours} hours
                                    <br />
                                    Additional Hours:{" "}
                                    {prepaidPackage.formattedHourlyRate} / hour
                                  </div>
                                ),
                              }))}
                              components={{ SingleValue }}
                              onChange={selectedOption =>
                                setFieldValue(
                                  "prepaidPackageId",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              placeholder="Select Package"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="prepaidPackageId" />
                      </label>
                    </div>
                    <div
                      className="modal-footer"
                      css={css`
                        flex-flow: column;
                      `}
                    >
                      {status && (
                        <div
                          className="alert alert-danger"
                          css={css`
                            justify-content: center !important;
                          `}
                        >
                          {status}
                        </div>
                      )}
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        text="Purchase Package"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </ModalWithProvidedBody>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const purchaseAmpPackageMutation = compress`
  mutation($userId: ID!, $prepaidPackageId: ID!) {
    purchaseAmpPackage(userId: $userId, prepaidPackageId: $prepaidPackageId) {
      viewer {
        prepaidHours
      }
      invoice {
        hours
      }
      errorMessages
    }
  }
`

const ampPackagesQuery = compress`
  query($userId: ID!) {
    user(id: $userId) {
      prepaidHours
      prepaidPackages(amp: true) {
        id
        name
        hours
        formattedPrice
        formattedHourlyRate
      }
    }
  }
`

export default AddAmpPackage
