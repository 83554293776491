const ordered = (list, ...fields) => {
  if (!list) return []

  return [...list].sort((a, b) => {
    let inputA = a
    let inputB = b
    fields.forEach(field => {
      inputA = inputA ? inputA[field] : null
      inputB = inputB ? inputB[field] : null
    })

    if (inputB === undefined || inputB === null) return -1

    if (typeof inputA === "string") {
      inputA = inputA.toLowerCase()
      inputB = inputB.toLowerCase()
    }

    if (inputA > inputB) return 1
    if (inputA < inputB) return -1
    return 0
  })
}

export default ordered
