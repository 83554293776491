import React from "react"

import { FieldArray, Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import { RadioGroup } from "components/Forms/Formik"
import {
  CheckboxField,
  Field,
  FormFailures,
} from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

export type CompleteSessionModalProps = {
  id: string
  endsAt: string
  closeModal: () => void
  channel: any
}

const ValidationSchema = Yup.object().shape({
  earlyAcknowledgement: Yup.boolean().when("isEarly", {
    is: true,
    then: Yup.boolean().oneOf([true], "Acknowledgement required"),
  }),
  endedEarlyReason: Yup.string().when("isEarly", {
    is: true,
    then: Yup.string().required(
      "Please describe why the session was ended early."
    ),
  }),
})

const CompleteSessionModal: React.FC<CompleteSessionModalProps> = ({
  id,
  endsAt,
  closeModal,
  channel,
}) => {
  const formState = {
    completedLessonResourceIds: [],
    isEarly: new Date().getTime() < new Date(endsAt).getTime() - 1000 * 60,
    earlyAcknowledgement: false,
    endedEarlyReason: "",
  }

  const client = useClient()

  const [result] = useQuery({
    query: lessonResourcesQuery,
    variables: { id },
    client,
  })

  const [_, runMutation]: any[] = useMutation(completeSessionMutation, client)

  const handleSubmit = (values, actions) => {
    runMutation({ id, ...values })
      .then(
        response => {
          const { failures } = response.data.completeAirtutorsSession
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            closeModal()
            channel.perform("end_session")
          }
        },
        err => actions.setStatus("An error occurred")
      )
      .catch(err => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, status, values }) => (
            <Form>
              <div className="modal-body">
                <p>
                  Are you sure? This will send all participants to the
                  post-session form.
                </p>
                {values.isEarly && (
                  <CheckboxField
                    name="earlyAcknowledgement"
                    label="I acknowledge that I am ending this session early."
                  />
                )}
                {values.earlyAcknowledgement && (
                  <Field name="endedEarlyReason" label="Please describe why:" />
                )}
                {data.airtutorsSession.currentLessonResources.length > 0 && (
                  <div className="form-group">
                    <h3 className="mb-0 font-medium">Worksheets</h3>
                    <small className="form-text">Mark if completed</small>
                    <FieldArray name="completedLessonResourceIds">
                      {arrayHelpers =>
                        data.airtutorsSession.currentLessonResources.map(
                          resource => (
                            <RadioGroup
                              key={resource.id}
                              type="checkbox"
                              name="completedLessonResourceIds"
                              values={[
                                {
                                  value: resource.id,
                                  label: (
                                    <>
                                      <div>
                                        {resource.lessonTopic.name}:{" "}
                                        {resource.name}
                                      </div>
                                      {resource.isLast && (
                                        <div className="text-danger">
                                          This is the final worksheet for this
                                          chapter
                                        </div>
                                      )}
                                    </>
                                  ),
                                },
                              ]}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(resource.id)
                                } else {
                                  arrayHelpers.remove(
                                    values.completedLessonResourceIds.findIndex(
                                      r => r === resource.id
                                    )
                                  )
                                }
                              }}
                            />
                          )
                        )
                      }
                    </FieldArray>
                  </div>
                )}
              </div>

              <div className="modal-footer block">
                <div className="flex flex-col">
                  {status && (
                    <div className="alert alert-danger mb-4 p-2">{status}</div>
                  )}
                  <div className="flex justify-between">
                    <FormFailures />
                    <button
                      className="btn solid red"
                      type="button"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn solid blue"
                      disabled={isSubmitting}
                    >
                      End the session
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </UrqlLoadingIndicator>
  )
}

const lessonResourcesQuery = gql`
  query ($id: ID!) {
    airtutorsSession(id: $id) {
      currentLessonResources {
        id
        name
        isLast
        lessonTopic {
          name
        }
      }
    }
  }
`

const completeSessionMutation = gql`
  mutation (
    $id: ID!
    $completedLessonResourceIds: [ID!]!
    $endedEarlyReason: String
    $earlyAcknowledgement: Boolean
  ) {
    completeAirtutorsSession(
      id: $id
      completedLessonResourceIds: $completedLessonResourceIds
      endedEarlyReason: $endedEarlyReason
      earlyAcknowledgement: $earlyAcknowledgement
    ) {
      airtutorsSession {
        id
      }
      failures {
        message
      }
    }
  }
`

export default CompleteSessionModal
