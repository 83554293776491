import React, { memo, useEffect, useState } from "react"

import axios from "src/axios"

function MatchingQuestionsCount({ grouped, tags }) {
  const [count, setCount] = useState("")

  useEffect(() => {
    const api = grouped
      ? "/api/admins/tailored_question_groups"
      : "/api/admins/tailored_questions"

    if (tags.length === 0) {
      setCount("")
    } else {
      axios
        .get(api, {
          params: { tags },
        })
        .then(response => {
          setCount(response.data.total)
        })
    }
  }, [grouped, tags])

  const label = grouped ? "Matching Question Groups" : "Matching Questions"
  return (
    <span className="text-lg font-bold">
      {label}: {count}
    </span>
  )
}

export default memo(MatchingQuestionsCount, (prev, next) => {
  return prev.tags.sort().join() === next.tags.sort().join()
})
