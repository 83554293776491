import React, { ReactNode } from "react"

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid"

import Form from "~tailwindui/Form"

import classNames from "src/classNames"
import ordered from "src/ordered"

export type ValueDisplayProps = {
  label: string
  value: string | boolean | JSX.Element
  description?: string | ReactNode
  subfield?: boolean
}

const ValueDisplay: React.FC<ValueDisplayProps> = ({
  label,
  value,
  description = "",
  subfield = false,
}) => {
  const formatValue = value => {
    if (value === true)
      return (
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      )
    if (value === false)
      return <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
    if (Array.isArray(value)) {
      return ordered(value.map(val => (val?.name ? val.name : val))).join(", ")
    } else return value
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-1 sm:py-6">
      <label
        className={classNames(
          subfield && "ml-8",
          "block text-sm font-medium leading-6 text-gray-900"
        )}
      >
        {label}
      </label>

      <div
        className={classNames(
          subfield && "ml-8",
          "text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
        )}
      >
        {formatValue(value)}
      </div>
      <Form.FieldDescription description={description} />
    </div>
  )
}

export default ValueDisplay
