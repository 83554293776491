import React, { useRef } from "react"

import { motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const Subjects = ({ subjects, studyGroupId }) => {
  const selectRef = useRef(null)
  const { runMutation } = useMutation(buildMutation(addSubjectMutation))

  return (
    <React.Fragment>
      <h4 className="mt-0">Subjects</h4>
      <EditableList>
        {ordered(subjects, "name").map(subject => (
          <motion.li key={subject.id} positionTransition>
            <Item
              mutation={removeSubjectMutation}
              mutationProps={{
                studyGroupId,
                subjectId: subject.id,
              }}
            >
              {subject.name}
            </Item>
          </motion.li>
        ))}
      </EditableList>
      <div className="mb-3">
        <AutocompleteSelect
          ref={selectRef}
          onChange={option => {
            if (!option?.value) return
            runMutation({ studyGroupId, subjectId: option.value }).then(() => {
              selectRef.current.clearValue()
            })
          }}
          withLoadingIndicator
          api="/api/admins/subjects/autocomplete_name"
          placeholder="Add Subject"
        />
      </div>
    </React.Fragment>
  )
}

const addSubjectMutation = compress`
  mutation($studyGroupId: ID!, $subjectId: ID!) {
    studyGroupAddSubject(studyGroupId: $studyGroupId, subjectId: $subjectId) {
      studyGroup {
        subjects {
          id
          name
        }
      }
    }
  }
`

const removeSubjectMutation = compress`
  mutation($studyGroupId: ID!, $subjectId: ID!) {
    studyGroupRemoveSubject(studyGroupId: $studyGroupId, subjectId: $subjectId) {
      studyGroup {
        subjects {
          id
          name
        }
      }
    }
  }
`
export default Subjects
