import React from "react"

import { gql, useQuery } from "urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import NoShowNotificationList from "./NoShowNotificationList"

const Container = () => {
  const [result] = useQuery({ query: noShowNotificationsQuery })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <NoShowNotificationList
          noShowNotifications={data.noShowNotifications.data}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const noShowNotificationsQuery = gql`
  query NoShowNotificationsQuery {
    noShowNotifications(pageSize: 100) {
      data {
        id
        student {
          fullName
          noShowCount
        }
      }
    }
  }
`

export default Container
