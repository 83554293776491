import React from "react"

import { Box } from "@chakra-ui/core"

import ordered from "src/ordered"

import StudyGroupRow from "./StudyGroupRow"

const StudyGroupsList = ({ studyGroups }) => {
  return (
    <Box as="table" minW="containers.md" w="full">
      <thead>
        <tr>
          <Box as="th" textAlign="left">
            Class
          </Box>
          <Box as="th" textAlign="left">
            Students
          </Box>
        </tr>
      </thead>
      <tbody>
        {ordered(studyGroups, "name").map(studyGroup => (
          <StudyGroupRow key={studyGroup.id} studyGroup={studyGroup} />
        ))}
      </tbody>
    </Box>
  )
}

export default StudyGroupsList
