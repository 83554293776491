import React, { useContext } from "react"

import Spinner from "~tailwindui/shared/Spinner"

import classNames from "src/classNames"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"

export type ScreenShareButtonProps = Record<never, any>

const ScreenShareButton: React.FC<ScreenShareButtonProps> = () => {
  const {
    channel,
    roster,
    localUser,
    session,
    localScreenShareDisabled,
    startLocalScreenShare,
    stopLocalScreenShare,
    screenShareRequestPending,
    setScreenShareRequestPending,
  } = useContext(CloudroomContext)

  if (localScreenShareDisabled) return null

  const localAttendee = roster[session.chimeJoinInfo.attendee.attendeeId]
  const screenSharing = localAttendee?.sharingContent

  const requestScreenShare = () => {
    if (["Tutor", "Teacher"].includes(localUser.type)) {
      startLocalScreenShare()
    } else {
      setScreenShareRequestPending(true)
      channel.perform("screen_share_request", { userId: localUser.id })
    }
  }

  const buttonClassNames = classNames(
    "btn solid blue mb-4 text-white",
    localUser.type === "Admin" && "btn-info",
    localUser.type === "Teacher" &&
      "bg-info-700 px-3 py-1 rounded font-semibold"
  )

  return screenSharing ? (
    <button
      className={classNames(
        "btn solid red",
        localUser.type === "Admin" && "btn-danger",
        localUser.type === "Teacher" &&
          "rounded bg-brand-700 p-1 px-3 font-semibold"
      )}
      onClick={stopLocalScreenShare}
    >
      Stop Screen Share
    </button>
  ) : (
    <button
      className={classNames(
        buttonClassNames,
        "flex items-center disabled:opacity-75 disabled:hover:bg-info-500 disabled:hover:text-white disabled:hover:opacity-75"
      )}
      onClick={requestScreenShare}
      disabled={screenShareRequestPending}
    >
      Share Your Screen
      {screenShareRequestPending && (
        <Spinner size="medium" className="ml-3 mr-0 fill-white" />
      )}
    </button>
  )
}

export default ScreenShareButton
