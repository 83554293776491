import React from "react"

import { NotificationContainer } from "react-notifications"

import ordered from "src/ordered"
import { tutorClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import StudyGroup from "./StudyGroup"

import "react-notifications/lib/notifications.css"

const StudyGroupTutorInvitation = ({ id }) => {
  const [result] = useQuery({
    query: invitationQuery,
    variables: { id },
    client,
  })

  return (
    <>
      <NotificationContainer />
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <h2>
              Study Group Registration for{" "}
              {data.studyGroupTutorInvitation.organization.name}
            </h2>

            <p className="italic">
              If no groups are displayed below, all available groups have been
              registered for at this time.
            </p>

            {ordered(
              data.studyGroupTutorInvitation.availableStudyGroups,
              "availabilities",
              "0",
              "startsAt"
            ).map(studyGroup => (
              <StudyGroup
                key={studyGroup.id}
                studyGroup={studyGroup}
                invitationId={id}
                organization={data.studyGroupTutorInvitation.organization}
              />
            ))}
          </>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const invitationQuery = gql`
  query ($id: ID!) {
    studyGroupTutorInvitation(id: $id) {
      organization {
        id
        name
      }
      availableStudyGroups {
        id
        name
        formattedDetails
        minTutorConsistencyRate
        maxSubsEstimate
        foreignLanguage {
          id
          name
        }
        subjects {
          id
          name
        }
        studyGroupGeneration {
          id
          startsOn
          endsOn
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
      }
    }
  }
`

export default StudyGroupTutorInvitation
