import React from "react"

import { gql, useQuery } from "urql"

import { Box, Flex, Heading } from "@chakra-ui/core"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import SemestersSelect from "./SemestersSelect"

const SemesterFilter = ({ semesterId = null }) => {
  const [result] = useQuery({
    query: semestersQuery,
    variables: { selectedSemesterId: semesterId },
  })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Flex align="center">
          <SemestersSelect semesters={data.semesters} />
          {data.semester && (
            <Box ml="4">
              <Heading textAlign="center" fontSize="xl">
                Viewing: {data.semester.name}
              </Heading>
            </Box>
          )}
        </Flex>
      )}
    </UrqlLoadingIndicator>
  )
}

const semestersQuery = gql`
  query SemestersQuery($selectedSemesterId: ID) {
    semesters {
      id
      name
      startsOn
      endsOn
    }
    semester(id: $selectedSemesterId) {
      name
    }
  }
`

export default SemesterFilter
