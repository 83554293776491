import React from "react"

import { red } from "styles/colors"

import { CardElement } from "@stripe/react-stripe-js"

import { ErrorMessage } from "components/Forms/Formik"

const AddPaymentMethod = () => {
  return (
    <div className="form-group">
      <label className="form-label w-full md:w-1/2">
        <span>Credit Card</span>
        <CardElement
          onChange={event => null}
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: red,
              },
            },
          }}
        />
        <div className="form-error">
          <ErrorMessage name="stripeToken" />
        </div>
      </label>
    </div>
  )
}

export default AddPaymentMethod
