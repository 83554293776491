import React from "react"

import { NotificationContainer, NotificationManager } from "react-notifications"

import "react-notifications/lib/notifications.css"

class AdminNotification extends React.Component {
  componentDidMount = () => {
    this.createNotification()
  }

  createNotification = () => {
    switch (this.props.type) {
      case "info":
        NotificationManager.info(this.props.content)
        break
      case "success":
        NotificationManager.success(this.props.content)
        break
      case "warning":
        NotificationManager.warning(this.props.content)
        break
      case "error":
        NotificationManager.error(this.props.content)
        break
      default:
        break
    }
  }

  render() {
    return <NotificationContainer />
  }
}

export default AdminNotification
