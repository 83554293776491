import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import LocalTime from "components/LocalTime"

const Availability = ({ availability, courseSectionId }) => {
  const [errors, setErrors] = useState()
  const { runMutation } = useMutation(buildMutation(removeAvailabilityMutation))

  const remove = () => {
    runMutation({ courseSectionId, availabilityId: availability.id })
      .then(
        response => {
          const { errorMessages } = response.courseSectionRemoveAvailability
          if (errorMessages) {
            setErrors(errorMessages)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          {availability.weekday}:&nbsp;
          <LocalTime timestamp={availability.startsAt} omitDate />
          &nbsp;&mdash;&nbsp;
          <LocalTime timestamp={availability.endsAt} omitDate />
        </div>
        <div>
          <button className="btn btn-sm btn-danger" onClick={remove}>
            Remove
          </button>
        </div>
      </div>
      {errors && <div className="alert alert-danger">{errors}</div>}
    </>
  )
}

const removeAvailabilityMutation = compress`
  mutation($courseSectionId: ID!, $availabilityId: ID!) {
    courseSectionRemoveAvailability(courseSectionId: $courseSectionId, availabilityId: $availabilityId) {
      errorMessages
      courseSection {
        id
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

export default Availability
