import React, { useEffect, useRef } from "react"

import { publicClient as client } from "src/urql-client"

import { gql, useMutation } from "hooks/urql"

const isEventScheduled = e =>
  e.origin === "https://calendly.com" &&
  e.data?.event === "calendly.event_scheduled"

const ScheduleInterview = ({ interviewer, token }) => {
  const listener = useRef()
  const [, runMutation] = useMutation(saveInterviewMutation, client)

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  })

  useEffect(() => {
    listener.current = window.addEventListener("message", function (e) {
      if (isEventScheduled(e)) {
        runMutation({
          token,
          interviewerId: interviewer.id,
          eventUri: e.data.payload.event.uri,
        })
      }
    })

    return () => {
      window.removeEventListener(listener.current)
    }
  }, [interviewer.id, runMutation, token])

  return (
    <>
      <p className="mb-0 text-center italic">
        No times available? Refresh the page to select a different option
      </p>
      <div
        className="calendly-inline-widget h-[630px] min-w-[320px]"
        data-url={interviewer.calendlyUrl}
      />
    </>
  )
}

const saveInterviewMutation = gql`
  mutation ($token: String!, $interviewerId: ID!, $eventUri: String!) {
    tutorApplicationInterviewScheduled(
      token: $token
      tutorApplicationInterviewerId: $interviewerId
      eventUri: $eventUri
    ) {
      failures {
        message
      }
    }
  }
`

export default ScheduleInterview
