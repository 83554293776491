import React from "react"

import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import { ValueFormat } from "~tailwindui/types/enums"

import Chip from "~tailwindui/ChipSimple"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

type BilledSessionsChipProps = {
  label: string
  startDate: Dayjs
  endDate: Dayjs
}

const BilledSessionsChip: React.FC<BilledSessionsChipProps> = ({
  label,
  startDate,
  endDate,
}) => {
  const today = dayjs().format()
  const [result] = useQuery({
    query,
    variables: {
      startingAt: startDate,
      endingAt: endDate,
      lastThirtyStart: dayjs(today).subtract(30, "days").startOf("day"),
      lastThirtyEnd: dayjs(today).endOf("day"),
    },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({
        data: {
          viewer: {
            organization: { customRange, lastThirty },
          },
        },
      }) => (
        <Chip.Item
          label={label}
          value={customRange}
          valueFormat={ValueFormat.Number}
          subtitle="Last 30 Days:"
          subtitleValue={lastThirty}
          subtitleValueFormat={ValueFormat.Number}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query BilledSessionsChipQuery(
    $startingAt: DateTime
    $endingAt: DateTime
    $lastThirtyStart: DateTime
    $lastThirtyEnd: DateTime
  ) {
    viewer {
      organization {
        customRange: billedSessionsCount(
          startDate: $startingAt
          endDate: $endingAt
        )
        lastThirty: billedSessionsCount(
          startDate: $lastThirtyStart
          endDate: $lastThirtyEnd
        )
      }
    }
  }
`

export default BilledSessionsChip
