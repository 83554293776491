import React, { useRef } from "react"

import ReactTable from "react-table"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import "react-table/react-table.css"

const AdminParentsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/parents",
  })
  const parentNameSelect = useRef(null)
  const parentEmailSelect = useRef(null)
  const parentPhoneNumberSelect = useRef(null)
  const studentNameSelect = useRef(null)

  const clearableSelects = [
    parentNameSelect.current,
    parentEmailSelect.current,
    parentPhoneNumberSelect.current,
    studentNameSelect.current,
  ]

  const columns = [
    {
      id: "full_name",
      Header: "Name",
      accessor: "full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={parentNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/parents/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "email",
      Header: "Email",
      accessor: "email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={parentEmailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/parents/autocomplete_email"
          />
        )
      },
    },
    {
      id: "phone_number",
      Header: "Phone Number",
      accessor: "phone_number",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={parentPhoneNumberSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/parents/autocomplete_phone_number"
          />
        )
      },
    },
    {
      id: "students",
      Header: "Students",
      accessor: "students",
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {props.row.students.map(student => (
            <li key={student.id}>
              <a href={student.show_path}>{student.full_name}</a>
            </li>
          ))}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={studentNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/students/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <a
            href={props.value.edit_path}
            className="btn btn-sm btn-outline-dark fa fa-pencil"
          >
            <span className="hidden">Edit</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminParentsTable
