import React, { useEffect, useRef } from "react"

import ClipboardJS from "clipboard"

const ClipboardButton = ({ content, children, ...props }) => {
  const button = useRef()
  const clipboard = useRef()

  useEffect(() => {
    clipboard.current = new ClipboardJS(button.current, { text: () => content })
    return () => clipboard.current?.destroy()
  }, [content])

  return (
    <button type="button" ref={button} className="btn" {...props}>
      {children}
    </button>
  )
}

export default ClipboardButton
