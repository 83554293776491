import React from "react"

import { Field } from "formik"

import { ErrorMessage, File, RadioGroup } from "components/Forms/Formik"

import styles from "./question-styles.module.scss"

const TailoredQuestionFormGroup = props => {
  const { values, setFieldValue } = props
  return (
    <div className="form-group">
      <h5>Question Text</h5>
      <div className="row">
        <div className="col-4">
          <RadioGroup
            name="type"
            values={[
              { value: "text", label: "Text" },
              { value: "image", label: "Image" },
            ]}
            defaultValue={values.type}
          />
        </div>

        <div className="col-4">
          {values.type === "text" && (
            <Field
              component="textarea"
              name="text"
              className={`form-control ${styles.textarea}`}
              cols="45"
              rows="5"
            />
          )}
          <ErrorMessage name="text" />

          {values.type === "image" && (
            <File
              name="image"
              className="form-control"
              setFieldValue={setFieldValue}
            />
          )}
          <ErrorMessage name="image" />

          {values.type === "image" && values.image && values.image.fileName && (
            <React.Fragment>
              <div>Current File:</div>
              <a
                href={values.image.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={values.image.thumbUrl} alt={values.image.fileName} />
                <div>{values.image.fileName}</div>
              </a>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default TailoredQuestionFormGroup
