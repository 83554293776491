import React from "react"

import { motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EditableDisplay from "components/Forms/EditableDisplay"
import RemoveButton from "components/RemoveButton"

import lessonResourcesFragment from "./lessonResourcesFragment"

const LessonResource = ({ resource, first, last }) => {
  const { runMutation } = useMutation(
    buildMutation(updateLessonResourceMutation)
  )
  const { runMutation: incrementOrder, running: incrementOrderRunning } =
    useMutation(buildMutation(incrementOrderMutation))
  const { runMutation: decrementOrder, running: decrementOrderRunning } =
    useMutation(buildMutation(decrementOrderMutation))

  const ResourceName = ({ children }) => {
    const { runMutation } = useMutation(
      buildMutation(removeLessonResourceMutation)
    )

    return (
      <div>
        <RemoveButton onClick={() => runMutation({ id: resource.id })} />
        <strong>{children}</strong>
      </div>
    )
  }

  return (
    <motion.li positionTransition className="mb-3">
      <div className="row">
        <div className="col-6">
          <EditableDisplay
            displayValue={resource.name}
            save={({ value }) => runMutation({ id: resource.id, name: value })}
            tag={ResourceName}
          />
        </div>
        <div className="col-6">
          <button
            className="mx-2"
            onClick={() => incrementOrder({ id: resource.id })}
            disabled={last || incrementOrderRunning}
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
          <button
            className="mx-2"
            onClick={() => decrementOrder({ id: resource.id })}
            disabled={first || decrementOrderRunning}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
          <img src={resource.file.thumbUrl} alt={resource.file.fileName} />
        </div>
      </div>
    </motion.li>
  )
}

const updateLessonResourceMutation = compress`
  mutation($id: ID!, $name: String!) {
    updateLessonResource(id: $id, name: $name) {
      lessonResource {
        id
        name
      }
    }
  }
`

const decrementOrderMutation = compress`
  ${lessonResourcesFragment}
  mutation($id: ID!) {
    decrementLessonResourceOrder(id: $id) {
      lessonTopic {
        id
        lessonResources {
          ...LessonResourcesFields
        }
      }
    }
  }
`

const incrementOrderMutation = compress`
  ${lessonResourcesFragment}
  mutation($id: ID!) {
    incrementLessonResourceOrder(id: $id) {
      lessonTopic {
        id
        lessonResources {
          ...LessonResourcesFields
        }
      }
    }
  }
`

const removeLessonResourceMutation = compress`
  ${lessonResourcesFragment}
  mutation($id: ID!) {
    removeLessonResource(id: $id) {
      lessonTopic {
        id
        lessonResources {
          ...LessonResourcesFields
        }
      }
    }
  }
`

export default LessonResource
