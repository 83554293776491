import React, { useContext } from "react"

import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"

import classNames from "src/classNames"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import BaseButton from "components/ChimeCloudroom/Shared/BaseButton"

export type LocalTileProps = Record<never, any>

const LocalTile: React.FC<LocalTileProps> = () => {
  const {
    audioVideo,
    audioSetupInProgress,
    videoSetupInProgress,
    channel,
    muteLocalAudio,
    unmuteLocalAudio,
    muteLocalVideo,
    unmuteLocalVideo,
    localControlsLocked,
    localUser,
    roster,
    ghost,
    session,
  } = useContext(CloudroomContext)

  const localAttendee = roster[session.chimeJoinInfo.attendee.attendeeId]
  const audioOn = localAttendee && !localAttendee.muted
  const videoOn = localAttendee?.sharingVideo
  const screenSharing = localAttendee?.sharingContent

  const toggleLocalAudio = () => {
    if (audioVideo.realtimeIsLocalAudioMuted()) {
      unmuteLocalAudio()
      channel.perform("unmute_self", { type: "audio", userId: localUser.id })
    } else {
      muteLocalAudio()
      channel.perform("mute_self", { type: "audio", userId: localUser.id })
    }
  }

  const toggleLocalVideo = () => {
    if (audioVideo.hasStartedLocalVideoTile()) {
      muteLocalVideo()
      channel.perform("mute_self", { type: "video", userId: localUser.id })
    } else {
      unmuteLocalVideo()
      channel.perform("unmute_self", { type: "video", userId: localUser.id })
    }
  }

  return (
    <div>
      <div className={classNames("relative", ghost && "hidden")}>
        <audio id="room-audio" className="hidden" />
        <video
          id="video-local"
          className={classNames(
            "aspect-video max-w-full object-contain",
            !videoOn && !screenSharing && "hidden"
          )}
        />
        <div
          className={classNames(
            "flex aspect-video h-full items-center justify-center bg-neutral-800 text-[length:2cqw]",
            (videoOn || screenSharing) && "hidden"
          )}
        >
          {localUser.fullName}
        </div>
        <div className="absolute bottom-2 right-1">
          <BaseButton
            onClick={toggleLocalAudio}
            active={!audioOn}
            disabled={localControlsLocked || audioSetupInProgress}
            className="mr-2 w-20"
            activeIcon={faMicrophoneSlash}
            inactiveIcon={faMicrophone}
            transparent={true}
          />
          {localUser.type !== "Student" && (
            <BaseButton
              onClick={toggleLocalVideo}
              className="mr-2 w-20"
              active={!videoOn}
              disabled={localControlsLocked || videoSetupInProgress}
              activeIcon={faVideoSlash}
              inactiveIcon={faVideo}
              transparent={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default LocalTile
