import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import Select from "react-select"

import { parentClient } from "src/graphql-config"
import ordered from "src/ordered"

import WithLoadingIndicator from "components/WithLoadingIndicator"

const Subjects = ({ studentId, onChange }) => {
  const loadingState = useQuery(
    buildQuery(subjectsQuery, { studentId }, { client: parentClient })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading your subjects"
    >
      {({ data }) => (
        <Select
          options={ordered(data.student.subjects, "name")}
          onChange={onChange}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
        />
      )}
    </WithLoadingIndicator>
  )
}

const subjectsQuery = compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      subjects {
        id
        name
      }
    }
  }
`

export default Subjects
