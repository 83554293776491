import React from "react"

import { FieldArray, useField, useFormikContext } from "formik"

import { degreeLevelOptions, degreeTypeOptions } from "src/enums"

import { ErrorMessage } from "components/Forms/Formik"
import {
  AutocompleteField,
  CheckboxField,
  Field,
  FileField,
  SelectField,
} from "components/Forms/Formik/hookComponents"

const Degrees = () => {
  const { setFieldValue } = useFormikContext()
  const [degreesField] = useField("degreesAttributes")

  return (
    <div className="mb-4">
      {degreesField.value.filter(d => !d._destroy).length === 0 && (
        <CheckboxField
          name="noCollegeEducation"
          label="Did not attend college"
        />
      )}
      <FieldArray name="degreesAttributes">
        {arrayHelpers => (
          <>
            {degreesField.value.map((degree, index) =>
              degree._destroy ? null : (
                <React.Fragment key={index}>
                  <AutocompleteField
                    api="/api/public/colleges/autocomplete_name"
                    name={`degreesAttributes[${index}].college`}
                    label="College/University"
                    creatable
                    defaultValue={
                      degree.college
                        ? { label: degree.college, value: degree.college }
                        : null
                    }
                    onChange={selected => {
                      setFieldValue(
                        `degreesAttributes[${index}].college`,
                        selected?.label
                      )
                      setFieldValue(
                        `degreesAttributes[${index}].diplomaMill`,
                        selected?.diploma_mill
                      )
                    }}
                  />
                  <Field
                    name={`degreesAttributes[${index}].degree`}
                    label="Degree"
                  />
                  <SelectField
                    name={`degreesAttributes[${index}].degreeType`}
                    label="Degree Type"
                    options={degreeTypeOptions}
                    defaultValue={degreeTypeOptions.find(
                      l => l.value === degree.degreeType
                    )}
                  />
                  <SelectField
                    name={`degreesAttributes[${index}].level`}
                    label="Level"
                    options={degreeLevelOptions}
                    defaultValue={degreeLevelOptions.find(
                      l => l.value === degree.level
                    )}
                  />

                  {degree.level === "other" && (
                    <Field
                      name={`degreesAttributes[${index}].levelOther`}
                      label="What is your degree level?"
                    />
                  )}

                  <Field
                    name={`degreesAttributes[${index}].year`}
                    label="Year of Graduation"
                    type="number"
                  />
                  <CheckboxField
                    name={`degreesAttributes[${index}].inUsa`}
                    label="This college is located in the US"
                  />

                  {!degree.inUsa && (
                    <AutocompleteField
                      api="/api/public/countries/autocomplete_name"
                      name={`degreesAttributes[${index}].country`}
                      label="Country where degree was obtained"
                      creatable
                      defaultValue={
                        degree.country
                          ? { label: degree.country, value: degree.country }
                          : null
                      }
                      onChange={selected =>
                        setFieldValue(
                          `degreesAttributes[${index}].country`,
                          selected?.label
                        )
                      }
                    />
                  )}

                  <FileField
                    name={`degreesAttributes[${index}].transcript`}
                    label="Transcript (optional)"
                  />
                  <button
                    type="button"
                    className="btn solid red"
                    onClick={() => {
                      if (degree.id) {
                        arrayHelpers.replace(index, {
                          ...degree,
                          _destroy: true,
                        })
                      } else {
                        arrayHelpers.remove(index)
                      }
                    }}
                  >
                    Remove Degree
                  </button>
                  <hr />
                </React.Fragment>
              )
            )}

            <button
              type="button"
              className="btn solid blue"
              onClick={() =>
                arrayHelpers.push({
                  college: "",
                  degree: "",
                  level: "",
                  levelOther: "",
                  year: "",
                  country: "",
                  degreeType: "",
                  inUsa: false,
                  diplomaMill: false,
                  transcript: {},
                })
              }
            >
              Add Degree
            </button>

            <div>
              <ErrorMessage name="degreesAttributes" />
            </div>
          </>
        )}
      </FieldArray>
    </div>
  )
}

export default Degrees
