import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { tutorClient } from "src/graphql-config"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const UnbilledSessions = props => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(unbilledSessionsQuery, { page: page }, { client: tutorClient })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading your unbilled sessions"
      heading={<h2>Unbilled Sessions</h2>}
    >
      {({ data }) =>
        data.airtutorsSessions.data.length === 0 ? (
          "No unbilled sessions"
        ) : (
          <React.Fragment>
            <table>
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Student</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.airtutorsSessions.data.map(session => (
                  <tr key={session.id}>
                    <td>{session.subject.name}</td>
                    <td>
                      <ul>
                        {session.students.map(student => (
                          <li key={student.id}>
                            <a href={student.showPath}>{student.fullName}</a>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <LocalTime
                        format="ddd MMM D"
                        timestamp={session.startsAt}
                      />
                    </td>
                    <td>
                      <LocalTime omitDate timestamp={session.startsAt} />
                      &mdash;
                      <LocalTime omitDate timestamp={session.endsAt} />
                    </td>
                    <td className="button-cell">
                      <a
                        className="btn solid small blue"
                        href={session.showPath}
                      >
                        View
                      </a>
                      <a
                        className="btn solid small green"
                        href={session.billPath}
                      >
                        Complete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={page}
              totalPages={data.airtutorsSessions.totalPages}
              changePage={page => setPage(page)}
            />
          </React.Fragment>
        )
      }
    </WithLoadingIndicator>
  )
}

const unbilledSessionsQuery = compress`
  query($page: Int!) {
    airtutorsSessions(unbilled: true, scheduled: true, past: true, page: $page) {
      totalPages
      data {
        id
        startsAt
        endsAt
        showPath
        billPath
        students {
          id
          fullName
          showPath
        }
        subject {
          name
        }
      }
    }
  }
`

export default UnbilledSessions
