import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RemoveAvailabilitySet = ({ availabilitySetId }) => {
  const [errors, setErrors] = useState()
  const { runMutation } = useMutation(buildMutation(removeMutation))

  const remove = () => {
    runMutation({ availabilitySetId })
      .then(
        response => {
          const { errorMessages } = response.availabilitySetRemove
          if (errorMessages) {
            setErrors(errorMessages)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Remove Availability Set"
      buttonText="Remove Availability Set"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              This will remove all availabilities in this set as well as any
              associated student availabilities
            </p>
          </div>

          <div className="modal-footer flex-column">
            {errors && <div className="alert alert-danger">{errors}</div>}
            <div className="d-flex justify-content-between w-full">
              <button onClick={closeModal} className="btn btn-outline-info">
                Never mind
              </button>
              <button onClick={remove} className="btn btn-danger">
                Remove
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const removeMutation = compress`
  mutation($availabilitySetId: ID!) {
    availabilitySetRemove(availabilitySetId: $availabilitySetId) {
      errorMessages
      availabilitySet {
        id
      }
    }
  }
`

export default RemoveAvailabilitySet
