class TwilioUser {
  constructor({
    identity,
    audioTrack,
    videoTrack,
    audioMuted = false,
    videoMuted = false,
  }) {
    this.identity = identity
    if (identity) {
      this.id = identity.match(/\d+/)[0]
      this.type = identity.match(/[a-zA-Z]+/)[0]
    }
    this.audioMuted = audioMuted
    this.videoMuted = videoMuted
    this.audioTrack = audioTrack
    this.videoTrack = videoTrack
  }

  addTrack = track => {
    if (track.kind === "audio") {
      this.audioTrack = track
    } else if (track.kind === "video") {
      this.videoTrack = track
    } else {
      throw Error.new(`unsupported track type detected: ${track.kind}`)
    }
  }

  get tracks() {
    return [this.audioTrack, this.videoTrack].filter(track => track)
  }
}

export default TwilioUser
