import { compress } from "micro-graphql-react"

export default compress`
  fragment StudentLessonFields on Student {
    lessonPlanAssignments {
      id
      lessonPlan {
        id
        name
        showPath

        lessonTopics {
          id
          name
          lessonResources {
            id
            name
            completed(studentId: $studentId)
            current(studentId: $studentId)
            lessonResourceCompletions(studentId: $studentId) {
              id
              studentMastery
              studentTopicMasteryStart
              studentTopicMasteryEnd
              tutorMastery
              tutorTopicMastery
              session {
                id
                showPath
                startsAt
                tutor {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`
