import React, { useState } from "react"

import { gql, useQuery } from "urql"

import Header from "~tailwindui/Form/Header"
import Table from "~tailwindui/Table/Table"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

const StudyGroupsPage = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: studyGroupsQuery,
    variables: { page },
  })

  return (
    <>
      <WithLoadingIndicator
        result={result}
        header={<Header title="Study Groups" />}
      >
        {({ data }) => (
          <>
            <Table
              data={data.viewer.organization.studyGroups}
              changePage={setPage}
              headers={["Name", "Tutor", "Subject", "Students", "School"]}
              valueMapper={studyGroup => [
                studyGroup.name,
                studyGroup.tutor?.fullName,
                <ul key="subjectsList" className="list-none">
                  {studyGroup.subjects.map(subject => (
                    <li key={subject.id}>{subject.name}</li>
                  ))}
                </ul>,
                <ul key="studentsList" className="list-none">
                  {studyGroup.students.map(student => (
                    <li key={student.id}>{student.fullName}</li>
                  ))}
                </ul>,
                studyGroup.school?.name || "N/A",
              ]}
              rowCallback={studyGroup =>
                (window.location.href = `/organization_admin/study_groups/${studyGroup.id}`)
              }
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const studyGroupsQuery = gql`
  query studyGroups($page: Int!) {
    viewer {
      organization {
        studyGroups: paginatedStudyGroups(page: $page) {
          currentPage
          totalPages
          data {
            id
            name
            tutor {
              id
              fullName
            }
            subjects {
              id
              name
            }
            students {
              id
              fullName
            }
            school {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default StudyGroupsPage
