import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { ErrorMessage, File, SubmitButton } from "components/Forms/Formik"

import createTutorBulkUploadMutation from "./createTutorBulkUploadMutation"

const ValidationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test("file", "File must be present", file => {
      return file.name || file.fileName
    })
    .test(
      "file",
      "File must be a CSV. Please use export to option in your spreadhseet application.",
      file => {
        return file.type === "text/csv"
      }
    ),
})

const TutorBulkUploadForm = props => {
  const { runMutation } = useMutation(
    buildMutation(createTutorBulkUploadMutation)
  )
  const formState = {
    file: {},
  }

  const handleSubmit = (values, actions) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      runMutation({
        file: b64,
        filename: values.file.name || values.file.fileName,
      }).then(response => {
        const { success, redirectTo, errorMessage } =
          response.createTutorBulkUpload
        if (success) {
          window.location = redirectTo
        } else {
          actions.setStatus(errorMessage)
        }
      })
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ status, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <label className="form-label w-100">
                  CSV File
                  <File
                    name="file"
                    className="form-control"
                    accept=".csv"
                    setFieldValue={setFieldValue}
                  />
                </label>
                <ErrorMessage name="file" />
              </div>
            </div>

            <div className="col-12">
              {status && (
                <div>
                  <div className="alert alert-danger d-inline-block">
                    {status}
                  </div>
                </div>
              )}

              <SubmitButton isSubmitting={isSubmitting} text="Upload" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default TutorBulkUploadForm
