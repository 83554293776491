import styled from "styled-components"

import ActionContainer from "./ActionContainer"
import Item from "./Item"
import UrqlItem from "./UrqlItem"

const EditableList = styled.ul`
  padding-left: 0;
  list-style: none;
  li {
    margin-bottom: 5px;
  }
`

export default EditableList
export { ActionContainer, Item, UrqlItem }
