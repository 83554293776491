import React, { ReactNode } from "react"

import { NavLink } from "react-router-dom"

import { ChevronRightIcon } from "@heroicons/react/20/solid"

export type SimpleLinkListProps = {
  items: { to: string; display: ReactNode }[]
}

const SimpleLinkList: React.FC<SimpleLinkListProps> = ({ items }) => {
  return (
    <ul>
      {items.map(item => (
        <NavLink
          key={item.to}
          to={item.to}
          className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50"
        >
          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="relative truncate text-lg leading-6 text-gray-900">
                {item.display}
              </p>
            </div>
            <ChevronRightIcon
              className="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </div>
        </NavLink>
      ))}
    </ul>
  )
}

export default SimpleLinkList
