import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import { tutorClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import CourseSection from "./CourseSection"

const CourseSectionRegistration = () => {
  const loadingState = useQuery(buildQuery(courseClustersQuery, {}, { client }))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div>
          <p
            css={css`
              font-size: 18px;
              font-style: italic;
              text-align: center;
            `}
          >
            If no courses are displayed below, all available groups have been
            registered for at this time.
          </p>
          {ordered(data.registerableCourseClusters, "name").map(
            courseCluster => (
              <div key={courseCluster.id}>
                <h2>{courseCluster.name}</h2>
                <p className="italic">{courseCluster.description}</p>
                <dl className="mb-0">
                  <dt>Grades</dt>
                  <dd
                    css={css`
                      :after {
                        content: initial;
                      }
                    `}
                  >
                    <ul className="m-0">
                      {ordered(courseCluster.grades, "number").map(grade => (
                        <li key={grade.id}>{grade.name}</li>
                      ))}
                    </ul>
                  </dd>

                  <dt>Subject</dt>
                  <dd>{courseCluster.subject.name}</dd>
                </dl>
                <hr />
                {ordered(
                  courseCluster.tutorRegisterableCourseSections,
                  "startsOn"
                ).map(courseSection => (
                  <CourseSection
                    key={courseSection.id}
                    courseSection={courseSection}
                  />
                ))}
              </div>
            )
          )}
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const courseClustersQuery = compress`
  query {
    viewer {
      foreignLanguages {
        id
      }
    }
    registerableCourseClusters {
      id
      name
      description
      subject {
        id
        name
      }
      grades {
        id
        name
        number
      }
      tutorRegisterableCourseSections {
        id
        startsOn
        endsOn
        maxSubsEstimate
        organization {
          minTutorConsistencyRate
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
          duration
        }
      }
    }
  }
`

export default CourseSectionRegistration
