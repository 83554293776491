import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import Form from "~tailwindui/Form"
import AlertMessageBox from "~tailwindui/Form/AlertMessageBox"
import FormikForm from "~tailwindui/FormikForm"
import Table from "~tailwindui/Table/Table"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import emptyFunction from "src/emptyFunction"
import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"
import DonutChart from "components/OrganizationAdmin/components/DonutChart"
import { studentPresenceIcon } from "components/OrganizationAdmin/components/presenceIcon"

interface MatchParams {
  id: string
}

const SessionDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const [result] = useQuery({
    query: sessionsQuery,
    variables: { id },
  })

  const statusMessage = ({ statusState, canceledBy, cancellationReason }) => {
    switch (statusState) {
      case "initial":
      case "requested":
        return "Note: The details of this session have not yet been finalized."
      case "scheduled":
        return ""
      case "substitute_requested":
        return "Note: A substitute has been requested for this session."
      case "canceled":
        return `Note: This session was canceled by the ${canceledBy} for the following reason: "${cancellationReason}"`
      case "declined":
        return "Note: This session was declined by the tutor."
    }
  }

  const buildChart = ({ studentSessions, statusState }) => {
    if (studentSessions.length < 2 || statusState !== "scheduled") return null
    const noShows = studentSessions.filter(
      studentSession => studentSession.noShow
    ).length
    const excusedAbsences = studentSessions.filter(
      studentSession => studentSession.excusedAbsence
    ).length
    const presences = studentSessions.filter(
      studentSession => !studentSession.noShow && !studentSession.excusedAbsence
    ).length
    return (
      <DonutChart
        title="Attendance"
        classNames="mt-10"
        data={[
          {
            value: excusedAbsences,
            name: `Excused (${excusedAbsences})`,
          },
          {
            value: presences,
            name: `Present (${presences})`,
          },
          {
            value: noShows,
            name: `No Shows (${noShows})`,
          },
        ]}
      />
    )
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer, airtutorsSession } }) =>
        airtutorsSession === null ? (
          <AlertMessageBox
            messages={[{ message: "You do not have access to this session" }]}
          />
        ) : (
          <>
            <FormikForm.Wrapper
              editable={false}
              initialValues={airtutorsSession}
              title={`${airtutorsSession.subject.name} Session by ${airtutorsSession.tutor.fullName}`}
              displayData={airtutorsSession}
            >
              <>
                {(airtutorsSession.tutorFeedback ||
                  airtutorsSession.topicsCovered) && (
                  <blockquote className="border-l-4 border-gray-300 bg-gray-50 p-4">
                    <p className="text-xl font-medium italic leading-relaxed text-gray-900">
                      "{airtutorsSession.tutorFeedback}"
                    </p>
                    <p className="text-lg font-light italic">
                      ~ {airtutorsSession.tutor.fullName}
                    </p>
                    <br />
                    <p className="text-lg font-light italic">
                      Topics Covered: {airtutorsSession.topicsCovered}
                    </p>
                  </blockquote>
                )}
                {
                  <>
                    {airtutorsSession.future && (
                      <div className="text-md font-medium italic text-gray-500">
                        This session has not happened yet.
                      </div>
                    )}
                    <div className="text-md font-medium italic text-red-500">
                      {statusMessage(airtutorsSession)}
                    </div>
                  </>
                }
                <Form.Section title="Session Information">
                  <FormikForm.TextInput label="Subject" name="subject.name" />
                  <FormikForm.TextInput label="Tutor" name="tutor.fullName" />
                  {airtutorsSession.studyGroup && (
                    <FormikForm.TextInput
                      label="Study Group"
                      name="studyGroup.name"
                    />
                  )}
                  <>
                    <Form.ValueDisplay
                      label="Start Time"
                      value={
                        <LocalTime
                          timestamp={airtutorsSession.startsAt}
                          format="ddd lll"
                        />
                      }
                    />
                    {airtutorsSession.formattedActualDuration ? (
                      <FormikForm.TextInput
                        label="Duration"
                        name="formattedActualDuration"
                      />
                    ) : (
                      <Form.ValueDisplay
                        label="Scheduled Duration"
                        value={`${airtutorsSession.scheduledDurationMinutes} minutes`}
                      />
                    )}
                    <Form.ValueDisplay
                      label="Status"
                      value={airtutorsSession.statusState}
                    />
                    {viewer.canOrganizationAdminAccessFinances && (
                      <Form.ValueDisplay
                        label="Billing Status"
                        value={airtutorsSession.billState}
                      />
                    )}
                  </>
                </Form.Section>
              </>
            </FormikForm.Wrapper>
            {buildChart(airtutorsSession)}
            <Table
              title="Students"
              data={airtutorsSession.studentSessions}
              changePage={emptyFunction}
              headers={[
                "Name",
                "Present?",
                "Participation",
                "Tutor Assessment",
                "Cancellation/Absence Details",
              ]}
              valueMapper={studentSession => [
                studentSession.student.fullName,
                studentPresenceIcon(studentSession, airtutorsSession),
                titlecase(studentSession.participationLevel),
                titlecase(studentSession.tutorAssessment),
                studentSession.cancellationReason,
              ]}
              rowCallback={studentSession =>
                (window.location.href = `/organization_admin/students/${studentSession.student.id}`)
              }
            />
          </>
        )
      }
    </WithLoadingIndicator>
  )
}

const sessionsQuery = gql`
  query session($id: ID!) {
    viewer {
      canOrganizationAdminAccessFinances
    }
    airtutorsSession(id: $id) {
      id
      startsAt
      endsAt
      future
      statusState
      billState
      tutorFeedback
      studentFeedback
      scheduledDurationMinutes
      formattedBilledDuration
      formattedActualDuration
      canceledBy
      cancellationReason
      noShow
      missingTutor
      topicsCovered
      studyGroup {
        id
        name
        school {
          id
          name
        }
      }
      tutor {
        id
        fullName
      }
      subject {
        id
        name
      }
      studentSessions {
        id
        noShow
        excusedAbsence
        canceledAt
        cancellationReason
        participationLevel
        tutorAssessment
        student {
          id
          fullName
        }
      }
    }
  }
`

export default SessionDetails
