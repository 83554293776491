import React, { useContext } from "react"

import {
  faLock,
  faLockOpen,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import classNames from "src/classNames"

import { gql, useClient, useQuery } from "hooks/urql"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import BaseButton from "components/ChimeCloudroom/Shared/BaseButton"
import RemoteScreenShare from "components/ChimeCloudroom/TutorLayout/RemoteScreenShare"

export type RemoteTileProps = {
  attendeeId: string
  numAttendees?: number
  tutorView?: boolean
  qualityControlView?: boolean
}

const RemoteTile: React.FC<RemoteTileProps> = ({
  attendeeId,
  numAttendees = 1,
  tutorView = false,
  qualityControlView = false,
}) => {
  const { session, allControlsLocked, controlsLockedUserIds, roster, channel } =
    useContext(CloudroomContext)

  const attendee = roster[attendeeId]
  const attendeeAudioOn = attendee && !attendee.muted
  const attendeeVideoOn = attendee?.sharingVideo
  const attendeeScreensharing = attendee?.sharingContent

  const [userType, userId] = attendee?.externalUserId.split("-")
  const lowBandwidthStudent =
    userType === "Student" && session.lowBandwidthCloudroom
  const remoteControls = tutorView && userType === "Student"

  const [result] = useQuery({
    query,
    client: useClient(),
    variables: { sessionId: session.id, userId },
  })

  let heightClassName
  if (numAttendees < 2) heightClassName = "max-h-screen"
  else if (numAttendees < 7) heightClassName = "max-h-[50vh]"
  else heightClassName = "max-h-[33vh]"

  return (
    <div
      className={classNames(
        "space-around flex flex-col justify-center",
        heightClassName
      )}
    >
      <div
        className={classNames(
          "flex aspect-video justify-center",
          tutorView && "max-h-[calc(100%-100px)]"
        )}
      >
        <video
          id={`video-${attendeeId}`}
          className={classNames(
            "aspect-video max-w-full object-contain",
            !attendeeVideoOn && !attendeeScreensharing && "hidden",
            qualityControlView && "max-h-[19vh]"
          )}
        />
        <div
          className={classNames(
            "flex aspect-video h-full items-center justify-center bg-neutral-800",
            tutorView ? "text-[max(1.5rem,2cqw)]" : "text-[2cqw]",
            (attendeeVideoOn || attendeeScreensharing) && "hidden",
            qualityControlView && "max-h-[19vh]"
          )}
        >
          {result.data && result.data.airtutorsSession.cloudroomUser.fullName}
          &nbsp;
          {!remoteControls && (
            <FontAwesomeIcon
              icon={attendeeAudioOn ? faMicrophone : faMicrophoneSlash}
            />
          )}
        </div>
      </div>

      <div className="flex h-20 flex-col items-center py-2">
        {remoteControls && (
          <div className="flex max-w-[500px] justify-between">
            <BaseButton
              onClick={() => {
                if (attendeeAudioOn)
                  channel.perform("mute_user", { type: "audio", userId })
                else channel.perform("unmute_user", { type: "audio", userId })
              }}
              active={!attendeeAudioOn}
              className="mr-4 w-12 py-2"
              activeIcon={faMicrophoneSlash}
              inactiveIcon={faMicrophone}
            />
            {!lowBandwidthStudent && (
              <BaseButton
                onClick={() => {
                  if (attendeeVideoOn)
                    channel.perform("mute_user", { type: "video", userId })
                  else channel.perform("unmute_user", { type: "video", userId })
                }}
                active={!attendeeVideoOn}
                activeIcon={faVideoSlash}
                inactiveIcon={faVideo}
                className="mr-4 w-12 py-2"
              />
            )}
            <BaseButton
              onClick={() => {
                if (controlsLockedUserIds.includes(userId))
                  channel.perform("unlock_user", { userId })
                else channel.perform("lock_user", { userId })
              }}
              active={
                allControlsLocked || controlsLockedUserIds.includes(userId)
              }
              activeIcon={faLock}
              inactiveIcon={faLockOpen}
              className="mr-4 w-12 py-2"
            />
            <RemoteScreenShare userId={userId} />
          </div>
        )}

        {!qualityControlView && attendeeVideoOn && result.data && (
          <span className="mr-4">
            {result.data.airtutorsSession.cloudroomUser.fullName}
            &nbsp;
            {!remoteControls && (
              <FontAwesomeIcon
                icon={attendeeAudioOn ? faMicrophone : faMicrophoneSlash}
              />
            )}
          </span>
        )}
      </div>
    </div>
  )
}

const query = gql`
  query ($sessionId: ID!, $userId: ID!) {
    airtutorsSession(id: $sessionId) {
      cloudroomUser(id: $userId) {
        id
        fullName
      }
    }
  }
`

export default RemoteTile
