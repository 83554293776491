import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const DeleteFutureSessions = ({ studyGroupId }) => {
  const [errors, setErrors] = useState()
  const { runMutation, running } = useMutation(buildMutation(deleteMutation))

  const handleMutation = () => {
    runMutation({ studyGroupId })
      .then(
        response => {
          const { errorMessages } = response.studyGroupDeleteFutureSessions
          if (errorMessages) {
            setErrors(errorMessages)
          } else {
            window.location.reload()
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Delete Future Sessions"
      buttonText="Delete Future Sessions"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              This will <span className="italic">delete</span> ALL future
              sessions. NO notifications will be sent. This is a NUCLEAR OPTION.
              Only use if you are trying to adjust the scheduled time for bulk
              scheduled sessions or really just want all the sessions to become
              dodo birds.
            </p>
          </div>
          <div className="modal-footer flex-column">
            {errors && <div className="alert alert-danger">{errors}</div>}
            <div className="grid w-full grid-cols-2 gap-x-4">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-outline-info whitespace-normal"
              >
                Aw hayl no!
                <br />
                Get me outta here!
              </button>
              <button
                type="button"
                onClick={handleMutation}
                className="btn btn-danger whitespace-normal"
                disabled={running}
              >
                YES,
                <br />
                I'M POSITIVE I KNOW WHAT I'M DOING
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const deleteMutation = compress`
  mutation($studyGroupId: ID!) {
    studyGroupDeleteFutureSessions(studyGroupId: $studyGroupId) {
      errorMessages
    }
  }
`

export default DeleteFutureSessions
