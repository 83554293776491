import React, { useState } from "react"

import ordered from "src/ordered"

import { gql, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { Positive } from "components/Icons"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type OrganizationOrganizationAdminsProps = {
  organizationId: string
  newPath: string
}

const OrganizationOrganizationAdmins: React.FC<
  OrganizationOrganizationAdminsProps
> = ({ organizationId, newPath }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: organizationAdminsQuery,
    variables: { organizationId, page },
  })

  return (
    <UrqlLoadingIndicator
      result={result}
      heading={
        <div className="mb-3 flex items-center">
          <h4 className="m-0 mr-3">Admins</h4>
          <a href={newPath} className="btn btn-success">
            New Admin
          </a>
        </div>
      }
    >
      {({ data }) => (
        <>
          <AutocompleteSelect
            api="/api/admins/organization_admins/autocomplete_full_name"
            searchOptions={`organization_id=${organizationId}`}
            onChange={selectedOption => {
              if (selectedOption)
                window.location.href = `/admin/organization_admins/${selectedOption.value}`
            }}
            placeholder="Jump to admin"
          />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Financial Data?</th>
                <th>Semesters?</th>
                <th>User Management?</th>
                <th>Schools</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {data.organization.organizationAdmins.data.map(
                organizationAdmin => (
                  <tr key={organizationAdmin.id}>
                    <td>{organizationAdmin.fullName}</td>
                    <td>{organizationAdmin.email}</td>
                    <td className="text-center">
                      {organizationAdmin.canOrganizationAdminAccessFinances && (
                        <Positive />
                      )}
                    </td>
                    <td className="text-center">
                      {organizationAdmin.canOrganizationAdminAccessSemesters && (
                        <Positive />
                      )}
                    </td>
                    <td className="text-center">
                      {organizationAdmin.canOrganizationAdminAccessUserManagement && (
                        <Positive />
                      )}
                    </td>
                    <td>
                      {organizationAdmin.canOrganizationAdminAccessAllSchools
                        ? "All"
                        : ordered(
                            organizationAdmin.accessibleSchools,
                            "name"
                          ).map(school => (
                            <div key={school.id}>{school.name}</div>
                          ))}
                    </td>
                    <td>
                      <a
                        className="btn btn-sm btn-info"
                        href={organizationAdmin.showPath}
                      >
                        View
                      </a>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={data.organization.organizationAdmins.currentPage}
            totalPages={data.organization.organizationAdmins.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const organizationAdminsQuery = gql`
  query ($organizationId: ID!, $page: Int!) {
    organization(id: $organizationId) {
      organizationAdmins: paginatedOrganizationAdmins(page: $page) {
        currentPage
        totalPages
        data {
          id
          fullName
          email
          canOrganizationAdminAccessFinances
          canOrganizationAdminAccessSemesters
          canOrganizationAdminAccessUserManagement
          canOrganizationAdminAccessAllSchools
          showPath

          accessibleSchools {
            id
            name
          }
        }
      }
    }
  }
`

export default OrganizationOrganizationAdmins
