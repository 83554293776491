import React from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import Select, { components as selectComponents } from "react-select"
import { gray, red } from "styles/colors"
import * as Yup from "yup"

import { css } from "@emotion/core"
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import airbrake from "src/airbrake-notifier"
import { gradeOptionsFromTiers } from "src/grade-options"
import { publicClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SingleValue = props => (
  <selectComponents.SingleValue {...props}>
    {props.data.shortLabel}
  </selectComponents.SingleValue>
)

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  password: Yup.string()
    .required("Please specify a password")
    .min(6, "Password must be at least 6 characters long"),
  passwordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("password")], "Passwords do not match"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the Air Tutors privacy policy"
  ),
  students: Yup.array()
    .min(1, "Please add at least one student")
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        gradeId: Yup.string().required("Grade is required"),
        schoolName: Yup.string().required("Please specify a school"),
        password: Yup.string()
          .required("Please specify a password")
          .min(6, "Password must be at least 6 characters long"),
        passwordConfirmation: Yup.string()
          .required("Please confirm your password")
          .equals([Yup.ref("password")], "Passwords do not match"),
      })
    ),
})

const ParentRegistration = ({ prepaidPackageId, partnerId, data }) => {
  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    stripeToken: "",
    password: "",
    passwordConfirmation: "",
    students: [],
    termsAccepted: false,
    prepaidPackageId: prepaidPackageId,
    partnerId: partnerId,
  }

  const { runMutation } = useMutation(
    buildMutation(createAccountMutation, { client })
  )

  const stripe = useStripe()
  const elements = useElements()

  const packageOptions = ordered(data.prepaidPackages, "hours").map(
    prepaidPackage => ({
      value: prepaidPackage.id,
      shortLabel: `${prepaidPackage.name}: ${prepaidPackage.formattedPrice}`,
      label: (
        <div>
          {prepaidPackage.name}: {prepaidPackage.formattedPrice}
          <br />
          {prepaidPackage.hours} hours
          <br />
          Additional Hours: {prepaidPackage.formattedHourlyRate} / hour
        </div>
      ),
    })
  )

  const gradeOptions = gradeOptionsFromTiers(data.tiers)

  const handleSubmit = (values, actions) => {
    const cardElement = elements.getElement(CardElement)

    stripe
      .createSource(cardElement, { type: "card" })
      .then(
        ({ error, source }) => {
          if (error) {
            actions.setStatus(error.message)
            actions.setSubmitting(false)
            return
          }

          values.stripeToken = source.id
          runMutation({ input: values })
            .then(
              response => {
                const { errorMessage } = response.registerParent
                if (errorMessage) {
                  actions.setStatus(errorMessage)
                  actions.setSubmitting(false)
                } else {
                  window.location = "/"
                }
              },
              error => {
                actions.setStatus(
                  "Something went wrong while creating your account"
                )
                airbrake.notify({
                  error,
                  name: "ParentRegistration#runMutation",
                })
                actions.setSubmitting(false)
              }
            )
            .catch(error => {
              actions.setStatus(
                "Something went wrong while creating your account"
              )
              airbrake.notify({
                error,
                name: "ParentRegistration#runMutation",
              })
              actions.setSubmitting(false)
            })
        },
        error => {
          actions.setStatus(
            "Something went wrong while saving your payment information"
          )
          airbrake.notify({
            error,
            name: "ParentRegistration#createSource",
          })
          actions.setSubmitting(false)
        }
      )
      .catch(error => {
        actions.setStatus(
          "Something went wrong while saving your payment information"
        )
        airbrake.notify({
          error,
          name: "ParentRegistration#createSource",
        })
        actions.setSubmitting(false)
      })
  }

  return (
    <div
      css={css`
        margin-left: 30px;
        margin-right: 30px;

        .form-group {
          margin: 30px 0;
        }

        .form-label {
          display: flex;
          align-items: center;
          > span {
            font-weight: 500;
          }

          > span,
          > input,
          > div {
            flex-basis: 33%;
          }
        }
        .form-error {
          display: flex;
          align-items: center;
          > div {
            margin: 0 0 0 30px;
          }
        }
        .credit-card-info {
          font-style: italic;
          color: ${gray};
          width: 66%;
        }

        .StripeElement {
          margin-bottom: 0;
        }

        @media screen and (max-width: 768px) {
          .form-label {
            flex-flow: column;

            > span,
            > input,
            > div {
              width: 100%;
            }
          }
          .form-error {
            > div {
              margin: 15px 0;
            }
          }
          .credit-card-info {
            width: 100%;
          }
        }
      `}
    >
      <h1
        css={css`
          text-align: center;
          font-weight: 500;
        `}
      >
        Parent Registration
      </h1>
      <div className="text-center">
        <a href="?student=true">
          Not a parent? Click here to create a student account
        </a>
      </div>
      <Formik
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        initialValues={formState}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label className="form-label">
                <span>Parent First Name</span>
                <Field name="firstName" autoFocus />
                <div className="form-error">
                  <ErrorMessage name="firstName" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Parent Last Name</span>
                <Field name="lastName" autoFocus />
                <div className="form-error">
                  <ErrorMessage name="lastName" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Parent Cell Phone</span>
                <Field name="phoneNumber" type="tel" />
                <div className="form-error">
                  <ErrorMessage name="phoneNumber" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Parent Email</span>
                <Field name="email" type="email" />
                <div className="form-error">
                  <ErrorMessage name="email" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Password</span>
                <Field
                  name="password"
                  type="password"
                  autoComplete="new-password"
                />
                <div className="form-error">
                  <ErrorMessage name="password" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Confirm Password</span>
                <Field
                  name="passwordConfirmation"
                  type="password"
                  autoComplete="new-password"
                />
                <div className="form-error">
                  <ErrorMessage name="passwordConfirmation" />
                </div>
              </label>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Credit Card</span>
                <CardElement
                  onChange={event => null}
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: red,
                      },
                    },
                  }}
                />
                <div className="form-error">
                  <ErrorMessage name="stripeToken" />
                </div>
              </label>
              <div className="credit-card-info">
                Credit card info is required to register but will not be charged
                until a purchase is confirmed. If you would like to register
                without a credit card please call us at 800-211-1986.
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">
                <span>Select Package (optional)</span>
                <Field name="prepaidPackageId">
                  {({ field: { name, value } }) => (
                    <Select
                      isClearable
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      isOptionSelected
                      options={packageOptions}
                      defaultValue={packageOptions.find(o => o.value === value)}
                      components={{ SingleValue }}
                      onChange={selectedOption =>
                        setFieldValue(
                          "prepaidPackageId",
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                      placeholder="Select Package"
                    />
                  )}
                </Field>
              </label>
            </div>

            <div>
              <h2>Students</h2>
              <FieldArray name="students">
                {arrayHelpers => (
                  <>
                    {values.students.map((student, index) => (
                      <React.Fragment key={index}>
                        <div className="form-group">
                          <label className="form-label">
                            <span>Student First Name</span>
                            <Field name={`students.${index}.firstName`} />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.firstName`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Student Last Name</span>
                            <Field name={`students.${index}.lastName`} />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.lastName`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Student Email</span>
                            <Field
                              name={`students.${index}.email`}
                              type="email"
                            />
                            <div className="form-error">
                              <ErrorMessage name={`students.${index}.email`} />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Student Cell Phone</span>
                            <Field
                              name={`students.${index}.phoneNumber`}
                              type="tel"
                            />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.phoneNumber`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Grade</span>
                            <Field name={`students.${index}.gradeId`}>
                              {({ field: { name, value } }) => (
                                <Select
                                  options={gradeOptions}
                                  onChange={selected =>
                                    setFieldValue(
                                      `students.${index}.gradeId`,
                                      selected?.value
                                    )
                                  }
                                />
                              )}
                            </Field>
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.gradeId`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>School</span>
                            <Field name={`students.${index}.schoolName`} />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.schoolName`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Password</span>
                            <Field
                              name={`students.${index}.password`}
                              type="password"
                            />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.password`}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            <span>Confirm Password</span>
                            <Field
                              name={`students.${index}.passwordConfirmation`}
                              type="password"
                            />
                            <div className="form-error">
                              <ErrorMessage
                                name={`students.${index}.passwordConfirmation`}
                              />
                            </div>
                          </label>
                        </div>

                        <div>
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className="btn solid red"
                          >
                            Remove the above student
                          </button>
                        </div>

                        <hr />
                      </React.Fragment>
                    ))}

                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            gradeId: "",
                            schoolName: "",
                            password: "",
                            passwordConfirmation: "",
                          })
                        }}
                        className="btn solid green"
                      >
                        Add Student
                      </button>
                    </div>
                    <ErrorMessage name="students" />
                  </>
                )}
              </FieldArray>
            </div>

            <div className="form-group">
              <RadioGroup
                type="checkbox"
                name="termsAccepted"
                values={[
                  {
                    value: true,
                    label: (
                      <>
                        I am giving Air Tutors permission to contact my family
                        regarding setting up tutoring services and agree to
                        the&nbsp;
                        <a href="/privacy-policy" target="_blank">
                          Air Tutors privacy policy
                        </a>
                      </>
                    ),
                  },
                ]}
              />
              <ErrorMessage name="termsAccepted" />
            </div>

            {status && (
              <div className="alert mt-3">
                <span dangerouslySetInnerHTML={{ __html: status }} />
              </div>
            )}
            <SubmitButton isSubmitting={isSubmitting} text="Create Account" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const packageOptionsQuery = compress`
  query($partnerId: ID) {
    prepaidPackages(partnerId: $partnerId) {
      id
      name
      hours
      price
      formattedPrice
      hourlyRate
      formattedHourlyRate
    }

    tiers {
      id
      gradeRange
      grades {
        id
        name
        number
      }
    }
  }
`

const createAccountMutation = compress`
  mutation($input: ParentRegistrationInputObject!) {
    registerParent(input: $input) {
      success
      errorMessage
    }
  }
`

const Wrapper = ({ prepaidPackageId, partnerId }) => {
  const loadingState = useQuery(
    buildQuery(packageOptionsQuery, { partnerId }, { client })
  )
  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Elements stripe={stripePromise}>
          <ParentRegistration
            prepaidPackageId={prepaidPackageId}
            partnerId={partnerId}
            data={data}
          />
        </Elements>
      )}
    </WithLoadingIndicator>
  )
}

export default Wrapper
