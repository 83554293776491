import React from "react"

import PropTypes from "prop-types"

import { adminClient } from "src/graphql-config"

import TailoredAssignmentPreview from "components/TailoredAssignmentPreview"

const AdminTailoredAssignmentPreview = props => {
  const { id } = props
  return <TailoredAssignmentPreview id={id} client={adminClient} />
}

AdminTailoredAssignmentPreview.propTypes = {
  id: PropTypes.number.isRequired,
}

export default AdminTailoredAssignmentPreview
