import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { publicClient as client } from "src/urql-client"

import { gql, useMutation, useQuery } from "hooks/urql"

import {
  AwsFileField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const ValidationSchema = Yup.object().shape({
  selfieUrl: Yup.mixed().test(
    "selfieUrl",
    "Selfie file upload or external URL is required",
    function () {
      if (!this.parent.selfieUrl && !this.parent.externalUrl) return false
      return true
    }
  ),
})

const SelfieSubmission = ({ token }) => {
  const formState = {
    token,
    selfieUrl: "",
    selfieFilename: "",
    externalUrl: "",
  }

  const [isUploading, setIsUploading] = useState(false)

  const [result] = useQuery({
    query: signedUrlQuery,
    variables: { token },
    client,
  })
  const [, runMutation] = useMutation(submitSelfieMutation, client)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions)
            return
          }

          const { failures } = result.data.submitTutorApplicationSelfie
          if (failures) {
            handleFailure(actions, failures)
          } else {
            actions.setSubmitting(false)
            actions.setStatus([
              {
                type: "success",
                message: "Selfie uploaded successfully. We'll be in touch!",
              },
            ])
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <p>
                After reviewing your questionnaire responses, we are thrilled to
                move forward with your application. The next step is for you to
                create a short (1-2 minute) “selfie” video so we get to see you
                in action!
              </p>
              <ul>
                <li>
                  Tell us about the path that led you to where you are now
                  (where you grew up, went to school, etc.)
                </li>
                <li>What do you do for fun?</li>
                <li>
                  Tell us either a funny or memorable teaching/tutoring story
                  from your past.
                </li>
              </ul>

              <p>
                To give you some examples, here are links to videos of Hasan and
                Amy, co-founders of Air Tutors: Meet Hasan! Meet Amy! Please be
                yourself in your video - we want to get a feel for your
                personality as well as some interesting tidbits about you. As
                you can see from our videos, recording the selfie from your
                phone or computer is totally fine! You don't have to have a full
                blown production - we'd actually prefer that you don't. Just
                make sure you're clearly visible and stay within the 2-3 minute
                timeframe. You can submit your video as a YouTube, Vimeo, or
                Loom link (recommended), or as a Dropbox or Google Drive link.
                Deadline for completion: 3 days upon receipt of this email.
                Please include your first and last name in the filename. We look
                forward to watching your video and learning more about you!
                Note: For those invited to move forward after the video, the
                last step is an interview and teaching test.
              </p>
              <AwsFileField
                name="selfie"
                label="Selfie Video"
                signedUrl={data.tutorApplication.selfie.signedUrl}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
              <div className="alert bg-blue-400 p-5 text-left italic">
                Note: uploading files takes time -- make sure you stay on this
                page until the "Uploading..." text disappears and then be sure
                to click "Submit Selfie" below!
                <br />
                <div className="text-center font-bold normal-case">
                  If everything worked, you will see a green success
                  notification at the bottom of the form.
                </div>
              </div>
              <div>
                <p>OR</p>
              </div>
              <Field
                name="externalUrl"
                label="Provide a Youtube/Loom/Vimeo link"
              />
              <FormFailures />
              <SubmitButton
                text="Submit Selfie"
                submitting={isSubmitting || isUploading}
              />
            </Form>
          )}
        </Formik>
      )}
    </UrqlLoadingIndicator>
  )
}

const signedUrlQuery = gql`
  query ($token: String!) {
    tutorApplication(token: $token) {
      selfie {
        signedUrl {
          url
          key
          successActionStatus
          acl
          policy
          credential
          algorithm
          date
          signature
        }
      }
    }
  }
`

const submitSelfieMutation = gql`
  mutation (
    $token: String!
    $selfieUrl: String
    $selfieFilename: String
    $externalUrl: String
  ) {
    submitTutorApplicationSelfie(
      token: $token
      selfieUrl: $selfieUrl
      selfieFilename: $selfieFilename
      externalUrl: $externalUrl
    ) {
      failures {
        message
      }
    }
  }
`

export default SelfieSubmission
