import React from "react"

import { gql, useQuery } from "hooks/urql"

import TagSelect from "components/Forms/TagSelect"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TutorForeignLanguages = ({ tutorId }) => {
  const [result] = useQuery({
    query: foreignLanguagesQuery,
    variables: { tutorId },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <TagSelect
            api="/api/admins/foreign_languages/autocomplete_name"
            tagList={data.tutor.foreignLanguages.map(language => ({
              value: language.id,
              label: language.name,
            }))}
            modelKey="foreignLanguageId"
            recordId={tutorId}
            createMutation={createForeignLanguageMutation}
            addMutation={addForeignLanguageMutation}
            removeMutation={removeForeignLanguageMutation}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const foreignLanguagesQuery = gql`
  query ($tutorId: ID!) {
    tutor(id: $tutorId) {
      id
      foreignLanguages {
        id
        name
      }
    }
  }
`

const createForeignLanguageMutation = gql`
  mutation ($name: String!, $userId: ID) {
    createForeignLanguage(name: $name, userId: $userId) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const addForeignLanguageMutation = gql`
  mutation ($foreignLanguageId: ID!, $userId: ID!) {
    foreignLanguageAddUser(
      foreignLanguageId: $foreignLanguageId
      userId: $userId
    ) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const removeForeignLanguageMutation = gql`
  mutation ($foreignLanguageId: ID!, $userId: ID!) {
    foreignLanguageRemoveUser(
      foreignLanguageId: $foreignLanguageId
      userId: $userId
    ) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

export default TutorForeignLanguages
