import React, { useState } from "react"

import dayjs from "dayjs"
import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import {
  ChartBarIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid"

import { Color, ValueFormat } from "~tailwindui/types/enums"

import ChipDetails from "~tailwindui/ChipDetails"
import AlertMessageBox from "~tailwindui/Form/AlertMessageBox"
import Header from "~tailwindui/Form/Header"
import Table from "~tailwindui/Table/Table"
import { chipIconColorClassNames } from "~tailwindui/helpers/classNameHelpers"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import LocalTime from "components/LocalTime"
import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"
import ExportDropDown from "components/OrganizationAdmin/components/ExportDropDown"
import GroupedBarChart from "components/OrganizationAdmin/components/GroupedBarChart"

interface MatchParams {
  id: string
}

const SchoolDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const [studyGroupsPage, setStudyGroupsPage] = useState(1)
  const [studentsPage, setStudentsPage] = useState(1)
  const [schoolsResult] = useQuery({
    query: schoolsQuery,
    variables: { id, startingAt, endingAt },
  })

  const [studentsResult] = useQuery({
    query: studentsQuery,
    variables: { id, studyGroupsPage, studentsPage, startingAt, endingAt },
  })

  return (
    <>
      <WithLoadingIndicator result={schoolsResult}>
        {({ data: { school } }) => <Header title={school?.name} />}
      </WithLoadingIndicator>
      <DataDateRange
        startDate={startingAt}
        setStartDate={setStartingAt}
        endDate={endingAt}
        setEndDate={setEndingAt}
      />
      <WithLoadingIndicator result={schoolsResult}>
        {({ data: { viewer, school } }) =>
          school === null ? (
            <AlertMessageBox
              messages={[{ message: "You do not have access to this school" }]}
            />
          ) : (
            <>
              <ChipDetails
                details={[
                  {
                    label: "Attendance",
                    Icon: ChartBarIcon,
                    iconClassNames: chipIconColorClassNames(Color.Green),
                    stats: [
                      {
                        label: "Groups",
                        value: school.groupAttendance,
                        valueFormat: ValueFormat.Percent,
                      },
                      {
                        label: "Students",
                        value: school.studentAttendance,
                        valueFormat: ValueFormat.Percent,
                      },
                    ],
                  },
                  {
                    label: "No Show Sessions",
                    Icon: UserCircleIcon,
                    iconClassNames: chipIconColorClassNames(Color.Red),
                    stats: [
                      {
                        label: "Group",
                        value: school.noShowStudyGroupsCount,
                        valueFormat: ValueFormat.Number,
                      },
                      {
                        label: "Student",
                        value: school.noShowStudentsCount,
                        valueFormat: ValueFormat.Number,
                      },
                    ],
                  },
                  {
                    label: "Active",
                    Icon: UserGroupIcon,
                    iconClassNames: chipIconColorClassNames(Color.Blue),
                    stats: [
                      {
                        label: "Groups",
                        value: school.activeStudyGroupsCount,
                        valueFormat: ValueFormat.Number,
                      },
                      {
                        label: "Students",
                        value: school.activeStudentsCount,
                        valueFormat: ValueFormat.Number,
                      },
                    ],
                  },
                ]}
              />

              <ExportDropDown
                startingAt={startingAt}
                endingAt={endingAt}
                timezone={viewer.emailTimeZone}
                schools={[school]}
              />

              <hr />

              <div className="mt-8">
                <GroupedBarChart
                  query={subjectTypeQuery}
                  variables={{
                    startingAt,
                    endingAt,
                    schoolIds: [school.id],
                  }}
                  dataSetKey="sessionsDataSet"
                  groupByField="subjectBucketsSubjectType"
                  title="Sessions per Subject Type"
                />
              </div>
            </>
          )
        }
      </WithLoadingIndicator>

      <WithLoadingIndicator result={studentsResult}>
        {({ data: { school } }) => (
          <>
            <Table
              title="Active Study Groups"
              data={school.studyGroupsByNoShow}
              changePage={setStudyGroupsPage}
              headers={["Name", "Subject", "Students", "No Shows"]}
              valueMapper={({ studyGroup, noShows }) => [
                studyGroup.name,
                <ul key="subjectsList" className="list-none">
                  {studyGroup.subjects.map(subject => (
                    <li key={subject.id}>{subject.name}</li>
                  ))}
                </ul>,
                <ul key="studentsList" className="list-none">
                  {studyGroup.students.map(student => (
                    <li key={student.id}>{student.fullName}</li>
                  ))}
                </ul>,
                noShows,
              ]}
              rowCallback={({ studyGroup }) =>
                (window.location.href = `/organization_admin/study_groups/${studyGroup.id}`)
              }
            />

            <Table
              title="Active Students"
              data={school.students}
              changePage={setStudentsPage}
              headers={[
                "First Name",
                "Last Name",
                "Grade",
                "No Shows",
                "Next Session",
              ]}
              valueMapper={student => [
                student.firstName,
                student.lastName,
                student.grade?.name,
                student.rangedNoShowCount,
                <LocalTime
                  key="startsAt"
                  timestamp={student.nextSession?.startsAt}
                />,
              ]}
              rowCallback={student =>
                (window.location.href = `/organization_admin/students/${student.id}`)
              }
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const schoolsQuery = gql`
  query schoolDetails($id: ID!, $startingAt: DateTime!, $endingAt: DateTime!) {
    viewer {
      emailTimeZone
    }
    school(id: $id) {
      id
      name
      studentAttendance: attendance(
        startingAt: $startingAt
        endingAt: $endingAt
        groupRate: false
      )
      groupAttendance: attendance(
        startingAt: $startingAt
        endingAt: $endingAt
        groupRate: true
      )
      activeStudentsCount: studentsCount(
        startDate: $startingAt
        endDate: $endingAt
        active: true
      )
      activeStudyGroupsCount: studyGroupsCount(
        startDate: $startingAt
        endDate: $endingAt
        active: true
      )
      noShowStudentsCount: noShows(startDate: $startingAt, endDate: $endingAt)
      noShowStudyGroupsCount: noShows(
        startDate: $startingAt
        endDate: $endingAt
        group: true
      )
      startingGrade {
        id
        name
      }
      endingGrade {
        id
        name
      }
    }
  }
`

const subjectTypeQuery = gql`
  query MonthlySessionsGroupedByMonthAndSubjectType(
    $startingAt: DateTime!
    $endingAt: DateTime!
    $schoolIds: [ID!]
  ) {
    viewer {
      organization {
        sessionsDataSet(
          filters: {
            startingAt: $startingAt
            endingAt: $endingAt
            schoolIds: $schoolIds
          }
          groupByOptions: {
            groups: [subjectBucketsSubjectType]
            timePeriod: { periodLength: month, dateField: startsAt }
          }
        ) {
          total
          groupedData {
            value
            groupingStartDate
            subjectBucketsSubjectType
          }
        }
      }
    }
  }
`

const studentsQuery = gql`
  query students(
    $id: ID!
    $studyGroupsPage: Int!
    $studentsPage: Int!
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    school(id: $id) {
      studyGroupsByNoShow(
        page: $studyGroupsPage
        startDate: $startingAt
        endDate: $endingAt
      ) {
        currentPage
        totalPages
        data {
          studyGroup {
            id
            name
            subjects {
              id
              name
            }
            students {
              id
              fullName
            }
          }
          noShows
        }
      }
      students(
        page: $studentsPage
        startDate: $startingAt
        endDate: $endingAt
      ) {
        currentPage
        totalPages
        data {
          id
          firstName
          lastName
          grade {
            id
            name
          }
          rangedNoShowCount(startDate: $startingAt, endDate: $endingAt)
          nextSession {
            id
            startsAt
          }
        }
      }
    }
  }
`

export default SchoolDetails
