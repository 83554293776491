import React from "react"

import PropTypes from "prop-types"

import { css } from "@emotion/core"

const RemoveButton = props => {
  const { onClick, buttonCss, disabled } = props
  return (
    <button
      type="button"
      onClick={onClick}
      className={`btn btn-sm btn-outline-danger red rounded-circle text-danger
        nc-icon nc-simple-remove m-0 mr-3 p-1 ${props.className}`}
      css={css`
        ${buttonCss}
      `}
      disabled={disabled}
    />
  )
}

RemoveButton.propTypes = {
  onClick: PropTypes.func,
}
export default RemoveButton
