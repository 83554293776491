import { compress } from "micro-graphql-react"

export default compress`
  query($id: ID!) {
    tailoredAssignment(id: $id) {
      id
      tailoredSections {
        id
        name

        tailoredQuestionGroups {
          id
          diagrams {
            filename
            url
          }
          tailoredQuestions {
            id
            type
            text
            image {
              fileName
              url
            }
            tailoredAnswers {
              id
              type
              text
              correct
              image {
                fileName
                url
              }
            }
          }
        }
        tailoredQuestions {
          id
          type
          text
          image {
            fileName
            url
          }
          diagram {
            fileName
            url
          }
          tailoredAnswers {
            id
            type
            text
            correct
            image {
              fileName
              url
            }
          }
        }
      }
    }
  }
`
