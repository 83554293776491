import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const StudentStudyGroups = ({ id }) => {
  const loadingState = useQuery(buildQuery(studyGroupsQuery, { id }))

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h4>Study Groups</h4>}
    >
      {({ data }) => (
        <ul className="list-unstyled">
          {ordered(data.student.studyGroups, "name").map(studyGroup => (
            <li key={studyGroup.id} className="mb-3">
              <div>
                <a href={studyGroup.showPath}>{studyGroup.name}</a>
                {studyGroup.courseSection && (
                  <>
                    <div>
                      <LocalTime
                        timestamp={studyGroup.courseSection.startsOn}
                        omitTime
                      />{" "}
                      &mdash;{" "}
                      <LocalTime
                        timestamp={studyGroup.courseSection.endsOn}
                        omitTime
                      />
                    </div>

                    <div>
                      <ul className="list-unstyled">
                        {studyGroup.courseSection.availabilities.map(
                          availability => (
                            <li key={availability.id}>
                              {availability.weekday}{" "}
                              <LocalTime
                                timestamp={availability.startsAt}
                                omitDate
                              />{" "}
                              &ndash;{" "}
                              <LocalTime
                                timestamp={availability.endsAt}
                                omitDate
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                )}

                {studyGroup.availabilities.length === 0 ? null : (
                  <ul className="list-unstyled">
                    {ordered(studyGroup.availabilities, "weekdayNumber").map(
                      availability => (
                        <li key={availability.id}>
                          {availability.weekday}{" "}
                          <LocalTime
                            timestamp={availability.startsAt}
                            format="h:mm A"
                          />{" "}
                          &ndash;{" "}
                          <LocalTime
                            timestamp={availability.endsAt}
                            format="h:mm A z"
                          />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </WithLoadingIndicator>
  )
}

const studyGroupsQuery = compress`
  query($id: ID!) {
    student(id: $id) {
      studyGroups {
        id
        name
        showPath
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
        courseSection {
          id
          startsOn
          endsOn
          availabilities {
            id
            weekday
            weekdayNumber
            startsAt
            endsAt
          }
        }
      }
    }
  }
`

export default StudentStudyGroups
