import React from "react"

import { useField } from "formik"

import ordered from "src/ordered"

import { SelectField } from "components/Forms/Formik/hookComponents"

const SchoolField = ({ schools }) => {
  const [gradeField] = useField("gradeId")
  let permittedSchools = schools.filter(school =>
    school.permittedGrades.map(grade => grade.id).includes(gradeField.value)
  )

  return (
    <SelectField
      name="schoolId"
      label="School"
      options={ordered(permittedSchools, "name")}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      valueAttribute="id"
    />
  )
}

export default SchoolField
