import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"

import { useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

const StatementSettingsForm = ({ organization, closeModal }) => {
  const formState = {
    statementDefaultTo: organization.statementDefaultTo,
    statementDefaultMessage: organization.statementDefaultMessage,
    statementNoShowLineItem: organization.statementNoShowLineItem,
    statementStudentsColumn: organization.statementStudentsColumn,
  }

  const [, update] = useMutation(statementConfigMutation)

  const handleSubmit = (values, actions) => {
    update({ organizationId: organization.id, input: values })
      .then(
        result => {
          const { failures } = result.data.updateOrganizationStatementSettings
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="modal-body">
          <div>
            <label className="text-lg font-medium">Statement Generation</label>
            <CheckboxField
              name="statementNoShowLineItem"
              label="Include No Show Line Item"
            />
            <CheckboxField
              name="statementStudentsColumn"
              label="Include Students Column"
            />
          </div>

          <hr />

          <div>
            <label className="text-lg font-medium">Send Email Defaults</label>
            <Field
              name="statementDefaultTo"
              label="Send statements to these email addresses (comma separated)"
            />
            <TextAreaField
              name="statementDefaultMessage"
              label="Body of email message"
              rows={5}
              className="!max-h-full"
            />
          </div>
        </div>
        <div className="modal-footer flex-col">
          <FormFailures />
          <div className="w-100 flex justify-between">
            <SubmitButton />
            <button
              type="button"
              className="btn btn-danger"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

const statementConfigMutation = gql`
  mutation (
    $organizationId: ID!
    $input: OrganizationStatementSettingsInputObject!
  ) {
    updateOrganizationStatementSettings(
      organizationId: $organizationId
      input: $input
    ) {
      failures {
        message
      }
    }
  }
`

export default StatementSettingsForm
