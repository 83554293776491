import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import { Field, handleFailure } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody, { Footer } from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  text: Yup.string().required("Question text is required"),
})

type NewQuestionProps = Record<string, never>

const NewQuestion: React.FC<NewQuestionProps> = () => {
  const formState = { text: "" }
  const [, createQuestion]: any[] = useMutation(createQuestionMutation)

  const handleSubmit = (values, actions, closeModal) => {
    createQuestion(values)
      .then(
        result => {
          const { failures } = result.data.createSurveyQuestion
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Question created successfully")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      buttonText="New Question"
      buttonClassName="btn btn-success"
      modalTitle="New Survey Question"
      modalClassName="bootstrap-modal"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <Field name="text" label="Question Text" autoFocus />
              <p className="alert alert-info">
                Students will be able to select between "Don't Agree", "Kinda
                Agree", and "Completely Agree" options
              </p>
            </div>

            <Footer cancelAction={closeModal} submitText="Create Question" />
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createQuestionMutation = gql`
  mutation CreateSurveyQuestion($text: String!) {
    createSurveyQuestion(text: $text) {
      question {
        id
        text
      }
      failures {
        message
      }
    }
  }
`

export default NewQuestion
