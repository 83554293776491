import React from "react"

import { gql } from "@urql/core"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const NewTutor = ({ canAdminExpenses, canAdminBudgets }) => {
  const [result] = useQuery({ query: tiersQuery })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Form
          tiers={data.tiers}
          canAdminExpenses={canAdminExpenses}
          canAdminBudgets={canAdminBudgets}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const tiersQuery = gql`
  query {
    tiers {
      id
      gradeRange
      order
    }
  }
`

export default NewTutor
