import React from "react"

import addStudentMutation from "./addStudentMutation"
import MatchedUsers from "./index"
import loadMatchedStudentsQuery from "./loadMatchedStudentsQuery"
import removeStudentMutation from "./removeStudentMutation"

const MatchedStudents = props => {
  const { tutorId } = props
  return (
    <MatchedUsers
      sourceUserId={tutorId}
      sourceKey="tutor"
      matchedKey="student"
      loadUsersQuery={loadMatchedStudentsQuery}
      matchUserMutation={addStudentMutation}
      unmatchUserMutation={removeStudentMutation}
      autocompletePath="/api/admins/students/autocomplete_full_name"
      searchAutocompletePath={`/api/admins/tutors/${tutorId}/autocomplete_recommended_students`}
    />
  )
}

export default MatchedStudents
