import React, { useState } from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const SessionRequestedInfoModal = props => {
  const [dismissed, setDismissed] = useState(false)

  if (dismissed) return ""

  return (
    <ModalWithProvidedBody
      isOpen={true}
      hideCloseButton={true}
      closeModal={() => setDismissed(true)}
      modalClassName="bootstrap-modal"
      modalTitle="Session Requested"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <div className="modal-body">
            <p className="lead">
              Session request sent to tutor for approval. You will receive an
              email once they have confirmed your session.
            </p>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-between w-100">
              <button className="btn solid blue" onClick={closeModal}>
                Okay
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

export default SessionRequestedInfoModal
