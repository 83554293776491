import React, { useRef, useState } from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { adminClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import EditableList from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateObject } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import { SessionDateField } from "components/Forms/Formik/hookComponents"
import RemoveButton from "components/RemoveButton"

const ValidationSchema = Yup.object().shape({
  studyGroupId: Yup.string().required("Please select a study group"),
  subjectId: Yup.string().required("Please select a subject"),
  tutorId: Yup.string().required("Please select a tutor"),
  students: Yup.array().min(1, "At least 1 student is required"),
  sessionDate: Yup.number().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

const ScheduleSession = ({ studyGroup, tutor }) => {
  const [errorMessages, setErrorMessages] = useState([])
  const studentSelectRef = useRef()
  const formState = {
    studyGroupId: studyGroup?.id || "",
    subjectId: "",
    tutorId: tutor?.id || "",
    students: [],
    sessionDate: new DateObject().setHour(12).setMinute(0).setSecond(0),
    durationMinutes: 60,
  }
  const { runMutation } = useMutation(buildMutation(scheduleSessionMutation))

  const addGroupStudents = (studyGroupId, setFieldValue) => {
    client.runQuery(groupStudentsQuery, { id: studyGroupId }).then(response => {
      setFieldValue("students", response.data.studyGroup.students)
    })
  }

  const handleSubmit = (values, actions) => {
    const { students, sessionDate, ...rest } = values
    runMutation({
      studentIds: values.students.map(s => s.id),
      sessionDate: sessionDate.toDate(),
      ...rest,
    }).then(response => {
      const payload = response.scheduleAirtutorsSession
      if (payload.airtutorsSession) {
        window.location = payload.airtutorsSession.showPath
      } else {
        actions.setSubmitting(false)
        actions.setStatus(
          "Failed to schedule session. Please correct validation errors."
        )
        setErrorMessages(payload.errorMessages)
      }
    })
  }

  return (
    <div className="form-wrapper">
      {errorMessages.map((msg, index) => (
        <div key={index} className="alert alert-danger">
          {msg}
        </div>
      ))}
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <h4 className="m-0 mb-3">Study Group</h4>
                  <AutocompleteSelect
                    api="/api/admins/study_groups/autocomplete_name"
                    onChange={selected =>
                      setFieldValue(
                        "studyGroupId",
                        selected ? selected.value : ""
                      )
                    }
                    selected={
                      studyGroup?.id && {
                        label: studyGroup.name,
                        value: studyGroup.id,
                      }
                    }
                  />
                  <ErrorMessage name="studyGroupId" />
                </div>

                <div className="mt-3">
                  <h4 className="m-0 mb-3">Subject</h4>
                  <AutocompleteSelect
                    api="/api/admins/subjects/autocomplete_name"
                    searchOptions={`study_group_id=${
                      studyGroup?.id || values.studyGroupId
                    }`}
                    onChange={selected =>
                      setFieldValue("subjectId", selected?.value || "")
                    }
                  />
                  <ErrorMessage name="subjectId" />
                </div>

                <div className="mt-3">
                  <h4 className="m-0 mb-3">Tutor</h4>
                  <AutocompleteSelect
                    api="/api/admins/tutors/autocomplete_full_name"
                    searchOptions="published=true"
                    onChange={selected =>
                      setFieldValue("tutorId", selected?.value)
                    }
                    selected={
                      tutor?.id && {
                        label: tutor.fullName,
                        value: tutor.id,
                      }
                    }
                  />
                  <ErrorMessage name="tutorId" />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <h4 className="m-0">Students</h4>
                  {values.studyGroupId && (
                    <button
                      className="btn btn-info"
                      type="button"
                      onClick={() =>
                        addGroupStudents(values.studyGroupId, setFieldValue)
                      }
                    >
                      Add Group Students
                    </button>
                  )}
                </div>
                <FieldArray name="students">
                  {arrayHelpers => (
                    <React.Fragment>
                      <AutocompleteSelect
                        ref={studentSelectRef}
                        api="/api/admins/students/autocomplete_full_name"
                        searchOptions={`organization_id=${studyGroup?.organizationId}`}
                        onChange={selectedOption => {
                          if (!selectedOption?.value) return
                          const selectedId = String(selectedOption.value)
                          arrayHelpers.push({
                            id: selectedId,
                            fullName: selectedOption.label,
                          })
                          setTimeout(
                            () => studentSelectRef.current.clearValue(),
                            0
                          )
                        }}
                        placeholder="Add Student"
                      />

                      <div className="mt-3">
                        <EditableList>
                          {ordered(values.students, "fullName").map(
                            ({ id, fullName }) => (
                              <li key={id}>
                                <RemoveButton
                                  onClick={() =>
                                    arrayHelpers.remove(
                                      values.students.findIndex(
                                        s => s.id === id
                                      )
                                    )
                                  }
                                />
                                <span>{fullName}</span>
                              </li>
                            )
                          )}
                        </EditableList>
                      </div>
                    </React.Fragment>
                  )}
                </FieldArray>
                <ErrorMessage name="students" />
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4">
                <SessionDateField
                  value={values.sessionDate}
                  name="sessionDate"
                  label={<h4 className="my-3">Session Date</h4>}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4">
                <h4 className="my-3">Duration in minutes</h4>
                <Field
                  name="durationMinutes"
                  className="form-control"
                  type="number"
                  min="15"
                  step="15"
                />
                <ErrorMessage name="durationMinutes" />
              </div>
            </div>

            {status && (
              <div
                className="alert alert-danger"
                css={css`
                  margin-top: 15px;
                `}
              >
                {status}
              </div>
            )}

            <SubmitButton isSubmitting={isSubmitting} text="Schedule" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const groupStudentsQuery = compress`
  query($id: ID!) {
    studyGroup(id: $id) {
      students {
        id
        fullName
      }
    }
  }
`

const scheduleSessionMutation = compress`
  mutation(
    $studyGroupId: ID!
    $tutorId: ID!
    $subjectId: ID!
    $studentIds: [ID!]!
    $sessionDate: DateTime!
    $durationMinutes: Int!
  ) {
    scheduleAirtutorsSession(
      studyGroupId: $studyGroupId
      tutorId: $tutorId
      subjectId: $subjectId
      studentIds: $studentIds
      sessionDate: $sessionDate
      durationMinutes: $durationMinutes
    ) {
      airtutorsSession {
        showPath
      }
      errorMessages
    }
  }
`

export default ScheduleSession
