import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { studentClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import EditableList from "components/EditableList"
import RemoveButton from "components/RemoveButton"

type Teacher = {
  id: number
  fullName: string
  lastName: string
}

type Subject = {
  id: number
  name: string
}

type TeacherSubject = {
  id: number
  teacher: Teacher
  subject: Subject
}

type CurrentTeacherSubjectsProps = {
  teacherSubjects: [TeacherSubject]
}

const CurrentTeacherSubjects: React.FC<CurrentTeacherSubjectsProps> = ({
  teacherSubjects,
}) => {
  const [errors, setErrors] = useState("")
  const { runMutation } = useMutation(
    buildMutation(removeTeacherSubjectMutation, { client })
  )

  const removeTeacherSubject = teacherSubjectId => {
    runMutation({ teacherSubjectId })
      .then(
        response => {
          const { errorMessages } = response.teacherSubjectRemove
          if (errorMessages) {
            setErrors(errorMessages)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <>
      {errors && <div className="alert">{errors}</div>}
      <EditableList>
        {ordered(
          ordered(teacherSubjects, "teacher", "lastName"),
          "subject",
          "name"
        ).map(({ id, teacher, subject }) => (
          <li key={id}>
            <RemoveButton onClick={() => removeTeacherSubject(id)} />
            <span>
              {teacher.fullName}: {subject.name}
            </span>
          </li>
        ))}
      </EditableList>
    </>
  )
}

const removeTeacherSubjectMutation = compress`
  mutation($teacherSubjectId: ID!) {
    teacherSubjectRemove(teacherSubjectId: $teacherSubjectId) {
      errorMessages
      removedTeacherSubjectId
    }
  }
`

export default CurrentTeacherSubjects
