import React, { useState } from "react"

import NonOrganizationStudents from "./NonOrganizationStudents"
import Organizations from "./Organizations"
import StudentInfoModal, { ModalContext } from "./StudentInfoModal"

const UncontactedStudents = () => {
  const [student, setStudent] = useState()

  return (
    <ModalContext.Provider value={[student, setStudent]}>
      <StudentInfoModal />
      <NonOrganizationStudents />
      <Organizations />
    </ModalContext.Provider>
  )
}

export default UncontactedStudents
export { ModalContext }
