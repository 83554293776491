import React from "react"

import { Form, Formik } from "formik"
import moment from "moment-timezone"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { gql, useMutation } from "hooks/urql"

import { DateObject, DateRangePicker } from "components/Forms/DatePicker"
import { ErrorMessage } from "components/Forms/Formik"
import { SubmitButton } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  startsAt: Yup.number().required(),
  endsAt: Yup.number().required(),
})

const GradeRunRates = ({ id: organizationId }) => {
  const formState = {
    organizationId,
    startsAt: new DateObject(),
    endsAt: new DateObject(),
  }
  const [, exportGradeRunRates] = useMutation(gradeRunRatesMutation)

  const handleSubmit = (values, actions, closeModal) => {
    const { startsAt, endsAt, ...rest } = values
    const startMoment = moment(startsAt.toDate()).startOf("day")
    const endMoment = moment(endsAt?.toDate() || startMoment).endOf("day")
    exportGradeRunRates({
      startsAt: startMoment.format(),
      endsAt: endMoment.format(),
      ...rest,
    })
      .then(
        response => {
          if (response.error || response.errors) {
            actions.setStatus(
              response.error?.message || response.errors?.message
            )
            actions.setSubmitting(false)
            return
          }
          const { errorMessages } = response.data.organizationGradeRunRates
          if (errorMessages) {
            actions.setStatus(errorMessages)
          }
          closeModal()
          NotificationManager.info(
            "Your summary is being built and will be downloaded momentarily"
          )
        },
        () => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Grade Run Rates"
      buttonClassName="btn btn-info"
      buttonText="Grade Run Rates"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ values, status, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Date Range
                    <div
                      css={css`
                        min-height: 50px;
                      `}
                    >
                      <DateRangePicker
                        fixed
                        value={[values.startsAt, values.endsAt]}
                        onChange={([startDate, endDate]) => {
                          setFieldValue("startsAt", startDate)
                          setFieldValue("endsAt", endDate)
                        }}
                      />
                    </div>
                    <ErrorMessage name="startsAt" />
                    <ErrorMessage name="endsAt" />
                  </label>
                </div>
              </div>

              <div className="modal-footer flex-col">
                {status && <div className="alert alert-danger">{status}</div>}
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <SubmitButton isSubmitting={isSubmitting} text="Generate" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const gradeRunRatesMutation = gql`
  mutation ($organizationId: ID!, $startsAt: DateTime!, $endsAt: DateTime!) {
    organizationGradeRunRates(
      organizationId: $organizationId
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      errorMessages
    }
  }
`

export default GradeRunRates
