import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $sourceUserId: ID!,
    $matchUserId: ID!,
  ) {
    matchUser(
      tutorId: $sourceUserId,
      studentId: $matchUserId,
    ) {
      student {
        id
        showPath
        fullName
        lastName
        tutorToStudentPhoneNumber(id: $sourceUserId)
        studentToTutorPhoneNumber(id: $sourceUserId)
      }
    }
  }
`
