import React from "react"

import Form from "./Form"

const NewTeacher = ({ organizationId, organizationName }) => {
  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    organizationId: organizationId || "",
    hasRealtimeboardLicense: false,
    teacherDigestsEnabled: true,
    avatar: {},
  }

  let organization = null
  if (organizationId) {
    organization = {
      id: organizationId,
      name: organizationName,
    }
  }

  return <Form formState={formState} organization={organization} />
}

export default NewTeacher
