import React, { useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import Header from "~tailwindui/Form/Header"
import Table from "~tailwindui/Table/Table"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import LocalTime from "components/LocalTime"
import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"

const StudentsPage = () => {
  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: studentsQuery,
    variables: { page, startingAt, endingAt },
  })

  return (
    <>
      <Header title="Students" />
      <DataDateRange
        startDate={startingAt}
        setStartDate={setStartingAt}
        endDate={endingAt}
        setEndDate={setEndingAt}
      />
      <WithLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <Table
              data={data.viewer.organization.students}
              changePage={setPage}
              headers={[
                "First Name",
                "Last Name",
                "School",
                "Grade",
                "No Shows",
                "Next Session",
              ]}
              valueMapper={student => [
                student.firstName,
                student.lastName,
                student.school?.name,
                student.grade?.name,
                student.rangedNoShowCount,
                <LocalTime
                  key="startsAt"
                  timestamp={student.nextSession?.startsAt}
                />,
              ]}
              rowCallback={student =>
                (window.location.href = `/organization_admin/students/${student.id}`)
              }
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const studentsQuery = gql`
  query students(
    $page: Int!
    $attending: Boolean
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      organization {
        students: paginatedStudents(page: $page, attending: $attending) {
          currentPage
          totalPages
          data {
            id
            firstName
            lastName
            school {
              id
              name
            }
            grade {
              id
              name
            }
            rangedNoShowCount(startDate: $startingAt, endDate: $endingAt)
            nextSession {
              id
              startsAt
            }
          }
        }
      }
    }
  }
`

export default StudentsPage
