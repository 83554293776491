import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ConfirmSessionButton = ({
  airtutorsSessionId,
  tutorId,
  tutorName,
  buttonText = "Confirm Session",
}) => {
  const [errors, setErrors] = useState([])
  const { runMutation, running } = useMutation(
    buildMutation(confirmSessionMutation)
  )

  const confirmSession = () => {
    runMutation({ airtutorsSessionId, tutorId })
      .then(
        response => {
          const { failures } = response.airtutorsSessionConfirm
          if (failures) {
            setErrors(failures)
          } else {
            window.location.reload()
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
  }

  return (
    <ModalWithProvidedBody
      modalTitle={buttonText}
      buttonText={buttonText}
      buttonClassName="btn btn-success"
      afterClose={() => setErrors([])}
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              Are you sure? This will confirm the session on behalf of{" "}
              {tutorName}.
            </p>
          </div>
          <div className="modal-footer flex-column">
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger mb-0 mt-3">
                <div>
                  <p>{error.message}</p>
                  {error.conflictingSessions && (
                    <ul className="list-unstyled">
                      {error.conflictingSessions.map(session => (
                        <li key={session.id}>
                          <a
                            href={session.showPath}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="text-white"
                          >
                            <LocalTime timestamp={session.startsAt} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-between w-full">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button
                onClick={confirmSession}
                className="btn btn-info"
                disabled={running}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const confirmSessionMutation = compress`
  mutation($airtutorsSessionId: ID!, $tutorId: ID!) {
    airtutorsSessionConfirm(airtutorsSessionId: $airtutorsSessionId, tutorId: $tutorId) {
      failures {
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
      airtutorsSession {
        id
      }
    }
  }
`

export default ConfirmSessionButton
