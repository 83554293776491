import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { publicClient } from "src/graphql-config"

import {
  CheckboxField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
  TextAreaField,
  TimeZoneField,
} from "components/Forms/Formik/hookComponents"
import OrganizationConsentFields from "components/Student/Intake/OrganizationConsentFields"
import ParentAccountFields from "components/Student/Intake/ParentAccountFields"
import SchoolField from "components/Student/Intake/SchoolField"
import TermsField from "components/Student/Intake/TermsField"

import AvailabilityFields from "./AvailabilityFields"
import TeacherSubjectsFields from "./TeacherSubjectsFields"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Student first name is required"),
  lastName: Yup.string().required("Student last name is required"),
  email: Yup.string()
    .required("Student email is required")
    .test("email", "Please use your school email", function () {
      const domains = this.parent.allowedDomains
      if (domains.length === 0) return true

      const valid = domains.some(domain => this.parent.email?.endsWith(domain))
      if (valid) {
        return true
      } else {
        return this.createError({
          message: `Please use your school email. It should end with ${domains.join(
            " or "
          )}`,
          path: "email",
        })
      }
    }),
  phoneNumber: Yup.string().required("Phone number is required"),
  parentFirstName: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string().required("Parent first name is required"),
  }),
  parentLastName: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string().required("Parent last name is required"),
  }),
  parentEmail: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string().required("Parent email is required"),
  }),
  parentPhoneNumber: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string().required("Parent phone number is required"),
  }),
  gradeId: Yup.string().required("Grade is required"),
  schoolId: Yup.string().required("School is required"),
  teacherSubjects: Yup.array()
    .min(1, "Please select at least 1 teacher and subject")
    .of(
      Yup.object().shape({
        teacher: Yup.object().required("Please select a teacher").nullable(),
        subject: Yup.object().required("Please select a subject"),
      })
    ),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  passwordConfirmation: Yup.string()
    .required("Password confirmation is required")
    .equals([Yup.ref("password")], "Passwords do not match"),
  createParentAccount: Yup.mixed()
    .when("parentConsentRequired", {
      is: true,
      then: Yup.boolean().test(
        "createParentAccount",
        "Parent account is required",
        value => value === true
      ),
    })
    .when("intakeParentRequired", {
      is: true,
      then: Yup.boolean().test(
        "createParentAccount",
        "Parent account is required",
        value => value === true
      ),
    }),
  parentPassword: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  }),
  parentPasswordConfirmation: Yup.mixed().when("createParentAccount", {
    is: true,
    then: Yup.string()
      .required("Password confirmation is required")
      .equals([Yup.ref("parentPassword")], "Parent Passwords do not match"),
  }),
  organizationConsentAccepted: Yup.mixed().when("studentConsentRequired", {
    is: true,
    then: Yup.boolean().test(
      "studentConsentRequired",
      "You must accept the organization consent form",
      value => value === true
    ),
  }),
  organizationConsentSignature: Yup.mixed().when("studentConsentRequired", {
    is: true,
    then: Yup.boolean().test(
      "studentConsentRequired",
      "You must sign the organization consent form",
      value => value?.length > 0
    ),
  }),
  parentOrganizationConsentAccepted: Yup.mixed().when("parentConsentRequired", {
    is: true,
    then: Yup.boolean().test(
      "parentConsentRequired",
      "You must accept the organization consent form",
      value => value === true
    ),
  }),
  parentOrganizationConsentSignature: Yup.mixed().when(
    "parentConsentRequired",
    {
      is: true,
      then: Yup.boolean().test(
        "parentConsentRequired",
        "You must sign the organization consent form",
        value => value?.length > 0
      ),
    }
  ),
  availabilityIds: Yup.array().test(
    "availabilityIds",
    "Please select more availabilities",
    function () {
      const min = this.parent.requiredAvailabilities
      if (this.parent.availabilityIds.length < min) {
        return this.createError({
          message: `Please select at least ${min} availabilities`,
          path: "availabilityIds",
        })
      } else {
        return true
      }
    }
  ),
  termsAccepted: Yup.boolean().test(
    "termsAccepted",
    "You must accept the Air Tutors privacy policy and grant permission for Air Tutors to contact you",
    value => value === true
  ),
})

const StudentIntake = ({ data }) => {
  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
    parentPhoneNumber: "",
    createParentAccount:
      data.organization.parentConsentRequired ||
      data.organization.intakeParentRequired
        ? true
        : false,
    parentPassword: "",
    parentPasswordConfirmation: "",
    gradeId: "",
    schoolId: "",
    emailTimeZone: data.organization.emailTimeZone,
    password: "",
    passwordConfirmation: "",
    teacherSubjects: [],
    availabilityIds: [],
    termsAccepted: false,
    organizationConsentAccepted: false,
    organizationConsentSignature: "",
    parentOrganizationConsentAccepted: false,
    parentOrganizationConsentSignature: "",
    requiredAvailabilities: data.organization.minimumAvailabilityWindows,
    allowedDomains: data.organization.allowedDomains,
    intakeParentRequired: data.organization.intakeParentRequired,
    parentConsentRequired: data.organization.parentConsentRequired,
    studentConsentRequired: data.organization.studentConsentRequired,
    specialAccommodations: "",
  }

  const { runMutation } = useMutation(
    buildMutation(studentIntakeMutation, { client: publicClient })
  )

  const handleSubmit = (values, actions) => {
    const {
      teacherSubjects,
      requiredAvailabilities,
      allowedDomains,
      intakeParentRequired,
      parentConsentRequired,
      studentConsentRequired,
      ...fields
    } = values

    runMutation({
      input: {
        organizationId: data.organization.id,
        teacherSubjectIds: teacherSubjects.map(
          teacherSubject => teacherSubject.id
        ),
        ...fields,
      },
    })
      .then(
        response => {
          if (response.studentIntake.success) {
            window.location = "/"
          } else {
            actions.setStatus(
              <>
                Please correct the below errors:
                <ul>
                  {response.studentIntake.errors.map(error => (
                    <li key={error}>
                      <span dangerouslySetInnerHTML={{ __html: error }} />
                    </li>
                  ))}
                </ul>
              </>
            )
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <div
      css={css`
        margin-left: 30px;
        margin-right: 30px;

        .form-group {
          margin: 30px 0;
        }

        .form-label {
          display: flex;
          align-items: center;
          > span {
            font-weight: 500;
          }

          > span,
          > input,
          > textarea,
          > div {
            flex-basis: 33%;
          }
        }
        .form-error {
          display: flex;
          align-items: center;
          > div {
            margin: 0 0 0 30px;
          }
        }

        @media screen and (max-width: 768px) {
          .form-label {
            flex-flow: column;

            > span,
            > input,
            > div {
              width: 100%;
            }
          }
          .form-error {
            > div {
              margin: 15px 0;
            }
          }
        }
      `}
    >
      <h1
        css={css`
          text-align: center;
          font-weight: 500;
        `}
      >
        {data.organization.name} Student Registration
      </h1>
      <p
        dangerouslySetInnerHTML={{
          __html: data.organization.intakeBlurb,
        }}
      />
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="firstName" label="Student First Name" autoFocus />
          <Field name="lastName" label="Student Last Name" />
          <Field
            name="email"
            type="email"
            label="Student School Email Address"
          />
          <Field name="phoneNumber" label="Student Cell Phone Number" />
          <Field name="parentFirstName" label="Parent First Name" />
          <Field name="parentLastName" label="Parent Last Name" />
          <Field name="parentEmail" type="email" label="Parent Email" />
          <Field name="parentPhoneNumber" label="Parent Cell Phone Number" />
          <TimeZoneField name="emailTimeZone" label="Time Zone" />
          <SelectField
            name="gradeId"
            label="Grade"
            options={data.grades}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            valueAttribute="id"
          />
          <SchoolField schools={data.organization.schools} />

          <TeacherSubjectsFields organization={data.organization} />
          <AvailabilityFields organization={data.organization} />

          <Field
            name="password"
            type="password"
            autoComplete="new-password"
            label="Student Password"
          />
          <Field
            name="passwordConfirmation"
            type="password"
            autoComplete="new-password"
            label="Student Password Confirmation"
          />
          <TextAreaField
            name="specialAccommodations"
            label="Does your student have any special accommodations or needs you would like us to know about?"
          />

          {data.organization.studentConsentRequired && (
            <OrganizationConsentFields
              label="Student Assent"
              signatureLabel="Student Signature"
              acceptanceName="organizationConsentAccepted"
              signatureName="organizationConsentSignature"
              organization={data.organization}
            />
          )}

          <hr />
          <CheckboxField
            name="createParentAccount"
            label="Create a parent account?"
          />
          <ParentAccountFields organization={data.organization} />

          <TermsField />

          <FormStatus />

          <SubmitButton text="Complete Registration" />
        </Form>
      </Formik>
    </div>
  )
}

const studentIntakeMutation = compress`
  mutation($input: StudentIntakeInputObject!) {
    studentIntake(input: $input) {
      success
      errors
    }
  }
`

export default StudentIntake
