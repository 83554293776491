import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import LocalTime from "components/LocalTime"

import AssignStudents from "./AssignStudents"

const Availability = ({ availability, organizationId }) => {
  const { runMutation } = useMutation(buildMutation(removeMutation))
  const [errors, setErrors] = useState()

  const remove = () => {
    runMutation({ id: availability.id })
      .then(
        response => {
          const { errorMessages } = response.availabilityRemove
          if (errorMessages) {
            setErrors(errorMessages)
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <li>
      <div className="d-flex align-items-center mb-2">
        <button
          onClick={remove}
          className="mr-3 cursor-pointer border-none text-3xl"
        >
          <FontAwesomeIcon icon={faTimesCircle} className="text-red-500" />
        </button>
        <div>
          {availability.weekday}
          <span> @ </span>
          <LocalTime timestamp={availability.startsAt} format="LT" />
          <span> &mdash; </span>
          <LocalTime timestamp={availability.endsAt} format="LT" />
        </div>
        <AssignStudents
          organizationId={organizationId}
          availability={availability}
        />
      </div>
      {errors && <div className="alert alert-danger mt-3">{errors}</div>}
    </li>
  )
}

const removeMutation = compress`
  mutation($id: ID!) {
    availabilityRemove(availabilityId: $id) {
      errorMessages
      availability {
        id
        weekday
        weekdayNumber
        owner {
          ... on AvailabilitySet {
            id
          }
        }
      }
    }
  }
`

export default Availability
