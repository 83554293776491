import React, { useState } from "react"

import defaultAxios from "axios"
import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { ErrorMessage } from "components/Forms/Formik"
import { Loading } from "components/Icons"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import lessonTopicsFragment from "./lessonTopicsFragment"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
  fileUrl: Yup.string().required("Please select a file"),
})

const NewLessonResourceButton = ({ lessonTopicId, signedUrl }) => {
  const { runMutation } = useMutation(
    buildMutation(createLessonResourceMutation)
  )
  const [isUploading, setIsUploading] = useState(false)

  const formState = {
    name: "",
    fileUrl: "",
    filename: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({
      lessonTopicId,
      name: values.name,
      fileUrl: values.fileUrl,
      filename: values.filename,
    })
      .then(
        r => {
          closeModal()
        },
        e => {
          actions.setSubmitting(false)
          actions.setStatus("An error occurred while creating your worksheet")
        }
      )
      .catch(e => {
        actions.setSubmitting(false)
        actions.setStatus("An error occurred while creating your worksheet")
      })
  }

  const handleFileUpload = ({ event, setFieldValue }) => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append("key", signedUrl.key)
    formData.append("success_action_status", signedUrl.successActionStatus)
    formData.append("acl", signedUrl.acl)
    formData.append("policy", signedUrl.policy)
    formData.append("x-amz-credential", signedUrl.credential)
    formData.append("x-amz-algorithm", signedUrl.algorithm)
    formData.append("x-amz-date", signedUrl.date)
    formData.append("x-amz-signature", signedUrl.signature)
    formData.append("x-amz-server-side-encryption", "AES256")
    formData.append("Content-Type", file.type)
    formData.append("file", file)

    setIsUploading(true)

    defaultAxios.post(signedUrl.url, formData).then(response => {
      const xmlDocument = new DOMParser().parseFromString(
        response.data,
        "text/xml"
      )
      const location = xmlDocument.getElementsByTagName("Location")[0].innerHTML
      setFieldValue("fileUrl", decodeURIComponent(location))
      setFieldValue("filename", file.name || file.fileName)
      setIsUploading(false)
    })
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-success"
      buttonText="New Worksheet"
      modalClassName="bootstrap-modal"
      modalTitle="New Worksheet"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ status, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field className="form-control" name="name" autoFocus />
                    <ErrorMessage name="name" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label w-100">
                    File
                    <Field name="file" type="text" className="form-control">
                      {({ field: { onChange, name, value } }) => (
                        <input
                          type="file"
                          className="form-control"
                          name={name}
                          onChange={e =>
                            handleFileUpload({
                              event: e,
                              setFieldValue,
                            })
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="fileUrl" />
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                {status && (
                  <div
                    className="alert alert-danger"
                    css={css`
                      margin-top: 15px;
                    `}
                  >
                    {status}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={isSubmitting || isUploading}
                >
                  <span>{isUploading ? "Uploading..." : "Create"}</span>
                  {(isSubmitting || isUploading) && (
                    <div
                      css={css`
                        display: inline-block;
                        margin-left: 15px;
                      `}
                    >
                      <Loading />
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createLessonResourceMutation = compress`
  ${lessonTopicsFragment}
  mutation(
    $lessonTopicId: ID!
    $name: String!
    $fileUrl: String!
    $filename: String!
  ) {
    createLessonResource(
      name: $name
      lessonTopicId: $lessonTopicId
      fileUrl: $fileUrl
      filename: $filename
    ) {
      lessonTopic {
        ...LessonTopicsFields
      }
    }
  }
`

export default NewLessonResourceButton
