import React, { useRef, useState } from "react"

import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import { tutorClient as client } from "src/urql-client"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"
import LocalTime from "components/LocalTime"

const CourseSection = ({ courseSection }) => {
  const [status, setStatus] = useState()
  const [errorMessages, setErrorMessages] = useState([])
  const [inFlight, setInFlight] = useState(false)
  const submitClicked = useRef(false)

  const register = () => {
    if (submitClicked.current) {
      return
    }
    submitClicked.current = true
    setInFlight(true)
    setErrorMessages([])
    setStatus("starting")

    const { unsubscribe } = pipe(
      client.subscription(registerSubscription, {
        courseSectionId: courseSection.id,
      }),
      subscribe(result => {
        if (result.data) {
          const { status, errorMessages } = result.data.courseSectionRegister
          setStatus(status)
          setErrorMessages(errorMessages)
          if (errorMessages.length) {
            setErrorMessages(errorMessages)
            setInFlight(false)
            submitClicked.current = false
            unsubscribe()
          }
          if (status === "in-progress") {
            setStatus(
              <>
                In Progress <br />
                (Please be patient, this process can take some time)
              </>
            )
          }
          if (status === "success") {
            setErrorMessages([])
            setStatus(
              <>
                Registration Successful <br />
                <a href="/">Click here to go back to your dashboard</a>
              </>
            )
            unsubscribe()
          }
        } else if (result.error) {
          setErrorMessages([result.error?.message])
          setInFlight(false)
          submitClicked.current = false
          unsubscribe()
        }
      })
    )
  }

  return (
    <div key={courseSection.id}>
      <dl>
        <dt>Starts</dt>
        <dd>
          <LocalTime timestamp={courseSection.startsOn} omitTime />
        </dd>

        <dt>Ends</dt>
        <dd>
          <LocalTime timestamp={courseSection.endsOn} omitTime />
        </dd>

        <dt>Weekly Sessions</dt>
        <dd>
          {courseSection.availabilities.map(availability => (
            <div key={availability.id}>
              {availability.weekday}{" "}
              <LocalTime timestamp={availability.startsAt} omitDate /> to{" "}
              <LocalTime timestamp={availability.endsAt} omitDate />
            </div>
          ))}
        </dd>
      </dl>
      {(courseSection.maxSubsEstimate ||
        courseSection.maxSubsEstimate === 0) && (
        <ConsistentTutorWarning
          minTutorConsistencyRate={
            courseSection.organization.minTutorConsistencyRate
          }
          maxSubsEstimate={courseSection.maxSubsEstimate}
        />
      )}
      <button
        className="btn solid green"
        onClick={register}
        disabled={inFlight}
      >
        Register
      </button>

      {status && (
        <div className="alert my-5 w-1/3 bg-blue-400 p-4">
          Current Status: {status}
        </div>
      )}

      {errorMessages.map((msg, index) => (
        <div
          className="alert alert-danger p-4"
          key={index}
          dangerouslySetInnerHTML={{ __html: msg }}
        />
      ))}

      <hr />
    </div>
  )
}

const registerSubscription = gql`
  subscription ($courseSectionId: ID) {
    courseSectionRegister(courseSectionId: $courseSectionId) {
      status
      errorMessages
    }
  }
`

export default CourseSection
