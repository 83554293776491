import React from "react"

import { motion } from "framer-motion"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import CollapsibleContainer from "components/CollapsibleContainer"
import EditableList, { Item } from "components/EditableList"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import AddHolidays from "./AddHolidays"

const OrganizationHolidays = ({ organizationId }) => {
  const loadingState = useQuery(
    buildQuery(
      holidaysQuery,
      { organizationId },
      {
        onMutation: [
          {
            when: "organizationAddHolidays",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.organizationAddHolidays
              currentResults.organization.holidays = organization.holidays
              softReset(currentResults)
            },
          },
          {
            when: "organizationRemoveHoliday",
            run: ({ softReset, currentResults }, response) => {
              const { organization } = response.organizationRemoveHoliday
              currentResults.organization.holidays = organization.holidays
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <CollapsibleContainer title="Show Holidays">
          <EditableList>
            {data.organization.holidays.sort().map(holiday => (
              <motion.li key={holiday} positionTransition>
                <Item
                  mutation={removeHolidayMutation}
                  mutationProps={{
                    organizationId,
                    holiday: holiday,
                  }}
                >
                  <LocalTime timestamp={holiday} omitTime />
                </Item>
              </motion.li>
            ))}
          </EditableList>
          <AddHolidays organizationId={organizationId} />
        </CollapsibleContainer>
      )}
    </WithLoadingIndicator>
  )
}

const removeHolidayMutation = compress`
  mutation($organizationId: ID!, $holiday: Date!) {
    organizationRemoveHoliday(organizationId: $organizationId, holiday: $holiday) {
      organization {
        id
        holidays
      }
    }
  }
`

const holidaysQuery = compress`
  query($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      holidays
    }
  }
`

export default OrganizationHolidays
