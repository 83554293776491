import React, { useContext } from "react"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type IncomingStudentsModalProps = Record<never, any>

const IncomingStudentsModal: React.FC<IncomingStudentsModalProps> = () => {
  const { incomingStudents, setIncomingStudents } = useContext(CloudroomContext)

  if (!incomingStudents) return null

  return (
    <ModalWithProvidedBody
      isOpen={incomingStudents}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Incoming Students"
      hideCloseButton
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              Another tutor was unable to make their session. One or more
              students have been reassigned to your session and will be joining
              momentarily.
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="btn solid blue"
              onClick={() => {
                closeModal()
                setIncomingStudents(false)
              }}
            >
              Dismiss
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default IncomingStudentsModal
