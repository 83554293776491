import React from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import InlineConfirmation from "components/InlineConfirmation"

const ApproveResume = ({ id }) => {
  const [, runMutation] = useMutation(workflowMutation)

  const handleSubmit = () => {
    return runMutation({ id }).then(result => {
      if (result.error) {
        NotificationManager.error(result.error.message)
      }

      const { failures } = result.data.approveResume
      if (failures) {
        NotificationManager.error(failures.map(f => f.message).join(", "))
      } else {
        NotificationManager.success("Resume approved")
      }
    })
  }

  return (
    <InlineConfirmation
      handleSubmit={handleSubmit}
      buttonText="Approve Resume"
      type="success"
    />
  )
}

const workflowMutation = gql`
  mutation ($id: ID!) {
    approveResume(id: $id) {
      failures {
        message
      }
      tutorApplication {
        id
      }
    }
  }
`

export default ApproveResume
