import React, { ReactNode } from "react"

import classNames from "src/classNames"

import Paginator from "./Paginator"

export type TableProps = {
  data: any
  changePage: (page: number) => void
  title?: string | ReactNode
  subtitle?: string
  rowCallback?: (record) => void
  headers: string[]
  valueMapper: (record) => any[]
}

const Table: React.FC<TableProps> = ({
  data,
  changePage,
  title,
  subtitle,
  rowCallback,
  headers,
  valueMapper,
}) => {
  const records = data.data || data
  const { currentPage, totalPages } = data
  return (
    <div className="mb-16 mt-4 border-b-2 border-b-gray-200/50 pb-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          {title && (
            <h2 className="my-2 text-lg font-semibold leading-6 text-gray-900">
              {title}
            </h2>
          )}
          {subtitle && <p className="mt-2 text-sm text-gray-700">{subtitle}</p>}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>

      <div className="flow-root px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    {headers[0]}
                  </th>
                  {headers.slice(1).map(colName => (
                    <th
                      key={colName}
                      scope="col"
                      className="top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      {colName}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {records.length === 0 ? (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="text-semibold py-8 pl-4 text-lg font-thin text-gray-900"
                    >
                      No records found
                    </td>
                  </tr>
                ) : (
                  records.map((record, recordIdx) => {
                    const rowValues = valueMapper(record)
                    return (
                      <tr
                        key={record.id || JSON.stringify(record)}
                        onClick={() => rowCallback && rowCallback(record)}
                        className={classNames(
                          rowCallback && "cursor-pointer",
                          "hover:bg-gray-200/50"
                        )}
                      >
                        <td
                          className={classNames(
                            recordIdx !== records.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900"
                          )}
                        >
                          {rowValues[0]}
                        </td>
                        {rowValues.slice(1).map((val, colIdx) => (
                          <td
                            key={`${record.id}-col-${colIdx}`}
                            className={classNames(
                              recordIdx !== records.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                            )}
                          >
                            {val}
                          </td>
                        ))}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        {records.length > 0 && currentPage && (
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={changePage}
          />
        )}
      </div>
    </div>
  )
}
export default Table
