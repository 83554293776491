import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import StudyGroup from "./StudyGroup"
import studyGroupFragment from "./studyGroupFragment"

const UnassignedStudyGroups = ({ courseSection }) => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      groupsQuery,
      { courseSectionId: courseSection.id, page: page },
      {
        onMutation: [
          {
            when: "courseSectionRegisterStudent",
            run: ({ softReset, currentResults }, response) => {
              const { courseSection: updatedCourseSection } =
                response.courseSectionRegisterStudent
              if (updatedCourseSection?.id !== courseSection.id) return

              currentResults.courseSection.unassignedStudyGroups =
                updatedCourseSection.unassignedStudyGroups
              softReset(currentResults)
            },
          },
          {
            when: "courseSectionBulkRegisterStudents",
            run: ({ softReset, currentResults }, response) => {
              const { courseSection: updatedCourseSection } =
                response.courseSectionBulkRegisterStudents
              if (updatedCourseSection?.id !== courseSection.id) return

              currentResults.courseSection.unassignedStudyGroups =
                updatedCourseSection.unassignedStudyGroups
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h4 className="mt-0">Unassigned Study Groups</h4>}
    >
      {({ data }) =>
        data.courseSection.unassignedStudyGroups.data.length > 0 ? (
          <>
            {data.courseSection.unassignedStudyGroups.data.map(group => (
              <StudyGroup studyGroup={group} key={group.id} canAddTutor />
            ))}
            <Pagination
              currentPage={data.courseSection.unassignedStudyGroups.currentPage}
              totalPages={data.courseSection.unassignedStudyGroups.totalPages}
              changePage={setPage}
            />
          </>
        ) : (
          <div>
            <span>No unassigned groups</span>
            <hr />
          </div>
        )
      }
    </WithLoadingIndicator>
  )
}

const groupsQuery = compress`
  ${studyGroupFragment}
  query($courseSectionId: ID!, $page: Int) {
    courseSection(id: $courseSectionId) {
      unassignedStudyGroups(page: $page) {
        currentPage
        totalPages
        data {
          ...StudyGroupFields
        }
      }
    }
  }
`

export default UnassignedStudyGroups
