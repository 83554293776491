import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

class AutocompleteTutor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { tutorId: this.props.tutorId }
  }

  onChange = tutorId => {
    this.setState({ tutorId: tutorId ? tutorId.value : "" })
  }

  render() {
    return (
      <div className="form-group">
        <label className="form-control-label">Tutor</label>
        <input
          type="hidden"
          value={this.state.tutorId}
          name={this.props.name}
        />
        <AutocompleteSelect
          onChange={this.onChange}
          api="/api/admins/tutors/autocomplete_full_name"
          searchOptions={this.props.searchOptions}
          selected={{ value: this.props.tutorId, label: this.props.tutorName }}
          clearableSelects={[]}
        />
      </div>
    )
  }
}

AutocompleteTutor.defaultProps = {
  tutorId: "",
  tutorName: "",
}

export default AutocompleteTutor
