import React, { useEffect, useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import moment from "moment-timezone"
import * as Yup from "yup"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateObject, DateRangePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import {
  CheckboxField,
  DateRangeField,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  startsAt: Yup.number().required(),
})

const KitchenSinkReport = ({ id }) => {
  const formState = {
    id: id,
    startsAt: new DateObject(),
    endsAt: new DateObject(),
    lastLoginDates: [],
    schoolId: undefined,
    perSchoolTabs: false,
  }

  const [sheetUrl, setSheetUrl] = useState()

  const { runMutation } = useMutation(buildMutation(reportMutation))

  useEffect(() => {
    EventEmitter.subscribe("kitchenSinkGenerated", data => {
      setSheetUrl(data.url)
    })
  }, [])

  const handleSubmit = (values, actions, closeModal) => {
    const { startsAt, endsAt, lastLoginDates, ...rest } = values
    const startMoment = moment(startsAt.toDate()).startOf("day")
    const endMoment = moment(endsAt?.toDate() || startMoment).endOf("day")

    const variables = {
      startsAt: startMoment.format(),
      endsAt: endMoment.format(),
      ...rest,
    }
    if (lastLoginDates[0]) {
      variables.lastLoginStartsAt = moment(lastLoginDates[0].toDate())
        .startOf("day")
        .format()
    }
    if (lastLoginDates[1]) {
      variables.lastLoginEndsAt = moment(lastLoginDates[1].toDate())
        .endOf("day")
        .format()
    }

    setSheetUrl()
    runMutation(variables)
      .then(
        response => {
          const { errorMessages } = response.createOrganizationKitchenSinkReport
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            actions.setStatus(
              "Leave this modal open to be alerted once a link to your report is ready."
            )
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Kitchen Sink Report"
      buttonClassName="btn btn-info"
      buttonText="Kitchen Sink Report"
      afterClose={() => setSheetUrl()}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
          validationSchema={ValidationSchema}
        >
          {({ values, status, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Date Range
                    <div
                      css={css`
                        min-height: 50px;
                      `}
                    >
                      <DateRangePicker
                        fixed
                        value={[values.startsAt, values.endsAt]}
                        onChange={([startDate, endDate]) => {
                          setFieldValue("startsAt", startDate)
                          setFieldValue("endsAt", endDate)
                        }}
                      />
                    </div>
                    <ErrorMessage name="startsAt" />
                    <ErrorMessage name="endsAt" />
                  </label>
                </div>

                <DateRangeField
                  name="lastLoginDates"
                  label="Last Cloudroom Presence"
                />

                <CheckboxField name="perSchoolTabs" label="Per School Tabs" />

                <div className="form-group">
                  <label className="form-label w-100">
                    School (optional)
                    <AutocompleteSelect
                      api="/api/admins/schools/autocomplete_name"
                      onChange={selected =>
                        setFieldValue("schoolId", selected?.value)
                      }
                      menuPortalTarget={document.body}
                    />
                  </label>
                </div>
              </div>

              <div className="modal-footer d-block">
                {status && (
                  <div className="alert alert-danger m-0">{status}</div>
                )}
                <div>
                  <SubmitButton isSubmitting={isSubmitting} text="Generate" />
                  {sheetUrl && (
                    <a
                      href={sheetUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-success"
                    >
                      Click to view report
                    </a>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const reportMutation = compress`
  mutation(
    $id: ID!
    $schoolId: ID
    $startsAt: DateTime!
    $endsAt: DateTime!
    $lastLoginStartsAt: DateTime
    $lastLoginEndsAt: DateTime
    $perSchoolTabs: Boolean!
  ) {
    createOrganizationKitchenSinkReport(
      organizationId: $id
      schoolId: $schoolId
      startsAt: $startsAt
      endsAt: $endsAt
      lastLoginStartsAt: $lastLoginStartsAt
      lastLoginEndsAt: $lastLoginEndsAt
      perSchoolTabs: $perSchoolTabs
    ) {
      errorMessages
    }
  }
`

export default KitchenSinkReport
