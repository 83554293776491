import React from "react"

import ApprovedExpenses from "./ApprovedExpenses"
import ChangesRequestedExpenses from "./ChangesRequestedExpenses"
import DeniedExpenses from "./DeniedExpenses"
import SubmittedExpenses from "./SubmittedExpenses"

const TutorExpensesList = ({ mayCreateExpense }) => {
  return (
    <>
      <h1>Your Expenses</h1>
      {mayCreateExpense && (
        <div>
          <a className="btn solid green" href="/tutor/expenses/new">
            New Expense
          </a>
        </div>
      )}
      <ChangesRequestedExpenses />
      <SubmittedExpenses />
      <ApprovedExpenses />
      <DeniedExpenses />
    </>
  )
}

export default TutorExpensesList
