import React, { useEffect, useRef, useState } from "react"

import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useQuery } from "urql"

import { Button, CircularProgress, Link } from "@chakra-ui/core"

import Paginator from "~tailwindui/Table/Paginator"

import Wrapper from "components/Pages/Teacher/Table/Wrapper"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import { STUDENT_SESSION_FILTER, STUDENT_SESSION_ORDER } from "../constants"

import StudentSessionTableRow from "./StudentSessionTableRow"
import ViewerContext from "./ViewerContext"

const StudentSessionsTableContainer = componentProps => {
  const [page, setPage] = useState(1)
  const [exportUrl, setExportUrl] = useState("")
  const exportDownloadLink = useRef()
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)

  const urlVariables = {
    filters: JSON.parse(searchParams.get(STUDENT_SESSION_FILTER)) || [],
    order: JSON.parse(searchParams.get(STUDENT_SESSION_ORDER)) || {
      field: "startsAt",
      direction: "descending",
    },
  }

  const variables = {
    page,
    semesterId: componentProps.semesterId || "",
    ...urlVariables,
  }

  const [result] = useQuery({ query: studentSessionsTableQuery, variables })
  const [exportResult, fetchExport] = useQuery({
    query: exportQuery,
    variables,
    pause: true,
  })

  useEffect(() => {
    if (!exportResult.data) return
    if (exportResult.data.studentSessions?.csvExport) {
      const blob = new Blob(
        [atob(exportResult.data.studentSessions.csvExport)],
        {
          type: "text/csv",
        }
      )
      const url = URL.createObjectURL(blob)
      setExportUrl(url)
      // unsure if this timeout is truly necessary, but leaving it in to be safe
      setTimeout(() => URL.revokeObjectURL(url), 1000)
    } else {
      NotificationManager.error(
        "Result too large, please use filters to reduce the result count",
        "Export Error",
        0
      )
    }
  }, [exportResult.data])

  useEffect(() => {
    if (exportUrl === "") return
    exportDownloadLink.current.click()
  }, [exportUrl])

  let dateTitle = "Date"
  if (componentProps.semesterId) {
    dateTitle = (
      <CircularProgress
        isIndeterminate
        thickness={0.1}
        color="brandStatic"
        width="full"
        size="20px"
      />
    )
    if (result.data?.semester) {
      dateTitle = result.data.semester.name
    }
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <ViewerContext.Provider value={data.viewer}>
          <Button
            onClick={() => fetchExport()}
            position="absolute"
            right={["4", "4", "12"]}
            variant="outline"
            variantColor="blue"
            color="blue.700"
            disabled={exportResult.fetching}
          >
            Download Spreadsheet
          </Button>
          <Link
            ref={exportDownloadLink}
            href={exportUrl}
            download="student air tutors session export.csv"
            display="none"
          />
          <Wrapper
            title="Student Air Tutors Sessions"
            rowComponent={StudentSessionTableRow}
            tableProp="studentSessions"
            data={data.studentSessions.data}
            order={urlVariables.order}
            filters={urlVariables.filters}
            filterKey={STUDENT_SESSION_FILTER}
            orderKey={STUDENT_SESSION_ORDER}
            columns={[
              {
                title: "Students",
                field: "student",
                width: "15%",
                isOrderable: false,
                isFilterable: true,
              },
              {
                title: "Tutor",
                field: "tutor",
                width: "15%",
                isOrderable: true,
                isFilterable: true,
              },
              {
                title: "Subject",
                field: "subject",
                width: "13%",
                isOrderable: true,
                isFilterable: true,
              },
              {
                title: "School",
                field: "school",
                width: "15%",
                isOrderable: true,
                isFilterable: true,
              },
              {
                title: dateTitle,
                field: "startsAt",
                width: "12%",
                isOrderable: true,
                isFilterable: true,
                isDateFilter: true,
                semester: data.semester,
              },
              {
                title: "Attendance (hours:minutes)",
                width: "20%",
                isOrderable: false,
                isFilterable: false,
              },
              {
                title: "Resources",
                width: "10%",
                isOrderable: false,
                isFilterable: false,
              },
            ]}
          />
          <Paginator
            currentPage={data.studentSessions.currentPage}
            totalPages={data.studentSessions.totalPages}
            changePage={setPage}
          />
        </ViewerContext.Provider>
      )}
    </UrqlLoadingIndicator>
  )
}

const studentSessionsTableQuery = gql`
  query StudentSessionsTableQuery(
    $page: Int
    $order: OrderInputObject
    $filters: [SessionFilterInputObject!]
    $semesterId: ID!
  ) {
    viewer {
      id
      ssoEmail
    }

    semester(id: $semesterId) {
      id
      name
      startsOn
      endsOn
    }

    studentSessions(
      page: $page
      order: $order
      filters: $filters
      semesterId: $semesterId
    ) {
      currentPage
      totalPages
      data {
        id
        startsAt
        endsAt
        googledocUrl
        googleSpreadsheetUrl
        studyGroup {
          id
          school {
            id
            name
          }
        }
        sessionParticipantSummaries {
          name
          formattedDuration
        }
        realtimeboards {
          id
          url
          ssoEnabled
          displayName
        }
        cloudroomRecordings {
          url
          name
        }
        subject {
          id
          name
        }
        students {
          id
          fullName
          lastName
        }
        tutor {
          id
          fullName
        }
      }
    }
  }
`

const exportQuery = gql`
  query StudentSessionsTableExportQuery(
    $order: OrderInputObject
    $filters: [SessionFilterInputObject!]
    $semesterId: ID!
  ) {
    studentSessions(order: $order, filters: $filters, semesterId: $semesterId) {
      csvExport
    }
  }
`
export default StudentSessionsTableContainer
