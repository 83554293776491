import React, { ReactNode } from "react"

import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikProvider,
  FormikValues,
} from "formik"
import { Asserts } from "yup"

import { LayoutDirection } from "~tailwindui/types/enums"

import { default as TailwindForm } from "~tailwindui/Form"
import FormikForm from "~tailwindui/FormikForm"

import classNames from "src/classNames"
import emptyFunction from "src/emptyFunction"

import { handleFailure } from "components/Forms/Formik/hookComponents"

import PersistedValuesContext from "./PersistedValuesContext"

export type WrapperProps = {
  editable?: boolean
  title: string
  editing?: boolean
  children: ReactNode
  initialValues?: FormikValues
  mutation?: { name: string; run: (variables: any, context?: any) => any }
  validationSchema?: Asserts<FormikValues>
  convertFormValues?: (any) => any
  displayData?: { [key: string]: any }
  onSuccess?: () => void
  onFailure?: () => void
  onCancel?: () => void
  layoutDirection?: LayoutDirection
}

const Wrapper: React.FC<WrapperProps & Partial<FormikProps<FormikValues>>> = ({
  editable = false,
  editing = false,
  children,
  title,
  initialValues,
  mutation,
  convertFormValues = vals => vals,
  onSuccess = emptyFunction,
  onFailure = emptyFunction,
  onCancel = emptyFunction,
  displayData = {},
  layoutDirection = LayoutDirection.Vertical,
  ...props
}) => {
  const myInitialValues = { ...initialValues, editing }
  const handleSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (!mutation) {
      onSuccess()
      return
    }
    const { editing, ...params } = values

    mutation
      .run(convertFormValues(params))
      .then(result => {
        if (result.error) {
          handleFailure(actions, [{ message: result.error.message }])
          onFailure()
          return
        }

        const { failures } = result.data[mutation.name]
        if (failures) {
          handleFailure(actions, failures)
          onFailure()
        } else {
          actions.setSubmitting(false)
          actions.setFieldValue("editing", false)
          onSuccess()
        }
      })
      .catch(e => {
        handleFailure(actions, [e])
        onFailure()
      })
  }

  return (
    <PersistedValuesContext.Provider value={displayData}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={myInitialValues}
        {...props}
      >
        {formikbag => (
          <Form>
            {title && (
              <TailwindForm.Header title={title}>
                <>
                  {editable && !formikbag.values.editing && (
                    <div className="flex items-center justify-start">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => formikbag.setFieldValue("editing", true)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </>
              </TailwindForm.Header>
            )}

            <div className="space-y-12 sm:space-y-16">
              <div
                className={classNames(
                  layoutDirection === "horizontal" &&
                    "grid-cols-1 sm:grid-cols-2",
                  layoutDirection === "vertical" && "grid-rows-1",
                  "mt-5 grid gap-5"
                )}
              >
                <FormikProvider value={formikbag}>
                  {typeof children === "function"
                    ? (
                        children as (
                          bag: FormikProps<FormikValues>
                        ) => React.ReactNode
                      )(formikbag as FormikProps<FormikValues>)
                    : !(React.Children.count(children) === 0)
                    ? React.Children.only(children)
                    : null}
                </FormikProvider>
              </div>
            </div>

            {editable && formikbag.values.editing && (
              <>
                <div className="mt-6">
                  <FormikForm.Failures />
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={() => {
                      formikbag.resetForm()
                      formikbag.setFieldValue("editing", false)
                      onCancel()
                    }}
                    disabled={formikbag.isSubmitting}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={formikbag.isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </PersistedValuesContext.Provider>
  )
}

export default Wrapper
