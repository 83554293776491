import React from "react"

import ReactTable from "react-table"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const AssignedStudyGroupsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/tutors/study_groups/future",
  })

  const columns = [
    {
      id: "study_group",
      Header: "Name",
      accessor: "name",
      Cell: props => (
        <div>
          <div
            css={css`
              color: black;
            `}
          >
            {props.value}
          </div>
          {props.original.course_section && (
            <>
              <div>
                <LocalTime
                  timestamp={props.original.course_section.starts_on}
                  omitTime
                />{" "}
                &mdash;{" "}
                <LocalTime
                  timestamp={props.original.course_section.ends_on}
                  omitTime
                />
              </div>

              <ul className="list-unstyled">
                {props.original.course_section.availabilities.map(
                  availability => (
                    <li key={availability.id}>
                      {availability.weekday}{" "}
                      <LocalTime timestamp={availability.starts_at} omitDate />{" "}
                      &ndash;{" "}
                      <LocalTime timestamp={availability.ends_at} omitDate />
                    </li>
                  )
                )}
              </ul>
            </>
          )}
          <ul className="list-unstyled">
            {props.original.availabilities.map(availability => (
              <li key={availability.id}>
                {availability.weekday}{" "}
                <LocalTime timestamp={availability.starts_at} omitDate />{" "}
                &ndash; <LocalTime timestamp={availability.ends_at} omitDate />
              </li>
            ))}
          </ul>
        </div>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/tutors/study_groups/autocomplete_name"
          />
        )
      },
    },
    {
      id: "organization",
      Header: "Organization",
      accessor: "organization",
      Cell: props => props.value.name,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/tutors/organizations/autocomplete_name"
          />
        )
      },
    },
    {
      id: "students",
      Header: "Students",
      accessor: "students",
      sortable: false,
      Cell: props => (
        <ul>
          {ordered(props.value, "last_name").map(student => (
            <li key={student.id}>
              <a href={student.show_path}>{student.full_name}</a>
            </li>
          ))}
        </ul>
      ),
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/tutors/students/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <a href={props.value.show_path} className="btn small blue">
          View
        </a>
      ),
    },
  ]

  if (!loading && data.length === 0) return null
  return (
    <>
      <h1>Future Study Groups</h1>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        manual
        filterable
      />
    </>
  )
}

export default AssignedStudyGroupsTable
