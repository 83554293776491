import React from "react"

import { AlertLevel } from "~tailwindui/types/enums"

import {
  alertBackgroundClassNames,
  alertIcon,
  alertTextClassNames,
} from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

export type AlertMessage = { type?: AlertLevel; message: string }
export type AlertMessageBoxProps = {
  messages: AlertMessage[]
  level?: AlertLevel
  className?: string
}

const AlertMessageBox: React.FC<AlertMessageBoxProps> = ({
  messages,
  level = AlertLevel.Error,
  className = "",
}) => {
  return (
    <div
      className={classNames(
        className,
        "rounded-md",
        alertBackgroundClassNames(level),
        "p-4"
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">{alertIcon(level)}</div>

        <div className="ml-3">
          <div
            className={classNames(
              "text-sm font-medium",
              alertTextClassNames(level)
            )}
          >
            <div className="flex list-disc flex-col space-y-1 pl-5">
              {messages.map(({ message }) => (
                <span
                  key={message}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertMessageBox
