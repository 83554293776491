import React, { useContext } from "react"

import { useField, useFormikContext } from "formik"

import Form from "~tailwindui/Form"

import dig from "src/dig"

import PersistedValuesContext from "./PersistedValuesContext"

export type TextInputProps = {
  label: string
  name: string
  description?: string
  subfield?: boolean
  displayValue?: any
}

const TextInput: React.FC<
  TextInputProps & React.HTMLProps<HTMLInputElement>
> = ({ name, displayValue, ...props }) => {
  const [field, meta] = useField({ name })
  const persistedValues = useContext(PersistedValuesContext)
  const { values } = useFormikContext() as any
  const error = meta.touched && meta.error ? meta.error : ""
  return values.editing ? (
    <Form.TextInput error={error} {...field} {...props} />
  ) : (
    <Form.ValueDisplay
      {...field}
      {...props}
      value={displayValue || dig(persistedValues, name.split("."))}
    />
  )
}

export default TextInput
