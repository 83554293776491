import React from "react"

import { useField } from "formik"

import { SelectField } from "components/Forms/Formik/hookComponents"

const GradeForm = ({ grades }) => {
  const [field] = useField({ name: "gradeId" })

  return (
    <>
      <SelectField
        name={field.name}
        label="Grade"
        options={grades}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        defaultValue={grades.find(grade => grade.id === field.value)}
        valueAttribute="id"
        menuPortalTarget={document.body}
      />
    </>
  )
}

GradeForm.modalTitle = "What grade are you in now?"
GradeForm.displayName = "Grade"
GradeForm.providedFields = ["gradeId"]
GradeForm.step = "Grade"
export default GradeForm
