import React from "react"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const SessionCanceledModal = ({ isOpen, onClose }) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalClassName="bootstrap-modal"
      modalTitle="Session Canceled"
      closeModal={onClose}
      hideCloseButton
    >
      <>
        <div className="modal-body">
          <p className="lead">
            We're sorry, but your tutor will not be able to make your session,
            so unfortunately your session is canceled.
          </p>
        </div>
        <div className="modal-footer">
          <a className="btn solid red" href="/">
            Back to Dashboard
          </a>
        </div>
      </>
    </ModalWithProvidedBody>
  )
}

export default SessionCanceledModal
