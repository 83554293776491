import React from "react"

import { useField } from "formik"

import { tutorAssessmentOptions } from "src/enums"
import ordered from "src/ordered"

import {
  ErrorMessage,
  RadioField,
} from "components/Forms/Formik/hookComponents"

const TutorAssessment = ({ students, sessionPresences }) => {
  const [, meta] = useField("tutorAssessment")
  const totalDuration = student =>
    sessionPresences
      .filter(presence => presence.user.id === student.id)
      .map(presence => presence.duration)
      .reduce((prev, curr) => prev + curr, 0)

  return (
    <div>
      <div className="mb-5 text-center">
        <h3 className="my-0 font-medium">Student Assessment</h3>
        <div className="py-7 text-left">
          <div className="italic"> Evaluation Criteria Guide</div>
          <div className="grid grid-cols-4 gap-3">
            <div className="font-semibold">Fails to meet expectations</div>
            <div className="col-span-3">
              Learning goals are not currently met, or student needs additional
              support in order to meet learning goals
            </div>

            <div className="font-semibold">Partially meets expectations</div>
            <div className="col-span-3">
              Shows progress in meeting learning goals with appropriate levels
              of support
            </div>

            <div className="font-semibold">Meets expectations</div>
            <div className="col-span-3">
              Completes task independently, exhibits an understanding of the
              concept
            </div>

            <div className="font-semibold">Exceeds expectations</div>
            <div className="col-span-3">
              Completes tasks beyond current material independently, displays
              ability to grasp future concepts
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
        {ordered(students, "lastName").map(student => (
          <div key={student.id} className="mb-3">
            <RadioField
              name={`tutorAssessment[${student.id}].tutorAssessment`}
              hideError
              label={
                <div className="mb-2">
                  <div className="font-medium">{student.fullName}</div>
                  <div className="italic">
                    (Duration: {totalDuration(student)} minutes)
                  </div>
                </div>
              }
              options={tutorAssessmentOptions}
            />
          </div>
        ))}
      </div>

      <ErrorMessage {...meta} />
    </div>
  )
}

export default TutorAssessment
