import React from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"

import { tutorClient } from "src/graphql-config"

import CancelSessionButton from "components/AirtutorsSession/CancelSessionButton"

const TutorCancelSessionButton = props => {
  const { runMutation } = useMutation(
    buildMutation(cancelSessionMutation, { client: tutorClient })
  )

  return <CancelSessionButton runMutation={runMutation} {...props} />
}

const cancelSessionMutation = compress`
  mutation($id: ID!, $cancellationReason: String!) {
    cancelAirtutorsSession(id: $id, cancellationReason: $cancellationReason) {
      success
      errorMessage
    }
  }
`

TutorCancelSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default TutorCancelSessionButton
