import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import CollapsibleContainer from "components/CollapsibleContainer"
import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import StudentsTable from "./StudentsTable"
import studentFields from "./studentFields"

const OrganizationStudents = ({ organization }) => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      studentsQuery,
      { id: organization.id, page },
      {
        onMutation: [
          {
            when: "studentContacted",
            run: ({ softReset, currentResults }, response) => {
              const { student } = response.studentContacted
              if (!student || student?.organization?.id !== organization.id)
                return

              currentResults.organization.students.data =
                currentResults.organization.students.data.filter(
                  s => s.id !== student.id
                )
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <CollapsibleContainer
          title={
            <label
              css={css`
                font-size: 1.5rem;
                margin: 0;
              `}
            >
              <a href={data.organization.showPath}>{data.organization.name}</a>
            </label>
          }
          defaultCollapsed={false}
        >
          <StudentsTable students={data.organization.students.data} />
          <Pagination
            totalPages={data.organization.students.totalPages}
            currentPage={data.organization.students.currentPage}
            changePage={setPage}
          />
        </CollapsibleContainer>
      )}
    </WithLoadingIndicator>
  )
}

const studentsQuery = compress`
  ${studentFields}
  query($id: ID!, $page: Int!) {
    organization(id: $id) {
      id
      name
      showPath
      students: uncontactedStudents(page: $page) {
        totalPages
        currentPage
        data {
          ...StudentFields
        }
      }
    }
  }
`

export default OrganizationStudents
