import { useEffect, useMemo, useRef, useState } from "react"

import { detect } from "detect-browser"
import Video from "twilio-video"

import airbrake from "src/airbrake-notifier"

import useWebsocket from "hooks/useWebsocket"

import TwilioUser from "components/TwilioCloudRoom/TwilioUser"
import useCloudRoomLogging from "components/TwilioCloudRoom/hooks/useCloudRoomLogging"

const useCloudRoom = ({
  id,
  lowBandwidth,
  accessToken,
  previewRef,
  ghost,
  setUsers,
  setError,
}) => {
  const [room, setRoom] = useState()
  const roomRef = useRef()
  const localVideoTrack = useRef(null)

  useEffect(() => {
    const addLocalUser = ({ participant }) => {
      if (ghost) return
      let container = previewRef.current
      const localPublications = Array.from(participant.tracks.values())
      const user = new TwilioUser({ identity: participant.identity })
      localPublications.forEach(publication => {
        user.addTrack(publication.track)
        container && container.appendChild(publication.track.attach())
      })
      localVideoTrack.current = Array.from(
        participant.videoTracks.values()
      )[0].track
    }

    let roomOptions = {
      automaticSubscription: true,
      video: ghost
        ? false
        : {
            width: { min: 320, ideal: 1280, max: 1920 },
            height: { min: 240, ideal: 720, max: 1080 },
          },
      audio: ghost ? false : {},
      dominantSpeaker: true,
      networkQuality: {
        local: 3,
        remote: 2,
      },
    }
    if (lowBandwidth) {
      roomOptions = {
        automaticSubscription: true,
        audio: ghost ? false : {},
        dominantSpeaker: true,
        networkQuality: {
          local: 3,
          remote: 2,
        },
        preferredVideoCodecs: ["VP8"],
      }
    }

    Video.connect(accessToken, roomOptions)
      .then(room => {
        setRoom(room)
        roomRef.current = room
        addLocalUser({ participant: room.localParticipant })

        room.localParticipant.on(
          "trackPublicationFailed",
          (error, localTrack) => {
            airbrake.notify({
              error,
              name: "trackPublicationFailed",
              context: { severity: "warning" },
            })
            alert(
              "iOS versions < 12.1 are currently unsupported for Cloudroom. Please use an up to date version of the Chrome desktop browser."
            )
          }
        )

        room.on("trackSubscribed", (track, publication, participant) => {
          setUsers(currentUsers => {
            const identity = participant.identity
            const user = currentUsers.has(identity)
              ? new TwilioUser({ ...currentUsers.get(identity) })
              : new TwilioUser({ identity: identity })
            if (track.kind === "audio") {
              if (track.isEnabled) user.audioMuted = false
              if (!track.isEnabled) user.audioMuted = true
            }
            if (track.kind === "video") {
              if (track.isEnabled) user.videoMuted = false
              if (!track.isEnabled) user.videoMuted = true
            }
            user.addTrack(publication.track)

            const updatedUsers = currentUsers.set(user.identity, user)
            return updatedUsers
          })
        })

        room.on("trackUnsubscribed", (track, publication, participant) => {
          setUsers(currentUsers => {
            const user = currentUsers.get(participant.identity)
            const updatedUser = new TwilioUser({ ...user })
            if (publication.kind === "audio") updatedUser.audioTrack = null
            if (publication.kind === "video") updatedUser.videoTrack = null
            track.detach()
            const updatedUsers = currentUsers.set(
              participant.identity,
              updatedUser
            )
            return updatedUsers
          })
        })

        room.on("trackDisabled", (publication, participant) => {
          setUsers(currentUsers => {
            const user = currentUsers.get(participant.identity)
            const updatedUser = new TwilioUser({ ...user })
            if (publication.kind === "audio") updatedUser.audioMuted = true
            if (publication.kind === "video") updatedUser.videoMuted = true
            const updatedUsers = currentUsers.set(
              participant.identity,
              updatedUser
            )
            return updatedUsers
          })
        })

        room.on("trackEnabled", (publication, participant) => {
          setUsers(currentUsers => {
            const user = currentUsers.get(participant.identity)
            const updatedUser = new TwilioUser({ ...user })
            if (publication.kind === "audio") updatedUser.audioMuted = false
            if (publication.kind === "video") updatedUser.videoMuted = false
            const updatedUsers = currentUsers.set(
              participant.identity,
              updatedUser
            )
            return updatedUsers
          })
        })

        room.on("participantDisconnected", participant => {
          setUsers(currentUsers => {
            const updatedUsers = currentUsers.delete(participant.identity)
            return updatedUsers
          })
        })
      })
      .catch(error => {
        if (
          process.env.RAILS_ENV === "production" &&
          !error?.message.match(/permission denied|request is not allowed/i)
        ) {
          airbrake.notify({
            error,
            name: "CloudRoom#permission_denied",
            context: { severity: "warning" },
          })
        }

        const browser = detect()
        if (browser?.os === "iOS" && browser?.name !== "ios") {
          alert("For iOS support, please use the Safari browser")
        } else {
          setError({ error, browser })
        }
      })

    const disconnect = () => {
      if (room) {
        room.disconnect()
      } else if (roomRef.current) {
        roomRef.current.disconnect()
      }
    }
    window.addEventListener("beforeunload", disconnect)

    return () => {
      window.removeEventListener("beforeunload", disconnect)
      disconnect()
    }
    // eslint-disable-next-line
  }, [])

  useCloudRoomLogging({ room, setError })
  const channelProps = useMemo(() => {
    return { id }
  }, [id])
  const { channel } = useWebsocket({
    channelName: "CloudroomChannel",
    channelProps,
  })
  const { userChannel } = useWebsocket({
    channelName: "CloudroomUserChannel",
    channelProps,
  })
  return { room, localVideoTrack, channel, userChannel }
}

export default useCloudRoom
