import React from "react"

import { useMutation } from "hooks/urql"

import { Loading } from "components/Icons"
import RemoveButton from "components/RemoveButton"

import ActionContainer from "./ActionContainer"

const UrqlItem = props => {
  const { mutation, mutationProps, children, ...other } = props
  const [mutationResult, runMutation] = useMutation(mutation)

  return (
    <div className="d-flex align-items-center" {...other}>
      <ActionContainer>
        {mutationResult.fetching ? (
          <div className="pt-[1px] pr-[8px] pb-[3px] pl-[6px]">
            <Loading />
          </div>
        ) : (
          <RemoveButton onClick={() => runMutation(mutationProps)} />
        )}
      </ActionContainer>
      {children}
    </div>
  )
}

export default UrqlItem
