import React, { useRef } from "react"

import {
  buildMutation,
  buildQuery,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import PropTypes from "prop-types"

import ordered from "src/ordered"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import RemoveButton from "components/RemoveButton"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import addStudentSubjectMutation from "./addStudentSubjectMutation"
import loadStudentSubjectsQuery from "./loadStudentSubjectsQuery"
import removeStudentSubjectMutation from "./removeStudentSubjectMutation"
import styles from "./styles.module.scss"

const AdminStudentSubjects = props => {
  const { studentId } = props
  const selectRef = useRef(null)
  const loadingState = useQuery(
    buildQuery(
      loadStudentSubjectsQuery,
      { studentId },
      {
        onMutation: [
          {
            when: "addStudentSubject",
            run: ({ softReset, currentResults }, response) => {
              const addedSubject = response.addStudentSubject.subject

              if (
                currentResults.student.subjects.find(
                  subject => subject.id === addedSubject.id
                )
              ) {
                return
              }

              currentResults.student.subjects.push(addedSubject)
              softReset(currentResults)
            },
          },
          {
            when: "removeStudentSubject",
            run: ({ softReset, currentResults }, response) => {
              const newSubjects = currentResults.student.subjects.filter(
                subject =>
                  subject.id !== response.removeStudentSubject.subject.id
              )

              currentResults.student.subjects = newSubjects
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const { runMutation: runAddSubjectMutation } = useMutation(
    buildMutation(addStudentSubjectMutation)
  )

  const { runMutation: runRemoveSubjectMutation } = useMutation(
    buildMutation(removeStudentSubjectMutation)
  )

  const subjectSelected = option => {
    if (!option?.value) return
    const subjectId = option.value
    runAddSubjectMutation({ studentId, subjectId }).then(() => {
      selectRef.current.clearValue()
    })
  }

  const removeSubject = id => {
    runRemoveSubjectMutation({ studentId, subjectId: id })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading student subjects"
      heading={<h4>Subjects</h4>}
    >
      {({ data }) => {
        const { subjects, grade } = data.student
        return (
          <div className="row">
            <div className="col-12">
              {ordered(subjects, "name").map(({ id, name }) => (
                <div key={id} className={styles.subjectContainer}>
                  <RemoveButton onClick={() => removeSubject(id)} />
                  <span>{name}</span>
                </div>
              ))}
              <AutocompleteSelect
                ref={selectRef}
                onChange={subjectSelected}
                api={"/api/admins/subjects/autocomplete_name"}
                searchOptions={`tier_id=${grade.tier.id}`}
                placeholder={`Add ${grade.tier.gradeRange} subject`}
              />
            </div>
          </div>
        )
      }}
    </WithLoadingIndicator>
  )
}

AdminStudentSubjects.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default AdminStudentSubjects
