import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import LocalTime from "components/LocalTime"
import RemoveButton from "components/RemoveButton"

const Recipient = ({ recipient }) => {
  const [errorMessage, setErrorMessage] = useState()
  const { runMutation, running } = useMutation(
    buildMutation(removeRecipientMutation)
  )

  const handleClick = () => {
    runMutation({ id: recipient.id }).then(response => {
      const { errorMessages } = response.batchTextRemoveRecipient
      if (errorMessages) {
        setErrorMessage(errorMessages)
      }
    })
  }

  return (
    <>
      <tr>
        <td>{recipient.user?.fullName}</td>
        <td>{recipient.phoneNumber || recipient.user?.phoneNumber}</td>
        <td>
          {recipient.sentAt ? (
            <LocalTime timestamp={recipient.sentAt} />
          ) : (
            <RemoveButton onClick={handleClick} disabled={running} />
          )}
          {errorMessage && (
            <div className="alert alert-danger d-inline-block ml-3">
              {errorMessage}
            </div>
          )}
        </td>
      </tr>
    </>
  )
}

const removeRecipientMutation = compress`
  mutation($id: ID!) {
    batchTextRemoveRecipient(id: $id) {
      errorMessages
      batchText {
        batchTextRecipients {
          id
          phoneNumber
          sentAt
          user {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`

export default Recipient
