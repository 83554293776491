import React from "react"

import { gql, useMutation } from "hooks/urql"

import { handleFailure, readFile } from "components/Forms/Formik/hookComponents"

import GroupForm from "../Form"

const NewTailoredQuestionGroup = () => {
  const [, runMutation]: any[] = useMutation(createGroupMutation)

  const handleSubmit = (values, actions) => {
    const promises = values.diagrams.map(diagram => readFile(diagram))
    Promise.all(promises).then(files => {
      values.diagrams = files
      runMutation(values)
        .then(
          result => {
            const { failures, redirectTo } =
              result.data.tailoredQuestionCreateGroup
            if (failures.length > 0) {
              handleFailure(actions, failures)
            } else {
              window.location.href = redirectTo
            }
            actions.setSubmitting(false)
          },
          () => handleFailure(actions)
        )
        .catch(() => handleFailure(actions))
    })
  }

  return <GroupForm internalName="" tagList={[]} handleSubmit={handleSubmit} />
}

const createGroupMutation = gql`
  mutation createTailoredQuestionGroup(
    $internalName: String!
    $tagList: [String!]!
    $diagrams: [FileInputObject!]!
  ) {
    tailoredQuestionCreateGroup(
      internalName: $internalName
      tagList: $tagList
      diagrams: $diagrams
    ) {
      failures {
        message
      }
      redirectTo
    }
  }
`

export default NewTailoredQuestionGroup
