import React from "react"

import { TimeZoneField } from "components/Forms/Formik/hookComponents"

import Country from "./Country"

const Location = () => (
  <>
    <Country />
    <TimeZoneField name="timeZone" />
  </>
)

export default Location
