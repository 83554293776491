import { Dispatch, SetStateAction, useEffect, useState } from "react"

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultModality,
  LogLevel,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js"
import camelize from "camelize"

export type UseChimeCloudroomProps = {
  session: any
  localUser: {
    type: "Student" | "Tutor" | "Teacher" | "Admin"
    id: string
  }
  setRoster: Dispatch<SetStateAction<Record<string, any>>>
  ghost?: boolean
  muteOutput?: boolean
}

export type HookValues = Record<any, any>

const useChimeCloudroom = ({
  session,
  localUser,
  setRoster,
  ghost = false,
  muteOutput = false,
}: UseChimeCloudroomProps): HookValues => {
  const { chimeJoinInfo } = session
  const lowBandwidthStudent =
    session.lowBandwidthCloudroom && localUser.type === "Student"

  const { meeting, attendee } = chimeJoinInfo
  const [audioSetupInProgress, setAudioSetupInProgress] =
    useState<boolean>(true)
  const [videoSetupInProgress, setVideoSetupInProgress] =
    useState<boolean>(true)
  const [audioVideo, setAudioVideo] = useState<any>()

  useEffect(() => {
    const logger = new ConsoleLogger("MyLogger", LogLevel.WARN)
    const deviceController = new DefaultDeviceController(logger)
    const configuration = new MeetingSessionConfiguration(
      camelize(meeting),
      camelize(attendee)
    )
    const meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    )

    setAudioVideo(meetingSession.audioVideo)

    // SET OBSERVER
    const observer = {
      audioVideoDidStart: () => {
        // set up audio output
        if (!muteOutput) {
          meetingSession.audioVideo.listAudioOutputDevices().then(devices => {
            const audioOutputDeviceInfo = devices[0]
            meetingSession.audioVideo.chooseAudioOutput(
              audioOutputDeviceInfo.deviceId
            )
          })

          const audioElement: HTMLAudioElement =
            document.querySelector("#room-audio")
          meetingSession.audioVideo.bindAudioElement(audioElement)
          setTimeout(() => audioElement.play(), 500)
        }

        // set audio input initial state
        if (!ghost) {
          meetingSession.audioVideo.listAudioInputDevices().then(devices => {
            const audioInputDeviceInfo = devices[0]
            meetingSession.audioVideo.startAudioInput(
              audioInputDeviceInfo.deviceId
            )
          })
          meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(true)
          meetingSession.audioVideo.realtimeUnmuteLocalAudio()

          const initialAudioMuted = session.audioMutedUserIds.includes(
            localUser.id
          )

          if (initialAudioMuted) {
            meetingSession.audioVideo.stopAudioInput()
            meetingSession.audioVideo.realtimeMuteLocalAudio()
          }
        }
        setAudioSetupInProgress(false)

        // set video input initial state
        const videoIsOn =
          !lowBandwidthStudent &&
          !ghost &&
          !session.videoMutedUserIds.includes(localUser.id)

        if (videoIsOn) {
          meetingSession.audioVideo.listVideoInputDevices().then(devices => {
            const videoInputDeviceInfo = devices[0]
            meetingSession.audioVideo
              .startVideoInput(videoInputDeviceInfo.deviceId)
              .then(() => meetingSession.audioVideo.startLocalVideoTile())
          })
        }
        setVideoSetupInProgress(false)
      },
      // when video starts or stops, update roster
      videoTileDidUpdate: tileState => {
        if (!tileState.boundAttendeeId) return

        const baseAttendeeId = new DefaultModality(
          tileState.boundAttendeeId
        ).base()

        setRoster(currentRoster => {
          const newRoster = { ...currentRoster }

          if (tileState.isContent) {
            if (currentRoster[baseAttendeeId].sharingContent)
              return currentRoster

            newRoster[baseAttendeeId].videoTileId = tileState.tileId
            newRoster[baseAttendeeId].sharingContent = tileState.active
            newRoster[baseAttendeeId].sharingVideo = false
          } else {
            const attendee = newRoster[baseAttendeeId]
            if (!attendee) return currentRoster

            newRoster[baseAttendeeId].videoTileId = tileState.tileId
            newRoster[baseAttendeeId].sharingVideo = tileState.active
            newRoster[baseAttendeeId].sharingContent = false
          }

          return newRoster
        })

        if (tileState.isContent) {
          const localAttendeeId = session.chimeJoinInfo.attendee.attendeeId

          const videoElementId =
            baseAttendeeId === localAttendeeId
              ? "#video-local"
              : `#video-${baseAttendeeId}`
          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            document.querySelector(videoElementId)
          )
          return
        }

        if (tileState.localTile && (ghost || lowBandwidthStudent)) return

        const videoElementId = tileState.localTile
          ? "#video-local"
          : `#video-${tileState.boundAttendeeId}`
        meetingSession.audioVideo.bindVideoElement(
          tileState.tileId,
          document.querySelector(videoElementId)
        )
      },
      // when video stops, update roster
      videoTileWasRemoved: tileId => {
        setRoster(currentRoster => {
          const newRoster = { ...currentRoster }
          const attendeeId = Object.keys(currentRoster).find(
            id => newRoster[id].videoTileId === tileId
          )

          if (!newRoster[attendeeId]) return currentRoster

          delete newRoster[attendeeId].videoTileId
          newRoster[attendeeId].sharingVideo = false
          newRoster[attendeeId].sharingContent = false
          return newRoster
        })
      },
    }
    meetingSession.audioVideo.addObserver(observer)
    meetingSession.audioVideo.start()

    // ROSTERING
    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId, present, externalUserId) => {
        const userType = externalUserId.split("-")[0]
        if (["ghost", "aws:MediaPipeline"].includes(userType)) return

        const baseAttendeeId = new DefaultModality(presentAttendeeId).base() // "my_id#content" => "my_id"
        if (baseAttendeeId !== presentAttendeeId) return // this must be a content share, so do not roster

        if (present) {
          setRoster(currentRoster => {
            const newRoster = { ...currentRoster }
            newRoster[presentAttendeeId] = {
              attendeeId: presentAttendeeId,
              externalUserId,
            }
            return newRoster
          })
        } else {
          setRoster(currentRoster => {
            const newRoster = { ...currentRoster }
            delete newRoster[presentAttendeeId]
            return newRoster
          })
        }

        meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          (attendeeId, volume, muted, signalStrength, externalUserId) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base() // "my_id#content" => "my_id"
            if (baseAttendeeId !== attendeeId) return // this must be a content share, so do not roster

            setRoster(currentRoster => {
              if (!currentRoster[attendeeId]) return currentRoster
              const newRoster = { ...currentRoster }
              // A null value for any field means that it has not changed.
              if (volume !== null) newRoster[attendeeId].volume = volume // fraction between 0 and 1
              if (muted !== null) newRoster[attendeeId].muted = muted // boolean
              if (signalStrength !== null)
                newRoster[attendeeId].signalStrength = signalStrength // 0 (no signal), 0.5 (weak), 1 (strong)
              return newRoster
            })
          }
        )
      }
    )

    window.addEventListener("pagehide", () => meetingSession.audioVideo.stop())

    // eslint-disable-next-line
  }, [])

  return {
    audioVideo,
    audioSetupInProgress,
    videoSetupInProgress,
    session,
    ghost,
  }
}
export default useChimeCloudroom
