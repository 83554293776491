import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $id: ID!
  ) {
    approveTutorSubject(
      id: $id
    ) {
      tutorSubject {
        id
        approvalState
      }
    }
  }
`
