import React, { useState } from "react"

const InlineConfirmation = ({
  handleSubmit,
  buttonText,
  type = "success",
  size = "normal",
}) => {
  const [pending, setPending] = useState(false)
  let buttonSize = ""
  switch (size) {
    case "small":
      buttonSize = "btn-sm"
      break
    case "large":
      buttonSize = "btn-large"
  }

  return pending ? (
    <>
      <button
        type="button"
        onClick={() => {
          handleSubmit()
            .then(
              () => setPending(false),
              () => setPending(false)
            )
            .catch(() => setPending(false))
        }}
        className={`btn ${buttonSize} btn-success rounded-none rounded-l-[3px]`}
      >
        Confirm
      </button>

      <button
        type="button"
        onClick={() => setPending(false)}
        className={`btn ${buttonSize} btn-danger -ml-px rounded-none rounded-r-[3px]`}
      >
        Cancel
      </button>
    </>
  ) : (
    <button
      type="button"
      onClick={() => setPending(true)}
      className={`btn ${buttonSize} btn-${type}`}
    >
      {buttonText}
    </button>
  )
}

export default InlineConfirmation
