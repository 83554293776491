import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { parentClient as client } from "src/graphql-config"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import AddTeacherSubject from "./AddTeacherSubject"
import CurrentTeacherSubjects from "./CurrentTeacherSubjects"

const EditTeacherSubjects = ({ studentId }) => {
  const loadingState = useQuery(
    buildQuery(
      teacherSubjectsQuery,
      { studentId },
      {
        client,
        onMutation: [
          {
            when: "teacherSubjectRemove",
            run: ({ softReset, currentResults }, response) => {
              const { removedTeacherSubjectId } = response.teacherSubjectRemove
              if (!removedTeacherSubjectId) return
              currentResults.student.teacherSubjects =
                currentResults.student.teacherSubjects.filter(
                  ts => ts.id !== removedTeacherSubjectId
                )
              softReset(currentResults)
            },
          },
          {
            when: "teacherSubjectAdd",
            run: ({ softReset, currentResults }, response) => {
              const { teacherSubject } = response.teacherSubjectAdd
              if (!teacherSubject) return
              currentResults.student.teacherSubjects.push(teacherSubject)
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )
  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <CurrentTeacherSubjects
            studentId={studentId}
            teacherSubjects={data.student.teacherSubjects}
          />
          <AddTeacherSubject
            studentId={studentId}
            teachers={data.student.accessibleTeachers}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const teacherSubjectsQuery = compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      teacherSubjects {
        id
        teacher {
          id
          fullName
          lastName
        }
        subject {
          id
          name
        }
      }
      accessibleTeachers {
        id
        fullName
        lastName
        teacherSubjects {
          id
          subject {
            id
            name
          }
        }
      }
    }
  }
`

export default EditTeacherSubjects
