import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

export type DuplicateAssignmentProps = { id: number }

const DuplicateAssignment: React.FC<DuplicateAssignmentProps> = ({ id }) => {
  const formState = {
    id,
    name: "",
  }

  const [, runMutation]: any[] = useMutation(duplicateMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.tailoredAssignmentDuplicate
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Duplicate Assignment"
      buttonText="Make a Copy"
      buttonClassName="btn btn-sm active:focus:!bug-purple-900 bg-purple-500 hover:!bg-purple-700 focus:!bg-purple-700"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              <Field name="name" label="New Assignment Name" />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton text="Create" />
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const duplicateMutation = gql`
  mutation DuplicateTailoredAssignment($id: ID!, $name: String!) {
    tailoredAssignmentDuplicate(id: $id, name: $name) {
      failures {
        message
      }
    }
  }
`

export default DuplicateAssignment
