import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import { handleFailure } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody, { Footer } from "components/ModalWithProvidedBody"

type RemoveQuestionProps = {
  question: {
    id: number
  }
}

const RemoveQuestion: React.FC<RemoveQuestionProps> = ({ question }) => {
  const formState = { id: question.id }
  const [, removeQuestion]: any[] = useMutation(removeQuestionMutation)

  const handleSubmit = (values, actions, closeModal) => {
    removeQuestion(values)
      .then(
        result => {
          const { failures } = result.data.removeSurveyQuestion
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Question removed")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      buttonText="Delete"
      buttonClassName="btn btn-danger"
      modalTitle="Delete Survey Question"
      modalClassName="bootstrap-modal"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <p>
                Are you sure? This will remove the question from all surveys and
                remove associated answers.
              </p>
            </div>

            <Footer
              cancelAction={closeModal}
              submitText="Remove Question"
              cancelText="No, keep it!"
              isDangerous
            />
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const removeQuestionMutation = gql`
  mutation RemoveSurveyQuestion($id: ID!) {
    removeSurveyQuestion(id: $id) {
      question {
        id
      }
      failures {
        message
      }
    }
  }
`

export default RemoveQuestion
