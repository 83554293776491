import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  FileField,
  FormFailures,
  SubmitButton,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  receipt: Yup.mixed().test("receipt", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const PaymentReceived = ({ statementId }) => {
  const formState = {
    statementId,
    receipt: {},
  }

  const [, paymentReceived] = useMutation(paymentReceivedMutation)

  const handleSubmit = (values, actions, closeModal) => {
    const { receipt, ...variables } = values
    readFile(receipt)
      .then(file => {
        variables.receipt = file
        paymentReceived(variables)
          .then(
            result => {
              const { failures } = result.data.statementPaymentReceived
              if (failures) {
                handleFailure(actions, failures)
              } else {
                closeModal()
              }
            },
            () => handleFailure(actions)
          )
          .catch(() => handleFailure(actions))
      })
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Mark as Paid"
      buttonText="Mark as Paid"
      buttonClassName="btn btn-success"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          <Form>
            <div className="modal-body">
              <FileField name="receipt" label="Receipt" />
            </div>
            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const paymentReceivedMutation = gql`
  mutation ($statementId: ID!, $receipt: FileInputObject!) {
    statementPaymentReceived(statementId: $statementId, receipt: $receipt) {
      failures {
        message
      }
      statement {
        id
      }
    }
  }
`

export default PaymentReceived
