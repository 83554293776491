import React, { useRef, useState } from "react"

import { motion } from "framer-motion"
import { buildMutation, compress, useMutation } from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

const SessionDetails = ({ session, studyGroup }) => {
  const [error, setError] = useState()
  const selectRef = useRef()
  const { runMutation } = useMutation(buildMutation(addStudentMutation))

  const studentSelected = option => {
    if (!option?.value) return
    runMutation({
      airtutorsSessionId: session.id,
      studentId: option.value,
    }).then(data => {
      selectRef.current.clearValue()
      if (data.airtutorsSessionAddStudent.errors) {
        setError(data.airtutorsSessionAddStudent.errors[0].message)
      } else if (error) {
        setError(null)
      }
    })
  }

  return (
    <React.Fragment key={session.id}>
      <dl className="col-12 col-md-4">
        <div className="row">
          {session.tutor ? (
            <>
              <dt className="col-6">Tutor</dt>
              <dd className="col-6">
                <a href={session.tutor.showPath}>{session.tutor.fullName}</a>
              </dd>
            </>
          ) : (
            <>
              <dt className="col-6">Requested Tutors</dt>
              <dd className="col-6">
                <ul className="list-unstyled">
                  {session.activeRequestedTutors.map(tutor => (
                    <li key={tutor.id}>
                      <a href={tutor.showPath}>{tutor.fullName}</a>
                    </li>
                  ))}
                </ul>
              </dd>
            </>
          )}
        </div>
        <div className="row">
          <dt className="col-6">Subject</dt>
          <dd className="col-6">{session.subject.name}</dd>
        </div>
        <div className="row">
          <dt className="col-6">Status</dt>
          <dd className="col-6">{session.statusState}</dd>
        </div>
        <div className="row">
          <dt className="col-6">Billed</dt>
          <dd className="col-6">{session.billState}</dd>
        </div>
      </dl>
      <div className="col-12 col-md-4">
        <h6>Students</h6>
        <EditableList>
          {ordered(session.students, "lastName").map(student => (
            <motion.li key={student.id} positionTransition>
              <Item
                mutation={removeStudentMutation}
                mutationProps={{
                  airtutorsSessionId: session.id,
                  studentId: student.id,
                }}
              >
                <a href={student.showPath}>{student.fullName}</a>
              </Item>
            </motion.li>
          ))}
        </EditableList>
        <AutocompleteSelect
          ref={selectRef}
          api="/api/admins/students/autocomplete_full_name"
          searchOptions={
            studyGroup.organization
              ? `organization_id=${studyGroup.organization.id}`
              : ""
          }
          onChange={studentSelected}
        />
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
      <div className="col-12 col-md-4">
        <LocalTime omitTime timestamp={session.startsAt} />
        <div>
          <LocalTime omitDate timestamp={session.startsAt} />
          &mdash;
          <LocalTime omitDate timestamp={session.endsAt} />
        </div>
        <a href={session.showPath} className="btn btn-info">
          View Details
        </a>
      </div>
      <div className="col-12">
        <hr />
      </div>
    </React.Fragment>
  )
}

const addStudentMutation = compress`
  mutation($airtutorsSessionId: ID!, $studentId: ID!) {
    airtutorsSessionAddStudent(airtutorsSessionId: $airtutorsSessionId, studentId: $studentId) {
      airtutorsSession {
        id
        students {
          id
          fullName
          showPath
        }
      }
    }
  }
`

const removeStudentMutation = compress`
  mutation($airtutorsSessionId: ID!, $studentId: ID!) {
    airtutorsSessionRemoveStudent(airtutorsSessionId: $airtutorsSessionId, studentId: $studentId) {
      airtutorsSession {
        id
        students {
          id
          fullName
          showPath
        }
      }
    }
  }
`

export default SessionDetails
