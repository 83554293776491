import React from "react"

import { Loading } from "components/Icons"

const ClearIndicator = props => {
  const { getStyles } = props
  return (
    <div style={getStyles("clearIndicator", props)}>
      <Loading />
    </div>
  )
}

export default ClearIndicator
