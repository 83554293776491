import React, { useContext } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

import FormStateContext from "../FormStateContext"

const ValidationSchema = Yup.object().shape({
  // parent fields
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("password")], "Passwords do not match"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the Air Tutors privacy policy"
  ),
})

const ProfileStep = props => {
  const [store, setStore] = useContext(FormStateContext)

  const formState = store.parentProfileFields || {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
    termsAccepted: false,
  }
  const setFormState = state => {
    setStore({ ...store, parentProfileFields: state })
  }

  const handleSubmit = (values, actions) => {
    setFormState(values)
    actions.setSubmitting(false)
    props.nextStep()
  }

  return (
    <div className="rounded-md border-2 border-solid border-gray-200 bg-gray-50 p-5">
      <h2 className="mt-0">Parent Profile</h2>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="firstName" label="First Name" autoFocus />
          <Field name="lastName" label="Last Name" />
          <Field name="email" label="Email" autoComplete="email" />
          <Field
            name="phoneNumber"
            label="Phone Number"
            autoComplete="phoneNumber"
          />
          <Field
            name="password"
            type="password"
            label="Password"
            autoComplete="new-password"
          />
          <Field
            name="passwordConfirmation"
            type="password"
            label="Password Confirmation"
            autoComplete="new-password"
          />
          <CheckboxField
            name="termsAccepted"
            label={
              <>
                I am giving Air Tutors permission to contact my family regarding
                setting up tutoring services and agree to the&nbsp;
                <a href="/privacy-policy" target="_blank">
                  Air Tutors privacy policy
                </a>
              </>
            }
          />

          <div className="mt-3 flex flex-col items-center justify-between space-y-2 sm:flex-row sm:space-y-0">
            <button type="button" onClick={props.reset} className="btn red">
              Back
            </button>
            <SubmitButton
              text="Next: Add Student"
              className="btn solid green"
            />
          </div>
          <FormFailures />
        </Form>
      </Formik>
    </div>
  )
}

export default ProfileStep
