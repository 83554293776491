import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  gradeRange: Yup.string().required("Please specify a name"),
  hourlyRate: Yup.string().required("Please specify a default hourly rate"),
})

const EditTier = ({ tier }) => {
  const { runMutation } = useMutation(buildMutation(updateTierMutation))
  const formState = {
    id: tier.id,
    gradeRange: tier.gradeRange,
    hourlyRate: tier.hourlyRate,
  }

  const handleSubmit = (values, actions, closeModal) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.tierUpdate
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            closeModal()
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Edit Tier"
      buttonClassName="btn"
      buttonText="Edit Tier"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, closeModal)
          }
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label w-100">
                    Name
                    <Field
                      name="gradeRange"
                      className="form-control"
                      autoFocus
                    />
                  </label>
                  <ErrorMessage name="gradeRange" />
                </div>

                <div className="form-group">
                  <label className="form-label w-100">
                    Hourly Rate
                    <Field name="hourlyRate" className="form-control" />
                  </label>
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && <div className="alert alert-danger">{status}</div>}
                <SubmitButton isSubmitting={isSubmitting} text="Update Tier" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const updateTierMutation = compress`
  mutation($id: ID!, $gradeRange: String!, $hourlyRate: String!) {
    tierUpdate(id: $id, gradeRange: $gradeRange, hourlyRate: $hourlyRate) {
      tier {
        id
        gradeRange
        formattedHourlyRate
        hourlyRate
      }
    }
  }
`

export default EditTier
