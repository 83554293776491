import React from "react"

import { gql, useQuery } from "urql"

import Layer from "components/Pages/Layer"
import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import EditProfileForm from "./EditProfileForm"

const EditProfile = () => {
  const [result] = useQuery({ query: profileQuery })

  return (
    <Layer>
      <ErrorBoundary name="editProfile">
        <UrqlLoadingIndicator result={result}>
          {({ data }) => <EditProfileForm viewer={data.viewer} />}
        </UrqlLoadingIndicator>
      </ErrorBoundary>
    </Layer>
  )
}

const profileQuery = gql`
  query ProfileQuery {
    viewer {
      firstName
      lastName
      email
      emailTimeZone
      emailTimeZoneEnum
    }
  }
`

export default EditProfile
