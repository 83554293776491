import React from "react"

import StepWizard from "react-step-wizard"

import ProfileStep from "./ProfileStep"
import StudentProfileStep from "./StudentProfileStep"

const ParentRegistration = ({ reset }) => {
  return (
    <>
      <StepWizard isLazyMount>
        <ProfileStep stepName="parentProfile" reset={reset} />
        <StudentProfileStep stepName="addStudent" />
      </StepWizard>
    </>
  )
}

export default ParentRegistration
