import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { expenseTypeOptions } from "src/enums"
import { tutorClient as client } from "src/graphql-config"

import {
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  TextAreaField,
  readFile,
} from "components/Forms/Formik/hookComponents"

import ReceiptsInput from "./ReceiptsInput"

const ValidationSchema = Yup.object().shape({
  expenseType: Yup.string().required(),
  amount: Yup.number()
    .required()
    .test(
      "amount",
      "Intro Session may only be billed as $20",
      function (value) {
        const expenseType = this.parent.expenseType
        if (expenseType === "intro_session" && value !== 20) {
          return false
        }
        return true
      }
    ),
  description: Yup.string().required(),
  receipts: Yup.array()
    .of(
      Yup.object()
        .nullable()
        .shape({
          file: Yup.string().required("Please select a file"),
        })
    )
    .test("file", "At least 1 receipt is required", function (value) {
      if (this.parent.receiptsOptional) return true

      const expenseType = this.parent.expenseType
      if (expenseType === "intro_session") {
        return true
      } else {
        return value && value.length > 0
      }
    }),
})

const ExpenseForm = ({ formState, tutor, receiptsOptional }) => {
  const { runMutation } = useMutation(
    buildMutation(expenseMutation, { client })
  )

  const handleSubmit = (values, actions) => {
    const { receipts, receiptsOptional, __typename, ...variables } = values
    const promises = receipts.map(receipt => readFile(receipt))
    Promise.allSettled(promises)
      .then(promiseResults => {
        variables.receipts = promiseResults.map(result => result.value)

        runMutation({ input: variables })
          .then(
            response => {
              const { failures } = response.saveExpense
              if (failures) {
                actions.setStatus(failures)
              } else {
                window.location = "/"
              }
            },
            () => actions.setStatus([{ message: "Something went wrong" }])
          )
          .catch(() => actions.setStatus([{ message: "Something went wrong" }]))
          .finally(() => actions.setSubmitting(false))
      })
      .catch(() =>
        actions.setStatus([
          { message: "One or more files failed to upload successfully" },
        ])
      )
  }

  let validExpenseTypeOptions = [...expenseTypeOptions]
  if (!tutor.canSubmitGeneralExpenses) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "other"
    )
  }
  if (tutor.hasSubmittedFingerprintExpense) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "fingerprinting"
    )
  }
  if (tutor.hasSubmittedIntroSessionExpense) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "intro_session"
    )
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <SelectField
          name="expenseType"
          label="Expense Type"
          options={validExpenseTypeOptions}
          defaultValue={expenseTypeOptions.find(
            option => option.value === formState.expenseType
          )}
        />

        <Field name="amount" label="Amount" type="number" step={0.01} />
        <Field name="description" label="Description" />
        <TextAreaField name="comments" label="Comments" />
        <ReceiptsInput />
        <FormFailures />
        <SubmitButton />
      </Form>
    </Formik>
  )
}

const expenseMutation = compress`
  mutation($input: ExpenseInputObject!) {
    saveExpense(input: $input) {
      failures {
        message
      }
    }
  }
`

export default ExpenseForm
