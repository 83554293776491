import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import AlertMessageBox from "~tailwindui/Form/AlertMessageBox"
import Button from "~tailwindui/Form/Button"
import Header from "~tailwindui/Form/Header"
import SimpleLinkList from "~tailwindui/shared/SimpleLinkList"
import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import NewSemester from "./NewSemester"
import SemesterItem from "./SemesterItem"

export type SettingsProps = Record<string, never>

const Settings: React.FC<SettingsProps> = () => {
  const [result] = useQuery({ query: viewerQuery })
  const [showNewForm, setShowNewForm] = useState(false)

  if (
    result.data &&
    !result.data.viewer.canOrganizationAdminAccessSemesters &&
    !result.data.viewer.canOrganizationAdminAccessUserManagement
  ) {
    return (
      <span>
        Sorry, you don't have access to any settings for this organization.
      </span>
    )
  }
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          {viewer.canOrganizationAdminAccessSemesters && (
            <>
              <Header title="Semesters">
                {!showNewForm && (
                  <Button
                    text="Create New"
                    onClick={() => setShowNewForm(true)}
                  />
                )}
              </Header>
              {showNewForm && (
                <NewSemester
                  organizationId={viewer.organization.id}
                  hideForm={() => setShowNewForm(false)}
                />
              )}
              {!viewer.organization.activeSemester && !showNewForm && (
                <AlertMessageBox
                  level={AlertLevel.Info}
                  messages={[
                    {
                      message:
                        "You do not have an active (current) semester. Please create one above.",
                    },
                  ]}
                />
              )}
              <ul className="mt-4">
                {viewer.organization.semesters.map(semester => (
                  <SemesterItem key={semester.id} semester={semester} />
                ))}
              </ul>
            </>
          )}

          {viewer.canOrganizationAdminAccessUserManagement && (
            <>
              <Header title="User Management" />
              <SimpleLinkList
                items={[
                  {
                    to: "/profile",
                    display: "Manage Your Profile",
                  },
                  {
                    to: "/users",
                    display: "Manage Admin Users",
                  },
                ]}
              />
            </>
          )}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const viewerQuery = gql`
  query viewer {
    viewer {
      canOrganizationAdminAccessSemesters
      canOrganizationAdminAccessUserManagement
      organization {
        id
        primaryAdmin {
          id
        }
        activeSemester {
          id
          name
          startsOn
          endsOn
        }
        semesters {
          id
          name
          startsOn
          endsOn
        }
      }
    }
  }
`

export default Settings
