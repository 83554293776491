import React, { useState } from "react"

import { gql, useQuery } from "hooks/urql"

import CollapsibleContainer from "components/CollapsibleContainer"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const BudgetlessStudyGroups = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: organizationsQuery,
    variables: { page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <a className="btn btn-info mb-2" href="study_groups/bulk_actions/new">
            Bulk Update
          </a>
          {data.organizations.data.map(organization =>
            organization.studyGroups.data.length === 0 ? null : (
              <CollapsibleContainer
                key={organization.id}
                title={
                  <label className="m-0 text-2xl">
                    <a href={organization.showPath}>{organization.name}</a>
                  </label>
                }
                defaultCollapsed={false}
              >
                <table className="table">
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>School</th>
                      <th>Budget Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {organization.studyGroups.data.map(studyGroup => (
                      <tr key={studyGroup.id}>
                        <td>
                          <a href={studyGroup.showPath}>{studyGroup.name}</a>
                        </td>
                        <td>{studyGroup.school?.name}</td>
                        <td>{studyGroup.budget ? "Inactive" : "Missing"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CollapsibleContainer>
            )
          )}

          <Pagination
            totalPages={data.organizations.totalPages}
            currentPage={data.organizations.currentPage}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const organizationsQuery = gql`
  query ($page: Int!) {
    organizations(page: $page, test: false) {
      totalPages
      currentPage
      data {
        id
        name
        showPath
        studyGroups: budgetlessStudyGroups {
          currentPage
          totalPages
          data {
            id
            name
            splitBill
            organization {
              id
              name
            }
            school {
              id
              name
            }
            budget {
              id
              active
            }
            showPath
          }
        }
      }
    }
  }
`

export default BudgetlessStudyGroups
