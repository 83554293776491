const handleFailure = (
  actions,
  failures = [{ message: "Something went wrong " }]
) => {
  actions.setSubmitting(false)
  actions.setStatus(failures)
  // and here we can send a unified airbrake message if we want,
  // filtering the set we send notifications for as needed
}

export default handleFailure
