import React from "react"

import { useFormikContext } from "formik"

import AddPhoneNumbers from "./AddPhoneNumbers"
import AddSessions from "./AddSessions"
import AddStudents from "./AddStudents"
import AddTutors from "./AddTutors"

const removeDuplicates = users => {
  const uniqUsers = users
    .reduce((map, user) => {
      const strippedPhoneNumber = user.phoneNumber.replace(/\D/g, "")
      return map.has(strippedPhoneNumber)
        ? map
        : map.set(strippedPhoneNumber, user)
    }, new Map())
    .values()

  return [...uniqUsers]
}

const AddRecipients = () => {
  const { values, setFieldValue } = useFormikContext()

  const addPhoneNumberRecipients = phoneNumbers => {
    const allRecipients = values.recipients.concat(
      phoneNumbers.map(phoneNumber => ({
        phoneNumber,
      }))
    )

    setFieldValue("recipients", removeDuplicates(allRecipients))
  }

  const addUserRecipients = (
    users,
    attribute = "phoneNumber",
    fallbackAttribute = null
  ) => {
    const getNumber = user => {
      const defaultNumber = user[attribute]
      if (defaultNumber && defaultNumber.trim().length > 0) {
        return defaultNumber
      } else {
        const fallbackNumber = user[fallbackAttribute]
        if (fallbackNumber && fallbackNumber.trim().length > 0) {
          return fallbackNumber
        }
      }
    }

    const allRecipients = values.recipients.concat(
      users
        .filter(user => getNumber(user))
        .map(user => ({
          phoneNumber: getNumber(user),
          user: user,
        }))
    )

    setFieldValue("recipients", removeDuplicates(allRecipients))
  }

  return (
    <>
      <AddPhoneNumbers addRecipients={addPhoneNumberRecipients} />
      <AddStudents addRecipients={addUserRecipients} />
      <AddTutors addRecipients={addUserRecipients} />
      <AddSessions addRecipients={addUserRecipients} />
    </>
  )
}

export default AddRecipients
