import React from "react"

function Pagination(props) {
  const { totalPages, currentPage, changePage } = props

  if (totalPages <= 1) return null

  return (
    <nav className="d-flex justify-content-between">
      <ul className="pagination">
        {currentPage !== 1 && (
          <React.Fragment>
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => changePage(1)}
              >
                First
              </button>
            </li>
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => changePage(currentPage - 1)}
              >
                Prev
              </button>
            </li>
          </React.Fragment>
        )}
        {currentPage >= 3 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => changePage(currentPage - 2)}
            >
              {currentPage - 2}
            </button>
          </li>
        )}
        {currentPage >= 2 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => changePage(currentPage - 1)}
            >
              {currentPage - 1}
            </button>
          </li>
        )}
        <li className="page-item active">
          <button type="button" className="page-link">
            {currentPage}
          </button>
        </li>
        {totalPages - currentPage >= 1 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => changePage(currentPage + 1)}
            >
              {currentPage + 1}
            </button>
          </li>
        )}
        {totalPages - currentPage >= 2 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => changePage(currentPage + 2)}
            >
              {currentPage + 2}
            </button>
          </li>
        )}
        {currentPage !== totalPages && (
          <React.Fragment>
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => changePage(currentPage + 1)}
              >
                Next
              </button>
            </li>
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => changePage(totalPages)}
              >
                Last
              </button>
            </li>
          </React.Fragment>
        )}
      </ul>
    </nav>
  )
}

Pagination.defaultProps = {
  totalPages: 0,
}

export default Pagination
