import React, { useState } from "react"

import { buildQuery, useQuery } from "micro-graphql-react"

import ordered from "src/ordered"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Generation from "./Shared/Generation"

import GenerateSessions from "./GenerateSessions"
import TutorInviteTrigger from "./TutorInviteTrigger"

const updateResults = ({
  currentResults,
  studyGroup,
  teacherSubject,
  studyGroupGeneration,
}) => {
  const generationIndex =
    currentResults.organization.studyGroupGenerations.data.findIndex(
      g => g.id === studyGroupGeneration?.id
    )

  if (studyGroup) {
    const groupIndex = currentResults.organization.studyGroupGenerations.data[
      generationIndex
    ].studyGroups.findIndex(g => g.id === studyGroup.id)
    currentResults.organization.studyGroupGenerations.data[
      generationIndex
    ].studyGroups[groupIndex] = studyGroup
  }

  if (teacherSubject) {
    currentResults.organization.studyGroupGenerations.data[
      generationIndex
    ].teacherSubject = teacherSubject
  }

  return currentResults
}

const GeneratedStudyGroups = ({ query, organizationId, title }) => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(
    buildQuery(
      query,
      { id: organizationId, page },
      {
        onMutation: [
          {
            when: "generatedStudyGroupAddStudent",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject, studyGroupGeneration } =
                response.generatedStudyGroupAddStudent

              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  teacherSubject,
                  studyGroupGeneration,
                })
              )
            },
          },
          {
            when: "generatedStudyGroupRemoveStudent",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject, studyGroupGeneration } =
                response.generatedStudyGroupRemoveStudent

              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  teacherSubject,
                  studyGroupGeneration,
                })
              )
            },
          },
          {
            when: "studyGroupAddUser",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupAddUser
              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  studyGroupGeneration: studyGroup.studyGroupGeneration,
                })
              )
            },
          },
          {
            when: "studyGroupRemoveUser",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup } = response.studyGroupRemoveUser
              softReset(
                updateResults({
                  currentResults,
                  studyGroup,
                  studyGroupGeneration: studyGroup.studyGroupGeneration,
                })
              )
            },
          },
          {
            when: "createGeneratedStudyGroup",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroupGeneration } =
                response.createGeneratedStudyGroup

              const generationIndex =
                currentResults.organization.studyGroupGenerations.data.findIndex(
                  g => g.id === studyGroupGeneration.id
                )
              currentResults.organization.studyGroupGenerations.data[
                generationIndex
              ].studyGroups = studyGroupGeneration.studyGroups
              softReset(currentResults)
            },
          },
          {
            when: "studyGroupRemove",
            run: ({ softReset, currentResults }, response) => {
              const { studyGroup, teacherSubject } = response.studyGroupRemove

              const generationIndex =
                currentResults.organization.studyGroupGenerations.data.findIndex(
                  g => g.id === studyGroup.studyGroupGeneration.id
                )

              const currentGroups =
                currentResults.organization.studyGroupGenerations.data[
                  generationIndex
                ].studyGroups

              currentResults.organization.studyGroupGenerations.data[
                generationIndex
              ].studyGroups = currentGroups.filter(g => g.id !== studyGroup.id)

              currentResults.organization.studyGroupGenerations.data[
                generationIndex
              ].teacherSubject = teacherSubject

              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">{title}</h2>
              <div className="text-center">
                <TutorInviteTrigger organizationId={organizationId} />
                <GenerateSessions organizationId={organizationId} />
              </div>
            </div>
          </div>
          {ordered(
            data.organization.studyGroupGenerations.data,
            "teacherSubject",
            "teacher",
            "lastName"
          ).map(generation => (
            <Generation
              key={generation.id}
              generation={generation}
              organizationId={organizationId}
            />
          ))}
          <Pagination
            currentPage={data.organization.studyGroupGenerations.currentPage}
            totalPages={data.organization.studyGroupGenerations.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

export default GeneratedStudyGroups
