import React from "react"

import { buildMutation, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Loading } from "components/Icons"
import RemoveButton from "components/RemoveButton"

import ActionContainer from "./ActionContainer"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const LoadingContainer = styled.div`
  padding: 1px 7px 2px;
`

const Item = ({ mutation, mutationProps, children, ...other }) => {
  const { running, runMutation } = useMutation(buildMutation(mutation))

  return (
    <Container {...other}>
      <ActionContainer>
        {running ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <RemoveButton onClick={() => runMutation(mutationProps)} />
        )}
      </ActionContainer>
      {children}
    </Container>
  )
}

Item.propTypes = {
  mutation: PropTypes.string.isRequired,
  mutationProps: PropTypes.object,
}

export default Item
