/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react"

import { onEnd, onPush, pipe, subscribe, takeWhile } from "wonka"

import client from "src/urql-client"

import { getCacheForClient } from "./cache"
import { computeNextState, hasDepsChanged, initialState } from "./state"
import { useRequest } from "./useRequest"

const isSuspense = (client, context) =>
  client.suspense && (!context || context.suspense !== false)

let currentInit = false

export function useQuery(args) {
  const activeClient = args.client || client
  const cache = getCacheForClient(activeClient)
  const suspense = isSuspense(activeClient, args.context)
  const request = useRequest(args.query, args.variables)

  const source = useMemo(() => {
    if (args.pause) return null

    const source = activeClient.executeQuery(request, {
      requestPolicy: args.requestPolicy,
      ...args.context,
    })

    return suspense
      ? pipe(
          source,
          onPush(result => {
            cache.set(request.key, result)
          })
        )
      : source
  }, [
    cache,
    activeClient,
    request,
    suspense,
    args.pause,
    args.requestPolicy,
    args.context,
  ])

  const getSnapshot = useCallback(
    (source, suspense) => {
      if (!source) return { fetching: false }

      let result = cache.get(request.key)
      if (!result) {
        let resolve = value => null

        const subscription = pipe(
          source,
          takeWhile(() => (suspense && !resolve) || !result),
          subscribe(_result => {
            result = _result
            if (resolve) resolve(result)
          })
        )

        if (result == null && suspense) {
          const promise = new Promise(_resolve => {
            resolve = _resolve
          })

          cache.set(request.key, promise)
          throw promise
        } else {
          subscription.unsubscribe()
        }
      } else if (suspense && result != null && "then" in result) {
        throw result
      }

      return result || { fetching: true }
    },
    [cache, request]
  )

  const deps = [
    activeClient,
    request,
    args.requestPolicy,
    args.context,
    args.pause,
  ]

  const [state, setState] = useState(() => {
    currentInit = true
    try {
      return [
        source,
        computeNextState(initialState, getSnapshot(source, suspense)),
        deps,
      ]
    } finally {
      currentInit = false
    }
  })

  let currentResult = state[1]
  if (source !== state[0] && hasDepsChanged(state[2], deps)) {
    setState([
      source,
      (currentResult = computeNextState(
        state[1],
        getSnapshot(source, suspense)
      )),
      deps,
    ])
  }

  useEffect(() => {
    const source = state[0]
    const request = state[2][1]

    let hasResult = false

    const updateResult = result => {
      hasResult = true
      if (!currentInit) {
        setState(state => {
          const nextResult = computeNextState(state[1], result)
          return state[1] !== nextResult
            ? [state[0], nextResult, state[2]]
            : state
        })
      }
    }

    if (source) {
      const subscription = pipe(
        source,
        onEnd(() => {
          updateResult({ fetching: false })
        }),
        subscribe(updateResult)
      )

      if (!hasResult) updateResult({ fetching: true })

      return () => {
        cache.dispose(request.key)
        subscription.unsubscribe()
      }
    } else {
      updateResult({ fetching: false })
    }
  }, [cache, state[0], state[2][1]])

  const executeQuery = useCallback(
    opts => {
      const context = {
        requestPolicy: args.requestPolicy,
        ...args.context,
        ...opts,
      }

      setState(state => {
        const source = suspense
          ? pipe(
              activeClient.executeQuery(request, context),
              onPush(result => {
                cache.set(request.key, result)
              })
            )
          : activeClient.executeQuery(request, context)
        return [source, state[1], deps]
      })
    },
    [
      activeClient,
      cache,
      request,
      suspense,
      getSnapshot,
      args.requestPolicy,
      args.context,
    ]
  )

  return [currentResult, executeQuery]
}
