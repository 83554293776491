import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import Select from "react-select"
import * as Yup from "yup"

import { timezoneOptions } from "src/enums"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, File, SubmitButton } from "components/Forms/Formik"

const ValidationSchema = Yup.object().shape({
  timezone: Yup.string().required(
    "Please select a timezone to parse the availabilities in"
  ),
  file: Yup.mixed().test("file", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const CourseClusterBulkUploadForm = props => {
  const { runMutation } = useMutation(
    buildMutation(createCourseClusterBulkUploadMutation)
  )
  const formState = {
    organizationId: "",
    timezone: timezoneOptions[0].value,
    file: {},
  }

  const handleSubmit = (values, actions) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      const params = {
        file: b64,
        filename: values.file.name || values.file.fileName,
        organizationId: values.organizationId,
        timezone: values.timezone,
      }
      if (params.organizationId === "") {
        delete params.organizationId
      }

      runMutation(params).then(response => {
        const { success, redirectTo, errorMessage } =
          response.createCourseClusterBulkUpload
        if (success) {
          window.location = redirectTo
        } else {
          actions.setStatus(errorMessage)
        }
      })
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ status, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <label className="form-label w-100">
                  CSV File
                  <File
                    name="file"
                    className="form-control"
                    accept=".csv"
                    setFieldValue={setFieldValue}
                  />
                </label>
                <ErrorMessage name="file" />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label w-100">
                  Timezone
                  <Select
                    options={timezoneOptions}
                    defaultValue={timezoneOptions[0]}
                    onChange={selectedOption =>
                      setFieldValue("timezone", selectedOption?.value)
                    }
                    styles={{
                      container: provided => ({
                        ...provided,
                        maxWidth: 250,
                      }),
                    }}
                  />
                </label>
                <ErrorMessage name="timezone" />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label w-100">
                  Organization
                  <Field
                    name="organizationId"
                    type="text"
                    className="form-control"
                  >
                    {({ field: { name, value } }) => (
                      <AutocompleteSelect
                        api="/api/admins/organizations/autocomplete_name"
                        selected={value}
                        onChange={selectedOption =>
                          setFieldValue(name, selectedOption?.value)
                        }
                        placeholder="Search for organization"
                      />
                    )}
                  </Field>
                  <ErrorMessage name="organizationId" />
                </label>
              </div>
            </div>

            <div className="col-12">
              {status && (
                <div>
                  <div className="alert alert-danger d-inline-block">
                    {status}
                  </div>
                </div>
              )}

              <SubmitButton isSubmitting={isSubmitting} text="Upload" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const createCourseClusterBulkUploadMutation = compress`
  mutation(
    $file: String!,
    $filename: String!,
    $organizationId: ID,
    $timezone: TimeZoneEnum!
  ) {
    createCourseClusterBulkUpload(
      file: $file
      filename: $filename
      organizationId: $organizationId
      timezone: $timezone
    ) {
      success
      redirectTo
      errorMessage
      errors {
        field
        messages
      }
    }
  }
`

export default CourseClusterBulkUploadForm
