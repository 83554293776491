import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"

import {
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

type EditQuestionsProps = {
  id: string
  currentQuestions: [{ id: string; text: string }]
  allQuestions: [{ id: string; text: string }]
  updateSurvey: any
}

const EditQuestions: React.FC<EditQuestionsProps> = ({
  id,
  currentQuestions,
  allQuestions,
  updateSurvey,
}) => {
  const formState = { questionIds: [] }
  const handleSubmit = (values, actions) => {
    updateSurvey({ id, input: values })
      .then(
        result => {
          const { failures } = result.data.updateSurvey
          if (failures.length > 1) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.info("Questions list updated")
            actions.setSubmitting(false)
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="max-w-[500px]">
          <div className="flex items-center justify-between">
            <div>
              <SelectField
                name="questionIds"
                isMulti
                defaultValue={currentQuestions.map(question => ({
                  label: question.text,
                  value: question.id,
                }))}
                options={allQuestions.map(question => ({
                  label: question.text,
                  value: question.id,
                }))}
                isClearable
                menuPortalTarget={document.body}
              />

              <FormFailures />
            </div>
            <div>
              <SubmitButton text="Save Question List" />
            </div>
          </div>
          <p className="alert alert-info">
            Students will be able to select between "Don't Agree", "Kinda
            Agree", and "Completely Agree" options
          </p>
        </div>
      </Form>
    </Formik>
  )
}

export default EditQuestions
