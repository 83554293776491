import React, { useContext } from "react"

import { Link } from "react-router-dom"

import {
  Box,
  Button,
  Flex,
  List,
  ListItem,
  Stack,
  useTheme,
} from "@chakra-ui/core"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"
import RealtimeboardButton from "components/Pages/RealtimeboardButton"

import ViewerContext from "./ViewerContext"

const StudentSessionTableRow = props => {
  const studentSession = props.data
  const theme = useTheme()
  const viewer = useContext(ViewerContext)

  return (
    <>
      <Box as="td" pl="3">
        <List marginBottom={2}>
          {ordered(studentSession.students, "lastName").map(student => (
            <ListItem key={student.id} marginTop={2} textTransform="capitalize">
              {student.fullName.toLowerCase()}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box as="td" pl="4">
        {studentSession.tutor && studentSession.tutor.fullName}
      </Box>
      <Box as="td" pl="4">
        {studentSession.subject && studentSession.subject.name}
      </Box>
      <Box as="td" pl="4">
        {studentSession.studyGroup?.school?.name}
      </Box>
      <Box as="td" pl="4">
        <LocalTime timestamp={studentSession.startsAt} omitTime />
        <br />
        <LocalTime timestamp={studentSession.startsAt} omitDate />
      </Box>
      <Box as="td" pl="4">
        <List marginBottom={2}>
          {studentSession.sessionParticipantSummaries.map((summary, idx) => (
            <ListItem key={idx} marginTop={2} textTransform="capitalize">
              {summary.name.toLowerCase()}: {summary.formattedDuration}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box as="td" py="4" pl="4">
        <Flex w="full">
          <Stack>
            {studentSession.realtimeboards.map(board => (
              <RealtimeboardButton viewer={viewer} {...board} key={board.url} />
            ))}
            {studentSession.googledocUrl && (
              <Button
                as="a"
                target="_blank"
                href={studentSession.googledocUrl}
                size="sm"
                {...theme.buttonVariants.realtimeboard}
              >
                Google Doc
              </Button>
            )}
            {studentSession.googleSpreadsheetUrl && (
              <Button
                as="a"
                target="_blank"
                href={studentSession.googleSpreadsheetUrl}
                size="sm"
                {...theme.buttonVariants.realtimeboard}
              >
                Google Spreadsheet
              </Button>
            )}
            <Button
              as={Link}
              to={`/sessions/${studentSession.id}/cloudroom`}
              target="_blank"
              size="sm"
              {...theme.buttonVariants.cloudroom}
            >
              Cloudroom
            </Button>
            {studentSession.cloudroomRecordings.map(({ url, name }) => (
              <Button
                as="a"
                href={url}
                size="sm"
                key={url}
                {...theme.buttonVariants.recording}
              >
                {name}
              </Button>
            ))}
          </Stack>
        </Flex>
      </Box>
    </>
  )
}

export default StudentSessionTableRow
