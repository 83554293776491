import React, { useRef } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import {
  useGradeOptions,
  useRemoteTableData,
  useSubjectOptions,
} from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

import "react-table/react-table.css"

const AdminStudentsTable = ({ defaultFilters = {} }) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/students",
    defaultFilters: defaultFilters,
  })
  const gradeOptions = useGradeOptions()
  const subjectOptions = useSubjectOptions()
  const studentNameSelect = useRef(null)
  const studentEmailSelect = useRef(null)
  const parentEmailSelect = useRef(null)

  const clearableSelects = [
    studentNameSelect.current,
    studentEmailSelect.current,
    parentEmailSelect.current,
  ]

  const columns = [
    {
      id: "full_name",
      Header: "Name",
      accessor: "full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={studentNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/students/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "email",
      Header: "Email",
      accessor: "email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={studentEmailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/students/autocomplete_email"
          />
        )
      },
    },
    {
      id: "parent_email",
      Header: "Parent Email",
      accessor: "parent_email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={parentEmailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/students/autocomplete_parent_email"
          />
        )
      },
    },
    {
      id: "phone_number",
      Header: "Phone Number",
      accessor: "phone_number",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/students/autocomplete_phone_number"
          />
        )
      },
    },
    {
      id: "grade",
      Header: "Grade",
      sortable: false,
      accessor: "grades.name",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => (event ? onChange(event.value) : onChange(""))}
            setValue={filter ? filter.value : "all"}
            options={gradeOptions}
            isClearable
          />
        )
      },
    },
    {
      id: "subjects",
      Header: "Subjects",
      sortable: false,
      accessor: row => row.subjects.map(subject => subject.name).join(", "),
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => (event ? onChange(event.value) : onChange(""))}
            setValue={filter ? filter.value : "all"}
            options={subjectOptions}
            isClearable
          />
        )
      },
    },
    {
      id: "no_show_count",
      Header: "No Shows",
      sortable: true,
      filterable: false,
      accessor: "no_show_count",
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <a
            href={props.value.edit_path}
            className="btn btn-sm btn-outline-dark fa fa-pencil"
          >
            <span className="hidden">Edit</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminStudentsTable
