import React from "react"

import { useField, useFormikContext } from "formik"

import UntypedAutoCompleteSelect from "components/Forms/AutocompleteSelect"

import ErrorMessage from "./ErrorMessage"

const AutocompleteSelect = UntypedAutoCompleteSelect as React.ComponentType<any>

export type AutocompleteFieldProps = {
  name: string
  label?: string
  api: string
  isMulti?: boolean
  valueAttribute?: string
  value?: any
  onChange?: (val: any) => void
  searchOptions?: string
  creatable?: boolean
  placeholder?: string
  isClearable?: boolean
  selected?: any
}

const AutocompleteField = React.forwardRef<
  HTMLSelectElement,
  AutocompleteFieldProps
>(({ valueAttribute = "value", ...props }, ref) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  return (
    <div className="form-group">
      <label className="form-label w-100">
        <span>{props.label}</span>
        <div>
          <AutocompleteSelect
            onChange={selected => {
              if (props.isMulti) {
                setFieldValue(
                  field.name,
                  selected.map(item => item?.[valueAttribute])
                )
              } else {
                const newVal = selected ? selected[valueAttribute] : ""
                setFieldValue(field.name, newVal)
              }
            }}
            ref={ref}
            {...props}
            menuPortalTarget={document.body}
          />
        </div>
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
})

export default AutocompleteField
