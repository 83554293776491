import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { tutorClient as client } from "src/graphql-config"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  reason: Yup.string().required(
    "Please specify a reason (this will only be visible to admins)"
  ),
  substituteNotes: Yup.string().required(
    "Please leave a note for the tutor who will pick up the session"
  ),
})

const RequestSubstitute = ({ id, subRequestsLeft }) => {
  const { runMutation } = useMutation(
    buildMutation(requestSubstituteMutation, { client })
  )
  const formState = {
    id: id,
    reason: "",
    substituteNotes: "",
  }

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { errorMessages } = response.airtutorsSessionRequestSubstitute
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location = "/"
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Request Substitute Tutor"
      modalClassName="bootstrap-modal"
      buttonText="Request Substitute Tutor"
      buttonClassName="btn solid red"
    >
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <div className="modal-body">
              <p>
                Once this form is submitted, this session will no longer be
                assigned to you.
              </p>
              <p>
                If no substitute can be found, the session will be canceled 1
                hour prior to the scheduled start time
              </p>

              <div>
                <label>
                  Reason
                  <Field
                    name="reason"
                    component="textarea"
                    className="w-100"
                    autoFocus
                  />
                </label>
                <ErrorMessage name="reason" />
              </div>

              <div className="mt-3">
                <label>
                  Notes - these will be provided to the tutor who picks up your
                  session
                  <Field
                    name="substituteNotes"
                    component="textarea"
                    className="w-100"
                  />
                </label>
                <ErrorMessage name="substituteNotes" />
              </div>
            </div>

            <div className="modal-footer flex-column">
              {status && <div className="alert p-3">{status}</div>}
              {subRequestsLeft && (
                <span className="alert p-3">
                  WARNING:{" "}
                  {subRequestsLeft === 1
                    ? "This is your last substitute request for this group. If you cannot attend a future session, you will be forced to give up the group."
                    : `You currently have ${subRequestsLeft} substitute requests left for this group. After this, you will have ${
                        subRequestsLeft - 1
                      }.`}{" "}
                  Please think carefully before submitting this request.
                </span>
              )}
              <SubmitButton isSubmitting={isSubmitting} text="Submit Request" />
            </div>
          </Form>
        )}
      </Formik>
    </ModalWithProvidedBody>
  )
}

const requestSubstituteMutation = compress`
  mutation($id: ID!, $reason: String!, $substituteNotes: String!) {
    airtutorsSessionRequestSubstitute(airtutorsSessionId: $id, reason: $reason, substituteNotes: $substituteNotes) {
      errorMessages
    }
  }
`

export default RequestSubstitute
