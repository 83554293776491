import React, { useEffect, useState } from "react"

import { compress } from "micro-graphql-react"

import { adminClient as client } from "src/graphql-config"

import Pagination from "components/Pagination"

import SearchForm from "./SearchForm"
import SearchResults from "./SearchResults"

const StudentSearch = () => {
  const [status, setStatus] = useState()
  const [queryInProgress, setQueryInProgress] = useState(false)
  const [data, setData] = useState({
    data: [],
    page: 1,
    totalPages: 1,
  })

  const [formParams, setFormParams] = useState({
    values: {},
    page: 1,
  })

  useEffect(() => {
    setQueryInProgress(true)
    client
      .runQuery(studentsQuery, {
        params: formParams.values,
        page: formParams.page,
      })
      .then(
        response => {
          const { data, errors } = response
          if (data) {
            setData(data.studentSearch)
            setStatus()
          } else {
            setStatus(errors.map(e => e.message).join(", "))
          }
        },
        () => setStatus("An error occurred")
      )
      .catch(() => setStatus("An error occurred"))
      .finally(() => setQueryInProgress(false))
  }, [formParams])

  return (
    <>
      <SearchForm
        setFormParams={setFormParams}
        queryInProgress={queryInProgress}
      />
      {status && <div className="alert alert-danger">{status}</div>}
      <SearchResults students={data.data} />
      <Pagination
        totalPages={data.totalPages}
        currentPage={formParams.page}
        changePage={newPage => setFormParams({ ...formParams, page: newPage })}
      />
    </>
  )
}

const studentsQuery = compress`
  query($params: StudentSearchInputObject!, $page: Int!) {
    studentSearch(params: $params, page: $page) {
      totalPages
      currentPage
      data {
        id
        createdAt
        fullName
        lastName
        email
        phoneNumber
        parentName
        parentEmail
        parentPhoneNumber
        hasPrivateBillingMethod
        organization {
          id
          name
        }
        school {
          id
          name
        }
        grade {
          id
          name
        }
        subjects {
          id
          name
        }
        studyGroups {
          id
          name
        }
      }
    }
  }
`

export default StudentSearch
