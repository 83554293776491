import React from "react"

import { Field } from "components/Forms/Formik/hookComponents"

import { FormGroup } from "./styles"

const PhoneNumberForm = () => {
  return (
    <>
      <FormGroup>
        <Field name="phoneNumber" label="Student Phone Number" autoFocus />
      </FormGroup>

      <FormGroup>
        <Field name="parentPhoneNumber" label="Parent Phone Number" />
      </FormGroup>
    </>
  )
}

PhoneNumberForm.modalTitle = "Lets review your phone numbers"
PhoneNumberForm.displayName = "Phone Numbers"
PhoneNumberForm.providedFields = ["phoneNumber", "parentPhoneNumber"]
PhoneNumberForm.step = "Phone Number"
export default PhoneNumberForm
