import ordered from "./ordered"

const findGradeOption = (gradeOptions, val) => {
  const gradeTier = gradeOptions.find(gradeTier =>
    gradeTier.options.find(opt => opt.value === val)
  )
  return gradeTier?.options?.find(opt => opt.value === val)
}

const gradeOptionsFromTiers = gradeTiers => {
  return gradeTiers?.map(gradeTier => ({
    label: gradeTier.gradeRange,
    options: ordered(gradeTier.grades, "number").map(grade => ({
      label: grade.name,
      value: grade.id,
    })),
  }))
}

export { findGradeOption, gradeOptionsFromTiers }
