import React from "react"

import { ArrowUpIcon } from "@heroicons/react/20/solid"

import classNames from "src/classNames"

import { gql, useMutation } from "hooks/urql"

import TagSelect from "components/Forms/TagSelect"

export type TailoredQuestionProps = {
  question: any
  scrollTo?: string
  editable?: boolean
}

const TailoredQuestion: React.FC<TailoredQuestionProps> = ({
  question,
  scrollTo,
  editable = false,
}) => {
  const [, runMutation]: any[] = useMutation(removeQuestionMutation)

  return (
    <div
      key={question.id}
      className="my-4 rounded-md border-solid border-gray-400 p-4 font-mono"
    >
      <h4>
        {question.type === "text" ? (
          question.text
        ) : (
          <img
            src={question.image.url}
            alt={question.image.filename || "question"}
          />
        )}
      </h4>
      {scrollTo && (
        <span
          onClick={() => document.getElementById(scrollTo)?.scrollIntoView()}
          className="flex items-center font-sans text-gray-500 hover:cursor-pointer"
        >
          Jump to Reference Image(s) <ArrowUpIcon className="ml-2 h-5 w-5" />
        </span>
      )}
      <hr className="my-4 h-0.5" />

      <div className="grid grid-cols-2">
        <ul className="pl-0">
          {question.tailoredAnswers.map(answer => (
            <div key={answer.id} className="form-group">
              <div className="form-check">
                <div
                  className={classNames(
                    "py-2 px-3",
                    answer.correct && "max-w-max rounded-md bg-emerald-100"
                  )}
                >
                  <label
                    className={classNames(
                      "form-check-label flex items-center hover:cursor-default"
                    )}
                  >
                    <input type="radio" disabled className="form-check-input" />
                    {answer.type === "text" ? (
                      answer.text
                    ) : (
                      <img
                        src={answer.image.url}
                        alt={answer.image.filename || "answer"}
                      />
                    )}
                    <span className="form-check-sign" />
                  </label>
                </div>
              </div>
            </div>
          ))}
        </ul>

        {question.diagram && (
          <div>
            <img src={question.diagram.url} alt={question.diagram.filename} />
          </div>
        )}
      </div>

      {editable && (
        <div className="flex items-center space-x-4">
          <TagSelect
            api="/api/admins/tailored_questions/autocomplete_tags"
            tagList={question.tags.map(tag => ({
              value: tag.id,
              label: tag.name,
            }))}
            modelKey="tagId"
            recordKey="tailoredQuestionId"
            recordId={question.id}
            createMutation={createTagMutation}
            addMutation={addTagMutation}
            removeMutation={removeTagMutation}
          />

          <a className="btn btn-warning" href={question.editPath}>
            Edit
          </a>

          <button
            className="btn btn-danger"
            onClick={() => runMutation({ id: question.id })}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  )
}

const removeQuestionMutation = gql`
  mutation ($id: ID!) {
    removeTailoredQuestion(id: $id) {
      success
      tailoredQuestion {
        id
      }
    }
  }
`

const createTagMutation = gql`
  mutation ($name: String!, $tailoredQuestionId: ID!) {
    tailoredQuestionCreateTag(
      name: $name
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

const addTagMutation = gql`
  mutation ($tagId: ID!, $tailoredQuestionId: ID!) {
    tailoredQuestionAddTag(
      tagId: $tagId
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

const removeTagMutation = gql`
  mutation ($tagId: ID!, $tailoredQuestionId: ID!) {
    tailoredQuestionRemoveTag(
      tagId: $tagId
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

export default TailoredQuestion
