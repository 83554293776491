import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"

import { useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RemoveStatement = ({ statement }) => {
  const formState = {}
  const [, removeStatement] = useMutation(deleteStatementMutation)
  const handleSubmit = (values, actions) => {
    removeStatement({ statementId: statement.id })
      .then(
        response => {
          const { failures, redirectTo } = response.data.deleteStatement
          if (redirectTo) {
            window.location = redirectTo
          } else {
            handleFailure(actions, failures)
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Remove Statement"
      buttonText="Remove Statement"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          <Form>
            <div className="modal-body">
              <p className="alert alert-danger">
                Are you sure? This will permanently remove this statement!
              </p>
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton text="Remove" className="btn btn-danger" />
                <button type="button" className="btn" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const deleteStatementMutation = gql`
  mutation ($statementId: ID!) {
    deleteStatement(statementId: $statementId) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default RemoveStatement
