import React, { useRef, useState } from "react"

import { Float } from "@headlessui-float/react"
import { Menu } from "@headlessui/react"
import { gql } from "@urql/core"

import ordered from "src/ordered"
import client from "src/urql-client"

import { Loading } from "components/Icons"

const TeacherSubject = ({ teacherSubject }) => {
  const [show, setShow] = useState(false)
  const [unassignedStudents, setUnassignedStudents] = useState()
  const closeTimer = useRef()
  const openTimer = useRef()
  const open = () => {
    if (closeTimer.current !== null) {
      clearTimeout(closeTimer.current)
      closeTimer.current = null
    }

    openTimer.current = setTimeout(() => {
      setShow(true)
      if (!unassignedStudents) {
        client
          .query(unassignedStudentsQuery, {
            teacherSubjectId: teacherSubject.id,
          })
          .toPromise()
          .then(result => {
            setUnassignedStudents(result.data.teacherSubject.unassignedStudents)
          })
      }
    }, 250)
  }

  const delayClose = () => {
    closeTimer.current = setTimeout(() => {
      if (openTimer.current !== null) {
        clearTimeout(openTimer.current)
        openTimer.current = null
      }
      setShow(false)
    }, 150)
  }

  return (
    <Menu>
      <td>
        <Float
          show={show}
          placement="top"
          flip
          arrow
          offset={12}
          enter="transition duration-200 ease-out"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition duration-150 ease-in"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
          tailwindcssOriginClass
        >
          <div onMouseEnter={open} onMouseLeave={delayClose}>
            {teacherSubject.teacher.fullName}: {teacherSubject.subject.name} (
            {teacherSubject.students.length} Students)
          </div>

          <Menu.Items
            className="rounded-md border border-gray-200 bg-white shadow-lg focus:outline-none"
            onMouseEnter={open}
            onMouseLeave={delayClose}
          >
            <Float.Arrow className="absolute h-5 w-5 rotate-45 border border-gray-200 bg-white" />

            <div className="relative h-full rounded-md bg-white p-3 text-rose-500">
              <div className="relative grid gap-2 bg-white py-1 px-2">
                <div className="text-lg font-medium">All Students</div>
                {ordered(teacherSubject.students, "lastName").map(student => (
                  <a
                    key={student.id}
                    className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50 hover:no-underline"
                    href={student.showPath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {student.fullName}
                  </a>
                ))}

                <div className="text-lg font-medium">Unassigned Students</div>
                {unassignedStudents ? (
                  unassignedStudents.length > 0 ? (
                    ordered(unassignedStudents, "lastName").map(student => (
                      <a
                        key={student.id}
                        className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50 hover:no-underline"
                        href={student.showPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {student.fullName}
                      </a>
                    ))
                  ) : (
                    <div>No unassigned students</div>
                  )
                ) : (
                  <div className="text-center">
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </Menu.Items>
        </Float>
      </td>
    </Menu>
  )
}

const unassignedStudentsQuery = gql`
  query ($teacherSubjectId: ID!) {
    teacherSubject(id: $teacherSubjectId) {
      unassignedStudents {
        id
        fullName
        lastName
        showPath
      }
    }
  }
`

export default TeacherSubject
