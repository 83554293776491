import { useEffect, useState } from "react"

import EventEmitter from "src/EventEmitter"
import cable from "src/cable"

const useWebsocket = ({ channelName, channelProps }) => {
  const [channel, setChannel] = useState()

  useEffect(() => {
    setChannel(
      cable.subscriptions.create(
        {
          channel: channelName,
          ...channelProps,
        },
        {
          received: data => {
            EventEmitter.dispatch(data.action, data)
          },
          error: e => {
            logger.error("channel error", e)
          },
        }
      )
    )
    return () => {
      if (channel) channel.unsubscribe()
    }
    // eslint-disable-next-line
  }, [channelName, channelProps])

  return { channel }
}

export default useWebsocket
