import React, { useState } from "react"

import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ChimeCloudRoom from "components/ChimeCloudroom/QualityControlLayout"

const ChimeCloudRoomContainer = ({ session, localUser }) => {
  const [muted, setMuted] = useState(true)

  const toggleMute = () => {
    setMuted(!muted)
  }

  return (
    <div
      key={session.id}
      className="aspect-[4/3] h-[20vh] overflow-hidden rounded-md border-solid border-gray-300"
    >
      <h4 className="m-0 my-1 ml-2 flex justify-between">
        <a
          href={session.showPath}
          target="_blank"
          rel="noopener noreferrer"
          className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
        >
          {session.tutor.fullName}: {session.subject.name}
        </a>
        {session.chimeJoinInfo && (
          <FontAwesomeIcon
            icon={muted ? faVolumeMute : faVolumeUp}
            onClick={toggleMute}
            className="ml-2 mr-4 basis-auto self-center"
          />
        )}
      </h4>
      <div>
        {session.chimeJoinInfo ? (
          <ChimeCloudRoom
            key={session.id}
            session={session}
            localUser={localUser}
            muted={muted}
          />
        ) : (
          <p className="pt-6 text-center italic">
            This cloudroom is not active.
          </p>
        )}
      </div>
    </div>
  )
}

export default ChimeCloudRoomContainer
