import React from "react"

import { File } from "components/Forms/Formik"

const DiagramFormGroup = props => {
  const { diagram, setFieldValue } = props
  return (
    <div className="form-group">
      <h5>Diagram</h5>
      <div className="row">
        <div className="col-4">
          <File
            name="diagram.image"
            className="form-control"
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
      {diagram && !diagram.remove && (
        <div className="row">
          <div className="col-4">
            <div>Current File:</div>
            <a href={diagram.url} target="_blank" rel="noopener noreferrer">
              <img src={diagram.thumbUrl} alt={diagram.fileName} />
              <div>{diagram.fileName}</div>
            </a>
          </div>
          <div className="col-4">
            <button
              className="btn btn-danger"
              onClick={() => removeFile(setFieldValue)}
            >
              Remove
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const removeFile = setFieldValue => {
  setFieldValue("diagram.remove", true)
}

export default DiagramFormGroup
