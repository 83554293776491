import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { useDisclosure } from "@chakra-ui/core"

import { tutorClient } from "src/graphql-config"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ConfirmSessionButton = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [errorMessage, setErrorMessage] = useState()

  const { runMutation, running } = useMutation(
    buildMutation(confirmSessionMutation, { client: tutorClient })
  )

  const confirmSession = () => {
    runMutation({ id }).then(response => {
      if (response.confirmAirtutorsSession.success) {
        window.location.reload()
      } else {
        setErrorMessage(response.confirmAirtutorsSession.errorMessage)
        onOpen()
      }
    })
  }

  return (
    <>
      <ModalWithProvidedBody
        isOpen={isOpen}
        closeModal={onClose}
        modalClassName="bootstrap-modal"
        modalTitle="Confirmation Failed"
        hideTrigger
      >
        {({ closeModal }) => (
          <React.Fragment>
            <div className="modal-body">
              <p className="lead">{errorMessage}</p>
            </div>
          </React.Fragment>
        )}
      </ModalWithProvidedBody>

      <button
        className="btn solid small green"
        onClick={confirmSession}
        disabled={running}
      >
        Confirm
      </button>
    </>
  )
}

const confirmSessionMutation = compress`
  mutation($id: ID!) {
    confirmAirtutorsSession(id: $id) {
      success
      errorMessage
    }
  }
`

export default ConfirmSessionButton
