import React, { useState } from "react"

import { gql } from "urql"

import { useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ReprocessBulkAction = ({ bulkActionId }) => {
  const [, runReprocess]: any[] = useMutation(reprocessMutation)
  const [errorMessages, setErrorMessages] = useState([])

  const reprocess = () => {
    runReprocess({ id: bulkActionId }).then(response => {
      const { failures } = response.data.studyGroupBulkActionReprocess
      if (failures) {
        setErrorMessages(failures.map(err => err.message))
      } else {
        window.location.reload()
      }
    })
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn btn-warning btn-sm"
      buttonText="Reprocess"
      modalTitle="Reprocess"
      afterClose={() => setErrorMessages([])}
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            Are you sure? This will re-execute the bulk action for all
            non-processed configured groups.
          </div>
          <div className="modal-footer flex-column">
            {errorMessages && (
              <div>
                {errorMessages.map(errorMessage => (
                  <div className="alert alert-danger" key={errorMessage}>
                    {errorMessage}
                  </div>
                ))}
              </div>
            )}

            <div className="d-flex justify-content-between w-full">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger"
              >
                Cancel
              </button>

              <button onClick={reprocess} className="btn btn-info">
                Confirm
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const reprocessMutation = gql`
  mutation ($id: ID!) {
    studyGroupBulkActionReprocess(id: $id) {
      failures {
        message
      }
    }
  }
`

export default ReprocessBulkAction
