import React from "react"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import classNames from "src/classNames"
import emptyFunction from "src/emptyFunction"

export type BaseButtonProps = {
  active: boolean
  onClick: () => void
  activeText?: string
  inactiveText?: string
  activeIcon: IconProp
  inactiveIcon: IconProp
  className?: string
  disabled?: boolean
  transparent?: boolean
}

const BaseButton: React.FC<BaseButtonProps> = ({
  active,
  onClick,
  activeText,
  inactiveText,
  activeIcon,
  inactiveIcon,
  className,
  disabled = false,
  transparent = false,
}) => {
  return (
    <button
      className={classNames(
        "inline-flex items-center justify-around rounded-sm border-none p-1 leading-4 text-white outline-none transition duration-500",
        transparent && "opacity-75",
        active ? "bg-brand-600" : "bg-blue-700",
        disabled && "cursor-not-allowed bg-gray-400",
        className
      )}
      onClick={disabled ? emptyFunction : onClick}
    >
      {activeText && active && <div className="w-full">{activeText}</div>}
      {inactiveText && !active && <div className="w-full">{inactiveText}</div>}
      <FontAwesomeIcon icon={active ? activeIcon : inactiveIcon} />
    </button>
  )
}

export default BaseButton
