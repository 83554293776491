import { Client, setDefaultClient } from "micro-graphql-react"

import csrfToken from "src/csrf-token"

const fetchOptions = {
  headers: {
    "X-CSRF-TOKEN": csrfToken,
  },
}

const adminClient = new Client({
  endpoint: "/api/admins/graphql",
  fetchOptions: fetchOptions,
})
setDefaultClient(adminClient)

const tutorClient = new Client({
  endpoint: "/api/tutors/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

const studentClient = new Client({
  endpoint: "/api/students/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

const parentClient = new Client({
  endpoint: "/api/parents/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

const teacherClient = new Client({
  endpoint: "/api/teachers/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

const organizationAdminClient = new Client({
  endpoint: "/api/organization_admins/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

const publicClient = new Client({
  endpoint: "/api/public/graphql",
  noCaching: true,
  fetchOptions: fetchOptions,
})

export {
  adminClient,
  organizationAdminClient,
  parentClient,
  publicClient,
  studentClient,
  teacherClient,
  tutorClient,
}
