import React from "react"

import { useField } from "formik"

import { timezoneOptions } from "src/enums"

import SelectField from "./SelectField"

const TimeZoneField = props => {
  const [field] = useField(props)

  return (
    <SelectField
      name={props.name}
      label="Time Zone"
      options={timezoneOptions}
      defaultValue={timezoneOptions.find(
        option => option.value === field.value
      )}
      {...props}
    />
  )
}

export default TimeZoneField
