import React, { useState } from "react"

import ordered from "src/ordered"

import { gql, useQuery } from "hooks/urql"

import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const OpenStudyGroupReplacements = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: studyGroupReplacementsQuery,
    variables: { page },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          {data.studyGroupTutorReplacements.data.length === 0 ? (
            <p className="lead">No open groups!</p>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr className="text-primary">
                    <th>Name</th>
                    <th>Organization</th>
                    <th>Start Date</th>
                    <th>Subjects</th>
                    <th>Original Tutor</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {data.studyGroupTutorReplacements.data.map(replacement => (
                    <tr key={replacement.id}>
                      <td>{replacement.studyGroup.name}</td>
                      <td>{replacement.studyGroup.organization?.name}</td>
                      <td>
                        <LocalTime timestamp={replacement.startsAt} omitTime />
                      </td>
                      <td>
                        {ordered(replacement.studyGroup.subjects, "name")
                          .map(s => s.name)
                          .join(", ")}
                      </td>
                      <td>
                        <a href={replacement.originalTutor?.showPath}>
                          {replacement.originalTutor?.fullName}
                        </a>
                      </td>
                      <td>
                        <a
                          className="btn btn-sm btn-info"
                          href={replacement.studyGroup.showPath}
                        >
                          View Group
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                currentPage={data.studyGroupTutorReplacements.currentPage}
                totalPages={data.studyGroupTutorReplacements.totalPages}
                changePage={setPage}
              />
            </>
          )}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const studyGroupReplacementsQuery = gql`
  query ($page: Int) {
    studyGroupTutorReplacements(page: $page, accepted: false) {
      currentPage
      totalPages
      data {
        id
        startsAt
        originalTutor {
          id
          fullName
          showPath
        }
        studyGroup {
          id
          name
          showPath
          subjects {
            id
            name
          }
          organization {
            id
            name
          }
        }
      }
    }
  }
`

export default OpenStudyGroupReplacements
