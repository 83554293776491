const base64ToArrayBuffer = data => {
  var binaryString = window.atob(data)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

const createBlob = (encodedString, type) => {
  const arrBuffer = base64ToArrayBuffer(encodedString)
  const blob = new Blob([arrBuffer], { type: type })
  return URL.createObjectURL(blob)
}

export default createBlob
