import React from "react"

import { gql, useQuery } from "hooks/urql"

import TagSelect from "components/Forms/TagSelect"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TutorTags = ({ tutorId }) => {
  const [result] = useQuery({ query: tagsQuery, variables: { tutorId } })
  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <TagSelect
          api="/api/admins/tutors/autocomplete_tags"
          tagList={data.tutor.tags.map(tag => ({
            value: tag.id,
            label: tag.name,
          }))}
          modelKey="tagId"
          recordId={tutorId}
          createMutation={createTagMutation}
          addMutation={addTagMutation}
          removeMutation={removeTagMutation}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const tagsQuery = gql`
  query ($tutorId: ID!) {
    tutor(id: $tutorId) {
      id
      tags {
        id
        name
      }
    }
  }
`
const createTagMutation = gql`
  mutation ($name: String!, $userId: ID!) {
    userCreateTag(name: $name, userId: $userId) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const addTagMutation = gql`
  mutation ($tagId: ID!, $userId: ID!) {
    userAddTag(tagId: $tagId, userId: $userId) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`

const removeTagMutation = gql`
  mutation ($tagId: ID!, $userId: ID!) {
    userRemoveTag(tagId: $tagId, userId: $userId) {
      user {
        ... on Tutor {
          id
        }
      }
      failures {
        message
      }
    }
  }
`
export default TutorTags
