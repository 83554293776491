import React from "react"

import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

import GenerateSessions from "./Availabilities/GenerateSessions"

const GenerationDetails = ({ studyGroup }) => (
  <div className="col-12">
    <h4>Generation Details</h4>
    <p>
      Note: Adding and removing students on this page will NOT update student
      availabilities. If you want the availability records to be verified and
      kept in sync, use the generated groups management page.
    </p>
    <div className="d-flex align-items-center mb-3">
      <a
        href={studyGroup.studyGroupGeneration.showPath}
        className="btn btn-info"
      >
        Manage Generation
      </a>
      <GenerateSessions
        studyGroupId={studyGroup.id}
        subjects={studyGroup.subjects}
      />
    </div>
    <dl className="row">
      <dt className="col-6 col-md-4 col-lg-3">Minimum Group Size</dt>
      <dd className="col-6 col-md-8 col-lg-9">
        {studyGroup.studyGroupGeneration.minimumGroupSize}
      </dd>

      <dt className="col-6 col-md-4 col-lg-3">Maximum Group Size</dt>
      <dd className="col-6 col-md-8 col-lg-9">
        {studyGroup.studyGroupGeneration.maximumGroupSize}
      </dd>

      <dt className="col-6 col-md-4 col-lg-3">Teacher Subject</dt>
      <dd className="col-6 col-md-8 col-lg-9">
        {studyGroup.teacherSubject.teacher.fullName}:{" "}
        {studyGroup.teacherSubject.subject.name}
      </dd>

      <dt className="col-6 col-md-4 col-lg-3">Availability Times</dt>
      <dd className="col-6 col-md-8 col-lg-9">
        <ul className="list-unstyled">
          {ordered(studyGroup.availabilities, "weekdayNumber").map(
            availability => (
              <li key={availability.id}>
                {availability.weekday}{" "}
                <LocalTime timestamp={availability.startsAt} format="h:mm A" />
                <span> &ndash; </span>
                <LocalTime timestamp={availability.endsAt} format="h:mm A z" />
              </li>
            )
          )}
        </ul>
      </dd>
    </dl>
  </div>
)

export default GenerationDetails
