import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { tutorClient as client } from "src/graphql-config"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const SubmittedExpenses = () => {
  const [page, setPage] = useState(1)
  const loadingState = useQuery(buildQuery(expensesQuery, { page }, { client }))

  if (loadingState.data && loadingState.data.expenses.data.length === 0)
    return null

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      heading={<h2>Pending Expenses</h2>}
    >
      {({ data }) => (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Your Comments</th>
                <th>Admin Feedback</th>
              </tr>
            </thead>
            <tbody>
              {data.expenses.data.map(expense => (
                <tr key={expense.id}>
                  <td>{expense.description}</td>
                  <td>{expense.expenseType}</td>
                  <td>{expense.formattedAmount}</td>
                  <td>{expense.comments}</td>
                  <td>{expense.feedback}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={data.expenses.currentPage}
            totalPages={data.expenses.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const expensesQuery = compress`
  query($page: Int!) {
    expenses(page: $page, state: submitted) {
      currentPage
      totalPages
      data {
        id
        expenseType
        description
        comments
        feedback
        formattedAmount
      }
    }
  }
`

export default SubmittedExpenses
