import React from "react"

import { motion } from "framer-motion"

import { Flex, Stack } from "@chakra-ui/core"

import Layer from "components/Pages/Layer"

import NoShowNotification from "./NoShowNotification"

const NoShowNotificationList = ({ noShowNotifications }) => {
  return (
    <motion.div
      initial={false}
      animate={noShowNotifications.length > 0 ? "visible" : "hidden"}
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%", height: 0, transitionEnd: { display: "none" } },
      }}
    >
      <Flex justify="center" className="flex-container">
        <Layer w={["50vw", "auto", "auto", "50vw"]} overflowY="hidden">
          <Stack>
            {noShowNotifications.map(noShowNotification => (
              <motion.div positionTransition key={noShowNotification.id}>
                <NoShowNotification noShowNotification={noShowNotification} />
              </motion.div>
            ))}
          </Stack>
        </Layer>
      </Flex>
    </motion.div>
  )
}

export default NoShowNotificationList
