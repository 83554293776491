import React, { useRef, useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import { css } from "@emotion/core"

import ordered from "src/ordered"
import client from "src/urql-client"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"

const StudyGroup = ({
  studyGroup,
  generatingFor,
  canAddTutor = false,
  canChangeTutor = false,
}) => {
  const [changingTutor, setChangingTutor] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [status, setStatus] = useState()
  const { runMutation: runChangeTutorMutation } = useMutation(
    buildMutation(changeTutorMutation)
  )

  const select = useRef()
  const [errors, setErrors] = useState([])

  const changeTutor = (selected, currentTutor) => {
    if (!selected) return

    runChangeTutorMutation({
      studyGroupId: studyGroup.id,
      newTutorId: selected.value,
      currentTutorId: currentTutor.id,
    })
      .then(
        response => {
          const { failures } = response.courseSectionChangeTutor
          if (failures) {
            setErrors(failures)
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
      .finally(() => setChangingTutor(false))
  }

  const registerTutor = selected => {
    if (!selected) return

    setErrorMessages([])
    setStatus("starting")

    const { unsubscribe } = pipe(
      client.subscription(registerTutorSubscription, {
        studyGroupId: studyGroup.id,
        tutorId: selected.value,
      }),
      subscribe(result => {
        if (result.data) {
          const { status, errorMessages } = result.data.courseSectionRegister
          setStatus(status)
          setErrorMessages(errorMessages)
          if (errorMessages.length) {
            setErrorMessages(errorMessages)
            unsubscribe()
          }
          if (status === "in-progress") {
            setStatus(
              <>
                In Progress <br />
                (Please be patient, this process can take some time)
              </>
            )
          }
          if (status === "success") {
            setErrorMessages([])
            setStatus(
              <>
                Registration Successful <br />
                Due to technical limitations, the UI cannot be automatically
                updated at this time. Please refresh the page to view updated
                tutor assignments.
              </>
            )
            unsubscribe()
          }
        } else if (result.error) {
          setStatus()
          setErrorMessages([result.error?.message])
          unsubscribe()
        }
        select.current?.clearValue()
      })
    )
  }

  const tutor = generatingFor || studyGroup.tutor

  return (
    <div>
      <dl>
        <dt>Name</dt>
        <dd>
          <a href={studyGroup.showPath}>{studyGroup.name}</a>
        </dd>

        <dt>Students</dt>
        <dd>
          {ordered(studyGroup.students, "lastName").map(student => (
            <div key={student.id}>
              <a href={student.showPath}>{student.fullName}</a>
            </div>
          ))}
        </dd>

        <dt>{generatingFor ? "Generating For Tutor" : "Tutor"}</dt>
        <dd>
          {tutor && (
            <div>
              <div>
                <a href={tutor.showPath}>{tutor.fullName}</a>
              </div>
              {canChangeTutor && (
                <div>
                  {!changingTutor ? (
                    <button
                      className="btn btn-sm btn-outline-info"
                      onClick={() => setChangingTutor(true)}
                    >
                      Change Tutor
                    </button>
                  ) : (
                    <div className="d-flex align-items-center mt-3">
                      <div className="flex-grow-1">
                        <AutocompleteSelect
                          api="/api/admins/tutors/autocomplete_full_name"
                          onChange={selected =>
                            changeTutor(selected, studyGroup.tutor)
                          }
                          withLoadingIndicator
                        />
                      </div>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => setChangingTutor(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {canAddTutor && (
            <>
              <AutocompleteSelect
                api="/api/admins/tutors/autocomplete_full_name"
                onChange={registerTutor}
                ref={select}
                withLoadingIndicator
              />
            </>
          )}
        </dd>
      </dl>
      {status && (
        <div className="alert my-5 bg-blue-400 p-4">
          Current Status: {status}
        </div>
      )}
      {errorMessages.map((msg, index) => (
        <div
          className="alert alert-danger p-4"
          css={css`
            a {
              color: white !important;
            }
          `}
          key={index}
          dangerouslySetInnerHTML={{ __html: msg }}
        />
      ))}
      {errors.map((error, index) => (
        <div key={index} className="alert alert-danger mt-3">
          <p>{error.message}</p>
          {error.conflictingSessions && (
            <ul className="list-unstyled">
              {error.conflictingSessions.map(session => (
                <li key={session.id}>
                  <a
                    href={session.showPath}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-white"
                  >
                    <LocalTime timestamp={session.startsAt} />
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
      <hr />
    </div>
  )
}

const registerTutorSubscription = gql`
  subscription ($studyGroupId: ID, $tutorId: ID) {
    courseSectionRegister(studyGroupId: $studyGroupId, tutorId: $tutorId) {
      status
      errorMessages
    }
  }
`

const changeTutorMutation = compress`
  mutation($studyGroupId: ID!, $currentTutorId: ID!, $newTutorId: ID!) {
    courseSectionChangeTutor(studyGroupId: $studyGroupId, currentTutorId: $currentTutorId, newTutorId: $newTutorId) {
      failures {
        message
        conflictingSessions {
          id
          showPath
          startsAt
        }
      }
      studyGroup {
        id
        tutor {
          id
          fullName
          lastName
          showPath
        }
        courseSection {
          id
        }
      }
    }
  }
`

export default StudyGroup
