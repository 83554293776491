import React from "react"

import { FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { useToast } from "@chakra-ui/core"
import { css } from "@emotion/core"

import { tutorClient } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ValidationSchema = Yup.object().shape({
  studentIds: Yup.array().min(1, "Please select at least one student"),
})

const SendSmsButton = ({ airtutorsSessionId }) => {
  const formState = {
    studentIds: [],
  }

  const toast = useToast()

  const loadingState = useQuery(
    buildQuery(studentsQuery, { airtutorsSessionId }, { client: tutorClient })
  )
  const { runMutation } = useMutation(
    buildMutation(sendSmsMutation, { client: tutorClient })
  )

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ id: airtutorsSessionId, ...values })
      .then(
        response => {
          const { failures } = response.airtutorsSessionSendLateSms
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            actions.setStatus()
            toast({
              title: "SMS Enqueued",
              description: "Your SMS will be sent in ~1 minute",
              status: "success",
              variant: "solid",
              duration: null,
              isClosable: true,
              position: "top",
            })
            closeModal()
          }
        },
        err => {
          actions.setStatus(`Rejected: ${err?.message}`)
        }
      )
      .catch(err => {
        actions.setStatus(`Caught: ${err?.message}`)
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName="btn solid purple w-100"
      buttonText="Send Student SMS"
      modalClassName="bootstrap-modal"
      modalTitle="Send Student SMS"
      afterOpenModal={loadingState.reload}
    >
      {({ closeModal }) => (
        <WithLoadingIndicator loadingState={loadingState}>
          {({ data }) => (
            <Formik
              initialValues={formState}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, closeModal)
              }
              validationSchema={ValidationSchema}
            >
              {({ values, status, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="modal-body">
                    <p className="mt-0">
                      This will send the following SMS to the selected students:
                    </p>
                    <p
                      css={css`
                        border: 1px solid black;
                        border-radius: 6px;
                        padding: 15px;
                        background-color: #eee;
                      `}
                    >
                      Hey [Student Name]! It's [Tutor Name]{" "}
                      <span role="img" aria-label="happy">
                        🤗
                      </span>{" "}
                      Your session with me is happening right now! Any chance
                      you can make it?
                      <br />
                      <br />
                      Click this link to join the Cloudroom: [Cloudroom Link]
                      <br />
                      <br />
                      BTW, if the arrow wheels keep spinning, click on the
                      camera icon in your URL address bar on the far right and
                      make sure airtutors.org is allowed. Lmk if you can make it
                      today!{" "}
                      <span role="img" aria-label="congratulations">
                        🥳
                      </span>
                    </p>
                    <FieldArray name="studentIds">
                      {arrayHelpers =>
                        ordered(
                          data.airtutorsSession.studentSessions,
                          "student",
                          "lastName"
                        ).map(studentSession => (
                          <RadioGroup
                            key={studentSession.id}
                            type="checkbox"
                            name="studentIds"
                            disabled={!studentSession.student.phoneNumber}
                            values={[
                              {
                                value: studentSession.student.id,
                                label: `${studentSession.student.fullName} ${
                                  !studentSession.student.phoneNumber
                                    ? "(has no number on file)"
                                    : ""
                                }${
                                  studentSession.lateSmsSentAt
                                    ? "(you can only send each student one SMS per session)"
                                    : ""
                                }`,
                                disabled:
                                  !studentSession.student.phoneNumber ||
                                  studentSession.lateSmsSentAt,
                              },
                            ]}
                            onChange={e => {
                              if (e.target.checked) {
                                arrayHelpers.push(studentSession.student.id)
                              } else {
                                arrayHelpers.remove(
                                  values.studentIds.findIndex(
                                    s => s === studentSession.student.id
                                  )
                                )
                              }
                            }}
                          />
                        ))
                      }
                    </FieldArray>
                    <ErrorMessage name="studentIds" />
                  </div>
                  <div
                    className="modal-footer"
                    css={css`
                      display: block !important;
                    `}
                  >
                    {status && (
                      <div
                        className="alert alert-danger"
                        css={css`
                          padding: 9px;
                          margin-bottom: 15px;
                        `}
                      >
                        {status}
                      </div>
                    )}
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <button className="btn solid red" onClick={closeModal}>
                        Cancel
                      </button>
                      <SubmitButton
                        text="Send SMS"
                        isSubmitting={isSubmitting}
                        containerCss={css`
                          margin: 0;
                        `}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </WithLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const studentsQuery = compress`
  query($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      studentSessions {
        id
        lateSmsSentAt
        student {
          id
          fullName
          lastName
          phoneNumber
        }
      }
    }
  }
`

const sendSmsMutation = compress`
  mutation($id: ID!, $studentIds: [ID!]!) {
    airtutorsSessionSendLateSms(id: $id, studentIds: $studentIds) {
      failures {
        message
      }
    }
  }
`

export default SendSmsButton
