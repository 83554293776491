import React from "react"

import { motion } from "framer-motion"
import { compress } from "micro-graphql-react"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import LocalTime from "components/LocalTime"

import AddAvailability from "./AddAvailability"
import GenerateSessions from "./GenerateSessions"

const Availabilities = ({ studyGroup }) => {
  return (
    <div className="col-12">
      <h4>Availability Times</h4>
      <EditableList>
        {ordered(studyGroup.availabilities, "weekdayNumber").map(
          availability => (
            <motion.li key={availability.id} positionTransition>
              <Item
                mutation={removeAvailabilityMutation}
                mutationProps={{
                  studyGroupId: studyGroup.id,
                  availabilityId: availability.id,
                }}
              >
                {availability.weekday}{" "}
                <LocalTime timestamp={availability.startsAt} format="h:mm A" />
                <span> &ndash; </span>
                <LocalTime timestamp={availability.endsAt} format="h:mm A z" />
              </Item>
            </motion.li>
          )
        )}
      </EditableList>

      <AddAvailability studyGroupId={studyGroup.id} />
      {studyGroup.availabilities.length > 0 && (
        <GenerateSessions
          studyGroupId={studyGroup.id}
          subjects={studyGroup.subjects}
        />
      )}
    </div>
  )
}

const removeAvailabilityMutation = compress`
  mutation($studyGroupId: ID!, $availabilityId: ID!) {
    studyGroupRemoveAvailability(studyGroupId: $studyGroupId, availabilityId: $availabilityId) {
      availability {
        id
      }
    }
  }
`

export default Availabilities
