import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
})

const NewForeignLanguage = () => {
  const formState = { name: "" }
  const [, createForeignLanguage] = useMutation(createForeignLanguageMutation)
  const handleSubmit = (values, actions) => {
    createForeignLanguage(values)
      .then(
        result => {
          const { failures } = result.data.createForeignLanguage
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Create Language"
      buttonText="Create Language"
      buttonClassName="btn btn-info"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field name="name" label="Name" />
            </div>
            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="d-flex w-full justify-between">
                <SubmitButton />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createForeignLanguageMutation = gql`
  mutation ($name: String!) {
    createForeignLanguage(name: $name) {
      failures {
        message
      }
    }
  }
`

export default NewForeignLanguage
