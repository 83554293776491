import React from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import InlineConfirmation from "components/InlineConfirmation"

const StartResumeReview = ({ id }) => {
  const [, runMutation] = useMutation(workflowMutation)

  const handleSubmit = () => {
    return runMutation({ id }).then(result => {
      if (result.error) {
        NotificationManager.error(result.error.message)
      }

      const { failures } = result.data.startResumeReview
      if (failures) {
        NotificationManager.error(failures.map(f => f.message).join(", "))
      } else {
        NotificationManager.success(
          "Success! You have been assigned as the reviewer!"
        )
      }
    })
  }

  return (
    <InlineConfirmation
      handleSubmit={handleSubmit}
      type="info"
      buttonText="Start Resume Review"
    />
  )
}

const workflowMutation = gql`
  mutation ($id: ID!) {
    startResumeReview(id: $id) {
      failures {
        message
      }
      tutorApplication {
        id
      }
    }
  }
`

export default StartResumeReview
