import React, { useState } from "react"

import Select from "react-select"
import { gql } from "urql"

import { expenseStateOptions } from "src/enums"

import { useQuery } from "hooks/urql"

import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Expense from "./Expense"
import Export from "./Export"

const AdminExpensesList = () => {
  const [page, setPage] = useState(1)
  const [expenseState, setExpenseState] = useState(
    expenseStateOptions.find(o => o.value === "submitted")
  )
  const [result] = useQuery({
    query: expensesQuery,
    variables: { page, state: expenseState.value },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <div>
            <Export />
          </div>
          <div className="max-w-[250px]">
            Change Filter
            <Select
              options={expenseStateOptions}
              value={expenseState}
              onChange={selected => {
                setPage(1)
                setExpenseState(selected)
              }}
            />
          </div>

          <h4>{expenseState.label} Expenses</h4>

          {data.expenses.data.map(expense => (
            <Expense key={expense.id} expense={expense} />
          ))}

          <Pagination
            currentPage={data.expenses.currentPage}
            totalPages={data.expenses.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const expensesQuery = gql`
  query ($page: Int!, $state: ExpenseStateEnum) {
    expenses(state: $state, page: $page) {
      totalPages
      currentPage

      data {
        id
        amount
        formattedAmount
        description
        comments
        feedback
        expenseType
        approvedAt
        deniedAt
        paidAt
        mayApprove
        mayDeny
        mayRequestChanges
        tutor: user {
          ... on Tutor {
            id
            fullName
            showPath
          }
        }
        receipts {
          id
          filename
          url
          thumbUrl
          previewUrl
          downloadUrl
        }
      }
    }
  }
`

export default AdminExpensesList
