import React, { useRef } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { formatDate } from "src/date-helpers"
import { statementStateOptions } from "src/enums"

import { useExportable, useRemoteTableData } from "hooks/remoteTable"

import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const BudgetStatements = ({ budgetId, newStatementPath }) => {
  const apiEndpoint = `/api/admins/budgets/${budgetId}/statements`
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint,
  })
  const table = useRef(null)
  const { exportToCSV } = useExportable({
    apiEndpoint,
    tableRef: table.current,
  })

  const columns = [
    {
      id: "starts_at",
      Header: "Start Date",
      accessor: "starts_at",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ startsAtStart: null, startsAtEnd: null })
              return
            }

            onChange({
              startsAtStart: formatDate(startDate.toDate()),
              startsAtEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "ends_at",
      Header: "End Date",
      accessor: "ends_at",
      Cell: props => <LocalTime timestamp={props.value} omitTime />,
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ endsAtStart: null, endsAtEnd: null })
              return
            }

            onChange({
              endsAtStart: formatDate(startDate.toDate()),
              endsAtEnd: formatDate((endDate || startDate).toDate()),
            })
          }}
        />
      ),
    },
    {
      id: "total",
      Header: "Total Dollar Amount",
      accessor: "total",
      filterable: false,
      Cell: props =>
        props.value < 0 ? (
          <span className="text-red-500">{props.original.formatted_total}</span>
        ) : (
          props.original.formatted_total
        ),
    },
    {
      id: "invoice_number",
      Header: "Invoice Number",
      accessor: "invoice_number",
      filterable: false,
    },
    {
      id: "aasm_state",
      Header: "Status",
      accessor: "aasm_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={eventArray =>
              onChange({ aasm_states: eventArray.map(event => event.value) })
            }
            setValue={filter ? filter.value : "all"}
            options={statementStateOptions}
            isMulti={true}
            isClearable
          />
        )
      },
    },
    {
      id: "links",
      Header: "Actions",
      accessor: "links",
      filterable: false,
      sortable: false,
      Cell: props => (
        <>
          <div>
            <a className="btn btn-sm btn-info" href={props.value.show_path}>
              Details
            </a>
          </div>
          {props.value.pdf_url && (
            <div>
              <a
                className="btn btn-sm btn-outline-info"
                href={props.value.pdf_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faCloudDownloadAlt} className="mr-2" />
                PDF
              </a>
            </div>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <h3 className="mb-0">Statements</h3>
      <div>
        <button type="button" onClick={exportToCSV} className="btn">
          CSV Export
        </button>
        <a href={newStatementPath} className="btn btn-info">
          New Statement
        </a>
      </div>

      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={table}
        manual
        filterable
      />
    </>
  )
}

export default BudgetStatements
