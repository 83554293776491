import React from "react"

import { useFormikContext } from "formik"

const SelectAll = ({ fieldName, items }) => {
  const { setFieldValue } = useFormikContext()

  const selectAll = () =>
    setFieldValue(
      fieldName,
      items.map(item => item.id)
    )

  const deselectAll = () => setFieldValue(fieldName, [])

  return (
    <>
      <button type="button" onClick={selectAll} className="btn btn-info">
        Select All
      </button>

      <button type="button" onClick={deselectAll} className="btn btn-warning">
        De-select All
      </button>
    </>
  )
}

export default SelectAll
