import React from "react"

import New from "./New"
import Table from "./Table"

const CollegesIndex = () => {
  return (
    <>
      <New />
      <Table />
    </>
  )
}

export default CollegesIndex
