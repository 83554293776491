import { compress } from "micro-graphql-react"

export default compress`
  mutation(
    $tutorId: ID!
    $subjectBucketId: ID!
  ) {
    addTutorSubject(
      tutorId: $tutorId
      subjectBucketId: $subjectBucketId
    ) {
      tutorSubject {
        id
        approvalState
        subjectBucket {
          id
          name
          tier {
            id
          }
        }
      }
    }
  }
`
